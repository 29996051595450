<template>
  <OmtGrid>
    <OmtRow mb="24px">
      <OmtTypography as="h1-bold"> {{ header }} </OmtTypography>
    </OmtRow>

    <OmtRow :mb="description ? '16px' : ''" v-if="subHeader">
      <OmtTypography as="h3-bold">
        {{ subHeader }}
      </OmtTypography>
    </OmtRow>

    <OmtRow v-if="description">
      <OmtTypography as="p1">
        {{ description }}
      </OmtTypography>
    </OmtRow>

    <OmtRow>
      <OmtDivider mt="24px" mb="24px" w="100%" />
    </OmtRow>

    <OmtRow mb="32px">
      <ul v-chakra pl="16px">
        <template v-for="(item, index) in terms">
          <template v-if="item.startsWith('<span>')">
            <li v-chakra mb="8px" :key="index">
              <OmtTypography as="p1" v-html="item" />
            </li>
          </template>
          <template v-else>
            <OmtTypography as="p1" v-html="item" my="16px !important" ml="-20px" />
          </template>
        </template>
      </ul>
    </OmtRow>

    <OmtRow>
      <CBox>
        <CStack
          :position="['absolute', 'relative']"
          :bottom="['90px', '0']"
          :left="['16px', '0']"
          :right="['16px', '0']"
        >
          <OmtCheckbox @change="optin = !optin" :is-checked="optin">
            <OmtTypography as="p1"> Confirmo que estou de acordo com as declarações descritas acima. </OmtTypography>
          </OmtCheckbox>

          <OmtButton :m="['26px 0 0 0', '32px auto 0 auto']" :w="['100%', '30%']" @click="submit" :disabled="!optin">
            Continuar
          </OmtButton>
        </CStack>
        <CBox :height="['124px', '0']" w="50%" />
      </CBox>
    </OmtRow>

    <CancellationDetailsModal :is-open.sync="showCancellationDetailsModal" @submit="handlePendencyModalSubmit" />

    <FeedbackModal
      v-if="showFeedbackModal"
      :protocol="modalInfos.protocol"
      :title="modalInfos.title"
      :cardTitle="modalInfos.cardTitle"
      :cardDescription="modalInfos.cardDescription"
      :cardIcon="modalInfos.cardIcon"
      :modalIconBind="modalInfos.modalIconBind"
      :buttonLabel="modalInfos.buttonLabel"
      :secondButtonLabel="modalInfos.secondButtonLabel"
      @buttonClick="modalInfos.buttonClick"
      @close="modalInfos.close"
    />
  </OmtGrid>
</template>

<script>
import generateAuditTerms from "@/components/Organisms/RefundNew/fixture/generate-audit-terms.js";
import FeedbackModal from "@/components/Molecules/FeedbackModal";
import CancellationDetailsModal from "@/components/Organisms/RefundNew/AuditForm/CancellationDetailsModal.vue";
import { FormsOptions } from "@/components/Organisms/RefundNew/AuditForm/scenarios";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    FeedbackModal,
    CancellationDetailsModal
  },
  data() {
    return {
      showFeedbackModal: false,
      showCancellationDetailsModal: false,
      optin: false,
      paidWithCash: null,
      sharedCredential: false,
      protocol: "",
      scenario: null,
      answers: [],
      refund: {},
      ticket: ""
    };
  },
  mounted() {
    this.scenario = this.$route.params.scenario;
    this.refund = this.$route.params.refund;
    this.ticket = this.$route.params.ticket;
    this.mustCancelProtocol = this.$route.params.mustCancelProtocol;

    if (this.$route.params.answers) {
      this.answers = this.$route.params.answers;

      const paymentTypeAnswer = this.answers.find(
        answer => answer.component === FormsOptions[FormsOptions.AuditFormPaymentType]
      );
      this.paidWithCash = paymentTypeAnswer?.value === 11;

      const sharedCredentialAnswer = this.answers.find(
        answer => answer.component === FormsOptions[FormsOptions.AuditFormSharedCredentials]
      );
      this.sharedCredential = sharedCredentialAnswer?.value === 9;
    }
  },
  computed: {
    ...mapState("saude", ["contratType"]),
    singlePlan() {
      return this.contratType === "F";
    },
    modalInfos() {
      const modalHeaderByType = {
        pendency: {
          title: "Respostas enviadas para análise",
          modalIconBind: {
            name: "clock",
            color: "var(--colors-light-primary-dark-blue)"
          }
        },
        cancellation: {
          title: "Esta solicitação de reembolso foi cancelada",
          modalIconBind: {
            name: "circle-exclamation",
            color: "var(--colors-light-support-warning)"
          }
        }
      };

      const modalInnerCardContentByType = {
        "change-password": {
          cardTitle: "É necessário que você altere a senha do seu app",
          cardDescription: `
            Como você forneceu sua senha para um prestador de serviço, a <b>alteração de senha é obrigatória.</b>
            <br/><br/> 
            Isso é necessário para prevenirmos a <b>utilização indevida de seu acesso.</b>
            <br/><br/> 
            Caso a senha não seja alterada, <b>seu acesso poderá ser bloqueado.</b>
          `
        },
        "security-recommendation": {
          cardTitle: "Previna-se contra o uso irregular do seu plano",
          cardDescription: `
            <ul>
              <li>
                <b>Nunca forneça sua senha do APP</b> para terceiros ou prestadores de serviços e troque-a periodicamente.
              </li>
              <li>
                <b>Nunca aceite “serviços e facilidades suspeitas”</b>, oferecidas por terceiros, mesmo que sejam prestadores de serviços médicos.
              </li>
              <li>
                Mantenha suas <b>informações pessoais atualizadas</b>.
              </li>
              <li>
                <b>Confira as informações nos documentos apresentados </b>e, em caso de dúvidas, contate a Central de Atendimento Omint.
              </li>
            </ul>
          `
        }
      };

      const mustChangePassword = this.scenario === 1 || this.sharedCredential;

      return {
        protocol: this.protocol,
        ...modalHeaderByType[[2, 3].includes(this.scenario) ? "pendency" : "cancellation"],
        ...modalInnerCardContentByType[
          [1, 2, 5].includes(this.scenario) ? "change-password" : "security-recommendation"
        ],
        cardIcon: "lock",
        buttonLabel: mustChangePassword ? "Alterar senha" : "Concluir",
        buttonClick: () => this.pushNextRoute(mustChangePassword),
        close: () => this.pushNextRoute(mustChangePassword)
      };
    },
    terms() {
      return generateAuditTerms(this.scenario);
    },
    header() {
      if ([1, 4, 5].includes(this.scenario)) return "Esta solicitação de reembolso será cancelada";
      if ([2, 3].includes(this.scenario)) return "Termo de orientações e declarações";
      return "Cancelamento de solicitação de reembolso";
    },
    subHeader() {
      if ([2, 3].includes(this.scenario))
        return "Por favor, leia as declarações abaixo e realize os procedimentos solicitados.";
      if ([4, 5].includes(this.scenario))
        return "A solicitação de reembolso só é permitida após o pagamento das despesas.";
      return "";
    },
    description() {
      if ([1, 6].includes(this.scenario))
        return "Para cancelarmos a solicitação de reembolso é necessário que você leia e confirme que está de acordo com as declarações abaixo:";
      if ([4, 5].includes(this.scenario)) return "Leia atentamente os termos abaixo para continuar:";
      return "";
    }
  },
  methods: {
    ...mapActions("refund", ["finishAudit", "cancelAudit"]),
    async handleFinishAudit({ obs = null, cb = () => {} }) {
      try {
        await this.$root.$emit("show-loading");
        let auditProtocol;

        if (this.mustCancelProtocol) {
          auditProtocol = await this.cancelAudit({
            ticket: this.ticket,
            protocol: this.refund.protocol,
            obs
          });
        } else {
          auditProtocol = await this.finishAudit({
            obs
          });
        }

        this.protocol = auditProtocol;
        cb();
      } catch (error) {
        this.$toast({
          description: error.message,
          status: "error",
          duration: 10000,
          position: "top"
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    submit() {
      if ([1, 6].includes(this.scenario)) {
        this.showCancellationDetailsModal = true;
      } else {
        this.handleFinishAudit({
          cb: () => {
            this.showFeedbackModal = true;
          }
        });
      }
    },
    handlePendencyModalSubmit(answers) {
      this.handleFinishAudit({
        obs: answers.obs,
        cb: () => {
          this.showCancellationDetailsModal = false;
          this.showFeedbackModal = true;
        }
      });
    },
    pushNextRoute(mustChangePassword) {
      const routerBody = mustChangePassword
        ? {
            name: "change.password",
            params: {
              forceChange: true
            }
          }
        : {
            path: "/refund-new"
          };

      this.$router.push(routerBody);
    }
  }
};
</script>
