<template>
  <div>
    <CBox
      :mt="['0', '24px']"
      :pt="['0', '24px']"
      :borderTop="['none', '0.5px solid']"
      :borderColor="['none', 'var(--colors-light-secondary-divider)']"
    >
      <OmtRow
        mb="17px"
        :mt="['24px', '0px']"
        :mx="['-16px', '0px']"
        :p="['24px 16px 24px', '0px']"
        :backgroundColor="['var(--colors-light-primary-light-gray)', 'white']"
      >
        <CFlex>
          <CBox w="36px">
            <OmtIcons name="money-check-dollar-pen" size="sm" :display="['block', 'none']" />
            <OmtIcons name="money-check-dollar-pen" size="1x" :display="['none', 'block']" />
          </CBox>
          <CBox flex="1">
            <CFlex direction="row" justify="space-between" align-items="center" mb="17px">
              <OmtTypography :as="['h3-bold', 'h1-semi-bold']"> Fatura nº {{ statement.id }} </OmtTypography>
              <OmtTypography :as="['p2', 'p4']" color="var(--colors-light-primary-gray2)">
                {{ statement.date }}
              </OmtTypography>
            </CFlex>
            <CFlex direction="row" justify="space-between" align-items="center">
              <OmtTypography as="p4" color="var(--colors-light-primary-gray2)" :display="['none', 'block']"
                >Valor total coparticipação <omt-bold> {{ statement.value }} </omt-bold>
              </OmtTypography>
              <OmtTypography as="p1" color="var(--colors-light-primary-gray1)" :display="['block', 'none ']"
                >Valor total {{ statement.value }}
              </OmtTypography>
              <div @click="download(statement.downloadUrl)">
                <OmtTypography as="button-small" color="var(--colors-light-primary-navy-blue)" cursor="pointer">
                  Baixar Fatura
                </OmtTypography>
              </div>
            </CFlex>
          </CBox>
        </CFlex>
      </OmtRow>
    </CBox>
    <CBox :display="['block', 'none']">
      <OmtRow mb="16px" v-for="(detail, index) in statement.details" :key="index">
        <CFlex>
          <CBox flex="1" p="0px 32px">
            <CFlex direction="column" mb="8px">
              <OmtTypography as="h3-bold" mb="8px"> {{ capitalize(detail.name) }} </OmtTypography>
              <OmtTypography as="p2" color="var(--colors-light-primary-gray2)" mb="8px">
                {{ capitalize(detail.affiliate) }}</OmtTypography
              >
              <OmtTypography as="p1">{{ capitalize(detail.service) }}</OmtTypography>
            </CFlex>
            <CFlex direction="row" justify="space-between" align-items="center">
              <OmtTypography as="p1">{{ detail.date }}</OmtTypography>
              <OmtTypography as="h3-bold">{{ detail.value }} </OmtTypography>
            </CFlex>
            <CFlex direction="column">
              <omtDivider mt="24px" />
            </CFlex>
          </CBox>
        </CFlex>
      </OmtRow>
    </CBox>
    <CBox :display="['none', 'block']">
      <OmtRow mt="40px" mb="16px">
        <CFlex justifyContent="space-between">
          <CBox w="24%">
            <OmtTypography as="p2" color="var(--colors-light-primary-gray2)"> Nome do Beneficiário </OmtTypography>
          </CBox>
          <CBox w="24%">
            <OmtTypography as="p2" color="var(--colors-light-primary-gray2)">
              Prest. de Serviço Credenciado
            </OmtTypography>
          </CBox>
          <CBox w="24%">
            <OmtTypography as="p2" color="var(--colors-light-primary-gray2)"> Hist. do Serviço </OmtTypography>
          </CBox>
          <CBox w="14%">
            <OmtTypography as="p2" color="var(--colors-light-primary-gray2)"> Data da utilização </OmtTypography>
          </CBox>
          <CBox w="14%">
            <OmtTypography as="p2" color="var(--colors-light-primary-gray2)"> Valor Cop. </OmtTypography>
          </CBox>
        </CFlex>
      </OmtRow>
      <OmtRow mb="16px" v-for="(detail, index) in statement.details" :key="index">
        <CFlex
          justifyContent="space-between"
          :borderBottom="statement.details.length - 1 === index ? 'none' : '0.5px solid'"
          borderColor="var(--colors-light-secondary-divider)"
          pb="16px"
        >
          <CBox w="24%">
            <OmtTypography as="p1" color="var(--colors-light-primary-gray1)">
              {{ capitalize(detail.name) }}
            </OmtTypography>
          </CBox>
          <CBox w="24%">
            <OmtTypography as="p1" color="var(--colors-light-primary-gray1)">
              {{ capitalize(detail.affiliate) }}
            </OmtTypography>
          </CBox>
          <CBox w="24%">
            <OmtTypography as="p1" color="var(--colors-light-primary-gray1)">
              {{ capitalize(detail.service) }}</OmtTypography
            >
          </CBox>
          <CBox w="14%">
            <OmtTypography as="p1" color="var(--colors-light-primary-gray1)"> {{ detail.date }}</OmtTypography>
          </CBox>
          <CBox w="14%">
            <OmtTypography as="p1" color="var(--colors-light-primary-gray1)"> {{ detail.value }}</OmtTypography>
          </CBox>
        </CFlex>
      </OmtRow>
    </CBox>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { capitalize } from "@/utils/utils";

export default {
  data() {
    return {
      statement: ""
    };
  },
  methods: {
    ...mapActions("copay", ["fetchCopayStatementsDetail"]),
    download(url) {
      window.open(url, "_blank");
    },
    capitalize(text) {
      return capitalize(text);
    }
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      const result = await this.fetchCopayStatementsDetail({ id: Number(this.$route.params.id) });
      this.statement = result;
    } catch (error) {
      console.error("copay detail load error", error);
    } finally {
      this.loading = false;
      this.$root.$emit("hide-loading");
    }
  }
};
</script>
