<template>
  <div :class="myClass">
    <input
      class="input"
      v-bind="this.$attrs"
      v-bind:value="value"
      v-bind:placeholder="placeholder"
      v-bind:type="type"
      v-bind:autocomplete="autocomplete"
      v-bind:maxlength="maxlength"
      v-on:input="$emit('input', $event.target.value)"
      v-on:keydown.enter.prevent="onEnter"
      v-on:keydown="onKeydown"
      v-on:keyup="onKeyup"
    />
    <slot></slot>
    <div class="loader" :style="loaderStyle" />
  </div>
</template>
<script>
export default {
  props: {
    hasIcon: {
      type: Boolean,
      default: true
    },
    value: {
      required: true
    },
    type: {
      type: String,
      default: "text"
    },
    maxlength: {
      type: Number
    },
    placeholder: {
      type: String,
      default: ""
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    onEnter: {
      type: Function,
      default: () => {
        return;
      }
    },
    onKeydown: {
      type: Function,
      default: () => {
        return;
      }
    },
    onKeyup: {
      type: Function,
      default: () => {
        return;
      }
    },
    transparent: {
      type: Boolean,
      default: false
    },
    reverse: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    myClass() {
      return `input-box
        ${this.hasIcon ? " hasIcon" : ""}
        ${this.transparent ? " transparent" : ""}
        ${this.reverse ? " reverse" : ""}
        ${this.isLoading ? " loading" : ""}`;
    },
    loaderStyle() {
      return `background: url('${process.env.VUE_APP_BASE_URL + "images/loading.svg"}') no-repeat center center;`;
    }
  }
};
</script>
<style scoped>
.input-box {
  display: flex;
  flex-direction: row;
  flex-flow: row nowrap;
  justify-content: flex-end;
  border: 0;
  width: calc(100% - 4px);
  min-height: 23px;
  padding: 2px;
  vertical-align: text-bottom;
  position: relative;
}

.input-box.loading .loader {
  position: absolute;
  right: -16px;
  height: 80px;
  width: 80px;
  transform: scale(0.5);
  top: -34px;
}

.input-box.reverse {
  flex-flow: row-reverse nowrap;
}

.input-box.transparent {
  background-color: transparent;
  color: inherit;
  padding-bottom: 5px;
}

.light .input-box.transparent {
  border-bottom: 2px solid rgba(0, 0, 0, 0.06);
}

.dark .input-box.transparent {
  color: white;
  border-bottom: 2px solid rgba(255, 255, 255, 0.06);
}

.input {
  font-family: "Open Sans", sans-serif;
  border: 0;
  width: 100%;
  min-height: 23px;
  padding: 0px;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 100%;
}

.big-font-size .input {
  font-size: 150%;
}

.hasIcon .input {
  width: calc(100% - 21px);
  font-size: 12px;
}

.big-font-size .hasIcon .input {
  font-size: 18px;
}

.big-font-size .hasIcon i {
  font-size: 18px;
}

.input-box i {
  margin-top: 7px;
}

.reverse .input {
  padding-left: 10px;
}

.input-box.transparent .input {
  background-color: transparent !important;
  color: inherit !important;
}

.input:hover,
.input:focus {
  outline: none;
}

.input::placeholder {
  opacity: 1;
}

.input::placeholder,
.input:-ms-input-placeholder,
.input::-ms-input-placeholder {
  color: var(--colors-light-primary-gray1);
}

.input.inverse::placeholder,
.input.inverse:-ms-input-placeholder,
.input.inverse::-ms-input-placeholder {
  color: var(--colors-light-primary-gray1);
}

@media only screen and (min-width: 992px) {
  /* Desktop */
  .input:hover,
  .input:focus {
    border-bottom: 1px solid;
  }
}
</style>
