import { render, staticRenderFns } from "./DeleteModal.vue?vue&type=template&id=3782c04d&scoped=true&"
import script from "./DeleteModal.vue?vue&type=script&lang=js&"
export * from "./DeleteModal.vue?vue&type=script&lang=js&"
import style0 from "./DeleteModal.vue?vue&type=style&index=0&id=3782c04d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3782c04d",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CModalBody: require('@chakra-ui/vue').CModalBody, CModalFooter: require('@chakra-ui/vue').CModalFooter, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
