<template>
  <CBox>
    <Header name="Detalhes do atendimento" titleLabel="Detalhes do atendimento" backRoute="/avaliacao-do-prestador" />
    <omint-page-content v-if="!loading">
      <CBox mb="24px">
        <AttendanceCard
          :evaluation="dadosAvaliacao"
          :prestadores="prestadores"
          @confirmEvaluation="handleConfirmValidation"
        />
      </CBox>

      <RatingForm
        v-show="confirmEvaluation === true"
        tipo="600"
        etapa="P"
        :protocolo="idAtendimento"
        rotaDestino="/avaliacao-do-prestador"
        textoAvaliacao="No geral, como você avalia seu atendimento?"
      />
      <template v-if="confirmEvaluation === false">
        <CFlex mb="16px" align="baseline">
          <i class="far fa-comment-alt fa-lg" v-chakra mr="16px"></i>
          <omt-h5> <strong>Deixar comentário</strong> </omt-h5>
        </CFlex>
        <CFlex align="end" direction="column" mb="48px">
          <omt-textarea
            v-chakra
            p="16px !important"
            max-width="calc(100% - 36px)"
            placeholder="Use esse espaço para comentários (opcional)"
            v-bind:value="observation"
            v-on:input="observation = $event"
            rows="6"
            maxlength="250"
          />
          <span>
            <omt-p> {{ observation.length }} /250 </omt-p>
          </span>
        </CFlex>

        <CBox pb="40px">
          <omt-buttons-bar>
            <omt-button
              :onClick="
                () =>
                  this.$router.push({
                    path: '/avaliacao-do-prestador'
                  })
              "
            >
              Voltar
            </omt-button>
            <omt-button
              :colorInverse="true"
              :onClick="
                () => {
                  handleAvaliarAtendimento();
                }
              "
            >
              Enviar
            </omt-button>
          </omt-buttons-bar>
        </CBox>
      </template>
    </omint-page-content>
  </CBox>
</template>

<script>
import Header from "@/components/Molecules/Header";
import AttendanceCard from "@/components/Organisms/ProviderEvaluation/AttendanceCard.vue";
import RatingForm from "@/components/Organisms/RatingForm";
import { CBox, CFlex } from "@chakra-ui/vue";
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "ProviderEvaluationDetails",
  components: { CBox, AttendanceCard, CFlex, Header, RatingForm },
  data() {
    return {
      dadosAvaliacao: {},
      confirmEvaluation: null,
      rating: 0,
      observation: "",
      prestadores: [],
      loading: true
    };
  },
  async mounted() {
    try {
      await this.$root.$emit("show-loading");
      if (!this.evaluationList?.length) {
        await this.fetchAtendimentosAvaliacao();
      }
      if (this.evaluationList?.atendimentosNaoAvaliados?.length && this.idAtendimento) {
        this.dadosAvaliacao = this.evaluationList.atendimentosNaoAvaliados.find(
          a => a.idAtendimento === this.idAtendimento
        );
      }
      if (this.dadosAvaliacao?.idAtendimento) {
        this.prestadores = await this.fetchDetalheAvaliacaoPrestador({
          atendimento: this.dadosAvaliacao.idAtendimento
        });
      }
    } finally {
      if (this.dadosAvaliacao?.idAtendimento === this.idAtendimento) {
        this.loading = false;
        await this.$root.$emit("hide-loading");
      } else {
        this.$router.push("/avaliacao-do-prestador");
      }
    }
  },
  methods: {
    ...mapActions("atendimento", ["fetchAtendimentosAvaliacao", "fetchDetalheAvaliacaoPrestador"]),
    ...mapActions("ticket", ["saveExpRating"]),
    handleConfirmValidation(confirmEvaluation) {
      if (!!this.confirmEvaluation && !confirmEvaluation) {
        this.rating = 0;
        this.observation = "";
      }
      this.confirmEvaluation = confirmEvaluation;
    },
    async handleAvaliarAtendimento() {
      await this.$root.$emit("show-loading");
      try {
        await this.saveExpRating({
          ticket: this.idAtendimento,
          type: 600,
          step: "P",
          acknowledged: this.confirmEvaluation,
          rating: this.confirmEvaluation ? this.rating : null,
          obs: this.confirmEvaluation ? this.observation : null,
          responses: null
        });
        this.$router.push({ path: "/avaliacao-do-prestador" });
      } catch (error) {
        await this.$root.$emit("hide-loading");
        await Swal.fire({
          text: error.message,
          confirmButtonText: "OK",
          icon: "error"
        });
      }
    }
  },
  computed: {
    ...mapState("saude", ["theme"]),
    ...mapState("atendimento", ["evaluationList"]),
    idAtendimento() {
      return this.$route.params.id ?? "0";
    }
  }
};
</script>

<style lang="scss" scoped>
.button {
  border: 1px solid #6f8092 !important;
  width: 50%;

  &:hover {
    opacity: 0.6;
  }

  & + & {
    margin-left: 24px;
  }
}

.vue-star-rating {
  width: 100%;
  justify-content: center;
}
</style>
