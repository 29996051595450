<template>
  <OmtStickedModal @close="$emit('close')" data-cy="feedback-modal">
    <OmtIcons v-bind="modalIconBind" size="2x" mb="32px" />
    <OmtTypography as="h1-bold" mb="32px">
      {{ title }}
    </OmtTypography>

    <CFlex v-if="!!protocol" align="center" mb="24px">
      <OmtIcons name="square-poll-horizontal" mr="12px" />
      <OmtTypography as="h2-bold"> Protocolo: {{ protocol }} </OmtTypography>
    </CFlex>

    <CBox
      v-if="!!cardTitle"
      bg="var(--colors-light-primary-light-gray)"
      p="10px 16px 16px 16px"
      :mb="['64px', '118px']"
    >
      <CFlex mb="14px">
        <OmtIcons :name="cardIcon" mr="16px" />
        <OmtTypography as="h2-bold">
          {{ cardTitle }}
        </OmtTypography>
      </CFlex>
      <template v-if="htmlCardDescription">
        <OmtTypography as="p1" v-html="cardDescription"></OmtTypography>
      </template>
      <template v-else>
        <OmtTypography as="p1">
          {{ cardDescription }}
        </OmtTypography>
      </template>
    </CBox>
    <CBox v-else p="10px 16px 16px 16px" :mb="['64px', '118px']">
      <OmtTypography as="p1" v-for="(description, index) in cardDescriptions" :key="`dsc-${index}`">
        {{ description }}
      </OmtTypography>
    </CBox>

    <OmtButton @click="$emit('buttonClick')" w="100%">
      {{ buttonLabel }}
    </OmtButton>
    <OmtButton v-if="secondButtonLabel" @click="$emit('secondButtonClick')" w="100%" variant="ghost">
      {{ secondButtonLabel }}
    </OmtButton>
  </OmtStickedModal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    protocol: {
      type: String
    },
    modalIconBind: {
      type: Object,
      default: () => ({
        name: "paper-plane-top"
      })
    },
    cardTitle: {
      type: String
    },
    cardDescription: {
      type: String
    },
    cardIcon: {
      type: String,
      default: "bell-on"
    },
    buttonLabel: {
      type: String,
      default: "Concluir"
    },
    secondButtonLabel: {
      type: String,
      default: ""
    }
  },
  computed: {
    htmlCardDescription() {
      if (!this.cardDescription) return false;
      return this.cardDescription.indexOf("<") !== -1;
    },
    cardDescriptions() {
      return this.cardDescription?.split("|") || "";
    }
  }
};
</script>
