<template>
  <CBox
    width="100vw"
    height="100vh"
    background="white"
    position="fixed"
    z-index="var(--zIndices-tooltip)"
    top="0"
    left="0"
    justifyContent="center"
    alignItems="center"
    display="flex"
    flexFlow="column"
  >
    <CBox position="relative" height="48px" width="48px" v-if="loading">
      <OmtIcons
        name="spinner-third"
        size="2xl"
        color="#172972"
        animation="spin 1.3s linear infinite"
        position="absolute"
      />
      <OmtIcons name="circle" size="2xl" color="#172972" opacity="0.4" position="absolute" />
    </CBox>

    <OmtTypography as="h1-bold" mb="24px">{{ title }}</OmtTypography>
    <OmtTypography as="p1" width="240px" textAlign="center">{{ description }}</OmtTypography>
    <CBox width="80%" mt="24px" v-if="progress > 0">
      <c-progress :value="progress" height="7px" has-stripe is-animated />
    </CBox>
  </CBox>
</template>
<script>
/**
 * Loading Component
 *
 * @prop {String} title text from title
 * @prop {String} description text from description
 * @prop {Number} progress default as hidden case show progress use 1 - 100
 * @prop {Boolean} loading default as true case hidden loading use false
 */

export default {
  name: "LoadingModal",
  props: {
    title: {
      type: String,
      default: "Aguarde"
    },
    description: {
      type: String,
      default: "Estamos carregando os dados"
    },
    progress: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
