<template>
  <omint-page>
    <Header name="Consulta de solicitação" backRoute="/" titleLabel="Consulta de solicitação" />
    <omint-page-content>
      <CBox>
        <TableResume :table="addressChanges" title="Alteração de correspondência" iconSrc="/images/omint/local.svg" />
        <TableResume :table="emailsChanges" title="Alteração de e-mail" iconSrc="/images/omint/email.svg" />
        <TableResume
          :table="telChanges"
          title="Alteração de telefone"
          iconSrc="/images/omint/tel.svg"
          :aditionalKeys="[
            {
              key: 'ReceberAviso',
              label: 'Principal'
            }
          ]"
        />

        <ResumeDisclaimer />
      </CBox>
    </omint-page-content>
    <Footer />
  </omint-page>
</template>

<script>
import { CBox } from "@chakra-ui/vue";
import TableResume from "@/components/Organisms/MyContacts/TableResume";
import ResumeDisclaimer from "@/components/Organisms/MyContacts/ResumeDisclaimer.vue";
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";
import { mapActions, mapState } from "vuex";

export default {
  name: "MyContactsPrint",
  props: ["idx"],
  components: { CBox, TableResume, ResumeDisclaimer, Header, Footer },
  computed: mapState({
    addressChanges: state => state.myContacts.changesResume.Enderecos,
    emailsChanges: state => state.myContacts.changesResume.Emails,
    telChanges: state => state.myContacts.changesResume.Telefones
  }),
  async mounted() {
    if (!this.idx) {
      this.$router.push("/");
    }
    try {
      await this.fetchChangesResume({ chave: this.idx });
    } finally {
      setTimeout(() => {
        this.$root.$emit("hide-loading");
      });
    }
  },
  methods: {
    ...mapActions("myContacts", ["fetchChangesResume"])
  }
};
</script>

<style lang="scss" scoped>
.button-group {
  button {
    width: 340px;
  }
  button:first-child {
    border: 1px solid #68788c;
    margin-right: 18px;
  }
}
</style>
