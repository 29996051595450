<script lang="jsx">
import Vue from "vue";
export default Vue.component("OmtLayoutContainer", {
  render() {
    return (
      <OmtGrid
        m={["", "0 auto !important"]}
        {...{
          attrs: this.$attrs
        }}
      >
        {this.$slots.default}
      </OmtGrid>
    );
  }
});
</script>
