<template>
  <omint-page>
    <omint-slider>
      <router-view />
    </omint-slider>

    <Footer />
  </omint-page>
</template>

<script>
import Footer from "@/components/Molecules/Footer";

export default {
  name: "ProviderEvaluation",
  components: {
    Footer
  }
};
</script>
