<template>
  <CBox>
    <CBox v-for="(b, indexB) in permissoes" :key="indexB" w="100%" mt="32px">
      <OmtTypography as="p1-bold">
        {{ b.pessoa }}
      </OmtTypography>
      <CBox v-for="(f, indexF) in b.funcionalidades" :key="`${indexF}-f`" w="100%" mt="24px">
        <OmtTypography as="h3-bold" color="var(--colors-light-primary-gray2)">
          {{ f.tipo || "Informações Médicas" }}
        </OmtTypography>
        <CBox v-for="(p, indexP) in f.permissoes" :key="`${indexP}-f`">
          <omt-divider />
          <CFlex justify="space-between" flex-wrap="wrap" w="100%">
            <OmtTypography as="p1"> {{ p.descricao }} </OmtTypography>
            <OmtTypography v-if="!!p.ativa" as="p1-bold"> Habilitado </OmtTypography>
            <OmtTypography v-else as="p1-bold"> Desabilitado </OmtTypography>
          </CFlex>
        </CBox>
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      if (!this.permissions?.length) {
        await this.fetchPermissions();
      }
    } catch (error) {
      this.$toast({
        description: error.message,
        status: "error",
        duration: 10000,
        position: "bottom-right"
      });
    } finally {
      this.$root.$emit("hide-loading");
    }
  },
  computed: {
    ...mapState("accessManagement", ["permissions"]),
    permissoes() {
      if (!this.permissions?.length) return [];
      return this.permissions.map(p => {
        const tipos = {};
        p.ListaFuncionalidades.forEach(f => {
          if (!tipos[f.Tipo]) {
            tipos[f.Tipo] = [];
          }
          tipos[f.Tipo].push({
            id: f.IdFuncionalidade,
            descricao: f.DsFuncionalidade,
            ativa: f.Ativa,
            original: f.Ativa
          });
        });
        return {
          id: p.IdPermissao,
          vinculo: p.Vinculo,
          pessoa: p.DsPessoa,
          ativa: p.IdPermissao > 0,
          funcionalidades: Object.keys(tipos).map(t => ({
            tipo: t,
            permissoes: tipos[t]
          }))
        };
      });
    }
  },
  methods: {
    ...mapActions("accessManagement", ["fetchPermissions"])
  }
};
</script>
<style scoped></style>
