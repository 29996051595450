<template>
  <omint-page-content>
    <div class="term-box">
      <OmtIcons name="check-circle" size="3x" color="var(--colors-light-support-success)" mb="32px" />
      <OmtTypography as="h1-bold" mb="16px">Cadastro realizado com sucesso!</OmtTypography>

      <CBox borderRadius="8px" backgroundColor="var(--colors-light-primary-light-gray)" p="16px" mb="24px">
        <CFlex>
          <CBox>
            <OmtIcons mr="16px" size="2x" name="mobile" />
          </CBox>
          <OmtTypography as="h2-bold" mb="16px">Seu acesso já está liberado</OmtTypography>
        </CFlex>
        <OmtTypography as="p1">Realize seu login e aproveite todas as facilidades do APP Minha Omint.</OmtTypography>
      </CBox>
    </div>

    <OmtButton
      variant="solid"
      width="100%"
      height="50px"
      border="0.5px solid var(--colors-light-secondary-divider)"
      borderRadius="40px"
      data-cy="button-entrar"
      @click="handleClick()"
      mb="3"
    >
      <OmtTypography as="h1-bold" color="white">Concluir</OmtTypography>
    </OmtButton>
  </omint-page-content>
</template>

<script>
export default {
  name: "Success",
  methods: {
    handleClick: async function () {
      await this.$router.replace({ path: "/" });
    }
  }
};
</script>
<style scoped>
.term-box {
  height: calc(100vh - 180px);
  overflow-y: auto;
}
</style>
