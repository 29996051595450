import Vue from "vue";
import * as api from "@/api/reembolso";
import { ListarBeneficiarioContaCredito } from "@/api/associado";
import * as atendimento from "@/api/atendimento";
import { checkUpdatePermission } from "@/utils/checkReadonlyUtil";

const mapProtocolState = {
  L: {
    name: "released",
    color: "var(--colors-light-support-success)",
    docName: "Recibo/NF"
  },
  D: {
    name: "refused",
    color: "var(--colors-light-support-error)",
    docName: null
  },
  C: {
    name: "canceled",
    color: "var(--colors-light-support-error)",
    docName: null
  },
  P: {
    name: "waitingForRegularization",
    color: "var(--colors-light-support-warning)",
    docName: null
  },
  E: {
    name: "entrada",
    color: "var(--colors-light-primary-dark-blue)",
    docName: null
  },
  A: {
    name: "inAnalysis",
    color: "var(--colors-light-primary-dark-blue)",
    docName: null
  },
  U: {
    name: "audit",
    color: "var(--colors-light-support-error)",
    docName: null
  }
};

const initialState = {
  refunds: [],
  filters: [],
  sorts: ["Mais recentes", "Mais antigos"],
  activedSort: "",
  protocols: [],
  beneficiaries: null,
  addPermission: false,
  options: [{ label: "Últimos 30", value: 0 }],
  pendencies: [],
  terms: "",
  attachmentTypes: {},
  lastFetch: 0,
  maxFiles: 0,
  newRefund: null,
  authorizationId: null,
  audit: {}
};

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  getters: {
    getNewRefundType: context => {
      return context.newRefund?.type;
    },
    hasActivedFilter: context => {
      const activedFilters = context.filters.filter(filter => !!filter.isActive);
      return activedFilters.length > 0;
    },
    beneficiariesList: context =>
      Object.keys(context.beneficiaries ?? []).map(bName => ({
        label: bName,
        value: bName
      })),
    filteredRefunds: context => {
      let sortedRefunds = [];

      const activedFilters = context.filters.filter(filter => filter.isActive);
      if (activedFilters.length) {
        activedFilters.forEach(filter => {
          sortedRefunds.push(
            ...context.protocols.filter(
              refund => !!refund.resumeDocTypes.find(type => type.description === filter.label)
            )
          );
        });
      } else {
        sortedRefunds = JSON.parse(JSON.stringify(context.protocols));
      }

      const sorts = {
        dateToNum(d) {
          d = d.split("/");
          return new Date(d[2], Number(d[1]) - 1, d[0]).getTime();
        },
        "Mais recentes"() {
          sortedRefunds.sort((a, b) => {
            return this.dateToNum(b.createdAt) - this.dateToNum(a.createdAt);
          });
        },
        "Mais antigos"() {
          sortedRefunds.sort((a, b) => {
            return this.dateToNum(a.createdAt) - this.dateToNum(b.createdAt);
          });
        }
      };

      if (context.activedSort) sorts[context.activedSort]();

      return sortedRefunds;
    }
  },
  actions: {
    async fetchAddPermission(context) {
      const infos = await api.ValidarBeneficiarioReembolsoDigital({
        vinculo: context.rootState.saude.vinculo
      });
      let refundNewPermission = false;
      if (infos && infos.Data && infos.Data.length > 0) {
        refundNewPermission = infos.Data[0].AtReembolsoDigital === 1;
      }
      context.commit("SET_ADD_PERMISSION", refundNewPermission);
    },
    async fetchBeneficiaries(context) {
      const resp = await ListarBeneficiarioContaCredito({
        vinculo: context.rootState.saude.vinculo,
        servico: 0
      });
      resp.Data.forEach(r => context.commit("SET_BENEFICIARY", r));
    },
    async fetchDetail(context, refundId) {
      if (context.state.refunds.length === 0) {
        await context.dispatch("fetchList", true);
      }
      const id = Number.parseInt(refundId);
      const refund = context.state.refunds.filter(r => r.IdReembolso === id);
      if (refund.length > 0) {
        return refund[0];
      }
    },
    async fetchDetailHistory(context, refundId) {
      return api.HistoricoReembolso({
        vinculo: context.rootState.saude.vinculo,
        reembolso: refundId
      });
    },
    async fetchMaxFiles(context) {
      if (context.state.maxFiles > 0) return context.state.maxFiles;

      const response = await api.ObterLimiteArquivosPedido({
        vinculo: context.rootState.saude.vinculo
      });
      if (response?.Data > 0) {
        context.commit("SET_MAX_FILES", response.Data);
        return response.Data;
      }
      return 6; // padrão para pedidos de reembolso
    },
    async fetchProtocols(context, options) {
      const protocolo = context.state.protocols.length > 0 ? options?.protocol ?? "" : "";
      const chamado = context.state.protocols.length > 0 ? options?.ticket ?? 0 : 0;

      const resolveProtocolData = data => ({
        protocol: data.Protocolo,
        ticket: data.Chamado,
        createdAt: data.DtInicio,
        canEvaluate: data.NotaAvaliacao === null,
        resumeDocTypes: data.Resumo.map(t => ({
          type: t.Tipo,
          description: t.Dsc,
          color: mapProtocolState[t.Tipo]?.color ?? "var(--colors-light-primary-dark-blue)",
          total:
            (t.Tipo === "A" && t.Dsc === "Em triagem") ||
            t.Tipo === "E" ||
            t.Tipo === "L" ||
            (t.Tipo === "A" && t.Val === "R$ 0,00")
              ? null
              : t.Val,
          count: t.Qtd
        }))
      });
      const response = await api.ObterProtocolos({
        vinculo: context.rootState.saude.vinculo,
        periodo: 3,
        protocolo,
        chamado
      });
      if (response?.Data?.length) {
        let protocols = [];
        if (chamado > 0 || protocolo !== "") {
          context.commit("SET_NEW_REFUND", null);
          // adicionar o que falta, atualiza o que existe
          let novoProtocolo = true;
          protocols = context.state.protocols.map(p => {
            if (p.ticket === chamado || p.protocol === protocolo) {
              novoProtocolo = false;
              return resolveProtocolData(response.Data[0]);
            } else {
              return p;
            }
          });
          if (novoProtocolo) {
            protocols = [resolveProtocolData(response.Data[0])].concat(protocols);
          }
        } else {
          protocols = response.Data.map(p => resolveProtocolData(p));
        }
        context.commit("SET_PROTOCOLS", protocols);
      }
    },
    async fetchProtocolDetail(context, { protocol }) {
      const response = await api.DetalheProtocolo({
        vinculo: context.rootState.saude.vinculo,
        protocolo: protocol
      });
      const resolveDocuments = (situacao, documento, ehFiscal, temImagem) => {
        const docs = [];
        if (!documento) return docs;
        docs.push({
          id: documento,
          label: ehFiscal ? mapProtocolState[situacao]?.docName ?? "Recibo/NF" : "Documento",
          source: temImagem
            ? api.ObterDocumento({
                vinculo: context.rootState.saude.vinculo,
                documento
              })
            : null
        });
        return docs;
      };
      const resolveDemonstrativo = (situacao, descricaoSituacao, protocolo, documento) => {
        if (situacao === "L" && descricaoSituacao?.toLowerCase() === "crédito liberado") {
          return {
            id: documento,
            label: "Demonstrativo de pagamento",
            source: api.ObterDemonstrativo({
              vinculo: context.rootState.saude.vinculo,
              protocolo,
              documento
            })
          };
        }
        return null;
      };
      const obsHistory = obs => {
        if (!obs?.length) return [];
        return obs.map(o => ({
          Data: o.Data,
          Obs: o.Obs ? o.Obs.split("\n") : []
        }));
      };
      const respData = response.Data;
      respData.Situacao = respData.Situacao.map(s => ({
        ...s,
        emTriagem: (s.Tipo === "A" && s.Dsc === "Em triagem") || s.Tipo === "E"
      }));
      respData.showValues = !respData.Situacao.find(s => s.emTriagem);
      return {
        protocol: respData.Protocolo,
        obsProtocol: respData.ObservacaoProtocolo,
        obsHistory: obsHistory(respData.Observacoes),
        beneficiary: respData.Beneficiario,
        createdAt: respData.DtInicio,
        totalAmount: respData.Total,
        showValues: respData.showValues,
        docsLabel: respData.Situacao.find(s => s.emTriagem || s.Documentos.find(d => !d.DocFiscal))
          ? "Documento(s)"
          : "Documentos Fiscais",
        accountDetails: {
          name: respData.Favorecido,
          account: respData.Conta,
          agency: respData.Agencia,
          bank: respData.Banco
        },
        refunds: respData.Situacao.map(s => ({
          status: s.Tipo,
          description: s.Dsc,
          color: mapProtocolState[s.Tipo]?.color ?? "var(--colors-light-primary-dark-blue)",
          total: s.Tipo !== "L" && s.Tipo !== "D" && !s.Documentos.find(d => d.DocFiscal) ? null : s.Total,
          showValues: !(s.emTriagem || (s.Tipo !== "L" && s.Tipo !== "D" && !s.Documentos.find(d => d.DocFiscal))),
          pictures: s.emTriagem ? s.Documentos.map(d => ({ id: d.Documento })) ?? [] : [],
          list: s.Chamados.map(c => ({
            ticket: c.Chamado,
            dueDate: c.DtLimite,
            pendencies: c.Pendencias,
            devolutionGroups: c.GruposDevolucao,
            docs: c.Docs.map(d => ({
              ticket: d.Chamado,
              provider: d.Prestador,
              createdAt: d.DocFiscal ? d.DtAtendimento : null,
              creditedAt: d.DocFiscal ? d.DtLiberacao : null,
              requestedValue: d.DocFiscal ? d.VlApresentado : null,
              refundedValue: d.DocFiscal ? d.VlReembolsoTotalProtocolo : null,
              dscCompl: d.DocFiscal ? null : "Documento complementar",
              reasonsPendencieRefused: d.Motivos,
              receipts: resolveDocuments(s.Tipo, d.Documento, d.DocFiscal, d.DocImagem),
              demonstrativo: resolveDemonstrativo(s.Tipo, s.Dsc, respData.Protocolo, d.Documento)
            })).sort((a, b) => {
              if (a.dscCompl && !b.dscCompl) return 1;
              if (!a.dscCompl && b.dscCompl) return -1;
              return 0;
            })
          }))
        })),
        pictures: respData.Imagens.map(i => ({
          id: i
        }))
      };
    },
    async fetchProtocolDetailImage(context, { documento, original }) {
      return await api.ObterImagem({
        vinculo: context.rootState.saude.vinculo,
        documento,
        original
      });
    },
    async fetchTerms(context) {
      await context.dispatch("fetchAttachmentTypes");
      const refundTermId = 1;
      const refundTerms = await atendimento.OperacaoPerfilOmintTermoAceiteWeb({
        vinculo: context.rootState.saude.vinculo,
        termo: refundTermId
      });
      if (refundTerms.Data) {
        context.commit("SET_TERMS", refundTerms.Data);
      }
    },
    async fetchAttachmentTypes(context) {
      const response = await api.ObterTiposAnexos({
        vinculo: context.rootState.saude.vinculo
      });
      if (response?.Data) {
        context.commit("SET_ATTACHMENT_TYPES", response.Data);
      }
    },
    async getDocument(context, infos) {
      return await atendimento.ObterDocumento({
        vinculo: context.rootState.saude.vinculo,
        chamado: infos.ticket,
        documento: infos.document
      });
    },
    updateRefundData(context) {
      // monta as opções de filtro
      const options = context.state.refunds.map(r => {
        return new Date(r.DataEntrada).getFullYear();
      });
      const filter = options.filter((d, i) => options.indexOf(d) === i);
      const ops = filter.map(o => ({ label: `${o}`, value: o }));
      context.commit("SET_OPTIONS", ops);
    },
    returnRefundTicket: async (context, { protocolo, observacao, arquivos }) => {
      checkUpdatePermission();
      return await atendimento.RetornoPendenciaReembolso({
        vinculo: context.rootState.saude.vinculo,
        protocolo,
        observacao,
        arquivos
      });
    },
    createRefundTicket: async (context, { arquivos, beneficiario, contacorrente, observacao }) => {
      checkUpdatePermission();
      return await atendimento.NovaSolicitacaoReembolso({
        vinculo: context.rootState.saude.vinculo,
        arquivos,
        beneficiario,
        contacorrente,
        observacao,
        autenticacao: context.state.authorizationId
      });
    },
    checkRefundTicket: async (context, { beneficiario }) => {
      return await atendimento.ConsultaChamadoReembolsoAtual({
        vinculo: context.rootState.saude.vinculo,
        beneficiario
      });
    },
    fetchAuditQuestion: async (context, { ticket, protocol, questionTypeId }) => {
      const audit =
        context.state.audit?.ticket === ticket
          ? JSON.parse(JSON.stringify(context.state.audit))
          : {
              id: 0,
              ticket,
              protocol,
              cancel: false,
              files: false,
              finish: false
            };
      if (audit[questionTypeId]) return;
      try {
        const response = await api.ObterPerguntaAuditoria({
          vinculo: context.rootState.saude.vinculo,
          autenticacao: context.state.authorizationId,
          chamado: ticket,
          protocolo: protocol,
          pergunta: questionTypeId
        });
        audit[questionTypeId] = {
          text: response.Data.DsPergunta,
          options: response.Data.Opcoes.map(o => ({ id: o.Id, label: o.Texto })),
          response: 0
        };
        context.commit("SET_AUDIT", audit);
      } catch (error) {
        throw new Error(error.message);
      }
    },
    saveAuditResponse: async (context, { questionTypeId, option }) => {
      checkUpdatePermission();
      try {
        const response = await api.ResponderAuditoria({
          vinculo: context.rootState.saude.vinculo,
          autenticacao: context.state.authorizationId,
          chamado: context.state.audit.ticket,
          protocolo: context.state.audit.protocol,
          auditoria: context.state.audit.id,
          pergunta: questionTypeId,
          resposta: option
        });
        const audit = JSON.parse(JSON.stringify(context.state.audit));
        const data = response.Data;
        audit.id = data.Id;
        audit.cancel = data.CancelarProtocolo;
        audit.files = data.AnexarComplementos;
        audit.finish = data.EncerrarFluxo;
        audit[questionTypeId].response = option;
        if (!audit.finish) {
          audit[data.IdPergunta] = {
            text: data.DsPergunta,
            options: data.Opcoes.map(o => ({ id: o.Id, label: o.Texto }))
          };
        }
        context.commit("SET_AUDIT", audit);
        return data;
      } catch (error) {
        throw new Error(error.message);
      }
    },
    cancelAudit: async (context, { ticket, protocol, obs }) => {
      checkUpdatePermission();
      try {
        const response = await api.EncerrarAuditoria({
          vinculo: context.rootState.saude.vinculo,
          autenticacao: context.state.authorizationId,
          chamado: ticket,
          protocolo: protocol,
          auditoria: 0,
          anexos: [],
          observacao: obs,
          solicitacaoCancelamento: true
        });
        return response.Data.ProtocoloANS;
      } catch (error) {
        throw new Error(error.message);
      }
    },
    finishAudit: async (context, { files, obs }) => {
      checkUpdatePermission();
      try {
        const response = await api.EncerrarAuditoria({
          vinculo: context.rootState.saude.vinculo,
          autenticacao: context.state.authorizationId,
          chamado: context.state.audit.ticket,
          protocolo: context.state.audit.protocol,
          auditoria: context.state.audit.id,
          anexos: files,
          observacao: obs,
          solicitacaoCancelamento: false
        });
        return response.Data.ProtocoloANS;
      } catch (error) {
        throw new Error(error.message);
      }
    }
  },
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    SET_ADD_PERMISSION: (state, permission) => Vue.set(state, "addPermission", permission),
    SET_BENEFICIARY: (state, ben) => {
      if (ben === undefined) return;
      Vue.set(state, "beneficiaries", { ...state.beneficiaries, [ben.Nome]: ben });
    },
    SET_OPTIONS: (state, opts) => Vue.set(state, "options", [{ label: "Últimos 30", value: 0 }, ...opts]),
    SET_PROTOCOLS: (state, protocols) => {
      Vue.set(state, "protocols", protocols);

      const filters = [];
      protocols.forEach(r => r.resumeDocTypes.forEach(t => filters.push(t.description)));

      Vue.set(
        state,
        "filters",
        [...new Set(filters)].sort().map(f => ({
          label: f,
          isActive: state.filters.find(current => current.label === f)?.isActive ?? false
        }))
      );
    },
    SET_PENDENCIES: (state, pendencies) => Vue.set(state, "pendencies", pendencies),
    SET_REFUNDS: (state, refunds) => {
      let cache = {};
      // retira Ids duplicados
      const rfs = refunds.filter(r => {
        if (cache[r.IdReembolso]) {
          return false;
        }
        cache[r.IdReembolso] = true;
        return true;
      });

      Vue.set(state, "refunds", rfs);
    },
    SET_TERMS: (state, terms) => Vue.set(state, "terms", terms),
    SET_ATTACHMENT_TYPES: (state, attachmentTypes) => Vue.set(state, "attachmentTypes", attachmentTypes),
    SET_LAST_FETCH: (state, ticks) => Vue.set(state, "lastFetch", ticks),
    SET_MAX_FILES: (state, maxFiles) => Vue.set(state, "maxFiles", maxFiles),
    SET_NEW_REFUND: (state, newRefund) => Vue.set(state, "newRefund", newRefund),
    SET_ACTIVED_SORT: (state, activedSort) => Vue.set(state, "activedSort", activedSort),
    SET_FILTERS: (state, filters) => Vue.set(state, "filters", filters),
    SET_AUTHORIZATION_ID: (state, authorizationId) => Vue.set(state, "authorizationId", authorizationId),
    SET_AUDIT: (state, audit) => Vue.set(state, "audit", audit)
  }
};
