<template>
  <OmtGrid>
    <OmtRow mb="24px">
      <OmtTypography as="h1-bold"> E-mail </OmtTypography>
    </OmtRow>

    <OmtRow mb="24px">
      <OmtTypography as="h3-semi-bold">
        Utilizado para o envio de comunicações sobre o seu plano, com exceção da fatura digital. O cadastro do e-mail é
        obrigatório.
      </OmtTypography>
    </OmtRow>

    <OmtRow>
      <OmtCard box-shadow="sm" border-color="transparent">
        <OmtIcons
          mb="16px"
          box-shadow="sm"
          name="envelope"
          border-radius="50%"
          height="32px"
          width="32px"
          display="flex"
          justify-content="center"
          align-items="center"
          size="lg"
        />

        <OmtCard box-shadow="sm" border-color="transparent" mb="16px">
          <OmtTypography as="h3-bold" mb="16px"> E-mail </OmtTypography>

          <omt-input :haslabel="true" placeholder="Digite seu Email" v-model="$v.formEmails.Pessoal.$model" />

          <CBox v-if="showFaturaField" mt="24px">
            <OmtTypography as="h3-bold" mb="8px"> E-Mail para envio da Fatura (obrigatório): </OmtTypography>
            <OmtTypography as="p2" mb="16px"> Utilizado para envio da sua Fatura Digital </OmtTypography>

            <omt-input :haslabel="true" placeholder="Digite seu Email" v-model="$v.formEmails.Fatura.$model" />
          </CBox>
        </OmtCard>

        <omt-button :style="{ width: '100%' }" :onClick="submit" :colorInverse="true" :disabled="!canSubmit">
          Continuar
        </omt-button>
      </OmtCard>
    </OmtRow>
  </OmtGrid>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { mapState, mapMutations } from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      formEmails: {
        Fatura: "",
        Pessoal: ""
      },
      infoModalIsOpen: false,
      showTokenModal: false
    };
  },
  validations: {
    formEmails: {
      Fatura: {
        required,
        email
      },
      Pessoal: {
        required,
        email
      }
    }
  },
  async mounted() {
    if (this.emails?.Pessoal || this.emails.Fatura) {
      this.formEmails = _.cloneDeep(this.emails);
    }
    if (this.existEmailChangeRequest) this.handleOnExistChangeRequest();
    this.$v.$touch();
  },
  methods: {
    ...mapMutations("myContacts", ["SET_NEW_STATE"]),
    submit() {
      this.SET_NEW_STATE({ key: "newEmails", value: this.formEmails });
      this.$router.push({
        path: "/meus-contatos"
      });
    }
  },
  computed: {
    ...mapState({
      emails: state => state.myContacts.newEmails || state.myContacts.resumeContacts.Emails,
      showFaturaField: state => !state.myContacts.resumeContacts.Empresarial
    }),
    canSubmit() {
      if (this.formEmails?.Pessoal === this.emails?.Pessoal) {
        return false;
      }
      if (this.showFaturaField) {
        if (this.formEmails?.Fatura === this.emails?.Fatura) {
          return false;
        }
      }

      if (this.showFaturaField) {
        return !this.$v.formEmails.Pessoal.$error && !this.$v.formEmails.Fatura.$error;
      } else {
        return !this.$v.formEmails.Pessoal.$error;
      }
    }
  },
  watch: {
    emails(emails) {
      this.formEmails = _.cloneDeep(emails);
    }
  }
};
</script>
