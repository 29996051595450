var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CTextarea',_vm._b({attrs:{"value":_vm.value,"maxlength":this.maxLength},on:{"change":_vm.handleChange}},'CTextarea',Object.assign({}, {height: '160px',
    padding: '12px',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '400',
    _focus: {
      borderColor: 'inherit',
      boxShadow: 'none'
    }},
    _vm.$attrs),false))}
var staticRenderFns = []

export { render, staticRenderFns }