<template>
  <omint-page>
    <Header name="Gestão de acessos" titleLabel="Minhas Informações" backRoute="/" />
    <router-view />
    <Footer />
  </omint-page>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Header from "@/components/Molecules/Header/index.vue";
import Footer from "@/components/Molecules/Footer/index.vue";

export default {
  name: "MyContacts",
  components: { Footer, Header },
  mounted() {
    this.initPage();
  },
  data() {
    return {
      infoModalIsOpen: false
    };
  },
  methods: {
    ...mapActions("myContacts", ["validateActiveTicket", "fetchResumeContacts"]),
    async initPage() {
      try {
        this.$root.$emit("show-loading");
        await Promise.all([this.fetchResumeContacts(), this.validateActiveTicket()]);
      } catch (error) {
        Swal.fire({
          title: "Erro",
          text: error,
          confirmButtonText: "OK",
          icon: "error"
        });
      } finally {
        this.$root.$emit("hide-loading");
      }
    }
  }
};
</script>
