<template>
  <OmtLayoutContainer>
    <OmtRow mb="16px">
      <OmtTypography as="h1-bold"> Selecione no máximo 4 laboratórios </OmtTypography>
    </OmtRow>

    <OmtRow mb="24px">
      <OmtInput
        variant="filled"
        iconRight="search"
        placeholder="Busque pelo nome do laboratório"
        class="input-search-laboratories"
        v-model="search"
        :iconRightClick="onSearch"
        v-on:keydown.enter.prevent="onSearch"
      />
    </OmtRow>

    <OmtRow stack v-if="address.infos" mb="16px">
      <CFlex class="rules-card" flex-direction="column">
        <OmtTypography as="h2-bold" mb="14px"> Serão exibidos os laboratórios que: </OmtTypography>
        <CFlex>
          <OmtIcons name="check" mr="8px" color="#00C37D" />
          <OmtTypography as="p1" color="var(--colors-light-primary-gray1)">
            Possuem a
            <strong> cobertura do seu plano </strong>
          </OmtTypography>
        </CFlex>
        <CFlex>
          <OmtIcons name="check" mr="8px" color="#00C37D" />
          <OmtTypography as="p1" color="var(--colors-light-primary-gray1)">
            Realizem
            <strong> todos os exames </strong> adicionados
          </OmtTypography>
        </CFlex>
        <template v-if="isSearching">
          <CFlex>
            <OmtIcons name="check" mr="8px" color="#00C37D" />
            <OmtTypography as="p1" color="var(--colors-light-primary-gray)"> Sem limite de distância </OmtTypography>
          </CFlex>
          <CFlex>
            <OmtIcons name="check" mr="8px" color="#00C37D" />
            <OmtTypography as="p1" color="var(--colors-light-primary-gray)">
              CEP
              <strong> {{ address.infos.CEP }} </strong>
              <br />
              {{ address.infos.Endereco }}, {{ address.infos.UF }}
            </OmtTypography>
          </CFlex>
        </template>
        <CFlex v-else>
          <OmtIcons name="check" mr="8px" color="#00C37D" />
          <OmtTypography as="p1" color="var(--colors-light-primary-gray)">
            Estejam até 20km do seu CEP
            <strong> {{ address.infos.CEP }} </strong>
            <br />
            {{ address.infos.Endereco }}, {{ address.infos.UF }}
          </OmtTypography>
        </CFlex>
      </CFlex>
    </OmtRow>

    <OmtRow mb="16px">
      <OmtTypography as="h2-bold"> Laboratórios </OmtTypography>
    </OmtRow>
    <OmtRow stack mb="16px" v-if="searchedLaboratories.length === 0">
      <CFlex>
        <OmtIcons name="circle-exclamation" size="1x" mr="8px" />
        <OmtTypography as="p1" color="var(--colors-light-primary-gray1)">
          Não localizamos laboratórios que atendam aos critérios acima.
        </OmtTypography>
      </CFlex>
    </OmtRow>

    <OmtRow class="full-view" stack>
      <CBox v-for="(lab, index) in searchedLaboratories" :key="index">
        <OmtDivider v-if="index !== 0" w="calc(100% - 32px) !important" m="0 auto !important" />
        <CFlex
          :opacity="!canSelectLab && !lab.isActive ? '0.6' : '1'"
          :bg="lab.isActive ? 'var(--colors-light-primary-light-gray)' : ''"
          p="16px"
          justify-content="space-between"
        >
          <CFlex w="100%">
            <CImage :src="lab.logo" borderRadius="4px" h="24px" w="24px" mr="16px" />
            <CBox>
              <OmtTypography as="h3-bold" mb="6px"> {{ lab.name }} </OmtTypography>
              <OmtTypography as="p2" color="var(--colors-light-primary-gray2)">
                {{ lab.address }}
              </OmtTypography>
            </CBox>
          </CFlex>
          <CFlex align-items="flex-start">
            <OmtTypography as="p2" color="var(--colors-light-primary-gray2)" mr="24px">
              {{ lab.distance }}
            </OmtTypography>
            <OmtCheckbox
              :is-checked="lab.isActive"
              :is-disabled="!canSelectLab && !lab.isActive"
              @change="selectLaboratory(lab)"
            />
          </CFlex>
        </CFlex>
      </CBox>
    </OmtRow>

    <FixedButtonRow>
      <ButtonCounter
        :width="['', '30% !important']"
        :count="selectedLabsCount"
        :disabled="!selectedLabsCount"
        @click="submit"
        label="Gerar simulação"
      />
    </FixedButtonRow>
  </OmtLayoutContainer>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ButtonCounter from "@/components/Molecules/ButtonCounter";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

export default {
  components: {
    ButtonCounter,
    FixedButtonRow
  },
  data() {
    return {
      isSearching: false,
      search: ""
    };
  },
  async mounted() {
    if (!this.selectedExams.length) {
      this.$router.push("/copay/simulations/create/review");
      return;
    }
    try {
      this.$root.$emit("show-loading");
      await this.fetchBaseAddress({ type: "R" });
      await this.fetchMostSearchedLaboratories();
    } catch (error) {
      console.error("copay labs error", error);
    } finally {
      this.$root.$emit("hide-loading");
    }
  },
  computed: {
    ...mapState("copay", [
      "address",
      "selectedExams",
      "mostSearchedLaboratoriesTerms",
      "selectedLaboratories",
      "searchedLaboratories"
    ]),
    terms() {
      if (!this.mostSearchedLaboratoriesTerms?.length) return [];
      return this.mostSearchedLaboratoriesTerms.map(t => ({
        label: t,
        isActive: (t === "Todos" && !this.isSearching) || t === this.search
      }));
    },
    canSelectLab() {
      return this.selectedLaboratories.length < 4;
    },
    selectedLabsCount() {
      return this.selectedLaboratories?.length ?? 0;
    }
  },
  methods: {
    ...mapActions("copay", [
      "fetchMostSearchedLaboratories",
      "searchLaboratory",
      "triggerLaboratory",
      "fetchBaseAddress",
      "submitSimulation"
    ]),
    selectLaboratory(lab) {
      const isActive = !lab.isActive;
      this.triggerLaboratory({ isActive, lab });

      if (isActive) {
        this.$toast({
          description: "Laboratório selecionado com sucesso",
          status: "success",
          duration: 2000,
          position: "top"
        });
      }
    },
    async onSearch() {
      try {
        this.$root.$emit("show-loading");
        this.isSearching = this.search !== "";
        await this.searchLaboratory({ search: this.search });
      } catch (error) {
        console.error("copay exam error", error);
      } finally {
        this.$root.$emit("hide-loading");
      }
    },
    async submit() {
      try {
        this.$root.$emit("show-loading");
        const erroMsg = await this.submitSimulation();
        if (erroMsg) {
          this.$toast({
            description: erroMsg,
            status: "error",
            duration: 10000,
            position: "top"
          });
          return;
        }
        this.$root.$emit("finish-simulation");
        this.$router.push({ path: "/copay/simulations/view" });
        this.$root.$emit("hide-back-button");
      } catch (error) {
        console.error(error);
      } finally {
        this.$root.$emit("hide-loading");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.full-view {
  margin-left: -16px;
  min-width: calc(100% + 32px);
}

.input-search-laboratories ::v-deep input {
  font-weight: 400;
}

.input-search-laboratories ::v-deep svg {
  font-size: 16px;
}

.rules-card {
  background-color: #f6f6f9;
  padding: 16px;
  border-radius: 8px;
}

@media screen and (min-width: 1024px) {
  .full-view {
    margin-left: -36px;
    min-width: calc(100% + 72px);
  }
}

.full-view-swiper {
  ::v-deep .swiper {
    padding: 0 16px;
  }

  ::v-deep .swiper-slide {
    width: auto;
  }

  button {
    pointer-events: none;
  }
}
</style>
