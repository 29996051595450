<template>
  <CBox :pt="['24px', '69px']" mb="138px" data-cy="sent-pendency">
    <OmtIcons name="paper-plane-top" size="2x" mb="32px" />
    <OmtTypography as="h1-bold" mb="32px">
      Resposta enviada <br />
      com sucesso!
    </OmtTypography>
    <CFlex align="center" mb="32px">
      <OmtIcons name="square-poll-horizontal" mr="12px" />
      <OmtTypography as="h2-bold"> Protocolo: {{ protocol }} </OmtTypography>
    </CFlex>

    <CBox bg="var(--colors-light-primary-light-gray)" p="10px 16px 16px 16px">
      <CFlex mb="16px">
        <OmtIcons name="bell-on" mr="16px" />
        <OmtTypography v-if="protocolTitle" as="h2-bold">
          {{ protocolTitle }}
        </OmtTypography>
        <OmtTypography v-else as="h2-bold">
          Informações foram enviadas <br />
          para análise
        </OmtTypography>
      </CFlex>
      <OmtTypography as="p1" mb="8px"> {{ protocolMainTip }}. </OmtTypography>
      <OmtTypography v-if="protocolSubTip" as="p1"> {{ protocolSubTip }}. </OmtTypography>
    </CBox>
  </CBox>
</template>

<script>
export default {
  props: {
    protocol: {
      type: String
    },
    protocolTitle: {
      type: String
    },
    protocolMainTip: {
      type: String
    },
    protocolSubTip: {
      type: String
    }
  }
};
</script>
