const baseURL = `${process.env.VUE_APP_SERVER_URL}/VideoConferencia`;

export async function GravaAcessos({ vinculo, idSalaPessoa, tpPessoa }) {
  return await window.$axios
    .post({
      url: `${baseURL}/GravaAcessos`,
      query: {
        vinculo,
        idSalaPessoa,
        tpPessoa
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("GravaAcessos", error.message);
      return error;
    });
}

export async function ObterToken({ vinculo, idChamado, tpPessoa }) {
  return await window.$axios
    .get({
      url: `${baseURL}/ObterToken`,
      query: {
        vinculo,
        idChamado,
        tpPessoa
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterToken", error.message);
      return error;
    });
}

export async function ObterPessoasOnline({ vinculo, idSalaPessoa }) {
  return await window.$axios
    .get({
      url: `${baseURL}/ObterPessoasOnline`,
      query: {
        vinculo,
        idSalaPessoa
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterPessoasOnline", error.message);
      return error;
    });
}

export async function ObterImagemProfile({ vinculo, pessoa }) {
  return await window.$axios
    .get({
      url: `${baseURL}/ObterImagemProfile`,
      query: {
        vinculo,
        pessoa
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterImagemProfile", error.message);
      return error;
    });
}
