<template>
  <OmtGrid>
    <OmtRow :mb="['24px', '32px']">
      <OmtTypography :as="['h1-bold', 'h4-bold']"> Cadastro administradores </OmtTypography>
    </OmtRow>

    <OmtRow :mb="['16px', '24px']">
      <OmtTypography :as="['p1', 'p3']">
        Este serviço destina-se exclusivamente ao
        <b>cadastro e nomeação de um terceiro como administrador de seu contrato.</b>
      </OmtTypography>
    </OmtRow>

    <OmtRow :mb="['24px', '48px']">
      <CFlex bg="var(--colors-light-primary-light-gray)" :p="['16px', '20px 24px 24px 24px']" border-radius="8px">
        <OmtIcons
          mr="16px"
          name="circle-exclamation"
          color="var(--colors-light-support-warning)"
          :font-size="['16px', '21px']"
          size="inherit"
        />
        <OmtTypography :as="['p1', 'p3']">
          <b>Importante:</b> ao cadastrar um administrador, você estará lhe concedendo poderes para realizar transações
          e visualizar suas informações confidenciais e de seus beneficiários.
        </OmtTypography>
      </CFlex>
    </OmtRow>

    <template>
      <OmtRow :mb="['32px', '24px']">
        <OmtTypography :as="['h2-bold', 'h1-semi-bold']"> Informações do novo administrador </OmtTypography>
      </OmtRow>

      <OmtRow mb="24px">
        <CBox>
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> Nome Completo </OmtTypography>
          <OmtInput
            :disabled="readonly"
            v-model="form.name"
            height="42px"
            maxlength="150"
            :isInvalid="$v.form.name.$invalid"
          />
        </CBox>
      </OmtRow>

      <OmtRow :desktopEnd="6" mb="24px">
        <CBox>
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> CPF </OmtTypography>
          <OmtInput
            :disabled="readonly || editMode"
            v-model="form.cpf"
            height="42px"
            v-mask="'###.###.###-##'"
            :isInvalid="$v.form.cpf.$invalid"
          />
        </CBox>
      </OmtRow>
      <OmtRow :desktopStart="7" mb="24px">
        <CFlex flex-direction="column" justify-content="space-between" :min-height="['72px', '62px']">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">
            O administrador é estrangeiro?
          </OmtTypography>
          <CFlex>
            <OmtButton
              :disabled="readonly"
              :height="['42px', '32px']"
              max-width="154px"
              :variant="form.foreign ? 'solid' : 'outline'"
              width="50%"
              border-color="var(--colors-light-secondary-divider)"
              mr="16px"
              @click="form.foreign = true"
            >
              <OmtTypography
                :color="form.foreign ? 'white' : 'var(--colors-light-primary-gray1)'"
                :as="['h3-bold', 'h2-bold']"
              >
                Sim
              </OmtTypography>
            </OmtButton>
            <OmtButton
              :disabled="readonly"
              :height="['42px', '32px']"
              max-width="154px"
              width="50%"
              @click="form.foreign = false"
              :variant="!form.foreign ? 'solid' : 'outline'"
            >
              <OmtTypography
                :color="!form.foreign ? 'white' : 'var(--colors-light-primary-gray1)'"
                :as="['h3-bold', 'h2-bold']"
              >
                Não
              </OmtTypography>
            </OmtButton>
          </CFlex>
        </CFlex>
      </OmtRow>

      <OmtRow :desktopEnd="6" mb="24px">
        <CBox>
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">
            {{ form.foreign ? "RNE" : "RG" }}
          </OmtTypography>
          <OmtInput
            :disabled="readonly"
            v-model="form.id"
            height="42px"
            maxlength="30"
            :isInvalid="$v.form.id.$invalid"
          />
        </CBox>
      </OmtRow>

      <OmtRow :desktopStart="7" mb="24px">
        <CFlex flex-direction="column" justify-content="space-between" :min-height="['72px', '62px']">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> Sexo </OmtTypography>
          <CFlex>
            <OmtButton
              :disabled="readonly"
              v-for="(t, i) in parametros.TiposSexo"
              :key="`s-${t.Codigo}`"
              :variant="form.sexSelected === t.Codigo ? 'solid' : 'outline'"
              :height="['42px', '32px']"
              max-width="154px"
              width="50%"
              @click="form.sexSelected = t.Codigo"
              border-color="var(--colors-light-secondary-divider)"
              :mr="i < parametros.TiposSexo.length ? '16px' : ''"
            >
              <OmtTypography
                :as="['h3-bold', 'h2-bold']"
                :color="form.sexSelected === t.Codigo ? 'white' : 'var(--colors-light-primary-gray1)'"
              >
                {{ t.Descricao }}
              </OmtTypography>
            </OmtButton>
          </CFlex>
        </CFlex>
      </OmtRow>

      <OmtRow :desktopEnd="6" mb="24px">
        <CBox>
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> Data de nascimento </OmtTypography>
          <OmtInput
            :disabled="readonly"
            v-model="form.birthDate"
            height="42px"
            v-mask="'##/##/####'"
            :isInvalid="this.$v.form.birthDate.$invalid"
          />
        </CBox>
      </OmtRow>

      <OmtRow mb="24px">
        <CBox>
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> Nome completo da mãe </OmtTypography>
          <OmtInput
            :disabled="readonly"
            v-model="form.motherName"
            height="42px"
            maxlength="100"
            :isInvalid="$v.form.motherName.$invalid"
          />
        </CBox>
      </OmtRow>

      <OmtRow :desktopEnd="6" mb="24px">
        <CBox>
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> Grau de relacionamento </OmtTypography>
          <OmtSelect
            :disabled="readonly"
            :initialValue="form.relationshipType"
            @change="form.relationshipType = $event"
          >
            <option disabled selected value="">Selecione o Tipo de Relacionamento</option>
            <option v-for="r in parametros.TiposRelaciomento" :key="`r-${r.Codigo}`" :value="r.Codigo">
              {{ r.Descricao }}
            </option>
          </OmtSelect>
        </CBox>
      </OmtRow>
      <OmtRow v-show="showRelationshipExtraInfoInput" :desktopStart="7" mb="24px">
        <CBox>
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">
            Informe o grau de parentesco
          </OmtTypography>
          <OmtInput
            :disabled="readonly"
            v-model="form.relationshipExtraInfo"
            height="42px"
            maxlength="50"
            :isInvalid="$v.form.relationshipExtraInfo.$invalid"
          />
        </CBox>
      </OmtRow>
    </template>

    <OmtRow>
      <OmtDivider :mt="['40px', '48px']" :mb="['30px', '40px']" />
    </OmtRow>

    <template>
      <OmtRow mb="24px">
        <OmtTypography :as="['h2-bold', 'h1-semi-bold']"> Dados para contato </OmtTypography>
      </OmtRow>

      <OmtRow :desktopEnd="6" mb="24px">
        <CBox>
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> E-mail </OmtTypography>
          <OmtInput
            :disabled="readonly || editMode"
            v-model="form.email"
            height="42px"
            maxlength="100"
            :isInvalid="$v.form.email.$invalid"
          />
        </CBox>
      </OmtRow>
      <OmtRow :desktopStart="7" mb="24px">
        <CFlex flex-direction="column" justify-content="space-between" :min-height="['72px', '62px']">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> Tipo do e-mail </OmtTypography>
          <CFlex>
            <OmtButton
              :disabled="readonly"
              v-for="(t, i) in parametros.TiposContatoEmail"
              :key="`s-${t.Codigo}`"
              :variant="form.emailType === t.Codigo ? 'solid' : 'outline'"
              :height="['42px', '32px']"
              max-width="154px"
              width="50%"
              @click="form.emailType = t.Codigo"
              border-color="var(--colors-light-secondary-divider)"
              :mr="i < parametros.TiposContatoEmail.length ? '16px' : ''"
            >
              <OmtTypography
                :as="['h3-bold', 'h2-bold']"
                :color="form.emailType === t.Codigo ? 'white' : 'var(--colors-light-primary-gray1)'"
              >
                {{ t.Descricao }}
              </OmtTypography>
            </OmtButton>
          </CFlex>
        </CFlex>
      </OmtRow>

      <OmtRow :desktopEnd="6" mb="24px">
        <CBox>
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> Celular </OmtTypography>
          <OmtInput
            :disabled="readonly"
            v-model="form.cel"
            v-mask="'+55 (##) #####-####'"
            height="42px"
            :isInvalid="$v.form.cel.$invalid"
          />
        </CBox>
      </OmtRow>
      <OmtRow :desktopStart="7" mb="24px">
        <CFlex flex-direction="column" justify-content="space-between" :min-height="['72px', '62px']">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">
            Deseja receber notificações por SMS?
          </OmtTypography>
          <CFlex>
            <OmtButton
              :disabled="readonly"
              :height="['42px', '32px']"
              max-width="154px"
              :variant="form.receiveSMS ? 'solid' : 'outline'"
              width="50%"
              border-color="var(--colors-light-secondary-divider)"
              mr="16px"
              @click="form.receiveSMS = true"
            >
              <OmtTypography
                :color="form.receiveSMS ? 'white' : 'var(--colors-light-primary-gray1)'"
                :as="['h3-bold', 'h2-bold']"
              >
                Sim
              </OmtTypography>
            </OmtButton>
            <OmtButton
              :disabled="readonly"
              :height="['42px', '32px']"
              max-width="154px"
              width="50%"
              @click="form.receiveSMS = false"
              :variant="!form.receiveSMS ? 'solid' : 'outline'"
            >
              <OmtTypography
                :color="!form.receiveSMS ? 'white' : 'var(--colors-light-primary-gray1)'"
                :as="['h3-bold', 'h2-bold']"
              >
                Não
              </OmtTypography>
            </OmtButton>
          </CFlex>
        </CFlex>
      </OmtRow>

      <OmtRow :desktopEnd="6" mb="24px">
        <CBox>
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> Telefone fixo </OmtTypography>
          <OmtInput
            :disabled="readonly"
            v-model="form.tel"
            v-mask="maskTel"
            height="42px"
            :isInvalid="$v.form.tel.$invalid"
          />
        </CBox>
      </OmtRow>
      <OmtRow :desktopStart="7" :mb="['', '24px']">
        <CFlex flex-direction="column" justify-content="space-between" :min-height="['72px', '62px']">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> Tipo do telefone fixo </OmtTypography>
          <CFlex>
            <OmtButton
              :disabled="readonly"
              v-for="(t, i) in parametros.TiposContatoTelefoneFixo"
              :key="`s-${t.Codigo}`"
              :variant="form.telType === t.Codigo ? 'solid' : 'outline'"
              :height="['42px', '32px']"
              max-width="154px"
              width="50%"
              @click="form.telType = t.Codigo"
              border-color="var(--colors-light-secondary-divider)"
              :mr="i < parametros.TiposContatoTelefoneFixo.length ? '16px' : ''"
            >
              <OmtTypography
                :as="['h3-bold', 'h2-bold']"
                :color="form.telType === t.Codigo ? 'white' : 'var(--colors-light-primary-gray1)'"
              >
                {{ t.Descricao }}
              </OmtTypography>
            </OmtButton>
          </CFlex>
        </CFlex>
      </OmtRow>
    </template>

    <OmtRow :m="['40px -16px 32px', '52px -40px 48px']" bg="var(--colors-light-primary-light-gray)">
      <CBox :p="['28px 16px 36px', '32px 40px 48px']">
        <OmtTypography :as="['h2-bold', 'h1-semi-bold']" mb="24px"> Revalidação obrigatória </OmtTypography>

        <OmtTypography :as="['p1', 'p3']" mb="24px">
          A revalidação deste contato como administrador da sua conta é necessária para manter a sua segurança.
          Selecione a periodicidade em que você deseja fazer essa confirmação:
        </OmtTypography>

        <CFlex flex-direction="column" justify-content="space-between" :min-height="['72px', '62px']">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> Revalidação obrigatória </OmtTypography>
          <CFlex>
            <OmtButton
              :disabled="readonly || editMode"
              v-for="(t, i) in parametros.TiposRevalidacao"
              :key="`s-${t.Codigo}`"
              :variant="form.renewPolicyType === t.Codigo ? 'solid' : 'outline'"
              :height="['42px', '32px']"
              max-width="154px"
              width="50%"
              @click="form.renewPolicyType = t.Codigo"
              border-color="var(--colors-light-secondary-divider)"
              :mr="i < parametros.TiposRevalidacao.length ? '16px' : ''"
            >
              <OmtTypography
                :as="['h3-bold', 'h2-bold']"
                :color="form.renewPolicyType === t.Codigo ? 'white' : 'var(--colors-light-primary-gray1)'"
              >
                {{ t.Descricao }}
              </OmtTypography>
            </OmtButton>
          </CFlex>
        </CFlex>
      </CBox>
    </OmtRow>
    <OmtRow :desktopStart="5" :desktopEnd="8">
      <OmtButton @click="editMode ? updateSubmit() : (showTokenModal = true)" :disabled="$v.form.$invalid || readonly">
        {{ editMode ? "Salvar" : "Cadastrar" }}
      </OmtButton>
    </OmtRow>

    <TokenModal
      v-if="showTokenModal"
      tokenKey="terceiro"
      service="/terceiros/adicionar"
      v-on:cancel="showTokenModal = false"
      v-on:valid="validToken"
    />

    <FeedbackModal
      v-if="showFeedbackModal"
      :protocol="modalInfos.protocol"
      :title="modalInfos.title"
      :cardTitle="modalInfos.cardTitle"
      :cardDescription="modalInfos.cardDescription"
      :cardIcon="modalInfos.cardIcon"
      :modalIconBind="modalInfos.modalIconBind"
      :buttonLabel="modalInfos.buttonLabel"
      @buttonClick="modalInfos.buttonClick"
      @close="modalInfos.close"
    />
  </OmtGrid>
</template>

<script>
import FeedbackModal from "@/components/Molecules/FeedbackModal";
import { mapActions, mapState } from "vuex";
import { required, minLength, email } from "vuelidate/lib/validators";
import formValidations from "@/utils/formValidations";
import TokenModal from "@/components/Organisms/TokenModal";
import { maskTelFull } from "@/utils/utils";

const { isValidDate, isValidFullName } = formValidations;

export default {
  components: {
    FeedbackModal,
    TokenModal
  },
  data() {
    return {
      showFeedbackModal: false,
      showTokenModal: false,
      readonly: false,
      modalInfos: {
        modalIconBind: {
          name: "check-circle",
          color: "var(--colors-light-support-success)"
        },
        protocol: "",
        title: "Novo administrador adicionado com sucesso!",
        cardTitle: "Confirme a inclusão do novo administrador pelo seu e-mail",
        cardDescription:
          "Clique no link que você receberá por e-mail para confirmar esta inclusão. <br/><br/> O novo administrador poderá realizar transações e visualizar suas informações confidenciais e de seus beneficiários.",
        cardIcon: "envelope-circle-check",
        buttonLabel: "Concluir",
        buttonClick: this.pushBack,
        close: this.pushBack
      },
      editMode: false,
      form: {
        name: "",
        cpf: "",
        id: "",
        birthDate: "",
        motherName: "",
        relationshipType: "",
        relationshipDescription: "",
        relationshipExtraInfo: "",
        email: "",
        cel: "",
        tel: "",
        sexSelected: "F",
        foreign: false,
        emailType: "PTL",
        receiveSMS: false,
        telType: "RES",
        renewPolicyType: "S"
      },
      cities: []
    };
  },
  validations: {
    form: {
      name: {
        required,
        isValidFullName,
        minLength: minLength(5)
      },
      cpf: {
        required,
        minLength: minLength(14)
      },
      id: {
        required,
        minLength: minLength(8)
      },
      birthDate: {
        required,
        minLength: minLength(10),
        isValidDate
      },
      motherName: {
        required,
        isValidFullName,
        minLength: minLength(5)
      },
      relationshipType: {
        required
      },
      relationshipExtraInfo: {
        requiredIfNeed: function (value) {
          if (!this.showRelationshipExtraInfoInput) return true;
          return !!value.length;
        }
      },
      renewPolicyType: {
        required
      },
      email: {
        required,
        email
      },
      cel: {
        required,
        minLength: minLength(19)
      },
      tel: {
        minLength: minLength(18)
      }
    }
  },
  async mounted() {
    try {
      await this.$root.$emit("show-loading");

      await this.fetchParams();

      if (this.$route.params.id) {
        const id = this.$route.params.id;
        const registration = await this.fetchNomineeDetails({ id });

        if (registration) {
          this.buildReadonlyForm(registration);
        }
      }
    } catch (error) {
      this.$toast({
        description: error.message,
        status: "error",
        duration: 10000,
        position: "top"
      });
      this.pushBack();
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  methods: {
    ...mapActions("nominees", ["fetchParams", "createNominee", "fetchNomineeDetails", "updateNomineeDetails"]),
    async updateSubmit() {
      try {
        await this.$root.$emit("show-loading");
        await this.updateNomineeDetails({
          idPessoa: this.$route.params.id,
          nome: this.form.name,
          cpf: this.form.cpf,
          rne: this.form.foreign ? this.form.id : "",
          rg: this.form.foreign ? "" : this.form.id,
          emissorRg: "",
          dtEmissaoRg: "",
          dtNascimento: this.form.birthDate,
          ufLocalNascimento: "",
          idLocalNascimento: "",
          nomeLocalNascimento: "",
          sexo: this.form.sexSelected,
          nomeMae: this.form.motherName,
          idGrauRelacionamento: this.form.relationshipType,
          dsGrauRelacionamento: this.form.relationshipDescription,
          complementoGrauRelacionamento: this.form.relationshipExtraInfo,
          nrCelular: this.form.cel,
          smsCelular: this.form.receiveSMS,
          nrFixo: this.form.tel,
          tpFixo: this.form.tel === "" ? "" : this.form.telType,
          tpEmail: this.form.emailType,
          email: this.form.email,
          tpRevalidacao: this.form.renewPolicyType,
          avisoEmail: true
        });
        this.$toast({
          description: "Administrador atualizado com sucesso",
          status: "success",
          duration: 10000,
          position: "top"
        });
      } catch (error) {
        this.$toast({
          description: error.message,
          status: "error",
          duration: 10000,
          position: "top"
        });
      } finally {
        await this.$root.$emit("hide-loading");
        this.pushBack();
      }
    },
    async submit({ auth }) {
      try {
        await this.$root.$emit("show-loading");
        const dadosCriacao = await this.createNominee({
          autenticacao: auth,
          nome: this.form.name,
          cpf: this.form.cpf,
          rne: this.form.foreign ? this.form.id : "",
          rg: this.form.foreign ? "" : this.form.id,
          emissorRg: "",
          dtEmissaoRg: "",
          dtNascimento: this.form.birthDate,
          ufLocalNascimento: "",
          idLocalNascimento: "",
          nomeLocalNascimento: "",
          sexo: this.form.sexSelected,
          nomeMae: this.form.motherName,
          idGrauRelacionamento: this.form.relationshipType,
          dsGrauRelacionamento: this.form.relationshipDescription,
          complementoGrauRelacionamento: this.form.relationshipExtraInfo,
          nrCelular: this.form.cel,
          smsCelular: this.form.receiveSMS,
          nrFixo: this.form.tel,
          tpFixo: this.form.tel === "" ? "" : this.form.telType,
          tpEmail: this.form.emailType,
          email: this.form.email,
          tpRevalidacao: this.form.renewPolicyType,
          avisoEmail: true
        });

        if (dadosCriacao.Sucesso && dadosCriacao.ProtocoloANS) {
          this.modalInfos.protocol = dadosCriacao.ProtocoloANS;
          this.showFeedbackModal = true;
        }
      } catch (error) {
        this.$toast({
          description: error.message,
          status: "error",
          duration: 10000,
          position: "top"
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    validToken({ auth }) {
      this.showTokenModal = false;
      this.submit({ auth });
    },
    async buildReadonlyForm(registration) {
      this.readonly = false;
      this.editMode = true;

      this.form.name = registration.Nome;
      this.form.cpf = registration.Cpf;
      this.form.id = registration.Documento.Numero;
      this.form.birthDate = registration.StrDataNascimento;
      this.form.motherName = registration.NomeMae;
      this.form.relationshipType = registration.GrauRelacionamento.Id;
      this.form.relationshipDescription = registration.GrauRelacionamento.Descricao;
      this.form.relationshipExtraInfo = registration.GrauRelacionamento.Complemento;
      this.form.email = registration.Email.Endereco;
      this.form.cel = registration.TelefoneCelular.Formatado;
      this.form.tel = registration.TelefoneFixo.Formatado;
      this.form.sexSelected = registration.Sexo;
      this.form.foreign = registration.Estrangeiro;
      this.form.emailType = registration.Email.Tipo;
      this.form.receiveSMS = registration.TelefoneCelular.ReceberSms;
      this.form.telType = registration.TelefoneFixo.Tipo;
      this.form.renewPolicyType = registration.TipoRevalidacao;
    },
    maskTel(value) {
      return maskTelFull(value);
    },
    pushBack() {
      setTimeout(() => {
        this.$router.push({
          name: "third.party.register",
          params: {
            forceRefetch: true
          }
        });
      }, 1500);
    }
  },
  computed: {
    ...mapState("nominees", ["parametros"]),
    showRelationshipExtraInfoInput() {
      const relationshipSelected = this.parametros?.TiposRelaciomento?.find(
        relationship => relationship.Codigo === this.form.relationshipType
      );

      this.form.relationshipDescription = relationshipSelected?.Descricao || "";

      return relationshipSelected?.Complementar || false;
    }
  }
};
</script>
