<template>
  <CBox minHeight="100vh" width="100vw" position="sticky">
    <omint-page>
      <Header titleLabel="Serviços Omint" />
      <OmtGrid>
        <OmtRow>
          <CGrid template-columns="repeat(2, 1fr)" gap="2">
            <OmtCard
              data-cy="service-card"
              v-for="(m, index) in services"
              :key="`${index}-${m.Label}`"
              mb="5"
              @click="navigate(m.View, m.Label)"
              cursor="pointer"
            >
              <OmtIcons :name="m.Icon" size="xl" mb="4" />
              <OmtTypography as="h3-bold" mb="0" minHeight="40px">{{ m.Label }}</OmtTypography>
              <OmtTypography as="p1" color="var(--colors-light-primary-gray2)" minHeight="80px">{{
                m.Description
              }}</OmtTypography>
            </OmtCard>
          </CGrid>
        </OmtRow>
      </OmtGrid>
      <Footer />
    </omint-page>
  </CBox>
</template>

<script>
import Header from "@/components/Molecules/Header";
import { mapState } from "vuex";
import Footer from "@/components/Molecules/Footer";

export default {
  components: {
    Header,
    Footer
  },
  computed: {
    ...mapState("saude", ["menu"]),
    baseUrl() {
      return `${document.location.protocol}//${document.location.host}`;
    },
    services() {
      if (!this.menu?.length) return [];
      return this.menu.filter(m => !m.Desktop);
    }
  },
  methods: {
    navigate(link, label) {
      if (link) {
        const internalLink = link.startsWith("/") || link.startsWith(this.baseUrl);
        if (!internalLink || link !== this.$router.currentRoute.path) {
          try {
            if (internalLink) {
              this.$router.push(link);
            } else {
              if (this.$route.params.name === label) return;

              this.$router.push({
                name: "external.link",
                query: { title: label },
                params: { link: link, name: label }
              });
            }
          } catch (error) {
            return error;
          }
        }
      }
    }
  },
  mounted() {
    this.$root.$emit("hide-loading");
  }
};
</script>
