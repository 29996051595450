<template>
  <OmtStickedModal v-if="isOpen" @close="$emit('close')">
    <CBox :pt="['8px', '69px']" mb="42px" data-cy="sent-pendency">
      <OmtIcons name="check-circle" size="2x" mb="32px" color="success" />
      <OmtTypography as="h1-large" mb="32px">
        Solicitação enviada <br />
        com sucesso!
      </OmtTypography>
      <CFlex align="center" mb="32px">
        <OmtIcons name="square-poll-horizontal" mr="12px" />
        <OmtTypography as="h2-bold"> Protocolo: {{ protocol }} </OmtTypography>
      </CFlex>

      <CBox bg="var(--colors-light-primary-light-gray)" p="10px 16px 16px 16px">
        <CFlex flex-direction="column">
          <CFlex>
            <OmtIcons name="bell-on" mr="16px" />
            <OmtTypography as="h2-bold">
              A análise do pedido será feita em até {{ daysAnalizy }} dias úteis.
            </OmtTypography>
          </CFlex>
          <OmtTypography as="p1" mt="16px !important">
            Você será notificado sobre o andamento desta solicitação pela home do APP.
          </OmtTypography>
          <OmtTypography as="p1" mt="16px !important">
            Você poderá acompanhar o status na tela Minhas Solicitações.
          </OmtTypography>
        </CFlex>
      </CBox>
    </CBox>
    <OmtTypography as="p1" mb="16px">
      <omt-bold>Como você avalia sua experiência com esse serviço pelo APP Minha Omint?</omt-bold>
    </OmtTypography>

    <CFlex justify="space-between" mb="8px">
      <OmtButton
        :variant="rate === selectedRate ? 'solid' : 'outline'"
        width="48px"
        height="50px"
        border="0.5px solid var(--colors-light-secondary-divider)"
        borderRadius="50%"
        data-cy="rate-buttons"
        v-for="rate in 5"
        :key="rate"
        @click="selectRate(rate)"
      >
        <OmtTypography as="p1" :color="rate === selectedRate ? 'white' : 'inherit'">
          {{ rate }}
        </OmtTypography>
      </OmtButton>
    </CFlex>

    <CFlex justify="space-between" mb="22px">
      <OmtTypography as="p2"> Muito difícil </OmtTypography>
      <OmtTypography as="p2"> Muito fácil </OmtTypography>
    </CFlex>

    <OmtRow v-if="selectedRate && selectedRate <= 2">
      <CFlex flexDirection="column" alignItems="flex-end">
        <OmtTextarea
          class="input-observation"
          height="109px"
          padding="12px"
          placeholder="Inclua informações ou detalhes que podem complementar sua avaliação."
          @change="handleChange"
          :initialValue="observation"
        />
        <OmtTypography as="p2" color="var(--colors-light-primary-gray2)"> {{ observation.length }}/350 </OmtTypography>
      </CFlex>
    </OmtRow>

    <template #footer>
      <OmtButton w="100%" @click="submit" :disabled="selectedRate === null"> Concluir </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
export default {
  props: {
    isOpen: {
      Type: Boolean
    },
    daysAnalizy: {
      Type: Number
    },
    protocol: {
      type: String
    }
  },
  data() {
    return {
      selectedRate: null,
      observation: ""
    };
  },
  methods: {
    submit() {
      this.$emit("save", { rating: this.selectedRate, obs: this.observation });
    },
    selectRate(rate) {
      this.selectedRate = rate;
    },
    handleChange(text) {
      this.observation = text;
    }
  }
};
</script>
