<template>
  <omint-page>
    <Header name="Consulta de solicitação" backRoute="/" titleLabel="Consulta de solicitação" />
    <omint-page-content v-if="infos">
      <omt-highlight>Solicitado por</omt-highlight>
      <omt-p>{{ infos.Cabecalho.Solicitante }}</omt-p>

      <br />
      <omt-highlight>Ação:</omt-highlight>
      <omt-p>
        {{ infos.Acao }}
      </omt-p>

      <br />
      <omt-highlight>Antes</omt-highlight>
      <omt-p>
        <omt-bold>Banco: </omt-bold>
        {{ infos.ContaCorrenteAntes.CodigoBanco }} - {{ infos.ContaCorrenteAntes.NomeBanco }}
      </omt-p>
      <omt-p>
        <omt-bold>Agência: </omt-bold>
        {{ infos.ContaCorrenteAntes.CodigoAgencia }} - {{ infos.ContaCorrenteAntes.NomeAgencia }}
      </omt-p>
      <omt-p>
        <omt-bold>Conta: </omt-bold>
        {{ infos.ContaCorrenteAntes.NumeroConta }}
      </omt-p>
      <omt-p v-if="infos.ContaCorrenteAntes.DigitoConta != ''">
        <omt-bold>Dígito: </omt-bold>{{ infos.ContaCorrenteAntes.DigitoConta }}
      </omt-p>
      <omt-p v-if="infos.ContaCorrenteAntes.Principal">
        <omt-bold>Principal: </omt-bold>
        {{ infos.ContaCorrenteAntes.Principal === "S" ? "Sim" : "Não" }}
      </omt-p>

      <br />
      <omt-highlight>Depois</omt-highlight>
      <omt-p>
        <omt-bold>Banco: </omt-bold>
        {{ infos.ContaCorrenteDepois.CodigoBanco }} - {{ infos.ContaCorrenteDepois.NomeBanco }}
      </omt-p>
      <omt-p>
        <omt-bold>Agência: </omt-bold>
        {{ infos.ContaCorrenteDepois.CodigoAgencia }} - {{ infos.ContaCorrenteDepois.NomeAgencia }}
      </omt-p>
      <omt-p>
        <omt-bold>Conta: </omt-bold>
        {{ infos.ContaCorrenteDepois.NumeroConta }}
      </omt-p>
      <omt-p v-if="infos.ContaCorrenteDepois.DigitoConta != ''">
        <omt-bold>Dígito: </omt-bold>{{ infos.ContaCorrenteDepois.DigitoConta }}
      </omt-p>
      <omt-p v-if="infos.ContaCorrenteDepois.Principal">
        <omt-bold>Principal: </omt-bold>
        {{ infos.ContaCorrenteDepois.Principal === "S" ? "Sim" : "Não" }}
      </omt-p>
    </omint-page-content>

    <Footer />
  </omint-page>
</template>

<script>
import { mapActions } from "vuex";
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";

export default {
  name: "bank.print",
  props: ["idx"],
  components: { Header, Footer },
  data() {
    return {
      infos: null
    };
  },
  methods: {
    ...mapActions("bankAccount", ["feachTicket"])
  },
  async mounted() {
    if (!this.idx) {
      this.$router.push("/");
    }
    try {
      await this.$root.$emit("show-loading");
      const response = await this.feachTicket(this.idx);
      this.infos = response.Data;
    } finally {
      await this.$root.$emit("hide-loading");
    }
  }
};
</script>
