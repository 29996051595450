import { render, staticRenderFns } from "./ServiceCollapsableCard.vue?vue&type=template&id=0710d686&scoped=true&"
import script from "./ServiceCollapsableCard.vue?vue&type=script&lang=js&"
export * from "./ServiceCollapsableCard.vue?vue&type=script&lang=js&"
import style0 from "./ServiceCollapsableCard.vue?vue&type=style&index=0&id=0710d686&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0710d686",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFlex: require('@chakra-ui/vue').CFlex, CDivider: require('@chakra-ui/vue').CDivider, CBox: require('@chakra-ui/vue').CBox, CCollapse: require('@chakra-ui/vue').CCollapse})
