import * as geoUtils from "@/utils/geoDistance";
import { getStorageData } from "@/utils/storageUtil";

const baseURL = process.env.VUE_APP_SERVER_URL;

export async function ObterServicosDisponiveis({ vinculo, login, perfil, operacao }) {
  return window.$axios
    .get({
      url: `${baseURL}/Menu/ObterServicosDisponiveisPWA`,
      query: {
        vinculo,
        versaoMenu: process.env.VUE_APP_MENU_VERSION,
        login,
        perfil,
        operacao
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterServicosDisponiveis", error.message);
      return error;
    });
}

export async function EnviarCodigoRecuperarSenha({ email }) {
  return window.$axios
    .post({
      url: `${baseURL}/RecuperarSenha/EnviarCodigo`,
      query: {
        email
      }
    })
    .then(resp => {
      return resp.data;
    })
    .catch(error => {
      window.$log.error("EnviarCodigo", error.message);
      return error;
    });
}

export async function InformarCodigoRecuperarSenha({ codigo, email, password }) {
  const senhaEncrypted = window.$jse.encrypt(password);
  return window.$axios
    .post({
      url: `${baseURL}/RecuperarSenha/InformarCodigo`,
      query: {
        codigo,
        email,
        senhaEncrypted
      }
    })
    .then(resp => {
      return resp.data;
    })
    .catch(error => {
      window.$log.error("InformarCodigo", error.message);
      return error;
    });
}

export function GerarUrlWallet({ vinculo, tipoCredencial }) {
  const queryString = new URLSearchParams({
    nrVinculo: vinculo,
    tipoCredencial
  }).toString();

  return `/wallet/ObterWallet?${queryString}`;
}

export async function AutorizacaoListar({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/Autorizacao/Listar`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("Listar", error.message);
      return error;
    });
}

export async function AutorizacaoObterDocumento({ vinculo, idAutorizacao }) {
  const chave = `${vinculo};${idAutorizacao}`;
  const key = `${window.$jse.encrypt(chave)}`;
  const type = ".pdf";
  const queryString = new URLSearchParams({
    key,
    type
  }).toString();

  return `${baseURL}/Autorizacao/ObterAutorizacaoPWA?${queryString}`;
}

export async function ObterDadosChatBot({ vinculo, email }) {
  return window.$axios
    .get({
      url: `${baseURL}/Util/ObterDadosChatBot`,
      query: {
        vinculo,
        email
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterDadosChatBot", error.message);
      return error;
    });
}

export async function PWAVersion() {
  return window.$axios
    .get({
      url: `${baseURL}/Home/PWAVersion`
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("PWAVersion", error.message);
      return error;
    });
}

export async function BuscarDocumento({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/Home/BuscarDocumento`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("BuscarDocumento", error.message);
      return error;
    });
}

export async function ObterServicosUsuario({ operacao, perfil, login, usuario }) {
  return window.$axios
    .get({
      url: `${baseURL}/Menu/ObterServicosUsuario`,
      query: {
        operacao,
        perfil,
        login,
        usuario
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterServicosUsuario", error.message);
      return error;
    });
}

export async function RequestWallet(walletUrl) {
  return window.$axios
    .get({
      url: walletUrl,
      responseType: "blob"
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("RequestWallet", error.message);
      return null;
    });
}

export async function RemoveAccount({ vinculo }) {
  const coords: any = await geoUtils.GetLocation().catch(() => null);
  return window.$axios
    .post({
      url: `${baseURL}/MinhaConta/ApagarConta`,
      query: {
        vinculo,
        dsUsuario: getStorageData("username", ""),
        lat: coords?.latitude,
        lng: coords?.longitude
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterServicosUsuario", error.message);
      return error;
    });
}

export async function ObterMenusFavoritos({ vinculo, retornarAtalhos }) {
  return await window.$axios
    .get({
      url: `${baseURL}/Menu/ObterMenusFavoritos`,
      query: {
        vinculo,
        retornarAtalhos
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterMenusFavoritos", error.message);
      return error;
    });
}

export async function GravarMenusFavoritos({ vinculo, menus }) {
  return await window.$axios
    .post({
      url: `${baseURL}/Menu/GravarMenusFavoritos`,
      query: {
        vinculo
      },
      data: menus
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("GravarMenusFavoritos", error.message);
      return error;
    });
}
