import Redecredenciada from "@/pages/redecredenciada/index.vue";
import RedecredenciadaHome from "@/pages/redecredenciada/home.vue";
import RedecredenciadaSpecialitiesList from "@/pages/redecredenciada/specialities-list.vue";
import RedecredenciadaSubSpecialitiesList from "@/pages/redecredenciada/subspecialities-list.vue";
import RedecredenciadaLocationFilter from "@/pages/redecredenciada/location-filter.vue";
import RedecredenciadaDoctorsList from "@/pages/redecredenciada/doctors-list.vue";
import RedecredenciadaDoctorsInfo from "@/pages/redecredenciada/doctors-info.vue";
import RedecredenciadaPlansAttended from "@/pages/redecredenciada/plans-attended.vue";

const routes: Route[] = [
  {
    path: "/redecredenciada",
    alias: "/redecredenciada-home",
    component: Redecredenciada,
    props: true,
    children: [
      {
        path: "",
        name: "affiliate",
        props: true,
        component: RedecredenciadaHome,
        meta: {
          registerAccess: { servico: "Busca de Rede", funcionalidade: "Acesso a busca de rede" },
          validPath: ["/redecredenciada", "/redecredenciada-home"]
        }
      },
      {
        path: "especialidades",
        name: "affiliate.specs",
        props: true,
        component: RedecredenciadaSpecialitiesList,
        meta: {
          basePath: "/redecredenciada",
          validPath: ["/redecredenciada", "/redecredenciada-home"]
        }
      },
      {
        path: "subespecialidades",
        name: "affiliate.subspecs",
        props: true,
        component: RedecredenciadaSubSpecialitiesList,
        meta: {
          basePath: "/redecredenciada",
          validPath: ["/redecredenciada", "/redecredenciada-home"]
        }
      },
      {
        path: "regiao",
        name: "affiliate.locs",
        props: true,
        component: RedecredenciadaLocationFilter,
        meta: {
          basePath: "/redecredenciada",
          validPath: ["/redecredenciada", "/redecredenciada-home"]
        }
      },
      {
        path: "medicos",
        name: "affiliate.docs",
        props: true,
        component: RedecredenciadaDoctorsList,
        meta: {
          basePath: "/redecredenciada",
          validPath: ["/redecredenciada", "/redecredenciada-home"]
        }
      },
      {
        path: "medico/:situacao/:rede/:cdPrestador/:srEndereco",
        name: "affiliate.docInfo",
        component: RedecredenciadaDoctorsInfo,
        props: true,
        meta: {
          basePath: "/redecredenciada",
          validPath: ["/redecredenciada", "/redecredenciada-home"]
        }
      },
      {
        path: "planos",
        name: "affiliate.plansAttended",
        component: RedecredenciadaPlansAttended,
        props: true,
        meta: {
          basePath: "/redecredenciada",
          validPath: ["/redecredenciada", "/redecredenciada-home"]
        }
      }
    ]
  }
];

export default routes;
