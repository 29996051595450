<template>
  <OmtGrid>
    <OmtRow mb="24px">
      <ThirdPartyRegisterListItem :item="owner" />
    </OmtRow>

    <OmtRow
      v-for="(thirdParty, index) of thirdParties"
      :mb="(index < thirdParties.length - 1 && '24px') || ''"
      :key="index"
    >
      <ThirdPartyRegisterItem
        type="third-party"
        :item="thirdParty"
        :id="index"
        @action-modal="handleEventItem('actionModal', $event)"
        @visualizar="handleEventItem('visualizar', $event)"
        @prorrogar="handleEventItem('renovar', $event)"
      />
    </OmtRow>

    <OmtRow mt="160px" :desktopStart="4" :desktopEnd="9" v-show="!showTermModal">
      <OmtButton @click="podeAdicionar() ? (showTermModal = true) : ''">
        <OmtTypography :as="['button-medium', 'button-large']" color="white">
          Cadastrar novo administrador
        </OmtTypography>
      </OmtButton>
    </OmtRow>
    <ThirdPartyRegisterModalTerm :isOpen.sync="showTermModal" @continue="handleConcludeTerms" />
    <TokenModal
      v-if="showTokenModal"
      tokenKey="terceiro"
      :service="tokenService"
      v-on:cancel="showTokenModal = false"
      v-on:valid="validToken"
    />
    <ActionsModal
      v-if="showActionsModal"
      title="Ações"
      :list="[
        ...(thirdPartyItemSelected.Tipo === 'Terceiro'
          ? [{ label: 'Editar Informações', action: 'edit', icon: 'pen-to-square' }]
          : []),
        ...(thirdPartyItemSelected.Vigencia === '' &&
        thirdPartyItemSelected.Revalidacao === '' &&
        thirdPartyItemSelected.AdministradorNomeado == 'Revogado'
          ? [{ label: 'Reativar Autorização', action: 'autorizar', icon: 'circle-check' }]
          : []),
        ...(thirdPartyItemSelected.Vigencia === '' &&
        thirdPartyItemSelected.Revalidacao === '' &&
        thirdPartyItemSelected.AdministradorNomeado === 'Não'
          ? [{ label: 'Adicionar Autorização', action: 'adicionarAdministracao', icon: 'circle-check' }]
          : []),
        ...(thirdPartyItemSelected.AdministradorNomeado === 'Sim'
          ? [{ label: 'Renovar Autorização', action: 'renovar', icon: 'arrows-rotate' }]
          : []),
        ...(thirdPartyItemSelected.AdministradorNomeado === 'Sim' && thirdPartyItemSelected.Vigencia !== ''
          ? [{ label: 'Remover Autorização', action: 'remover', icon: 'circle-xmark' }]
          : [])
      ]"
      :value="thirdPartyItemSelected"
      @edit="handleEventItem('editar', $event)"
      @autorizar="handleEventItem('autorizar', $event)"
      @remover="handleEventItem('remover', $event)"
      @renovar="handleEventItem('renovar', $event)"
      @adicionarAdministracao="handleEventItem('adicionarAdministracao', $event)"
      @close="showActionsModal = false"
    />
    <ThirdPartyRegisterAutorizacaoModal
      @close="showAutorizacaoModal = false"
      :value="thirdPartyItemSelected"
      v-if="showAutorizacaoModal"
      :type="AutorizacaoModalType"
      @autorizar="handleEventItem('reativar', $event)"
      @renovar="handleEventItem('prorrogar', $event)"
      @adicionar="handleEventItem('adicionar', $event)"
    />
    <FeedbackModal
      v-if="showFeedbackModal"
      :protocol="modalInfos.protocol"
      :title="modalInfos.title"
      :cardTitle="modalInfos.cardTitle"
      :cardDescription="modalInfos.cardDescription"
      :cardIcon="modalInfos.cardIcon"
      :modalIconBind="modalInfos.modalIconBind"
      :buttonLabel="modalInfos.buttonLabel"
      @buttonClick="showFeedbackModal = false"
      @close="showFeedbackModal = false"
    />
  </OmtGrid>
</template>

<script>
import ActionsModal from "@/components/Molecules/ActionsModal";
import ThirdPartyRegisterListItem, {
  buildOwnerListItem,
  buildThirdpartyListItem
} from "@/components/Organisms/ThirdPartyRegister/ThirdPartyRegisterListItem.vue";
import ThirdPartyRegisterItem from "@/components/Organisms/ThirdPartyRegister/ThirdPartyRegisterItem.vue";
import ThirdPartyRegisterAutorizacaoModal from "@/components/Organisms/ThirdPartyRegister/ThirdPartyRegisterAutorizacaoModal.vue";
import ThirdPartyRegisterModalTerm from "@/components/Organisms/ThirdPartyRegister/ThirdPartyRegisterModalTerm.vue";
import FeedbackModal from "@/components/Molecules/FeedbackModal";
import { mapActions, mapState } from "vuex";
import TokenModal from "@/components/Organisms/TokenModal";

export default {
  components: {
    ThirdPartyRegisterListItem,
    ThirdPartyRegisterItem,
    ThirdPartyRegisterModalTerm,
    ThirdPartyRegisterAutorizacaoModal,
    TokenModal,
    FeedbackModal,
    ActionsModal
  },
  data() {
    return {
      showTermModal: false,
      showTokenModal: false,
      tokenService: "",
      typeSelected: "",
      owner: {
        list: buildOwnerListItem({})
      },
      thirdParties: [
        {
          idPessoa: 0,
          list: buildThirdpartyListItem({})
        }
      ],
      thirdPartyItemSelected: {},
      AutorizacaoModalType: "",
      showActionsModal: false,
      showAutorizacaoModal: false,
      showFeedbackModal: false,
      modalInfos: {
        modalIconBind: {
          name: "check-circle",
          color: "var(--colors-light-support-success)"
        },
        protocol: "",
        title: "",
        cardTitle: "",
        cardDescription: "",
        cardIcon: "user-plus",
        buttonLabel: "Concluir"
      }
    };
  },
  async mounted() {
    if (!this.titular) {
      this.$toast({
        description: "Apenas o titular pode utilizar o cadastro de terceiros",
        status: "error",
        duration: 5000,
        position: "top"
      });
      this.$router.replace("/");
      return;
    }
    try {
      if (this.$route.params?.forceRefetch || !this.dadosTitular || !this.nomeados.length) {
        await this.$root.$emit("show-loading");
        await this.fetchNomineesInfos();
      }
      this.owner.list = buildOwnerListItem({ ...this.dadosTitular });
      await this.getListThirdParty();
    } catch (error) {
      this.$toast({
        description: error.message,
        status: "error",
        duration: 10000,
        position: "top"
      });
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  computed: {
    ...mapState("saude", ["titular"]),
    ...mapState("nominees", ["dadosTitular", "nomeados"])
  },
  methods: {
    ...mapActions("nominees", [
      "fetchNomineesInfos",
      "createBeneficiary",
      "reactivateNomiee",
      "removeNomieePeriod",
      "updateNomieePeriod"
    ]),
    podeAdicionar() {
      const terceirosAtivos = this.nomeados?.filter(
        n => n.Tipo === "Terceiro" && n.AdministradorNomeado?.startsWith("Sim")
      );
      const result = (terceirosAtivos?.length ?? 0) < 2;

      if (!result) {
        this.$toast({
          description: "Você pode cadastrar apenas dois administradores para o seu título.",
          status: "error",
          duration: 10000,
          position: "top"
        });
      }
      return result;
    },
    async getListThirdParty() {
      this.thirdParties = this.nomeados.map(item => {
        return {
          AdministradorNomeado: item.AdministradorNomeado,
          DadosEditaveis: item.DadosEditaveis,
          Id: item.Id,
          Revalidacao: item.Revalidacao,
          Tipo: item.Tipo,
          Vigencia: item.Vigencia,
          Nome: item.Nome,
          list: buildThirdpartyListItem({
            Nome: item.Nome,
            Tipo: item.Tipo,
            Revalidacao: item.Revalidacao,
            AdministradorNomeado: item.AdministradorNomeado,
            Vigencia: item.Vigencia
          })
        };
      });
    },
    async submit({ auth }) {
      try {
        await this.$root.$emit("show-loading");
        if (this.typeSelected === "reativar") {
          const reativar = await this.reactivateNomiee({
            autenticacao: auth,
            idPessoa: this.thirdPartyItemSelected.Id,
            tpRevalidacao: this.thirdPartyItemSelected.Revalidacao
          });
          this.modalInfos.title = "Autorização reativada com sucesso!";
          this.modalInfos.cardTitle = "Autorização realizada com sucesso!";
          this.modalInfos.cardDescription =
            "A autorização do contato como administrador da sua conta precisará ser renovada após o período selecionado.";
          this.modalInfos.protocol = reativar.ProtocoloANS;
          this.showFeedbackModal = true;
        } else if (this.typeSelected === "remover") {
          const remover = await this.removeNomieePeriod({
            autenticacao: auth,
            idPessoa: this.thirdPartyItemSelected.Id
          });
          this.modalInfos.title = "Administração removida com sucesso!";
          this.modalInfos.cardTitle = "Removemos a autorização deste contato para administrar sua conta";
          this.modalInfos.cardDescription =
            "Este contato não pode realizar transações em seu nome e visualizar suas informações confidenciais e de seus beneficiários. <br /> Você pode reautorizá-lo a qualquer momento pelo mesmo menu utilizado.";
          this.modalInfos.protocol = remover.ProtocoloANS;
          this.showFeedbackModal = true;
        } else if (this.typeSelected === "prorrogar") {
          const prorrogar = await this.updateNomieePeriod({
            autenticacao: auth,
            idPessoa: this.thirdPartyItemSelected.Id,
            tpRevalidacao: this.thirdPartyItemSelected.Revalidacao
          });
          this.modalInfos.title = "Autorização renovada com sucesso!";
          this.modalInfos.cardTitle = "Autorização realizada com sucesso!";
          this.modalInfos.cardDescription =
            "A autorização do contato como administrador da sua conta precisará ser renovada após o período selecionado.";
          this.modalInfos.protocol = prorrogar.ProtocoloANS;
          this.showFeedbackModal = true;
        } else if (this.typeSelected === "adicionar") {
          const adicionar = await this.createBeneficiary({
            autenticacao: auth,
            idPessoa: this.thirdPartyItemSelected.Id,
            tpRevalidacao: this.thirdPartyItemSelected.Revalidacao
          });
          this.modalInfos.title = "Autorização realizada com sucesso!";
          this.modalInfos.cardTitle = "Autorização realizada com sucesso!";
          this.modalInfos.cardDescription =
            "A autorização do contato como administrador da sua conta precisará ser renovada após o período selecionado.";
          this.modalInfos.protocol = adicionar.ProtocoloANS;
          this.showFeedbackModal = true;
        }
      } catch (error) {
        this.$toast({
          description: error.message,
          status: "error",
          duration: 10000,
          position: "top"
        });
      } finally {
        await this.$root.$emit("hide-loading");
        await this.fetchNomineesInfos();
        await this.getListThirdParty();
      }
    },
    validToken({ auth }) {
      this.showTokenModal = false;
      this.submit({ auth });
    },
    handleEventItem(type, item) {
      this.showActionsModal = false;
      this.showAutorizacaoModal = false;
      this.thirdPartyItemSelected = item;
      this.typeSelected = type;
      switch (type) {
        case "visualizar":
          this.$router.push({ name: "third.party.register.form", params: { id: item.Id } });
          return;
        case "prorrogar":
          this.tokenService = "/terceiros/prorrogar";
          this.showTokenModal = true;
          return;
        case "reativar":
          this.tokenService = "/terceiros/prorrogar";
          this.showTokenModal = true;
          return;
        case "editar":
          this.$router.push({ name: "third.party.register.form", params: { id: item.Id } });
          return;
        case "autorizar":
          this.AutorizacaoModalType = "autorizar";
          this.showAutorizacaoModal = true;
          return;
        case "remover":
          this.tokenService = "/terceiros/revogar";
          this.showTokenModal = true;
          return;
        case "renovar":
          this.AutorizacaoModalType = "renovar";
          this.showAutorizacaoModal = true;
          return;
        case "actionModal":
          this.showActionsModal = true;
          return;
        case "adicionarAdministracao":
          this.AutorizacaoModalType = "adicionar";
          this.showAutorizacaoModal = true;
          return;
        case "adicionar":
          this.tokenService = "/terceiros/adicionar";
          this.showTokenModal = true;
          return;
      }
    },
    handleConcludeTerms() {
      this.$router.push({
        name: "third.party.register.form"
      });
    }
  }
};
</script>
