<template>
  <CFlex justify="space-between" width="100%" data-cy="refund-status-tile">
    <CFlex align="center" width="90%" :maxWidth="['calc(100% - 100px)']">
      <CPseudoBox :bg="color" w="8px" h="8px" borderRadius="50%" mr="8px" />
      <OmtTypography as="p1" mr="8px" :maxWidth="['calc(100% - 30px)']">
        {{ label }}
      </OmtTypography>
      <OmtTypography as="p1" v-if="count"> ({{ count }}) </OmtTypography>
    </CFlex>
    <CFlex align="center">
      <OmtTypography as="p1" v-if="!!total" style="white-space: nowrap"> {{ total }} </OmtTypography>
    </CFlex>
  </CFlex>
</template>

<script>
/**
 * @prop { String } color
 * @prop { String } label
 * @prop { Number } count
 * @prop { String } total
 */
export default {
  props: {
    color: {
      type: String
    },
    label: {
      type: String
    },
    count: {
      type: Number
    },
    total: {
      type: String
    }
  }
};
</script>
