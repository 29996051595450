<template>
  <omint-page-content>
    <template v-if="!vinculoAdicionado">
      <div class="term-box">
        <OmtTypography as="h1-bold" mb="16px">Cadastro de senha</OmtTypography>
        <OmtTypography as="h3-semi-bold" mb="32px">
          Cadastre abaixo a senha que você usará para acessar o App:
        </OmtTypography>
        <Password @passwordChange="onPasswordChange" />
      </div>
      <OmtButton
        variant="solid"
        width="100%"
        height="50px"
        border="0.5px solid var(--colors-light-secondary-divider)"
        borderRadius="40px"
        data-cy="button-entrar"
        @click="handleClick()"
        :disabled="!validPassword"
        mb="3"
      >
        <OmtTypography as="h1-bold" color="white">Continuar</OmtTypography>
      </OmtButton>
    </template>
    <FirstAccessNewPasswordModal :isOpen.sync="modalIsOpen" @continue="onContinue" />
  </omint-page-content>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import FirstAccessNewPasswordModal from "@/components/Organisms/FirstAccess/FirstAccessNewPasswordModal.vue";
import Password from "@/components/Molecules/Password/index.vue";

export default {
  name: "PasswordForm",
  mounted() {
    if (this.vinculoAdicionado) {
      this.$root.$emit("change-title-label", "Confirmação");
      this.modalIsOpen = true;
    } else {
      this.$root.$emit("change-title-label", "Senha");
    }
  },
  components: {
    FirstAccessNewPasswordModal,
    Password
  },
  data() {
    return {
      repasswordShow: false,
      modalIsOpen: false,
      validPassword: false,
      pass: null
    };
  },
  computed: {
    ...mapState("firstAccess", ["name", "vinculoAdicionado"])
  },
  methods: {
    ...mapActions("firstAccess", ["createUser"]),
    onContinue() {
      this.modalIsOpen = false;
      this.$router.push({ name: "login" });
    },
    onPasswordChange({ valid, password }) {
      this.validPassword = valid;
      this.pass = password;
    },
    handleClick: async function () {
      await this.$root.$emit("show-loading");
      const error = await this.createUser({ senha: this.pass });
      await this.$root.$emit("hide-loading");
      if (error !== "") {
        Swal.fire({
          title: "Aviso",
          text: error,
          confirmButtonText: "Tentar novamente"
        });
      } else {
        this.modalIsOpen = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.term-box {
  height: calc(100vh - 180px);
  overflow-y: auto;
}
</style>
