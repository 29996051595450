const baseURL = `${process.env.VUE_APP_SERVER_URL}/Biometria`;

export async function Cadastrar({ vinculo, selfie }) {
  return window.$axios
    .post({
      url: `${baseURL}/Cadastrar`,
      query: {
        vinculo
      },
      data: {
        selfie
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("Cadastrar", error.message);
      return error;
    });
}

export async function VerificarCadastro({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/VerificarCadastro`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("VerificarCadastro", error.message);
      return error;
    });
}

export async function Autenticar({ vinculo, servico, selfie }) {
  return window.$axios
    .post({
      url: `${baseURL}/Autenticar`,
      query: {
        vinculo
      },
      data: {
        servico,
        selfie
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("Autenticar", error.message);
      return error;
    });
}

export async function GerarQRCode({ vinculo, servico, telefone }) {
  return window.$axios
    .post({
      url: `${baseURL}/GerarQRCode`,
      query: {
        vinculo
      },
      data: {
        servico,
        telefone
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("GerarQRCode", error.message);
      return error;
    });
}

export async function VerificarQRCode({ vinculo, servico, token, id }) {
  return window.$axios
    .post({
      url: `${baseURL}/VerificarQRCode`,
      query: {
        vinculo
      },
      data: {
        servico,
        token,
        id
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("VerificarQRCode", error.message);
      return error;
    });
}

export async function RegistrarErro({ vinculo, codigo, descricao }) {
  return window.$axios
    .post({
      url: `${baseURL}/RegistrarErro`,
      query: {
        vinculo
      },
      data: {
        codigo,
        descricao
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("RegistrarErro", error.message);
      return error;
    });
}
