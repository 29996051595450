<template>
  <OmtGrid :minHeight="['auto', '629px']">
    <OmtRow :desktopStart="1" :desktopEnd="6" mb="24px">
      <CBox>
        <OmtTypography as="h1-regular" mb="24px"> <b>Observações</b> (opcional) </OmtTypography>

        <OmtTypography as="p1" mb="24px">
          <b> Todas as suas informações necessárias foram preenchidas. </b>
          Para finalizar, você gostaria de incluir alguma observação?
        </OmtTypography>
      </CBox>
    </OmtRow>

    <OmtRow :desktopStart="7" :pt="['0', '48px']">
      <CFlex flexDirection="column" alignItems="flex-end">
        <OmtTextarea
          height="160px"
          padding="12px"
          placeholder="Inclua informações ou detalhes que podem complementar os documentos enviados."
          @change="handleChange"
          :initialValue="obs"
        />
        <OmtTypography as="p2" data-cy="comments-count"> {{ obs.length }}/350 </OmtTypography>
      </CFlex>
    </OmtRow>

    <OmtRow pb="18px" pt="24px" bg="white" :desktopStart="4" :desktopEnd="9">
      <OmtButton @click="submit"> Enviar </OmtButton>
    </OmtRow>

    <RefundCreateCommentsModal
      :isOpen.sync="showModal"
      :protocol="protocoloANS"
      :prazos="this.prazos"
      @close="onModalClose"
      @save="onSave"
    />
  </OmtGrid>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import RefundCreateCommentsModal from "@/components/Organisms/RefundNew/RefundCreateCommentsModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  components: {
    RefundCreateCommentsModal
  },
  data() {
    return {
      showModal: false,
      obs: "",
      protocoloANS: "",
      dueDate: "2",
      prazos: []
    };
  },
  async mounted() {
    if (!this.addPermission || !this.newRefund?.anexos) {
      this.$router.push({ name: "refund.new" });
      return;
    }

    if (this.newRefund && "observacao" in this.newRefund) {
      this.obs = this.newRefund.observacao;
    }
  },
  computed: {
    ...mapState("refund", ["newRefund", "addPermission"])
  },
  methods: {
    ...mapActions("ticket", ["saveExpRating"]),
    ...mapMutations("refund", ["SET_NEW_REFUND"]),
    ...mapActions("refund", ["createRefundTicket", "checkRefundTicket"]),
    async submit() {
      await this.$root.$emit("show-loading");
      let requestedRefund = {};
      try {
        requestedRefund = await this.checkRefundTicket({
          beneficiario: this.newRefund.beneficiario
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }

      if (requestedRefund && requestedRefund.Data && requestedRefund.Data.length > 0) {
        const userInput = await Swal.fire({
          title: "Aviso",
          text: "Já existe uma solicitação de reembolso para este beneficiário\n\nO envio de documentos em duplicidade pode atrasar a análise e o crédito do reembolso, deseja continuar?",
          confirmButtonText: "Continuar",
          cancelButtonText: "Cancelar",
          showCancelButton: true
        });
        if (!userInput.value) {
          return;
        }
      }

      await this.$root.$emit("show-loading");

      let newRefund = {};
      try {
        newRefund = await this.createRefundTicket({
          arquivos: this.newRefund.anexos.map(pic => ({
            url: pic.source,
            tipo: ""
          })),
          beneficiario: this.newRefund.beneficiario,
          contacorrente: this.newRefund.contacorrente,
          observacao: this.newRefund.observacao
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }

      if (newRefund && newRefund.Data) {
        this.protocoloANS = newRefund.Data.protocoloANS;
        this.showModal = true;
        this.prazos = newRefund.Data.prazos;
      } else {
        await this.$toast({
          description: "Não foi possivel registrar o atendimento.\nServiço temporariamente indisponível.",
          status: "error",
          duration: 10000,
          position: "top"
        });
      }
    },
    handleChange(val) {
      this.obs = val;

      this.SET_NEW_REFUND({
        ...this.newRefund,
        observacao: val
      });
    },
    async onModalClose() {
      this.SET_NEW_REFUND(null);
      this.$router.push({
        name: "refund.new"
      });
    },
    async onSave(data) {
      await this.$root.$emit("show-loading");
      try {
        this.SET_NEW_REFUND(null);
        await this.saveExpRating(data);
      } finally {
        await this.$root.$emit("hide-loading");
        this.$router.push({
          name: "refund.new"
        });
      }
    }
  }
};
</script>
