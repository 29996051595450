<template>
  <omint-page>
    <Header name="Documentos de Apoio" titleLabel="Documentos de Apoio" backRoute="/" />
    <omint-page-content :message="error">
      <div v-if="myFiles && myFiles.length > 0">
        <div v-for="(file, idx) in myFiles" :key="'sec-' + idx">
          <omt-divider v-if="idx !== 0" />
          <omt-h5>
            <b>{{ file.name }}</b>
          </omt-h5>
          <div v-if="isMobile">
            <a v-for="(f, i) in file.itemCollection" :key="idx + 'files-' + i" :href="f.Url" target="_blank">
              {{ f.Name }}
              <br />
            </a>
          </div>
          <div v-else>
            <a v-for="(f, i) in file.itemCollection" :key="idx + 'files-' + i" @click.prevent="openFile(f.Name, f.Url)">
              {{ f.Name }}
              <br />
            </a>
          </div>
        </div>
      </div>
    </omint-page-content>
    <Footer />
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";
import { mapState, mapActions, mapMutations } from "vuex";
import { isMobile } from "@/utils/utils";

export default {
  components: { Header, Footer },
  name: "MyFiles",
  created() {
    this.fetchData();
  },
  data() {
    return {
      error: null
    };
  },
  computed: {
    ...mapState("myFiles", ["myFiles"]),
    isMobile() {
      return isMobile();
    }
  },
  watch: {
    $route: "fetchData"
  },
  methods: {
    ...mapActions("myFiles", ["listFiles"]),
    ...mapMutations("myFiles", ["SET_DOC"]),
    async fetchData() {
      try {
        await this.$root.$emit("show-loading");
        await this.listFiles();
      } finally {
        if (!this.myFiles || this.myFiles.length === 0) {
          this.error = "Nenhum documento encontrado";
        }
        await this.$root.$emit("hide-loading");
      }
    },
    openFile(name, url) {
      this.SET_DOC({ name, url });
      this.$router.push("/arquivo");
    }
  }
};
</script>
