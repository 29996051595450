import Vue from "vue";
import { ListarFaturas, ObterFatura } from "@/api/associado";

const initialState = {
  statements: []
};

// vuex module
export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions: {
    async listStatements(context) {
      const statements = await ListarFaturas({
        vinculo: context.rootState.saude.vinculo
      });
      if (statements.Data !== null) {
        await statements.Data.forEach(s =>
          ObterFatura({
            vinculo: context.rootState.saude.vinculo,
            nrTitulo: s.NrTitulo
          }).then(url => (s.url = url))
        );
        await context.commit("SET_STATEMENTS", statements.Data);
      }
    }
  },
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    SET_STATEMENTS: (state, next) => Vue.set(state, "statements", next)
  }
};
