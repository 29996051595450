<template>
  <OmtLayoutHeaderFooter :bg="['none', '#F6F6F9']" :header="{ titleLabel: 'Extrado de coparticipação' }">
    <OmtGrid :mt="['0', '32px !important']">
      <OmtRow :mb="['8px', '27px']">
        <OmtTypography :as="['h1-bold', 'h4-bold']"> Extrato de Coparticipação </OmtTypography>
      </OmtRow>
      <OmtRow>
        <OmtTypography as="p2" color="var(--colors-light-primary-gray2)" :display="['block', 'none']">
          Seu extrato será emitido com as seguintes informações:
        </OmtTypography>
        <OmtTypography as="h1-semi-bold" :display="['none', 'block']" mb="24px"> Titular </OmtTypography>
      </OmtRow>
      <OmtRow>
        <CFlex :display="['none', 'flex']">
          <CBox mr="123px">
            <OmtTypography as="p1" color="var(--colors-light-primary-gray2)" mb="4px"> Nome </OmtTypography>
            <OmtTypography as="p3" color="var(--colors-light-primary-gray1)">{{
              capitalize(copayStatementResume.name)
            }}</OmtTypography>
          </CBox>
          <CBox>
            <OmtTypography as="p1" color="var(--colors-light-primary-gray2)" mb="4px"> Titulo </OmtTypography>
            <OmtTypography as="p3" color="var(--colors-light-primary-gray1)">
              {{ copayStatementResume.code }}</OmtTypography
            >
          </CBox>
        </CFlex>
        <CFlex align-items="center" :display="['flex', 'none']" mt="32px">
          <CBox mr="16px">
            <OmtIcons name="user" size="1x" />
          </CBox>
          <CBox flex="1">
            <CFlex direction="row" justify="space-between" align-items="center">
              <OmtTypography as="h3-bold"> {{ capitalize(copayStatementResume.name) }} </OmtTypography>
              <OmtTypography as="p2" color="var(--colors-light-primary-gray2)">
                {{ copayStatementResume.code }}
              </OmtTypography>
            </CFlex>
          </CBox>
        </CFlex>
      </OmtRow>
      <OmtRow>
        <router-view />
      </OmtRow>
    </OmtGrid>
  </OmtLayoutHeaderFooter>
</template>

<script>
import { capitalize } from "@/utils/utils";
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState("copay", ["copayStatementResume"])
  },
  methods: {
    ...mapActions("copay", ["fetchCopayStatements"]),
    capitalize(text) {
      return capitalize(text);
    }
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      await this.fetchCopayStatements();
    } catch (error) {
      console.error("copay resume load error", error);
    } finally {
      this.loading = false;
      this.$root.$emit("hide-loading");
    }
  }
};
</script>

<style scoped>
.with-margin-top {
  margin-top: 32px !important;
}
</style>
