import Auths from "@/pages/auths/index.vue";
import AuthsList from "@/pages/auths/list.vue";
import AuthsForm from "@/pages/auths/form.vue";
import AuthsDetail from "@/pages/auths/detail.vue";
import AuthsCreate from "@/pages/auths/create/index.vue";
import AuthsAttachments from "@/pages/auths/create/attachments.vue";
import AuthsObservation from "@/pages/auths/create/observation.vue";
import AuthsTicketDetail from "@/pages/auths/ticket-detail.vue";

const routes: Route[] = [
  {
    path: "/auths",
    alias: "/autorizacao-lista",
    component: Auths,
    params: true,
    children: [
      {
        path: "",
        name: "auths",
        component: AuthsList,
        meta: {
          backTo: "/",
          registerAccess: { servico: "Autorizações", funcionalidade: "Acesso a listagem de autorizações" },
          validPath: ["/auths", "/autorizacao-lista"]
        }
      },
      {
        path: "form/:idCC?",
        name: "auths.form",
        component: AuthsForm,
        props: true,
        meta: {
          backTo: "/autorizacao-lista",
          validPath: ["/auths", "/autorizacao-lista"]
        }
      },
      {
        path: "detail/:idx",
        name: "auths.detail",
        component: AuthsDetail,
        props: true,
        meta: {
          backTo: "/autorizacao-lista",
          validPath: ["/auths", "/autorizacao-lista"]
        }
      },
      {
        path: "create",
        name: "auths.create",
        component: AuthsCreate,
        props: true,
        meta: {
          backTo: "/autorizacao-lista",
          validPath: ["/auths", "/autorizacao-lista"]
        }
      },
      {
        path: "attachments",
        name: "auths.attachments",
        component: AuthsAttachments,
        props: true,
        meta: {
          backTo: "/auths/create",
          validPath: ["/auths", "/autorizacao-lista"]
        }
      },
      {
        path: "detail/ticket/:idx",
        name: "auths.ticket.detail",
        component: AuthsTicketDetail,
        props: true,
        meta: {
          backTo: "/autorizacao-lista",
          validPath: ["/auths", "/autorizacao-lista"]
        }
      },
      {
        path: "observation",
        name: "auths.observation",
        component: AuthsObservation,
        props: true,
        meta: {
          backTo: "/auths/attachments",
          validPath: ["/auths", "/autorizacao-lista"]
        }
      }
    ]
  }
];

export default routes;
