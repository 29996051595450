<script lang="jsx">
import Vue from "vue";
import typography, { options } from "./typography";
import { CText } from "@chakra-ui/vue";
import _ from "lodash";

export default Vue.component("OmtTypography", {
  props: {
    as: {
      type: String | Array,
      required: true,
      validator: value => {
        const flatedValue = [value].flat();
        const correctValues = _.intersection(flatedValue, options);

        if (correctValues.length !== flatedValue.length) {
          console.error(`Wrong typography option ${value}`);
          console.error(`Allowed typography options : ${options.join(" - ")}`);
        }
        return true;
      }
    },
    color: {
      type: String,
      default: "var(--colors-light-primary-gray1)"
    }
  },
  render() {
    const flatedValue = [this.as].flat();
    const typos = flatedValue.map(opt => typography[opt]);

    const getTypography = options =>
      options.reduce((acc, obj) => {
        Object.entries(obj).forEach(([key, value]) => {
          if (!acc[key]) acc[key] = [];
          if (!acc[key].includes(value)) acc[key].push(value);
        });
        return acc;
      }, {});

    const { as, fontSize, fontWeight, lineHeight } = getTypography(typos);

    return (
      <CText
        as={as[0]}
        {...{
          attrs: {
            fontSize,
            fontWeight,
            lineHeight,
            fontFamily: "Open Sans, Roboto, sans-serif",
            color: this.color,
            ...this.$attrs
          },
          on: { ...this.$listeners }
        }}
      >
        {this.$slots.default}
      </CText>
    );
  }
});
</script>
