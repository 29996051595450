<template>
  <div :class="'outer' + cssMediaType">
    <div ref="shareMe" :class="'cred cor-' + credType" v-if="numberT === null">
      <div
        v-if="!this.plan.startsWith('K ')"
        class="ans-number-large"
        :style="
          'background: url(' +
          this.baseUrl +
          '/images/logo_ans_numero_large.png) no-repeat center; background-position: center; background-size: 30%, 6%;'
        "
      ></div>
      <img :src="this.bg" class="img" alt="Fundo da Credencial" />
      <div class="font cred__number">{{ number }}</div>
      <div class="font cred__name">{{ name }}</div>
      <div class="font cred__ingress label">Ingresso</div>
      <div class="font cred__ingress value">{{ ingress }}</div>
      <div class="font cred__valid label">Validade</div>
      <div class="font cred__valid value">{{ valid }}</div>
      <div class="font cred__birth label">Nascimento</div>
      <div class="font cred__birth value">{{ birth }}</div>
      <div class="font cred__plan label">Plano</div>
      <div class="font cred__plan value">{{ plan }}</div>
      <div class="font cred__cns">{{ cns.replace(" (CNS)", "") }}</div>
    </div>
    <div ref="shareMe" :class="'cred cor-' + credType" v-else>
      <img :src="this.bg" class="img" alt="Fundo da Credencial" />
      <div class="font cred__number">{{ numberT }}</div>
      <div class="font cred__name">{{ name }}</div>
      <div class="font cred__valid_ms label">Validade</div>
      <div class="font cred__valid_ms value">{{ valid }}</div>
      <div class="font cred__plan_ms label">Plano</div>
      <div class="font cred__plan_ms value">{{ planT }}</div>
      <div class="font cred__type_ms label">Padrão</div>
      <div class="font cred__type_ms value">{{ accT }}</div>
      <div class="font cred__assis_ms label">Assistência</div>
      <div class="font cred__assis_ms value">
        {{ accTp.replace("ASSISTÊNCIA ", "") }}
      </div>
      <div class="font cred__cns_ms">{{ abrT }}</div>
    </div>
    <div v-if="showButtons" class="shareButtons">
      <CBox
        w="50%"
        d="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        flexFlow="wrap"
        @click="validateClickWallet()"
        v-if="(iOS && v24) || (iOS && !v24)"
      >
        <OmtIcons name="credit-card-blank" size="xl" mb="2" />
        <OmtTypography w="100%" as="h3-bold">Adicionar<br />a Wallet</OmtTypography>
      </CBox>
      <CBox
        w="50%"
        d="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        flexFlow="wrap"
        @click="validateClickCredencial()"
        cursor="pointer"
      >
        <OmtIcons name="arrow-up-from-bracket" size="xl" mb="2" />
        <OmtTypography w="100%" as="h3-bold">Compartilhar<br />Credencial</OmtTypography>
      </CBox>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import * as utils from "@/utils/downloadUtil";
import OmintOTP from "@/utils/otpToken";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { RequestWallet } from "@/api/api";

const otp = new OmintOTP();

export default {
  name: "Credential",
  props: [
    "id",
    "number",
    "name",
    "ingress",
    "valid",
    "birth",
    "plan",
    "cns",
    "bg",
    "credType",
    "idx",
    "numberT",
    "planT",
    "accT",
    "accTp",
    "abrT",
    "disableToken",
    "cssMediaType"
  ],
  computed: {
    ...mapGetters("saude", ["baseUrl", "baseApi"]),
    ...mapGetters("biometrics", ["onNative"]),
    ...mapState("biometrics", ["nativeShareEnabled"]),
    webShareApiSupported() {
      return navigator.share;
    },
    webShareApiFileSupported() {
      return navigator.canShare && !this.iOS;
    },
    iOS() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    v24() {
      return this.onNative && Number.parseInt(localStorage.getItem("native_version")) >= 24;
    }
  },
  data: function () {
    return {
      timer: null,
      token: 0,
      showButtons: false,
      shareUrl: "",
      urlWallet: ""
    };
  },
  async mounted() {
    this.showButtons = this.$route.name !== "offlinecredential" && this.$route.name !== "shared";
    if (this.timer !== null || this.number === "" || !this.number) return;
    const n = this.number.split(" ");
    const secret = `${n[0]}${n[1]}`;
    this.setToken(secret);
    this.timer = setInterval(() => this.setToken(secret), 60 * 1000);
    if (this.idx !== -1) {
      const links = await this.fetchCredentialsLinks(this.id);
      this.urlWallet = `${this.baseApi}${links.urlWallet}`;
      this.shareUrl = `${document.location.protocol}//${document.location.host}${this.baseUrl}/shared/${links.shareUrl}`;
    }
  },
  methods: {
    ...mapActions("biometrics", ["shareImage"]),
    ...mapActions("saude", ["fetchCredentialsLinks"]),
    setToken: function (secret) {
      this.token = otp.getTokenOtp(secret);
      this.$emit("token", this.token);
    },
    validateClickWallet() {
      if (this.iOS && this.v24) this.wallet(this.urlWallet);
      if (this.iOS && !this.v24) window.open(this.urlWallet, "_blank");
    },
    validateClickCredencial() {
      if (this.webShareApiFileSupported || (this.nativeShareEnabled && this.v24)) {
        this.shareViaWebFileShare();
      } else if (this.webShareApiSupported) {
        this.shareViaWebShare();
      } else {
        this.shareDefault();
      }
    },
    async shareDefault() {
      try {
        this.$root.$emit("show-loading");
        const url = this.shareUrl;
        if (!url || url === `${document.location.protocol}//${document.location.host}${this.baseUrl}/shared/`) return;
        const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        const twitterURL = `https://twitter.com/intent/tweet?text=${url}`;
        const linkedInURL = `https://www.linkedin.com/shareArticle?mini=true&amp;url=${url}&amp;title=Credencial Omint&amp;summary=&amp;source=`;
        const whatsAppURL = `https://api.whatsapp.com/send?text=${url}`;
        const mailToURL = `mailto:?body=${url}`;
        this.$root.$emit("hide-loading");
        Swal.fire({
          html: `<div class="link-list" onclick="document.querySelector('.swal2-container').click();">
          <a href="${facebookURL}" target="_blank">
            <i class="fab fa-facebook color-facebook"></i>
            <span>Facebook</span>
            <i class="fa fa-angle-right"></i>
          </a>
          <a href="${twitterURL}" target="_blank" onclick="document.querySelector('.swal2-container').click();">
            <i class="fab fa-twitter-square color-twitter"></i>
            <span>Twitter</span>
            <i class="fa fa-angle-right"></i>
          </a>
          <a href="${linkedInURL}" target="_blank" onclick="document.querySelector('.swal2-container').click();">
            <i class="fab fa-linkedin color-linkedin"></i>
            <span>LinkedIn</span>
            <i class="fa fa-angle-right"></i>
          </a>
          <a href="${whatsAppURL}" target="_blank" data-action="share/whatsapp/share" onclick="document.querySelector('.swal2-container').click();">
            <i class="fab fa-whatsapp-square color-whatsapp"></i>
            <span>WhatsApp</span>
            <i class="fa fa-angle-right"></i>
          </a>
          <a href="${mailToURL}" target="_blank" onclick="document.querySelector('.swal2-container').click();">
            <i class="fa fa-envelope-square color-mail"></i>
            <span>Email</span>
            <i class="fa fa-angle-right"></i>
          </a>
        </div>`,
          showCancelButton: false,
          showConfirmButton: false,
          width: "200px"
        });
      } finally {
        this.$root.$emit("hide-loading");
      }
    },
    async shareViaWebFileShare() {
      try {
        this.$root.$emit("show-loading");
        const el = this.$refs.shareMe;
        const options = {
          type: "dataURL",
          useCORS: true,
          width: el.offsetWidth,
          height: el.offsetHeight
        };
        const dataURI = await this.$html2canvas(el, options);
        const blob = await utils.dataURItoBlob(dataURI);
        if (this.onNative && this.nativeShareEnabled && this.v24) {
          const base64 = await utils.blobToBase64(blob);
          await this.shareImage({ title: "credencial.png", data: base64 });
        } else {
          const file = new File([blob], "credencial.png", {
            type: "image/png"
          });
          const filesArray = [file];
          await navigator.share({
            files: filesArray
          });
        }
      } finally {
        this.$root.$emit("hide-loading");
      }
    },
    async shareViaWebShare() {
      await navigator.share({
        title: "Credencial Omint",
        url: this.shareUrl
      });
    },
    async wallet(walletUrl) {
      const data = await RequestWallet(walletUrl);
      if (data) {
        await utils.downloadUrlWithName(walletUrl, "wallet.pkpass");
        await utils.downloadBlobWithName(data, "wallet.pkpass");
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>
<style scoped>
/** $proporcao: 1532/974; = 1.572895277207392**/
.ans-number-large {
  position: absolute;
  height: 6%;
  width: 100%;
  top: 93%;
  left: 30%;
  z-index: 1;
}

.cor-prata * {
  color: #a7a9ac;
}

.cor-ouro * {
  color: #876e3d;
}

.cor-azul * {
  color: #041534;
}

.cor-branco * {
  color: #fff;
}

.cred {
  background-color: transparent;
  position: relative;
  max-width: 600px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

.cred__assis_ms {
  position: absolute;
  left: 65%;
  white-space: nowrap;
}

.cred__birth {
  position: absolute;
  left: 52%;
}

.cred__cns {
  position: absolute;
  left: 7%;
  width: 100%;
  top: 91%;
  font-size: min(2.2vw, 14.336px);
}

.cred__cns_ms {
  position: absolute;
  left: 7%;
  width: 60%;
  top: 89%;
  line-height: initial;
  font-size: min(2.2vw, 14.336px);
}

.cred__ingress {
  position: absolute;
  left: 7%;
}

.cred__name {
  position: absolute;
  top: 54%;
  left: 7%;
  width: 80%;
  font-weight: normal;
  font-size: min(4.7vw, 30.72px);
  line-height: min(4.7vw, 30.72px);
  overflow: hidden;
  height: 16%;
}

.cred__number {
  position: absolute;
  top: 42%;
  left: 7%;
  width: 80%;
  font-weight: 500;
  font-size: min(7vw, 46.08px);
  line-height: min(7vw, 46.08px);
}

.cred__plan {
  position: absolute;
  left: 80%;
}

.cred__plan_ms {
  position: absolute;
  left: 25%;
}

.cred__type_ms {
  position: absolute;
  left: 40%;
}

.cred__valid {
  position: absolute;
  left: 29%;
}

.cred__valid_ms {
  position: absolute;
  left: 7%;
}

.font {
  font-family: "Roboto" !important;
  text-transform: uppercase;
}

.img {
  display: block;
  max-width: 100%;
  object-fit: contain;
}

.label {
  top: 72%;
  font-size: min(2.5vw, 16.384px);
}

.outer {
  max-width: 938px;
  z-index: -1;
}

.value {
  top: 78%;
  font-weight: 500;
  font-size: min(2.5vw, 16.384px);
  padding-top: 0%;
  margin-top: 0%;
}

.ans-number-large__ms {
  position: absolute;
  top: 85%;
  left: -29%;
  height: 6%;
  width: 100%;
  z-index: 1;
}

.shareButtons {
  position: fixed;
  bottom: 60px;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
}

@media only screen and (((orientation: portrait) and (max-height: 488px)) or ((orientation: landscape) and (max-height: 500px))) {
  .cred {
    max-height: calc(100vh - 110px);
  }

  .cred__cns,
  .cred__cns_ms {
    font-weight: 300;
    font-size: min(2.8vh, 14.336px);
  }

  .cred__name {
    font-size: min(6vh, 30.72px);
    line-height: min(6vh, 30.72px);
  }

  .img {
    max-height: calc(100vh - 110px);
  }

  .label,
  .value {
    font-size: min(3.2vh, 16.384px);
  }

  .outer {
    max-width: calc((100vh - 110px) * 1.572895277207392);
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .cred__number {
    font-size: min(9vh, 46.08px);
    line-height: min(9vh, 46.08px);
  }
}

@media only screen and (((orientation: portrait) and (max-height: 488px))  or ((orientation: landscape) and (max-height: 635px))) {
  .shareButtons {
    position: relative;
    margin: 90px 0px 30px;
  }
}
</style>
