import { importModuleScript } from "@/utils/scriptUtil";
import { bus } from "../../../../main";

export default {
  data() {
    return {
      unicoCameraBuilder: null,
      unicoConfig: null,
      selfieCameraTypes: null
    };
  },
  computed: {
    publicPath() {
      return process.env.BASE_URL;
    },
    projectNumber() {
      return process.env.VUE_APP_UNICO_PROJECT_NUMBER ?? "";
    },
    projectId() {
      return process.env.VUE_APP_UNICO_PROJECT_ID ?? "";
    },
    mobileSdkAppId() {
      return process.env.VUE_APP_UNICO_MOBILE_SDK_APP_ID ?? "";
    },
    hostname() {
      return process.env.VUE_APP_UNICO_HOSTNAME ?? "";
    },
    hostInfo() {
      return process.env.VUE_APP_UNICO_HOST_INFO ?? "";
    },
    hostKey() {
      return process.env.VUE_APP_UNICO_HOST_KEY ?? "";
    },
    apiJS() {
      return `${this.publicPath}scripts/unico-webframe-3.18.6.min.js`;
    }
  },
  async created() {
    if (!this.projectNumber) {
      if (window) window.$log.error("Sem parametrização para biometria");
      return;
    }
    await importModuleScript(
      document,
      this.apiJS,
      `import { UnicoConfig, UnicoCheckBuilder, SelfieCameraTypes, UnicoThemeBuilder } from "${this.apiJS}";
      window.bus.$emit("unico:builder", { UnicoConfig, UnicoCheckBuilder, SelfieCameraTypes, UnicoThemeBuilder });`
    );
    bus.$on("unico:builder", ({ UnicoConfig, UnicoCheckBuilder, SelfieCameraTypes, UnicoThemeBuilder }) => {
      this.unicoConfig = new UnicoConfig()
        .setProjectNumber(this.projectNumber)
        .setProjectId(this.projectId)
        .setMobileSdkAppId(this.mobileSdkAppId)
        .setHostname(this.hostname)
        .setHostInfo(this.hostInfo)
        .setHostKey(this.hostKey);

      this.unicoCameraBuilder = new UnicoCheckBuilder();
      this.unicoCameraBuilder.setResourceDirectory(`${this.publicPath}resources`);
      this.unicoCameraBuilder.setModelsPath(`${this.hostname}${this.publicPath}models`);

      const unicoTheme = new UnicoThemeBuilder()
        .setColorSilhouetteSuccess("#00C37D")
        .setColorSilhouetteError("#C31700")
        .setColorSilhouetteNeutral("#fff")
        .setBackgroundColor("#fff")
        .setColorText("#4E4E4E")
        .setBackgroundColorComponents("#172972")
        .setColorTextComponents("#FFF")
        .setBackgroundColorButtons("#172972")
        .setColorTextButtons("#FFF")
        .setBackgroundColorBoxMessage("#fff")
        .setColorTextBoxMessage("#4E4E4E")
        .setHtmlPopupLoading(
          `<div style="position: absolute; top: 50%; right: 50%; transform: translate(50%, -50%); z-index: 10; text-align: center;">Carregando...</div>`
        )
        .build();
      this.unicoCameraBuilder.setTheme(unicoTheme);

      this.selfieCameraTypes = SelfieCameraTypes;
      this.initCameraUnico();
    });
  },
  destroyed() {
    bus.$off("unico:builder");
  }
};
