<template>
  <c-modal :is-open="true" display="relative">
    <c-modal-content ref="content" h="0">
      <c-modal-body>
        <div class="modal">
          <c-box :key="'modal-' + requestCount">
            <omt-highlight> Antes de continuar, os seguintes recursos do seu aparelho são necessários: </omt-highlight>
            <ul>
              <omt-li v-if="useCamera && camera !== 'granted'">
                Permissão para uso da
                <omt-bold> câmera </omt-bold>
              </omt-li>
              <omt-li v-if="useMic && microfone !== 'granted'">
                Permissão para uso do <omt-bold>microfone</omt-bold>
              </omt-li>
            </ul>
            <br />
            <omt-buttons-bar>
              <omt-button v-if="onNative" :onClick="requestPermission"> Abrir permissões do celular </omt-button>
              <omt-button v-else :onClick="exit" :colorInverse="true"> Sair </omt-button>
            </omt-buttons-bar>
          </c-box>
        </div>
      </c-modal-body>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { CBox, CModal, CModalBody, CModalContent, CModalOverlay } from "@chakra-ui/vue";

export default {
  name: "ResouceModal",
  components: { CBox, CModal, CModalOverlay, CModalContent, CModalBody },
  props: {
    useCamera: {
      type: Boolean,
      default: true
    },
    useMic: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      requestCount: 0,
      timer: null
    };
  },
  computed: {
    ...mapGetters("biometrics", ["onNative"]),
    ...mapState("biometrics", ["camera", "microfone"])
  },
  methods: {
    ...mapActions("biometrics", ["checkPermissionForResource", "openAppSettings"]),
    requestPermission() {
      this.openAppSettings();
      this.requestCount += 1;
      if (this.timer === null) {
        this.timer = setInterval(() => this.testPermissions(), 1000);
      }
    },
    limparTimer() {
      if (this.timer !== null) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    testPermissions() {
      this.checkPermissionForResource("camera");
      this.checkPermissionForResource("microfone");
    },
    exit() {
      this.$router.push("/");
    }
  },
  beforeDestroy() {
    this.limparTimer();
  }
};
</script>

<style scoped>
.modal {
  position: absolute;
  right: 0;
  top: 25vh;
  min-width: 25vh;
  padding: 0.625rem;
  margin: 1.5rem;
  background-color: #f0f0f0;
}
</style>
