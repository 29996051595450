<template>
  <omint-page-content v-if="task">
    <div v-if="displayTerms">
      <div v-if="task.Optin" v-html="task.Optin"></div>
      <omt-buttons-bar>
        <omt-button :onClick="hideTerms"> Fechar </omt-button>
      </omt-buttons-bar>
    </div>
    <div v-else>
      <h1 class="title" v-if="isIndicacao">Agende seu horário</h1>
      <h1 class="title" v-else>Agendamento de consulta</h1>
      <h2 class="option">
        {{ task.BeneficiarioBloqueado ? "Associado para atendimento" : "Selecione o associado para atendimento" }}
      </h2>
      <Options
        id="optBeneficiaryId"
        name="optBeneficiary"
        :solid="true"
        :disabled="task.BeneficiarioBloqueado || isLoading"
        :isLoading="isLoading"
        :preSelected="task.Beneficiario"
        :options="beneficiariosDisponiveis"
        :onSelection="onBeneficiarySelection"
        placeholder="Selecione o associado"
      />

      <h2 class="option">
        {{ task.LocalBloqueado ? "Local para atendimento" : "Selecione o local para atendimento" }}
      </h2>
      <Options
        id="optLocalId"
        name="optLocal"
        :solid="true"
        :disabled="task.LocalBloqueado || isLoading"
        :isLoading="isLoading"
        preSelected="single"
        :options="locaisDisponiveis"
        :onSelection="onLocalSelection"
        placeholder="Selecione o local"
        class="optLocal"
      />
      <p class="selected-address" v-if="selectedAddress">{{ selectedAddress }}</p>

      <h2 class="option">
        {{
          task.EspecialidadeBloqueada ? "Especialidade para atendimento" : "Selecione a especialidade para atendimento"
        }}
      </h2>
      <Options
        id="optSpecialityId"
        name="optSpeciality"
        :solid="true"
        :disabled="task.EspecialidadeBloqueada || isLoading"
        :isLoading="isLoading"
        preSelected="single"
        :options="especialidadesDisponiveis"
        :onSelection="onSpecialitySelection"
        placeholder="Selecione a especialidade"
      />

      <h2 class="option" v-if="task.StaffBloqueado">Nome do profissional</h2>
      <Options
        v-if="task.StaffBloqueado"
        id="optStaffId"
        name="optStaff"
        :solid="true"
        :disabled="true"
        :isLoading="isLoading"
        preSelected="single"
        :options="staffsDisponiveis"
        :onSelection="onStaffSelection"
        placeholder="Selecione o profissional"
      />

      <div v-show="false">
        <div ref="sucessMessage" class="sucessMessage">
          <i class="fas fa-2x fa-check-circle"></i>
          <h2>Agendamento realizado com sucesso!</h2>
          <p>Seu agendamento foi realizado com sucesso e já está disponível na área <b>Minhas Atividades</b>.</p>
          <p>
            Caso seja necessário cancelar ou reagendá-lo, clique em <b>Ver detalhes</b> e escolha a nova data para
            atendimento.
          </p>
        </div>
      </div>

      <v-calendar
        ref="calendar"
        titlePosition="center"
        is-expanded
        v-chakra
        mb="24px"
        :color="theme === 'dark' ? 'black' : 'indigo'"
        :is-dark="theme === 'dark'"
        :attributes="calendarAttributes"
        :available-dates="datasDisponiveis"
        @dayclick="onDayClick"
      />

      <BlockList v-if="!isLoading && horariosDisponiveis.length > 0">
        <div class="time-box">
          <h2 class="option">Selecione entre os horários disponíveis</h2>
          <div class="time-options-box">
            <div
              :class="selectedTime === s ? 'selected-time' : 'time-option'"
              v-for="s in horariosDisponiveis"
              :key="s"
              @click="onTimeClick(s)"
            >
              {{ s }}
            </div>
          </div>
        </div>
      </BlockList>

      <div v-if="!isLoading && !task.StaffBloqueado && selectedTime" class="staff-box">
        <h2 class="option">Selecione o nome do profissional</h2>
        <Options
          id="optStaffId"
          name="optStaff"
          :solid="true"
          :disabled="isLoading"
          :isLoading="isLoading"
          :preSelected="selectedStaff"
          :options="staffsDisponiveis"
          :onSelection="onStaffSelection"
          placeholder="Selecione o profissional"
        />
      </div>

      <div class="term-box">
        <SwitchCheckBox :disabled="!this.selectedBeneficiary" :onChange="val => (termsCheck = val)" :rounded="true" />
        <span @click.prevent="showTerms()">
          Li os <u>termos gerais (consentimento livre e esclarecido)</u>. Compreendi, concordo e aceito.
        </span>
      </div>

      <omt-buttons-bar>
        <omt-button class="submit-button" :onClick="onSubmit" :colorInverse="true" :disabled="disableButton">
          Agendar
        </omt-button>
      </omt-buttons-bar>
    </div>
    <br />
  </omint-page-content>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import BlockList from "@/components/Molecules/BlockList";
import Options from "@/components/Molecules/Options";
import SwitchCheckBox from "@/components/Molecules/SwitchCheckBox";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "Schedule",
  data() {
    return {
      chamado: 0,
      prestador: "",
      prevRoute: "",
      task: null,
      agenda: null,
      displayTerms: false,
      termsCheck: false,
      selectedBeneficiary: "",
      selectedLocal: "",
      selectedAddress: "",
      selectedSpeciality: "",
      selectedStaff: "",
      selectedDate: "",
      selectedTime: "",
      diaAgenda: null,
      horariosDisponiveis: [],
      isLoading: true,
      calendarAttributes: [
        {
          key: "selected-date",
          highlight: true,
          dates: null
        }
      ]
    };
  },
  components: {
    Options,
    BlockList,
    SwitchCheckBox
  },
  computed: {
    ...mapGetters("saude", ["defaultRoute"]),
    ...mapState("saude", ["theme"]),
    isEquipeSaude() {
      return this.$route.name === "tasks.equipe";
    },
    isIndicacao() {
      return this.$route.name === "tasks.indicacao";
    },
    isPrestador() {
      return this.$route.name === "tasks.prestador";
    },
    beneficiariosDisponiveis() {
      if (!this.task?.Beneficiarios) return [];
      return this.task.Beneficiarios.map(l => ({
        value: l.Codigo,
        label: l.Nome
      }));
    },
    locaisDisponiveis() {
      if (!this.task?.Locais) return [];
      return this.task.Locais.map(l => ({
        value: l.Id,
        label: l.Nome
      }));
    },
    especialidadesDisponiveis() {
      if (!this.task?.Especialidades) return [];
      return this.task.Especialidades.map(l => ({
        value: l.Id,
        label: l.Nome
      }));
    },
    staffsDisponiveis() {
      if (!this.task?.Staffs) return [];
      if (this.task?.StaffBloqueado)
        return this.task.Staffs.map(s => ({
          value: s.Id,
          label: s.Nome
        }));

      const horario = this.diaAgenda?.Horarios?.find(h => h.Hora === this.selectedTime);
      if (!horario?.Staffs) return [];
      const staffsValidos = this.task.Staffs.filter(s => horario.Staffs.find(sh => sh.IdStaff === s.Id));
      const staffs = staffsValidos.map(s => ({
        value: s.Id,
        label: s.Nome
      }));
      return staffs;
    },
    datasDisponiveis() {
      if (!this.agenda) return new Date(1, 0, 0);
      const dias = this.agenda.map(d => new Date(d.Ano, d.Mes - 1, d.Dia)) ?? [];
      return dias.length > 0 ? dias : new Date(1, 0, 0);
    },
    tipoOperacao() {
      if (this.isEquipeSaude) return 1;
      if (this.isIndicacao) return 2;
      if (this.isPrestador) return 3;
      return 0;
    },
    disableButton() {
      if (!this.termsCheck) return true;
      if (!this.selectedBeneficiary) return true;
      if (!this.selectedLocal) return true;
      if (!this.selectedSpeciality) return true;
      if (!this.selectedStaff) return true;
      if (!this.diaAgenda?.Dia) return true;
      if (!this.diaAgenda?.Mes) return true;
      if (!this.diaAgenda?.Ano) return true;
      if (!this.selectedTime) return true;
      return false;
    }
  },
  methods: {
    ...mapActions("tasks", ["featchTaskRules", "featchCalendar", "createTask"]),
    hideTerms() {
      this.displayTerms = false;
    },
    showTerms() {
      window.scrollTo(0, 0);
      this.displayTerms = true;
    },
    async onBeneficiarySelection(value) {
      const alterarLoading = !this.isLoading;
      try {
        if (alterarLoading) this.isLoading = true;
        if (value && value !== this.selectedBeneficiary) {
          this.selectedBeneficiary = value;
          if (alterarLoading) await this.iniciarCalendario();
        }
      } finally {
        if (alterarLoading) this.isLoading = false;
      }
    },
    async onLocalSelection(value) {
      const alterarLoading = !this.isLoading;
      try {
        if (alterarLoading) this.isLoading = true;
        if (value && value !== this.selectedLocal) {
          const localObj = this.task.Locais.find(l => l.Id === value);
          this.selectedLocal = value;
          this.selectedAddress = localObj.Endereco;

          if (alterarLoading) await this.iniciarCalendario();
        }
      } finally {
        if (alterarLoading) this.isLoading = false;
      }
    },
    async onSpecialitySelection(value) {
      const alterarLoading = !this.isLoading;
      try {
        if (alterarLoading) this.isLoading = true;
        if (value && value !== this.selectedSpeciality) {
          this.selectedSpeciality = value;
          if (alterarLoading) await this.iniciarCalendario();
        }
      } finally {
        if (alterarLoading) this.isLoading = false;
      }
    },
    async onStaffSelection(value) {
      const alterarLoading = !this.isLoading;
      try {
        if (alterarLoading) this.isLoading = true;
        if (value && value !== this.selectedStaff) {
          this.selectedStaff = this.staffsDisponiveis?.find(s => s.value === value) ? value : "";
        }
        if (!this.selectedStaff && this.staffsDisponiveis?.length === 1) {
          this.selectedStaff = this.staffsDisponiveis[0].value;
        }
      } finally {
        if (alterarLoading) this.isLoading = false;
      }
    },
    async onDayClick(value) {
      const alterarLoading = !this.isLoading;
      try {
        if (alterarLoading) this.isLoading = true;
        if (value?.date && value.date !== this.selectedDate) {
          this.diaAgenda = this.agenda?.find(d => d.Dia === value.day && d.Mes === value.month && d.Ano === value.year);
          this.selectedDate = value.date;
          this.selectedTime = "";
          if (this.diaAgenda) {
            this.horariosDisponiveis = this.diaAgenda.Horarios?.map(h => h.Hora) ?? [];
            this.calendarAttributes[0].dates = value.date;
            if (this.horariosDisponiveis.length === 1) {
              await this.onTimeClick(this.horariosDisponiveis[0]);
            }
          }
          window.scrollTo(0, document.body.scrollHeight);
        }
      } finally {
        if (alterarLoading) this.isLoading = false;
      }
    },
    async onTimeClick(value) {
      const alterarLoading = !this.isLoading;
      try {
        if (alterarLoading) this.isLoading = true;
        if (value && value !== this.selectedTime) {
          const currentStaff = this.selectedStaff;
          this.selectedTime = value;
          if (!this.task.StaffBloqueado) {
            this.selectedStaff = "";
            window.scrollTo(0, document.body.scrollHeight);
            this.$nextTick(() => this.onStaffSelection(currentStaff));
          }
        }
      } finally {
        if (alterarLoading) this.isLoading = false;
      }
    },
    async iniciarCalendario() {
      const alterarLoading = !this.isLoading;
      try {
        if (alterarLoading) this.isLoading = true;
        if (this.selectedBeneficiary && this.selectedLocal && this.selectedSpeciality) {
          this.agenda = null;
          this.selectedDate = "";
          this.selectedTime = "";
          this.diaAgenda = null;
          this.horariosDisponiveis = [];
          this.calendarAttributes[0].dates = null;
          if (!this.task.StaffBloqueado) {
            this.selectedStaff = "";
          }
          var agenda = await this.featchCalendar({
            beneficiario: this.selectedBeneficiary,
            local: this.selectedLocal,
            especialidade: this.selectedSpeciality,
            staff: this.task.StaffBloqueado ? this.selectedStaff : 0
          });
          this.task.Staffs = agenda?.Staffs ?? [];
          this.agenda = agenda?.DiasDisponiveis ?? [];
          window.scrollTo(0, document.body.scrollHeight);
        }
      } finally {
        if (alterarLoading) this.isLoading = false;
      }
    },
    async onSubmit() {
      if (this.disableButton) return;
      try {
        await this.$root.$emit("show-loading");
        const horario = this.diaAgenda?.Horarios?.find(h => h.Hora === this.selectedTime);
        const staff = horario.Staffs.find(sh => sh.IdStaff === this.selectedStaff);

        const chamadoGerado = await this.createTask({
          beneficiario: this.selectedBeneficiary,
          local: this.selectedLocal,
          especialidade: this.selectedSpeciality,
          staff: this.selectedStaff,
          dia: this.diaAgenda.Dia,
          mes: this.diaAgenda.Mes,
          ano: this.diaAgenda.Ano,
          hora: this.selectedTime,
          duracao: null,
          chamado: this.chamado,
          reserva: this.task.Reserva,
          slot: staff.Slot,
          tipo: this.tipoOperacao
        });
        if (chamadoGerado.MensagemProcessamento) {
          await this.$root.$emit("hide-loading");
          Swal.fire({
            icon: "error",
            text: chamadoGerado.MensagemProcessamento,
            confirmButtonText: "OK"
          });
        } else {
          await this.$root.$emit("hide-loading");
          Swal.fire({
            html: this.$refs.sucessMessage,
            confirmButtonText: "Concluir",
            showCloseButton: true
          }).then(async () => {
            this.$router.push(this.defaultRoute);
          });
        }
      } catch (error) {
        await this.$root.$emit("hide-loading");
        Swal.fire({
          icon: "error",
          text: "Serviço temporariamente indisponível",
          confirmButtonText: "OK"
        });
        window.$log.error("Erro ao criar a tarefa", error);
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    async iniciarRegras() {
      if (this.isPrestador && !this.prestador) return false;
      const task = await this.featchTaskRules({
        chamado: this.chamado,
        prestador: this.prestador,
        tipo: this.tipoOperacao
      });
      if (!task) return false;
      if (task.MensagemProcessamento) return false;
      if (!task.Beneficiarios?.length) return false;
      if (!task.Locais?.length) return false;
      if (!task.Especialidades?.length) return false;
      if (task.DiasDisponiveis?.length) {
        this.agenda = task.DiasDisponiveis;
        task.DiasDisponiveis = null;
      }
      this.task = task;
      return true;
    },
    async initPage() {
      let regrasValidas = false;
      try {
        this.isLoading = true;
        this.prevRoute = this.$route.path;
        this.task = null;
        this.agenda = null;
        this.displayTerms = false;
        this.termsCheck = false;
        this.selectedBeneficiary = "";
        this.selectedLocal = "";
        this.selectedSpeciality = "";
        this.selectedStaff = "";
        this.selectedDate = "";
        this.selectedTime = "";
        this.diaAgenda = null;
        this.horariosDisponiveis = [];
        this.calendarAttributes = [
          {
            key: "selected-date",
            highlight: true,
            dates: null
          }
        ];

        regrasValidas = await this.iniciarRegras();
      } catch (error) {
        window.$log.error("Erro ao carregar a tarefa", error);
      } finally {
        if (regrasValidas) {
          await this.$root.$emit("hide-loading");
          this.isLoading = false;
        } else {
          this.$router.push(this.defaultRoute);
        }
      }
    }
  },
  async mounted() {
    await this.$root.$emit("show-loading");
    this.chamado = this.$route.query.chamado ? Number(this.$route.query.chamado) : 0;
    this.prestador = this.$route.params.prestador ? this.$route.params.prestador : "";
    await this.initPage();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.path;
      vm.chamado = to.query.chamado ? Number(to.query.chamado) : 0;
      vm.prestador = to.params.prestador ? to.params.prestador : "";
      vm.task = null;
    });
  },
  updated() {
    if (this.prevRoute !== this.$route.path) {
      this.$root.$emit("show-loading");
      this.$nextTick(() => this.initPage());
    }
  }
};
</script>

<style scoped lang="scss">
.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 24px;
}
.big-font-size .title {
  font-size: 20px;
  line-height: 30px;
}
.ligth .title {
  color: var(--colors-light-primary-gray1);
}
.dark .title {
  color: #f0f0f0;
}
.staff-box {
  margin-top: 12px;
}
.option {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 16px;
}
h2.option {
  margin-bottom: 0.5rem;
}
.optLocal.option {
  margin-bottom: 4px;
}
.big-font-size .option {
  font-size: 18px;
}
.light .option {
  color: var(--colors-light-primary-gray1);
}
.dark .option {
  color: #f0f0f0;
}
.time-box {
  padding: 16px;
}
.time-options-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
}

.time-option,
.selected-time {
  padding: 7px;
  height: 32px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  cursor: pointer;
}
.big-font-size .time-option,
.big-font-size .selected-time {
  height: 20px;
  font-size: 15px;
  line-height: 20px;
}
.light .time-option {
  border: 1px solid #68788c;
}
.dark .time-option {
  border: 1px solid #f0f0f0;
}
.light .selected-time {
  background-color: #68788c;
  color: #f0f0f0;
  border: 1px solid #68788c;
}
.dark .selected-time {
  background-color: #f0f0f0;
  color: var(--colors-light-primary-gray1);
  border: 1px solid #f0f0f0;
}
.submit-button {
  width: 100%;
  margin: 25px 0;
}
.sucessMessage {
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  & i {
    color: var(--colors-light-primary-gray1);
  }
  & h2 {
    font-size: 15px;
    font-weight: 700;
    line-height: 16px;
    color: var(--colors-light-primary-gray1);
    margin-bottom: 14px;
  }
  & p {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: var(--colors-light-primary-gray1);
  }
  & b {
    font-weight: 700;
    line-height: 17px;
  }
}
.term-box {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-top: 15px;
  & div {
    flex-basis: 70px;
  }
  & span {
    padding-left: 1rem;
    width: 100%;
  }
}

.selected-address {
  margin-left: 5px;
  margin-bottom: 16px;
}
</style>
