<template>
  <OmtGrid v-if="auth && auth.idAutorizacao">
    <OmtRow>
      <OmtTypography as="h1-bold" mb="24px"> Protocolo {{ auth.protocoloANS }} </OmtTypography>
    </OmtRow>

    <OmtRow :mobileEnd="2" :desktopEnd="5" mb="14px">
      <TextTile v-bind="{ title: 'Nº autorização', description: `${auth.idAutorizacao}` }" />
    </OmtRow>

    <OmtRow :mobileStart="3" :desktopStart="6">
      <TextTile v-bind="{ title: 'Data da solicitação', description: auth.dtSolicitacao }" />
    </OmtRow>

    <OmtRow>
      <CDivider mt="14px" mb="16px" />
    </OmtRow>

    <OmtRow>
      <OmtTypography as="h2-bold" mb="16px"> Dados do beneficiário </OmtTypography>
    </OmtRow>

    <OmtRow :desktopEnd="5" mb="16px">
      <TextTile v-bind="{ title: 'Beneficiário', description: auth.dsBeneficiario }" />
    </OmtRow>

    <OmtRow :desktopEnd="5" mb="16px">
      <TextTile v-bind="{ title: 'Código beneficiário', description: auth.idBeneficiario }" />
    </OmtRow>

    <OmtRow mb="16px">
      <StatusTile v-bind="{ title: 'Status', color: colorSituacao, label: auth.dsSituacao }" />
    </OmtRow>

    <OmtRow mb="16px" v-if="motivosDevolucao.length">
      <TextTile v-bind="{ title: 'Motivo', description: motivosDevolucao[0].dsObservacao }" />
    </OmtRow>

    <OmtRow mb="16px" v-if="pendencyToAnswer.devolutionGroups">
      <TextTile v-if="pendencyToAnswer.devolutionGroups.length > 1" v-chakra v-bind="{ title: 'Pendências' }" />

      <CBox v-for="(devolutionGroup, dgIndex) in pendencyToAnswer.devolutionGroups" :key="dgIndex">
        <OmtTypography as="p1-bold" mb="14px">
          {{ devolutionGroup.Grupo }}
        </OmtTypography>

        <CBox ml="20px" v-for="(devolutionReason, drIndex) in devolutionGroup.Motivos" :key="drIndex">
          <OmtTypography as="p1" mb="14px">
            <omt-bold v-if="devolutionGroup.Motivos.length > 1">{{ drIndex + 1 }}. </omt-bold>
            {{ devolutionReason }}
          </OmtTypography>
        </CBox>
      </CBox>
    </OmtRow>

    <OmtRow mb="16px" v-if="auth.tpSituacao === 'P' && pendencyDuesDate">
      <CBox bg="var(--colors-light-primary-light-gray)" p="10px 16px 16px 16px">
        <CFlex mb="16px">
          <OmtIcons name="circle-exclamation" mr="16px" />
          <OmtTypography as="h2-bold"> Atente-se ao prazo para regularização </OmtTypography>
        </CFlex>
        <OmtTypography as="p1">
          As informações ou documentos solicitados <b>devem ser enviados</b>
          <CText as="span" color="var(--colors-light-support-error)">
            <b>{{ pendencyDuesDate }}</b>
          </CText>
          . Após esse prazo, esta solicitação será automaticamente cancelada.
        </OmtTypography>
      </CBox>
    </OmtRow>

    <template v-if="auth.tpSituacao === 'L'">
      <OmtRow
        :desktopEnd="12"
        mb="14px"
        border=".5px solid var(--colors-light-secondary-divider)"
        border-radius="8px"
        py="19px"
        px="16px"
        v-for="(doc, index) in authDocs"
        :key="`doc-${index}`"
      >
        <CBox>
          <CFlex>
            <OmtIcons name="memo" mr="16px" />
            <OmtTypography as="h2-bold"> Autorização liberada </OmtTypography>
          </CFlex>
          <CFlex ps="27px" mb="16px" v-if="doc.validade">
            <OmtTypography as="p1">Validade {{ doc.validade }}</OmtTypography>
          </CFlex>
          <CFlex>
            <OmtButton w="100%" height="40px" fontSize="14px" @click="downloadAuth(doc.url)">
              Baixar autorização
            </OmtButton>
          </CFlex>
        </CBox>
      </OmtRow>
    </template>
    <OmtRow v-else-if="auth.tpSituacao === 'P'">
      <OmtButton height="40px" fontSize="14px" @click="showPendencyModal = true">Responder pendências</OmtButton>
    </OmtRow>

    <template v-if="pictures.length > 0">
      <OmtRow>
        <CDivider mt="14px" mb="16px" />
      </OmtRow>

      <OmtRow>
        <OmtTypography as="h2-bold" mb="8px"> Imagens enviadas </OmtTypography>
      </OmtRow>

      <OmtRow>
        <OmtTypography as="p1" mb="16px"> Pedidos médicos e relatórios </OmtTypography>
      </OmtRow>

      <OmtRow>
        <CGrid
          w="100%"
          :templateColumns="['repeat(auto-fill, minmax(74px, 1fr))', 'repeat(auto-fill, minmax(134px, 1fr))']"
          gap="6"
        >
          <ImagePreviewer v-for="(picture, index) in pictures" :key="index" v-bind="picture" />
        </CGrid>
      </OmtRow>
    </template>

    <AnswerPendencyModal
      :isOpen.sync="showPendencyModal"
      :protocol="protocolCreatedOnSave"
      :pendencyToAnswer.sync="pendencyToAnswer"
      :step="pendencyModalStep"
      :limitFiles="limitFiles"
      @savePendency="onSavePendency"
      @closeProtocolModal="onCloseProtocolModal"
      filesDescription="Insira somente imagem(ns) e/ou informações relacionadas às pendências solicitadas"
      protocolTitle="Informações foram enviadas para análise e serão analisadas em até 02 dias úteis"
      protocolMainTip="Você poderá acompanhar o status na tela de Autorizações"
      protocolSubTip=""
    />
  </OmtGrid>
</template>
<script>
import TextTile from "@/components/Molecules/TextTile";
import StatusTile from "@/components/Molecules/StatusTile";
import AnswerPendencyModal from "@/components/Molecules/AnswerPendencyModal/index.vue";
import ImagePreviewer from "@/components/Molecules/ImagePreviewer";
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { isMobile } from "@/utils/utils";

export default {
  name: "AuthDetail",
  props: ["idx"],
  components: {
    TextTile,
    StatusTile,
    AnswerPendencyModal,
    ImagePreviewer
  },
  data() {
    return {
      auth: {},
      authDocs: [],
      authImgs: [],
      pictures: [],
      limitFiles: 0,
      showPendencyModal: false,
      motivosDevolucao: [],
      motivosPendencia: [],
      pendencyModalStep: 1,
      protocolCreatedOnSave: ""
    };
  },
  async mounted() {
    await this.$root.$emit("show-loading");
    if (!this.idx) {
      this.$router.replace({ name: "auths" });
      return;
    }
    try {
      await this.$root.$emit("show-loading");
      if (!this.auths || this.auths.length === 0) {
        await this.listAuths();
      }
      const id = Number.parseInt(this.idx);
      this.auth = this.auths.find(a => a.idAutorizacao === id);
      if (!this.auth) {
        this.$router.replace({ name: "auths" });
        return;
      }
      switch (this.auth.tpSituacao) {
        case "C":
        case "D":
        case "R":
          this.motivosDevolucao = await this.fetchReasons({ autorizacao: this.auth.idAutorizacao });
          break;
        case "L":
          this.authDocs = await this.getAuthFileList({ autorizacao: this.auth.idAutorizacao });
          break;
        case "P":
          this.motivosPendencia = await this.fetchPendencies({ autorizacao: this.auth.idAutorizacao });
          break;
      }
      this.limitFiles = await this.fetchMaxFiles();
      const allAuthDocs = await this.fetchAuthDocuments({ auth: this.auth.idAutorizacao, ticket: this.auth.idChamado });
      this.authImgs = allAuthDocs.filter(
        d => d.tipo === "gif" || d.tipo === "png" || d.tipo === "jpg" || d.tipo === "jpeg"
      );
      this.loadPictures();
    } catch {
      this.$router.replace({ name: "auths" });
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  computed: {
    ...mapState("auths", ["auths"]),
    isMobile() {
      return isMobile();
    },
    colorSituacao() {
      const colors = {
        A: "var(--colors-light-primary-navy-blue)",
        C: "var(--colors-light-support-error)",
        D: "var(--colors-light-support-error)",
        L: "var(--colors-light-support-success)",
        P: "var(--colors-light-support-warning)",
        R: "var(--colors-light-support-error)",
        T: "var(--colors-light-primary-gray2)"
      };
      const tpStatus = this.auth.tpSituacao;
      return tpStatus in colors ? colors[tpStatus] : "var(--colors-light-primary-gray2)";
    },
    pendencyDuesDate() {
      if (!this.motivosPendencia?.length) return "";
      return ` até ${this.motivosPendencia[0].dataLimiteCancelamento}`;
    },
    pendencyToAnswer() {
      if (!this.motivosPendencia?.length) return {};
      return {
        ticket: this.motivosPendencia[0].idChamadoConvocacao,
        devolutionGroups: [
          {
            Grupo: "Para continuar a análise de sua autorização solicitamos o envio de:",
            Motivos: [...new Set(this.motivosPendencia.map(m => m.dsOcorrenciaAutorizacaoDetalhe))]
          }
        ]
      };
    }
  },
  methods: {
    ...mapActions("auths", [
      "fetchMaxFiles",
      "getAuthFileList",
      "listAuths",
      "fetchAuthDocuments",
      "fetchReasons",
      "fetchPendencies",
      "submitPendencieResponse"
    ]),
    ...mapActions("myAccount", ["fetchImage"]),
    loadPictures() {
      if (!this.auth.idAutorizacao) return;
      if (!this.authImgs?.length) return;
      this.authImgs.forEach(p =>
        this.fetchImage({ documento: p.id, original: false }).then(source =>
          this.pictures.push({
            id: p.id,
            source: source,
            loadModalImage: () => this.loadPicture(p.id)
          })
        )
      );
    },
    async loadPicture(id) {
      return await this.fetchImage({ documento: id, original: true });
    },
    downloadAuth(url) {
      window.open(url, "_blank");
    },
    async onSavePendency({ observacao, arquivos }) {
      try {
        await this.$root.$emit("show-loading");
        this.showPendencyModal = false;
        this.protocolCreatedOnSave = await this.submitPendencieResponse({
          autorizacao: this.auth.idAutorizacao,
          chamado: this.pendencyToAnswer.ticket,
          observacao,
          anexos: arquivos
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }

      if (this.protocolCreatedOnSave) {
        this.pendencyModalStep = 2;
        this.showPendencyModal = true;
      } else {
        await Swal.fire({
          title: "Erro",
          text: "Não foi possivel registrar o atendimento.\nServiço temporariamente indisponível.",
          confirmButtonText: "OK",
          icon: "error"
        });
      }
    },
    onCloseProtocolModal() {
      this.showPendencyModal = false;
      if (this.pendencyModalStep === 2) {
        this.$root.$emit("show-loading");
        this.$router.push({
          path: "/autorizacao-lista"
        });
      }
    }
  }
};
</script>
