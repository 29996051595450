<template>
  <CBox :bg="['none', 'var(--colors-light-primary-light-gray)']" minHeight="100vh" width="100vw" position="sticky">
    <OmtLayoutContainer>
      <OmtRow :mb="['26px', '32px']">
        <OmtTypography as="h1-bold"> Busque por especialidade </OmtTypography>
      </OmtRow>
      <OmtRow :mb="['32px', '42px']">
        <OmtInput variant="filled" placeholder="Buscar" icon-right="search" v-model="inputText" />
      </OmtRow>
      <OmtRow v-for="(spec, idx) in filteredSpecialities" :key="'spec-' + idx">
        <OmtTile @click="handleClick(spec)" cursor="pointer" :divider="idx !== filteredSpecialities.length - 1">
          <OmtTypography as="h2-semi-bold">
            {{ capitalizeWords(spec.DescricaoEspecialidade) }}
          </OmtTypography>
        </OmtTile>
      </OmtRow>
      <OmtRow v-if="showAffiliateIndication" @click="redirectToAffiliateIndication" mt="42px">
        <CBox bg="var(--colors-light-primary-light-gray)" py="14px" px="16px" border-radius="12px">
          <OmtTile :divider="false" icon="lightbulb">
            <OmtTypography as="h2-bold" max-w="235px"> Gostaria de uma indicação de um especialista? </OmtTypography>
          </OmtTile>
        </CBox>
      </OmtRow>
    </OmtLayoutContainer>
  </CBox>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "SpecialitiesList",
  data() {
    return {
      prevRoute: "/redecredenciada-home",
      inputText: ""
    };
  },
  beforeRouteEnter(_, from, next) {
    next(vm => {
      vm.prevRoute = from.path;
    });
  },
  async mounted() {
    if (!this.group) {
      await this.$router.replace({ name: "affiliate" });
      return;
    }
    try {
      this.$root.$emit("change-title-label", this.capitalizeWords(this.group));
      this.$root.$emit("change-back-route", "/redecredenciada-home");
      await this.$root.$emit("show-loading");
      if (this.prevRoute === "/redecredenciada-home" || this.prevRoute === "/redecredenciada/") {
        await this.fetchSpecialities();
      }
    } catch (error) {
      this.$toast({
        description: error instanceof Error ? error.message : error,
        status: "error",
        duration: 10000,
        position: "top"
      });
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  computed: {
    ...mapState("affiliate", ["specialities", "group", "tipoAtendimento", "allowAffiliateIndication"]),
    filteredSpecialities() {
      if (!this.inputText) return this.specialities;

      return this.specialities.filter(speciality =>
        speciality.DescricaoEspecialidade.toLowerCase().includes(this.inputText.toLowerCase())
      );
    },
    showAffiliateIndication() {
      if (!this.allowAffiliateIndication) return false;
      return this.tipoAtendimento === "mediclin" || this.tipoAtendimento === "terapia";
    }
  },
  methods: {
    ...mapActions("affiliate", ["fetchSpecialities"]),
    ...mapMutations("affiliate", ["SET_SPECIALITY"]),
    handleClick(spec) {
      this.SET_SPECIALITY({ cod: spec.CodigoEspecialidade, label: spec.DescricaoEspecialidade });
      this.$router.replace({ name: "affiliate.locs" });
    },
    redirectToAffiliateIndication() {
      this.$router.push({ path: "/indicacao-de-credenciados/criar-indicacao" });
    },
    capitalizeWords(inputString) {
      return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
    }
  }
};
</script>
