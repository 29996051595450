<template>
  <CBox>
    <OmtTypography as="p1" mb="24px"> Edite ou adicione suas <b>informações pessoais</b>. </OmtTypography>
    <template v-if="titular">
      <CFlex mb="16px">
        <OmtIcons name="home" height="16px" width="16px" size="lg" mr="8px" />
        <OmtTypography as="h2-bold" mb="12px">Endereço</OmtTypography>
      </CFlex>
      <OmtCard w="100%" mb="24px">
        <CFlex direction="column" mb="12px">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">CEP</OmtTypography>
          <OmtInput
            :disabled="existChangeRequest"
            v-mask="'#####-###'"
            max-size="9"
            v-model="endereco.cep"
            fontSize="14px"
            fontWeight="700"
            @change="e => onCepChanged(e)"
          />
        </CFlex>
        <CFlex direction="column" mb="12px">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Bairro</OmtTypography>
          <OmtInput
            :disabled="naoPermiteAdicinarEnderecoCepInvalido"
            v-model="endereco.bairro"
            fontSize="14px"
            fontWeight="700"
          />
        </CFlex>
        <CFlex direction="column" mb="12px">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Logradouro</OmtTypography>
          <OmtInput
            :disabled="naoPermiteAdicinarEnderecoCepInvalido"
            v-model="endereco.logradouro"
            fontSize="14px"
            fontWeight="700"
          />
        </CFlex>
        <CFlex direction="column" mb="12px">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Número</OmtTypography>
          <OmtInput
            :disabled="existChangeRequest"
            v-model="endereco.numero"
            fontSize="14px"
            fontWeight="700"
            max-size="10"
          />
        </CFlex>
        <CFlex direction="column" mb="12px">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Complemento (opcional)</OmtTypography>
          <OmtInput
            :disabled="existChangeRequest"
            v-model="endereco.complemento"
            fontSize="14px"
            fontWeight="700"
            max-size="40"
          />
        </CFlex>
        <template v-if="naoPermiteAdicinarEnderecoCepInvalido">
          <CFlex direction="column" mb="12px">
            <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Estado</OmtTypography>
            <OmtInput is-disabled v-model="endereco.uf" fontSize="14px" fontWeight="700" max-size="40" />
          </CFlex>
          <CFlex direction="column" mb="12px">
            <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Cidade</OmtTypography>
            <OmtInput is-disabled v-model="endereco.cidade" fontSize="14px" fontWeight="700" max-size="40" />
          </CFlex>
        </template>
        <template v-else>
          <CFlex direction="column" mb="12px">
            <OmtTypography as="p1">Estado</OmtTypography>
            <OmtSelect placeholder="Selecione o estado" @change="e => onUfChange(e)">
              <option v-for="(uf, index) in estados" :key="index" :value="uf">
                {{ uf }}
              </option>
            </OmtSelect>
          </CFlex>
          <CFlex direction="column" mb="12px">
            <OmtTypography as="p1">Cidade</OmtTypography>
            <OmtSelect v-if="isLoadingCidades" placeholder="Selecione a cidade" is-loading></OmtSelect>
            <OmtSelect
              v-else
              placeholder="Selecione a cidade"
              @change="e => onCidadeChange(e)"
              :loading="isLoadingCidades"
            >
              <option v-for="(c, index) in cidades" :key="index" :value="c.id">
                {{ c.nome }}
              </option>
            </OmtSelect>
          </CFlex>
        </template>
      </OmtCard>
    </template>

    <CFlex mb="16px">
      <OmtIcons name="envelope-open-text" height="16px" width="16px" size="lg" mr="8px" />
      <OmtTypography as="h2-bold" mb="12px">E-mail</OmtTypography>
    </CFlex>
    <OmtCard w="100%" mb="24px">
      <CFlex direction="column" mb="12px">
        <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">E-mail</OmtTypography>
        <OmtInput
          :disabled="existChangeRequest"
          max-size="255"
          v-model="$v.email.pessoal.$model"
          fontSize="14px"
          fontWeight="700"
        />
      </CFlex>
      <CFlex v-if="email.showFatura" direction="column" mb="12px">
        <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">E-mail para envio de Fatura</OmtTypography>
        <OmtInput
          :disabled="existChangeRequest"
          max-size="255"
          v-model="$v.email.fatura.$model"
          fontSize="14px"
          fontWeight="700"
        />
      </CFlex>
      <CFlex v-if="email.showFatura" direction="column" mb="12px">
        <OmtTypography as="p2">Utilizado para envio da sua Fatura Digital</OmtTypography>
      </CFlex>
    </OmtCard>

    <CFlex mb="16px">
      <OmtIcons name="phone" height="16px" width="16px" size="lg" mr="8px" />
      <OmtTypography as="h2-bold" mb="12px">Telefone</OmtTypography>
      <CFlex>
        <OmtIcons
          name="circle-exclamation"
          height="16px"
          width="16px"
          size="lg"
          ml="16px"
          @mouseleave="showTooltipPhone = false"
          @mouseover="showTooltipPhone = true"
        />
        <CBox
          class="tooltip-phone"
          bg="var(--colors-light-primary-navy-blue)"
          color="var(--colors-white)"
          borderRadius="4px"
          v-if="showTooltipPhone"
        >
          <span>Você pode cadastrar apenas três números de telefone</span>
        </CBox>
      </CFlex>
    </CFlex>
    <OmtCard v-for="(fone, index) in telefones" :key="index" w="100%" mb="24px" v-show="!fone.removido">
      <CFlex direction="column" mb="12px">
        <CFlex direction="row" justify="space-between" flex-wrap="wrap">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Tipo do telefone</OmtTypography>
          <OmtIcons
            v-if="!existChangeRequest"
            name="trash-can"
            height="16px"
            width="16px"
            size="lg"
            ml="16px"
            color="var(--colors-light-primary-navy-blue)"
            cursor="pointer"
            @click.prevent="onRemoverTelefoneClick(index)"
          />
        </CFlex>
        <OmtSelect
          :disabled="existChangeRequest"
          placeholder="Selecione o tipo do telefone"
          @change="e => onTipoTelefoneChange(index, e)"
          :initialValue="fone.tipo"
        >
          <option value="Celular">Celular</option>
          <option value="Comercial">Comercial</option>
          <option value="Residencial">Residencial</option>
        </OmtSelect>
      </CFlex>
      <CFlex direction="column" mb="18px">
        <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Telefone</OmtTypography>
        <OmtInput
          :disabled="existChangeRequest"
          max-size="255"
          v-model="fone.numero"
          v-mask="getMascaraTelefone(fone)"
          fontSize="14px"
          fontWeight="700"
        />
      </CFlex>
      <CFlex direction="column" mb="12px">
        <OmtCheckbox
          :isDisabled="existChangeRequest || (fone.tipo !== 'Celular' && fone.tipo !== 'Comercial')"
          @change="onWhatappClick(index)"
          :is-checked="fone.whatsapp"
        >
          <OmtTypography as="p2" pl="15px"> Número Whatsapp </OmtTypography>
        </OmtCheckbox>
      </CFlex>
    </OmtCard>

    <FixedButtonRow :useFooter="false" :solidColor="true" :fixedSize="podeAdicionarTelefone ? '2x' : '1x'">
      <CFlex direction="column" :w="['100%', '308px !important']">
        <OmtButton
          v-if="podeAdicionarTelefone"
          @click="onAdicionarTelefoneClick"
          w="100%"
          variant="ghost"
          color="var(--colors-light-primary-navy-blue)"
        >
          {{ labelAdicionarTelefone }}
        </OmtButton>
        <OmtButton
          @click.prevent="onNextClick"
          :w="['100%', '308px !important']"
          variant="solid"
          :disabled="!canSubmit"
        >
          Continuar
        </OmtButton>
      </CFlex>
    </FixedButtonRow>
  </CBox>
</template>

<script>
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import utils from "@/utils/utils";
import { required, email } from "vuelidate/lib/validators";
import { maskTelFull, maskTel9DigitRegion } from "@/utils/utils";

export default {
  components: { FixedButtonRow },
  data() {
    return {
      endereco: {
        cep: "",
        tipoLogradouro: "",
        idLogradouro: 0,
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        idCidade: "",
        uf: ""
      },
      telefones: [],
      email: {
        pessoal: "",
        fatura: "",
        showFatura: false
      },
      estados: [],
      isLoadingCidades: false,
      cidades: [],
      showTooltipPhone: false
    };
  },
  validations: {
    email: {
      pessoal: {
        required,
        email
      },
      fatura: {
        required,
        email
      }
    }
  },
  created() {
    this.estados = utils.statesFromCountry.map(estado => estado.initials);
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      if (
        !this.resumeContacts?.Endereco &&
        !this.resumeContacts.Telefones?.length &&
        !this.resumeContacts?.Emails?.Pessoal
      ) {
        await Promise.all([this.fetchResumeContacts(), this.validateActiveTicket(), this.validarAtendimentoWhatsApp()]);
      } else {
        await this.validateActiveTicket();
      }
      const values = this.initialValues();
      this.endereco = values.endereco;
      this.email = values.email;
      this.telefones = values.telefones;
      this.$v.$touch();
    } catch (error) {
      this.$toast({
        description: error.message,
        status: "error",
        duration: 10000,
        position: "bottom-right"
      });
    } finally {
      this.$root.$emit("hide-loading");
    }
  },
  computed: {
    ...mapState("saude", ["titular"]),
    ...mapState("myAccount", ["welcomeOnBoardTourAuth"]),
    ...mapState("myContacts", [
      "resumeContacts",
      "existChangeRequest",
      "newAddress",
      "newEmails",
      "newPhones",
      "newWhatsapp"
    ]),
    ...mapState("atendimento", ["WhatsAppAtivo", "NumeroFormatado", "DDD", "DDI"]),
    naoPermiteAdicinarEnderecoCepInvalido() {
      return true;
      // confirmar regra de cep invalido, se vai permitir informar os dados (hoje falta o tipo de logradouro)
      // return this.existChangeRequest || this.endereco.idLogradouro > 0;
    },
    podeAdicionarTelefone() {
      if (this.existChangeRequest) return false;
      return ((this.telefones ?? []).filter(t => !t.removido) ?? []).length < 3;
    },
    labelAdicionarTelefone() {
      return ((this.telefones ?? []).filter(t => !t.removido) ?? []).length
        ? "Adicionar outro número"
        : "Adicionar número";
    },
    whatsapp() {
      if (this.newWhatsapp) return this.newWhatsapp;

      return this.WhatsAppAtivo
        ? `+${(this.DDI || 0).zeroLeft()} (${(this.DDD || 0).zeroLeft()}) ${this.NumeroFormatado}`
        : "";
    },
    canSubmit() {
      if (this.titular) {
        if (!this.endereco.cep) return false;
        if (!this.endereco.idLogradouro) return false;
        if (!this.endereco.numero) return false;
        if (!this.endereco.bairro) return false;
        if (!this.endereco.cidade) return false;
        if (!this.endereco.uf) return false;
      }
      if (!this.email.pessoal) return false;
      if (this.$v.email.pessoal.$error) return false;
      if (this.email.showFatura) {
        if (!this.email.fatura) return false;
        if (this.$v.email.fatura.$error) return false;
      }
      return true;
    }
  },
  methods: {
    ...mapActions("myContacts", [
      "createChangeRequestFromTour",
      "fetchResumeContacts",
      "fetchCities",
      "fetchAddressInfos",
      "validateActiveTicket",
      "validadeChanges"
    ]),
    ...mapActions("atendimento", ["validarAtendimentoWhatsApp", "ativarAtendimentoWhatsApp"]),
    ...mapActions("myAccount", ["checkNextWelcomeOnBoardTour"]),
    ...mapMutations("myContacts", ["SET_NEW_STATE"]),
    ...mapMutations("myAccount", ["SET_WELCOME_ONBOARD_TOUR_AUTH_RULES"]),
    initialValues() {
      const baseEndereco = this.newAddress ?? this.resumeContacts?.Endereco;
      const endereco = {
        cep: baseEndereco?.CEP ?? "",
        tipoLogradouro: baseEndereco?.TipoLogradouro ?? "",
        idLogradouro: baseEndereco?.IdLogradouro ?? 0,
        logradouro: baseEndereco?.Logradouro ?? "",
        numero: baseEndereco?.Numero ?? "",
        complemento: baseEndereco?.Complemento ?? "",
        bairro: baseEndereco?.Bairro ?? "",
        cidade: baseEndereco?.Cidade ?? "",
        uf: baseEndereco?.UF ?? ""
      };
      const baseEmail = this.newEmails ?? this.resumeContacts?.Emails;
      const email = {
        pessoal: baseEmail?.Pessoal ?? "",
        fatura: baseEmail?.Fatura ?? "",
        showFatura: this.titular && !(this.resumeContacts?.Empresarial ?? true)
      };
      const whatsapp = this.whatsapp;
      let fones = [];
      const baseTelefone = this.newPhones ?? this.resumeContacts?.Telefones;
      if (baseTelefone?.length) {
        fones = baseTelefone.map(t => {
          const numero = `${t.countryCode} ${t.Numero}`;
          return {
            tipo: t.Tipo,
            numero,
            whatsapp: numero === whatsapp
          };
        });
      }
      if (this.WhatsAppAtivo && !fones.find(t => t.whatsapp))
        fones = [
          {
            numero: whatsapp,
            tipo: "Celular",
            whatsapp: true
          },
          ...fones
        ];
      const telefones = fones.slice(0, 3);
      return {
        endereco,
        email,
        telefones
      };
    },
    async onCepChanged(event) {
      if (!event?.isTrusted) return;

      let cep = (this.endereco.cep ?? "").replace(/\D/g, "");
      if (cep.length === 8 && cep.indexOf("-") === -1) {
        const formattedCEP = cep.replace(/\D/g, "");
        const finalCep = formattedCEP.replace(/^(\d{5})(\d{3})$/, "$1-$2");
        this.endereco.cep = finalCep;
        cep = finalCep;
      }
      if (cep.length !== 9 || cep.replace(/\D/g, "").length !== 8 || cep.substring(5, 6) !== "-") return;

      try {
        this.$root.$emit("show-loading");
        const response = await this.fetchAddressInfos({
          cep
        });

        if (response?.length) {
          const end = response[0];
          this.endereco.idLogradouro = end.Codigo;
          this.endereco.logradouro = end.Descricao;
          this.endereco.tipoLogradouro = end.TipoLogradouro.Codigo;
          this.endereco.bairro = end.Bairro;
          this.endereco.idCidade = end.Municipio.Codigo;
          this.endereco.cidade = end.Municipio.Nome;
          this.endereco.uf = end.Municipio.UF;
        } else {
          this.endereco.idLogradouro = 0;
          this.endereco.logradouro = "";
          this.endereco.tipoLogradouro = "";
          this.endereco.bairro = "";
          this.endereco.idCidade = "";
          this.endereco.cidade = "";
          this.endereco.uf = "";

          this.$toast({
            description: "CEP não localizado",
            status: "error",
            duration: 10000,
            position: "bottom-right"
          });
        }
      } catch (error) {
        console.error("Não foi possível as informações de logradouro", error);
      } finally {
        this.$root.$emit("hide-loading");
      }
    },
    async onUfChange(uf) {
      if (!uf) {
        this.onCidadeChange(0);
        return;
      }

      let cidades = [];
      try {
        this.$root.$emit("show-loading");
        this.isLoadingCidades = true;
        this.endereco.cidade = "";
        this.endereco.idCidade = "";
        const response = await this.fetchCities({
          uf
        });
        if (response?.length) {
          cidades = response.map(c => ({
            id: `${c.Id}`,
            nome: c.Nome
          }));
        }
      } catch (error) {
        console.error("Não foi possível obter a lista de cidades", error);
      } finally {
        this.isLoadingCidades = false;
        this.$root.$emit("hide-loading");
      }

      this.cidades = cidades;
    },
    onCidadeChange(cidade) {
      if (cidade) {
        this.endereco.idCidade = cidade;
        this.endereco.cidade = this.cidades.find(c => c.id === cidade).nome;
      } else {
        this.endereco.cidade = "";
        this.endereco.idCidade = "";
      }
    },
    onAdicionarTelefoneClick() {
      if (this.existChangeRequest) return;
      if (!this.podeAdicionarTelefone) return;
      this.telefones.push({ tipo: "Celular", numero: "+55", whatsapp: false });
      this.$nextTick(() => window.scrollTo(0, document.body.scrollHeight));
    },
    getMascaraTelefone(fone) {
      if (fone.tipo === "Comercial" || fone.tipo === "Residencial") {
        return maskTelFull(fone.numero);
      } else {
        return maskTel9DigitRegion;
      }
    },
    onRemoverTelefoneClick(indexRemovido) {
      if (this.existChangeRequest) return;
      const telefones = [];
      this.telefones.forEach((t, index) => {
        const fone = { ...t };
        if (index === indexRemovido) {
          fone.removido = true;
        }
        telefones.push(fone);
      });
      this.telefones = telefones;
    },
    onTipoTelefoneChange(indexAlterado, tipo) {
      if (this.existChangeRequest) return;
      const telefones = [];
      this.telefones.forEach((t, index) => {
        const fone = { ...t };
        if (index === indexAlterado) {
          fone.tipo = tipo;
          if (tipo !== "Celular" && tipo !== "Comercial") fone.whatsapp = false;
        }
        telefones.push(fone);
      });
      this.telefones = telefones;
    },
    onWhatappClick(indexMarcado) {
      if (this.existChangeRequest) return;
      this.telefones.forEach(
        (t, index) =>
          (t.whatsapp = !t.whatsapp && index === indexMarcado && (t.tipo === "Celular" || t.tipo === "Comercial"))
      );
    },
    async onNextClick() {
      try {
        this.$root.$emit("show-loading");
        let semAlteracaoWhatsapp = true;
        let semAlteracaoContatos = true;
        let whatsappSelecionado = "";
        if (!this.existChangeRequest) {
          const tels = [];
          const countryCodeRegex = /^\+(\d+)\s*/;
          this.telefones.forEach(tel => {
            const match = tel.numero.match(countryCodeRegex);
            let countryCode = "";
            if (match && match[1]) {
              countryCode = "+" + match[1];
            }
            if (!tel.removido)
              tels.push({
                Numero: tel.numero.replace(countryCodeRegex, ""),
                Tipo: tel.tipo,
                countryCode
              });
          });
          this.SET_NEW_STATE({ key: "newPhones", value: tels });
          this.SET_NEW_STATE({
            key: "newEmails",
            value: {
              Pessoal: this.email.pessoal,
              Fatura: this.email.fatura
            }
          });
          this.SET_NEW_STATE({
            key: "newAddress",
            value: {
              CEP: this.endereco.cep,
              TipoLogradouro: this.endereco.tipoLogradouro,
              IdLogradouro: this.endereco.idLogradouro,
              Logradouro: this.endereco.logradouro,
              Numero: this.endereco.numero,
              Complemento: this.endereco.complemento,
              Bairro: this.endereco.bairro,
              Cidade: this.endereco.cidade,
              UF: this.endereco.uf
            }
          });
          const validacaoAlteracao = await this.validadeChanges({ allowWithoutChanges: true });
          const whatsappAtual = this.whatsapp;
          whatsappSelecionado = this.telefones.find(t => !!t.whatsapp)?.numero ?? "";
          if (!whatsappSelecionado || whatsappSelecionado === whatsappAtual) {
            whatsappSelecionado = null;
          }
          this.SET_NEW_STATE({ key: "newWhatsapp", value: whatsappSelecionado });
          if (validacaoAlteracao?.MsgValidacao) {
            // erro nos dados
            this.$toast({
              description: validacaoAlteracao.MsgValidacao,
              status: "error",
              duration: 10000,
              position: "bottom-right"
            });
            return;
          }
          semAlteracaoWhatsapp = !whatsappSelecionado;
          semAlteracaoContatos =
            !validacaoAlteracao?.Enderecos?.length &&
            !validacaoAlteracao?.Emails?.length &&
            !validacaoAlteracao?.Telefones?.length;
          if (!validacaoAlteracao?.Enderecos?.length) this.SET_NEW_STATE({ key: "newAddress", value: null });
          if (!validacaoAlteracao?.Emails?.length) this.SET_NEW_STATE({ key: "newEmails", value: null });
          if (!validacaoAlteracao?.Telefones?.length) this.SET_NEW_STATE({ key: "newPhones", value: null });
        }
        if (semAlteracaoContatos) {
          if (!semAlteracaoWhatsapp) {
            await this.ativarAtendimentoWhatsApp({
              phoneNumber: whatsappSelecionado,
              auth: 0
            });
          }
          this.nextTask();
        } else if (this.welcomeOnBoardTourAuth) {
          this.submit();
        } else {
          this.SET_WELCOME_ONBOARD_TOUR_AUTH_RULES({
            routeBack: this.$route.path,
            action: "myContacts/createChangeRequestFromTour"
          });
          this.$router.push("/welcome/identity-verification");
        }
      } catch (error) {
        this.$toast({
          description: error.message,
          status: "error",
          duration: 10000,
          position: "bottom-right"
        });
      } finally {
        this.$root.$emit("hide-loading");
      }
    },
    async nextTask() {
      const rota = await this.checkNextWelcomeOnBoardTour();
      this.$router.replace(rota);
    },
    async submit() {
      try {
        this.$root.$emit("show-loading");
        await this.createChangeRequestFromTour();
        this.nextTask();
      } catch (error) {
        this.$toast({
          description: error.message,
          status: "error",
          duration: 10000,
          position: "bottom-right"
        });
      } finally {
        this.$root.$emit("hide-loading");
      }
    }
  }
};
</script>
<style scoped lang="scss">
.tooltip-phone {
  position: absolute;
  width: 200px;
  transform: translate(50px, -12px);
  padding: 2px 4px;
  font-size: 12px;
  text-align: center;
}
.tooltip-phone::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  width: 16px;
  height: 8px;
  transform: translateY(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent #172972 transparent transparent;
}
</style>
