<template>
  <div>
    <CBox :bg="['none', 'var(--colors-light-primary-light-gray)']" minHeight="100vh" width="100vw" position="sticky">
      <OmtLayoutContainer>
        <OmtRow :mb="['9px']" v-if="$route.params.nomePrestador">
          <CFlex>
            <CBox mr="2"
              ><OmtIcons
                size="xl"
                name="user-doctor"
                p="2"
                backgroundColor="var(--colors-light-primary-light-gray)"
                borderRadius="8px"
            /></CBox>
            <CBox width="90%" mr="auto">
              <OmtTypography as="h1-semi-bold">{{ $route.params.nomePrestador }}</OmtTypography>
            </CBox>
          </CFlex>
        </OmtRow>
        <OmtRow mb="12px" v-if="$route.params.nomePrestador">
          <omt-divider />
        </OmtRow>
        <OmtRow mb="24px">
          <CBox>
            <OmtTypography as="h1-semi-bold">Planos atendidos</OmtTypography>
          </CBox>
        </OmtRow>
        <OmtRow :mb="['16px']" v-for="(p, i) in plans" :key="'plan-' + i">
          <CBox border="0.5px solid var(--colors-light-secondary-divider)" p="16px" borderRadius="8px">
            <CFlex width="100%"
              ><OmtIcons name="address-card" mr="8px" size="lg" />
              <OmtTypography as="h2-regular"
                ><strong>Plano {{ p.CodigoPlano }}</strong>
              </OmtTypography></CFlex
            >
            <omt-divider />
            <CFlex width="100%" mb="16px"
              ><OmtTypography as="h2-regular"
                ><strong>Contratação:</strong> {{ p.DescricaoContratacao }}</OmtTypography
              ></CFlex
            >
            <CFlex width="100%" mb="16px"
              ><OmtTypography as="h2-regular"><strong>Nome:</strong> {{ p.DescricaoDivulgacao }}</OmtTypography></CFlex
            >
            <CFlex width="100%" mb="16px"
              ><OmtTypography as="h2-regular"
                ><strong>Número de registro:</strong> {{ p.NumeroRegistro }}</OmtTypography
              ></CFlex
            >
            <CFlex width="100%" mb="16px"
              ><OmtTypography as="h2-regular"
                ><strong>Segmentação:</strong> {{ p.DescricaoSegmentacao }}</OmtTypography
              ></CFlex
            >
            <CFlex width="100%"
              ><OmtTypography as="h2-regular"
                ><strong>Situação ANS:</strong> {{ p.DescricaoAnsSituacao }}</OmtTypography
              ></CFlex
            >
          </CBox>
        </OmtRow>
      </OmtLayoutContainer>
    </CBox>
  </div>
</template>

<script>
import Footer from "@/components/Molecules/Footer";
import { mapState, mapActions } from "vuex";

export default {
  name: "PlansAttended",
  components: {
    Footer
  },
  data() {
    return {
      plans: []
    };
  },
  async mounted() {
    if (!this.match.CodigoPrestador) {
      this.$router.replace({ name: "affiliate" });
      return;
    }
    try {
      await this.$root.$emit("show-loading");
      this.plans = await this.fetchPlansAttended();
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  computed: {
    ...mapState("affiliate", ["match"])
  },
  methods: {
    ...mapActions("affiliate", ["fetchPlansAttended"])
  }
};
</script>
