<template>
  <CBox>
    <OmtButton
      leftIcon="arrow-up-arrow-down"
      size="sm"
      iconSize="sm"
      border=".5px solid var(--colors-light-secondary-divider)"
      :variant="!!activedSort ? 'solid' : 'outline'"
      :iconColor="!!activedSort ? 'white' : 'var(--colors-light-primary-gray1)'"
      @click="isOpen = !isOpen"
    >
      <OmtTypography as="button-small" :color="activedSort ? 'white' : 'var(--colors-light-primary-gray1)'">
        Ordenar
      </OmtTypography>
    </OmtButton>
    <OmtStickedModal
      data-cy="refund-list-sort-modal"
      v-if="isOpen"
      @close="close"
      :headerBind="{
        padding: ['16px', '82px 42px 0px 42px']
      }"
      :bodyBind="{
        padding: ['16px 16px 24px 16px', '68px 42px 24px 42px']
      }"
    >
      <template #header>
        <OmtButton class="clear" :display="['block', 'none']" variant="ghost" @click="clear" height="18px" p="0">
          <OmtTypography as="p1" h="18px" w="46px" position="relative"> Limpar </OmtTypography>
        </OmtButton>
      </template>

      <CBox :minHeight="['initial', 'calc(100vh - 400px)']">
        <CFlex justifyContent="space-between" :mb="['24px', '36px']">
          <OmtTypography as="h1-bold"> Ordenação </OmtTypography>
          <OmtButton class="clear" variant="ghost" @click="clear" height="18px" p="0" :display="['none', 'block']">
            <OmtTypography as="p1" class="" h="18px" w="46px" position="relative"> Limpar </OmtTypography>
          </OmtButton>
        </CFlex>

        <CRadioGroup v-model="activedSort" mt="24px">
          <CRadio :value="sort" cursor="pointer" v-for="(sort, index) in sorts" :key="index">
            <OmtTypography as="p1"> {{ sort }} </OmtTypography>
          </CRadio>
        </CRadioGroup>
      </CBox>

      <template #footer>
        <OmtButton w="100%" @click="save"> Aplicar </OmtButton>
      </template>
    </OmtStickedModal>
  </CBox>
</template>

<script>
/**
 * @prop {Boolean} isOpen
 * @prop {String[]} sorts
 * @prop {String} initialValue
 */
export default {
  props: {
    sorts: {
      type: Array
    },
    initialValue: {
      type: String
    }
  },
  data() {
    return {
      isOpen: false,
      activedSort: ""
    };
  },
  methods: {
    close() {
      this.isOpen = false;
      this.$emit("sort", this.initialValue);
    },
    clear() {
      this.activedSort = "";
    },
    save() {
      this.$emit("sort", this.activedSort);
      this.isOpen = false;
    }
  },
  watch: {
    isOpen: {
      handler() {
        this.activedSort = this.initialValue;
      },
      immediate: true
    },
    initialValue: {
      handler() {
        this.activedSort = this.initialValue;
      }
    }
  }
};
</script>
