<template>
  <OmtLayoutContainer :px="['0', '50px']">
    <OmtRow z-index="1">
      <CFlex w="100%" :flex-direction="['column', 'row']" justify-content="space-between">
        <CBox w="100%" :max-width="['100%', '400px']">
          <OmtTypography as="p1" mb="12px" color="var(--colors-light-primary-gray2)">
            Beneficiário que realizará o procedimento
          </OmtTypography>
          <OmtSelect
            placeholder="Selecione o beneficiário"
            @change="handleBeneficiaryChange"
            :initialValue="selectedBeneficiary"
            mb="24px"
          >
            <option
              v-for="(vinculo, index) in beneficiaries"
              :key="index"
              :value="`${vinculo.Titulo}${vinculo.Sequencia}`"
            >
              {{ vinculo.Nome }}
            </option>
          </OmtSelect>
        </CBox>
        <CBox w="100%" :max-width="['100%', '400px']">
          <OmtTypography as="p1" mb="12px" color="var(--colors-light-primary-gray2)">
            Selecione o semestre do procedimento
          </OmtTypography>
          <OmtSelect
            placeholder="Selecione o semestre"
            @change="handleSemesterChange"
            :initialValue="selectedSemester"
            mb="24px"
          >
            <option v-for="(semester, index) in semesters" :key="index" :value="`${semester.Semestre}/${semester.Ano}`">
              {{ semester.Semestre }}º Semestre {{ semester.Ano }}
            </option>
          </OmtSelect>
        </CBox>
      </CFlex>
    </OmtRow>

    <OmtRow v-if="displayExtracts" align-items="center" mt="24px">
      <OmtTypography as="h1-bold"> Extrato</OmtTypography>
      <CBox ml="auto" d="flex" justify-content="end">
        <OmtButton
          height="18px"
          p="0"
          align-items="self-end"
          leftIcon="arrow-down-to-bracket"
          variant="ghost"
          iconColor="var(--colors-light-primary-navy-blue)"
          @click="handleBtnDownloadClick"
        >
          <OmtTypography ml="-8px" as="button-small" color="var(--colors-light-primary-navy-blue)">
            Baixar / Imprimir
          </OmtTypography>
        </OmtButton>
      </CBox>
    </OmtRow>

    <OmtRow v-for="(service, index) in services" :key="index" mt="24px">
      <ServiceCollapsableCard :serviceDetail="service" :isCollapsed="true" />
    </OmtRow>
  </OmtLayoutContainer>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ServiceCollapsableCard from "@/components/Organisms/ServicesExtract/ServiceCollapsableCard.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  components: {
    ServiceCollapsableCard
  },
  data() {
    return {
      selectedBeneficiary: "",
      selectedSemester: ""
    };
  },
  methods: {
    ...mapActions("servicesExtract", ["initBeneficiaries", "fetchSemesters", "fetchServices", "getExtractUrl"]),

    async handleBeneficiaryChange(e) {
      this.$root.$emit("show-loading");
      this.selectedBeneficiary = e;
      await this.fetchSemesters({ vinculo: e });

      this.selectedSemester = "";
      this.$root.$emit("hide-loading");
    },
    async handleSemesterChange(semestreAno) {
      this.$root.$emit("show-loading");
      this.selectedSemester = semestreAno;
      const semestreAnoSplit = semestreAno.split("/");
      await this.fetchServices({
        semestre: semestreAnoSplit[0],
        ano: semestreAnoSplit[1],
        vinculoBeneficiario: this.selectedBeneficiary
      });
      this.$root.$emit("hide-loading");
      if (!this.services || this.services.length === 0) {
        await Swal.fire({
          title: "Alerta",
          text: "Beneficiário não possui extrato registrado",
          confirmButtonText: "OK",
          icon: "warning"
        });
      }
    },
    async handleBtnDownloadClick() {
      this.$root.$emit("show-loading");
      const semestreAnoSplit = this.selectedSemester.split("/");
      const url = await this.getExtractUrl({
        semestre: semestreAnoSplit[0],
        ano: semestreAnoSplit[1],
        vinculoBeneficiario: this.selectedBeneficiary
      });
      window.open(url, "_blank");
      this.$root.$emit("hide-loading");
    }
  },
  computed: {
    ...mapState("servicesExtract", ["beneficiaries", "semesters", "services"]),
    displayExtracts() {
      return this.services && this.services.length > 0;
    }
  },
  async mounted() {
    this.$root.$emit("show-loading");
    await this.initBeneficiaries();
    this.$root.$emit("hide-loading");
  }
};
</script>
