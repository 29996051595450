<template>
  <OmtLayoutHeaderFooter
    v-bind="{
      header: {
        titleLabel: 'Notificação'
      }
    }"
  >
    <OmtGrid p="0 !important" :mt="['auto', '32px !important']">
      <OmtRow :desktopStart="1" :desktopEnd="12">
        <OmtGrid>
          <OmtRow mb="5">
            <OmtCard>
              <CFlex v-if="item" w="100%">
                <OmtIcons size="lg" :color="item.Cor" :name="item.Icone" />
                <CBox mx="16px" width="100%">
                  <OmtTypography as="h3-bold" mb="8px">{{ item.Mensagem }}</OmtTypography>
                  <OmtTypography
                    as="p1"
                    color="var(--colors-light-primary-gray2)"
                    v-html="item.MensagemPopUp.replaceAll('|', '<br />')"
                  >
                  </OmtTypography>
                  <template v-if="item.DtEnvio && item.DtEnvio !== '01/01/0001 00:00'">
                    <OmtDivider mt="3" mb="3" />
                    <OmtTypography as="p2" color="var(--colors-light-primary-gray2)">{{ item.DtEnvio }}</OmtTypography>
                  </template>
                </CBox>
              </CFlex>
            </OmtCard>
          </OmtRow>
        </OmtGrid>
      </OmtRow>
    </OmtGrid>
  </OmtLayoutHeaderFooter>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "NotificationDetail",
  data() {
    return {
      item: null
    };
  },
  computed: {
    ...mapState("saude", ["profile"])
  },
  mounted() {
    this.$root.$emit("hide-loading");
    if (!("idx" in this.$route?.params)) this.$router.replace("/notifications");
    if (!this.profile?.messages?.length) this.$router.replace("/notifications");
    this.item = this.profile.messages.find(m => m.index === this.$route.params.idx);
    if (!this.item) this.$router.replace("/notifications");
  }
};
</script>
