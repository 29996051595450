<template>
  <div :class="myClass" v-on="$listeners">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    simpleBox: {
      type: Boolean,
      default: false
    },
    leftIcon: {
      type: Boolean,
      default: false
    },
    rightIcon: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    myClass() {
      let blockStyle = `block-list ${this.simpleBox ? "simple" : "block"}`;
      if (this.leftIcon) blockStyle = `${blockStyle} list-left-icon`;
      if (this.rightIcon) blockStyle = `${blockStyle} list-right-icon`;
      return blockStyle;
    }
  }
};
</script>
<style>
.block-list {
  display: block;
  position: relative;
}

.block-list.block:first-of-type {
  margin-top: 20px;
}

.block-list.simple:first-of-type {
  padding-top: 0px;
}

.block-list.block {
  box-shadow: 0px 0px 16px 2px #00000026;
}

.block-list.block a {
  line-height: 130%;
  padding: 7px 17px;
  margin: 7px 0px 0px 0px;
}

.block-list.simple a {
  letter-spacing: 1.1px;
  padding: 13px 30px 13px 0px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.07);
  font-size: 100%;
}

.big-font-size .block-list.simple a {
  font-size: 150%;
}

.block-list.block.list-left-icon a {
  padding-left: 20px;
}

.block-list.block.list-right-icon a {
  padding-right: 10px;
}

.block-list.simple.list-left-icon a {
  padding-left: 40px;
}

.big-font-size .block-list.simple.list-left-icon a {
  padding-left: 50px;
}

.block-list.simple.list-right-icon a {
  padding-right: 40px;
}

.block-list a {
  font-weight: 500;
  display: block;
  font-size: 13px;
  color: var(--colors-light-primary-gray1) !important;
}

.big-font-size .block-list a {
  font-size: 19px;
}

.block-list a i {
  position: absolute;
  line-height: 30px;
  transform: translateY(-50%);
  margin-top: -5px;
  top: 60%;
}

.block-list.block a i {
  width: 20px;
}

.block-list.simple a i {
  width: 40px;
}

.list-left-icon a i:first-child {
  text-align: left;
}

.block.list-left-icon a i:first-child {
  left: 15px;
}

.simple.list-left-icon a i:first-child {
  left: 0px;
}

.list-right-icon a i:last-child {
  text-align: right;
  right: 10px;
}

.block-list.block a i {
  font-size: 14px;
}

.big-font-size .block-list.block a i {
  font-size: 20px;
}

.block-list.simple a i {
  font-size: 24px;
}

.big-font-size .block-list.simple a i {
  font-size: 36px;
}

.block-list a small {
  font-weight: 400;
  font-size: 11px;
  display: inline-block;
  margin-top: -5px;
}

.big-font-size .block-list a small {
  font-size: 16px;
}

.light .block,
.light .block * {
  background-color: white;
  color: var(--colors-light-primary-gray1);
}

.light .block.inverse * {
  color: white;
  background-color: #68788c;
}

.dark .block,
.dark .block * {
  background-color: #1f1f1f;
  color: #ccd1d9;
}

.dark .block.inverse * {
  color: #1f1f1f;
  background-color: #ccd1d9;
}
</style>
