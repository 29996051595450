<template>
  <CBox data-cy="answer-pendency">
    <OmtTypography as="h1-bold" mb="24px"> Responder pendências </OmtTypography>
    <template v-if="exibirMotivos">
      <OmtTypography v-if="pendencyToAnswer.devolutionGroups.length > 1" as="h3-semi-bold" mb="8px">
        Motivos:
      </OmtTypography>

      <template v-if="pendencyToAnswer.devolutionGroups.length">
        <CBox v-for="(devolutionGroup, dgIndex) in pendencyToAnswer.devolutionGroups" :key="dgIndex" pl="22px">
          <OmtTypography v-if="pendencyToAnswer.devolutionGroups.length > 1" as="h3-semi-bold" mb="8px">
            <omt-bold>{{ dgIndex + 1 }}. </omt-bold>
            {{ devolutionGroup.Grupo }}
          </OmtTypography>
          <OmtTypography v-else as="h3-semi-bold" mb="8px">
            {{ devolutionGroup.Grupo }}
          </OmtTypography>

          <CBox ml="20px" v-for="(devolutionReason, drIndex) in devolutionGroup.Motivos" :key="drIndex">
            <OmtTypography as="p1" mb="14px">
              <omt-bold v-if="devolutionGroup.Motivos.length > 1">{{ drIndex + 1 }}. </omt-bold>
              {{ devolutionReason }}
            </OmtTypography>
          </CBox>
        </CBox>
      </template>
      <CBox v-else pl="22px">
        <OmtTypography as="p1" mb="14px" v-for="(motivo, index) in pendencies" :key="`m${index}`">
          <omt-bold v-if="pendencies.length > 1">{{ index + 1 }}. </omt-bold>{{ motivo }}
        </OmtTypography>
      </CBox>
    </template>

    <CDivider mt="12px" mb="16px" />

    <OmtTypography as="h3-semi-bold" mb="22px"> {{ filesDescription }}. </OmtTypography>

    <ImageUploader
      @change="onImageUploaderChange"
      :length="limitFiles"
      @uploadStart="$emit('uploadStart')"
      @uploadEnd="$emit('uploadEnd')"
    />

    <CDivider mt="12px" mb="16px" />

    <CFlex mb="16px">
      <OmtTypography as="h3-semi-bold" mr="8px"> Observações </OmtTypography>
      <OmtTypography as="p1"> (opcional) </OmtTypography>
    </CFlex>

    <CFlex flexDirection="column" alignItems="flex-end">
      <OmtTextarea
        height="160px"
        padding="12px"
        placeholder="Inclua informações ou detalhes que podem complementar os documentos enviados."
        @change="handleChange"
        :initialValue="obs"
      />
      <OmtTypography as="p2"> {{ answer.obs.length }}/350 </OmtTypography>
    </CFlex>
  </CBox>
</template>

<script>
import ImageUploader from "@/components/Molecules/ImageUploader";

export default {
  components: {
    ImageUploader
  },
  props: {
    pendencyToAnswer: {
      type: Object
    },
    answer: {
      type: Object
    },
    filesDescription: {
      type: String
    },
    limitFiles: {
      type: Number
    }
  },
  data() {
    return {
      excludeModalIsOpen: false,
      obs: ""
    };
  },
  computed: {
    exibirMotivos() {
      return !!this.pendencyToAnswer;
    },
    pendencies() {
      if (!this.exibirMotivos) return [];
      const motivos = [];
      this.pendencyToAnswer?.docs?.forEach(r => {
        if (r.reasonsPendencieRefused?.length) r.reasonsPendencieRefused.forEach(m => motivos.push(m));
      });
      return [...new Set(motivos)];
    }
  },
  methods: {
    handleExclude() {
      this.excludeModalIsOpen = true;
    },
    handleClose() {
      this.excludeModalIsOpen = false;
    },
    handleChange(obs) {
      this.obs = obs;
      this.$emit("update:answer", {
        ...this.answer,
        obs
      });
    },
    onImageUploaderChange(pictures) {
      this.$emit("update:answer", {
        ...this.answer,
        pictures
      });
    }
  }
};
</script>
