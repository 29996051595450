import { render, staticRenderFns } from "./Tile.vue?vue&type=template&id=a5eb371a&scoped=true&"
import script from "./Tile.vue?vue&type=script&lang=js&"
export * from "./Tile.vue?vue&type=script&lang=js&"
import style0 from "./Tile.vue?vue&type=style&index=0&id=a5eb371a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5eb371a",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CStack: require('@chakra-ui/vue').CStack, CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox})
