<template>
  <omint-page>
    <Header name="checkup digital" backRoute="/" :titleLabel="'Checkup Digital'" />
    <omint-slider>
      <router-view />
    </omint-slider>
    <Footer />
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";

export default {
  components: { Header, Footer },
  name: "Voucher"
};
</script>
