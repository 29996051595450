<template>
  <omint-page>
    <omint-slider>
      <router-view />
    </omint-slider>
  </omint-page>
</template>

<script>
export default {
  name: "VideoCall",
  mounted() {
    const equalsMenu = document.getElementById("INDmenu-btn");
    if (equalsMenu) equalsMenu.style["cssText"] = "display: none;";
  }
};
</script>
