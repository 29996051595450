const baseURL = `${process.env.VUE_APP_SERVER_URL}/Formulario`;

export async function BuscarPergunta({ vinculo, formulario }) {
  return window.$axios
    .get({
      url: `${baseURL}/BuscarPergunta`,
      query: {
        vinculo,
        formulario
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("BuscarPergunta", error.message);
      return error;
    });
}

export async function Responder({ vinculo, identificacao, formulario, pergunta, resposta, subRespostas }) {
  return window.$axios
    .post({
      url: `${baseURL}/Responder`,
      query: {
        vinculo,
        identificacao,
        formulario,
        pergunta,
        resposta
      },
      data: subRespostas
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("Responder", error.message);
      return error;
    });
}

export async function LigarDrOmint({ vinculo, identificacao, telefone }) {
  return window.$axios
    .post({
      url: `${baseURL}/LigarDrOmint`,
      query: {
        vinculo,
        identificacao,
        telefone
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("LigarDrOmint", error.message);
      return error;
    });
}

export async function RegistrarAgenda({
  vinculo,
  id,
  prestador,
  telefone,
  email,
  inicioDisponibilidade,
  terminoDisponibilidade
}) {
  return window.$axios
    .post({
      url: `${baseURL}/RegistrarAgenda`,
      query: {
        vinculo,
        id,
        prestador,
        telefone,
        email,
        inicioDisponibilidade: inicioDisponibilidade.toJSON(),
        terminoDisponibilidade: terminoDisponibilidade.toJSON()
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("RegistrarAgenda", error.message);
      return error;
    });
}

export async function ListarAgenda({ vinculo, referencia }) {
  return window.$axios
    .get({
      url: `${baseURL}/ListarAgenda`,
      query: {
        vinculo,
        referencia: referencia.toJSON()
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ListarAgenda", error.message);
      return error;
    });
}
