const baseURL = `${process.env.VUE_APP_SERVER_URL}/Autorizacao`;

export async function ListarMedicos({ vinculo, crm }) {
  return window.$axios
    .get({
      url: `${baseURL}/ListarMedicos`,
      query: {
        vinculo,
        crm
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ListarMedicos", error.message);
      throw new Error(error.message);
    });
}

export async function BuscarLocalEvento({ vinculo, paciente, tipoEvento, crm, ufCrm }) {
  return window.$axios
    .get({
      url: `${baseURL}/BuscarLocalEvento`,
      query: {
        vinculo,
        paciente,
        tipoEvento,
        crm,
        ufCrm
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("BuscarLocalEvento", error.message);
      throw new Error(error.message);
    });
}

export async function ObterDocumentos({ vinculo, autorizacao }) {
  const key = `${vinculo};${autorizacao}`;
  const chave = `${window.$jse.encrypt(key)}`;
  return window.$axios
    .get({
      url: `${baseURL}/ObterDocumentos`,
      query: {
        vinculo,
        chave
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterDocumentos", error.message);
      throw new Error(error.message);
    });
}

export function ObterArquivoAutorizacao({ vinculo, autorizacao, detalhe }) {
  const key = `${vinculo};${autorizacao};${detalhe}`;
  const chave = `${window.$jse.encrypt(key)}`;
  const type = ".pdf";
  const queryString = new URLSearchParams({
    chave,
    type
  }).toString();

  return `${baseURL}/ObterArquivoAutorizacao?${queryString}`;
}

export async function NovaAutorizacao({ vinculo, beneficiario, evento, dataEvento, localEvento, observacao, anexos }) {
  const formData = new FormData();
  formData.append("sqBeneficiario", beneficiario);
  formData.append("tipoEvento", evento);
  formData.append("dataEvento", dataEvento);
  formData.append("localEvento", localEvento);
  formData.append("observacao", observacao);
  for (let i = 0; i < anexos.length; i++) {
    formData.append(`anexo${i + 1}`, anexos[i]);
  }

  return await window.$axios
    .post({
      url: `${baseURL}/NovaAutorizacao`,
      query: {
        vinculo
      },
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("NovaAutorizacao", error.message);
      throw new Error(error.message);
    });
}

export async function ValidarNovaAutorizacao({ vinculo, evento }) {
  return await window.$axios
    .get({
      url: `${baseURL}/ValidarNovaAutorizacao`,
      query: {
        vinculo,
        tipoEvento: evento
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ValidarNovaAutorizacao", error.message);
      throw new Error(error.message);
    });
}

export async function ListarDocumentos({ vinculo, autorizacao, chamado }) {
  return await window.$axios
    .get({
      url: `${baseURL}/ListarDocumentos`,
      query: {
        vinculo,
        idAutorizacao: autorizacao,
        idChamado: chamado
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ListarDocumentos", error.message);
      throw new Error(error.message);
    });
}

export async function ObterLimiteArquivos({ vinculo }) {
  return await window.$axios
    .get({
      url: `${baseURL}/ObterLimiteArquivos`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterLimiteArquivos", error.message);
      return error;
    });
}

export async function ObterPrevisaoRetorno({ vinculo }) {
  return await window.$axios
    .get({
      url: `${baseURL}/ObterPrevisaoRetorno`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterPrevisaoRetorno", error.message);
      return error;
    });
}

export async function ListarGeral({ vinculo, periodo, titulo, nome, documento, filtro, autorizacao }) {
  return await window.$axios
    .post({
      url: `${baseURL}/ListarGeral`,
      query: {
        vinculo
      },
      data: {
        periodo,
        titulo,
        nome,
        documento,
        filtro,
        autorizacao
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ListarGeral", error.message);
      return error;
    });
}

export async function ObterPendenciasAutorizacao({ vinculo, idAutorizacao }) {
  return await window.$axios
    .get({
      url: `${baseURL}/ObterPendenciasAutorizacao`,
      query: {
        vinculo,
        idAutorizacao
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterPendenciasAutorizacao", error.message);
      return error;
    });
}

export async function ObterDevolucaoOcorrencia({ vinculo, autorizacao }) {
  return await window.$axios
    .get({
      url: `${baseURL}/ObterDevolucaoOcorrencia`,
      query: {
        vinculo,
        autorizacao
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterDevolucaoOcorrencia", error.message);
      return error;
    });
}

// submete multiplas pendências para uma autorização e retorna um array contendo os protocolos de cada chamado gerado (um chamado para cada pendencia)
export async function RetornoPendenciaConvocacao({ vinculo, autorizacao, chamadoConvocacao, observacao, anexos }) {
  const formData = new FormData();
  formData.append("observacao", observacao);
  formData.append("idAutorizacao", autorizacao);
  formData.append("idChamadoConvocacao", chamadoConvocacao);

  for (let i = 0; i < anexos.length; i++) {
    formData.append(`anexo${i + 1}`, anexos[i]);
  }

  return await window.$axios
    .post({
      url: `${baseURL}/RetornoPendenciaConvocacao`,
      query: {
        vinculo
      },
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("NovaAutorizacao", error.message);
      return error;
    });
}

export async function ObterPlanoPrazoRetorno({ vinculo, tipoEvento }) {
  return await window.$axios
    .get({
      url: `${baseURL}/ObterPlanoPrazoRetorno`,
      query: {
        vinculo,
        tipoEvento
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterPlanoPrazoRetorno", error.message);
      return error;
    });
}
