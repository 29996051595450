import Vue from "vue";
import { MeusDocumentos } from "../api/associado";
import { getStorageData, setStorageData } from "@/utils/storageUtil";

let initialState = {
  myFiles: null,
  doc: {}
};

// vuex module
export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions: {
    async listFiles(context) {
      if (!context.state.myFiles) {
        const filesStorage = getStorageData("myFiles", []);
        if (filesStorage && filesStorage.length > 0) {
          await context.commit("SET_FILES", filesStorage);
        } else {
          const files = await MeusDocumentos({
            vinculo: context.rootState.saude.vinculo
          });
          if (files.Data) {
            await context.commit("SET_FILES", files.Data);
          }
          return;
        }
      }
      MeusDocumentos({
        vinculo: context.rootState.saude.vinculo
      }).then(files => {
        if (files.Data) {
          context.commit("SET_FILES", files.Data);
        }
      });
    }
  },
  mutations: {
    LOGOUT_USER: state => {
      Object.keys(state).forEach(p => {
        Vue.set(state, p, initialState[p]);
        setStorageData(p, null);
      });
    },
    RESET_CONTEXT: state => {
      Object.keys(state).forEach(p => {
        Vue.set(state, p, initialState[p]);
        setStorageData(p, null);
      });
    },
    SET_FILES: (state, files) => {
      const prop = "myFiles";
      const newData = Vue.set(state, prop, files);
      setStorageData(prop, newData);
    },
    SET_DOC: (state, { name, url }) => {
      const doc = { name, url };
      Vue.set(state, "doc", doc);
    }
  }
};
