<template>
  <Modal
    :basedOn="showModal"
    @close="showModal = false"
    appendTo="body"
    modal-class="fullscreen-modal"
    :baseZindex="2000"
    @beforeOpen="beforeOpen"
    @beforeClose="beforeClose"
  >
    <CBox position="relative" height="100vh" width="100vw">
      <OmtButton
        @click="closeModalAndSave"
        z-index="1"
        variant="ghost"
        position="absolute"
        top="16px"
        right="5%"
        padding="0"
        min-width="13px"
      >
        <OmtIcons name="xmark" color="white" size="lg" />
      </OmtButton>

      <CImage class="onboard-image" :src="currentStepImage" />

      <CBox class="footer">
        <OmtCheckbox
          v-if="currentStep === onboardData.images.length - 1"
          class="optin"
          :bottom="onboardData.checkboxPosition"
          @change="optin = !optin"
          :is-checked="optin"
        >
          <OmtTypography as="p1" color="white" pl="7px"> Não exibir novamente </OmtTypography>
        </OmtCheckbox>

        <OmtButton height="27px" v-show="currentStep > 0" @click="currentStep--" class="button">
          <OmtTypography as="p1" color="white"> Voltar </OmtTypography>
        </OmtButton>
        <OmtButton height="27px" @click="next" class="button">
          <OmtTypography as="p1" color="white">
            {{ currentStep === onboardData.images.length - 1 ? "Finalizar" : "Próximo" }}
          </OmtTypography>
        </OmtButton>
      </CBox>
    </CBox>
  </Modal>
</template>

<script>
import VueModal from "@kouts/vue-modal";
import affiliateSearchOnboard from "./affiliate-search-onboard";
import copayOnboard from "./copay-onboard";
import initialOnboard from "./initial-onboard";
import refundOnboard from "./refund-onboard";
import bodyScroll from "body-scroll-freezer";
import { mapActions } from "vuex";

export const onboards = {
  "affiliate-search-onboard": {
    images: affiliateSearchOnboard,
    checkboxPosition: "10%",
    template: process.env.VUE_APP_AFFILIATE_SEARCH_ONBOARD
  },
  "copay-onboard": {
    images: copayOnboard,
    checkboxPosition: "10%",
    template: process.env.VUE_APP_COPAY_ONBOARD
  },
  "initial-onboard": {
    images: initialOnboard,
    checkboxPosition: "20%",
    template: process.env.VUE_APP_INITIAL_ONBOARD
  },
  "refund-onboard": {
    images: refundOnboard,
    checkboxPosition: "10%",
    template: process.env.VUE_APP_REFUND_ONBOARD
  }
};

export default {
  props: {
    onboard: {
      type: String,
      validator: val => Object.keys(onboards).includes(val)
    }
  },
  components: {
    Modal: VueModal
  },
  async mounted() {
    this.$nextTick(() => {
      const windowWidth = window.visualViewport?.width || window.innerWidth;
      if (windowWidth > 1024) {
        this.showModal = false;
        return;
      }
    });
  },
  data() {
    return {
      currentStep: 0,
      showModal: true,
      optin: false
    };
  },
  methods: {
    ...mapActions("saude", ["saveClosedOnboard"]),
    beforeOpen() {
      bodyScroll.freeze();
    },
    beforeClose() {
      bodyScroll.unfreeze();
    },
    next() {
      if (this.currentStep === this.onboardData.images.length - 1) {
        this.closeModalAndSave();
      } else this.currentStep++;
    },
    async closeModalAndSave() {
      this.showModal = false;
      try {
        await this.saveClosedOnboard({
          onboard: this.onboard,
          sendToServer: !!this.optin,
          template: onboards[this.onboard].template
        });
      } catch (error) {
        console.error(error);
      }
    }
  },
  computed: {
    onboardData() {
      return onboards[this.onboard];
    },
    currentStepImage() {
      return this.onboardData.images.find((_, index) => index === this.currentStep);
    }
  }
};
</script>

<style>
.vm-content {
  padding: 0 !important;
}

.fullscreen-modal {
  width: 100% !important;
  max-width: 100% !important;
  top: 0 !important;
  margin: 0 !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

.vm-titlebar {
  display: none !important;
}

.onboard-image {
  height: 100%;
  width: 100%;
  z-index: 0;
}

@media all and (orientation: landscape) {
  .onboard-image {
    height: initial;
    width: initial;
  }
}
</style>

<style scoped>
.footer {
  z-index: 1;
}

@media all and (orientation: landscape) {
  .footer {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100vw;
    background: var(--colors-light-primary-disabled);
    height: 64px;
  }

  .footer .optin,
  .footer .button,
  .footer .button + .button {
    bottom: 16px;
  }
}

.button {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  position: absolute;
  bottom: 4.25%;
  right: calc(5% + 112px);
}

.button + .button {
  bottom: 4.25%;
  right: 5%;
}

.optin {
  position: absolute;
  left: 7%;
}

::v-deep .optin > div {
  color: transparent;
  border-color: #fff;
}

::v-deep input:checked + div {
  color: #fff;
  border-color: #fff !important;
}
</style>
