import { mapState, mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      cameraLiberada: true,
      authType: ""
    };
  },
  computed: {
    ...mapState("biometrics", ["camera", "microfone"]),
    ...mapGetters("biometrics", ["onNative"]),
    v25() {
      return Number.parseInt(localStorage.getItem("native_version")) >= 25;
    },
    shouldRequestResources() {
      if (this.authType !== "BIO-UNICO") return false;
      if (!this.v25 || !this.onNative) return !this.cameraLiberada;
      return this.v25 && this.onNative && this.camera !== "granted";
    }
  },
  methods: {
    ...mapActions("biometrics", ["fetchFaceStatus"]),
    ...mapActions("ticket", ["fetchAuthType"]),
    async checkResources() {
      if (navigator && "mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices) {
        try {
          const videoStream = await navigator.mediaDevices.getUserMedia({
            video: true
          });
          const tracks = videoStream.getTracks();
          for (let i = 0; i < tracks.length; i++) {
            await tracks[i].stop();
          }
          this.cameraLiberada = true;
        } catch (error) {
          process.browser && window.$log.error("Error on checkResources video", error);
          this.cameraLiberada = false;
        }
      } else {
        this.cameraLiberada = false;
      }
    },
    async validateActiveBiometry({ service, rules, type, routeBack, sucessCallBack }) {
      try {
        await this.$root.$emit("show-loading");
        this.authType = await this.fetchAuthType({ service, rules, type });
        if (this.authType.Autorizador !== "BIO-UNICO") {
          sucessCallBack(this.authType);
          return;
        }
        const hasBiometry = await this.fetchFaceStatus();
        const { Gerado, Aceito, Processando } = hasBiometry;

        if (!!Gerado && !!Aceito) {
          if (!this.onNative) {
            await this.checkResources();
          }

          if (this.shouldRequestResources) {
            return;
          }

          sucessCallBack(this.authType);
          return;
        }

        const timeout = 5000;
        if (Processando) {
          this.$toast({
            description: "Seu cadastro de biometria ainda está em análise",
            status: "info",
            duration: timeout,
            position: "top"
          });
          return;
        }

        this.$toast({
          description: "Você ainda não possui biometria cadastrada, vamos te redirecionar para a página de cadastro",
          status: "info",
          duration: timeout,
          position: "top"
        });

        setTimeout(() => {
          this.$router.push({
            name: "biometry-register",
            params: {
              routeBack
            }
          });
        }, timeout);
      } catch (error) {
        this.$toast({
          description: error.message ?? "Não foi possível aplicar as regras de autenticação",
          status: "error",
          duration: 5000,
          position: "top"
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
    }
  }
};
