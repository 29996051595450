<template>
  <CBox
    :bg="telefonesValidos.length <= 1 ? 'var(--colors-light-primary-light-gray)' : '#FFFFFF'"
    p="15px"
    mt="24px"
    border-radius="10px"
  >
    <CFlex v-if="telefonesValidos.length === 1">
      <OmtIcons name="phone" height="16px" width="16px" size="1x" mr="16px" />
      <CBox>
        <OmtTypography as="h3-bold" mb="12px"> Telefone </OmtTypography>
        <OmtTypography as="p2" mb="8px">
          {{ telefone }}
        </OmtTypography>
      </CBox>
    </CFlex>
    <CBox v-else-if="telefonesValidos.length === 0">
      <CFlex>
        <OmtIcons name="lightbulb" height="16px" width="16px" mr="16px" size="1x" />
        <OmtTypography as="h2-bold" mb="12px" mt="8px"> Atenção: </OmtTypography>
      </CFlex>
      <OmtTypography as="p1-bold" mb="12px" mt="8px"> Não identificamos nenhum número cadastrado. </OmtTypography>
    </CBox>
    <CBox v-else>
      <CBox v-for="(fone, index) in telefonesValidos" :key="index" mb="24px">
        <OmtTypography as="p1" mb="12px" mt="8px"> {{ fone.tipo }} </OmtTypography>
        <CFlex justify="space-between">
          <OmtRadio @click="onTelefoneSelected(index)" :value="fone.numero" :isChecked="index === indexTelefone">
            <OmtTypography as="p3"> {{ fone.numero }} </OmtTypography>
          </OmtRadio>
        </CFlex>
        <OmtDivider mt="24px" v-if="index !== telefonesValidos.length - 1" />
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      telefone: "",
      telefones: [],
      indexTelefone: -1
    };
  },
  computed: {
    ...mapState("myAccount", ["welcomeOnBoardTourAuth"]),
    ...mapState("myContacts", ["resumeContacts", "existChangeRequest"]),
    ...mapState("atendimento", ["WhatsAppAtivo", "NumeroFormatado", "DDD", "DDI"]),
    whatsapp() {
      return this.WhatsAppAtivo
        ? `+${(this.DDI || 0).zeroLeft()} (${(this.DDD || 0).zeroLeft()}) ${this.NumeroFormatado}`
        : "";
    },
    telefonesValidos() {
      return this.telefones?.filter(t => t.tipo === "Celular" || t.tipo === "Comercial") ?? [];
    }
  },
  methods: {
    ...mapActions("myContacts", ["validateActiveTicket", "fetchResumeContacts"]),
    ...mapActions("atendimento", ["validarAtendimentoWhatsApp", "ativarAtendimentoWhatsApp"]),
    initialValues() {
      const whatsapp = this.newWhatsapp ?? this.whatsapp;
      let fones = [];
      const baseTelefone = this.newPhones ?? this.resumeContacts?.Telefones;
      let jahMarcadoWhatsapp = false;
      if (baseTelefone?.length) {
        fones = baseTelefone.map(t => {
          const numero = `${t.countryCode} ${t.Numero}`;
          const ehWhatsapp = !jahMarcadoWhatsapp && numero === whatsapp;
          if (ehWhatsapp) jahMarcadoWhatsapp = true;
          return {
            tipo: t.Tipo,
            numero,
            whatsapp: ehWhatsapp
          };
        });
      }
      if (this.WhatsAppAtivo) {
        const foneWhatsapp = fones.find(t => t.whatsapp);
        if (!foneWhatsapp)
          fones = [
            {
              numero: whatsapp,
              tipo: "Celular",
              whatsapp: true
            },
            ...fones
          ];
      }
      const telefones = fones.slice(0, 3);
      let indexTelefone = -1;
      telefones.forEach((t, index) => {
        if (t.whatsapp) indexTelefone = index;
      });
      return {
        numero: whatsapp,
        indexTelefone,
        telefones
      };
    },
    onTelefoneSelected(index) {
      this.indexTelefone = index;
      this.telefone = this.telefonesValidos[index].numero;
    },
    async submit() {
      if (this.telefone && this.telefone !== this.whatsapp) {
        await this.ativarAtendimentoWhatsApp({
          phoneNumber: this.telefone,
          auth: this.welcomeOnBoardTourAuth
        });
      }
    }
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");

      if (
        !this.resumeContacts?.Endereco &&
        !this.resumeContacts.Telefones?.length &&
        !this.resumeContacts?.Emails?.Pessoal
      ) {
        await Promise.all([this.fetchResumeContacts(), this.validateActiveTicket(), this.validarAtendimentoWhatsApp()]);
      } else {
        await this.validateActiveTicket();
      }
      const values = this.initialValues();
      this.telefones = values.telefones;
      if (values.numero) {
        this.telefone = values.numero;
        this.indexTelefone = values.indexTelefone;
      } else if (this.telefonesValidos.length === 1) {
        this.telefone = this.telefonesValidos[0].numero;
        this.indexTelefone = 0;
      }

      if (this.existChangeRequest) {
        // não pode editar os telefones existente pois já existe uma alteração em processamento
        this.$root.$emit("change-welcome-tour", { accessLabel: "", nextLabel: "Continuar" });
      } else if (!this.resumeContacts.Telefones?.length) {
        this.$root.$emit("change-welcome-tour", { accessLabel: "Adicionar um número", nextLabel: "Confirmar" });
      } else if (this.resumeContacts.Telefones.length >= 3) {
        this.$root.$emit("change-welcome-tour", { accessLabel: "Editar números", nextLabel: "Continuar" });
      } else {
        this.$root.$emit("change-welcome-tour", { accessLabel: "Adicionar outro número", nextLabel: "Confirmar" });
      }
      this.$root.$emit("welcome-continue", this.submit);
    } catch (error) {
      this.$toast({
        description: error.message,
        status: "error",
        duration: 10000,
        position: "bottom-right"
      });
    } finally {
      this.$root.$emit("hide-loading");
    }
  }
};
</script>
<style scoped></style>
