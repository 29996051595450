<template>
  <OmtLayoutHeaderFooter
    :bg="['none', '#F6F6F9']"
    v-bind="{
      header: {
        titleLabel: 'Cadastro de administradores'
      },
      footer: false
    }"
  >
    <router-view />
  </OmtLayoutHeaderFooter>
</template>

<script>
export default {
  name: "ThirdPartyRegister"
};
</script>
