<template>
  <div class="resume-disclaimer">
    <img :src="baseUrl + '/images/omint/info.svg'" />
    <span> Lembramos que você também pode acompanhar esta solicitação acessando <strong>Atendimento</strong>. </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: { ...mapGetters("saude", ["baseUrl"]) }
};
</script>

<style lang="scss" scoped>
.resume-disclaimer {
  padding: 16px 0;
  display: flex;
  align-items: baseline;

  img {
    margin-right: 14px;
    position: relative;
    top: 4px;
  }
}
</style>
