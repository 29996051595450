<template>
  <CBox data-cy="text-tile">
    <OmtTypography as="p2" mb="4px" color="var(--colors-light-primary-gray2)"> {{ title }} </OmtTypography>
    <OmtTypography as="p1"> {{ description }} </OmtTypography>
  </CBox>
</template>

<script>
/**
 * @prop { String } title
 * @prop { String } description
 */

export default {
  props: {
    title: String,
    description: String
  }
};
</script>
