<template>
  <omint-page-content>
    <omt-p>
      <omt-bold>Prestador</omt-bold>
    </omt-p>
    <Options
      ref="opsDoctors"
      :options="doctorsOptions"
      name="Prestador"
      id="doctors-list"
      :on-selection="onSelectDoctor"
      :preSelected="doctor"
      placeholder="Selecione o prestador"
      :solid="true"
    />

    <omt-p class="calendar">
      <omt-bold>Data</omt-bold>
      <v-date-picker ref="picker" @dayclick="onChooseDate" :value="day" popover-visibility="focus" />
    </omt-p>

    <omt-p>
      <omt-bold>Horário</omt-bold>
    </omt-p>
    <Options
      ref="opsSchedule"
      :options="scheduleOptions"
      name="Schedule"
      id="schedule-list"
      :on-selection="onSelectSchedule"
      :preSelected="schedule"
      placeholder="Selecione o horário"
      :solid="true"
    />

    <br />
    <omt-buttons-bar>
      <omt-button :onClick="() => $router.back()"> Cancelar </omt-button>
      <omt-button :onClick="submit" :disabled="doctor === null || day === null || schedule === null">
        Salvar
      </omt-button>
    </omt-buttons-bar>
  </omint-page-content>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Options from "@/components/Molecules/Options";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "NewScheduleCovid19",
  components: { Options },
  computed: {
    ...mapState("forms", ["prestadores", "horarios"]),
    doctorsOptions() {
      let opts = [];
      let id = 0;
      this.prestadores.forEach(e => {
        opts.push({ value: id++, label: e.nome });
      });
      return opts;
    },
    scheduleOptions() {
      let opts = [];
      let id = 0;
      this.horarios.forEach(e => {
        opts.push({ value: id++, label: e.de });
      });
      return opts;
    }
  },
  data() {
    return {
      doctor: null,
      schedule: null,
      day: null
    };
  },
  methods: {
    ...mapActions("forms", ["listarAgenda", "registrarAgenda"]),
    async onChooseDate(day) {
      this.day = day.date;
    },
    onSelectDoctor(value) {
      this.doctor = this.prestadores[value];
    },
    onSelectSchedule(value) {
      this.schedule = this.horarios[value];
    },
    async submit() {
      await this.$root.$emit("show-loading");
      let saved = false;
      try {
        const de = this.schedule.de.split(":");
        const ate = this.schedule.ate.split(":");
        const inicioDisponibilidade = new Date(
          this.day.getFullYear(),
          this.day.getMonth(),
          this.day.getDate(),
          de[0],
          de[1],
          0
        );
        let terminoDisponibilidade = new Date(
          this.day.getFullYear(),
          this.day.getMonth(),
          this.day.getDate(),
          ate[0],
          ate[1],
          0
        );
        if (terminoDisponibilidade < inicioDisponibilidade) {
          let result = new Date(terminoDisponibilidade);
          result.setDate(terminoDisponibilidade.getDate() + 1);
          terminoDisponibilidade = result;
        }
        saved = await this.registrarAgenda({
          id: 0,
          prestador: this.doctor.nome,
          telefone: this.doctor.telefone,
          email: this.doctor.email,
          inicioDisponibilidade,
          terminoDisponibilidade
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
      if (saved) {
        const userInput = await Swal.fire({
          title: "Sucesso!",
          text: "Agenda registrada com sucesso\n\nDeseja cadastrar mais uma agenda?",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          showCancelButton: true
        });
        if (userInput.value) {
          this.day = null;
        } else {
          this.$router.push({ path: "/forms/covid19/schedule" });
        }
      } else {
        await Swal.fire({
          title: "Erro",
          text: "Não foi possivel registrar a agenda.\nServiço temporariamente indisponível.",
          confirmButtonText: "OK",
          icon: "error"
        });
      }
    }
  }
};
</script>
<style scoped>
.calendar {
  overflow: hidden;
}
</style>
