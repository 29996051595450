<template>
  <omint-page>
    <Header name="Conta Corrente" titleLabel="Conta Corrente" />
    <omint-slider>
      <router-view />
    </omint-slider>
    <Footer />
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";

export default {
  components: { Header, Footer },
  name: "BankAccount"
};
</script>
