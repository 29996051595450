<template>
  <CPseudoBox v-bind="$attrs">
    <BlogLayoutHeader />
    <CPseudoBox
      as="main"
      :p="['16px', '40px 0']"
      :w="['100%', 'calc(100vw - 324px)']"
      max-width="1024px"
      :m="['0', '0 auto']"
    >
      <slot />
    </CPseudoBox>
    <BlogLayoutFooter />
  </CPseudoBox>
</template>

<script>
import BlogLayoutFooter from "./BlogLayoutFooter.vue";
import BlogLayoutHeader from "./BlogLayoutHeader.vue";

export default {
  components: {
    BlogLayoutFooter,
    BlogLayoutHeader
  }
};
</script>
