<template>
  <p :class="myClass">
    <slot />
  </p>
</template>
<script>
export default {
  props: ["simple"],
  computed: {
    myClass() {
      return `p
      ${this.simple === true ? " simple" : " double"}`;
    }
  }
};
</script>
<style scoped>
.p {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  /** definição designer
  font-size: 1.8125rem;
  line-height: 2.75rem;
   */
}

.mb-0 {
  margin-bottom: 0;
}

.big-font-size .p {
  font-size: 19px;
}

.simple {
  line-height: 120%;
  margin: 0px;
  padding: 0px;
}

.double {
  line-height: 26px;
}

.light .p {
  color: var(--colors-light-primary-gray1);
}

.dark .p {
  color: #f0f0f0;
}
</style>
