import { render, staticRenderFns } from "./EvaluationItem.vue?vue&type=template&id=69797784&scoped=true&"
import script from "./EvaluationItem.vue?vue&type=script&lang=js&"
export * from "./EvaluationItem.vue?vue&type=script&lang=js&"
import style0 from "./EvaluationItem.vue?vue&type=style&index=0&id=69797784&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69797784",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFlex: require('@chakra-ui/vue').CFlex})
