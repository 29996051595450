const PROP_PREFIX = process.env.VUE_APP_PROP_PREFIX;
const PROP_EXP = 60 * 24 * 60 * 60 * 1000; // 60 dias

export function getStorageData(prop, defaultValue, checkExp = false) {
  try {
    // busca a propriedade com prefixo
    const ls = localStorage.getItem(`${PROP_PREFIX}${prop}`);
    const json = JSON.parse(ls);
    if (checkExp && "exp" in json && json.exp < Date.now()) return defaultValue;
    return json[prop];
  } catch (err) {
    return defaultValue;
  }
}

export function setStorageData(prop, newData) {
  try {
    const propWithPrefix = `${PROP_PREFIX}${prop}`;
    localStorage.removeItem(propWithPrefix);
    if (newData !== null) {
      const exp = PROP_EXP + Date.now();
      localStorage.setItem(propWithPrefix, JSON.stringify({ exp, [prop]: newData }));
    }
    return true;
  } catch (err) {
    window.$log.error("Storage error.", err);
    return false;
  }
}
