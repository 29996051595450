<template>
  <div class="session-message">
    {{ message }}
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { isLoggedIn, expirationTime } from "@/utils/authUtil";

export default {
  name: "ExpirationTime",
  data() {
    return {
      timerExp: null,
      timerValid: null,
      message: null
    };
  },
  async mounted() {
    const sessionValida = this.checkToken(true);
    if (sessionValida) {
      this.timerExp = setInterval(() => this.checkToken(false), this.tempoExpiracao);
      this.timerValid = setInterval(() => this.checkToken(true), this.tempoValidacao);
    }
  },
  computed: {
    ...mapState("saude", ["hydrated"]),
    exibirTempoExpiracao() {
      return "localhost:8080" === document.location.host;
    },
    tempoExpiracao() {
      return 60 * 1000; // a cada minuto
    },
    tempoValidacao() {
      return 10 * 60 * 1000; // a cada 10 minutos
    }
  },
  methods: {
    ...mapActions("saude", ["testSession"]),
    ...mapActions("accessManagement", ["lockApp"]),
    logout() {
      this.$router.replace("/logout");
    },
    async blockApp() {
      await this.lockApp();
      await this.$router.replace("/login");
    },
    async checkToken(validarSession) {
      if (!navigator.onLine) return true;
      if (!this.hydrated) return true;
      if (!window.$axios.hasAuthToken()) return true;
      if (this.$route.meta?.allowAnonymous) return true;

      if (isLoggedIn()) {
        if (validarSession) {
          const situacao = await this.testSession();
          if (!situacao) {
            this.logout();
            return false;
          }
        }
        if (this.exibirTempoExpiracao) {
          this.message = `Sessão expira em ${expirationTime()}`;
        }
      } else {
        this.message = "Sessão expirada";
        this.blockApp();
      }
      return true;
    }
  },
  beforeDestroy() {
    if (this.timerExp) clearInterval(this.timerExp);
    if (this.timerValid) clearInterval(this.timerValid);
  }
};
</script>
<style scoped>
.session-message {
  display: none;
  clear: both;
  color: white;
  line-height: 15px;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0;
  margin: -15px 0 0 0;
  height: 15px;
  width: 100%;
  z-index: 150;
  background-color: black;
}
</style>
