/* eslint-disable */
import Vue from "vue";

// bibliotecas de terceiros
import VueMask from "v-mask";
import VCalendar from "v-calendar";
import Vuelidate from "vuelidate";
import VueHtml2Canvas from "vue-html2canvas";
import VueRouter from "vue-router";
import VueCroppie from "vue-croppie";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import Chakra, { CThemeProvider, CColorModeProvider, CReset } from "@chakra-ui/vue";

// Arquivos estáticos
import "croppie/croppie.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "../public/styles/sweetalert2.css";
import "../public/styles/equals-web.css";
import "../public/styles/toast.css";

import extendTheme from "./theme";

// controles do vue: rotas e armazenamento
import mainRouter from "./router";
import store from "./store";
import "@/utils/configUtil";
import "@/utils/protoTypes";

// register core componentes
import "@/components/Atoms";
import "@/components/Templates";
import "@/components/Atoms/Icons/library.js";

// componentes Omint
import OmintApp from "./App.vue";
import OmintPage from "./components/Atoms/omt-page.vue";
import OmintPageContent from "./components/Atoms/omt-app-page-content.vue";
import OmintSlider from "./components/Atoms/omt-slider.vue";
import h1 from "./components/Atoms/omt-h1.vue";
import h2 from "./components/Atoms/omt-h2.vue";
import h3 from "./components/Atoms/omt-h3.vue";
import h4 from "./components/Atoms/omt-h4.vue";
import h5 from "./components/Atoms/omt-h5.vue";
import highlight from "./components/Atoms/omt-highlight.vue";
import p from "./components/Atoms/omt-p.vue";
import card from "./components/Atoms/omt-card.vue";
import b from "./components/Atoms/omt-b.vue";
import li from "./components/Atoms/omt-li.vue";
import uppercase from "./components/Atoms/omt-uppercase.vue";
import icon from "./components/Atoms/omt-icon.vue";
import input from "./components/Atoms/omt-input.vue";
import select from "./components/Atoms/omt-select.vue";
import inputBox from "./components/Atoms/omt-input-box.vue";
import button from "./components/Atoms/omt-button.vue";
import buttonsBar from "./components/Atoms/omt-buttons-bar.vue";
import inlineBar from "./components/Atoms/omt-inline-bar.vue";
import divider from "./components/Atoms/omt-divider.vue";
import textarea from "./components/Atoms/omt-textarea.vue";
import reportCard from "./components/Atoms/omt-report-card.vue";

// service worker
import { initServiceWorker } from "./pwa/registerServiceWorker";

const appInsightsKey = process.env.VUE_APP_APPINSIGHTS_KEY || "";
if (appInsightsKey) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.VUE_APP_APPINSIGHTS_KEY,
      disableTelemetry: process.env.VUE_APP_APPINSIGHTS_DISABLE === "true",
      autoTrackPageVisitTime: true, // Flag para ativar rastreio automático de tempo por página
      enableAutoRouteTracking: true // Flag para ativar o rastreio automático de rotas
    }
  });
  const filteringFunction = envelope => {
    if (envelope.baseType === "RemoteDependencyData") {
      if (
        envelope.baseData.target.includes("hotjar") ||
        envelope.baseData.target.startsWith("https://d.clarity.ms") ||
        envelope.baseData.target.startsWith("https://www.google-analytics") ||
        envelope.baseData.target.startsWith("https://gtm") ||
        envelope.baseData.target.startsWith("https://stats.g.doubleclick.net")
      ) {
        return false;
      }
    }
    return true;
  };
  appInsights.addTelemetryInitializer(filteringFunction);
  appInsights.loadAppInsights();
  appInsights.trackEvent({ name: `App ${process.env.VUE_APP_NAME || ""} Loaded!` });

  Vue.config.errorHandler = (err, vm, info) => {
    appInsights.trackException({ exception: err, properties: { vm, info } });
    appInsights.flush();
  };

  Object.defineProperty(Vue.prototype, "$appInsights", {
    get: () => appInsights
  });
  Object.defineProperty(window, "$appInsights", {
    get: () => appInsights
  });
}

Vue.use(VueMask);
Vue.use(Chakra, {
  extendTheme
});

Vue.use(VCalendar);
Vue.use(Vuelidate);
Vue.use(VueHtml2Canvas);
Vue.use(VueRouter);
Vue.use(VueCroppie);

Vue.component("omint-page", OmintPage);
Vue.component("omint-page-content", OmintPageContent);
Vue.component("omint-slider", OmintSlider);
Vue.component("omt-h1", h1);
Vue.component("omt-h2", h2);
Vue.component("omt-h3", h3);
Vue.component("omt-h4", h4);
Vue.component("omt-h5", h5);
Vue.component("omt-highlight", highlight);
Vue.component("omt-card", card);
Vue.component("omt-p", p);
Vue.component("omt-bold", b);
Vue.component("omt-li", li);
Vue.component("omt-uppercase", uppercase);
Vue.component("omt-icon", icon);
Vue.component("omt-input", input);
Vue.component("omt-select", select);
Vue.component("omt-input-box", inputBox);
Vue.component("omt-button", button);
Vue.component("omt-buttons-bar", buttonsBar);
Vue.component("omt-inline-bar", inlineBar);
Vue.component("omt-divider", divider);
Vue.component("omt-textarea", textarea);
Vue.component("omt-report-card", reportCard);

Vue.config.productionTip = false;
window.addEventListener("flutterInAppWebViewPlatformReady", event => console.log({ event }));
if (window.ReactNativeWebView) {
  Vue.prototype.$native = window.ReactNativeWebView;
} else if (window.JSChannel) {
  Vue.prototype.$native = window.JSChannel;
} else if (window.flutter_inappwebview) {
  Vue.prototype.$native = {
    postMessage: message => window.flutter_inappwebview.callHandler("JSChannel", message)
  };
}

export const bus = new Vue();
window.bus = bus;
Vue.prototype.$bus = window.bus;

export const app = new Vue({
  router: mainRouter,
  store,
  render: h => h(CThemeProvider, [h(CReset), h(CColorModeProvider, [h(OmintApp)])])
});

if (!window["Cypress"]) {
  initServiceWorker(bus).then(wb => {
    Vue.prototype.$workbox = wb;
  });

  app.$mount("#app");
}
