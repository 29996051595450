import Vue from "vue";
import { ObterSolicitacoes, ObterDadosRelatorio, EnviarCarta, DownloadCartaSeguroViagem } from "@/api/seguroViagem";

const initialState = {
  filters: { beneficiarios: [], idiomas: [] },
  sorts: ["Mais recentes", "Mais antigos"],
  activedSort: "",
  solicitacoes: [],
  lastFetch: 0,
  beneficiarios: [],
  idiomas: []
};

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  getters: {
    solicitacoesFiltradas: context => {
      let filtradas = [];

      const fb = context.filters.beneficiarios;
      const beneficiarios = Object.keys(fb).filter(f => fb[f]) ?? [];
      const fi = context.filters.idiomas;
      const idiomas = Object.keys(fi).filter(f => fi[f]) ?? [];
      const solicitacoes = JSON.parse(JSON.stringify(context.solicitacoes));
      if (beneficiarios.length || idiomas.length) {
        solicitacoes.forEach(s => {
          const validB = beneficiarios.length ? !!s.beneficiarios.find(f => beneficiarios.includes(f.nome)) : true;
          const validI = idiomas.length ? idiomas.includes(s.idioma) : true;
          if (validB && validI) filtradas.push(s);
        });
      } else {
        filtradas = solicitacoes;
      }

      const sorts = {
        dateToNum(d) {
          d = d.split("/");
          return new Date(d[2], Number(d[1]) - 1, d[0]).getTime();
        },
        "Mais recentes"() {
          filtradas.sort((a, b) => {
            return this.dateToNum(b.data) - this.dateToNum(a.data);
          });
        },
        "Mais antigos"() {
          filtradas.sort((a, b) => {
            return this.dateToNum(a.data) - this.dateToNum(b.data);
          });
        }
      };

      if (context.activedSort) sorts[context.activedSort]();

      return filtradas;
    }
  },
  actions: {
    async fetchSolicitacoes(context, options) {
      const protocolFilter = options?.protocol ?? "";
      const forceFetch = options?.force ?? false;
      if (!forceFetch && !protocolFilter && context.state.lastFetch) {
        const fetchCacheLimit = new Date().getTime() - 900000; // 15min
        if (context.state.lastFetch > fetchCacheLimit) return;
      }

      try {
        const response = await ObterSolicitacoes({
          vinculo: context.rootState.saude.vinculo
        });
        const solicitacoes = [];
        const idiomas = {};
        const beneficiarios = {};
        (response?.Data ?? []).forEach(s =>
          (s.Idiomas ?? []).forEach(i => {
            const solicitacao = {
              data: s.DataInclusao,
              protocolo: s.Protocolo,
              idioma: i.Idioma,
              urlDownload: DownloadCartaSeguroViagem({
                vinculo: context.rootState.saude.vinculo,
                documento: i.Documento
              }),
              beneficiarios: []
            };
            if (!idiomas[i.Idioma]) idiomas[i.Idioma] = context.state.filters.idiomas[i.Idioma] ?? false;
            (i.Beneficiarios ?? []).forEach(b => {
              solicitacao.beneficiarios.push({
                nome: b.Beneficiario,
                parentesco: b.Parentesco
              });
              if (!beneficiarios[b.Beneficiario])
                beneficiarios[b.Beneficiario] = context.state.filters.beneficiarios[b.Beneficiario] ?? false;
            });
            solicitacoes.push(solicitacao);
          })
        );

        context.commit("SET_SOLICITACOES", { solicitacoes, idiomas, beneficiarios });
        return true;
      } catch {
        return false;
      }
    },
    async fetchBeneficiarios(context) {
      if (context.state.beneficiarios?.lenght && context.state.idiomas?.lenght) return;
      try {
        const resp = await ObterDadosRelatorio({
          vinculo: context.rootState.saude.vinculo
        });
        const baseUrl = context.rootGetters["saude/baseUrl"];
        context.commit(
          "SET_BENEFICIARIOS",
          (resp.Data?.Beneficiarios ?? []).map(b => ({
            nome: b.Beneficiario,
            seq: b.Sequencia,
            med: b.PlanoMedico,
            odo: b.PlanoOdonto,
            parentesco: b.Parentesco
          }))
        );
        context.commit(
          "SET_IDIOMAS",
          (resp.Data?.Idiomas ?? []).map(i => ({
            id: i.Id,
            label: i.Texto,
            img: `${baseUrl}/images/bandeiras/${i.Id}.png`
          }))
        );
        return true;
      } catch {
        return false;
      }
    },
    async postEnviarCarta(context, { idiomas, beneficiarios }) {
      try {
        const resp = await EnviarCarta({
          vinculo: context.rootState.saude.vinculo,
          idiomas,
          beneficiarios
        });
        const sucesso = !!resp?.Data;
        if (sucesso) context.dispatch("fetchSolicitacoes", { force: true });
        const docs = resp.Data.Documentos ?? {};
        return {
          sucesso,
          erro: sucesso ? "" : "Não foi possível gerar a carta. Tente novamente em alguns minutos.",
          protocolo: sucesso
            ? {
                email: resp.Data.Email,
                protocoloANS: resp.Data.Protocolo,
                documentos: Object.keys(docs).map(d => ({
                  label: d,
                  urlDownload: DownloadCartaSeguroViagem({
                    vinculo: context.rootState.saude.vinculo,
                    documento: docs[d]
                  })
                }))
              }
            : {}
        };
      } catch (error) {
        return {
          sucesso: false,
          erro: error.message,
          protocolo: {}
        };
      }
    }
  },
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    SET_ACTIVED_SORT: (state, activedSort) => Vue.set(state, "activedSort", activedSort),
    SET_FILTERS: (state, filters) => Vue.set(state, "filters", filters),
    SET_SOLICITACOES: (state, { solicitacoes, idiomas, beneficiarios }) => {
      Vue.set(state, "solicitacoes", solicitacoes);
      Vue.set(state, "lastFetch", new Date().getTime());
      Vue.set(state, "filters", {
        idiomas,
        beneficiarios
      });
    },
    SET_BENEFICIARIOS: (state, beneficiarios) => Vue.set(state, "beneficiarios", beneficiarios),
    SET_IDIOMAS: (state, idiomas) => Vue.set(state, "idiomas", idiomas)
  }
};
