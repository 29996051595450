<template>
  <div>
    <CBox :bg="['none', 'var(--colors-light-primary-light-gray)']" minHeight="100vh" width="100vw" position="sticky">
      <OmtLayoutContainer>
        <OmtRow :mb="['32px', '48px']">
          <OmtTypography as="h1-bold"> Selecione a localidade </OmtTypography>
        </OmtRow>
        <template v-if="locationFilterOptions.length">
          <OmtRow mb="24px">
            <OmtTypography as="h2-bold"> Distância </OmtTypography>
          </OmtRow>
          <OmtRow v-for="(option, index) in locationFilterOptions" :key="index">
            <CBox>
              <CFlex justify="space-between" align-items="center" cursor="pointer">
                <OmtRadio
                  @click="onLocationTypeChange"
                  :value="option.value"
                  :isChecked="locationType === option.value"
                  name="distance"
                >
                  <OmtTypography as="p3"> {{ option.label }} </OmtTypography>
                </OmtRadio>
              </CFlex>
              <OmtDivider mt="24px" mb="24px" v-if="index !== locationFilterOptions.length - 1" />
            </CBox>
          </OmtRow>
        </template>

        <OmtRow :mt="['32px', '48px']" mb="24px">
          <OmtTypography as="h2-bold"> Exibir por região </OmtTypography>
        </OmtRow>
        <OmtRow mb="16px">
          <CBox>
            <OmtTypography as="p1"> Estado </OmtTypography>
            <OmtSelect
              placeholder="Selecione o estado"
              @change="onStateSelection"
              mb="24px"
              :initialValue="selectedState"
            >
              <option v-for="(state, index) in states" :key="index" :value="state.value">
                {{ state.label }}
              </option>
            </OmtSelect>
          </CBox>
        </OmtRow>
        <OmtRow mb="16px">
          <CBox>
            <OmtTypography as="p1"> Cidade </OmtTypography>
            <OmtSelect
              placeholder="Selecione a cidade"
              :disabled="!cities.length"
              @change="onCitySelection"
              mb="24px"
              :initialValue="selectedCity"
            >
              <option v-for="(city, index) in cities" :key="index" :value="city.value">
                {{ city.label }}
              </option>
            </OmtSelect>
          </CBox>
        </OmtRow>
        <OmtRow :mb="['32px', '52px']">
          <CBox>
            <OmtTypography as="p1"> Bairro </OmtTypography>
            <OmtSelect
              placeholder="Selecione o bairro"
              :disabled="!neighborhoods.length"
              @change="onNeighborhoodSelection"
              mb="24px"
              :initialValue="selectedNeighborhood"
            >
              <option v-for="(neighborhood, index) in neighborhoods" :key="index" :value="neighborhood.value">
                {{ neighborhood.label }}
              </option>
            </OmtSelect>
          </CBox>
        </OmtRow>
        <OmtRow :desktopStart="5" :desktopEnd="8">
          <OmtButton @click="submit" :disabled="!(isSearchForRegion || isSearchForLocationType)"> Continuar </OmtButton>
        </OmtRow>
      </OmtLayoutContainer>
    </CBox>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "LocationFilter",
  async mounted() {
    if (!this.group || !this.codigoEspecialidade) {
      await this.$router.replace({ name: "affiliate" });
      return;
    }
    try {
      await this.$root.$emit("show-loading");
      if (this.deactivated) {
        this.useSearchByRegion();
      }
      this.$root.$emit(
        "change-back-route",
        this.codigoSubEspecialidade ? "/redecredenciada/subespecialidades" : "/redecredenciada/especialidades"
      );
      if (
        this.prevRoute === "/redecredenciada/subespecialidades" ||
        this.prevRoute === "/redecredenciada/especialidades"
      ) {
        await this.fetchRegions();
      }
      this.states = await this.fetchStates();
      await this.$root.$emit("hide-loading");
    } catch (error) {
      await this.$router.replace({ name: "affiliate" });
    }
  },
  data() {
    return {
      selectedState: null,
      selectedCity: null,
      selectedNeighborhood: null,
      states: [],
      cities: [],
      neighborhoods: [],
      prevRoute: "/redecredenciada/subespecialidades",
      distanceOption: "",
      locationType: ""
    };
  },
  beforeRouteEnter(_, from, next) {
    next(vm => {
      vm.prevRoute = from.path;
    });
  },
  computed: {
    ...mapState("affiliate", [
      "group",
      "codigoEspecialidade",
      "codigoSubEspecialidade",
      "locationFilter",
      "deactivated"
    ]),
    locationFilterOptions() {
      const options = [];
      if (this.locationFilter.C && this.locationFilter.C !== "Seu local de trabalho") {
        options.push({
          value: "C",
          label: "Próximo ao meu trabalho",
          end: this.locationFilter.C
        });
      }
      if (this.locationFilter.R && this.locationFilter.R !== "Seu endereço residencial") {
        options.push({
          value: "R",
          label: "Próximo a minha casa",
          end: this.locationFilter.R
        });
      }
      if (this.locationFilter.G && this.locationFilter.G !== "Perto de Sua Localização Atual") {
        options.push({
          value: "G",
          label: "Próximo a minha localização atual",
          end: this.locationFilter.G
        });
      }
      return options;
    },
    isSearchForRegion() {
      return !!this.selectedState;
    },
    isSearchForLocationType() {
      return !!this.locationType && !this.selectedCity && !this.selectedNeighborhood && !this.selectedState;
    }
  },
  methods: {
    ...mapActions("affiliate", ["fetchRegions", "fetchStates", "fetchCities", "fetchNeighborhoods"]),
    ...mapMutations("affiliate", [
      "SET_STATE",
      "SET_CITY",
      "SET_NEIGHBORHOOD",
      "SET_LOCATION_TYPE",
      "SET_FILTERS",
      "SET_SORT"
    ]),
    async onStateSelection(val) {
      this.selectedState = val;
      this.onLocationTypeChange("");
      this.SET_STATE(val);
      this.SET_CITY("");
      this.SET_NEIGHBORHOOD("");
      this.cities = await this.fetchCities();
    },
    async onCitySelection(val) {
      this.selectedCity = val;
      this.onLocationTypeChange("");
      this.SET_CITY(val);
      this.SET_NEIGHBORHOOD("");
      this.neighborhoods = await this.fetchNeighborhoods();
    },
    onNeighborhoodSelection(val) {
      this.selectedNeighborhood = val;
      this.onLocationTypeChange("");
      this.SET_NEIGHBORHOOD(val);
    },
    useSearchByRegion() {
      this.$root.$emit("change-back-route", null);
      this.SET_LOCATION_TYPE("Busca por região");
      if (this.deactivated) {
        this.$router.replace({ name: "affiliate.docs" });
      } else {
        this.$router.push({ name: "affiliate.docs" });
      }
    },
    useSearchByLocationType() {
      this.$root.$emit("change-back-route", null);
      if (this.locationType === "R") {
        this.SET_LOCATION_TYPE("Perto da minha casa");
      } else if (this.locationType === "C") {
        this.SET_LOCATION_TYPE("Perto do meu trabalho");
      } else {
        this.SET_LOCATION_TYPE("Sua localização atual");
      }

      this.SET_STATE("");
      this.SET_CITY("");
      this.SET_NEIGHBORHOOD("");
      this.$router.push({ name: "affiliate.docs" });
    },
    submit() {
      this.SET_FILTERS("");
      this.SET_SORT("");
      if (this.isSearchForRegion) {
        this.useSearchByRegion();
      } else if (this.isSearchForLocationType) {
        this.useSearchByLocationType();
      }
    },
    onLocationTypeChange(type) {
      this.locationType = type;
    }
  },
  watch: {
    locationType(type) {
      if (type) {
        this.selectedState = null;
        this.selectedCity = null;
        this.selectedNeighborhood = null;
      }
    }
  }
};
</script>
