<template>
  <CBox v-if="match" :bg="['none', 'var(--colors-light-primary-light-gray)']" minHeight="100vh" width="100vw">
    <OmtLayoutContainer>
      <OmtRow mb="10px">
        <CFlex>
          <CBox mr="2">
            <OmtIcons
              name="user-doctor"
              p="8px"
              backgroundColor="var(--colors-light-primary-light-gray)"
              borderRadius="8px"
              h="32px"
              w="32px"
              size="1x"
              display="flex"
              justify-content="center"
              align-items="center"
            />
          </CBox>
          <CFlex width="80%" mr="auto" align-items="center">
            <OmtTypography as="button-medium">{{ name }}</OmtTypography>
          </CFlex>
          <CBox v-show="false" mr="4">
            <OmtIcons size="1x" name="share-nodes" />
          </CBox>
          <CBox v-show="hydrated" width="auto">
            <OmtIcons
              v-show="!deactivated"
              size="1x"
              name="heart"
              v-on:click.prevent="changeFavorite"
              cursor="pointer"
              v-if="!favorite"
            />
            <OmtIcons
              v-show="!deactivated"
              size="1x"
              name="heart"
              prefix="fa-sharp"
              v-on:click.prevent="changeFavorite"
              cursor="pointer"
              v-else
            />
          </CBox>
        </CFlex>
      </OmtRow>

      <OmtRow mb="10px">
        <omt-divider />
      </OmtRow>

      <OmtRow v-if="match.Distancia !== undefined && !Number.isNaN(match.Distancia)" mb="16px">
        <CBox>
          <CFlex align-items="center" mb="4px">
            <OmtIcons size="1x" name="location-dot" mr="2" />
            <OmtTypography as="h3-bold"> {{ distanciaFormatada }} km • A partir {{ locationTypeParse }} </OmtTypography>
          </CFlex>
          <OmtTypography ml="20px" as="h3-bold" v-if="docInfo.OrientacaoAtendimentoRede === 'S'">
            Rede Mediservice
          </OmtTypography>
        </CBox>
      </OmtRow>

      <OmtRow mb="4px" v-if="address1 !== ''">
        <CFlex>
          <OmtTypography as="p1">{{ address1 }}</OmtTypography>
        </CFlex>
      </OmtRow>

      <OmtRow mb="24px" v-if="address2 !== ''">
        <CFlex>
          <OmtTypography as="p1">{{ address2 }}</OmtTypography>
        </CFlex>
      </OmtRow>

      <OmtRow v-if="notaFormatada" mb="24px" v-show="false">
        <CFlex justifyContent="space-between">
          <CBox display="inline-flex" alignItems="center">
            <OmtIcons size="1x" prefix="fa-sharp" name="star" mr="2" color="var(--colors-light-support-warning)" />
            <OmtTypography as="h3-bold" color="var(--colors-light-support-warning)">{{ notaFormatada }}</OmtTypography>
          </CBox>
          <CBox v-if="match.Avaliacoes">
            <OmtTypography as="p1">{{ match.Avaliacoes }} avaliações</OmtTypography>
          </CBox>
        </CFlex>
      </OmtRow>

      <OmtRow v-if="docInfo.UrlAgenda" mb="24px">
        <CFlex>
          <OmtButton
            height="auto"
            py="8px"
            w="100%"
            backgroundColor="transparent"
            color="var(--colors-light-primary-gray1)"
            border="1px solid var(--colors-light-secondary-divider)"
            borderRadius="24px"
            @click="() => gotoSite(docInfo.UrlAgenda)"
          >
            <OmtIcons size="1x" name="calendar" mr="2" />
            <OmtTypography as="h3-bold">Agenda online</OmtTypography>
          </OmtButton>
        </CFlex>
      </OmtRow>

      <OmtRow v-if="match.WhatsApp" mb="24px">
        <CFlex>
          <OmtButton
            height="auto"
            py="8px"
            w="100%"
            backgroundColor="transparent"
            color="var(--colors-light-primary-gray1)"
            border="1px solid var(--colors-light-secondary-divider)"
            borderRadius="24px"
            @click="() => redirectWhats(match.WhatsApp)"
          >
            <OmtIcons size="1x" name="whatsapp" mr="2" color="var(--colors-light-support-success)" />
            <OmtTypography as="h3-bold">Agendar por WhatsApp</OmtTypography>
          </OmtButton>
        </CFlex>
      </OmtRow>

      <OmtRow mb="24px" v-for="(c, i) in docInfo.Contatos" :key="'tel-' + i">
        <CFlex>
          <OmtButton
            @click="() => goTo('tel: ' + c.EnderecoTelefoneDdd + c.EnderecoTelefonePrefixo + c.EnderecoTelefone)"
            height="auto"
            py="8px"
            w="100%"
            backgroundColor="transparent"
            color="var(--colors-light-primary-gray1)"
            border="1px solid var(--colors-light-secondary-divider)"
            borderRadius="32px"
          >
            <OmtIcons size="xl" name="phone" mr="2" /><OmtTypography as="h3-bold">
              ({{ c.EnderecoTelefoneDdd }}) {{ c.EnderecoTelefonePrefixo }} {{ c.EnderecoTelefone }}
            </OmtTypography>
          </OmtButton>
        </CFlex>
      </OmtRow>

      <OmtRow
        mb="24px"
        v-if="v24 && match.GmLatitude && match.GmLongitude && match.GmLongitude !== '' && match.GmLatitude !== ''"
      >
        <CFlex justifyContent="space-between" width="100%">
          <CFlex
            width="45%"
            py="10px"
            border="1px solid var(--colors-light-secondary-divider)"
            borderRadius="8px"
            justifyContent="center"
            alignItems="center"
            @click="goTo(`https://www.google.com/maps/search/?api=1&query=${match.GmLatitude}%2C${match.GmLongitude}`)"
          >
            <img :src="googleMaps" />
          </CFlex>
          <CFlex
            width="45%"
            py="10px"
            border="1px solid var(--colors-light-secondary-divider)"
            bgColor="#15CCFF"
            borderRadius="8px"
            justifyContent="center"
            alignItems="center"
            @click="goTo(`https://www.waze.com/ul?ll=${match.GmLatitude}%2C${match.GmLongitude}&navigate=yes&zoom=17`)"
          >
            <img :src="waze" />
            <img :src="wazeIcon" />
          </CFlex>
        </CFlex>
      </OmtRow>

      <OmtRow v-if="docInfo.OrientacaoAtendimentoRede === 'S'" mb="16px">
        <CBox p="16px" borderRadius="8px" backgroundColor="var(--colors-light-primary-light-gray)">
          <CFlex mb="16px" align-items="baseline">
            <OmtIcons name="circle-exclamation" mr="14px" size="1x" />
            <OmtTypography as="button-medium">Atenção!</OmtTypography>
          </CFlex>

          <CBox mb="16px">
            <OmtTypography as="p1">
              Esse local pertence a <b>rede complementar {{ redeComplementar }} </b>, contratada para atender seu plano
              em
              <b>regiões onde a Omint não possui contratação direta.</b>
            </OmtTypography>
          </CBox>

          <CBox
            v-if="docInfo.PlanoAtendimentoRede || docInfo.PadraoAtendimentoRede || docInfo.CredencialAtendimentoRede"
            mb="16px"
          >
            <OmtTypography as="p1" display="inline-flex">
              Para atendimento, você deve apresentar no local sua credencial {{ redeComplementar }} e informar os dados
              do plano:
            </OmtTypography>
          </CBox>

          <CBox border="1px solid var(--colors-light-secondary-divider)" border-radius="8px" p="16px">
            <CFlex v-if="docInfo.PlanoAtendimentoRede" mr="2" align-items="center">
              <OmtIcons name="address-card" size="1x" />
              <OmtTypography ml="8px" as="p1-bold"> Plano {{ docInfo.PlanoAtendimentoRede }} </OmtTypography>
            </CFlex>
            <omt-divider />
            <OmtTypography as="p1" v-if="docInfo.CredencialAtendimentoRede">
              <b>Credencial:</b> {{ docInfo.CredencialAtendimentoRede }}
            </OmtTypography>
            <OmtTypography as="p1" mt="16px !important" v-if="docInfo.PadraoAtendimentoRede">
              <b>Padrão:</b> {{ docInfo.PadraoAtendimentoRede }}
            </OmtTypography>
          </CBox>
        </CBox>
      </OmtRow>

      <template v-if="docInfo.DescricaoObservacao">
        <OmtRow mb="16px">
          <CFlex alignItems="center">
            <CBox mr="2">
              <OmtIcons
                size="1x"
                name="circle-exclamation"
                p="8px"
                backgroundColor="var(--colors-light-primary-light-gray)"
                borderRadius="8px"
                h="32px"
                w="32px"
                display="flex"
                justify-content="center"
                align-items="center"
              />
            </CBox>
            <CBox mr="2">
              <OmtTypography as="button-medium">Observações</OmtTypography>
            </CBox>
          </CFlex>
        </OmtRow>
        <OmtRow mb="10px">
          <OmtTypography as="p1" display="inline-flex">
            {{ docInfo.DescricaoObservacao }}
          </OmtTypography>
        </OmtRow>
      </template>

      <template v-if="docInfo.Especialidades && docInfo.Especialidades.length > 0">
        <OmtRow v-if="docInfo.DescricaoObservacao" mb="10px">
          <omt-divider />
        </OmtRow>
        <OmtRow mb="16px">
          <CFlex alignItems="center">
            <CBox mr="2">
              <OmtIcons
                size="1x"
                name="user-doctor"
                p="8px"
                backgroundColor="var(--colors-light-primary-light-gray)"
                borderRadius="8px"
                h="32px"
                w="32px"
                display="flex"
                justify-content="center"
                align-items="center"
              />
            </CBox>
            <CBox mr="2">
              <OmtTypography as="button-medium">Especialidades</OmtTypography>
            </CBox>
          </CFlex>
        </OmtRow>

        <OmtRow mb="10px">
          <CFlex>
            <CList styleType="disc" pl="8px">
              <CListItem v-for="(e, i) in docInfo.Especialidades" :key="`spec-${i}`">
                <OmtTypography as="p1" display="inline-flex">
                  {{ e.DescricaoEspecialidade.trim() }}
                </OmtTypography>
              </CListItem>
            </CList>
          </CFlex>
        </OmtRow>
      </template>

      <template v-if="docInfo.Formacao && docInfo.Formacao.length > 0">
        <OmtRow mb="10px">
          <omt-divider />
        </OmtRow>

        <OmtRow mb="16px">
          <CFlex alignItems="center">
            <CBox mr="2">
              <OmtIcons
                size="1x"
                name="file-certificate"
                p="8px"
                backgroundColor="var(--colors-light-primary-light-gray)"
                borderRadius="8px"
                h="32px"
                w="32px"
                display="flex"
                justify-content="center"
                align-items="center"
              />
            </CBox>
            <CBox mr="2">
              <OmtTypography as="button-medium">Dados curriculares</OmtTypography>
            </CBox>
          </CFlex>
        </OmtRow>

        <OmtRow v-for="(c, i) in docInfo.Formacao" :key="`cv-${i}`">
          <CBox>
            <OmtTypography as="p1" v-if="c.Periodo">
              <strong>{{ c.Periodo }}</strong>
            </OmtTypography>
            <OmtTypography as="p1"> {{ c.Descricao }}</OmtTypography>
          </CBox>
        </OmtRow>
      </template>

      <template v-if="facilidades && facilidades.length > 0">
        <OmtRow mb="10px">
          <omt-divider />
        </OmtRow>

        <OmtRow :mb="['24px', '32px']">
          <CFlex alignItems="center">
            <CBox mr="2">
              <OmtIcons
                size="1x"
                name="check"
                p="8px"
                backgroundColor="var(--colors-light-primary-light-gray)"
                borderRadius="8px"
                h="32px"
                w="32px"
                display="flex"
                justify-content="center"
                align-items="center"
              />
            </CBox>
            <CBox mr="2">
              <OmtTypography as="button-medium">Facilidades</OmtTypography>
            </CBox>
          </CFlex>
        </OmtRow>

        <OmtRow mb="24px">
          <CFlex>
            <CList styleType="disc" pl="8px">
              <CListItem v-for="(e, i) in facilidades" :key="`acc-${i}`">
                <template v-if="e === teleconsulta">
                  <CFlex display="inline-flex">
                    <OmtTypography display="inline-flex" as="p1">{{ e }}</OmtTypography>
                    <OmtIcons name="video" ml="12px" />
                  </CFlex>
                </template>
                <OmtTypography v-else display="inline-flex" as="p1">{{ e }}</OmtTypography>
              </CListItem>
            </CList>
          </CFlex>
        </OmtRow>
      </template>

      <template v-if="idioms && idioms.length > 0">
        <OmtRow mb="10px">
          <omt-divider />
        </OmtRow>

        <OmtRow mb="16px">
          <CFlex alignItems="center">
            <CBox mr="2">
              <OmtIcons
                size="1x"
                name="earth-americas"
                p="8px"
                backgroundColor="var(--colors-light-primary-light-gray)"
                borderRadius="8px"
                h="32px"
                w="32px"
                display="flex"
                justify-content="center"
                align-items="center"
              />
            </CBox>
            <CBox mr="2">
              <OmtTypography as="button-medium">Idiomas</OmtTypography>
            </CBox>
          </CFlex>
        </OmtRow>

        <OmtRow>
          <CFlex>
            <CList styleType="disc" pl="8px">
              <CListItem v-for="(id, i) in idioms" :key="`lang-${i}`">
                <OmtTypography display="inline-flex" as="p1">{{ id.trim() }}</OmtTypography>
              </CListItem>
            </CList>
          </CFlex>
        </OmtRow>
      </template>

      <OmtRow mb="10px">
        <omt-divider />
      </OmtRow>

      <OmtRow mb="16px">
        <CFlex alignItems="center">
          <CBox mr="2">
            <OmtIcons
              size="1x"
              name="medal"
              p="8px"
              backgroundColor="var(--colors-light-primary-light-gray)"
              borderRadius="8px"
              h="32px"
              w="32px"
              display="flex"
              justify-content="center"
              align-items="center"
            />
          </CBox>
          <CBox mr="2">
            <OmtTypography as="button-medium">Qualificações</OmtTypography>
          </CBox>
        </CFlex>
      </OmtRow>

      <OmtRow mb="16px">
        <CFlex>
          <CFlex
            v-if="docInfo.Qualificacoes && docInfo.Qualificacoes.length > 0"
            w="100%"
            p="16px"
            bg="var(--colors-light-primary-light-gray)"
            border-radius="8px"
            justify-content="center"
          >
            <CFlex v-for="(q, i) in docInfo.Qualificacoes" :key="'qap-' + i" align-items="center">
              <CBox v-if="i > 0" m="-8px 16px" h="22px" border-left="1px solid var(--colors-light-secondary-divider)" />
              <img :src="q.UrlIconeImagem" :alt="q.CodigoIcone" v-chakra max-w="30px" max-h="16px" />
            </CFlex>
          </CFlex>
          <CBox w="100%" v-else p="10px 30px 10px 8px" bg="var(--colors-light-primary-light-gray)" border-radius="8px">
            <OmtTypography as="h3-semi-bold">Qualificações não informadas pelo Prestador.</OmtTypography>
          </CBox>
        </CFlex>
      </OmtRow>

      <OmtRow mb="10px">
        <omt-divider />
      </OmtRow>

      <OmtRow mb="24px">
        <CBox>
          <OmtTypography as="p1">
            Conforme Instrução Normativa 52/DIDES de 21/03/2013 a qualificação é uma informação voluntaria do Prestador
          </OmtTypography>
        </CBox>
      </OmtRow>

      <OmtRow mb="24px">
        <CFlex>
          <CList>
            <CListItem
              v-for="(q, i) in qualificacoesANS"
              :key="'qaa-' + i"
              display="inline-flex"
              w="100%"
              :pb="i < qualificacoesANS.length - 1 ? '16px' : ''"
            >
              <img :src="q.src" :alt="q.name" />
              <OmtTypography ml="2" as="p1">{{ q.dsc }}</OmtTypography>
            </CListItem>
          </CList>
        </CFlex>
      </OmtRow>

      <template v-if="pictures && pictures.length > 0">
        <OmtRow mb="10px">
          <omt-divider />
        </OmtRow>

        <OmtRow mb="12px">
          <CFlex alignItems="center">
            <CBox mr="2">
              <OmtIcons
                size="1x"
                name="image"
                p="8px"
                backgroundColor="var(--colors-light-primary-light-gray)"
                borderRadius="8px"
                h="32px"
                w="32px"
                display="flex"
                justify-content="center"
                align-items="center"
              />
            </CBox>
            <CBox mr="2">
              <OmtTypography as="button-medium">Fotos do local</OmtTypography>
            </CBox>
          </CFlex>
        </OmtRow>

        <OmtRow mb="24px" v-if="pictures.length">
          <OmtSwiper class="pictures-swiper" :pagination="true" :spaceBetween="16" w="100%" :key="pictures.length">
            <OmtSwiperSlide
              v-for="(picture, index) in pictures"
              :key="'foto-' + index"
              maxWidth="322px"
              :slidesPerView="1.1"
              bgColor="#CCC"
              borderRadius="8px"
              height="120px"
            >
              <ImagePreviewer v-bind="picture" />
            </OmtSwiperSlide>
          </OmtSwiper>
        </OmtRow>
      </template>

      <OmtRow mb="24px" v-if="redesSociais && redesSociais.length > 0">
        <CFlex justifyContent="space-evenly">
          <CBox v-for="(r, i) in redesSociais" :key="'redesocial-' + i">
            <OmtButton
              :disabled="!r.Link"
              height="38px"
              background="transparent"
              :opacity="r.Link ? '1' : '0.4 !important'"
              ps="0"
              pe="0"
              border="1px solid var(--colors-light-primary-gray1)"
              borderRadius="100%"
              @click="goToRedeSocial(r)"
            >
              <OmtIcons prefix="fa-brands" size="1x" :name="r.Tipo" />
            </OmtButton>
          </CBox>
        </CFlex>
      </OmtRow>

      <OmtRow mb="24px">
        <CFlex pb="4">
          <OmtButton pt="12px" pb="12px" w="100%" height="auto" @click="() => showPlansAttended()">
            <OmtTypography as="h3-bold" color="#FFF">Consultar planos atendidos</OmtTypography>
          </OmtButton>
        </CFlex>
      </OmtRow>

      <OmtRow mb="24px" v-if="deactivated">
        <CFlex pb="4">
          <OmtButton pt="12px" pb="12px" w="100%" height="auto" @click="() => showSubstitutes()">
            <OmtTypography as="h3-bold" color="#FFF">Credenciados substitutos</OmtTypography>
          </OmtButton>
        </CFlex>
      </OmtRow>
    </OmtLayoutContainer>
  </CBox>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ImagePreviewer from "@/components/Molecules/ImagePreviewer";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { isMobile } from "@/utils/utils";

export default {
  name: "DoctorsInfo",
  components: {
    ImagePreviewer
  },
  data() {
    return {
      favorite: false,
      pictures: []
    };
  },
  async mounted() {
    await this.$root.$emit("show-loading");
    try {
      let inativos = this.Situacao !== "A";
      if (!this.hydrated) {
        this.SET_REDE(this.RedeBusca);
        inativos = false;
      }
      await this.fetchDoctorsInfo({
        rede: this.rede,
        codigoPrestador: this.CdPrestador,
        srEndereco: this.SrEndereco,
        inativos
      });
      await this.checkDeactivateMatch();
      if (!this.match.CodigoPrestador) {
        this.$router.replace({ name: "affiliate" });
        return;
      }
      this.loadPictures();
      if (!this.deactivated) {
        this.addAffiliateView({
          prestador: this.match.CodigoPrestador,
          endereco: this.match.SrEndereco
        });
        this.testFavorite({
          prestador: this.match.CodigoPrestador,
          endereco: this.match.SrEndereco
        }).then(r => (this.favorite = r));
      }
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  computed: {
    ...mapState("affiliate", ["docInfo", "match", "deactivated", "rede", "locationType"]),
    ...mapState("saude", ["hydrated"]),
    ...mapGetters("saude", ["baseUrl"]),
    ...mapGetters("biometrics", ["onNative"]),
    CdPrestador() {
      return this.$route.params.cdPrestador ?? "";
    },
    SrEndereco() {
      return this.$route.params.srEndereco ?? "";
    },
    Situacao() {
      return this.$route.params.situacao ?? "";
    },
    RedeBusca() {
      return this.$route.params.rede ?? "";
    },
    isMobile() {
      return isMobile;
    },
    distanciaFormatada() {
      return this.match?.Distancia ? `${Math.round(this.match.Distancia * 100) / 100}`.replaceAll(".", ",") : "";
    },
    notaFormatada() {
      return this.match?.ScoreTotal ? `${Math.round(this.match.ScoreTotal * 10) / 10}`.replaceAll(".", ",") : "";
    },
    teleconsulta() {
      return "Atende também por teleconsulta";
    },
    facilidades() {
      const acessos = [];
      if (this.docInfo?.Acessos?.length) acessos.push(...this.docInfo.Acessos);
      if (this.match?.Teleconsulta && !acessos.find(a => a.toUpperCase().includes("TELECONSULTA")))
        acessos.push(this.teleconsulta);
      return acessos;
    },
    googleMaps() {
      return process.env.VUE_APP_BASE_URL + "images/omint/google-maps.png";
    },
    waze() {
      return process.env.VUE_APP_BASE_URL + "images/omint/waze.png";
    },
    wazeIcon() {
      return process.env.VUE_APP_BASE_URL + "images/omint/waze-icon.png";
    },
    qualificacoesANS() {
      const qa = [
        {
          name: "Aona",
          dsc: "Padrão nacional de qualidade",
          src: `${this.baseUrl}/images/omint/busca/aona.jpg`
        },
        {
          name: "Adicq",
          dsc: "Padrão nacional de qualidade",
          src: `${this.baseUrl}/images/omint/busca/adicq.jpg`
        },
        {
          name: "Apalc",
          dsc: "Padrão nacional de qualidade",
          src: `${this.baseUrl}/images/omint/busca/apalc.jpg`
        },
        {
          name: "Acba",
          dsc: "Padrão internacional de qualidade",
          src: `${this.baseUrl}/images/omint/busca/acba.jpg`
        },
        {
          name: "Aqg",
          dsc: "Padrão internacional de qualidade",
          src: `${this.baseUrl}/images/omint/busca/aqg.jpg`
        },
        {
          name: "R",
          dsc: "Profissional com residência",
          src: `${this.baseUrl}/images/omint/busca/r.jpg`
        },
        {
          name: "N",
          dsc: "Comunicação de eventos adversos",
          src: `${this.baseUrl}/images/omint/busca/n.jpg`
        },
        {
          name: "P",
          dsc: "Profissional com especialização",
          src: `${this.baseUrl}/images/omint/busca/p.jpg`
        },
        {
          name: "Q",
          dsc: "Qualidade monitorada",
          src: `${this.baseUrl}/images/omint/busca/q.jpg`
        },
        {
          name: "E",
          dsc: "Título de Especialista",
          src: `${this.baseUrl}/images/omint/busca/e.jpg`
        }
      ];
      return qa;
    },
    name() {
      if (!this.match.DescricaoPrestador) return "";
      if (this.match.DescricaoPrestador.indexOf("-") === -1) {
        return this.match.DescricaoPrestador;
      }
      const s = this.match.DescricaoPrestador.split("-")[0];
      return s ? s.trim() : this.match.DescricaoPrestador;
    },
    redeComplementar() {
      if (this.docInfo.OrientacaoAtendimentoRede !== "S") return "";
      return this.docInfo.NomeRede.replace("MediService", "Mediservice");
    },
    unit() {
      if (!this.match.DescricaoPrestador) return "";
      if (this.match.DescricaoPrestador.indexOf("-") === -1) {
        return "";
      }
      const s = this.match.DescricaoPrestador.split("-")[1];
      return s ? s.trim() : "";
    },
    address1() {
      if (!this.match.TipoLogradouro) return "";
      return `${this.match.TipoLogradouro.trim()} ${this.match.EnderecoRua.trim()}, ${this.match.EnderecoNumero.trim()}`;
    },
    address2() {
      if (!this.match.TipoLogradouro) return "";
      return `${this.match.EnderecoBairro.trim()} - ${this.match.EnderecoCidade.trim()} - ${this.match.CodigoEstado.trim()}`;
    },
    idioms() {
      if (!this.docInfo.Curriculo || this.docInfo.Curriculo.length === 0) return [];
      let is = [];
      this.docInfo.Curriculo.forEach(c => {
        if (c.Tipo === "Idiomas") {
          is = c.Registros;
          return is;
        }
      });
      return is;
    },
    v24() {
      return Number.parseInt(localStorage.getItem("native_version")) >= 24;
    },
    locationTypeParse() {
      const locationLabel = {
        "Perto do meu trabalho": "do seu trabalho",
        "Perto da minha casa": "da sua casa",
        "Sua localização atual": "da sua localização atual",
        "Busca por região": "da sua busca por região"
      };

      return locationLabel[this.locationType] ?? "da sua localização atual";
    },
    redesSociais() {
      const base = [
        {
          Tipo: "instagram",
          Link: null
        },
        {
          Tipo: "facebook",
          Link: null
        },
        {
          Tipo: "linkedin",
          Link: null
        },
        {
          Tipo: "youtube",
          Link: null
        },
        {
          Tipo: "twitter",
          Link: null
        },
        {
          Tipo: "spotify",
          Link: null
        }
      ];

      const redesValidas = this.docInfo.RedeSocial?.filter(r => r.Link && r.Link.startsWith("https")) ?? [];
      if (!redesValidas.find(r => r.Tipo === "instagram")) {
        const instagram = this.docInfo.RedeSocial?.find(
          r => r.Tipo === "instagram" && r.Link && r.Link.startsWith("@")
        );
        if (instagram) {
          const link = instagram.Link.substring(1);
          if (link && !link.startsWith("@")) {
            instagram.Link = `https://www.instagram.com/${link}`;
            redesValidas.push(instagram);
          }
        }
      }

      const mergedArray = redesValidas.reduce(
        (result, contentItem) => {
          const matchingIndex = result.findIndex(baseItem => baseItem.Tipo === contentItem.Tipo);
          if (matchingIndex !== -1) {
            result[matchingIndex].Link = contentItem.Link;
          }
          return result;
        },
        [...base]
      );

      return mergedArray;
    }
  },
  methods: {
    ...mapActions("myAccount", ["fetchImage"]),
    ...mapActions("affiliate", [
      "fetchDoctorsInfo",
      "addAffiliateView",
      "testFavorite",
      "remFavorite",
      "addFavorite",
      "fetchSubstitutes",
      "checkDeactivateMatch"
    ]),
    ...mapMutations("affiliate", ["SET_REDE"]),
    loadPictures() {
      if (!this.docInfo?.Fotos?.length) return;
      this.docInfo.Fotos.forEach(p =>
        this.fetchImage({ documento: p, original: false }).then(source =>
          this.pictures.push({
            id: p,
            source: source,
            loadModalImage: () => this.loadPicture(p)
          })
        )
      );
    },
    async loadPicture(id) {
      return await this.fetchImage({ documento: id, original: true });
    },
    goTo(destiny) {
      window.open(destiny);
    },
    goToRedeSocial(redeSocial) {
      if (redeSocial?.Link && redeSocial.Link.startsWith("https")) {
        this.gotoSite(redeSocial.Link);
      }
    },
    async gotoSite(link) {
      if (!link) return;
      if (this.onNative) {
        const yesOrNo = await Swal.fire({
          title: "Aviso",
          text: "Você será redirecionado para um site externo. Deseja continuar?",
          confirmButtonText: "Continuar",
          cancelButtonText: "Cancelar",
          showCancelButton: true
        });
        if (!yesOrNo.value) return;
        link = `${link}${link.includes("?") ? "&" : "?"}type=.pdf`;
      }
      window.open(link, "_blank");
    },
    changeFavorite() {
      if (this.favorite) {
        this.remFavorite({
          prestador: this.match.CodigoPrestador,
          endereco: this.match.SrEndereco
        });
      } else {
        this.addFavorite({
          prestador: this.match.CodigoPrestador,
          endereco: this.match.SrEndereco
        });
      }
      this.favorite = !this.favorite;
    },
    async redirectWhats(fone) {
      fone = fone.replaceAll("(", "");
      fone = fone.replaceAll(")", "");
      fone = fone.replaceAll("-", "");
      fone = fone.replaceAll(" ", "");
      const link = `https://wa.me/55${fone}`;
      if (this.isMobile) {
        window.location = `${link}?text=Ol%C3%A1`;
      } else {
        window.open(link, "_blank");
      }
    },
    async showSubstitutes() {
      await this.$root.$emit("show-loading");
      await this.fetchSubstitutes();
      this.$router.push({ name: "affiliate.docs" });
      await this.$root.$emit("hide-loading");
    },
    async showPlansAttended() {
      this.$router.push({ name: "affiliate.plansAttended", params: { nomePrestador: this.name } });
    }
  }
};
</script>

<style>
.pictures-swiper .swiper-slide > div {
  padding: 0 !important;
  max-width: 100%;
}

.pictures-swiper .swiper-slide > div img {
  height: 120px;
}
</style>
