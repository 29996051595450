import Ticket from "@/pages/ticket/index.vue";
import TicketList from "@/pages/ticket/list.vue";
import TicketForm from "@/pages/ticket/form.vue";
import TicketDetail from "@/pages/ticket/detail.vue";
import Avaliation from "@/pages/avaliation/index.vue";
import TicketProtocol from "@/pages/ticket-protocol/index.vue";

const routes: Route[] = [
  {
    path: "/ticket-protocol/:id",
    name: "ticketProtocol",
    component: TicketProtocol,
    props: true,
    meta: {
      checkPathDisabled: true
    }
  },
  {
    path: "/ticket",
    alias: "/atendimento-lista",
    component: Ticket,
    params: true,
    children: [
      {
        path: "",
        name: "ticket",
        component: TicketList,
        meta: {
          registerAccess: { servico: "Atendimento", funcionalidade: "Acesso a listagem de atendimentos" },
          validPath: ["/ticket", "/atendimento-lista"]
        }
      },
      {
        path: "form/:idCC?",
        name: "ticket.form",
        component: TicketForm,
        props: true,
        meta: {
          backTo: "/atendimento-lista",
          validPath: ["/ticket", "/atendimento-lista"]
        }
      },
      {
        path: "detail/:idx",
        name: "ticket.detail",
        component: TicketDetail,
        props: true,
        meta: {
          backTo: "/atendimento-lista",
          validPath: ["/ticket", "/atendimento-lista"]
        }
      }
    ]
  },
  {
    path: "/experience-rating/:protocolo/:tipo/:etapa",
    alias: "/avaliacao/:protocolo/:tipo/:etapa",
    name: "avaliation",
    component: Avaliation,
    meta: {
      backTo: "/",
      checkPathDisabled: true
    }
  }
];

export default routes;
