<template>
  <omint-page>
    <Header name="Atendimento" titleLabel="Atendimento" backRoute="/" />
    <omint-slider>
      <router-view />
    </omint-slider>
    <Footer />
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";

export default {
  name: "Ticket",

  components: {
    Header,
    Footer
  }
};
</script>
