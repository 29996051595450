import VueRouter from "vue-router";

import afterEachRouteGuard from "./guards/afterEachRouteGuard";
import beforeEachRouteGuard from "./guards/beforeEachRouteGuard";
import allRoutes from "./routes";

const routes = [...allRoutes];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  await beforeEachRouteGuard(to, from, next);
});

router.afterEach((to, from) => {
  afterEachRouteGuard(to, from);
});
export default router;
