<template>
  <OmtStickedModal @close="close">
    <template #header>
      <OmtButton :display="['block', 'none']" class="clear" variant="ghost" @click="clear" height="18px" p="0">
        <OmtTypography as="p1" h="18px" w="46px" position="relative"> Limpar </OmtTypography>
      </OmtButton>
    </template>

    <CBox :minHeight="['initial', 'calc(100vh - 400px)']">
      <CFlex justifyContent="space-between" :mb="['32px', '36px']">
        <OmtTypography as="h1-bold"> Filtrar </OmtTypography>
        <OmtButton class="clear" variant="ghost" @click="clear" height="18px" p="0" :display="['none', 'block']">
          <OmtTypography as="p1" h="18px" w="46px" position="relative"> Limpar </OmtTypography>
        </OmtButton>
      </CFlex>
      <OmtTypography as="h2-bold" mb="24px" v-show="false"> Avaliação </OmtTypography>

      <CStack flex-direction="column-reverse !important" mb="24px" v-show="false">
        <CBox v-for="i in 2" :key="`avaliacao-${i}`">
          <CFlex justify="space-between" cursor="pointer">
            <OmtIcons name="star" mr="12px" />
            <OmtRadio
              @click="internalFilters.minimumRate = $event"
              :value="`${i + 2}`"
              :isChecked="internalFilters.minimumRate === `${i + 2}`"
              name="rate"
            >
              <OmtTypography as="p1"> Avaliação maior que {{ i + 2 }} estrelas </OmtTypography>
            </OmtRadio>
          </CFlex>
          <OmtDivider v-if="i !== 1" />
        </CBox>
      </CStack>

      <template v-if="internalFilters.facilities.length">
        <OmtTypography as="h2-bold" mb="24px"> Facilidades </OmtTypography>

        <CStack mb="24px">
          <CBox v-for="(sub, index) in internalFilters.facilities" :key="`facilities-${index}`">
            <CFlex cursor="pointer">
              <OmtIcons :name="sub.icon" mr="12px" />
              <OmtCheckbox @change="sub.isActive = !sub.isActive" :is-checked="sub.isActive" isFullWidth inverted>
                <OmtTypography as="p1"> {{ sub.label }} </OmtTypography>
              </OmtCheckbox>
            </CFlex>
            <OmtDivider v-if="index !== internalFilters.facilities.length - 1" />
          </CBox>
        </CStack>
      </template>

      <template v-if="internalFilters.subspecialties.length">
        <OmtTypography as="h2-bold" mb="24px"> Sub-especialidade </OmtTypography>

        <CStack mb="24px">
          <CBox v-for="(sub, index) in internalFilters.subspecialties" :key="`subespecialidade-${index}`">
            <CFlex cursor="pointer">
              <OmtIcons name="user-doctor" mr="12px" />
              <OmtCheckbox @change="sub.isActive = !sub.isActive" :is-checked="sub.isActive" isFullWidth inverted>
                <OmtTypography as="p1"> {{ "Especialista em " + sub.label }} </OmtTypography>
              </OmtCheckbox>
            </CFlex>
            <OmtDivider v-if="index !== internalFilters.subspecialties.length - 1" />
          </CBox>
        </CStack>
      </template>

      <template v-if="internalFilters.languages.length">
        <OmtTypography as="h2-bold" mb="24px"> Idiomas </OmtTypography>

        <CStack mb="24px">
          <CBox v-for="(lang, index) in internalFilters.languages" :key="`idioma-${index}`">
            <CFlex cursor="pointer">
              <OmtIcons name="language" mr="12px" />
              <OmtCheckbox @change="lang.isActive = !lang.isActive" :is-checked="lang.isActive" isFullWidth inverted>
                <OmtTypography as="p1"> {{ lang.label }} </OmtTypography>
              </OmtCheckbox>
            </CFlex>
            <OmtDivider v-if="index !== internalFilters.languages.length - 1" /> </CBox
        ></CStack>
      </template>

      <template v-if="internalFilters.networks.length">
        <OmtTypography as="h2-bold" mb="24px"> Rede </OmtTypography>
        <CStack mb="24px">
          <CBox v-for="(lang, index) in internalFilters.networks" :key="`rede-${index}`">
            <CFlex cursor="pointer">
              <OmtIcons name="list-tree" mr="12px" />
              <OmtCheckbox @change="lang.isActive = !lang.isActive" :is-checked="lang.isActive" isFullWidth inverted>
                <OmtTypography as="p1"> {{ lang.label }} </OmtTypography>
              </OmtCheckbox>
            </CFlex>
            <OmtDivider v-if="index !== internalFilters.networks.length - 1" />
          </CBox>
        </CStack>
      </template>
    </CBox>

    <template #footer>
      <OmtButton w="100%" mb="24px" @click="save"> Aplicar </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
const initState = () => ({
  minimumRate: "",
  facilities: [],
  languages: [],
  subspecialties: [],
  networks: []
});

const facilityInfo = {
  online: {
    icon: "calendar",
    label: "Agendamento online"
  },
  videoCall: {
    icon: "video",
    label: "Atende também por teleconsulta"
  },
  whatsapp: {
    icon: "whatsapp",
    label: "Agendamento por WhatsApp"
  }
};

export default {
  props: ["filters", "facilities", "languages", "networks", "subspecialties"],
  data() {
    return {
      internalFilters: initState()
    };
  },
  methods: {
    init() {
      const filters = initState();
      if (this.facilities?.length)
        filters.facilities = this.facilities.map(fac => {
          let isActive = false;

          if (this.filters.facilities) {
            const storedFacility = this.filters.facilities.find(_fac => _fac.name === fac.name);
            isActive = storedFacility.isActive;
          }

          return {
            name: fac.name,
            ...facilityInfo[fac.name],
            isActive
          };
        });
      if (this.languages?.length)
        filters.languages = this.languages.map(lang => {
          let isActive = false;

          if (this.filters.languages) {
            const storedLanguage = this.filters.languages.find(_lang => _lang.normalizedLabel === lang.normalizedLabel);
            isActive = storedLanguage.isActive;
          }

          return {
            ...lang,
            isActive
          };
        });
      if (this.networks?.length)
        filters.networks = this.networks.map(net => {
          let isActive = false;

          if (this.filters.networks) {
            const storedNetworks = this.filters.networks.find(_net => _net.normalizedLabel === net.normalizedLabel);
            isActive = storedNetworks.isActive;
          }

          return {
            ...net,
            isActive
          };
        });
      if (this.subspecialties?.length)
        filters.subspecialties = this.subspecialties.map(esp => {
          let isActive = false;

          if (this.filters.subspecialties) {
            const storedSubspecialties = this.filters.subspecialties?.find(
              _esp => _esp.normalizedLabel === esp.normalizedLabel
            );
            isActive = storedSubspecialties.isActive;
          }

          return {
            ...esp,
            isActive
          };
        });

      this.internalFilters = filters;
    },
    close() {
      this.$emit("close");
      this.clear();
    },
    clear() {
      const filters = initState();
      if (this.facilities?.length)
        filters.facilities = this.facilities.map(fac => {
          return {
            name: fac.name,
            ...facilityInfo[fac.name],
            isActive: false
          };
        });
      if (this.languages?.length)
        filters.languages = this.languages.map(lang => {
          return {
            ...lang,
            isActive: false
          };
        });
      if (this.networks?.length)
        filters.networks = this.networks.map(net => {
          return {
            ...net,
            isActive: false
          };
        });
      if (this.subspecialties?.length)
        filters.subspecialties = this.subspecialties.map(esp => {
          return {
            ...esp,
            isActive: false
          };
        });

      this.internalFilters = filters;
    },
    save() {
      this.$emit("save", this.internalFilters);
      this.close();
    }
  },
  watch: {
    facilities: {
      immediate: true,
      handler() {
        this.init();
      }
    },
    languages: {
      immediate: true,
      handler() {
        this.init();
      }
    },
    networks: {
      immediate: true,
      handler() {
        this.init();
      }
    },
    subspecialties: {
      immediate: true,
      handler() {
        this.init();
      }
    }
  }
};
</script>
