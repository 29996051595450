<template>
  <OmtGrid>
    <OmtRow>
      <CBox :display="['none', 'block']">
        <OmtTypography as="h4-bold" mb="12px" color="var(--colors-light-primary-gray1)"> Dados da Carta </OmtTypography>
        <OmtTypography as="p3" mb="12px" color="var(--colors-light-primary-gray1)">
          Ao selecionar as seguintes informações, vamos gerar um PDF das cartas.
        </OmtTypography>
      </CBox>
      <CBox :display="['block', 'none']">
        <OmtTypography as="h1-bold" mb="20px">
          {{ tituloEtapa }}
        </OmtTypography>
        <CFlex direction="row">
          <OmtIcons v-if="iconeEtapa" :name="iconeEtapa" mr="13px" />
          <OmtTypography as="h2-bold" mb="12px" mt="20px">
            {{ subTituloEtapa }}
          </OmtTypography>
        </CFlex>
      </CBox>
    </OmtRow>
    <OmtRow>
      <CBox :display="regraBlocoIdioma" mt="24px">
        <CFlex direction="row" justify="space-between" :mb="['24px', '0px']">
          <OmtTypography :display="['none', 'block']" as="h4-bold" font-size="18px">Idioma: </OmtTypography>
          <CFlex direction="row" align-self="center" :justify="['space-between', 'flex-end']" :w="['100%', '']">
            <CBox cursor="pointer" @click="selecionarTodos('idiomas')">
              <OmtTypography as="button-small" color="var(--colors-light-primary-navy-blue)" :ml="['0px', '30px']">
                Selecionar todos
              </OmtTypography>
            </CBox>
            <CBox cursor="pointer" @click="limpar('idiomas')">
              <OmtTypography
                as="button-small"
                :color="!limparIdiomas ? 'var(--colors-light-primary-gray2)' : 'var(--colors-light-primary-navy-blue)'"
                :disabled="!limparIdiomas"
                ml="30px"
              >
                Limpar
              </OmtTypography>
            </CBox>
          </CFlex>
        </CFlex>
        <CBox w="100%" :display="['none', 'block']">
          <omt-divider />
        </CBox>
        <CBox :display="['none', 'block']" v-for="linhaIdioma in idiomasDesktop" :key="linhaIdioma.id">
          <CFlex direction="row">
            <CBox v-for="idioma in linhaIdioma.idiomas" :key="idioma.id" w="33.33%">
              <OmtCheckbox @change="() => (idioma.selecionado = !idioma.selecionado)" :is-checked="idioma.selecionado">
                <CFlex direction="row" align-items="center">
                  <CImage height="28px" :src="idioma.img" ml="15px" />
                  <OmtTypography color="var(--colors-light-primary-gray1)" as="p3" pl="15px">{{
                    idioma.label
                  }}</OmtTypography>
                </CFlex>
              </OmtCheckbox>
            </CBox>
          </CFlex>
          <omt-divider v-if="linhaIdioma.exibirSeparador" />
        </CBox>
        <CFlex v-if="etapa === 0" :display="['block', 'none']" direction="column">
          <OmtCard
            v-for="idioma in listaIdiomas"
            :key="idioma.id"
            mb="16px"
            :border-color="
              idioma.selecionado ? 'var(--colors-light-primary-navy-blue)' : 'var(--colors-light-secondary-divider)'
            "
          >
            <OmtCheckbox @change="() => (idioma.selecionado = !idioma.selecionado)" :is-checked="idioma.selecionado">
              <CFlex direction="row" align-items="center">
                <CImage height="28px" :src="idioma.img" ml="15px" />
                <OmtTypography as="p3" pl="15px">{{ idioma.label }}</OmtTypography>
              </CFlex>
            </OmtCheckbox>
          </OmtCard>
        </CFlex>
      </CBox>
    </OmtRow>
    <OmtRow>
      <CBox :display="regraBlocoBeneficiario" :mt="['24px', '48px']">
        <CFlex direction="row" justify="space-between" :mb="['24px', '0px']">
          <OmtTypography :display="['none', 'block']" as="p4-semi-bold">Beneficiários: </OmtTypography>
          <CFlex direction="row" align-self="center" :justify="['space-between', 'flex-end']" :w="['100%', '']">
            <CBox cursor="pointer" @click="selecionarTodos('beneficiarios')">
              <OmtTypography as="button-small" color="var(--colors-light-primary-navy-blue)" :ml="['0px', '30px']">
                Selecionar todos
              </OmtTypography>
            </CBox>
            <CBox cursor="pointer" @click="limpar('beneficiarios')">
              <OmtTypography
                as="button-small"
                :color="
                  !limparBeneficiario ? 'var(--colors-light-primary-gray2)' : 'var(--colors-light-primary-navy-blue)'
                "
                ml="30px"
                :disabled="!limparBeneficiario"
              >
                Limpar
              </OmtTypography>
            </CBox>
          </CFlex>
        </CFlex>
        <CBox w="100%" :display="['none', 'block']">
          <omt-divider />
        </CBox>
        <OmtCard
          display="flex"
          flex-direction="column"
          mb="16px"
          v-for="beneficiario in listaBeneficiarios"
          :key="beneficiario.seq"
          :border-color="
            beneficiario.selecionado ? 'var(--colors-light-primary-navy-blue)' : 'var(--colors-light-secondary-divider)'
          "
        >
          <CFlex direction="row" justify="space-between" p="12px">
            <OmtCheckbox
              color="var(--colors-light-primary-gray1)"
              @change="() => (beneficiario.selecionado = !beneficiario.selecionado)"
              :is-checked="beneficiario.selecionado"
            >
              <CFlex direction="column" ml="24px">
                <OmtTypography :as="['h3-bold', 'p4-semi-bold']" color="var(--colors-light-primary-gray1)">
                  {{ capitalize(beneficiario.nome) }}
                </OmtTypography>
                <OmtTypography :display="['block', 'none']" as="p2" color="var(--colors-light-primary-gray2)">
                  {{ capitalize(beneficiario.parentesco) }}
                </OmtTypography>
              </CFlex>
            </OmtCheckbox>
            <OmtTypography :display="['none', 'block']" as="p4" color="var(--colors-light-primary-gray2)">
              {{ capitalize(beneficiario.parentesco) }}
            </OmtTypography>
          </CFlex>
        </OmtCard>
      </CBox>
    </OmtRow>
    <FixedButtonRow>
      <OmtButton
        :display="['block', 'none']"
        :w="['100%', '308px !important']"
        @click="proximaEtapa"
        :disabled="!podeContinuar"
      >
        Continuar
      </OmtButton>
      <OmtButton
        :display="['none', 'block']"
        :w="['100%', '308px !important']"
        @click="submit"
        :disabled="!podeGerarCarta"
      >
        Continuar
      </OmtButton>
    </FixedButtonRow>
  </OmtGrid>
</template>

<script>
import SafeLetterListFiltersHeader from "@/components/Organisms/SafeLetter/SafeLetterListFiltersHeader.vue";
import SafeLetterListSortButton from "@/components/Organisms/SafeLetter/SafeLetterListSortButton.vue";
import SafeLetterListCard from "@/components/Organisms/SafeLetter/SafeLetterListCard.vue";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";
import { mapActions, mapState } from "vuex";
import { capitalize } from "@/utils/utils";
export default {
  components: {
    SafeLetterListFiltersHeader,
    SafeLetterListSortButton,
    SafeLetterListCard,
    FixedButtonRow
  },
  data() {
    return {
      listaIdiomas: [],
      listaBeneficiarios: [],
      etapa: 0,
      regraBlocoIdioma: "none",
      regraBlocoBeneficiario: "none",
      tituloEtapa: "",
      iconeEtapa: "",
      subTituloEtapa: ""
    };
  },
  computed: {
    ...mapState("safeLetter", ["beneficiarios", "idiomas"]),
    idiomaSelecionado() {
      return this.listaIdiomas.filter(l => l.selecionado).length > 0;
    },
    beneficiarioSelecionado() {
      return this.listaBeneficiarios.filter(l => l.selecionado).length > 0;
    },
    podeGerarCarta() {
      return this.idiomaSelecionado && this.beneficiarioSelecionado;
    },
    podeContinuar() {
      return this.etapa === 0 ? this.idiomaSelecionado : this.podeGerarCarta;
    },
    idiomasDesktop() {
      if (!this.listaIdiomas?.length) return [];
      const totalColunas = 3;
      const colunas = {};
      this.listaIdiomas.forEach((idioma, index) => {
        const coluna = index % totalColunas;
        if (colunas[coluna]) {
          colunas[coluna].push(idioma);
        } else {
          colunas[coluna] = [idioma];
        }
      });
      const linhas = [];
      const totalLinhas = colunas[0].length;
      for (let l = 0; l < totalLinhas; l++) {
        const linha = [];
        for (let c = 0; c < totalColunas; c++) if (colunas[c].length > l) linha.push(colunas[c][l]);
        linhas.push({ idiomas: linha, exibirSeparador: l + 1 < totalLinhas });
      }
      return linhas;
    },
    limparIdiomas() {
      return this.listaIdiomas.some(idioma => idioma.selecionado);
    },
    limparBeneficiario() {
      return this.listaBeneficiarios.some(beneficiario => beneficiario.selecionado);
    }
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      await this.fetchBeneficiarios();
      this.listaIdiomas = this.idiomas.map(l => ({ ...l, selecionado: false }));
      this.listaBeneficiarios = this.beneficiarios.map(l => ({ ...l, selecionado: false }));
      if (this.$route.params?.etapa !== "0") {
        this.$router.replace("/carta-seguro-viagem/create/0");
        return;
      }
      this.initPage();
    } finally {
      this.$root.$emit("hide-loading");
    }
  },
  watch: {
    $route(route) {
      this.etapa = Number(route.params?.etapa ?? "0");
      this.initPage();
    }
  },
  methods: {
    ...mapActions("safeLetter", ["fetchBeneficiarios", "postEnviarCarta"]),
    initPage() {
      if (this.etapa === 0) {
        this.tituloEtapa = "Seleção de idiomas";
        this.iconeEtapa = "earth-americas";
        this.regraBlocoIdioma = "block";
        this.regraBlocoBeneficiario = ["none", "block"];
        this.subTituloEtapa = "Selecione os idiomas da carta:";
        this.$root.$emit("change-back-route", "/carta-seguro-viagem");
      } else {
        this.tituloEtapa = "Seleção de beneficiários";
        this.iconeEtapa = "user-plus";
        this.regraBlocoIdioma = ["none", "block"];
        this.regraBlocoBeneficiario = "block";
        this.subTituloEtapa = "Selecione o(s) beneficiário(s) para nomeação da carta:";
        this.$root.$emit("change-back-route", "/carta-seguro-viagem/create/0");
      }
      this.$root.$emit("hide-loading");
    },
    selecionarIdioma(id) {
      if (this.listaIdiomas.includes(id)) {
        this.listaIdiomas = this.listaIdiomas.filter(idiomaId => idiomaId !== id);
      } else {
        this.listaIdiomas.push(id);
      }
    },
    selecionarTodos(tipo) {
      (tipo === "idiomas" ? this.listaIdiomas : this.listaBeneficiarios).forEach(l => (l.selecionado = true));
    },
    limpar(tipo) {
      (tipo === "idiomas" ? this.listaIdiomas : this.listaBeneficiarios).forEach(l => (l.selecionado = false));
    },
    proximaEtapa() {
      if (!this.podeContinuar) return;
      if (this.etapa === 0) {
        this.etapa = 1;
        this.$router.push("/carta-seguro-viagem/create/1");
      } else {
        this.submit();
      }
    },
    async submit() {
      if (!this.podeGerarCarta) return;
      try {
        this.$root.$emit("show-loading");
        const idiomas = this.listaIdiomas.filter(l => l.selecionado).map(l => l.id);
        const beneficiarios = this.listaBeneficiarios.filter(l => l.selecionado).map(l => l.seq);
        const resp = await this.postEnviarCarta({
          idiomas,
          beneficiarios
        });
        if (resp.sucesso) {
          this.$router.push({
            name: "safe-letter.success",
            params: {
              protocolo: resp.protocolo
            }
          });
        } else {
          this.$toast({
            description: resp.erro,
            status: "error",
            duration: 10000,
            position: "top"
          });
        }
      } catch (error) {
        this.$toast({
          description: error?.message ?? "Serviço temporariamente indisponível.",
          status: "error",
          duration: 10000,
          position: "top"
        });
      } finally {
        this.$root.$emit("hide-loading");
      }
    },
    capitalize(text) {
      return capitalize(text);
    }
  }
};
</script>
