const baseURL = `${process.env.VUE_APP_SERVER_URL}/Agenda`;

export async function ListarTarefas({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ListarTarefas`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ListarTarefas", error.message);
      return error;
    });
}

export async function DetalheTarefa({ vinculo, chamado }) {
  return window.$axios
    .post({
      url: `${baseURL}/DetalheTarefa`,
      query: {
        vinculo
      },
      data: {
        chamado
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("DetalheTarefa", error.message);
      return error;
    });
}

export async function ObterRegrasAgenda({ vinculo, chamado, prestador, tipo }) {
  return window.$axios
    .post({
      url: `${baseURL}/ObterRegrasAgenda`,
      query: {
        vinculo
      },
      data: {
        chamado,
        prestador,
        tipo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterRegrasAgenda", error.message);
      return error;
    });
}

export async function ObterAgenda({ vinculo, beneficiario, local, especialidade, staff }) {
  return window.$axios
    .post({
      url: `${baseURL}/ObterAgenda`,
      query: {
        vinculo
      },
      data: {
        beneficiario,
        local,
        especialidade,
        staff
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterAgenda", error.message);
      return error;
    });
}

export async function NovaTarefa({
  vinculo,
  beneficiario,
  local,
  especialidade,
  staff,
  dia,
  mes,
  ano,
  hora,
  duracao,
  dispositivo,
  chamado,
  reserva,
  slot,
  tipo
}) {
  return window.$axios
    .post({
      url: `${baseURL}/NovaTarefa`,
      query: {
        vinculo
      },
      data: {
        beneficiario,
        local,
        especialidade,
        staff,
        dia,
        mes,
        ano,
        hora,
        duracao,
        dispositivo,
        chamado,
        reserva,
        slot,
        tipo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("NovaTarefa", error.message);
      return error;
    });
}
