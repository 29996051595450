<template>
  <CBox class="sticked-modal-portal">
    <Modal
      :baseZindex="2000"
      :basedOn="true"
      :modalClass="`sticked-modal ${uuid} ${display ? 'show-sticked-modal' : 'hide-sticked-modal'}`"
      @close="$emit('close')"
      @beforeOpen="beforeOpen"
      @beforeClose="beforeClose"
    >
      <CBox class="sticked-modal-wrapper">
        <CBox class="ios-margin" />
        <CFlex
          data-cy="sticked-modal-header"
          class="sticked-modal-header"
          justifyContent="space-between"
          :p="['16px', '42px 42px 0px 42px']"
          v-bind="headerBind"
        >
          <OmtButton data-cy="close-button" variant="ghost" height="18px" @click="$emit('close')" p="0" min-width="0">
            <OmtIcons name="xmark" size="lg" />
          </OmtButton>
          <slot name="header"></slot>
        </CFlex>

        <CBox
          data-cy="sticked-modal-body"
          :flex="['1', 'initial']"
          :p="['16px 16px 24px 16px', '24px 42px 24px 42px']"
          class="sticked-modal-body"
          v-bind="bodyBind"
        >
          <slot></slot>
        </CBox>

        <CBox v-if="$slots.footer" data-cy="sticked-modal-footer" class="sticked-modal-footer" v-bind="footerBind">
          <CFlex justify="center" :p="['16px', '42px 42px 0px 42px']" width="100%">
            <slot name="footer"></slot>
          </CFlex>
        </CBox>
      </CBox>
    </Modal>
  </CBox>
</template>

<script>
/**
 * extends https://github.com/kouts/vue-modal
 */
import VueModal from "@kouts/vue-modal";
import bodyScroll from "body-scroll-freezer";
import "@kouts/vue-modal/dist/vue-modal.css";
import _ from "lodash";

export default {
  components: {
    Modal: VueModal
  },
  props: {
    headerBind: Object,
    bodyBind: Object,
    footerBind: Object
  },
  data() {
    return {
      uuid: _.uniqueId("sticked-modal_"),
      display: true
    };
  },
  methods: {
    beforeOpen() {
      bodyScroll.freeze();
    },
    beforeClose() {
      bodyScroll.unfreeze();
    },
    updateModalPosition() {
      setTimeout(() => {
        const modalElement = document.querySelector(`.${this.uuid}`);
        const modalHeight = modalElement?.clientHeight;

        if (modalElement) {
          const visualViewportHeight = window.visualViewport?.height || window.innerHeight;
          const headerHeight = 80;
          const viewPortOffset = 10;
          const windowWidth = window.visualViewport?.width || window.innerWidth;

          if (windowWidth > 1024) {
            modalElement.style.marginTop = "0"; // Reset margin-top to 0
          } else {
            if (visualViewportHeight > modalHeight) {
              const marginTop = visualViewportHeight - modalHeight;
              modalElement.style.marginTop = `${
                marginTop > viewPortOffset ? marginTop : viewPortOffset + headerHeight
              }px`;
            } else {
              modalElement.style.marginTop = `${viewPortOffset + headerHeight}px`;
            }
          }
        }
      }, 0);
    }
  },
  mounted() {
    this.updateModalPosition();
    window.addEventListener("resize", this.updateModalPosition);

    this.$root.$on("show-loading", () => {
      this.display = false;
    });

    this.$root.$on("hide-loading", () => {
      this.display = true;
    });

    const equalsMenu = document.getElementById("INDmenu-btn");
    if (equalsMenu && equalsMenu.style["cssText"]) {
      equalsMenu.style["cssText"] = `${equalsMenu.style["cssText"]} display: none;`;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateModalPosition);

    const equalsMenu = document.getElementById("INDmenu-btn");
    if (equalsMenu && equalsMenu.style["cssText"]) {
      equalsMenu.style["cssText"] = equalsMenu.style["cssText"].replaceAll("display: none;", "");
    }
  }
};
</script>

<style lang="scss">
@keyframes slide-top {
  from {
    top: 100%;
  }

  to {
    top: 0;
  }
}

@keyframes slide-left {
  from {
    left: 100%;
  }

  to {
    left: 0;
  }
}

.sticked-modal-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;

  @media screen and (min-width: 1024px) {
    justify-content: flex-start;
  }
}

.sticked-modal-portal {
  height: 0;
  width: 0;
}

.sticked-modal {
  border-radius: 16px 16px 0 0;
  top: 0px;
  width: 100%;
  animation: 1s slide-top ease forwards;

  @media screen and (min-width: 1024px) {
    border-radius: 0;
    overflow-y: scroll;
    margin: 0px 0px 0px auto;
    height: 100%;
    max-width: 640px;
    min-height: 100%;
    animation: 1s slide-left ease forwards;
  }

  // Overrides
  .vm-titlebar {
    display: none;
  }

  .vm-content {
    padding: 0 !important;
  }
}

.hide-sticked-modal {
  opacity: 0;
}

.show-sticked-modal {
  opacity: 1;
}
</style>
