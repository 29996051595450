import { AuditFormType } from ".";

export enum FormsOptions {
  AuditFormPerformer,
  AuditFormSharedCredentials,
  AuditFormPaymentType,
  AuditFormRealizedAllProcedures
}

export const AuditFormPerformerGetter = (id = "I") => ({
  id,
  component: FormsOptions[FormsOptions.AuditFormPerformer]
});

export const AuditFormSharedCredentialsGetter = (id = "S") => ({
  id,
  component: FormsOptions[FormsOptions.AuditFormSharedCredentials]
});

export const AuditFormPaymentTypeGetter = (id = "P") => ({
  id,
  component: FormsOptions[FormsOptions.AuditFormPaymentType]
});

export const AuditFormRealizedAllProceduresGetter = (id = "R") => ({
  id,
  component: FormsOptions[FormsOptions.AuditFormRealizedAllProcedures]
});

const scenarios: AuditFormType[][] = [
  // Cenário 1
  [
    {
      ...AuditFormPerformerGetter(),
      value: 22
    },
    {
      ...AuditFormSharedCredentialsGetter("C"),
      value: [20, 21]
    }
  ],

  // Cenário 2
  [
    {
      ...AuditFormPerformerGetter(),
      value: [7, 8, 18]
    },
    {
      ...AuditFormSharedCredentialsGetter(),
      value: 9
    },
    {
      ...AuditFormPaymentTypeGetter(),
      value: [11, 12, 13, 14]
    },
    {
      ...AuditFormRealizedAllProceduresGetter(),
      value: [15, 16, 17]
    }
  ],

  // Cenário 3
  [
    {
      ...AuditFormPerformerGetter(),
      value: [7, 8, 18]
    },
    {
      ...AuditFormSharedCredentialsGetter(),
      value: 10
    },
    {
      ...AuditFormPaymentTypeGetter(),
      value: [11, 12, 13, 14]
    },
    {
      ...AuditFormRealizedAllProceduresGetter(),
      value: [15, 16, 17]
    }
  ],

  // Cenário 4
  [
    {
      ...AuditFormPerformerGetter(),
      value: [7, 8, 18]
    },
    {
      ...AuditFormSharedCredentialsGetter(),
      value: 10
    },
    {
      ...AuditFormPaymentTypeGetter(),
      value: 19
    }
  ],

  // Cenário 5
  [
    {
      ...AuditFormPerformerGetter(),
      value: [7, 8, 18]
    },
    {
      ...AuditFormSharedCredentialsGetter(),
      value: 9
    },
    {
      ...AuditFormPaymentTypeGetter(),
      value: 19
    }
  ]
];

export default scenarios;
