import store from "@/store";

function allowUpdate() {
  return !store.state.saude.isReadonlyAccess;
}

function checkUpdatePermission() {
  if (store.state.saude.isReadonlyAccess) throw new Error("Acesso sem permissão para gravação");
}

export { checkUpdatePermission, allowUpdate };
