<template>
  <omint-page-content v-if="hasVoucher">
    <omt-p>
      ID Autorização: <omt-bold>{{ authId }}</omt-bold>
    </omt-p>
    <omt-p>
      Data da Solicitação <omt-bold>{{ soliticationDate.formatDDMMYYYY() }}</omt-bold>
    </omt-p>
    <omt-p>
      Data Agendamento: <omt-bold>{{ schdeuleDate.formatDDMMYYYY() }}</omt-bold>
    </omt-p>
    <omt-p>
      Data Validade: <omt-bold>{{ valid.formatDDMMYYYY() }}</omt-bold>
    </omt-p>
    <omt-p>
      Voucher: <omt-bold>{{ voucherId }}</omt-bold>
    </omt-p>
    <omt-p>
      Prestador: <omt-bold>{{ serviceProviderDesc }}</omt-bold>
    </omt-p>
    <omt-p>
      Status: <omt-bold>{{ status }}</omt-bold>
    </omt-p>
    <br />
    <omt-buttons-bar>
      <omt-button :onClick="onCancel">Cancelar Autorização</omt-button>
    </omt-buttons-bar>
    <br />
  </omint-page-content>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "VoucherInfo",
  data() {
    return {
      hasVoucher: null
    };
  },
  async mounted() {
    await this.$root.$emit("show-loading");
    this.$root.$emit("change-title-label", "Checkup Digital");
    this.hasVoucher = await this.fetchScheduled();
    if (this.hasVoucher) {
      await this.fetchServiceProviders();
      await this.checkSchduled();
      await this.$root.$emit("hide-loading");
    } else {
      await this.$router.replace({ name: "voucher.from" });
    }
  },
  methods: {
    ...mapActions("voucher", ["fetchScheduled", "fetchServiceProviders", "checkSchduled", "cancelVoucher"]),
    onCancel: async function () {
      const resp = await Swal.fire({
        title: "Confirmação",
        text: "Confirma o cancelamento da autorização?",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        showCancelButton: true,
        showConfirmButton: true
      });

      if (resp.value) {
        try {
          await this.$root.$emit("show-loading");
          await this.cancelVoucher();
        } finally {
          await this.$root.$emit("hide-loading");
        }
        await Swal.fire({
          text: "Autorização Cancelada com Sucesso",
          icon: "success"
        });
        await this.$router.replace({ name: "voucher.from" });
      }
    }
  },
  computed: {
    ...mapState("voucher", [
      "authId",
      "soliticationDate",
      "schdeuleDate",
      "valid",
      "voucherId",
      "serviceProviderDesc",
      "status"
    ])
  }
};
</script>
