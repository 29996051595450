<template>
  <omint-page-content>
    <div class="term-box">
      <OmtTypography as="h1-bold" mb="16px">Termo de aceite/responsabilidade de utilização</OmtTypography>
      <OmtTypography as="h2-semi-bold" mb="24px">Canais de Atendimento Minha Omint</OmtTypography>
      <div v-html="term"></div>
    </div>
    <omt-divider />

    <OmtButton
      variant="solid"
      width="100%"
      height="50px"
      border="0.5px solid var(--colors-light-secondary-divider)"
      borderRadius="40px"
      data-cy="button-entrar"
      @click="handleClick()"
      mb="3"
    >
      <OmtTypography as="h1-bold" color="white">Concordar e continuar</OmtTypography>
    </OmtButton>
    <OmtTypography as="p2"
      >Ao continuar, você concorda com o <strong>Termo de aceite/responsabilidade de utilização</strong> descrito acima.
    </OmtTypography>
  </omint-page-content>
</template>

<script>
import { getUA } from "@/utils/deviceName";
import { mapState, mapActions } from "vuex";
import SwitchCheckBox from "@/components/Molecules/SwitchCheckBox";

export default {
  name: "Terms",
  components: { SwitchCheckBox },
  async mounted() {
    await this.$root.$emit("show-loading");
    this.$root.$emit("change-title-label", "Termo de Aceite");
    await this.fetchTerms({ device: getUA() });
    await this.$root.$emit("hide-loading");
  },
  data: function () {
    return {
      checked: true
    };
  },
  computed: {
    ...mapState("firstAccess", ["term"])
  },
  methods: {
    ...mapActions("firstAccess", ["fetchTerms", "accpetTerms"]),
    handleClick: async function () {
      await this.$root.$emit("show-loading");
      if (this.checked) {
        await this.accpetTerms();
        await this.$router.push({ name: "first-access.bond" });
      }
      await this.$root.$emit("hide-loading");
    }
  }
};
</script>
<style scoped>
.term-box {
  height: calc(100vh - 250px);
  overflow-y: auto;
}

::v-deep p ~ p {
  margin-bottom: 1rem;
}
</style>
