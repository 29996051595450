<template>
  <OmtLayoutContainer>
    <OmtRow z-index="1">
      <CBox v-if="showFilters" w="100%" d="flex">
        <OmtButton
          mr="16px"
          size="sm"
          :variant="hasActivedFilter ? 'solid' : 'outline'"
          leftIcon="sliders-h"
          :iconColor="hasActivedFilter ? 'white' : 'var(--colors-light-primary-gray1)'"
          iconSize="sm"
          border=".5px solid var(--colors-light-secondary-divider)"
          @click="showFiltersOptions = !showFiltersOptions"
        >
          <OmtTypography as="button-small" :color="hasActivedFilter ? 'white' : 'var(--colors-light-primary-gray1)'">
            Filtrar
          </OmtTypography>

          <CBox
            ml="16px"
            v-show="filtersCount"
            bg="var(--colors-light-secondary-light-blue)"
            border-radius="12px"
            min-h="16px"
            min-w="16px"
            h="16px"
            w="16px"
            display="flex"
            justify-content="center"
            align-items="center"
          >
            <OmtTypography as="p2" color="white">
              {{ filtersCount }}
            </OmtTypography>
          </CBox>
        </OmtButton>
        <CBox v-if="filters" v-show="false" w="174px !important">
          <OmtSelect
            variant="outline"
            placeholder="Selecione um filtro"
            @change="e => setListOption(e)"
            mb="24px"
            :initialValue="listOption"
          >
            <option v-for="(filter, index) in filters.dates" :key="index" :value="filter.value">
              {{ filter.label }}
            </option>
          </OmtSelect>
        </CBox>

        <OmtButton variant="ghost" :disabled="!hasActivedFilter" ml="auto" size="sm" @click="cleanAllFilters">
          Limpar
        </OmtButton>
      </CBox>
    </OmtRow>

    <AuthCreateModal :isOpen.sync="modalIsOpen" :recentAuthInfo="existePedidoRecente" @continue="createNewAuth" />

    <OmtRow v-for="(auth, idx) in filteredAuths" mt="24px" :key="`auth-${idx}`">
      <AuthCard
        v-bind="{
          protocol: auth.protocoloANS,
          createdAt: auth.dtSolicitacao,
          name: auth.dsBeneficiario,
          tpStatus: auth.tpSituacao,
          dsStatus: auth.dsSituacao,
          canEvaluate: auth.notaAvaliacao === null
        }"
        @click="handleClick(auth)"
        @evaluate="evaluateRequest(auth)"
      />
    </OmtRow>

    <AuthFilterModal :isOpen.sync="showFiltersOptions" />

    <FixedButtonRow>
      <OmtButton :w="['100%', '308px !important']" @click="showModalNewAuth"> Solicitar nova autorização </OmtButton>
    </FixedButtonRow>

    <AvaliationModal
      v-if="showAvaliationModal"
      @close="showAvaliationModal = false"
      @ratingSubmitClick="onRatingSubmitClick($event)"
      :protocolNumber="avaliation.protocol"
      :protocolDate="avaliation.date"
      protocolTypeName="Autorização"
      protocolType="4"
      stage="P"
    />
    <CBox h="30px" />
  </OmtLayoutContainer>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import AvaliationModal from "@/components/Organisms/AvaliationModal.vue";
import AuthCard from "@/components/Organisms/Auths/AuthCard.vue";
import AuthFilterModal from "@/components/Organisms/Auths/AuthFilterModal.vue";
import AuthCreateModal from "@/components/Organisms/Auths/AuthCreateModal.vue";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

export default {
  name: "AuthsList",
  components: {
    AvaliationModal,
    AuthCard,
    AuthFilterModal,
    AuthCreateModal,
    FixedButtonRow
  },
  async mounted() {
    await this.$root.$emit("show-loading");
    try {
      await this.listAuths();
    } catch {
      window.$toast.error("Serviço temporariamente indisponível");
    } finally {
      await this.$root.$emit("hide-loading");

      if (this.newAuthSuccess) this.displaySuccessToast();
    }
  },
  data() {
    return {
      avaliation: { date: "", protocol: "" },
      showAvaliationModal: false,
      showFiltersOptions: false,
      modalIsOpen: false,
      existePedidoRecente: false
    };
  },
  methods: {
    ...mapActions("auths", ["hasRecentlyeNewAuth", "listAuths"]),
    ...mapMutations("auths", ["SET_LIST_OPTION", "SET_NEW_AUTH", "RESET_FILTERS", "SET_SUCCESS_NEW_AUTH"]),
    evaluateRequest(request) {
      if (!request?.protocoloANS || !request?.dtSolicitacao) return;
      this.avaliation = {
        protocol: request.protocoloANS,
        date: request.dtSolicitacao
      };
      this.showAvaliationModal = true;
    },
    async onRatingSubmitClick(rating) {
      if (!rating?.newRatingValue || !this.avaliation?.protocol) return;
      try {
        this.$root.$emit("show-loading");
        await this.listAuths();
      } catch (error) {
        console.error(error);
      } finally {
        this.avaliation = {
          protocol: "",
          date: ""
        };
        this.showAvaliationModal = false;
        this.$root.$emit("hide-loading");
      }
    },
    setListOption(value) {
      if (value !== this.listOption) {
        this.SET_LIST_OPTION(value);
      }
    },
    handleClick(auth) {
      this.$router.push({
        path:
          auth.idAutorizacao === 0 ? `/auths/detail/ticket/${auth.idChamado}` : `/auths/detail/${auth.idAutorizacao}`
      });
    },
    cleanAllFilters() {
      this.RESET_FILTERS();
    },
    async showModalNewAuth() {
      try {
        this.existePedidoRecente = await this.hasRecentlyeNewAuth({ type: "" });
      } finally {
        this.modalIsOpen = true;
      }
    },
    createNewAuth() {
      this.SET_NEW_AUTH({});
      this.$router.push({
        path: "/auths/create"
      });
    },
    displaySuccessToast() {
      this.$toast({
        description: "Seu pedido foi enviado com sucesso. Isto aparecerá na lista em instantes.",
        status: "success",
        duration: 10000
      });
      this.SET_SUCCESS_NEW_AUTH(false);
    }
  },
  computed: {
    ...mapState("auths", ["auths", "listOption", "filters", "newAuthSuccess"]),
    showFilters() {
      if (!this.auths?.length) return false;
      return this.auths.length > 0;
    },
    filteredAuths() {
      const tpPeriodo = this.listOption ? Number.parseInt(this.listOption) : 3;
      let filtered = this.auths.filter(auth => auth.tpPeriodo <= tpPeriodo);
      if (this.filters) {
        const beneficiario = this.filters.beneficiaries?.value;
        if (beneficiario) {
          filtered = filtered.filter(auth => auth.dsBeneficiario === beneficiario);
        }
        const activedStatus = this.filters.status?.data?.filter(status => status.isActive);
        if (activedStatus?.length) {
          filtered = filtered.filter(auth => activedStatus.map(status => status.label).includes(auth.dsSituacao));
        }
      }
      return filtered;
    },
    hasActivedFilter() {
      const hasActivedStatus = this.filters?.status?.data?.filter(f => f.isActive) || [];
      return !!hasActivedStatus.length || !!this.filters?.beneficiaries?.value;
    },
    filtersCount() {
      const activedStatus = this.filters?.status?.data?.filter(f => f.isActive) || [];
      const count = activedStatus.length + (this.filters?.beneficiaries?.value ? 1 : 0);
      return count;
    }
  }
};
</script>
<style scoped></style>
