










import ResouceModal from "@/components/Organisms/DrOmint/ResourceModal.vue";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import Header from "@/components/Molecules/Header/index.vue";
import { isMobile } from "@/utils/utils";
import { isAssociado } from "@/utils/perfilRouteUtil";

export default {
  name: "omint-video-call",
  components: { Header, ResouceModal },
  async mounted() {
    try {
      if (!this.chamado) {
        this.$router.replace("/");
      }
      this.task = await this.featchTaskDetail({ chamado: this.chamado });
      if (!this.task) {
        this.$router.replace("/");
      }

      // caso a url de teams seja usada com uma task do opentok
      if (!this.sala && this.task.Situacao !== 1) {
        this.$router.push({
          name: "tasks.precall",
          params: {
            chamado: this.chamado
          }
        });
      }

      if (!this.sala) {
        this.$router.replace("/");
      }
      this.initResources();
      // se o usuário estiver nessa página é porque a videoconferência foi iniciada
      this.registerAccess({
        servico: "Videoconferência",
        funcionalidade: "Chamada iniciada",
        id: this.chamado
      });
    } catch (error) {
      window.$log.error("Erro ao iniciar video chamada", error);
    }

    window.addEventListener("message", this.onCallEnded);
    this.$root.$emit("hide-loading");
  },
  beforeDestroy() {
    window.removeEventListener("message", this.onCallEnded);
  },
  data() {
    return {
      initialized: false,
      checkingResources: true,
      cameraLiberada: false,
      microfoneLiberado: false,
      encerrando: false,
      task: null
    };
  },
  computed: {
    ...mapState("biometrics", ["camera", "microfone"]),
    ...mapState("saude", ["perfil"]),
    ...mapGetters("biometrics", ["onNative"]),
    sala() {
      const device = this.onNative || isMobile() ? "mobile" : "desktop";
      if (!this.task) return;

      let url = new URL(this.task.UrlSala);

      if (url.searchParams.has("device")) {
        url.searchParams.set("device", device);
      } else {
        url.searchParams.append("device", device);
      }

      return url.toString();
    },
    chamado() {
      return this.$route.params.chamado ? Number(this.$route.params.chamado) : 0;
    },
    shouldRequestResources() {
      if (!this.v25 || !this.onNative)
        return !this.checkingResources && (!this.cameraLiberada || !this.microfoneLiberado);
      return this.v25 && this.onNative && (this.camera !== "granted" || this.microfone !== "granted");
    }
  },
  methods: {
    ...mapActions("myAccount", ["registerAccess"]),
    ...mapActions("biometrics", ["checkPermissionForResource"]),
    ...mapActions("tasks", ["fetchANSProtocol", "featchTaskDetail"]),
    ...mapMutations("ticket", ["SET_RATING_INFOS"]),
    async initResources() {
      if (this.initialized) return;
      if (this.onNative) {
        this.checkPermissionForResource("camera");
        this.checkPermissionForResource("microfone");
        this.checkingResources = false;
      } else {
        await this.checkResources();
      }
      this.initialized = true;
    },
    async onCallEnded() {
      if (this.encerrando) return;
      try {
        this.encerrando = true;
        this.$root.$emit("show-loading");
        this.registerAccess({ servico: "Videoconferência", funcionalidade: "Chamada encerrada", id: this.chamado });
        const rotaInicial = this.defaultRoute;
        if (isAssociado(this.perfil)) {
          const protocol = await this.fetchANSProtocol({
            chamado: this.chamado
          });
          if (protocol) {
            this.SET_RATING_INFOS({ ratingRoute: rotaInicial, ratingType: 8 });
            await this.$root.$emit("hide-loading");
            await this.$router.replace({
              path: `/experience-rating/${protocol}/8/P`
            });
            return;
          }
        }
        this.$router.push(rotaInicial);
      } catch (error) {
        window.$log.error("Não foi possível registrar o encerramento da sala", error);
      } finally {
        this.$root.$emit("hide-loading");
      }
    },
    async checkResources() {
      if (navigator && "mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices) {
        try {
          const audioStream = await navigator.mediaDevices.getUserMedia({
            audio: true
          });
          const tracks = audioStream.getTracks();
          for (let i = 0; i < tracks.length; i++) {
            await tracks[i].stop();
          }
          this.microfoneLiberado = true;
        } catch (error) {
          this.microfoneLiberado = false;
          window.$log.error("Error on checkResources audio", error);
        }

        try {
          const videoStream = await navigator.mediaDevices.getUserMedia({
            video: true
          });
          const tracks = videoStream.getTracks();
          for (let i = 0; i < tracks.length; i++) {
            await tracks[i].stop();
          }
          this.cameraLiberada = true;
        } catch (error) {
          this.cameraLiberada = false;
          window.$log.error("Error on checkResources video", error);
        }
      } else {
        this.cameraLiberada = false;
        this.microfoneLiberado = false;
      }
      this.checkingResources = false;
    }
  }
};
