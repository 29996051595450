<template>
  <div v-if="exibirMenu" id="menu">
    <OmtIcons name="bars" size="lg" @click.prevent="showHideMenu" />
    <div :class="theme">
      <div v-show="!menuHidden" class="menu-hider" @click.prevent="showHideMenu"></div>
      <div :class="menuClass">
        <nav class="menu-box">
          <router-link to="/" v-chakra color="inherit !important">
            <div class="app-name">Omint</div>
          </router-link>
          <omt-divider bottom="25" />

          <template v-if="isLogged">
            <div v-if="loadingMenu" class="loading">
              <dot-loader :loading="true" color="rgb(16, 42, 69)" size="30px"></dot-loader>
            </div>
            <template v-else>
              <DrawerLink v-if="exibirVinculos" key="vinculos" icon="user" label="Vinculos" link="/vinculos" />
              <DrawerLink
                v-for="(m, index) in filteredMenu"
                :key="`${index}-${m.Label}`"
                :icon="m.Icon"
                :label="m.Label"
                :link="m.View"
              />
              <omt-divider v-if="exibirVinculos || filteredMenu.length" />

              <DrawerLink icon="key" label="Alterar Senha" link="/change-password" />

              <div v-if="(v25 && onNative) || hasBiometric">
                <omt-divider />
                <DrawerLink icon="gear" label="Configuração" link="/settings" />
              </div>
            </template>
            <omt-divider />
            <div @click.prevent="onNative ? blockApp() : logout()">
              <DrawerLink key="sair" icon="fa fa-window-close" label="Sair" link="" />
            </div>
          </template>
          <template v-else>
            <DrawerLink key="login" icon="fas fa-sign-in-alt" label="Login" link="/login" />
            <DrawerLink
              v-if="credentials && credentials.length > 0"
              key="credencialoffline"
              icon="fa fa-newspaper"
              label="Credenciais"
              link="/offlinecredential"
            />
          </template>

          <omt-divider />
          <div class="app-version">
            <p>Copyright <span class="copyright-year"></span> | OMINT Saúde e Seguros.<br />{{ this.pwaVersion }}</p>
          </div>
          <div v-if="iOS" class="ios-margin"></div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import DotLoader from "vue-spinner/src/DotLoader";
import DrawerLink from "./DrawerLink";
import { mapActions, mapGetters, mapState } from "vuex";
import { ROTA_OPTIN, ROTA_WELCOME_ONBOARD_TOUR } from "@/utils/perfilRouteUtil";
import { isLoggedIn } from "@/utils/authUtil";
import { isMobile } from "@/utils/utils";

export default {
  components: { DotLoader, DrawerLink },
  computed: {
    ...mapGetters("saude", ["pwaVersion"]),
    ...mapState("saude", ["credentials", "hydrated", "menu", "operacao", "perfil", "theme", "vinculo", "vinculos"]),
    ...mapGetters("biometrics", ["onNative"]),
    ...mapState("biometrics", ["hasBiometric"]),
    ...mapState("accessManagement", ["appLocked"]),
    ...mapState("myAccount", ["welcomeOnBoardTourStep"]),
    iOS() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    isLogged() {
      if (this.appLocked) return false;
      return this.isLoggedInApp && this.vinculo && this.perfil && this.operacao;
    },
    exibirVinculos() {
      if (!this.hydrated) return false;
      if (!this.vinculos?.length) return false;
      return this.vinculos.length > 1 || this.vinculos[0].Vinculos?.length > 1;
    },
    exibirMenu() {
      const rota = this.$route.path;
      if (rota.startsWith(ROTA_OPTIN)) return false;
      if (rota.startsWith(ROTA_WELCOME_ONBOARD_TOUR) && this.welcomeOnBoardTourStep > 0) return false;
      return true;
    },
    v25() {
      return Number.parseInt(localStorage.getItem("native_version")) >= 25;
    },
    filteredMenu() {
      if (!this.menu?.length) return [];
      if (!this.exibirMenu) return [];
      if (this.appLocked) return [];
      return this.menu.filter(m => !m.Desktop || (m.Desktop && !this.onNative && !this.isMobile));
    },
    loadingMenu() {
      return !this.filteredMenu?.length && !this.hydrated;
    }
  },
  mounted() {
    this.isLoggedInApp = isLoggedIn();
    this.onResizeHandler();
  },
  methods: {
    ...mapActions("saude", ["changeTheme"]),
    ...mapActions("accessManagement", ["lockApp"]),
    async logout() {
      this.$router.replace("/logout");
    },
    async blockApp() {
      await this.lockApp();
      await this.$router.replace("/login");
    },
    myStorage() {
      this.$router.replace("/myStorage");
    },
    showHideMenu() {
      if (this.menuHidden) {
        this.menuClass = "menu menu-active";
        this.menuHidden = false;
      } else {
        this.menuClass = "menu menu-deactive";
        this.menuHidden = true;
      }
    },
    onResizeHandler() {
      this.isMobile = isMobile();
    }
  },
  created() {
    this.$root.$on("show-hide-menu", () => {
      this.showHideMenu();
    });
    window.addEventListener("resize", this.onResizeHandler);
  },
  beforeDestroy() {
    this.$root.$off("show-hide-menu");
    window.removeEventListener("resize", this.onResizeHandler);
  },
  data() {
    return {
      menuHidden: true,
      menuClass: "menu menu-deactive",
      isMobile: isMobile(),
      isLoggedInApp: false
    };
  }
};
</script>
<style scoped>
.app-name {
  cursor: pointer;
  display: block;
  font-size: 24px;
  text-align: center;
  padding: 30px 0px 20px 0px;
  font-weight: 800;
  letter-spacing: 2px;
  line-height: 110%;
  text-transform: uppercase;
}

.app-version {
  display: block;
  font-size: 10px;
  text-align: center;
}

.menu-active {
  transform: translate3d(0%, 0, 0);
  transition: transform 300ms ease;
  will-change: transform;
}

.menu {
  display: flex;
  position: fixed;
  top: 0px;
  width: 250px;
  right: 0px;
  height: 100vh;
  z-index: 150;
}

@media screen and (min-width: 970px) {
  .menu {
    min-width: 250px;
    width: calc(40px + (100vw - 970px) / 2);
  }
}

.menu-box {
  display: block;
  position: relative;
  width: 100%;
  right: 0px;
  bottom: 0px;
  overflow: scroll;
}

.menu-deactive {
  transform: translateX(100%);
  transition: transform 300ms ease;
  will-change: transform;
}

.menu-hider {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.35);
}

.dark .menu-box {
  color: white;
  background-color: #1f1f1f;
}

.light .menu-box {
  color: #031432;
  background-color: white;
}

.ios-margin {
  width: 100%;
  height: 60px;
}

.loading {
  padding-left: calc(50% - 15px);
}
</style>
