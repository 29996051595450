<template>
  <OmtLayoutHeaderFooter :header="{ titleLabel: 'Busca credenciada' }">
    <router-view />
  </OmtLayoutHeaderFooter>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "AffiateSearch",
  methods: {
    ...mapMutations("affiliate", ["SET_DEACTIVATED"])
  },
  created() {
    this.SET_DEACTIVATED(false);
  }
};
</script>
