import Logger from "@/utils/logger";
import AxiosProxy from "@/utils/axiosProxy";
import JseUtil from "@/utils/jseUtil";

const logger = new Logger();
window.$log = logger;

const axiosProxy = new AxiosProxy();
window.$axios = axiosProxy;

const jseUtil = new JseUtil();
window.$jse = jseUtil;

export {};
