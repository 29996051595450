<template>
  <div>
    <Header name="Indicação de Rede" titleLabel="indicação de Rede" />
    <omint-page-content>
      <CBox mb="4">
        <omt-h1> <omt-uppercase>Solicitar indicação médica</omt-uppercase> </omt-h1>
      </CBox>

      <CBox mb="5">
        <omt-p> Selecione uma das opções abaixo: </omt-p>
      </CBox>

      <CBox mb="4">
        <AffiliateIndicationTile
          :hasCountainer="true"
          :hasHoverEffect="true"
          icon="far fa-comment-alt"
          title="Por mensagem"
          subtitle="Envie sua solicitação por mensagem de texto."
          link="/indicacao-de-credenciados/formulario"
        />
      </CBox>

      <CBox>
        <AffiliateIndicationTile
          :hasCountainer="true"
          :hasHoverEffect="true"
          icon="far fa-calendar"
          title="Por Teleatendimento"
          subtitle="Agende um horário e fale com a Enfermeira."
          link="/agendamento/indicacao"
        />
      </CBox>
    </omint-page-content>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";
import AffiliateIndicationTile from "@/components/Organisms/AffiliateIndication/Tile";
import { CBox } from "@chakra-ui/vue";

export default {
  name: "AffiliateIndicationMenu",
  components: { Footer, Header, AffiliateIndicationTile, CBox },
  mounted() {
    setTimeout(() => {
      this.$root.$emit("hide-loading");
    }, 0);
  }
};
</script>
