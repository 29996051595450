<template>
  <CBox background-color="white">
    <CFlex justify-content="space-between" padding="24px 16px" margin="-16px -16px 0px -16px">
      <CFlex>
        <OmtButton
          mr="16px"
          size="sm"
          :variant="hasActivedFilter ? 'solid' : 'outline'"
          leftIcon="sliders-h"
          :iconColor="hasActivedFilter ? 'white' : 'var(--colors-light-primary-gray1)'"
          iconSize="sm"
          border=".5px solid var(--colors-light-secondary-divider)"
          @click="$emit('update:isOpen', true)"
        >
          <OmtTypography as="button-small" :color="hasActivedFilter ? 'white' : 'var(--colors-light-primary-gray1)'">
            Filtrar
          </OmtTypography>
        </OmtButton>

        <slot />
      </CFlex>

      <OmtButton variant="ghost" size="sm" :disabled="!hasActivedFilter && !activedSort" @click="onClean">
        <OmtTypography as="button-small" color="var(--colors-light-primary-gray1)"> Limpar </OmtTypography>
      </OmtButton>
    </CFlex>

    <OmtSwiper ref="OmtSwiper" :spaceBetween="12">
      <OmtSwiperSlide v-for="(filter, index) in activedFilters" :key="index" width="auto">
        <OmtButton
          size="xs"
          bg="var(--colors-light-primary-light-gray)"
          rightIcon="times"
          iconSize="xs"
          @click="removeFilter(filter.type, filter.label)"
        >
          <OmtTypography color="var(--colors-light-primary-gray1)" as="p2">
            {{ filter.label }}
          </OmtTypography>
        </OmtButton>
      </OmtSwiperSlide>
    </OmtSwiper>
  </CBox>
</template>

<script>
/**
 * @prop { Boolean } isOpen
 * @type { key: String, value: Boolean } filterOptionObjectType
 * @type { beneficiarios: Array<filterOptionObjectType>, idiomas: Array<filterOptionObjectType> } filterType
 * @prop { filterType } filters
 * @prop { String } activedSort
 * @Event {(filterType) => void} change
 * @Event {(String) => void} cleanSorts
 * **/
export default {
  props: {
    isOpen: {
      type: Boolean
    },
    filters: {
      type: Object
    },
    activedSort: {
      type: String
    }
  },
  data() {
    return {
      activedFilters: [],
      headerFilters: {},
      hasActivedFilter: false
    };
  },
  methods: {
    removeFilter(type, label) {
      this.headerFilters[type][label] = !this.headerFilters[type][label];

      this.$emit("change", this.headerFilters);
    },
    onClean() {
      if (this.activedFilters.length > 0) {
        Object.keys(this.headerFilters.beneficiarios).forEach(b => (this.headerFilters.beneficiarios[b] = false));
        Object.keys(this.headerFilters.idiomas).forEach(i => (this.headerFilters.idiomas[i] = false));
        this.$emit("change", this.headerFilters);
      }

      this.$emit("cleanSorts", "");
    },
    updateFilters(newValues) {
      const activedFilters = [];
      let hasActivedFilter = false;
      Object.keys(newValues.beneficiarios).forEach(b => {
        if (newValues.beneficiarios[b]) {
          activedFilters.push({ type: "beneficiarios", label: b });
          hasActivedFilter = true;
        }
      });
      Object.keys(newValues.idiomas).forEach(i => {
        if (newValues.idiomas[i]) {
          activedFilters.push({ type: "idiomas", label: i });
          hasActivedFilter = true;
        }
      });
      this.headerFilters = JSON.parse(JSON.stringify(newValues));
      this.activedFilters = activedFilters;
      this.hasActivedFilter = hasActivedFilter;
    }
  },
  watch: {
    filters(newValues) {
      this.updateFilters(newValues);
    }
  },
  mounted() {
    this.updateFilters(this.filters ?? {});
  }
};
</script>
