<template>
  <OmtLayoutContainer>
    <OmtRow mb="24px">
      <OmtTypography as="h1-bold"> Envio das imagens </OmtTypography>
    </OmtRow>
    <OmtRow mb="24px">
      <OmtTypography as="p1" color="#4E4E4E" font-weight="600 !important">
        Insira a imagem referente a sua solicitação:
      </OmtTypography>
    </OmtRow>

    <OmtRow>
      <ImageUploader @change="onImageUploaderChange" :length="limitFiles" :initialValue="pictures" />
    </OmtRow>

    <OmtRow :desktopStart="1" :desktopEnd="6" :gridRowStart="['initial', '2']">
      <CBox>
        <CFlex mb="8px">
          <OmtIcons name="check" size="lg" mr="16px" color="var(--colors-light-support-success)" />
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">
            <b>Arquivos suportados:</b> .jpg, .png, .heic e .pdf. com até 25mb.
          </OmtTypography>
        </CFlex>

        <CFlex mb="8px">
          <OmtIcons name="check" size="lg" mr="16px" color="var(--colors-light-support-success)" />
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">
            Envie apenas <b>um documento por foto</b>.
          </OmtTypography>
        </CFlex>

        <CFlex mb="8px">
          <OmtIcons name="check" size="lg" mr="16px" color="var(--colors-light-support-success)" />
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">
            Certifique-se de que todas as informações das imagens estão legíveis.
          </OmtTypography>
        </CFlex>
      </CBox>
    </OmtRow>

    <FixedButtonRow>
      <OmtButton :w="['100%', '308px !important']" @click="next" :disabled="disableButton"> Continuar </OmtButton>
    </FixedButtonRow>
  </OmtLayoutContainer>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import ImageUploader from "@/components/Molecules/ImageUploader";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

export default {
  name: "UploadFileRequest",
  components: { ImageUploader, FixedButtonRow },
  data() {
    return {
      pictures: [],
      requireAuth: true
    };
  },

  computed: {
    ...mapState("request", ["addPermission", "limitFiles", "newRequest", "subjectOptions"]),
    disableButton() {
      return this.pictures.length === 0;
    }
  },
  methods: {
    ...mapMutations("request", ["SET_PICTURES"]),
    onImageUploaderChange(pictures) {
      this.pictures = pictures?.filter(pic => pic.source && pic.name) ?? [];
    },
    next() {
      if (this.disableButton) return;
      this.SET_PICTURES(this.pictures);
      this.$router.push({
        name: "request.observation",
        params: {
          requireAuth: this.requireAuth
        }
      });
    }
  },
  mounted() {
    if (!this.addPermission) {
      this.$router.replace("/solicitacoes");
      return;
    }
    if (!this.newRequest?.subject) {
      this.$router.replace("/solicitacoes/criar");
      return;
    }
    const subject = this.subjectOptions.find(s => s.value === this.newRequest.subject);
    if (!subject?.attchamentType) {
      this.$router.replace("/solicitacoes/criar");
      return;
    }
    this.requireAuth = !!subject.authorizationService;
    this.pictures = this.newRequest?.pictures ?? [];
    this.$root.$emit("hide-loading");
  }
};
</script>

<style scoped></style>
