<template>
  <OmtStickedModal @close="$emit('close')" data-cy="feedback-modal">
    <OmtTypography as="h1-bold" mb="32px">
      {{ title }}
    </OmtTypography>
    <CFlex>
      <CBox w="100%">
        <CBox
          v-for="(item, index) in list"
          :key="index"
          :pb="['16px', '24px']"
          cursor="pointer"
          @click="item.action ? $emit('' + item.action, value) : ''"
        >
          <CFlex justifyContent="space-between">
            <CFlex alignItems="center">
              <OmtIcons :name="item.icon" :font-size="['16px', '21px']" size="inherit" pr="8px" />
              <OmtTypography as="h3-semi-bold">
                {{ item.label }}
              </OmtTypography>
            </CFlex>
          </CFlex>
        </CBox>
      </CBox>
    </CFlex>
  </OmtStickedModal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    list: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  }
};
</script>
