<template>
  <OmtStickedModal @close="$emit('close')" data-cy="feedback-modal">
    <CBox min-height="60vh">
      <OmtTypography as="h1-bold" mb="32px" v-if="type === 'renovar'"> Renovar autorização </OmtTypography>
      <OmtTypography as="h1-bold" mb="32px" v-else> Autorizar administração </OmtTypography>

      <OmtTypography as="p1">
        A autorização deste contato como administrador da sua conta é necessária para manter a sua
        segurança.</OmtTypography
      >
      <OmtTypography as="h3-bold" py="16px">
        Selecione o período em que você deseja renovar esta autorização:
      </OmtTypography>
      <OmtTypography as="p1"> Revalidação obrigatória</OmtTypography>

      <CFlex py="16px" justifyContent="space-between">
        <CBox width="48%"
          ><OmtButton @click="selectedPeriod = 'A'" :variant="selectedPeriod === 'A' ? 'solid' : 'outline'" w="100%"
            >Anual</OmtButton
          ></CBox
        >
        <CBox width="48%"
          ><OmtButton @click="selectedPeriod = 'S'" :variant="selectedPeriod === 'S' ? 'solid' : 'outline'" w="100%"
            >Semestral</OmtButton
          ></CBox
        >
      </CFlex>
    </CBox>
    <template #footer>
      <OmtButton @click="$emit('' + type, { ...value, Revalidacao: selectedPeriod })" w="100%">Salvar</OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
      default: ""
    },
    value: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedPeriod: "S"
    };
  },
  computed: {
    htmlCardDescription() {
      if (!this.cardDescription) return false;
      return this.cardDescription.indexOf("<") !== -1;
    },
    cardDescriptions() {
      return this.cardDescription?.split("|") || "";
    }
  }
};
</script>
