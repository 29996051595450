<template>
  <CBox>
    <Header name="Avaliação" titleLabel="Avaliação" backRoute="/" />
    <omint-page-content>
      <InfoBox v-if="message" :message="message" />

      <template v-if="show.avaliacaoPrestadorPendente !== null">
        <CBox class="sticky" mb="16px" @click.prevent="() => toggle('avaliacaoPrestadorPendente')">
          <omt-h1 class="text-uppercase">
            Avaliações pendentes
            <span v-if="grupos > 1" class="icon">
              <i v-bind:class="['fas', show.avaliacaoPrestadorPendente ? 'fa-minus' : 'fa-plus']"></i>
            </span>
          </omt-h1>
        </CBox>
        <template v-if="show.avaliacaoPrestadorPendente">
          <CBox
            mb="16px"
            v-for="(evaluation, index) in evaluationList.atendimentosNaoAvaliados"
            :key="`${evaluation.idAtendimento}-${index}`"
          >
            <EvaluationItem
              :title="evaluation.NomePrestador"
              :description="evaluation.description"
              :hasBottomBorder="index < evaluationList.atendimentosNaoAvaliados.length - 1"
              @click="handleClick(evaluation)"
            />
          </CBox>
        </template>
        <omt-divider />
      </template>

      <template v-if="show.avaliacaoPrestadorRealizada !== null">
        <CBox class="sticky" mb="16px" @click.prevent="() => toggle('avaliacaoPrestadorRealizada')">
          <omt-h1 class="text-uppercase">
            Avaliações realizadas
            <span v-if="grupos > 1" class="icon">
              <i v-bind:class="['fas', show.avaliacaoPrestadorRealizada ? 'fa-minus' : 'fa-plus']"></i>
            </span>
          </omt-h1>
        </CBox>
        <template v-if="show.avaliacaoPrestadorRealizada">
          <CBox
            mb="16px"
            v-for="(evaluation, index) in evaluationList.atendimentosAvaliados"
            :key="`${evaluation.idAtendimento}-${index}`"
          >
            <EvaluationItem
              :title="evaluation.NomePrestador"
              :description="evaluation.description"
              :hasBottomBorder="index < evaluationList.atendimentosAvaliados.length - 1"
              :rating="evaluation.Avaliacao"
              :confirmAttendance="evaluation.ConfirmaAtendimento"
              @click="handleClick(evaluation)"
            />
          </CBox>
        </template>
        <omt-divider />
      </template>
    </omint-page-content>
  </CBox>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import InfoBox from "@/components/Molecules/InfoBox";
import Tile from "@/components/Organisms/AffiliateIndication/Tile";
import EvaluationItem from "@/components/Organisms/ProviderEvaluation/EvaluationItem.vue";
import { CBox } from "@chakra-ui/vue";
import Header from "@/components/Molecules/Header";

export default {
  name: "ProviderEvaluationList",
  components: { InfoBox, Tile, Header, CBox, EvaluationItem },
  data() {
    return {
      message: null,
      grupos: 0,
      show: {
        avaliacaoPrestadorPendente: null,
        avaliacaoPrestadorRealizada: null
      }
    };
  },
  async mounted() {
    try {
      await this.$root.$emit("show-loading");
      await this.fetchAtendimentosAvaliacao();
      if (this.evaluationList?.atendimentosNaoAvaliados?.length) {
        this.grupos++;
        this.show.avaliacaoPrestadorPendente = true;
      }
      if (this.evaluationList?.atendimentosAvaliados?.length) {
        this.grupos++;
        this.show.avaliacaoPrestadorRealizada = true;
      }
      if (!this.show.avaliacaoPrestadorPendente && !this.show.avaliacaoPrestadorRealizada) {
        this.message = "Nenhum atendimento para avaliação";
      }
    } catch (error) {
      this.message = "Serviço temporariamente indisponível";
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  methods: {
    ...mapActions("atendimento", ["fetchAtendimentosAvaliacao"]),
    ...mapMutations("ticket", ["SET_RATING_INFOS"]),
    toggle(stickyName) {
      if (this.grupos <= 1) return;
      this.show[stickyName] = !this.show[stickyName];
    },
    async handleClick(evaluation) {
      if (!evaluation?.TipoAtendimento) return;
      if (evaluation.Respondido === "S") {
        this.$router.push(`/avaliacao-do-prestador/resumo/${evaluation.idAtendimento}`);
      } else {
        if (evaluation.TipoAtendimento === "600") {
          this.$router.push(`/avaliacao-do-prestador/detalhes/${evaluation.idAtendimento}`);
        } else {
          await this.SET_RATING_INFOS({
            ratingRoute: "/avaliacao-do-prestador",
            ratingType: evaluation.TipoAtendimento
          });
          this.$router.replace({
            path: `/experience-rating/${evaluation.idAtendimento}/${evaluation.TipoAtendimento}/P`
          });
        }
      }
    }
  },
  computed: mapState("atendimento", ["evaluationList"])
};
</script>
<style scoped>
.text-uppercase {
  text-transform: uppercase;
}

.icon {
  position: absolute;
  display: inline-block;
  right: 0px;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  opacity: 1 !important;
  z-index: 50;
  display: flex;
  align-items: center;
}

.dark .sticky {
  background-color: black;
}
</style>
