<template>
  <CBox w="122px !important">
    <OmtButton
      leftIcon="arrow-up-arrow-down"
      size="sm"
      iconSize="sm"
      border=".5px solid var(--colors-light-secondary-divider)"
      :variant="!!initialValue ? 'solid' : 'outline'"
      :iconColor="!!initialValue ? 'white' : 'var(--colors-light-primary-gray1)'"
      @click="isOpen = !isOpen"
    >
      <OmtTypography as="button-small" :color="initialValue ? 'white' : 'var(--colors-light-primary-gray1)'">
        Ordenar
      </OmtTypography>
    </OmtButton>
    <CBox>
      <OmtStickedModal
        data-cy="request-list-sort-modal"
        v-if="isOpen"
        @close="close"
        :headerBind="{
          padding: ['16px', '82px 42px 0px 42px']
        }"
        :bodyBind="{
          padding: ['16px 16px 24px 16px', '68px 42px 24px 42px']
        }"
      >
        <template #header>
          <OmtButton class="clear" :display="['block', 'none']" variant="ghost" @click="clear" height="18px" p="0">
            <OmtTypography as="p1" h="18px" w="46px" position="relative"> Limpar </OmtTypography>
          </OmtButton>
        </template>

        <CBox :minHeight="['initial', 'calc(100vh - 400px)']">
          <CFlex justifyContent="space-between" :mb="['24px', '36px']">
            <OmtTypography as="h1-bold"> Ordenar </OmtTypography>
            <OmtButton class="clear" variant="ghost" @click="clear" height="18px" p="0" :display="['none', 'block']">
              <OmtTypography as="p1" class="" h="18px" w="46px" position="relative"> Limpar </OmtTypography>
            </OmtButton>
          </CFlex>

          <OmtRow>
            <OmtTypography as="h2-bold"> Por período </OmtTypography>
          </OmtRow>

          <CRadioGroup v-model="activedSort" mt="24px">
            <CRadio
              isFullWidth
              :value="option"
              cursor="pointer"
              v-for="(option, index) in sorts"
              :key="index"
              :class="index === 0 ? 'first-sort' : 'last-sort'"
            >
              <OmtTypography as="p1"> {{ option }} </OmtTypography>
            </CRadio>
          </CRadioGroup>
        </CBox>

        <template #footer>
          <OmtButton w="100%" @click="save"> Aplicar </OmtButton>
        </template>
      </OmtStickedModal>
    </CBox>
  </CBox>
</template>

<script>
/**
 * @prop {String[]} sorts
 * @prop {String} initialValue
 */
export default {
  props: {
    sorts: {
      type: Array
    },
    initialValue: {
      type: String
    }
  },
  data() {
    return {
      isOpen: false,
      activedSort: ""
    };
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    clear() {
      this.activedSort = "";
    },
    save() {
      this.$emit("sort", this.activedSort);
      this.isOpen = false;
    }
  },
  watch: {
    isOpen: {
      handler() {
        this.activedSort = this.initialValue;
      },
      immediate: true
    },
    initialValue: {
      handler() {
        this.activedSort = this.initialValue;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .first-sort {
  width: 100%;
  border-bottom: 0.5px solid #dadada;
  padding-bottom: 24px;
}

::v-deep .last-sort {
  width: 100%;
  padding-top: 24px;
}

div[data-chakra-component="CRadioGroup"] {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

::v-deep label[data-chakra-component="CRadio"] {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
</style>
