<template>
  <div v-if="show && !closeWindow" class="install-hider">
    <div :class="myClass">
      <div style="display: flex; justify-content: center">
        <img class="round-medium" src="app/omint-icons/icon075x075.png" width="90" alt="Logo Omint APP" />
      </div>
      <omt-buttons-bar v-if="displayInstallButton">
        <omt-button :onClick="installer" :bigSize="true"> Instalar na tela inicial </omt-button>
      </omt-buttons-bar>
      <omt-p v-else-if="iOS">
        Instale OMINT APP na sua tela inicial! Abrir o Safari e no menu selecione a opção "Adicionar a tela iniciar".
      </omt-p>
      <br />
      <omt-divider :onlyClear="true" />
      <a href="#" class="pwa-dismiss" @click.prevent="close"> Melhor mais tarde... </a>
      <i class="fa fa-caret-down fa-2x"></i>
    </div>
  </div>
</template>
<script>
import { getStorageData, setStorageData } from "@/utils/storageUtil";

export default {
  computed: {
    iOS() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    webView() {
      return navigator.userAgent.match(/WebView/i);
    }
  },
  data() {
    return {
      myClass: "install-box install-round-medium",
      closeWindow: false,
      timer: null,
      installer: undefined,
      installPrompt: undefined,
      displayInstallButton: false,
      show: false,
      rejectedInstall: false
    };
  },
  created() {
    this.rejectedInstall = getStorageData("rejected_install", false, true);
    if (this.webView || this.iOS) {
      return;
    }
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      this.installPrompt = e;
      this.displayInstallButton = true;
      this.show = !this.rejectedInstall;
      if (!this.iOS) {
        this.timer = setInterval(() => this.clearState(), 300);
      }
    });
    window.addEventListener("appinstalled", _ => {
      this.$root.$emit("hide-loading");
      this.closeWindow = true;
    });
    this.installer = () => {
      this.installPrompt.prompt();
      this.installPrompt.userChoice.then(result => {
        if (result.outcome === "accepted") {
          this.$root.$emit("show-loading");
        }
      });
    };
  },
  mounted() {
    if (this.webView || this.iOS) {
      return;
    }
    if (this.iOS && !this.rejectedInstall) {
      this.show = !window.navigator.standalone;
      this.timer = setInterval(() => this.clearState(), 300);
    }
  },
  methods: {
    clearState() {
      this.myClass = "install-box install-round-medium install-pwa-active";
      clearInterval(this.timer);
    },
    close() {
      setStorageData("rejected_install", true);
      this.closeWindow = true;
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>
<style scoped>
.install-hider {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.35);
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.install-box {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-content: center;
  opacity: 1;
  background-color: white;
  width: 90%;
  max-width: 300px;
  text-align: center;
  padding: 20px;
  margin: auto;
  z-index: 150;
  margin: 0;
  bottom: 150px;
  left: 50%;
  transform: translate(-50%, 200%);
  transition: all 350ms ease;
}

@media screen and (orientation: landscape) {
  .install-box {
    bottom: 25px;
  }
}

.pwa-dismiss {
  color: red;
  text-transform: uppercase;
  opacity: 0.8;
  font-weight: 800;
}

.install-pwa-active {
  transform: translate(-50%, 0%);
}

.install-round-medium {
  border-radius: 15px;
}
</style>
