<template>
  <omint-page>
    <Header name="Avalie sua experiência" titleLabel="Avalie sua experiência" :backRoute="rotaDestino" />
    <omint-page-content>
      <RatingForm :protocolo="protocolo" :tipo="tipo" :etapa="etapa" :rotaDestino="rotaDestino" />
    </omint-page-content>
    <Footer />
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import RatingForm from "@/components/Organisms/RatingForm";
import Footer from "@/components/Molecules/Footer";
import { mapState } from "vuex";

export default {
  name: "Avaliation",
  components: {
    Header,
    RatingForm,
    Footer
  },
  computed: {
    ...mapState("ticket", ["ratingRoute"]),
    protocolo() {
      return this.$route.params.protocolo;
    },
    tipo() {
      return this.$route.params.tipo ?? this.ratingType;
    },
    etapa() {
      return this.$route.params.etapa ?? "S";
    },
    rotaDestino() {
      return this.ratingRoute ?? "/";
    }
  },
  async mounted() {
    if (!this.protocolo || !this.tipo) {
      this.$router.replace(this.rotaDestino);
      return;
    }
  }
};
</script>
