import Vue from "vue";
import {
  ObterLogradouros,
  ObterParametrosEndereco,
  ObterResumoFormasContato,
  SolicitarAlteracaoGeral,
  ConsultarRequisicaoAlteracao,
  ValidarAlteracaoGeral,
  ObterMunicipios
} from "@/api/formasContato";
import { ExisteChamadoPendenteProcessamento } from "@/api/atendimento";
import { checkUpdatePermission } from "@/utils/checkReadonlyUtil";

const initialState = {
  paramsAddress: {},
  contacts: [],
  resumeContacts: {},
  changesResume: {},
  changeRequest: {},
  contactsToReceiveChanges: {},
  existChangeRequest: false,
  newAddress: null,
  newEmails: null,
  newPhones: null,
  newWhatsapp: null
};

// vuex module
export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions: {
    async fetchAddressInfos(context, { cep }) {
      return await ObterLogradouros({
        vinculo: context.rootState.saude.vinculo,
        cep
      });
    },
    async fetchCities(context, { uf }) {
      return await ObterMunicipios({
        vinculo: context.rootState.saude.vinculo,
        uf
      });
    },
    async fetchParamsAddress(context) {
      const paramsAddress = await ObterParametrosEndereco({
        vinculo: context.rootState.saude.vinculo
      });
      context.commit("SET_NEW_STATE", {
        key: "paramsAddress",
        value: paramsAddress
      });
    },
    async fetchResumeContacts(context) {
      try {
        const response = await ObterResumoFormasContato({
          vinculo: context.rootState.saude.vinculo
        });
        if (!response?.Data) {
          context.commit("SET_NEW_STATE", {
            key: "resumeContacts",
            value: {}
          });
          return;
        }
        const infos = response.Data;
        const tels = [];
        const countryCodeRegex = /^\+(\d+)\s*/;
        infos.Telefones.forEach((tel, id) => {
          const match = tel.Numero.match(countryCodeRegex);

          let countryCode = "";

          if (match && match[1]) {
            countryCode = "+" + match[1];
          }

          tels[id] = {
            Numero: tel.Numero.replace(countryCodeRegex, ""),
            Tipo: tel.Tipo,
            countryCode
          };
        });

        context.commit("SET_NEW_STATE", {
          key: "resumeContacts",
          value: {
            Empresarial: infos.Empresarial ?? true,
            Endereco: {
              CEP: infos.Endereco?.CEP ?? "",
              TipoLogradouro: infos.Endereco?.TipoLogradouro ?? "",
              IdLogradouro: infos.Endereco?.IdLogradouro ?? 0,
              Logradouro: infos.Endereco?.Logradouro ?? "",
              Numero: infos.Endereco?.Numero ?? "",
              Complemento: infos.Endereco?.Complemento ?? "",
              Bairro: infos.Endereco?.Bairro ?? "",
              Cidade: infos.Endereco?.Cidade ?? "",
              UF: infos.Endereco?.UF ?? ""
            },
            Emails: {
              Pessoal: infos.Emails?.Pessoal ?? "",
              Fatura: infos.Emails?.Fatura ?? ""
            },
            Telefones: tels
          }
        });
      } catch (error) {
        throw Error(error);
      }
    },
    async validadeChanges(context, { allowWithoutChanges }) {
      const telefones =
        context.state.newPhones?.map(phone => ({
          Numero: `${phone.countryCode} ${phone.Numero}`,
          Tipo: phone.Tipo
        })) || [];
      const changes = await ValidarAlteracaoGeral({
        vinculo: context.rootState.saude.vinculo,
        emails: context.state.newEmails || [],
        endereco: context.state.newAddress,
        telefones,
        permitirSemAlteracao: allowWithoutChanges ?? false
      });
      if (!changes)
        return {
          MsgValidacao: "Serviço temporariamente indisponível",
          Enderecos: [],
          Telefones: [],
          Emails: {}
        };
      if (changes instanceof Error)
        return {
          MsgValidacao: changes.message,
          Enderecos: [],
          Telefones: [],
          Emails: {}
        };

      return changes;
    },
    async createChangeRequestFromTour(context) {
      await context.dispatch("createChangeRequest", { auth: context.rootState.myAccount.welcomeOnBoardTourAuth });
      const infos = context.state.resumeContacts;
      context.commit("SET_NEW_STATE", {
        key: "resumeContacts",
        value: {
          Empresarial: infos.Empresarial ?? true,
          Endereco: context.state.newAddress ? { ...context.state.newAddress } : { ...infos.Endereco },
          Emails: context.state.newEmails ? { ...context.state.newEmails } : { ...infos.Emails },
          Telefones: context.state.newPhones?.length ? context.state.newPhones : infos.Telefones
        }
      });
      context.commit("SET_NEW_STATE", { key: "existChangeRequest", value: true });
      if (context.state.newWhatsapp) {
        await context.dispatch(
          "atendimento/ativarAtendimentoWhatsApp",
          { phoneNumber: context.state.newWhatsapp, auth: context.rootState.myAccount.welcomeOnBoardTourAuth },
          { root: true }
        );
      } else if (!context.state.resumeContacts?.Telefones?.find(t => t.Tipo === "Celular" || t.Tipo === "Comercial")) {
        // teve alteração das formas de contato mas não cadastrou nenhum telefone, não pode acessa a trilha de whatsapp
        await context.dispatch(
          "myAccount/updateWelcomeOnBoardTourCompl",
          { pathTo: "/whatsapp", option: true },
          { root: true }
        );
      }
      context.commit("RESET_NEW_CONTACTS");
    },
    async createChangeRequest(context, { auth }) {
      checkUpdatePermission();

      const telefones =
        context.state.newPhones?.map(phone => ({
          Numero: `${phone.countryCode} ${phone.Numero}`,
          Tipo: phone.Tipo
        })) || [];
      const response = await SolicitarAlteracaoGeral({
        vinculo: context.rootState.saude.vinculo,
        usuario: context.rootState.saude.username,
        emails: context.state.newEmails || [],
        endereco: context.state.newAddress,
        telefones,
        autenticacao: auth
      });

      if (response instanceof Error) throw Error(response);

      context.commit("SET_NEW_STATE", {
        key: "changeRequest",
        value: response.Data ? { ...response.Data } : { ...response }
      });

      return response;
    },
    async fetchChangesResume(context, { chave }) {
      const changes = await ConsultarRequisicaoAlteracao({ chave });
      context.commit("SET_NEW_STATE", {
        key: "changesResume",
        value: { ...changes }
      });
    },
    async validateActiveTicket(context) {
      if (!context.rootState.saude.vinculo) return false;

      const existChangeRequest = await ExisteChamadoPendenteProcessamento({
        vinculo: context.rootState.saude.vinculo,
        beneficiario: context.rootState.saude.vinculo,
        servico: 5
      });

      context.commit("SET_NEW_STATE", { key: "existChangeRequest", value: existChangeRequest.Data });
    }
  },
  mutations: {
    SET_NEW_STATE: (state, { key, value }) => Vue.set(state, key, value),
    LOGOUT_USER: state => Object.keys(state).forEach(key => (state[key] = initialState[key])),
    RESET_CONTEXT: state => Object.keys(state).forEach(key => (state[key] = initialState[key])),
    RESET_NEW_CONTACTS: state => {
      ["newAddress", "newEmails", "newPhones"].forEach(key => {
        state[key] = null;
      });
    }
  },
  getters: {
    hasNewChanges(state) {
      return state.newAddress || state.newEmails || state.newPhones;
    }
  }
};
