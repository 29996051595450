<template>
  <CBox>
    <CBox class="account-card">
      <CFlex justify="space-between" class="account-card_title">
        <omt-p>Conta corrente para crédito</omt-p>
        <omt-p v-if="!account.confiavel && !account.validada" class="account-cards-disclaimer text-danger">
          Validação Pendente
        </omt-p>
        <i v-if="!isChanging" class="fa fa-lg fa-trash-alt" @click="handleDelete"></i>
      </CFlex>

      <CBox v-for="(value, key, index) in accountInfos" :key="key">
        <CFlex>
          <CBox class="account-card_value">
            <omt-p>{{ key }}</omt-p>
          </CBox>
          <CBox>
            <omt-p>{{ value }}</omt-p>
          </CBox>
        </CFlex>
        <CDivider v-if="index != Object.keys(accountInfos).length - 1" bg="#ACB4B3" my="8px" opacity="0.1" />
      </CBox>

      <CBox class="account-card_actions">
        <CDivider v-if="isTitular || (!isTitular && isMainAccount)" bg="#ACB4B3" my="8px" opacity="0.1"></CDivider>
        <CFlex align="center">
          <template v-if="isChangingMainAccount">
            <CCheckbox
              v-model="checkValue"
              @change="selectMainAccount(account)"
              class="checkbox"
              name="main-account"
              size="md"
              mb="var(--space-5)"
            ></CCheckbox>
            <omt-p v-if="isMainAccount"> Atualizar para conta secundária </omt-p>
            <omt-p v-else>
              <CFlex align="center">
                Atualizar como conta principal
                <i v-chakra ml="2" class="fa fa-lg fa-info" @click="showAccountMainModal = true"></i>
              </CFlex>
            </omt-p>
          </template>

          <template v-else>
            <i v-if="isMainAccount" class="fas fa-lg fa-check-circle"></i>
            <omt-p v-if="isMainAccount"> Selecionada como conta principal </omt-p>
          </template>
        </CFlex>

        <CBox
          v-if="!isMainAccount && !isChanging && !isChangingMainAccount && isTitular"
          class="account-card__change-label"
          ml="4"
          @click="emitChange"
        >
          <omt-p><b> Alterar para conta PRINCIPAL </b></omt-p>
        </CBox>
      </CBox>
    </CBox>
    <DeleteModal
      v-if="showDeleteModal"
      @close="showDeleteModal = false"
      @cancel="showDeleteModal = false"
      @delete="emitDelete"
    />
    <AccountMainModal v-if="showAccountMainModal" @close="showAccountMainModal = false" />
  </CBox>
</template>

<script>
import { CBox, CFlex, CStack, CDivider, CCheckbox } from "@chakra-ui/vue";
import OmtHighlight from "@/components/Atoms/omt-highlight.vue";
import AccountMainModal from "@/components/Organisms/BankAccount/Modals/AccountMainModal.vue";
import DeleteModal from "@/components/Organisms/BankAccount/Modals/DeleteModal.vue";

export default {
  props: {
    account: {
      type: Object,
      required: true,
      default: {
        at_dac: "",
        nr_conta: "",
        cd_agencia: "",
        cd_banco: "",
        ds_banco: ""
      }
    },
    isMainAccount: {
      type: Boolean,
      required: true,
      default: false
    },
    isChanging: {
      type: Boolean,
      required: true,
      default: false
    },
    isTitular: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  components: {
    CBox,
    CFlex,
    CStack,
    CDivider,
    CCheckbox,
    OmtHighlight,
    DeleteModal,
    AccountMainModal
  },
  data() {
    return {
      checkValue: false,
      isChangingMainAccount: false,
      showDeleteModal: false,
      showAccountMainModal: false
    };
  },
  methods: {
    upperCaseFirstLetter(str) {
      str = str?.trim();
      if (!str) return "";

      return str
        .split(" ")
        .map(s => s.charAt(0).toUpperCase() + s.slice(1))
        .join(" ");
    },
    emitChange() {
      this.isChangingMainAccount = true;
    },
    selectMainAccount(account) {
      this.isChangingMainAccount = false;
      this.$emit("select", account);
    },
    handleDelete() {
      this.showDeleteModal = true;
    },
    emitDelete() {
      this.$emit("delete", this.account);
    }
  },
  computed: {
    banco() {
      return this.upperCaseFirstLetter(this.account.ds_banco);
    },
    agencia() {
      return this.account.cd_agencia?.trim();
    },
    conta() {
      return this.account.nr_conta?.trim();
    },
    digito() {
      return this.account.at_dac?.trim();
    },
    accountInfos() {
      return {
        Banco: this.banco,
        Agência: this.agencia,
        Conta: this.conta,
        Dígito: this.digito
      };
    }
  }
};
</script>

<style scoped lang="scss">
@mixin action-effect {
  cursor: pointer;
  transition: opacity ease 0.5s;
  &:hover {
    opacity: 0.6;
  }
}

.fa-check-circle {
  color: #00c37d;
}

.fa-info-circle {
  color: #a08e60;
}

.fa-info {
  border-radius: 50%;
  outline: 4px solid currentColor;
  padding: 10px;
  width: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.5);
  cursor: pointer;
  transition: opacity ease 0.5s;
  &:hover {
    opacity: 0.6;
  }
}

.fa-trash-alt {
  @include action-effect;
}

.dark.page {
  .account-card {
    background-color: #1f1f1f;
    color: white;
  }
}

.account-card {
  padding: 16px;
  background-color: white;
  box-shadow: 0px 0px 16px 2px #00000026;

  &_title {
    margin-bottom: var(--space-5);
    p {
      font-weight: 700;
    }
  }

  &_value {
    width: 100px;
    margin-right: 16px;
    min-width: 100px;
    p {
      font-weight: 600;
    }
  }

  &_actions {
    i {
      margin-right: var(--space-3);
    }
  }

  &__change-label {
    @include action-effect;
  }
}

.text-danger {
  color: #e80606 !important;
}

p {
  margin: 0;
}

.checkbox {
  margin: 0 13.2px 0 0;

  ::v-deep input[type="checkbox"] + div[data-chakra-component="CControlBox"] {
    svg {
      color: white;
    }
  }

  ::v-deep input[type="checkbox"]:checked + div[data-chakra-component="CControlBox"] {
    background-color: white;

    svg {
      background-color: #102a46;
      border-radius: 50%;
      height: 8px;
      width: 8px;
      color: var(--colors-light-primary-gray1);
    }
  }

  ::v-deep div[data-chakra-component="CControlBox"] {
    border-radius: 50% !important;
    border-color: #102a46;
    color: var(--colors-light-primary-gray1);
    background: white;
    height: var(--sizes-3);
    width: var(--sizes-3);
  }
}
</style>
