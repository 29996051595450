<template>
  <CBox>
    <OmtTypography as="p1" mb="16px">
      Precisamos de informações complementares para continuarmos a análise da solicitação de reembolso.
    </OmtTypography>

    <CFlex backgroundColor="var(--colors-light-primary-light-gray)" borderRadius="8px" padding="16px" mb="24px">
      <CBox>
        <OmtIcons name="circle-exclamation" size="sm" mr="2" color="var(--colors-light-support-warning)" />
      </CBox>
      <CBox>
        <OmtTypography as="p2">
          <b>Importante</b>: O prazo para envio das informações é até <b>{{ dueDate }}</b> . Após essa data esta
          solicitação de reembolso será <b>automaticamente cancelada</b>.
        </OmtTypography>
      </CBox>
    </CFlex>

    <OmtTypography as="h2-bold" mb="20px" v-show="!!question.text"> {{ question.text }} </OmtTypography>

    <OmtRadioGroup
      v-if="question.options && question.options.length"
      hasDivider
      :values="question.options"
      :value.sync="selectedRadio"
      customValueKey="id"
    />
  </CBox>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      default: () => ({
        text: "",
        options: []
      })
    },
    dueDate: String
  },
  data() {
    return {
      selectedRadio: null
    };
  },
  watch: {
    selectedRadio(val) {
      this.$emit("change", val);
    }
  }
};
</script>
