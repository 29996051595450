<template>
  <CFlex justify="space-between" width="100%" data-cy="refund-status-tile">
    <CFlex align="center" width="90%" :maxWidth="['calc(100% - 100px)']">
      <CPseudoBox :bg="color" w="8px" h="8px" borderRadius="50%" mr="8px" />
      <OmtTypography as="p1" mr="8px" :maxWidth="['calc(100% - 30px)']">
        {{ label }}
      </OmtTypography>
    </CFlex>
  </CFlex>
</template>

<script>
/**
 * @prop { String } color
 * @prop { String } label
 */
export default {
  props: {
    color: {
      type: String
    },
    label: {
      type: String
    }
  }
};
</script>
