<template>
  <CBox mb="16px" position="relative">
    <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">{{ label }}</OmtTypography>
    <OmtInput
      v-model="model.$model"
      data-cy="login"
      autocapitalize="none"
      :type="password && !hidden ? 'password' : 'text'"
      px="2"
    />
    <OmtIcons
      v-if="password"
      size="lg"
      :name="hidden ? 'eye-slash' : 'eye'"
      color="var(--colors-light-primary-primary-gray2)"
      position="absolute"
      right=".5rem"
      bottom=".5rem"
      @click.prevent="hidden = !hidden"
    />
  </CBox>
</template>

<script>
export default {
  name: "PasswordInputField",
  props: ["label", "model", "password"],
  data: function () {
    return {
      hidden: false
    };
  }
};
</script>
