<template>
  <omint-page>
    <Header name="Check List COVID19" titleLabel="Check List COVID19" />
    <omint-slider>
      <router-view />
    </omint-slider>
    <Footer />
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";

export default {
  name: "Forms",
  components: {
    Header,
    Footer
  }
};
</script>
