import ScreeningProgram from "@/pages/screening-program/index.vue";
import ScreeningProgramTerms from "@/pages/screening-program/terms.vue";

const routes: Route[] = [
  {
    path: "/screening-program",
    alias: "/termo-consentimento",
    component: ScreeningProgram,
    children: [
      {
        path: "",
        name: "screening.terms",
        component: ScreeningProgramTerms,
        meta: {
          checkPathDisabled: true,
          routerSequence: 0
        }
      }
    ]
  }
];

export default routes;
