import MyRequests from "@/pages/my-requests/index.vue";
import ListRequests from "@/pages/my-requests/list.vue";
import CreateRequest from "@/pages/my-requests/create.vue";
import UploadFileRequest from "@/pages/my-requests/upload-file.vue";
import ObservationRequest from "@/pages/my-requests/observation.vue";
import DetailRequest from "@/pages/my-requests/detail.vue";
import AuthMiddleware from "@/pages/auth-middleware/index.vue";

const routes: Route[] = [
  {
    path: "/solicitacoes",
    component: MyRequests,
    children: [
      {
        path: "",
        name: "list.requests",
        component: ListRequests,
        meta: {
          validPath: ["/solicitacoes", "/atendimento-lista"]
        }
      },
      {
        path: "detalhe/:protocol",
        name: "detail.request",
        component: DetailRequest,
        meta: {
          backTo: "/solicitacoes",
          validPath: ["/solicitacoes", "/atendimento-lista"]
        }
      },
      {
        path: "criar",
        name: "create.request",
        component: CreateRequest,
        meta: {
          backTo: "/solicitacoes",
          registerAccess: { servico: "Minhas Solicitacoes", funcionalidade: "Acesso a solicitação" },
          validPath: ["/solicitacoes", "/atendimento-lista"]
        }
      },
      {
        path: "upload",
        name: "request.upload.documents",
        component: UploadFileRequest,
        meta: {
          backTo: "/solicitacoes/criar",
          validPath: ["/solicitacoes", "/atendimento-lista"]
        }
      },
      {
        path: "observacao",
        component: AuthMiddleware,
        children: [
          {
            path: "",
            name: "request.observation",
            component: ObservationRequest,
            meta: {
              auth_service: "request/getAuthorizationService",
              backTo: "/solicitacoes/upload",
              save_auth_mutation: "request/SET_AUTHORIZATION_ID",
              validPath: ["/solicitacoes", "/atendimento-lista"]
            }
          }
        ]
      }
    ]
  }
];

export default routes;
