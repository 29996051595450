import Forms from "@/pages/forms/index.vue";
import FormsCovid19 from "@/pages/forms/covid19.vue";
import FormsScheduleCovid19 from "@/pages/forms/schedule-covid19.vue";
import FormsNewScheduleCovid19 from "@/pages/forms/new-schedule-covid19.vue";

const routes: Route[] = [
  {
    path: "/forms",
    alias: "/redireciona-volta-ao-trabalho",
    component: Forms,
    children: [
      {
        path: "",
        name: "forms.covid19",
        component: FormsCovid19,
        meta: {
          backTo: "/",
          routerSequence: 0,
          validPath: ["/redireciona-volta-ao-trabalho"]
        }
      },
      {
        path: "covid19/schedule",
        name: "agenda.covid19",
        component: FormsScheduleCovid19,
        meta: {
          routerSequence: 1,
          validPath: ["/redireciona-volta-ao-trabalho"]
        }
      },
      {
        path: "covid19/newschedule",
        name: "nova.agenda.covid19",
        component: FormsNewScheduleCovid19,
        meta: {
          routerSequence: 2,
          validPath: ["/redireciona-volta-ao-trabalho"]
        }
      }
    ]
  }
];

export default routes;
