<template>
  <OmtLayoutHeaderFooter :footer="false" :header="{ titleLabel: 'Autenticação por biometria' }">
    <OmtGrid>
      <OmtRow mb="24px">
        <OmtIcons name="face-viewfinder" size="3x" color="var(--colors-light-primary-navy-blue)" />
      </OmtRow>

      <OmtRow mb="24px">
        <OmtTypography as="h1-bold"> Validação de biometria </OmtTypography>
      </OmtRow>

      <OmtRow mb="24px">
        <BiometryAuthCard />
      </OmtRow>

      <OmtRow mb="32px">
        <BiometryCameraButton
          :disabled="disabledButton"
          @success="handleBiometryCameraSuccess"
          @error="handleBiometryCameraError"
          @resourceError="handleResourceError"
          @startLoading="$root.$emit('show-loading')"
          @endLoading="$root.$emit('hide-loading')"
          buttonLabel="Click para validar autenticação"
          :confirmUser="true"
        />
      </OmtRow>
    </OmtGrid>
  </OmtLayoutHeaderFooter>
</template>

<script>
import BiometryCameraButton from "@/components/Organisms/Biometry/BiometryCameraButton";
import { mapActions } from "vuex";
import BiometryAuthCard from "@/components/Organisms/Biometry/BiometryAuthCard";

export default {
  components: {
    BiometryCameraButton,
    BiometryAuthCard
  },
  data() {
    return {
      disabledButton: true
    };
  },
  async mounted() {
    try {
      const data = await this.fetchFaceStatus();
      if (!data?.Gerado) {
        this.showErrorMessage("O cadastro de biometria não foi realizado");
        return;
      }
      if (!data?.Aceito) {
        this.showErrorMessage("O cadastro de biometria ainda está em análise");
        return;
      }
      this.disabledButton = false;
    } catch (error) {
      this.handleError(error);
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  methods: {
    ...mapActions("biometrics", ["fetchFaceStatus", "authFace"]),
    async handleBiometryCameraSuccess(payload) {
      try {
        const { encrypted } = payload;
        await this.$root.$emit("show-loading");
        const data = await this.authFace({ service: "/refund/form", selfie: encrypted });
        if (data?.MensagemErro) {
          this.showErrorMessage(data.MensagemErro);
          return;
        }
        if (data?.Autorizado) {
          this.$toast({
            description: "Autenticação realizada com sucesso",
            status: "success",
            duration: 10000,
            position: "top"
          });
          return;
        }

        this.showErrorMessage("Não foi possível realizar a autenticação");
      } catch (error) {
        this.handleError(error);
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    handleError(error) {
      window.$log.error("biometry page", error);
      this.showErrorMessage("Ocorreu um erro ao obter a biometria");
    },
    showErrorMessage(message) {
      this.$toast({
        description: message ?? "Ocorreu um erro ao obter a biometria",
        status: "error",
        duration: 10000,
        position: "top"
      });
    },
    handleResourceError(error) {
      this.$toast({
        description: "Ocorreu um erro ao iniciar a câmera",
        status: "error",
        duration: 10000,
        position: "top"
      });
      window.$log.error("biometry init", error);
    },
    handleBiometryCameraError(error) {
      window.$log.error("biometry camera", error);
    }
  }
};
</script>
