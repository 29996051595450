<template>
  <CBox :bg="['none', '#F6F6F9']" minHeight="100vh" width="100vw" position="sticky">
    <Header titleLabel="Editar atalhos" />
    <OmtGrid v-if="loaded">
      <OmtRow>
        <CBox background-color="white">
          <CBox w="100%">
            <OmtTypography as="h2-bold" mb="45px"> Atalhos ativos </OmtTypography>
            <draggable
              v-model="listShortcutsActive"
              group="shortcuts"
              @start="drag = true"
              @end="(drag = false), changeAllList()"
              :name="!drag ? 'flip-list' : null"
              handle=".tracker-container"
              v-bind="dragOptions"
            >
              <transition-group>
                <CFlex
                  v-for="element of listShortcutsActive"
                  :key="'active' + element.id"
                  borderBottom="0.5px solid #DADADA"
                  mb="10px"
                  pb="10px"
                >
                  <CBox>
                    <OmtSwitch
                      :initialValue="element.active"
                      :disabled="element.view === '/edit-shortcuts'"
                      @change="changeList(element.id, 'desactivate')"
                    />
                  </CBox>
                  <CBox w="90%">
                    <OmtTypography as="h2-regular" ml="10px">{{ element.name }}</OmtTypography>
                  </CBox>
                  <CFlex justifyContent="center" alignItems="center">
                    <div class="tracker-container">
                      <div class="tracker"></div>
                    </div>
                  </CFlex>
                </CFlex>
              </transition-group>
            </draggable>
          </CBox>
          <CBox mt="50px" w="100%" :mb="['66px', '0']">
            <OmtTypography as="h2-bold" mb="45px"> Atalhos desativados </OmtTypography>
            <draggable
              v-model="listShortcuts"
              group="shortcuts"
              @start="drag = true"
              @end="(drag = false), changeAllList()"
              handle=".tracker-container"
              :name="!drag ? 'flip-list' : null"
              v-bind="dragOptions"
            >
              <transition-group>
                <CFlex
                  v-for="(element, id) of listShortcuts"
                  :key="'desactive' + element.id"
                  :borderBottom="listShortcuts.length - 1 === id ? 'none' : '0.5px solid #DADADA'"
                  mb="10px"
                  pb="10px"
                >
                  <CBox><OmtSwitch :initialValue="element.active" @change="changeList(element.id, 'active')" /></CBox>
                  <CBox w="90%">
                    <OmtTypography as="h2-regular" ml="10px">{{ element.name }}</OmtTypography>
                  </CBox>
                  <CBox>
                    <CFlex justifyContent="center" alignItems="center">
                      <div class="tracker-container">
                        <div class="tracker"></div>
                      </div>
                    </CFlex>
                  </CBox>
                </CFlex>
              </transition-group>
            </draggable>
          </CBox>
        </CBox>
      </OmtRow>
      <OmtRow
        justifyContent="center"
        :position="['fixed', 'relative']"
        :bottom="['66px', 'unset']"
        left="0"
        :p="['16px']"
        w="100%"
        background-color="white"
      >
        <OmtButton
          pt="16px"
          pb="16px"
          fontSize="14px"
          :w="['100%', '50% !important']"
          :isDisabled="disableButton"
          @click="saveMenus()"
          mx="auto"
        >
          Salvar
        </OmtButton>
      </OmtRow>
    </OmtGrid>
    <Footer />
  </CBox>
</template>

<script>
import Header from "@/components/Molecules/Header";
import { mapState, mapActions } from "vuex";
import draggable from "vuedraggable";
import Footer from "@/components/Molecules/Footer";

export default {
  components: {
    Header,
    draggable,
    Footer
  },
  data() {
    return {
      loaded: false,
      listShortcutsActive: [],
      listShortcuts: [],
      drag: false
    };
  },
  computed: {
    ...mapState("saude", ["menu", "menusFavoritos"]),
    disableButton() {
      return !this.listShortcutsActive?.find(s => s.active);
    },
    dragOptions() {
      return {
        animation: 200,
        group: "shortcuts",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    ...mapActions("saude", ["fetchFavoriteMenus", "saveFavoriteMenus"]),
    changeList(id, type) {
      if (type === "active") {
        let index = this.listShortcuts.findIndex(x => x.id === id);
        if (index !== -1) {
          let short = this.listShortcuts.splice(index, 1)[0];
          if (short.view !== "/edit-shortcuts") {
            this.listShortcutsActive.push(short);
          }
        }
      } else if (type === "desactivate") {
        let index = this.listShortcutsActive.findIndex(x => x.id === id);
        if (index !== -1) {
          let short = this.listShortcutsActive.splice(index, 1)[0];
          this.listShortcuts.unshift(short);
        }
      }
      this.changeAllList();
    },
    changeAllList() {
      const editShortcuts = this.listShortcuts.find(s => s.view === "/edit-shortcuts");
      this.listShortcuts = this.listShortcuts
        .filter(s => s.view !== "/edit-shortcuts")
        .map(x => ({ ...x, active: false }));
      const listShortcutsActive = this.listShortcutsActive.map(x => ({ ...x, active: true }));
      if (editShortcuts) {
        listShortcutsActive.push({ ...editShortcuts, active: true });
      }
      this.listShortcutsActive = listShortcutsActive;
    },
    async montarOpcoesAtalhos() {
      try {
        await this.$root.$emit("show-loading");
        this.listShortcutsActive = this.menusFavoritos.map(m => ({
          id: m.idMenuApp,
          icon: m.Icon,
          name: m.Label,
          view: m.View,
          active: true
        }));
        if (!this.listShortcutsActive.find(s => s.id > 0)) {
          this.listShortcutsActive.forEach(s => (s.id = this.menu.find(m => m.View === s.view)?.IdMenuApp ?? 0));
          this.listShortcutsActive.filter(s => s.id > 0);
        }
        this.listShortcuts = this.menu
          .filter(x => !x.Desktop && !this.listShortcutsActive.find(y => y.id === x.IdMenuApp))
          .map(m => ({
            id: m.IdMenuApp,
            icon: m.Icon,
            name: m.Label,
            view: m.View,
            active: false
          }));
        this.loaded = true;
      } catch (error) {
        console.error(error);
      } finally {
        this.$root.$emit("hide-loading");
      }
    },
    async saveMenus() {
      if (this.disableButton) return;
      await this.$root.$emit("show-loading");
      const menus = this.listShortcutsActive.map((x, i) => ({
        id_MenuApp: x.id,
        id_OrdemMenu: i
      }));
      try {
        await this.saveFavoriteMenus({ menus });
        await this.fetchFavoriteMenus({ retornarAtalhos: true });
        await this.montarOpcoesAtalhos();
        this.$root.$emit("hide-loading");
        this.$toast({
          description: "Menus salvos com sucesso",
          status: "success",
          duration: 10000,
          position: "top"
        });
      } catch (e) {
        this.$root.$emit("hide-loading");
        this.$toast({
          description: "Ocorreu um erro ao salvar os menus",
          status: "error",
          duration: 10000,
          position: "top"
        });
      }
    }
  },
  mounted() {
    this.montarOpcoesAtalhos();
  }
};
</script>
<style>
.flip-list-move {
  transition: transform 0.5s;
}

.tracker-container {
  width: 26px;
  height: 13px;
  cursor: pointer;
}

.tracker {
  height: 2px;
  width: 26px;
  background-color: black;
  border-radius: 6px;
  position: relative;
}

.tracker::before {
  content: "";
  position: absolute;
  inset: 0;
  top: 5px;
  height: 2px;
  background-color: black;
  border-radius: 6px;
}

.tracker::after {
  content: "";
  position: absolute;
  inset: 0;
  top: 10px;
  height: 2px;
  background-color: black;
  border-radius: 6px;
}
</style>
