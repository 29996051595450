const navyBlue = "#172972";

export default {
  light: {
    primary: {
      "dark-blue": "#102A46",
      gray1: "#4E4E4E",
      gray2: "#8C8C8C",
      disabled: "#586F8B",
      "navy-blue": navyBlue,
      "light-gray": "#F6F6F9"
    },
    secondary: {
      gold: "#A08E60",
      divider: "#DADADA",
      disabled: "#F5F5F5",
      "disabled-placeholder": "#586F8B40",
      "dark-gold": "#968B83",
      "light-blue": "#8A93B9"
    },
    support: {
      success: "#00C37D",
      warning: "#FBC246",
      error: "#C31700",
      news: "#1CA0FF"
    }
  },
  blue: {
    500: navyBlue
  }
};
