var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CPseudoBox',_vm._b({attrs:{"data-cy":"omt-image-previewer"}},'CPseudoBox',Object.assign({}, Object.assign({}, (!!_vm.previewOnClick && {
        cursor: 'pointer'
      }),
      (!!_vm.hasBorder
        ? {
            bg: 'var(--colors-light-primary-light-gray)',
            p: '9px',
            borderRadius: '8px',
            maxWidth: [_vm.getMobileSize + 18 + 'px', !_vm.onlyMobileSize && '148px'],
            minWidth: [_vm.getMobileSize + 18 + 'px', !_vm.onlyMobileSize && '148px']
          }
        : {
            maxWidth: [_vm.getMobileSize + 'px', !_vm.onlyMobileSize && '148px'],
            minWidth: [_vm.getMobileSize + 'px', !_vm.onlyMobileSize && '148px']
          }))),false),[_c('CBox',{on:{"click":function($event){return _vm.triggerModal(true)}}},[_c('CImage',_vm._b({attrs:{"w":"100%","borderRadius":"8px","h":[_vm.getMobileSize + 'px', !_vm.onlyMobileSize && '130px'],"src":_vm.source}},'CImage',_vm.$attrs,false))],1),(!!_vm.previewOnClick && _vm.showPictureModal)?_c('OmtStickedModal',{on:{"close":function($event){return _vm.triggerModal(false)}}},[_c('CBox',{attrs:{"p":"0 16px","min-height":"80vh"}},[_c('CImage',{attrs:{"data-cy":"image-preview-modal","src":_vm.sourceModal,"max-height":"100vh","max-width":"100%"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }