<template>
  <omint-page>
    <Header />
    <omint-page-content :message="error">
      <Credential
        v-if="post"
        class="box"
        :idx="-1"
        :id="post.id"
        :number="post.number"
        :name="post.name"
        :ingress="post.ingress"
        :valid="post.valid"
        :birth="post.birth"
        :plan="post.plan"
        :bg="post.bg"
        :credType="post.credType"
        :cns="post.cns"
        :disableToken="true"
        :numberT="post.numberT"
        :planT="post.planT"
        :accT="post.accT"
        :accTp="post.accTp"
        :abrT="post.abrT"
        :cssMediaType="''"
      />
    </omint-page-content>
    <Footer />
  </omint-page>
</template>

<script>
import { mapGetters } from "vuex";
import { ObterCredencialEletronicaCompartilhada } from "@/api/associado";
import Credential from "@/components/Organisms/Credential.vue";
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";

export default {
  components: { Credential, Header, Footer },
  name: "SharedCredential",
  data() {
    return {
      post: null,
      error: null
    };
  },
  computed: {
    ...mapGetters("saude", ["baseUrl"])
  },
  created() {
    this.$root.$emit("show-loading");
    this.fetchData();
  },
  watch: {
    $route: "fetchData"
  },
  methods: {
    async fetchData() {
      try {
        const sharedUrl = `${this.baseUrl}/shared/`;
        const chave = document.location.pathname.substr(sharedUrl.length);
        const resp = await ObterCredencialEletronicaCompartilhada({ chave });
        if (resp.Data !== undefined) {
          let c = resp.Data[0];
          if (c.BeneficiarioPlanoMedicina > 0) {
            let credTypeMedicina = "ouro";
            if (c.CodigoCredencialMedicina === "P") {
              credTypeMedicina = "prata";
            } else if (c.CodigoCredencialMedicina === "S") {
              credTypeMedicina = "azul";
            }
            this.post = {
              id: `med 0`,
              cns: c.NumeroCns,
              birth: new Date(c.DataNascimento).formatDDMMYYYY(),
              bg: c.CaminhoImagemCredenciaMedicina,
              plan: c.CodigoPlanoMedicina,
              valid: new Date(c.DataFimCredencialMedicina).formatDDMMYYYY(),
              ingress: new Date(c.DataIngresso).formatDDMMYYYY(),
              number: c.NumeroCredenciaMedicina,
              name: c.NomeBeneficiario,
              credType: credTypeMedicina,
              numberT: null
            };
            return;
          }

          if (c.BeneficiarioPlanoOdonto > 0) {
            let credTypeOdonto = "ouro";
            if (c.CodigoCredencialOdonto === "P") {
              credTypeOdonto = "prata";
            } else if (c.CodigoCredencialOdonto === "S") {
              credTypeOdonto = "azul";
            } else if (c.CodigoCredencialOdonto === "O") {
              credTypeOdonto = "azul";
            }
            this.post = {
              id: `odn 0`,
              cns: c.NumeroCns,
              birth: new Date(c.DataNascimento).formatDDMMYYYY(),
              bg: c.CaminhoImagemCredenciaOdonto,
              plan: c.CodigoPlanoOdonto,
              valid: new Date(c.DataFimCredencialOdonto).formatDDMMYYYY(),
              ingress: new Date(c.DataIngresso).formatDDMMYYYY(),
              number: c.NumeroCredenciaOdonto,
              name: c.NomeBeneficiario,
              credType: credTypeOdonto,
              numberT: null
            };
            return;
          }

          if (c.NumeroTerceira !== null) {
            let credTypeTerceira = "ouro";
            if (c.CodigoCredencialMedicina === "P") {
              credTypeTerceira = "prata";
            } else if (c.CodigoCredencialMedicina === "S") {
              credTypeTerceira = "azul";
            }
            this.post = {
              id: `mdsv 0`,
              cns: c.NumeroCns,
              birth: new Date(c.DataNascimento).formatDDMMYYYY(),
              bg: c.CaminhoImagemCredenciaTerc,
              plan: c.IdPlanoTerceira,
              valid: new Date(c.DataFimCredencialMedicina).formatDDMMYYYY(),
              ingress: new Date(c.DataIngresso).formatDDMMYYYY(),
              number: c.NumeroCredenciaMedicina,
              name: c.NomeBeneficiario,
              credType: credTypeTerceira,
              numberT: c.NumeroTerceira,
              planT: c.PlanoTerceira,
              accT: c.AcomodacaoTerceira,
              accTp: c.TpAssistencia,
              abrT: c.AbrangenciaTerceira
            };
          }
        } else if (resp.message) {
          this.error = resp.message;
        }
      } catch (error) {
        this.error = "Não foi possível obter a credencial";
      } finally {
        await this.$root.$emit("hide-loading");
      }
    }
  }
};
</script>
<style scoped></style>
