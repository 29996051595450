<template>
  <CBox background-color="white" data-cy="refund-list-filters-header">
    <CFlex data-cy="filters-buttons" justify-content="space-between" padding="24px 16px" margin="-16px -16px 0px -16px">
      <CFlex>
        <OmtButton
          mr="16px"
          size="sm"
          :variant="filtersHasChanges || hasActivedFilter ? 'solid' : 'outline'"
          leftIcon="sliders-h"
          :iconColor="filtersHasChanges || hasActivedFilter ? 'white' : 'var(--colors-light-primary-gray1)'"
          iconSize="sm"
          border=".5px solid var(--colors-light-secondary-divider)"
          @click="$emit('update:isOpen', true)"
        >
          <OmtTypography
            as="button-small"
            :color="filtersHasChanges || hasActivedFilter ? 'white' : 'var(--colors-light-primary-gray1)'"
          >
            Filtrar
          </OmtTypography>
        </OmtButton>

        <slot />
      </CFlex>

      <OmtButton
        data-cy="clean-filters-button"
        variant="ghost"
        size="sm"
        :disabled="!activedFilters.length && !activedSort"
        @click="onClean"
      >
        <OmtTypography as="button-small" color="var(--colors-light-primary-gray1)"> Limpar </OmtTypography>
      </OmtButton>
    </CFlex>

    <OmtSwiper ref="OmtSwiper" data-cy="applied-filters-swiper" :spaceBetween="12">
      <OmtSwiperSlide v-for="(filter, index) in activedFilters" :key="index" width="auto">
        <OmtButton
          size="xs"
          bg="var(--colors-light-primary-light-gray)"
          rightIcon="times"
          iconSize="xs"
          @click="removeFilter(filter.label)"
        >
          <OmtTypography color="var(--colors-light-primary-gray1)" as="p2">
            {{ filter.label }}
          </OmtTypography>
        </OmtButton>
      </OmtSwiperSlide>
    </OmtSwiper>
  </CBox>
</template>

<script>
import { mapGetters, mapState } from "vuex";

/**
 * @prop { Boolean } isOpen
 */
export default {
  props: {
    isOpen: {
      type: Boolean
    },
    filtersHasChanges: {
      type: Boolean
    },
    activedSort: {
      type: String
    }
  },
  data() {
    return {
      headerFilters: []
    };
  },
  methods: {
    removeFilter(label) {
      this.headerFilters.forEach(filter => {
        if (filter.label === label) filter.isActive = !filter.isActive;
      });

      this.$emit("change", this.headerFilters);
    },
    onClean() {
      if (this.activedFilters.length > 0) {
        this.headerFilters.forEach(f => (f.isActive = false));
        this.$emit("change", this.headerFilters);
      }

      this.$emit("cleanSorts", "");
    }
  },
  watch: {
    filters(newValues) {
      this.headerFilters = JSON.parse(JSON.stringify(newValues));
    }
  },
  computed: {
    ...mapGetters("refund", ["hasActivedFilter"]),
    ...mapState("refund", ["filters"]),
    activedFilters() {
      return this.headerFilters.filter(filter => !!filter.isActive);
    }
  }
};
</script>
