import CopayExtract from "@/pages/copay/extract/index.vue";
import CopayExtractList from "@/pages/copay/extract/list.vue";
import CopayExtractDetail from "@/pages/copay/extract/detail.vue";

const routes: Route[] = [
  {
    path: "/copay/extrato",
    component: CopayExtract,
    children: [
      {
        path: "",
        name: "copay.extrato",
        component: CopayExtractList,
        meta: {
          backTo: "/copay",
          validPath: ["/copay"]
        }
      },
      {
        path: "detalhe/:id?",
        name: "copay.extrato.detalhe",
        component: CopayExtractDetail,
        meta: {
          backTo: "/copay/extrato",
          validPath: ["/copay/extrato"]
        }
      }
    ]
  }
];

export default routes;
