<template>
  <CBox bg="var(--colors-light-primary-light-gray)" p="32px" mt="24px" borderRadius="8px">
    <CFlex v-if="titular">
      <OmtIcons
        name="home"
        height="16px"
        width="16px"
        size="lg"
        mr="16px"
        color="var(--colors-light-primary-navy-blue)"
      />
      <CBox>
        <OmtTypography as="h3-bold" mb="12px"> Endereço residencial </OmtTypography>
        <OmtTypography as="p2" mb="8px" color="var(--colors-light-primary-gray2)"> {{ endereco }} </OmtTypography>
        <OmtTypography as="p2" mb="16px" color="var(--colors-light-primary-gray2)"> {{ bairro }} </OmtTypography>
      </CBox>
    </CFlex>
    <CFlex>
      <OmtIcons
        name="envelope"
        height="16px"
        width="16px"
        size="lg"
        mr="16px"
        color="var(--colors-light-primary-navy-blue)"
      />
      <CBox>
        <OmtTypography as="h3-bold" mb="12px" mt="8px"> E-mail </OmtTypography>
        <OmtTypography as="p2" mb="16px" color="var(--colors-light-primary-gray2)"> {{ email }} </OmtTypography>
      </CBox>
    </CFlex>
    <CFlex>
      <OmtIcons
        name="phone"
        height="16px"
        width="16px"
        size="lg"
        mr="16px"
        color="var(--colors-light-primary-navy-blue)"
      />
      <CBox>
        <OmtTypography as="h3-bold" mb="12px" mt="8px"> Telefone </OmtTypography>
        <OmtTypography
          v-for="(f, index) in telefones"
          :key="index"
          as="p2"
          mb="8px"
          color="var(--colors-light-primary-gray2)"
        >
          <CFlex>
            {{ f.numero }}
            <OmtIcons
              name="whatsapp"
              height="16px"
              width="16px"
              size="sm"
              color="var(--colors-light-support-success)"
              ml="8px"
              v-if="f.whatsapp"
            />
          </CFlex>
        </OmtTypography>
      </CBox>
    </CFlex>
  </CBox>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState("saude", ["titular"]),
    ...mapState("myContacts", ["resumeContacts", "existChangeRequest"]),
    ...mapState("atendimento", ["WhatsAppAtivo", "NumeroFormatado", "DDD", "DDI"]),
    endereco() {
      if (!this.resumeContacts?.Endereco) return "Não cadastrado";
      const end = this.resumeContacts.Endereco;
      const numero = end.Numero ? `, ${end.Numero}` : " ";
      const complemento = end.Complemento ? ` - ${end.Complemento}` : " ";
      return `${end.TipoLogradouro} ${end.Logradouro}${numero}${complemento}`;
    },
    bairro() {
      if (!this.resumeContacts?.Endereco) return "";
      return this.resumeContacts.Endereco.Bairro ? `Bairro ${this.resumeContacts.Endereco.Bairro}` : "";
    },
    telefones() {
      const whatsapp = this.WhatsAppAtivo
        ? `+${(this.DDI || 0).zeroLeft()} (${(this.DDD || 0).zeroLeft()}) ${this.NumeroFormatado}`
        : "";
      let fones = [];
      if (this.resumeContacts?.Telefones?.length) {
        fones = this.resumeContacts.Telefones.map(t => {
          const numero = `${t.countryCode} ${t.Numero}`;
          return {
            numero,
            whatsapp: numero === whatsapp
          };
        });
      }
      if (this.WhatsAppAtivo && !fones.find(t => t.whatsapp))
        fones = [
          {
            numero: whatsapp,
            whatsapp: true
          },
          ...fones
        ];
      if (!fones.length) return [{ numero: "Não identificamos nenhum número cadastrado" }];
      return fones.slice(0, 3);
    },
    email() {
      if (!this.resumeContacts?.Emails?.Pessoal) return "Não identificamos nenhum email cadastrado";
      return this.resumeContacts.Emails.Pessoal;
    },
    fatura() {
      if (!this.titular) return "";
      if (!(this.resumeContacts?.Empresarial ?? true)) return "";
      return this.resumeContacts?.Emails?.Fatura;
    }
  },
  methods: {
    ...mapActions("myContacts", ["validateActiveTicket", "fetchResumeContacts"]),
    ...mapActions("atendimento", ["validarAtendimentoWhatsApp"])
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      if (
        !this.resumeContacts?.Endereco &&
        !this.resumeContacts.Telefones?.length &&
        !this.resumeContacts?.Emails?.Pessoal
      ) {
        await Promise.all([this.fetchResumeContacts(), this.validateActiveTicket(), this.validarAtendimentoWhatsApp()]);
        if (this.existChangeRequest) {
          // não pode editar pois já existe um pedido de alteração
          this.$root.$emit("change-welcome-tour", { accessLabel: "", nextLabel: "Continuar" });
        }
      }
    } catch (error) {
      this.$toast({
        description: error.message,
        status: "error",
        duration: 10000,
        position: "bottom-right"
      });
    } finally {
      this.$root.$emit("hide-loading");
    }
  }
};
</script>
<style scoped></style>
