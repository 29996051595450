<template>
  <div class="dropdown__box" v-if="options">
    <!-- Dropdown Input -->
    <input
      class="dropdown__input"
      :name="name"
      @focus="showOptions()"
      @blur="exit()"
      @keyup="keyMonitor"
      v-model="searchFilter"
      :disabled="disabled"
      :placeholder="placeholder"
      autocomplete="no"
      v-on:blur="onBlur"
    />

    <!-- Dropdown Options -->
    <div class="dropdown__content" v-show="optionsShown">
      <div
        class="dropdown__item"
        @mousedown="selectOption(option)"
        v-for="(option, index) in filteredOptions"
        :key="index"
      >
        {{ option.name || option.id || "-" }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  template: "Dropdown",
  props: {
    name: {
      type: String,
      required: false,
      default: "dropdown",
      note: "Input name"
    },
    options: {
      type: Array,
      required: true,
      note: "Options of dropdown. An array of options with id and name"
    },
    placeholder: {
      type: String,
      required: false,
      default: "Please select an option",
      note: "Placeholder of dropdown"
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: "Disable the dropdown"
    },
    onBlur: {
      type: Function,
      default: () => {
        return;
      }
    },
    maxItem: {
      type: Number,
      required: false,
      default: 6,
      note: "Max items showing"
    }
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: ""
    };
  },
  created() {
    this.$emit("selected", this.selected.id || "");
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      const regOption = new RegExp(this.searchFilter, "ig");
      for (const option of this.options) {
        if (this.searchFilter.length < 1 || option.name.match(regOption)) {
          if (filtered.length < this.maxItem) filtered.push(option);
        }
      }
      return filtered;
    }
  },
  methods: {
    selectOption(option, auto) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = option.name;
      if (auto) {
        return this.$emit("selected_auto", option.id);
      }

      this.$emit("selected", option.id);
    },
    showOptions() {
      if (!this.disabled) {
        this.searchFilter = "";
        this.optionsShown = true;
      }
    },
    exit() {
      if (!this.selected.id && this.selected.id !== 0) {
        this.selected = {};
        this.searchFilter = "";
      } else {
        this.searchFilter = this.selected.name;
        this.$emit("selected", this.selected.id);
      }
      this.optionsShown = false;
    },
    clear() {
      this.selected = {};
      this.optionsShown = false;
      this.searchFilter = "";
    },
    keyMonitor: function (event) {
      if (event.key === "Enter" && this.filteredOptions[0]) this.selectOption(this.filteredOptions[0]);
    }
  },
  watch: {
    searchFilter() {
      if (this.filteredOptions.length === 0) {
        this.selected = {};
      } else {
        this.selected = this.filteredOptions[0];
      }
      this.$emit("filter", this.searchFilter);
    }
  }
};
</script>

<style lang="scss" scoped>
input[disabled="true"] {
  opacity: 0.5;
}

input[aria-invalid="true"] {
  border-radius: 0px !important;
  border-bottom: solid 2px #ff5b32 !important;
}

.dropdown__box {
  position: relative;
  display: block;
  width: 100%;
  .dropdown__input {
    cursor: pointer;
    border-radius: 3px;
    padding: 6px 22px 6px 6px;
    min-width: 250px;
    width: 100%;
    background-color: transparent;
    transition: all 250ms ease;
    border: 0;
  }
  .dropdown__content {
    position: absolute;
    min-width: 248px;
    max-width: calc(100% - 2px);
    max-height: 248px;
    width: 100%;
    overflow: auto;
    z-index: 9999;
    .dropdown__item {
      padding: 8px;
      text-decoration: none;
      display: block;
      cursor: pointer;
    }
  }
  .dropdown__box:hover .dropdowncontent {
    display: block;
  }
}
.light {
  .dropdown__box {
    .dropdown__input {
      color: var(--colors-light-primary-gray1);
    }
    .dropdown__content {
      background-color: #fff;
      border: 1px solid #e7ecf5;
      box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
      .dropdown__item {
        color: var(--colors-light-primary-gray1);

        &:hover {
          background-color: #e7ecf5;
        }
      }
    }
  }
}
.dark {
  .dropdown__box {
    .dropdown__input {
      color: white;
    }
    .dropdown__content {
      background-color: black;
      border: 1px solid white;
      box-shadow: 0px -8px 34px 0px rgba(255, 255, 255, 0.05);
      .dropdown__item {
        color: white;
        &:hover {
          background-color: #818181;
        }
      }
    }
    .dropdown__box:hover .dropdowncontent {
      display: block;
    }
  }
}
</style>
