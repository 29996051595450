<template>
  <CBox v-bind="$attrs">
    <header class="header-page" data-cy="header">
      <OmtGrid
        bg="transparent !important"
        height="79px"
        my="0 !important"
        py="16px !important"
        :px="['16px !important', '0 !important']"
        align-items="center"
        class="header-grid"
      >
        <OmtRow
          v-if="!isHomePage && !!displayBackButton"
          @click.prevent="back"
          :desktopStart="1"
          :desktopEnd="1"
          :mobileStart="1"
          :mobileEnd="1"
        >
          <OmtIcons name="chevron-left" cursor="pointer" />
        </OmtRow>

        <OmtRow
          v-if="isHomePage || (!!showLogo && !title)"
          @click.prevent="back"
          :mobileStart="(isHomePage && 1) || (!!showLogo && !title && 2)"
          :mobileEnd="(isHomePage && 4) || (!!showLogo && !title && 3)"
          :desktopStart="(isHomePage && 1) || (!!showLogo && !title && 6)"
          :desktopEnd="(isHomePage && 3) || (!!showLogo && !title && 7)"
          :justify-content="['center', 'flex-start']"
          :display="(isHomePage && ['none !important', 'flex !important']) || []"
        >
          <CImage height="32px" width="116px !important" :src="logoImg" />
        </OmtRow>

        <OmtRow
          v-if="!!title && !isHomePage && (isLogged || allowAnonymous)"
          :desktopStart="2"
          :desktopEnd="11"
          :mobileStart="2"
          :mobileEnd="3"
          justify-content="center"
          align-items="center"
          class="box-title"
        >
          <OmtTypography
            text-align="center"
            display="block"
            w="auto !important"
            as="h2-bold"
            mb="0"
            class="label-title"
            v-if="!!title"
          >
            {{ title }}
          </OmtTypography>
        </OmtRow>

        <OmtRow
          v-if="isHomePage && isLogged"
          align-items="center"
          :mobileStart="1"
          :mobileEnd="1"
          :desktopEnd="isHomePage ? 10 : 11"
          :display="['flex', 'none !important']"
        >
          <CAvatar
            size="sm"
            :src="profileImage"
            width="40px !important"
            height="40px"
            cursor="pointer"
            @click="$emit('profilePicClick')"
          />
          <OmtTypography ml="18px" as="h1-bold" color="var(--colors-light-primary-navy-blue)" mb="0 !important">
            {{ profileName }}
          </OmtTypography>
        </OmtRow>

        <OmtRow
          v-if="isLogged"
          align-items="center"
          :desktopStart="isHomePage ? 11 : 12"
          :desktopEnd="12"
          :mobileStart="4"
          :mobileEnd="4"
          ml="auto"
        >
          <LibrasButton v-if="exibirMenu" ml="18px" />
          <NewsButton v-if="exibirNews" ml="18px" />
          <Drawer v-if="exibirMenu" v-chakra ml="18px" justify-content="flex-end" display="flex" w="auto !important" />
        </OmtRow>
      </OmtGrid>
    </header>
    <CBox height="80px"></CBox>
  </CBox>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Drawer from "@/components/Molecules/Drawer";
import NewsButton from "@/components/Molecules/NewsButton";
import LibrasButton from "@/components/Molecules/LibrasButton";
import equalweb from "@/components/Organisms/Equalweb/mixin.js";
import { ROTA_BASE, ROTA_OPTIN, ROTA_WELCOME_ONBOARD_TOUR, ROTA_AVISO_ATRASO } from "@/utils/perfilRouteUtil";
import { isLoggedIn } from "@/utils/authUtil";

export default {
  name: "Header",
  props: {
    name: {
      type: String
    },
    backRoute: {
      type: String
    },
    showLogo: {
      type: Boolean,
      default: false
    },
    titleLabel: {
      type: String,
      default: ""
    }
  },
  mixins: [equalweb],
  components: { Drawer, NewsButton, LibrasButton },
  computed: {
    ...mapGetters("saude", ["defaultRoute"]),
    ...mapState("saude", ["operacao", "perfil", "profile", "profileName", "route", "theme", "vinculo"]),
    ...mapState("myAccount", ["welcomeOnBoardTour", "welcomeOnBoardTourStep"]),
    exibirMenu() {
      if (this.welcomeOnBoardTourStep > 0) return false;
      const rota = this.$route.path;
      if (rota.startsWith(ROTA_OPTIN)) return false;
      if (rota.startsWith(ROTA_AVISO_ATRASO)) return false;
      if (rota.startsWith(ROTA_WELCOME_ONBOARD_TOUR)) return false;
      return true;
    },
    exibirNews() {
      const rota = this.$route.path;
      return this.exibirMenu && !rota.startsWith(ROTA_WELCOME_ONBOARD_TOUR);
    },
    isHomePage() {
      const rota = this.$route.path ?? "";
      return rota === this.route;
    },
    isLogged() {
      if (this.appLocked) return false;
      return this.isLoggedInApp && this.vinculo && this.perfil && this.operacao;
    },
    logoImg() {
      return process.env.VUE_APP_BASE_URL + "images/omint/logo2023.png";
    },
    allowAnonymous() {
      return !!this.$route?.meta?.allowAnonymous && !!this.titleLabel;
    },
    profileImage() {
      return this.profile.profilePic;
    }
  },
  watch: {
    $route(route) {
      if (route?.path && !route.path.startsWith(ROTA_WELCOME_ONBOARD_TOUR) && this.welcomeOnBoardTourStep) {
        const welcomeOnBoardTour = this.welcomeOnBoardTour ?? [];
        const nextTour = welcomeOnBoardTour.find(b => !b.currentResponse);
        if (nextTour?.id) this.currentBackRoute = `${ROTA_WELCOME_ONBOARD_TOUR}/${nextTour.id}`;
      }
    },
    titleLabel(val, oldVal) {
      if (val !== oldVal && val !== "") {
        this.title = val;
      }
    }
  },
  methods: {
    ...mapActions("saude", ["createHeader", "destroyHeader"]),
    async back() {
      let _backTo = this.currentBackRoute;
      if (this.$router.history.current.meta.backTo) {
        _backTo = this.$router.history.current.meta.backTo;
      } else if (!_backTo) {
        let startLocation = this.$router.history._startLocation;
        if (startLocation && startLocation.indexOf("?") > 0) {
          startLocation = startLocation.substring(0, startLocation.indexOf("?"));
        }
        if (startLocation === this.$router.history.current.path) {
          _backTo = ROTA_BASE;
        }
      }
      if (_backTo) {
        if (_backTo === ROTA_BASE) {
          _backTo = await this.hasWelcomeOnboardTour();
        }
        this.$router.push(_backTo);
      } else {
        this.$router.back();
      }
    },
    hasWelcomeOnboardTour() {
      if (this.welcomeOnBoardTourStep === 0) return this.defaultRoute;
      const welcomeOnBoardTour = this.welcomeOnBoardTour ?? [];
      const nextTour = welcomeOnBoardTour.find(b => !b.currentResponse);
      if (nextTour?.id) return `${ROTA_WELCOME_ONBOARD_TOUR}/${nextTour.id}`;
      return this.defaultRoute;
    },
    checkEqualButtonStype() {
      const headerGrid = document.querySelector(".header-grid");
      if (!headerGrid) return;

      const pos = headerGrid.getBoundingClientRect();
      const headerGap = window.innerWidth < 1024 ? 20 : 0;
      const right = pos.x + 125 + headerGap;
      this.equalButtonStyle = `left: initial; right: ${right}px !important; top: 26px; width: 75px; height: 75px; --indscale: 0.35; --indmaincolor: #102a46;`;
    },
    onResizeHandler() {
      this.checkEqualButtonStype();
      this.resizeEqualweb(0);
    }
  },
  data() {
    return {
      currentBackRoute: null,
      displayBackButton: true,
      equalButtonStyle: "",
      isLoggedInApp: false,
      title: null
    };
  },
  created() {
    if (this.createHeader()) {
      this.$root.$on("change-back-route", newBackRoute => {
        this.currentBackRoute = newBackRoute;
      });
      this.$root.$on("change-title-label", newTitle => {
        this.title = newTitle;
      });
      window.addEventListener("resize", this.onResizeHandler);
      window.addEventListener("orientationchange", this.onResizeHandler);
    }
  },
  destroy() {
    if (this.destroyHeader()) {
      this.$root.$off("change-back-route");
      this.$root.$off("change-title-label");
      window.removeEventListener("resize", this.onResizeHandler);
      window.removeEventListener("orientationchange", this.onResizeHandler);
    }
  },
  mounted() {
    this.isLoggedInApp = isLoggedIn();
    this.currentBackRoute = this.backRoute;
    this.title = this.titleLabel;
    this.checkEqualButtonStype();
    this.initEqualweb({
      ...this.defaultEqualwebButtonStyle,
      vPosition: ["0%", null],
      scale: ["0.35", "0.35"]
    });

    this.$root.$on("hide-back-button", () => {
      this.displayBackButton = false;
    });

    this.$root.$on("show-back-button", () => {
      this.displayBackButton = true;
    });

    if (!!this.titleLabel && !!this.showLogo) {
      throw new Error("Title and Logo Image not supported at same time [BaseMenu]");
    }
  }
};
</script>

<style>
.header-page {
  background: white;
  position: fixed;
  top: 0;
  z-index: var(--zIndices-popover);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--colors-light-secondary-divider);
  height: 80px;
}

.box-title {
  max-height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
