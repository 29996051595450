import { hotp } from "otplib";

export default class OmintOTP {
  getDateTime(): string {
    return new Date().toISOString();
  }

  getTokenUtc(): number {
    const _date = new Date();
    const _dateUtc = new Date(
      _date.getUTCFullYear(),
      _date.getUTCMonth(),
      _date.getUTCDate(),
      _date.getUTCHours(),
      _date.getUTCMinutes(),
      _date.getUTCSeconds()
    );
    const _timeUtc = _dateUtc.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: false,
      second: "2-digit"
    });
    return Number(
      _dateUtc.toLocaleDateString("en-US", { day: "2-digit" }) +
        _timeUtc.split(":")[0] +
        _timeUtc.split(":")[1] +
        _timeUtc.split(":")[2]
    );
  }

  getTokenCounter(): number {
    const sleep = 30;
    const tokenUtc = this.getTokenUtc();
    const result = tokenUtc / sleep;
    return parseInt(`${result}`);
  }

  getTokenOtp(secret): string {
    return hotp.generate(secret, this.getTokenCounter()).substr(0, 4);
  }
}
