import * as geoUtils from "@/utils/geoDistance";

const baseURL = `${process.env.VUE_APP_SERVER_URL}/Solicitacao`;

export async function DetalheProtocolo({ vinculo, protocolo }) {
  return window.$axios
    .get({
      url: `${baseURL}/DetalheProtocolo`,
      query: {
        vinculo,
        protocolo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("DetalheProtocolo", error.message);
      return error;
    });
}

export async function NovaSolicitacao({ vinculo, beneficiario, tipo, observacao, arquivos, autenticacao }) {
  const data = new FormData();
  data.append("beneficiario", beneficiario);
  data.append("tipo", tipo);
  data.append("observacao", observacao);
  data.append("autenticacao", autenticacao);
  for (let index = 0; index < arquivos.length; index++) {
    const arquivo = arquivos[index];
    data.append(`anexo${index + 1}`, arquivo);
  }
  const coords: any = await geoUtils.GetLocation().catch(() => null);
  data.append("lat", coords?.latitude ?? "");
  data.append("lng", coords?.longitude ?? "");
  return window.$axios
    .post({
      url: `${baseURL}/NovaSolicitacao`,
      query: {
        vinculo
      },
      contentType: "multipart/form-data",
      data
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("NovaSolicitacao", error.message);
      return error;
    });
}

export function ObterDocumento({ vinculo, protocolo }) {
  const key = `${vinculo};${protocolo}`;
  const chave = `${window.$jse.encrypt(key)}`;

  const queryString = new URLSearchParams({
    vinculo,
    chave,
    tipo: ".pdf"
  }).toString();

  return `${baseURL}/ObterDocumento?${queryString}`;
}

export async function ObterImagem({ vinculo, documento, original }) {
  const key = `${vinculo};${documento};${original ? "T" : "F"}`;
  const chave = `${window.$jse.encrypt(key)}`;
  return await window.$axios
    .get({
      url: `${baseURL}/ObterImagem`,
      query: {
        vinculo,
        chave,
        original
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterImagem", error.message);
      throw new Error(error.message);
    });
}

export async function ObterProtocolos({ vinculo, protocolo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterProtocolos`,
      query: {
        vinculo,
        protocolo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterProtocolos", error.message);
      return error;
    });
}

export async function ObterTiposSolicitacao({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterTiposSolicitacao`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterTiposSolicitacao", error.message);
      return error;
    });
}

export async function RetornoPendencia({ vinculo, protocolo, observacao, arquivos }) {
  const data = new FormData();
  data.append("protocolo", protocolo);
  data.append("observacao", observacao);
  for (let index = 0; index < arquivos.length; index++) {
    data.append(`anexo${index + 1}`, arquivos[index]);
  }

  return window.$axios
    .post({
      url: `${baseURL}/RetornoPendencia`,
      query: {
        vinculo
      },
      contentType: "multipart/form-data",
      data
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("RetornoPendencia", error.message);
      return error;
    });
}
