<template>
  <OmtLayoutContainer>
    <OmtRow justify-content="space-between">
      <OmtButton
        leftIcon="arrow-up-arrow-down"
        size="sm"
        iconSize="sm"
        border=".5px solid var(--colors-light-secondary-divider)"
        :variant="!!activeSort ? 'solid' : 'outline'"
        :iconColor="!!activeSort && !!simulations.length ? 'white' : 'var(--colors-light-primary-gray1)'"
        w="115px !important"
        @click="showSortModal = true"
        :disabled="!simulations.length"
      >
        <OmtTypography
          as="button-small"
          :color="!!activeSort && !!simulations.length ? 'white' : 'var(--colors-light-primary-gray1)'"
        >
          Ordenar
        </OmtTypography>
      </OmtButton>

      <OmtButton
        variant="ghost"
        :disabled="!filtersCount && !activeSort"
        size="sm"
        w="105px !important"
        @click="cleanSorts"
      >
        Limpar
      </OmtButton>
    </OmtRow>

    <template v-if="simulations.length">
      <OmtRow mt="16px" v-for="(simulation, index) in simulations" :key="`simulation-${index}`">
        <CardTile
          @click="openSimulation(simulation)"
          v-bind="{
            icon: { name: 'flask', size: 'sm' },
            title: 'Simulação de exames',
            description: `Simulado em ${simulation.date} • ${simulation.qty} exame${simulation.qty > 1 ? 's' : ''}`
          }"
        />
      </OmtRow>
    </template>

    <template v-else-if="activeSort">
      <OmtRow mt="132px">
        <CFlex flex-direction="column" align-items="center">
          <OmtIcons name="circle-exclamation" mb="16px" size="xl" color="var(--colors-light-primary-gray2)" />
          <OmtTypography as="p1" mb="12px"> Nenhuma simulação encontrada. </OmtTypography>
          <OmtTypography v-if="allowSimulations" as="p1" mb="16px">
            Clique no botão abaixo para iniciar uma nova ou clique em 'Limpar' para remover os filtros.
          </OmtTypography>
        </CFlex>
      </OmtRow>
    </template>

    <template v-else-if="!loading">
      <OmtRow mt="132px">
        <CFlex flex-direction="column" align-items="center">
          <OmtIcons name="circle-exclamation" mb="16px" size="xl" color="var(--colors-light-primary-gray2)" />
          <OmtTypography as="p1" mb="12px"> Você ainda não possui simulações. </OmtTypography>
          <OmtTypography v-if="allowSimulations" as="p1" mb="16px">
            Clique no botão abaixo para iniciar uma nova.
          </OmtTypography>
        </CFlex>
      </OmtRow>
    </template>

    <FixedButtonRow>
      <OmtButton :w="['100%', '308px !important']" @click="$router.push({ path: '/copay/simulations/create' })">
        Iniciar nova simulação
      </OmtButton>
    </FixedButtonRow>

    <RadioGroupModal
      v-if="showSortModal"
      :options="sorts"
      :initialValue="activeSort"
      @save="save"
      @close="showSortModal = false"
      title="Ordenar"
    />
  </OmtLayoutContainer>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import RadioGroupModal from "@/components/Molecules/RadioGroupModal";
import CardTile from "@/components/Molecules/CardTile";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

export default {
  components: {
    RadioGroupModal,
    CardTile,
    FixedButtonRow
  },
  data() {
    return {
      loading: true,
      showSortModal: false,
      sort: "",
      filtersCount: "",
      activedSort: ""
    };
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      await this.fetchPermition();
      if (this.simulations?.length) {
        this.fetchSimulations();
      } else {
        await this.fetchSimulations();
      }
    } catch (error) {
      console.error("copay load error", error);
    } finally {
      this.loading = false;
      this.$root.$emit("hide-loading");
    }
  },
  methods: {
    ...mapActions("copay", ["fetchSimulations", "fetchPermition"]),
    ...mapMutations("copay", ["SORT_SIMULATIONS", "CLEAN_SORTS", "SET_PROP"]),
    openSimulation(simulation) {
      this.$router.push({
        name: "copay.simulations.view",
        params: {
          id: simulation.id
        }
      });
    },
    cleanSorts() {
      this.CLEAN_SORTS();
    },
    save(sort) {
      this.SORT_SIMULATIONS(sort);
    }
  },
  computed: {
    ...mapState("copay", ["simulations", "sorts", "activeSort", "allowSimulations"])
  }
};
</script>
