<template>
  <omint-page-content>
    <div class="filtros">
      <div>
        <omt-p>
          <omt-bold>Data para consulta</omt-bold>
        </omt-p>
        <omt-p class="calendar">
          <v-date-picker ref="picker" @dayclick="onChooseDate" :value="dateList" popover-visibility="focus" />
        </omt-p>
      </div>
      <div>
        <omt-p>
          <omt-bold>Horário para consulta</omt-bold>
        </omt-p>
        <Options
          ref="opsSchedule"
          :options="scheduleOptions"
          name="Schedule"
          id="schedule-list"
          :on-selection="onSelectSchedule"
          preSelected="first"
          placeholder="Selecione o horário"
          :solid="true"
        />
      </div>
    </div>
    <BlockList v-show="!hideSchedule[idx]" v-for="(a, idx) in agenda" :key="idx" :leftIcon="false" :rigthIcon="true">
      <a v-on:click="remove(a, idx)">
        <omt-bold>{{ a.CdPrestador }}</omt-bold> {{ a.NrTelefone }}
        <omt-divider :onlyClear="true" />
        De {{ new Date(a.DhInicioDisponibilidade).formatDDMMYYYY() }}
        {{ new Date(a.DhInicioDisponibilidade).toLocaleTimeString() }}
        <omt-divider :onlyClear="true" />
        Até {{ new Date(a.DhTerminoDisponibilidade).formatDDMMYYYY() }}
        {{ new Date(a.DhTerminoDisponibilidade).toLocaleTimeString() }}
        <i class="fas fa-trash"></i>
      </a>
    </BlockList>
    <FloatingAddButton link="/forms/covid19/newschedule" />
  </omint-page-content>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FloatingAddButton from "@/components/Molecules/FloatingAddButton";
import BlockList from "@/components/Molecules/BlockList";
import Options from "@/components/Molecules/Options";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "ScheduleCovid19",
  components: { BlockList, FloatingAddButton, Options },
  computed: {
    ...mapState("forms", ["agenda", "horarios"]),
    scheduleOptions() {
      let opts = [];
      let id = 0;
      this.horarios.forEach(e => {
        opts.push({ value: id++, label: e.de });
      });
      return opts;
    }
  },
  data() {
    return {
      dateList: new Date(),
      dateNew: new Date(),
      showList: false,
      showNew: true,
      schedule: null,
      hideSchedule: {}
    };
  },
  methods: {
    ...mapActions("forms", ["listarAgenda", "registrarAgenda"]),
    async onSelectSchedule(value) {
      this.schedule = this.horarios[value];
      await this.consultar();
    },
    async onChooseDate(day) {
      this.dateList = day.date;
      await this.consultar();
    },
    async consultar() {
      await this.$root.$emit("show-loading");
      try {
        const de = (!this.schedule ? this.horarios[0].de : this.schedule.de).split(":");
        const referencia = new Date(
          this.dateList.getFullYear(),
          this.dateList.getMonth(),
          this.dateList.getDate(),
          de[0],
          de[1],
          0
        );
        await this.listarAgenda({ referencia });
        this.hideSchedule = {};
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    async remove(prest, id) {
      const userInput = await Swal.fire({
        title: "Confirmação",
        text: `Confirma a exclusão da agenda para ${prest.CdPrestador}?`,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        showCancelButton: true
      });
      if (!userInput.value) {
        return;
      }
      await this.$root.$emit("show-loading");
      try {
        const futuro = new Date(9999, 11, 31);
        await this.registrarAgenda({
          id: prest.IdPrestador,
          prestador: null,
          telefone: null,
          email: null,
          inicioDisponibilidade: futuro,
          terminoDisponibilidade: futuro
        });
      } finally {
        this.hideSchedule[id] = true;
        const old = this.hideSchedule;
        this.hideSchedule = {};
        Object.keys(old).forEach(element => {
          this.hideSchedule[id] = old[element];
        });
        await this.$root.$emit("hide-loading");
      }
    }
  },
  async mounted() {
    await this.consultar();
  }
};
</script>
<style scoped>
.filtros {
  display: flex;
  flex-basis: 50%;
}

.filtros div {
  width: 50%;
}

.filtros div:last-child {
  margin-left: 10px;
}

.filtros div div {
  width: calc(100% - 10px);
}

.calendar {
  overflow: hidden;
}
</style>
