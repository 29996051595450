// import { mode } from "@chakra-ui/vue";

/**
 * color: mode(<lighteen_color>, <darken_color>)
 */

import colors from "./colors";

export default {
  radii: {
    button: "40px"
  },
  opacity: {
    light: "0.6"
  },
  borders: {
    "2px": "1px solid"
  },
  shadows: {
    outline: `0 0 0 3px rgba(88, 111, 139, 0.6)`,
    sm: "0px 0px 24px 0px #0000001A"
  },
  breakpoints: ["375px", "1024px"],
  colors,
  baseStyles: {
    CDivider: {
      marginTop: "4",
      marginBottom: "4",
      borderStyle: "solid",
      borderColor: "var(--colors-light-secondary-divider)",
      borderWidth: "0.1px",
      opacity: ".3"
    }
  }
};
