<template>
  <omint-page>
    <Header name="Troca de Senha" titleLabel="Troca de Senha" backRoute="/" />
    <TokenModal
      v-if="showTokenModal"
      tokenKey="alteracao-senha"
      service="/change-password"
      v-on:cancel="() => showModal(false)"
      v-on:valid="validToken"
    />
    <omint-page-content>
      <form>
        <omt-p class="mb-0">Senha atual</omt-p>
        <omt-input
          :type="currentPasswordType"
          placeholder="Digite a senha senha atual"
          v-model="$v.currentPassword.$model"
          :class="$v.currentPassword.$error ? 'warning' : ''"
          autocomplete="off"
        />
        <i :class="currentPasswordIcon" @click.prevent="showHideCurrentPassword()"></i>
        <div class="error-continer">
          <omt-p class="error" v-if="!$v.currentPassword.required && $v.currentPassword.$dirty">
            &#8226; <span>Digite a senha atual</span>
          </omt-p>
        </div>

        <omt-p class="mb-0">Nova senha</omt-p>
        <omt-input
          :type="newPasswordType"
          placeholder="Digite a nova senha"
          v-model="$v.newPassword.$model"
          :class="$v.newPassword.$error ? 'warning' : ''"
          autocomplete="off"
        />
        <i :class="newPasswordIcon" @click.prevent="showHideNewPassword()"></i>
        <div class="error-continer">
          <omt-p class="error" v-if="$v.newPassword.$model === $v.currentPassword.$model && $v.newPassword.$dirty">
            &#8226; <span>A Senha Atual e a Nova Senha devem ser diferentes</span>
          </omt-p>
          <omt-p class="error" v-if="!$v.newPassword.required && $v.newPassword.$dirty">
            &#8226; <span>Digite uma nova senha</span>
          </omt-p>
          <omt-p class="error" v-if="!$v.newPassword.minLength && $v.newPassword.$dirty">
            &#8226; <span>A Nova Senha deve conter ao menos 7 caracteres</span>
          </omt-p>
          <omt-p class="error" v-if="!$v.newPassword.hasUpperCase && $v.newPassword.$dirty">
            &#8226; <span>A Nova Senha deve conter ao menos 1 letra maiúscula</span>
          </omt-p>
          <omt-p class="error" v-if="!$v.newPassword.hasNumber && $v.newPassword.$dirty">
            &#8226; <span>A Nova Senha deve conter ao menos 1 número</span>
          </omt-p>
          <omt-p class="error" v-if="!$v.newPassword.hasSpecialChars && $v.newPassword.$dirty">
            &#8226; <span>A Nova Senha deve conter ao menos 1 caractere especial</span>
          </omt-p>
        </div>

        <omt-p class="mb-0">Confirmação de senha</omt-p>
        <omt-input
          :type="againNewPasswordType"
          placeholder="Confirme a nova senha"
          v-model="$v.againNewPassword.$model"
          :class="$v.againNewPassword.$error ? 'warning' : ''"
          autocomplete="off"
        />
        <i :class="againNewPasswordIcon" @click.prevent="showHideAgainNewPassword()"></i>
        <div class="error-continer">
          <omt-p class="error" v-if="!$v.againNewPassword.sameAsPassword && $v.againNewPassword.$dirty">
            &#8226; <span>A Nova Senha e a Confirmação de Senha devem ser iguais.</span>
          </omt-p>
          <omt-p class="error" v-if="!$v.againNewPassword.minLength && $v.againNewPassword.$dirty">
            &#8226; <span>A Nova Senha deve conter ao menos 7 caracteres</span>
          </omt-p>
          <omt-p class="error" v-if="!$v.againNewPassword.hasUpperCase && $v.againNewPassword.$dirty">
            &#8226; <span>A Nova Senha deve conter ao menos 1 letra maiúscula</span>
          </omt-p>
          <omt-p class="error" v-if="!$v.againNewPassword.hasNumber && $v.againNewPassword.$dirty">
            &#8226; <span>A Nova Senha deve conter ao menos 1 número</span>
          </omt-p>
          <omt-p class="error" v-if="!$v.againNewPassword.hasSpecialChars && $v.againNewPassword.$dirty">
            &#8226; <span>A Nova Senha deve conter ao menos 1 caractere especial</span>
          </omt-p>
        </div>

        <omt-buttons-bar v-chakra mt="4">
          <div v-if="forceChange" class="button-space" />
          <omt-button :onClick="onCancelClick" v-if="!forceChange"> Cancelar </omt-button>
          <omt-button
            :class="{ audit: forceChange }"
            :disabled="
              !currentPassword ||
              !newPassword ||
              !againNewPassword ||
              $v.$anyError ||
              !$v.$anyDirty ||
              $v.newPassword.$model === $v.currentPassword.$model
            "
            :onClick="() => showModal(true)"
          >
            Alterar Senha
          </omt-button>
        </omt-buttons-bar>
      </form>
    </omint-page-content>
    <Footer v-if="!changePasswordRequired" />
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";
import TokenModal from "@/components/Organisms/TokenModal";
import { mapActions, mapState } from "vuex";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import formValidations from "@/utils/formValidations";

const { hasUpperCase, hasNumber, hasSpecialChars } = formValidations;

export default {
  components: { Header, Footer, TokenModal },
  name: "ChangePassword",
  data() {
    return {
      currentPassword: "",
      currentPasswordType: "password",
      currentPasswordIcon: "fa fa-eye hide-pwd",
      newPassword: "",
      newPasswordType: "password",
      newPasswordIcon: "fa fa-eye hide-pwd",
      againNewPassword: "",
      againNewPasswordType: "password",
      againNewPasswordIcon: "fa fa-eye hide-pwd",
      showTokenModal: false,
      forceChange: false
    };
  },
  validations: {
    currentPassword: {
      required
    },
    newPassword: {
      required,
      minLength: minLength(7),
      hasUpperCase,
      hasNumber,
      hasSpecialChars
    },
    againNewPassword: {
      required,
      sameAsPassword: sameAs("newPassword"),
      minLength: minLength(7),
      hasUpperCase,
      hasNumber,
      hasSpecialChars
    }
  },
  computed: {
    ...mapState("saude", ["userId"]),
    ...mapState("myAccount", ["changePasswordRequired"])
  },
  mounted() {
    this.$root.$emit("hide-loading");

    this.forceChange = this.$route.params.forceChange || this.changePasswordRequired;
  },
  methods: {
    ...mapActions("myAccount", ["changePassword"]),
    showHideCurrentPassword() {
      if (this.currentPasswordType === "password") {
        this.currentPasswordType = "text";
        this.currentPasswordIcon = "fa fa-eye-slash show-pwd";
      } else {
        this.currentPasswordType = "password";
        this.currentPasswordIcon = "fa fa-eye hide-pwd";
      }
    },
    showHideNewPassword() {
      if (this.newPasswordType === "password") {
        this.newPasswordType = "text";
        this.newPasswordIcon = "fa fa-eye-slash show-pwd";
      } else {
        this.newPasswordType = "password";
        this.newPasswordIcon = "fa fa-eye hide-pwd";
      }
    },
    showHideAgainNewPassword() {
      if (this.againNewPasswordType === "password") {
        this.againNewPasswordType = "text";
        this.againNewPasswordIcon = "fa fa-eye-slash show-pwd";
      } else {
        this.againNewPasswordType = "password";
        this.againNewPasswordIcon = "fa fa-eye hide-pwd";
      }
    },
    async onCancelClick() {
      await this.$router.replace({ name: "home" });
    },
    async onChangePwdClick({ auth }) {
      try {
        this.$root.$emit("show-loading");
        this.showModal(false);
        const resp = await this.changePassword({
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          auth
        });
        this.$root.$emit("hide-loading");
        if (resp) {
          await Swal.fire({
            title: "Sucesso",
            text: "Sua senha foi alterada.",
            confirmButtonText: "OK"
          });
          await this.$router.replace({ name: "home" });
        } else {
          await Swal.fire({
            title: "Aviso",
            text: "Senha informada não corresponde a senha atual.",
            confirmButtonText: "OK"
          });
        }
      } catch (error) {
        this.$root.$emit("hide-loading");
        await Swal.fire({
          title: "Aviso",
          text: "Sua senha não foi alterada. Por favor tente novamente mais tarde.",
          confirmButtonText: "OK"
        });
      }
    },
    showModal(value) {
      if (value === true) {
        this.formTouched = !this.$v.$anyDirty;
        this.errors = this.$v.$anyError;
        if (!this.errors && !this.formTouched) {
          this.showTokenModal = true;
        }
      } else {
        this.showTokenModal = false;
      }
    },
    async validToken({ auth }) {
      await this.onChangePwdClick({ auth });
    }
  }
};
</script>

<style scoped lang="scss">
.text {
  font-size: 16px !important;
  margin-bottom: 30px !important;
  color: #263c73 !important;
}
.buttons {
  gap: 1rem;

  button {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .button.audit {
    max-width: 250px;
    transform: translateX(-50%);
    left: 50% !important;
  }
}

.button-space {
  height: 100px;
}

.button.audit {
  margin-top: auto;
  border-radius: var(--radii-button);
  position: absolute;
  bottom: 16px;
  left: 16px;
  width: calc(100% - 32px);
  background-color: #172972;
  color: white;

  &.disabled {
    background-color: #eeebeb;
    color: #586f8b40;
    box-shadow: none;
    opacity: 1;
  }
}

.error-continer {
  margin-bottom: 1rem;
  margin-top: -1rem;
}
.error {
  line-height: 1.25 !important;
  margin-bottom: 0.25rem !important;
  font-size: 12px;
  text-align: left;
  margin: 0;
  display: flex;
  padding: 0;
  color: red !important;

  span {
    padding-left: 0.25rem;
  }
}

.hide-pwd,
.show-pwd {
  position: relative;
  top: -25px;
  margin-left: calc(100% - 25px);
  font-weight: 400 !important;
}
</style>
