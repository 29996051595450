const baseURL = `${process.env.VUE_APP_SERVER_URL}/Terceiros`;

export async function AdicionarBeneficiario({ vinculo, autenticacao, idPessoa, tpRevalidacao }) {
  return window.$axios
    .post({
      url: `${baseURL}/AdicionarBeneficiario`,
      query: {
        vinculo
      },
      data: {
        autenticacao,
        idPessoa,
        tipoRevalidacao: tpRevalidacao
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      throw new Error(error.message);
    });
}

export async function AdicionarNomeado({
  vinculo,
  autenticacao,
  nome,
  cpf,
  rne,
  rg,
  emissorRg,
  dtEmissaoRg,
  dtNascimento,
  idLocalNascimento,
  ufLocalNascimento,
  nomeLocalNascimento,
  sexo,
  nomeMae,
  idGrauRelacionamento,
  dsGrauRelacionamento,
  complementoGrauRelacionamento,
  nrCelular,
  smsCelular,
  nrFixo,
  tpFixo,
  tpEmail,
  email,
  avisoEmail,
  tpRevalidacao
}) {
  return window.$axios
    .post({
      url: `${baseURL}/AdicionarNomeado`,
      query: {
        vinculo
      },
      data: {
        autenticacao,
        nome,
        cpf,
        estrangeiro: rg ? false : true,
        documento: { numero: rg ? rg : rne, emissor: emissorRg, strDataEmissao: dtEmissaoRg },
        strDataNascimento: dtNascimento,
        localNascimento: {
          id: idLocalNascimento,
          uf: ufLocalNascimento,
          nome: nomeLocalNascimento
        },
        sexo,
        nomeMae,
        GrauRelacionamento: {
          id: idGrauRelacionamento,
          descricao: dsGrauRelacionamento,
          complemento: complementoGrauRelacionamento
        },
        telefoneCelular: {
          formatado: nrCelular,
          tipo: "CEL",
          receberSms: smsCelular
        },
        telefoneFixo: {
          formatado: nrFixo,
          tipo: tpFixo,
          receberSms: false
        },
        email: {
          tipo: tpEmail,
          endereco: email,
          receberAviso: avisoEmail
        },
        tipoRevalidacao: tpRevalidacao
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      throw new Error(error.message);
    });
}

export async function EditarNomeado({
  vinculo,
  idPessoa,
  nome,
  cpf,
  rne,
  rg,
  emissorRg,
  dtEmissaoRg,
  dtNascimento,
  idLocalNascimento,
  ufLocalNascimento,
  nomeLocalNascimento,
  sexo,
  nomeMae,
  idGrauRelacionamento,
  dsGrauRelacionamento,
  complementoGrauRelacionamento,
  nrCelular,
  smsCelular,
  nrFixo,
  tpFixo,
  tpEmail,
  email,
  avisoEmail,
  tpRevalidacao
}) {
  return window.$axios
    .post({
      url: `${baseURL}/EditarNomeado`,
      query: {
        vinculo
      },
      data: {
        idPessoa,
        nome,
        cpf,
        estrangeiro: rg ? false : true,
        documento: { numero: rg ? rg : rne, emissor: emissorRg, strDataEmissao: dtEmissaoRg },
        strDataNascimento: dtNascimento,
        localNascimento: {
          id: idLocalNascimento,
          uf: ufLocalNascimento,
          nome: nomeLocalNascimento
        },
        sexo,
        nomeMae,
        GrauRelacionamento: {
          id: idGrauRelacionamento,
          descricao: dsGrauRelacionamento,
          complemento: complementoGrauRelacionamento
        },
        telefoneCelular: {
          formatado: nrCelular,
          tipo: "CEL",
          receberSms: smsCelular
        },
        telefoneFixo: {
          formatado: nrFixo,
          tipo: tpFixo,
          receberSms: false
        },
        email: {
          tipo: tpEmail,
          endereco: email,
          receberAviso: avisoEmail
        },
        tipoRevalidacao: tpRevalidacao
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      throw new Error(error.message);
    });
}

export async function ObterCadastroNomeado({ vinculo, id }) {
  return window.$axios
    .post({
      url: `${baseURL}/ObterCadastroNomeado`,
      query: {
        vinculo
      },
      data: {
        id
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      throw new Error(error.message);
    });
}

export async function ObterMunicipios({ vinculo, uf }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterMunicipios`,
      query: {
        vinculo,
        uf
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      throw new Error(error.message);
    });
}

export async function ObterNomeados({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterNomeados`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      throw new Error(error.message);
    });
}

export async function ObterParametros({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterParametros`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      throw new Error(error.message);
    });
}

export async function Prorrogar({ vinculo, autenticacao, idPessoa, tpRevalidacao }) {
  return window.$axios
    .post({
      url: `${baseURL}/Prorrogar`,
      query: {
        vinculo
      },
      data: {
        autenticacao,
        idPessoa,
        tipoRevalidacao: tpRevalidacao
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      throw new Error(error.message);
    });
}

export async function Reativar({ vinculo, autenticacao, idPessoa, tpRevalidacao }) {
  return window.$axios
    .post({
      url: `${baseURL}/Reativar`,
      query: {
        vinculo
      },
      data: {
        autenticacao,
        idPessoa,
        tipoRevalidacao: tpRevalidacao
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      throw new Error(error.message);
    });
}

export async function Revogar({ vinculo, autenticacao, idPessoa }) {
  return window.$axios
    .post({
      url: `${baseURL}/Revogar`,
      query: {
        vinculo
      },
      data: {
        autenticacao,
        idPessoa
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      throw new Error(error.message);
    });
}
