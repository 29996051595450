<template>
  <div>
    <Header name="Indicação de Rede" titleLabel="Indicação de Rede" />
    <omint-page-content>
      <CBox mb="4">
        <omt-h1> <omt-uppercase>Solicitar indicação médica</omt-uppercase> </omt-h1>
      </CBox>

      <CBox mb="5">
        <omt-p>
          Oferecendo a melhor experiência a você, podemos realizar indicações de credenciados de acordo com a sua
          necessidade
        </omt-p>
      </CBox>

      <CBox mb="4">
        <AffiliateIndicationTile icon="far fa-comment-alt" title="Como podemos te ajudar?" />
      </CBox>

      <CFlex align="end" direction="column" mb="5">
        <omt-textarea
          v-chakra
          p="16px !important"
          maxWidth="calc(100vw - 66px)"
          placeholder="Exemplo: Especialista em queda de cabelo."
          v-bind:value="obs"
          v-on:input="obs = $event"
          rows="6"
          maxlength="250"
          required
        />
        <span>
          <omt-p> {{ obs.length }} /250 </omt-p>
        </span>
      </CFlex>

      <CFlex mb="4" align="baseline">
        <AffiliateIndicationTile icon="fas fa-image" title="Envio de imagens" />
        <i class="fa fa-lg fa-info" @click="showInfoModal.status = true"></i>
      </CFlex>

      <CBox mb="4">
        <omt-p> Você pode enviar documentos que possam contribuir com a nossa indicação </omt-p>
      </CBox>

      <CBox mb="6">
        <omt-divider />
      </CBox>

      <CBox mb="6">
        <omt-inline-bar class="upload-bar">
          <omt-icon v-for="(f, i) in files" :idx="i" :key="i">
            <div v-if="!f.url">
              <label :for="'file' + i">
                <i class="fas fa-plus"></i>
                <i class="far fa-image fa-4x"></i>
              </label>
              <input
                accept="application/pdf, image/png, image/jpeg"
                type="file"
                :id="'file' + i"
                @change="previewFile"
              />
            </div>
            <div v-else @click.prevent="() => removeFile(i)">
              <i class="fas fa-minus-circle"></i>
              <img class="thumb" v-if="f.thumb" :src="f.thumb" :alt="'Anexo ' + i" />
              <i v-else class="fas fa-file-pdf fa-4x"></i>
            </div>
          </omt-icon>
        </omt-inline-bar>
      </CBox>

      <CGrid mb="5">
        <omt-button :disabled="!obs.length" v-chakra w="100%" :colorInverse="true" :onClick="submitForm">
          Enviar
        </omt-button>
      </CGrid>

      <CBox mb="12" justify="center">
        <span> Você receberá um retorno em até 2 dias uteis. </span>
      </CBox>

      <ProtocolModal
        :isCentered="true"
        v-if="showProtocolModal.status"
        :protocol="showProtocolModal.protocol"
        :description="showProtocolModal.description"
        :success="true"
        @close="closeModal"
      />

      <InfoSendFileModal :isCentered="true" v-if="showInfoModal.status" @close="showInfoModal.status = false" />
    </omint-page-content>
    <Footer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";
import AffiliateIndicationTile from "@/components/Organisms/AffiliateIndication/Tile";
import InfoSendFileModal from "@/components/Organisms/AffiliateIndication/InfoSendFileModal.vue";
import ProtocolModal from "@/components/Organisms/BankAccount/Modals/ProtocolModal.vue";
import { CBox, CFlex, CGrid } from "@chakra-ui/vue";

export default {
  name: "AffiliateIndicationForm",
  components: { Footer, Header, AffiliateIndicationTile, CBox, CFlex, CGrid, ProtocolModal, InfoSendFileModal },
  data() {
    return {
      obs: "",
      totalFiles: -1,
      limitFiles: 6,
      files: [],
      showInfoModal: {
        status: false
      },
      showProtocolModal: {
        status: false,
        protocol: "",
        description:
          "Recebemos sua solicitação de indicação, e em até 2 dias uteis te notificaremos que o resultado estará disponível em seu APP."
      }
    };
  },
  methods: {
    ...mapActions("saude", ["uploadFile"]),
    ...mapActions("ticket", ["createNewTicket"]),
    async submitForm() {
      if (!this.obs) return;

      await this.$root.$emit("show-loading");
      let resp = {};
      let files = [];
      this.files.forEach(element => {
        if (element.url) files.push(element.url);
      });

      try {
        resp = await this.createNewTicket({
          obs: this.obs,
          files,
          chamadoReferenciado: null,
          classification: "8973"
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }

      if (resp.Data) {
        this.showProtocolModal.protocol = resp.Data.protocoloANS;
        this.showProtocolModal.status = true;
      } else {
        await Swal.fire({
          title: "Erro",
          text: "Não foi possivel registrar o atendimento.\nServiço temporariamente indisponível.",
          confirmButtonText: "OK",
          icon: "error"
        });
      }
    },
    removeFile(fileId) {
      if (fileId < 0 || fileId > this.totalFiles) return;
      while (fileId < this.totalFiles) {
        const nextFile = this.files[fileId + 1];
        this.files[fileId] = { thumb: nextFile.thumb, url: nextFile.url };
        fileId++;
      }
      this.files[this.totalFiles] = { thumb: null, url: null };
      this.totalFiles--;
      this.recreateFiles();
    },
    recreateFiles() {
      const oldFiles = this.files;
      this.files = [];
      oldFiles.forEach(element => {
        this.files.push(element);
      });
    },
    async previewFile(event) {
      await this.$root.$emit("show-loading");
      let resp = {};
      try {
        resp = await this.uploadFile({
          filename: event.target.files[0].filename,
          blobFile: event.target.files[0]
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
      if (resp.Data && resp.Data[0]) {
        if (resp.Data[0].message !== "OK") {
          await Swal.fire({
            title: "Alerta",
            text: resp.Data[0].message,
            confirmButtonText: "OK",
            icon: "warning"
          });
        } else if (this.totalFiles + resp.Data.length > this.limitFiles - 1) {
          await Swal.fire({
            title: "Alerta",
            text: `O total de imagens e número de páginas PDF não pode ultrapassar ${this.limitFiles}`,
            confirmButtonText: "OK",
            icon: "warning"
          });
        } else {
          for (let i = 0; i < resp.Data.length; i++) {
            this.totalFiles++;
            this.files[this.totalFiles] = { thumb: resp.Data[i].thumbUrl, url: resp.Data[i].fileRemoteUrl };
          }
          this.recreateFiles();
        }
      }
    },
    closeModal() {
      this.$router.replace({
        path: "/indicacao-de-credenciados"
      });
    }
  },
  async mounted() {
    for (let i = 0; i < this.limitFiles; i++) {
      this.files.push({ thumb: null, url: null });
    }

    await this.$root.$emit("hide-loading");
  }
};
</script>

<style lang="scss" scoped>
.fa-info {
  margin-left: 1rem;
  border-radius: 50%;
  outline: 4px solid currentColor;
  padding: 8px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.5);
}

.upload-bar {
  margin-top: -25px !important;
  justify-content: space-between;
  flex-wrap: nowrap !important;
}

.upload-bar div {
  margin: 0;
}

.upload-bar div i:first-child {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  right: -32px;
  background: #f0f0f0;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 2px solid #112b46;
  color: #112b46;
  font-size: 100%;
}

.upload-bar div input {
  display: none;
}

.upload-bar div img {
  width: 48px;
  height: 48px;
}

.upload-bar div label {
  cursor: pointer;
}

span {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: var(--colors-light-primary-gray1);
  display: block;
}
</style>
