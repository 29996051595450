<template>
  <omint-page>
    <Header name="Credencial" titleLabel="Credencial" />
    <omint-page-content>
      <Credential
        :id="c.id"
        :number="c.number"
        :name="c.name"
        :ingress="c.ingress"
        :valid="c.valid"
        :birth="c.birth"
        :plan="c.plan"
        :bg="c.bg || credentials[c.posBg].bg"
        :credType="c.credType"
        :cns="c.cns"
        :numberT="c.numberT"
        :planT="c.planT"
        :accT="c.accT"
        :accTp="c.accTp"
        :cssMediaType="''"
        :idx="null"
        @token="tokenUpdated"
      />
    </omint-page-content>
  </omint-page>
</template>

<script>
import Credential from "@/components/Organisms/Credential.vue";
import Header from "@/components/Molecules/Header";
import { mapState } from "vuex";
import Footer from "@/components/Molecules/Footer";

export default {
  components: { Credential, Header, Footer },
  name: "FullCredential",
  props: ["idx"],
  computed: {
    ...mapState("saude", ["credentials"]),
    c() {
      if (this.credentials && this.credentials.length - 1 >= this.idx) {
        return this.credentials[Number(this.idx)];
      }
      return {};
    }
  },
  methods: {
    tokenUpdated(token) {
      this.$root.$emit("change-title-label", `Credencial Token ${token}`);
    }
  },
  mounted() {
    this.$root.$emit("hide-loading");
    if (!this.credentials || this.credentials.length === 0) {
      this.$router.push("/");
    }
  }
};
</script>

<style scoped></style>
