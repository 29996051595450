<template>
  <div :class="myClass">
    {{ message }}
  </div>
</template>
<script>
export default {
  props: {
    fixed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      timer: null,
      message: null,
      myClass: "status-message"
    };
  },
  created() {
    window.addEventListener("offline", () => this.offLineMessage());
    window.addEventListener("online", () => this.onLineMessage());
  },
  mounted() {
    if (this.fixed) {
      if (navigator.onLine) {
        this.onLineMessage();
      } else {
        this.offLineMessage();
      }
    }
  },
  methods: {
    clearState() {
      this.message = null;
      this.myClass = "status-message";
      clearInterval(this.timer);
    },
    onLineMessage() {
      this.message = "Conexão com a intenet restabelecida";
      this.myClass = "status-message online-message active";
      if (!this.fixed) {
        this.timer = setInterval(() => this.clearState(), 2000);
      }
    },
    offLineMessage() {
      this.message = "Sem conexão com a intenet";
      this.myClass = "status-message offline-message active";
      if (!this.fixed) {
        this.timer = setInterval(() => this.clearState(), 2000);
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("offline", () => this.offLineMessage());
    window.removeEventListener("online", () => this.onLineMessage());
    if (this.timer) clearInterval(this.timer);
  }
};
</script>
<style scoped>
.status-message {
  display: block;
  clear: both;
  position: fixed;
  color: white;
  transform: translateY(-200%);
  transition: all 350ms ease;
  line-height: 55px;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  padding: 0;
  margin: 0;
  padding-top: 5px;
  left: 0px;
  right: 0px;
  top: 0px;
  height: 60px;
  width: 100%;
  z-index: var(--zIndices-toast);
}

.offline-message {
  background-color: red;
}

.online-message {
  background-color: green;
}

.active {
  transform: translateY(0%);
}
</style>
