import Login from "@/pages/login/index.vue";
import Logout from "@/pages/logout/index.vue";
import Offlinecredential from "@/pages/offlinecredential/index.vue";
import Redirect from "@/pages/redirect/index.vue";

const routes: Route[] = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      allowAnonymous: true,
      offline: true
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      allowAnonymous: true,
      offline: true
    }
  },
  {
    path: "/offlinecredential",
    name: "offlinecredential",
    component: Offlinecredential,
    meta: {
      backTo: "/",
      allowAnonymous: true,
      offline: true
    }
  },
  {
    path: "/readonly-access",
    alias: "/consulta-apenas",
    name: "readonly.access",
    props: true,
    component: Redirect,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/redirect",
    name: "redirect",
    props: true,
    component: Redirect,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "*",
    redirect: "/"
  }
];

export default routes;
