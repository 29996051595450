<template>
  <CFlex
    @click="handleClick"
    align="baseline"
    :class="{
      'tile-container': hasCountainer,
      'has-hover-effect': hasHoverEffect
    }"
  >
    <CFlex w="100%" :align-items="subtitle ? 'center' : 'baseline'">
      <i :class="`${this.icon} fa-lg`"></i>
      <CStack class="labels-container" ml="4">
        <omt-bold v-if="!!title">
          {{ title }}
        </omt-bold>
        <omt-p v-if="subtitle" :simple="true">
          <small>{{ subtitle }}</small>
        </omt-p>
        <omt-p v-chakra v-if="!!description">
          {{ description }}
        </omt-p>
      </CStack>
    </CFlex>
    <CFlex class="lead-icons-container">
      <CBox v-for="(leadIcon, index) in leadIcons" :key="index" v-bind:ml="index !== 0 && '4'">
        <i :class="`${leadIcon} fa-lg`"></i>
      </CBox>
    </CFlex>
  </CFlex>
</template>

<script>
import { CFlex, CStack, CBox } from "@chakra-ui/vue";

export default {
  props: ["icon", "hasCountainer", "hasHoverEffect", "title", "description", "link", "leadIcons", "subtitle"],
  components: {
    CFlex,
    CStack,
    CBox
  },
  methods: {
    handleClick() {
      if (this.link) this.$router.push(this.link);
      this.$emit("click");
    }
  }
};
</script>

<style lang="scss" scoped>
.labels-container {
  gap: 6px;
}

.has-hover-effect {
  transition: opacity 0.5s ease;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
}

p {
  margin: 0 !important;
}
.tile-container {
  padding: 1.25rem 1rem;
  box-shadow: 0px 0px 16px 2px #00000026;
}
.lead-icons-container {
  margin-left: auto;
  width: 100%;
  flex: 1;
}
.dark .tile-container {
  background-color: #1f1f1f;
}
</style>
