<template>
  <CFlex w="100%">
    <OmtIcons size="lg" :color="item.Cor" :name="item.Icone" />
    <CBox mx="16px" width="100%">
      <OmtTypography as="h3-bold" mb="8px">{{ item.Mensagem }}</OmtTypography>
      <OmtTypography
        as="p1"
        color="var(--colors-light-primary-gray2)"
        v-html="item.MensagemPopUp.replaceAll('|', '<br />')"
      >
      </OmtTypography>
      <template v-if="item.DtEnvio && item.DtEnvio !== '01/01/0001 00:00'">
        <OmtDivider mt="3" mb="3" />
        <OmtTypography as="p2" color="var(--colors-light-primary-gray2)">{{ item.DtEnvio }}</OmtTypography>
      </template>
    </CBox>
    <CBox width="auto">
      <OmtIcons size="sm" name="chevron-right" />
    </CBox>
  </CFlex>
</template>
<script>
export default {
  name: "HomeNoticeCard",
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
<style lang="scss" scoped></style>
