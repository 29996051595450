<script lang="jsx">
import blog from "@/components/Organisms/Blog";
import Vue from "vue";
import BlogLayout from "@/components/Organisms/Blog/BlogLayout/index.vue";

export default Vue.component("Blog", {
  beforeRouteEnter(to, _, next) {
    const blogPost = blog.find(blog => blog?.id === to?.params?.id);
    if (!blogPost) next(false);
    else next(true);
  },
  data() {
    return {
      component: null
    };
  },
  methods: {
    async getComponent() {
      try {
        const blogPost = blog.find(blog => blog.id === this.$route.params.id);
        const component = await blogPost.asyncComponent;
        this.component = component.default;
      } catch (error) {
        throw new Error(error);
      }
    }
  },
  watch: {
    "$route.params.id": {
      handler: function () {
        this.getComponent();
      },
      deep: true,
      immediate: true
    }
  },
  render() {
    const BlogPost = this.component;

    return (
      <BlogLayout>
        <BlogPost />
      </BlogLayout>
    );
  }
});
</script>
