<template>
  <omint-page>
    <Header v-show="!isFakeModal" name="Welcome" titleLabel="" />
    <CBox v-if="isFakeModal" class="fake-modal-out">
      <CBox class="fake-modal-in"> </CBox>
    </CBox>
    <omint-page-content>
      <template v-if="isEditPage">
        <OmtIcons v-if="isFakeModal" name="close" mb="12px" size="2x" cursor="pointer" @click.prevent="onCloseClick" />
        <router-view></router-view>
      </template>
      <CBox v-else>
        <CBox
          v-if="order > 0 && totalSteps > 0"
          padding="0"
          margin-bottom="32px"
          mx="auto"
          width="calc(100vw - 40px)"
          max-width="1024px"
        >
          <LineStepper :currentStep="step" :totalSteps="totalSteps" :inverseColor="true" />
        </CBox>
        <CBox v-if="tour">
          <CBox v-if="isMobile" cursor="pointer" @click.prevent="onCloseClick">
            <OmtIcons v-if="isLastPage" name="close" mb="32px" size="2x" />
          </CBox>
          <OmtIcons
            :name="tour.icon"
            :mb="mbSubtitle"
            :font-size="iconSize"
            size="inherit"
            color="var(--colors-light-primary-navy-blue)"
          />
          <OmtTypography v-if="tour.subtitle" :as="isLastPage ? 'h1-large' : 'h1-regular'" :mb="mbDescription">
            <span v-html="tour.subtitle" />
            <CFlex v-if="showIconInfo" class="icon-exclamation-whatsapp">
              <OmtIcons
                name="circle-exclamation"
                height="16px"
                width="16px"
                :font-size="'16px'"
                size="inherit"
                ml="2px"
                @mouseleave="showTooltipPhone = false"
                @mouseover="showTooltipPhone = true"
              />
              <CBox
                class="tooltip-phone"
                bg="var(--colors-light-primary-navy-blue)"
                color="var(--colors-white)"
                borderRadius="4px"
                v-if="showTooltipPhone"
              >
                <span>Você pode cadastrar apenas três números de telefone</span>
              </CBox>
            </CFlex>
          </OmtTypography>

          <OmtTypography v-if="tour.description" :as="isLastPage ? 'h1-regular' : 'p1'">
            <span v-html="tour.description" />
          </OmtTypography>

          <router-view></router-view>

          <FixedButtonRow :useFooter="false" :solidColor="true" :fixedSize="tour.accessLabel ? '2x' : '1x'">
            <CFlex direction="column" :w="['100%', '308px !important']">
              <OmtButton
                v-if="tour.accessLabel"
                @click="onGoToClick"
                w="100%"
                :color="inverseButtons ? 'var(--colors-white)' : 'var(--colors-light-primary-navy-blue)'"
                :variant="inverseButtons ? 'solid' : 'ghost'"
              >
                <OmtTypography
                  :as="inverseButtons ? 'h1-bold' : 'button-medium'"
                  :color="inverseButtons ? 'var(--colors-white)' : 'var(--colors-light-primary-navy-blue)'"
                >
                  {{ tour.accessLabel }}
                </OmtTypography>
              </OmtButton>
              <OmtButton @click="onNextClick" w="100%" :variant="inverseButtons ? 'ghost' : 'solid'">
                <OmtTypography
                  :as="!inverseButtons ? 'h1-bold' : 'button-medium'"
                  :color="inverseButtons ? 'var(--colors-light-primary-navy-blue)' : 'var(--colors-white)'"
                  >{{ tour.nextLabel }}
                </OmtTypography>
              </OmtButton>
            </CFlex>
          </FixedButtonRow>
        </CBox>
      </CBox>
    </omint-page-content>
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";
import LineStepper from "@/components/Atoms/LineStepper/index.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import { ROTA_BASE, ROTA_WELCOME_ONBOARD_TOUR } from "@/utils/perfilRouteUtil";
import { isMobile } from "@/utils/utils";

const FIRST_STEP = 0;
const LAST_STEP = 999;

export default {
  name: "Welcome",
  components: { Header, FixedButtonRow, LineStepper },
  data() {
    return {
      prevRoute: "",
      step: 0,
      order: 0,
      tour: null,
      inverseButtons: false,
      isEditPage: false,
      iconSize: "48px",
      mbSubtitle: "24px",
      mbDescription: "16px",
      isLastPage: false,
      isMobile: isMobile(),
      showTooltipPhone: false,
      showIconInfo: false,
      continueFunction: null
    };
  },
  watch: {
    $route(route) {
      if (route?.path) this.prevRoute = route.path;
      this.findStep(route);
    }
  },
  beforeRouteEnter(_, from, next) {
    next(vm => {
      vm.prevRoute = from.path;
    });
  },
  created() {
    this.checkForBiometric();
    this.checkForNativeShare();
    this.checkPermissionForResource("camera");
    this.checkPermissionForResource("microfone");
    this.checkPermissionForResource("location");
    this.onResizeHandler();
    window.addEventListener("resize", this.onResizeHandler);
    this.$root.$on("change-welcome-tour", ({ accessLabel, nextLabel }) => {
      this.tour.accessLabel = accessLabel;
      this.tour.nextLabel = nextLabel;
    });
    this.$root.$on("welcome-continue", continueFunction => {
      this.continueFunction = continueFunction;
    });
  },
  destroy() {
    window.removeEventListener("resize", this.onResizeHandler);
    this.$root.$off("change-welcome-tour");
    this.$root.$off("welcome-continue");
  },
  mounted() {
    this.findStep(this.$route);
    this.$root.$emit("hide-loading");
  },
  computed: {
    ...mapState("myAccount", ["welcomeOnBoardTour", "welcomeOnBoardTourStep"]),
    isFakeModal() {
      if (!this.isMobile) return false;
      return this.isEditPage || this.isLastPage;
    },
    totalSteps() {
      if (!this.welcomeOnBoardTour?.length) return 0;
      const ignoreInitialCard = this.welcomeOnBoardTour.filter(w => w.order > FIRST_STEP && w.order < LAST_STEP);
      if (!ignoreInitialCard?.length) return 0;
      return ignoreInitialCard.length;
    }
  },
  methods: {
    ...mapActions("biometrics", [
      "checkForBiometric",
      "registerListeners",
      "checkForNativeShare",
      "checkPermissionForResource"
    ]),
    ...mapActions("myAccount", ["submitWelcomeOnBoardTour", "checkNextWelcomeOnBoardTour"]),
    ...mapMutations("myAccount", ["SET_WELCOME_ONBOARD_TOUR_STEP"]),
    goHome() {
      this.$root.$emit("show-back-button");
      this.$router.replace(ROTA_BASE);
    },
    onResizeHandler() {
      this.isMobile = isMobile();
    },
    async findStep(route) {
      try {
        this.continueFunction = null;
        let isEditPage = false;
        let isLastPage = false;
        if (!this.welcomeOnBoardTour?.length || !route) {
          // sem fluxo de bem vindo / cadastro
          this.goHome();
          return;
        }
        let tour = null;
        if (route.path === ROTA_WELCOME_ONBOARD_TOUR) {
          // página inicial
          if ("id" in route.query) {
            const id = Number(route.query.id);
            tour = this.welcomeOnBoardTour.find(f => f.id === id);
          } else {
            tour = this.prevRoute?.startsWith(ROTA_WELCOME_ONBOARD_TOUR)
              ? this.welcomeOnBoardTour.find(f => !f.pathTo && (!f.currentResponse || f.currentResponse === "P"))
              : this.welcomeOnBoardTour[0];
          }
        } else {
          // fluxo
          isEditPage = route.path.endsWith("/edit");
          let routePath = route.path.replace(ROTA_WELCOME_ONBOARD_TOUR, "");
          routePath = routePath.replace("/edit", "");
          routePath = routePath.replace("/resume", "");
          tour = this.welcomeOnBoardTour.find(f => f.pathTo === routePath);
        }
        if (!tour)
          tour = this.welcomeOnBoardTour.find(f => f.pathTo && (!f.currentResponse || f.currentResponse === "P"));
        if (!tour) {
          // sem fluxo identificado
          this.goHome();
          return;
        }
        const step = this.welcomeOnBoardTour.indexOf(tour);
        this.$root.$emit("hide-back-button");
        this.$root.$emit("change-title-label", tour.title);
        this.SET_WELCOME_ONBOARD_TOUR_STEP(step);
        if (tour.order === LAST_STEP) {
          this.$root.$emit("change-back-route", ROTA_BASE);
          isLastPage = true;
          await this.checkNextWelcomeOnBoardTour();
        } else if (step === FIRST_STEP) {
          this.$root.$emit("change-back-route", ROTA_BASE);
        } else if (isEditPage) {
          this.$root.$emit("change-back-route", `${ROTA_WELCOME_ONBOARD_TOUR}${tour.pathTo}/resume`);
        } else {
          const tourAnterior = this.welcomeOnBoardTour[step - 1];
          const backRoute = tourAnterior.pathTo
            ? `${ROTA_WELCOME_ONBOARD_TOUR}${tourAnterior.pathTo}/resume`
            : ROTA_WELCOME_ONBOARD_TOUR;
          this.$root.$emit("change-back-route", backRoute);
        }
        if (isEditPage || isLastPage) {
          this.step = 0;
          this.order = 0;
          this.inverseButtons = false;
        } else {
          this.step = step;
          this.order = tour.order;
          this.inverseButtons = tour.order === 0;
        }
        this.tour = { ...tour };
        this.isEditPage = isEditPage;
        this.isLastPage = isLastPage;
        this.getStyleHeader();
      } catch (error) {
        this.goHome();
      }
    },
    async onGoToClick() {
      this.submitWelcomeOnBoardTour({ id: this.tour.id, option: true });
      if (this.tour.pathTo) {
        if (this.tour.pathTo === "/biometry/register") {
          const rota = await this.checkNextWelcomeOnBoardTour();
          // usa a página correta
          this.$router.replace({
            name: "biometry-register",
            params: {
              routeBack: "/welcome/biometry/register/resume",
              routeSucess: rota
            }
          });
          return;
        }
        this.$router.replace(`${ROTA_WELCOME_ONBOARD_TOUR}${this.tour.pathTo}/edit`);
        return;
      }
      const nextBoard = this.welcomeOnBoardTour.find(b => b.idx > this.tour.idx && b.pathTo && !b.currentResponse);
      if (nextBoard?.pathTo) {
        const rota = `${ROTA_WELCOME_ONBOARD_TOUR}${nextBoard.pathTo}/resume`;
        this.$router.replace(rota);
        return;
      }
      if (this.step === FIRST_STEP) {
        const rota = await this.checkNextWelcomeOnBoardTour();
        this.$router.replace(rota);
        return;
      }
      this.onNextClick();
    },
    onCloseClick() {
      if (this.isLastPage) {
        this.onNextClick();
        return;
      }
      this.$router.replace(`${ROTA_WELCOME_ONBOARD_TOUR}${this.tour.pathTo}/resume`);
    },
    async onNextClick() {
      if (this.continueFunction) await this.continueFunction();

      if (this.step === FIRST_STEP) {
        this.$router.push(ROTA_BASE);
        return;
      }
      const rota = await this.checkNextWelcomeOnBoardTour();
      this.$router.replace(rota);
    },
    getStyleHeader() {
      this.showIconInfo = false;
      if (this.isLastPage) {
        this.iconSize = "40px";
        this.mbSubtitle = "32px";
        this.mbDescription = "40px";
        return;
      }

      switch (this.tour.pathTo) {
        case "/meus-contatos":
          this.iconSize = "40px";
          this.mbDescription = "24px";
          break;
        case "/conta-corrente":
          this.iconSize = "40px";
          this.mbDescription = "6px";
          break;
        case "/whatsapp":
          this.iconSize = "40px";
          this.mbDescription = "8px";
          this.showIconInfo = true;
          break;
        case "/administrator-registrer":
          this.iconSize = "40px";
          this.mbDescription = "12px";
          break;
        default:
          this.iconSize = "48px";
          this.mbSubtitle = "24px";
          this.mbDescription = "16px";
      }
    }
  }
};
</script>
<style scoped lang="scss">
.fake-modal-out {
  background-color: black;
  opacity: 30%;
  padding-top: 16px;
}
.fake-modal-in {
  border-radius: 16px 16px 0px 0px;
  border-top-color: black;
  border-top-width: 2px;
  border-top-style: solid;
  background-color: white;
  opacity: 1;
  height: 16px;
}

.tooltip-phone {
  position: absolute;
  min-height: 40px;
  transform: translateY(20px);
  padding: 2px 4px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  font-weight: 400 !important;
  max-width: 200px;
}
.tooltip-phone::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 3px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #172972 transparent;
}

.icon-exclamation-whatsapp {
  display: inline-block;
  margin-left: 6px;
}
</style>
