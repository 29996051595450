<template>
  <div>
    <div v-if="loading" class="spinner-block">
      <div class="spinner-box">
        <dot-loader :loading="loading" color="rgb(16, 42, 69)" size="60px"></dot-loader>
      </div>
    </div>
  </div>
</template>

<script>
import DotLoader from "vue-spinner/src/DotLoader";

export default {
  name: "Loading",
  data() {
    return {
      loading: true
    };
  },
  components: {
    DotLoader
  },
  mounted() {
    this.$root.$on("show-loading", () => {
      this.showHideLoading(true);
    });
    this.$root.$on("hide-loading", () => {
      this.showHideLoading(false);
    });
  },
  destroyed() {
    this.$root.$off("show-loading");
    this.$root.$off("hide-loading");
  },
  methods: {
    showHideLoading(loading) {
      this.loading = loading;
    }
  }
};
</script>
<style>
.spinner-block {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0.75;
  background-color: #f0f0f0;
  z-index: var(--zIndices-toast);
}

.spinner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  z-index: var(--zIndices-toast);
}
</style>
