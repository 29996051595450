<template>
  <CModal
    :is-centered="isCentered"
    size="sm"
    :on-close="closeProtocolModal"
    :closeOnOverlayClick="true"
    :is-open="true"
  >
    <CModalContent borderRadius="4px" ref="content">
      <CModalBody
        borderRadius="4px"
        :class="theme"
        pb="0"
        pt="var(--space-3)"
        px="var(--space-3)"
        style="color: var(--colors-light-primary-gray1)"
      >
        <CBox position="relative">
          <i class="fa fa-md fa-times" @click="closeProtocolModal"></i>
        </CBox>
        <CBox class="modal-content">
          <i :class="`fa fa-md ${success ? 'fa-check' : 'error fa-times'}`"></i>

          <omt-h1 v-if="success"> Solicitação enviada! </omt-h1>
          <CBox v-else>
            <omt-h1> Não foi possivel registrar o pedido. </omt-h1>
            <omt-h1> Serviço temporariamente indisponível. </omt-h1>
          </CBox>
          <CBox v-if="protocol || description">
            <omt-p v-if="protocol"> Protocolo: {{ protocol }} </omt-p>
            <omt-p v-if="description"> {{ description }} </omt-p>
          </CBox>
        </CBox>
      </CModalBody>
      <CModalFooter :class="theme">
        <omt-button :colorInverse="true" :onClick="emitConclude">
          <omt-p>CONCLUIR</omt-p>
        </omt-button>
      </CModalFooter>
    </CModalContent>
    <CModalOverlay />
  </CModal>
</template>

<script>
import { CBox, CFlex, CStack, CModal, CModalOverlay, CModalFooter, CModalContent, CModalBody } from "@chakra-ui/vue";
import Options from "@/components/Molecules/Options";
import AccountCard from "@/components/Organisms/BankAccount/AccountCard.vue";
import { mapState } from "vuex";

export default {
  name: "ProtocolModal",
  props: {
    protocol: {
      type: String
    },
    description: {
      type: String
    },
    success: {
      type: Boolean,
      default: true
    },
    isCentered: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CBox,
    CFlex,
    Options,
    CStack,
    AccountCard,
    CModal,
    CModalFooter,
    CModalOverlay,
    CModalContent,
    CModalBody
  },
  computed: {
    ...mapState("saude", ["theme"])
  },
  methods: {
    closeProtocolModal() {
      this.$emit("close");
    },
    emitConclude() {
      this.$emit("conclude");
    }
  }
};
</script>

<style lang="scss" scoped>
h1,
p {
  text-align: center;
  margin: 0;
}

button > .p {
  color: white !important;
}

div[data-chakra-component="CModalBody"].dark {
  border-left: 1px solid currentColor;
  border-top: 1px solid currentColor;
  border-right: 1px solid currentColor;
}

footer.dark {
  border-left: 1px solid currentColor;
  border-bottom: 1px solid currentColor;
  border-right: 1px solid currentColor;
}

.fa-check,
.error.fa-times {
  border-radius: 50%;
  outline: 4px solid currentColor;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.8);
}

.fa-times:not(.error) {
  position: absolute;
  right: 0;
  cursor: pointer;
  transition: opacity ease 0.5s;
  &:hover {
    opacity: 0.6;
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--space-3);
}

::v-deep footer {
  button {
    width: 100%;
  }
  & > button {
    border: 1px solid #68788c !important;
    margin-right: var(--space-3);
  }
}
</style>

<style lang="scss">
.vm-content {
  ul {
    padding-left: 22px;
  }

  li + li {
    margin-top: 16px;
  }
}
</style>
