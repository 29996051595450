<template>
  <transition>
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: "Slider",
  data: function () {
    return {
      transitionName: "slide-left"
    };
  },
  watch: {
    $route(to, from) {
      this.transitionName = to.meta.routerSequence < from.meta.routerSequence ? "slide-right" : "slide-left";
    }
  }
};
</script>

<style scoped>
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.4s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(20em, 0) scale3d(0.9, 0.9, 0.9);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-20em, 0) scale3d(0.9, 0.9, 0.9);
}
</style>
