import AvaliacaoDoPrestador from "@/pages/avaliacao-do-prestador/index.vue";
import AvaliacaoDoPrestadorList from "@/pages/avaliacao-do-prestador/list.vue";
import AvaliacaoDoPrestadorDetails from "@/pages/avaliacao-do-prestador/details.vue";
import AvaliacaoDoPrestadorResume from "@/pages/avaliacao-do-prestador/resume.vue";

const routes: Route[] = [
  {
    path: "/avaliacao-do-prestador",
    component: AvaliacaoDoPrestador,
    children: [
      {
        path: "",
        name: "provider.evaluation",
        component: AvaliacaoDoPrestadorList,
        meta: {
          backTo: "/",
          routerSequence: 0
        }
      },
      {
        path: "detalhes/:id",
        name: "provider.evaluation.detail",
        component: AvaliacaoDoPrestadorDetails,
        meta: {
          backTo: "/avaliacao-do-prestador",
          validPath: ["/avaliacao-do-prestador"]
        }
      },
      {
        path: "resumo/:id",
        name: "provider.evaluation.resume",
        component: AvaliacaoDoPrestadorResume,
        meta: {
          backTo: "/avaliacao-do-prestador",
          validPath: ["/avaliacao-do-prestador"]
        }
      }
    ]
  }
];

export default routes;
