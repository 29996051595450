<template>
  <CBox>
    <OmtTypography as="h2-bold" mb="16px" v-show="!!question.text"> {{ question.text }} </OmtTypography>
    <CFlex v-if="question.options" justify-content="center">
      <OmtButton
        v-for="(option, index) in question.options"
        :key="index"
        height="42px"
        :max-width="['100%', '290px']"
        :variant="value === option.id ? 'solid' : 'outline'"
        flex="1"
        border-color="var(--colors-light-secondary-divider)"
        :mr="index === 0 && '16px'"
        @click="value = option.id"
      >
        <OmtTypography
          :color="value === option.id ? 'white' : 'var(--colors-light-primary-gray1)'"
          :as="['h3-bold', 'h2-bold']"
        >
          {{ option.label }}
        </OmtTypography>
      </OmtButton>
    </CFlex>
  </CBox>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      default: () => ({
        text: "",
        options: []
      })
    }
  },
  data() {
    return {
      value: 0
    };
  },
  watch: {
    value: {
      handler(val) {
        this.$emit("change", val);
      },
      immediate: true
    }
  }
};
</script>
