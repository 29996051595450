<template>
  <CPseudoBox data-cy="image-uploader">
    <CBox :overflow="['auto', ' scroll']" :max-height="['450px', '320px']">
      <template v-for="(picture, index) in pictures">
        <CBox
          v-if="picture.source"
          :key="index"
          :mb="index < pictures.length - 1 && '22px'"
          class="uploaded-picture"
          p="12px"
          display="flex"
          justify-content="center"
          borderRadius="8px"
          border="1px dashed var(--colors-light-secondary-divider)"
        >
          <CFlex
            width="100%"
            borderRadius="8px"
            bg="var(--colors-light-primary-light-gray)"
            p="8px"
            align-items="center"
            justify-content="space-between"
          >
            <CFlex width="95%" mr="6px" align-items="center">
              <ImagePreviewer size="xs" :onlyMobileSize="true" :hasBorder="false" :source="picture.source" />
              <OmtTypography ml="16px" as="h3-semi-bold">
                {{ picture.name }}
              </OmtTypography>
            </CFlex>
            <OmtIcons
              fontSize="20px"
              data-cy="image-uploader-exclude-icon"
              name="trash-can"
              cursor="pointer"
              @click="handleExclude(index)"
            />
          </CFlex>
        </CBox>

        <CBox
          v-else
          :key="index"
          :mb="index < pictures.length - 1 && '22px'"
          p="22px"
          class="uploaded-picture"
          display="flex"
          height="75px"
          align-items="center"
          justify-content="center"
          borderRadius="8px"
          border="1px dashed var(--colors-light-secondary-divider)"
          cursor="pointer"
          position="relative"
        >
          <OmtTypography color="var(--colors-light-primary-navy-blue)" as="button-small">
            Tirar foto ou escolher da galeria
          </OmtTypography>
          <input
            :accept="acceptedFiles"
            type="file"
            @change="previewFile($event, picture)"
            class="upload-input"
            v-chakra
            position="absolute"
            inset="0"
            opacity="0"
            cursor="pointer"
            style="text-indent: -100%"
          />
        </CBox>
      </template>
    </CBox>

    <CFlex
      justify="flex-end"
      :mb="['22px']"
      :position="['sticky']"
      :bottom="['0']"
      :background="['white']"
      :pt="['10px']"
    >
      <OmtTypography as="p2" data-cy="image-uploader-counter">
        {{ uploadedPicturesLength }}/{{ length }}
      </OmtTypography>
    </CFlex>

    <OmtStickedModal v-if="excludeModalIsOpen" @close="handleClose">
      <CBox p="0 16px">
        <OmtTypography as="h1-bold" mb="24px"> Excluir imagem </OmtTypography>
        <OmtTypography as="p1"> Tem certeza que deseja excluir essa imagem? </OmtTypography>
      </CBox>

      <template #footer>
        <CBox :p="['48px 16px 28px 16px', '16px']" flexDirection="column" w="100%">
          <OmtButton :w="['100%', '47%']" mb="16px" @click="deletePicture"> Excluir </OmtButton>
          <OmtButton
            :w="['100%', '47%']"
            :mb="['10px', '16px']"
            :ml="['0px', '16px']"
            variant="outline"
            @click="handleClose"
          >
            Cancelar
          </OmtButton>
        </CBox>
      </template>
    </OmtStickedModal>
  </CPseudoBox>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ImagePreviewer from "@/components/Molecules/ImagePreviewer";

/**
 * Component extends https://vue.chakra-ui.com/pseudobox
 *
 * @prop {any} $props any props listed here: https://vue.chakra-ui.com/style-props
 *
 * @prop {Number} maxFileSize
 * @prop {Number} length
 * @prop {String} acceptedFiles
 * @prop {Array<{ source: string, thumbnail: string, name: string }>} initialValue
 */

export default {
  components: {
    ImagePreviewer
  },
  props: {
    initialValue: {
      type: Array,
      default: () => [
        {
          source: "",
          thumbnail: "",
          name: ""
        }
      ]
    },
    maxFileSize: {
      type: Number,
      default: 0
    },
    acceptedFiles: {
      type: String,
      default: "application/pdf, image/png, image/jpeg, image/heic"
    },
    length: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      excludeModalIsOpen: false,
      pictures: [
        {
          source: "",
          thumbnail: "",
          name: ""
        }
      ],
      pictureIndexToExclude: 0
    };
  },
  computed: {
    uploadedPicturesLength() {
      const uploadedPictures = this.pictures.filter(picture => !!(picture.source && picture.thumbnail && picture.name));
      return uploadedPictures.length;
    },
    canAddNewField() {
      const hasEmptySlot = this.pictures.filter(picture => !picture.source && !picture.thumbnail && !picture.name);
      const reachedLimit = this.pictures.length === this.length;

      return !hasEmptySlot.length && !reachedLimit;
    }
  },
  methods: {
    ...mapActions("saude", ["uploadFile"]),
    handleExclude(index) {
      this.excludeModalIsOpen = true;
      this.pictureIndexToExclude = index;
    },
    deletePicture() {
      this.$delete(this.pictures, this.pictureIndexToExclude);
      this.handleClose();
      this.$emit("change", this.pictures);

      if (this.pictures.length === 0) {
        this.pictures = [
          {
            source: "",
            thumbnail: "",
            name: ""
          }
        ];
      }
    },
    handleClose() {
      this.excludeModalIsOpen = false;
    },
    showExceededLimitWarning() {
      this.$toast({
        description: `Limite máximo de ${this.length} fotos excedido`,
        status: "error",
        duration: 10000,
        position: "top"
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const pics = document.querySelectorAll(".uploaded-picture");
        const lastPic = pics[pics.length - 1];
        lastPic.scrollIntoView({
          behavior: "smooth"
        });
      });
    },
    async previewFile(event, picture) {
      let filename = "";
      let blobFile = null;
      try {
        filename = event.target.files[0].name;
        blobFile = event.target.files[0];
      } catch (error) {
        window.$log.error("arquivo inválido", error);
      }
      if (!blobFile) return;
      if (this.maxFileSize > 0) {
        const maxFileSize = this.maxFileSize * 1000 * 1000;
        if (blobFile.size > maxFileSize) {
          this.$toast({
            description: `O arquivo não pode exceder ${this.maxFileSize}mb`,
            status: "error",
            duration: 10000,
            position: "top"
          });
          return;
        }
      }
      this.$emit("uploadStart");
      await this.$root.$emit("show-loading");
      let res = {};
      let hasUploadError = false;
      try {
        res = await this.uploadFile({
          filename,
          blobFile
        });
      } catch (error) {
        hasUploadError = true;
      } finally {
        await this.$root.$emit("hide-loading");
        this.$emit("uploadEnd");
      }
      if (hasUploadError || !res?.Data) {
        await Swal.fire({
          title: "Erro",
          html: "Não foi possível enviar o documento.<br/>Verifique sua conexão com a internet e tente novamente.<br/>Se for possível, tente reduzir o tamanho do documento",
          confirmButtonText: "OK",
          icon: "error"
        });
        return;
      }
      const uploadedPicture = res.Data;
      const errorMessages = uploadedPicture.filter(pic => pic.message !== "OK");

      if (errorMessages.length) {
        await Swal.fire({
          title: "Alerta",
          text: errorMessages.map(error => error.message).join(", "),
          confirmButtonText: "OK",
          icon: "warning"
        });
      }
      if (this.length > 0) {
        const imagensAnexadas = this.pictures.filter(p => !!p.source)?.length ?? 0;
        const totalImagens = imagensAnexadas + uploadedPicture.length;
        if (totalImagens > this.length) {
          this.showExceededLimitWarning();
          return;
        }
      }

      if (uploadedPicture.length === 1) {
        const [singlePic] = uploadedPicture;
        const { originalFileName, thumbUrl, fileRemoteUrl } = singlePic;
        picture.source = fileRemoteUrl;
        picture.thumbnail = thumbUrl;
        picture.name = originalFileName;
      } else {
        uploadedPicture.forEach((_picture, uploadedIndex) => {
          if (uploadedIndex === 0) {
            const { originalFileName, thumbUrl, fileRemoteUrl } = _picture;

            picture.thumbnail = thumbUrl;
            picture.source = fileRemoteUrl;
            picture.name = originalFileName;
          } else if (this.pictures.length + 1 <= this.length) {
            const { originalFileName, thumbUrl, fileRemoteUrl } = _picture;

            this.pictures.push({
              source: fileRemoteUrl,
              name: originalFileName,
              thumbnail: thumbUrl
            });
          }
        });
      }

      this.$emit("change", this.pictures);
      this.scrollToBottom();
    }
  },
  watch: {
    initialValue: {
      handler(newInitialValue) {
        if (newInitialValue.length) {
          this.pictures = newInitialValue;
        }
      },
      immediate: true
    },
    pictures: {
      handler(newPics) {
        const hasEmptySlot = newPics.filter(picture => !picture.source && !picture.thumbnail && !picture.name);
        const reachedLimit = newPics.length === this.length;
        const canAdd = !hasEmptySlot.length && !reachedLimit;

        if (canAdd) {
          this.pictures.push({
            source: "",
            thumbnail: "",
            name: ""
          });

          this.scrollToBottom();
        }
      },
      deep: true
    }
  }
};
</script>
