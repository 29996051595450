<template>
  <OmtGrid :minHeight="['auto', '629px']">
    <OmtRow mb="24px">
      <CBox>
        <OmtTypography as="h1-bold" mb="24px"> Envio das imagens </OmtTypography>

        <OmtTypography as="p1" :display="['block', 'none']">
          <b> Insira as imagens das Notas Fiscais, Recibos, e se necessário, documentos complementares </b>
          (pedido médico, relatórios e etc):
        </OmtTypography>
      </CBox>
    </OmtRow>

    <OmtRow :desktopStart="7">
      <ImageUploader @change="onImageUploaderChange" :initialValue="pictures" :length="limitFiles" :maxFileSize="25" />
    </OmtRow>

    <OmtRow :desktopStart="1" :desktopEnd="6" :gridRowStart="['initial', '2']">
      <CBox>
        <OmtTypography as="p1" mb="24px" :display="['none', 'block']">
          <b> Insira as imagens das Notas Fiscais, Recibos, e se necessário, documentos complementares </b>
          (pedido médico, relatórios e etc):
        </OmtTypography>

        <CFlex mb="16px">
          <OmtIcons name="check" size="lg" mr="16px" color="var(--colors-light-support-success)" />
          <OmtTypography as="p1"> <b>Arquivos suportados:</b> .jpg, .png, .heic e .pdf. com até 25mb. </OmtTypography>
        </CFlex>

        <CFlex mb="16px">
          <OmtIcons name="check" size="lg" mr="16px" color="var(--colors-light-support-success)" />
          <OmtTypography as="p1"> Envie apenas <b>um documento por foto</b>. </OmtTypography>
        </CFlex>

        <CFlex mb="16px">
          <OmtIcons name="check" size="lg" mr="16px" color="var(--colors-light-support-success)" />
          <OmtTypography as="p1"> Certifique-se de que todas as informações das imagens estão legíveis. </OmtTypography>
        </CFlex>
      </CBox>
    </OmtRow>

    <OmtRow position="sticky" bottom="0" pb="18px" pt="24px" bg="white" :desktopStart="7" :desktopEnd="12">
      <OmtButton @click="handleClick" :disabled="!canSubmit"> Continuar </OmtButton>
    </OmtRow>
  </OmtGrid>
</template>

<script>
import ImageUploader from "@/components/Molecules/ImageUploader";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    ImageUploader
  },
  async mounted() {
    if (!this.newRefund || !this.newRefund.contacorrente) {
      this.$router.push({ name: "refund.new.create" });
      return;
    }
    try {
      if (!this.addPermission) {
        this.$router.push({ name: "refund.new" });
        return;
      }

      await this.$root.$emit("show-loading");

      this.limitFiles = await this.fetchMaxFiles();
    } finally {
      await this.$root.$emit("hide-loading");
    }

    if (this.newRefund && "anexos" in this.newRefund) {
      this.pictures = this.newRefund.anexos;
    }
  },
  data() {
    return {
      pictures: [],
      limitFiles: 0
    };
  },
  computed: {
    ...mapState("refund", ["newRefund", "addPermission"]),
    canSubmit() {
      const uploadedPictures = this.newRefund?.anexos?.filter(pic => pic.source && pic.name);
      return uploadedPictures?.length > 0;
    }
  },
  methods: {
    ...mapMutations("refund", ["SET_NEW_REFUND"]),
    ...mapActions("refund", ["fetchMaxFiles"]),
    handleClick() {
      this.$router.push({
        name: "refund.new.create.comments"
      });
    },
    onImageUploaderChange(pictures) {
      this.SET_NEW_REFUND({
        ...this.newRefund,
        anexos: pictures
      });
    }
  }
};
</script>
