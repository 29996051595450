<template>
  <OmtGrid>
    <OmtRow :m="['40px -16px 32px', '52px -40px 48px']" bg="var(--colors-light-primary-light-gray)">
      <CBox :p="['28px 16px 36px', '32px 40px 48px']">
        <OmtTypography :as="['h2-bold', 'h1-semi-bold']" mb="24px"> Revalidação obrigatória </OmtTypography>

        <OmtTypography :as="['p1', 'p3']" mb="24px">
          A revalidação deste contato como administrador da sua conta é necessária para manter a sua segurança.
          Selecione a periodicidade em que você deseja fazer essa confirmação:
        </OmtTypography>

        <CFlex flex-direction="column" justify-content="space-between" :min-height="['72px', '62px']">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> Revalidação obrigatória </OmtTypography>
          <CFlex>
            <OmtButton
              v-for="(t, i) in parametros.TiposRevalidacao"
              :key="`s-${t.Codigo}`"
              :variant="tpRevalidacao === t.Codigo ? 'solid' : 'outline'"
              :height="['42px', '32px']"
              max-width="154px"
              width="50%"
              @click="tpRevalidacao = t.Codigo"
              border-color="var(--colors-light-secondary-divider)"
              :mr="i < parametros.TiposRevalidacao.length ? '16px' : ''"
            >
              <OmtTypography
                :as="['h3-bold', 'h2-bold']"
                :color="tpRevalidacao === t.Codigo ? 'white' : 'var(--colors-light-primary-gray1)'"
              >
                {{ t.Descricao }}
              </OmtTypography>
            </OmtButton>
          </CFlex>
        </CFlex>
      </CBox>
    </OmtRow>

    <OmtRow :desktopStart="5" :desktopEnd="8">
      <OmtButton @click="showTokenModal = true" :disabled="tpRevalidacao === ''"> Salvar </OmtButton>
    </OmtRow>
    <TokenModal
      v-if="showTokenModal"
      tokenKey="terceiro"
      service="/terceiros/prorrogar"
      v-on:cancel="showTokenModal = false"
      v-on:valid="validToken"
    />
  </OmtGrid>
</template>
<script>
import { mapActions, mapState } from "vuex";

import TokenModal from "@/components/Organisms/TokenModal";
export default {
  data() {
    return {
      showTokenModal: false,
      tokenService: "",
      idPessoa: 0,
      tpRevalidacao: ""
    };
  },
  components: {
    TokenModal
  },
  async mounted() {
    try {
      await this.$root.$emit("show-loading");

      await this.fetchParams();

      if (this.$route.params.id) {
        const id = this.$route.params.id;
        await this.fetchNomineeDetails({ id });
        this.idPessoa = id;
      }
    } catch (error) {
      this.$toast({
        description: error.message,
        status: "error",
        duration: 10000,
        position: "top"
      });
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },

  methods: {
    ...mapActions("nominees", ["fetchParams", "reactivateNomiee", "fetchNomineeDetails"]),
    validToken({ auth }) {
      this.showTokenModal = false;
      this.submit({ auth });
    },
    async submit({ auth }) {
      try {
        await this.$root.$emit("show-loading");
        await this.reactivateNomiee({
          autenticacao: auth,
          idPessoa: this.idPessoa,
          tpRevalidacao: this.tpRevalidacao
        });
        this.$toast({
          description: "Administrador atualizado com sucesso!",
          status: "success",
          duration: 10000,
          position: "top"
        });
      } catch (error) {
        this.$toast({
          description: error.message,
          status: "error",
          duration: 10000,
          position: "top"
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
    }
  },
  computed: {
    ...mapState("nominees", ["parametros"])
  }
};
</script>
