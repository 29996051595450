<template>
  <omint-page-content>
    <TokenModal
      v-if="showTokenModal"
      :id="selectedAccount ? selectedAccount.id_ctacredito : 0"
      tokenKey="alterar-cc"
      service="/conta-corrente"
      v-on:cancel="() => showModal(false, null, '')"
      v-on:valid="({ auth }) => validToken(auth, selectedAccount, actionType)"
    />
    <CFlex align="center" mb="var(--space-5)">
      <omt-h1 style="text-transform: uppercase">Contas cadastradas</omt-h1>
      <i v-chakra class="fa fa-lg fa-info" @click="showInfoModal = true"></i>
    </CFlex>

    <CFlex align="center" mb="var(--space-3)">
      <i class="fas fa-lg fa-user" v-chakra mr="var(--radii-lg)"></i>
      <omt-p> Favorecido </omt-p>
    </CFlex>

    <Options
      id="optBeneficiaryId"
      name="optBeneficiary"
      :solid="true"
      :isLoading="isLoading"
      :preSelected="selectedBeneficiary ? selectedBeneficiary : currentBeneficiary"
      :options="beneficiaries"
      :onSelection="onBeneficiarySelect"
      placeholder="Selecione o favorecido"
    />

    <CBox class="account-cards" v-if="selectedBeneficiary">
      <omt-p v-if="isChanging" class="account-cards-disclaimer text-danger">
        <i class="fa fa-lg fa-info"></i>
        Existe uma solicitação de alteração em andamento! <br />
        Aguarde o processamento para solicitar uma nova alteração.
      </omt-p>
      <AccountCard
        v-for="a in accounts"
        :key="a.id_ctacredito"
        :account="a"
        :isChanging="isChanging"
        :isMainAccount="a.tp_principal === 'S'"
        :isTitular="titular"
        @select="account => showModal(true, account, 'main')"
        @delete="account => showModal(true, account, 'delete')"
      />
    </CBox>

    <omt-button
      v-if="canCreate"
      :onClick="redirectToCreate"
      :disabled="isLoading"
      class="flat-button"
      :colorInverse="true"
      v-chakra
      mb="var(--space-5)"
      >Adicionar nova conta</omt-button
    >

    <InfoModal v-if="showInfoModal" @close="showInfoModal = false" />
    <ProtocolModal
      v-if="protocolModal.show"
      :isCentered="true"
      :protocol="protocolModal.protocol"
      :description="protocolModal.description"
      :success="protocolModal.success"
      @close="protocolModal.show = false"
      @conclude="protocolModal.show = false"
    />
  </omint-page-content>
</template>

<script>
import { CBox, CFlex } from "@chakra-ui/vue";
import InfoModal from "@/components/Organisms/BankAccount/Modals/InfoModal.vue";
import ProtocolModal from "@/components/Organisms/BankAccount/Modals/ProtocolModal.vue";
import Options from "@/components/Molecules/Options";
import AccountCard from "@/components/Organisms/BankAccount/AccountCard.vue";
import TokenModal from "@/components/Organisms/TokenModal";

import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "BankAccountHome",
  components: {
    CBox,
    CFlex,
    Options,
    InfoModal,
    AccountCard,
    TokenModal,
    ProtocolModal
  },
  data() {
    return {
      isLoading: false,
      isChanging: false,
      showInfoModal: false,
      accounts: [],
      selectedAccount: 0,
      actionType: "",
      showTokenModal: false,
      protocolModal: {
        show: false,
        protocol: "",
        description: "",
        success: false
      }
    };
  },
  async mounted() {
    try {
      await this.$root.$emit("show-loading");
      await this.fetchBeneficiaries();
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  methods: {
    ...mapActions("bankAccount", [
      "hasActiveTicket",
      "fetchBeneficiaries",
      "fetchAccounts",
      "submitBeneficiaryBankInfos"
    ]),
    ...mapMutations("bankAccount", ["SET_SELECTED_BENEFICIARY"]),
    async onBeneficiarySelect(value) {
      if (this.selectedBeneficiary === value && this.accounts?.length > 0) return;
      if (this.isLoading) return;
      try {
        this.isLoading = true;
        await Promise.all([
          this.SET_SELECTED_BENEFICIARY(value),
          this.hasActiveTicket({ beneficiaryCode: value }).then(r => (this.isChanging = r)),
          this.fetchAccounts({ beneficiaryCode: value }).then(r => (this.accounts = r))
        ]);
      } finally {
        this.isLoading = false;
      }
    },
    renderProtocolModal({ protocol, success, description }) {
      this.protocolModal.show = true;
      this.protocolModal.protocol = protocol;
      this.protocolModal.success = success;
      this.protocolModal.description = description;
    },
    showModal(value, account, actionType) {
      this.showTokenModal = value;
      this.selectedAccount = account;
      this.actionType = actionType;
    },
    async validToken(auth, account, actionType) {
      this.showModal(false);
      switch (actionType) {
        case "main":
          await this.handleSelectMainAccount(auth, account);
          break;
        case "delete":
          await this.handleDelete(auth, account);
          break;
        default:
          break;
      }
      this.selectedAccount = null;
      this.actionType = "";
    },
    async handleSelectMainAccount(auth, account) {
      if (this.isChanging) return;
      this.isChanging = true;
      let protocolo = "";
      try {
        await this.$root.$emit("show-loading");
        protocolo = await this.submitBeneficiaryBankInfos({
          beneficiario: this.selectedBeneficiary,
          idConta: account.id_ctacredito,
          banco: account.cd_banco,
          agencia: account.cd_agencia,
          conta: account.nr_conta,
          digito: account.at_dac,
          excluir: false,
          principal: account.tp_principal === "S" ? false : true,
          emailContato: "",
          telefoneContato: "",
          autenticacao: auth
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
      if (protocolo) {
        this.renderProtocolModal({
          protocol: protocolo,
          success: true,
          description: "Em até 05 minutos esta solicitação será processada."
        });
      } else {
        this.isChanging = false;
        this.renderProtocolModal({
          protocol: "",
          success: false,
          description: ""
        });
      }
    },
    async handleDelete(auth, account) {
      if (this.isChanging) return;
      this.isChanging = true;
      let protocolo = "";
      try {
        await this.$root.$emit("show-loading");
        protocolo = await this.submitBeneficiaryBankInfos({
          beneficiario: this.selectedBeneficiary,
          idConta: account.id_ctacredito,
          banco: account.cd_banco,
          agencia: account.cd_agencia,
          conta: account.nr_conta,
          digito: account.at_dac,
          excluir: true,
          principal: account.tp_principal === "S",
          emailContato: "",
          telefoneContato: "",
          autenticacao: auth
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
      if (protocolo) {
        this.renderProtocolModal({
          protocol: protocolo,
          success: true,
          description: "Em até 05 minutos esta solicitação será processada."
        });
      } else {
        this.isChanging = false;
        this.renderProtocolModal({
          protocol: "",
          success: false,
          description: ""
        });
      }
    },
    redirectToCreate() {
      this.$router.push("/conta-corrente/criar");
    }
  },
  computed: {
    ...mapState("bankAccount", ["beneficiaries", "selectedBeneficiary"]),
    ...mapState("saude", ["titular", "vinculo"]),
    currentBeneficiary() {
      return this.vinculo?.substring(8, 10) ?? "00";
    },
    canCreate() {
      return !this.isChanging && this.selectedBeneficiary && this.accounts.length < 2;
    }
  }
};
</script>

<style lang="scss" scoped>
h1,
p {
  margin: 0;
}

button {
  width: 100%;
  cursor: pointer;
  transition: opacity ease 0.5s;
  &:hover {
    opacity: 0.6;
  }
}

.text-danger {
  color: #e80606 !important;
}

.fa-info {
  border-radius: 50%;
  outline: 4px solid currentColor;
  padding: 10px;
  width: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.5);
  cursor: pointer;
  transition: opacity ease 0.5s;
  &:hover {
    opacity: 0.6;
  }
}

.flat-button {
  text-transform: uppercase;
  margin-top: var(--space-5);
}

.account-cards {
  display: flex;
  flex-direction: column;
  gap: var(--space-3);

  &-disclaimer {
    display: flex;
    align-items: center;
    i {
      cursor: initial;
      &:hover {
        opacity: 1 !important;
      }
    }
  }
}
</style>
