import {
  ObterOptin,
  AceiteOptinUsuario,
  AlterarSenha,
  ProgramaScreening,
  ProgramaScreeningAceite,
  ObterBlogPosts,
  ObterImagem,
  OnBoardTourPendente,
  ObterRegrasOnBoardTour,
  EnviarTokenValidacao,
  InformarCodigoValidacao,
  SituacaoSessao,
  PermissaoFuncionalidade,
  RegistrarAcesso,
  RespostaOnBoardTour,
  VerificarAlteracaoSenhaPendente
} from "@/api/minhaConta";
import { EnviarCodigoRecuperarSenha, InformarCodigoRecuperarSenha } from "../api/api";
import { ExisteChamadoPendenteProcessamento, RegistroNavegacaoRef } from "@/api/atendimento";
import { checkUpdatePermission, allowUpdate } from "@/utils/checkReadonlyUtil";
import { ROTA_BASE, ROTA_WELCOME_ONBOARD_TOUR } from "@/utils/perfilRouteUtil";
import { isMobile } from "@/utils/utils";

/**
 * terms: {Descricao: String, Id: Int, Link: String}
 * acceptance: {[Id]: 'S' | 'N'}
 * */
const initialState = {
  terms: [],
  acceptance: {},
  resetPassword: {},
  screeningTerms: {},
  blogPosts: [],
  hasWelcomeOnboard: false,
  welcomeOnBoardTour: [],
  welcomeOnBoardTourStep: 0,
  welcomeOnBoardTourCode: "",
  welcomeOnBoardTourAuth: 0,
  welcomeOnBoardTourRules: {},
  changePasswordRequired: false
};

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions: {
    async fetchTerms(context) {
      const terms = await ObterOptin({
        login: context.rootState.saude.username
      });

      if (terms && terms.Data) {
        context.commit("SET_TERMS", terms.Data);
      }
    },
    async saveAndCheckNextWelcomeOnBoardTour(context) {
      await context.dispatch(context.state.welcomeOnBoardTourRules.action, null, { root: true });
      return await context.dispatch("checkNextWelcomeOnBoardTour");
    },
    async checkNextWelcomeOnBoardTour(context) {
      const idx = context.state.welcomeOnBoardTourStep;
      if (idx !== null) {
        await context.dispatch("submitWelcomeOnBoardTour", {
          id: context.state.welcomeOnBoardTour[idx].id,
          option: false
        });
      } else {
        context.commit("SET_WELCOME_ONBOARD_TOUR_STEP", null);
        return ROTA_BASE;
      }
      const nextBoard = context.state.welcomeOnBoardTour.find(b => b.idx > idx && !b.currentResponse);
      if (nextBoard) {
        return nextBoard.pathTo
          ? `${ROTA_WELCOME_ONBOARD_TOUR}${nextBoard.pathTo}/resume`
          : `${ROTA_WELCOME_ONBOARD_TOUR}?id=${nextBoard.id}`; //avoid redundance
      }
      context.commit("SET_WELCOME_ONBOARD_TOUR_STEP", null);
      return ROTA_BASE;
    },
    async fetchWelcomeOnBoardTour(context) {
      if (!allowUpdate()) {
        context.commit("SET_HAS_WELCOME_TOUR", false);
        return;
      }
      // fluxo aparece apenas em mobile
      if (!isMobile()) {
        context.commit("SET_HAS_WELCOME_TOUR", false);
        return;
      }

      const v25 = Number.parseInt(localStorage.getItem("native_version")) >= 25;
      const onNative = context.rootGetters["biometrics/onNative"];
      const hasBiometric = context.rootState.biometrics.hasBiometric;
      const allowSettings = (v25 && onNative) || hasBiometric;
      // verifica se existe alguma etapa do fluxo pendente
      try {
        const hasOnBoardTour = await OnBoardTourPendente({
          vinculo: context.rootState.saude.vinculo,
          configuracoes: allowSettings
        });
        context.commit("SET_HAS_WELCOME_TOUR", hasOnBoardTour?.Data ?? false);
      } catch {
        context.commit("SET_HAS_WELCOME_TOUR", false);
      }
    },
    async checkhWelcomeOnBoardTourPages(context) {
      try {
        if (!context.state.hasWelcomeOnboard) return;
        let rotas = [];
        (context.rootState.saude.menu ?? []).forEach(m => {
          if (m.View) rotas.push(m.View);
        });
        (context.rootState.saude.menusFavoritos ?? []).forEach(m => {
          if (m.View) rotas.push(m.View);
        });
        rotas = [...new Set(rotas)];
        const v25 = Number.parseInt(localStorage.getItem("native_version")) >= 25;
        const onNative = context.rootGetters["biometrics/onNative"];
        const hasBiometric = context.rootState.biometrics.hasBiometric;
        const allowSettings = (v25 && onNative) || hasBiometric;
        // verifica se existe alguma etapa do fluxo pendente
        const rules = await ObterRegrasOnBoardTour({
          vinculo: context.rootState.saude.vinculo,
          configuracoes: allowSettings,
          rotas
        });
        const welcomeOnBoardTourCode = rules?.Data?.Grupo ?? "";
        const welcomeOnBoardTour = (rules?.Data?.Regras ?? []).map((b, index) => ({
          id: b.Id,
          idx: index,
          order: b.Ordem,
          title: b.Titulo,
          icon: b.Icone,
          subtitle: b.Subtitulo,
          description: b.Descricao,
          pathTo: b.Rota,
          accessLabel: b.AcaoAcessar,
          nextLabel: b.AcaoPular,
          currentResponse: b.Resposta,
          changeOnlyWithAuth: b.RequerAutorizacao
        }));
        context.commit("SET_WELCOME_ONBOARD_TOUR_CODE", welcomeOnBoardTourCode);
        context.commit("SET_WELCOME_ONBOARD_TOUR", welcomeOnBoardTour);
      } catch {
        context.commit("SET_WELCOME_ONBOARD_TOUR_CODE", "");
        context.commit("SET_WELCOME_ONBOARD_TOUR", []);
      }
    },
    async fetchScreeningTerms(context) {
      const terms = await ProgramaScreening({
        vinculo: context.rootState.saude.vinculo
      });

      if (terms && terms.Data) {
        context.commit("SET_SCREENING_TERMS", terms.Data);
      }
    },
    async acceptScreeningTerms(context) {
      checkUpdatePermission();
      return await ProgramaScreeningAceite({
        vinculo: context.rootState.saude.vinculo,
        login: context.rootState.saude.username
      });
    },
    async answerToTerms(context, { id, ans }) {
      context.commit("ANSWER_TERM", { id, ans });
    },
    async updateWelcomeOnBoardTourCompl(context, { pathTo, option }) {
      const step = context.state.welcomeOnBoardTour.find(t => t.pathTo === pathTo);
      if (!step) return;
      await context.dispatch("submitWelcomeOnBoardTour", { id: step.id, option });
    },
    async submitWelcomeOnBoardTour(context, { id, option }) {
      const newResponse = option ? "A" : "P";
      let changedOnBoard = {};
      const respondidos = [];
      const pendentes = [];
      context.state.welcomeOnBoardTour.forEach(b => {
        const newObBoard = { ...b };
        if (b.id === id) {
          newObBoard.currentResponse = newResponse;
          changedOnBoard = newObBoard;
        }
        if (newObBoard.currentResponse) {
          respondidos.push(newObBoard);
        } else {
          pendentes.push(newObBoard);
        }
      });
      const welcomeOnBoardTour = [...respondidos, ...pendentes];
      welcomeOnBoardTour.forEach((b, index) => (b.idx = index));
      const currentStep = changedOnBoard.order === 0 && newResponse === "P" ? 0 : changedOnBoard.idx;
      context.commit("SET_WELCOME_ONBOARD_TOUR", welcomeOnBoardTour);
      if (pendentes.length > 0) {
        context.commit("SET_WELCOME_ONBOARD_TOUR_STEP", currentStep);
      } else {
        context.commit("SET_WELCOME_ONBOARD_TOUR_STEP", null);
        await context.dispatch("saude/fetchProfileMessages", null, { root: true });
      }

      checkUpdatePermission();
      await RespostaOnBoardTour({
        vinculo: context.rootState.saude.vinculo,
        codigo: context.state.welcomeOnBoardTourCode,
        id,
        resposta: newResponse
      });
    },
    async submitTerms(context) {
      if (!allowUpdate()) {
        context.commit("SET_TERMS", []);
        return;
      }

      const login = context.rootState.saude.username;
      const promisses = [];
      Object.keys(context.state.acceptance).forEach(k => {
        promisses.push(
          AceiteOptinUsuario({
            termo: k,
            aceite: context.state.acceptance[k],
            login
          })
        );
      });
      if (promisses.length > 0) {
        await await Promise.all(promisses);
      }
      await context.dispatch("fetchTerms");
    },
    async changePassword(context, { currentPassword, newPassword, auth }) {
      checkUpdatePermission();
      const result = await AlterarSenha({
        vinculo: context.rootState.saude.vinculo,
        senhaAtual: currentPassword,
        novaSenha: newPassword,
        autenticacao: auth
      });
      if (result && result.Data) {
        if (result.Data) {
          context.commit("SET_CHANGE_PASSWORD_REQUIRED", false);
        }
        return result.Data;
      }
      return false;
    },
    async sendResetPassordCode(context, { email }) {
      const resp = await EnviarCodigoRecuperarSenha({
        email
      });
      if (resp && resp.Data) {
        context.commit("SET_RESET_PASSWORD", resp.Data);
        return { success: true };
      }
      return { success: false, msg: "Não foi possível encontrar o usuário informado." };
    },
    async checkCodeAndChangePassword(_, { codigo, email, password }) {
      return await InformarCodigoRecuperarSenha({
        codigo,
        email,
        password
      });
    },
    async hasActiveTicket(context, { beneficiaryCode }) {
      if (!beneficiaryCode) return false;

      const response = await ExisteChamadoPendenteProcessamento({
        vinculo: context.rootState.saude.vinculo,
        beneficiario: beneficiaryCode,
        servico: 1
      });
      return !!(response instanceof Error ? false : response?.Data);
    },
    async fetchImage(context, { documento, original }) {
      return await ObterImagem({
        vinculo: context.rootState.saude.vinculo,
        documento,
        original
      });
    },
    async sendValidationCode(context, { phone, email, id, key, service }) {
      if (service && !service.startsWith("/")) {
        try {
          service = context.rootGetters[service];
        } catch (error) {
          window.$log.message(`using service ${service}`);
        }
      }
      const telefone = phone?.replace("(", "").replace(") ", "-");
      const response = await EnviarTokenValidacao({
        vinculo: context.rootState.saude.vinculo,
        telefone,
        email,
        id,
        chave: key,
        servico: service
      });
      if (response instanceof Error || !response?.Data)
        return {
          enviado: false
        };
      return response.Data;
    },
    async validateCode(context, { phone, email, id, key, code, auth, service }) {
      if (service && !service.startsWith("/")) {
        try {
          service = context.rootGetters[service];
        } catch (error) {
          window.$log.message(`using service ${service}`);
        }
      }
      const telefone = phone?.replace("(", "").replace(") ", "-");
      const response = await InformarCodigoValidacao({
        vinculo: context.rootState.saude.vinculo,
        telefone,
        email,
        id,
        chave: key,
        codigo: code,
        autenticacao: auth,
        servico: service
      });
      return response instanceof Error ? false : response?.Data ?? false;
    },
    async sessionState(context) {
      try {
        if (!context.rootState.saude.vinculo) return true;

        const situacao = await SituacaoSessao({
          vinculo: context.rootState.saude.vinculo
        });
        return situacao instanceof Error ? true : situacao?.Data ?? true;
      } catch {
        return true;
      }
    },
    async registerAccess(context, { servico, funcionalidade, id }) {
      try {
        let vinculo = context.rootState.saude.vinculo;
        if (!vinculo && context.rootState.saude.vinculos?.length) {
          vinculo = context.rootState.saude.vinculos[0].Vinculos[0].NumeroIdentificacaoVinculo;
        }
        if (!vinculo) return true;
        if (!allowUpdate()) return true;

        RegistrarAcesso({
          vinculo,
          dsUsuario: context.rootState.saude.username,
          servico,
          funcionalidade,
          id
        });
        return true;
      } catch {
        return false;
      }
    },
    async registerNavigation(context, { classificacao }) {
      try {
        let vinculo = context.rootState.saude.vinculo;
        if (!vinculo && context.rootState.saude.vinculos?.length) {
          vinculo = context.rootState.saude.vinculos[0].Vinculos[0].NumeroIdentificacaoVinculo;
        }
        if (!vinculo) return true;
        if (!allowUpdate()) return true;

        RegistroNavegacaoRef({
          vinculo: context.rootState.saude.vinculo,
          chamadoReferenciado: context.rootState.saude.chamadoRef,
          classificacao
        });
        return true;
      } catch {
        return false;
      }
    },
    async fetchAccessResource(context, { funcionalidade, permissao }) {
      const response = await PermissaoFuncionalidade({
        vinculo: context.rootState.saude.vinculo,
        funcionalidade,
        permissao
      });
      return response instanceof Error ? false : response?.Data ?? false;
    },
    async fetchBlogPosts(context) {
      if (context.state.blogPosts?.length) return;
      const response = await ObterBlogPosts({
        vinculo: context.rootState.saude.vinculo
      });
      const blogPosts = response instanceof Error ? [] : response?.Data ?? [];
      context.commit("SET_BLOG_POSTS", blogPosts);
    },
    async fetchChangePasswordRequired(context) {
      if (!allowUpdate()) return;
      const response = await VerificarAlteracaoSenhaPendente({
        vinculo: context.rootState.saude.vinculo
      });
      const changePasswordRequired = response instanceof Error ? false : response?.Data ?? false;
      context.commit("SET_CHANGE_PASSWORD_REQUIRED", changePasswordRequired);
    }
  },
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => (state[p] = initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => (state[p] = initialState[p])),
    SET_TERMS: (state, next) => (state.terms = next),
    SET_SCREENING_TERMS: (state, next) => (state.screeningTerms = next),
    SET_RESET_PASSWORD: (state, next) => (state.resetPassword = next),
    SET_BLOG_POSTS: (state, blogPosts) => (state.blogPosts = blogPosts),
    SET_CHANGE_PASSWORD_REQUIRED: (state, next) => (state.changePasswordRequired = next),
    SET_HAS_WELCOME_TOUR: (state, next) => (state.hasWelcomeOnboard = next),
    SET_WELCOME_ONBOARD_TOUR: (state, next) => (state.welcomeOnBoardTour = next),
    SET_WELCOME_ONBOARD_TOUR_STEP: (state, next) => (state.welcomeOnBoardTourStep = next),
    SET_WELCOME_ONBOARD_TOUR_CODE: (state, next) => (state.welcomeOnBoardTourCode = next),
    SET_WELCOME_ONBOARD_TOUR_AUTH: (state, next) => (state.welcomeOnBoardTourAuth = next),
    SET_WELCOME_ONBOARD_TOUR_AUTH_RULES: (state, next) => (state.welcomeOnBoardTourRules = next),
    ANSWER_TERM: (state, { id, ans }) => (state.acceptance[id] = ans)
  }
};
