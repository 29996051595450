import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=1161d715&scoped=true&"
import script from "./Modal.vue?vue&type=script&lang=js&"
export * from "./Modal.vue?vue&type=script&lang=js&"
import style0 from "./Modal.vue?vue&type=style&index=0&id=1161d715&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1161d715",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CAlertDialogOverlay: require('@chakra-ui/vue').CAlertDialogOverlay, CAlertDialogHeader: require('@chakra-ui/vue').CAlertDialogHeader, CAlertDialogBody: require('@chakra-ui/vue').CAlertDialogBody, CAlertDialogFooter: require('@chakra-ui/vue').CAlertDialogFooter, CAlertDialogContent: require('@chakra-ui/vue').CAlertDialogContent, CAlertDialog: require('@chakra-ui/vue').CAlertDialog})
