<template>
  <OmtGrid>
    <OmtRow v-if="!!refundDetails.protocol">
      <OmtTypography as="h1-bold" mb="24px"> Protocolo {{ refundDetails.protocol }} </OmtTypography>
    </OmtRow>

    <OmtRow :mobileEnd="2" :desktopEnd="5" mb="14px">
      <TextTile v-bind="{ title: 'Data da solicitação', description: refundDetails.createdAt }" />
    </OmtRow>

    <OmtRow :mobileStart="3" :desktopStart="6" v-if="refundDetails.showValues">
      <TextTile v-bind="{ title: 'Valor total apresentado', description: refundDetails.totalAmount }" />
    </OmtRow>

    <OmtRow>
      <CDivider mt="14px" mb="16px" />
    </OmtRow>

    <OmtRow>
      <OmtTypography as="h2-bold" mb="16px"> Conta Corrente para Crédito </OmtTypography>
    </OmtRow>

    <OmtRow :desktopEnd="5" mb="14px">
      <TextTile v-bind="{ title: 'Favorecido', description: refundDetails.accountDetails.name }" />
    </OmtRow>

    <OmtRow :mobileEnd="2" :desktopStart="6" :desktopEnd="8" mb="14px">
      <TextTile v-bind="{ title: 'Conta Corrente', description: refundDetails.accountDetails.account }" />
    </OmtRow>

    <OmtRow :mobileStart="3" :desktopStart="9">
      <TextTile v-bind="{ title: 'Agência', description: refundDetails.accountDetails.agency }" />
    </OmtRow>

    <OmtRow>
      <TextTile v-bind="{ title: 'Banco', description: refundDetails.accountDetails.bank }" />
    </OmtRow>

    <OmtRow v-if="refundDetails.obsHistory && refundDetails.obsHistory.length" mt="20px" mb="20px">
      <OmtCard>
        <CFlex cursor="pointer" @click="() => (isOpenObs = !isOpenObs)" justify-content="space-between">
          <OmtTypography as="h2-bold"> Observações </OmtTypography>
          <OmtIcons
            ml="16px"
            name="chevron-down"
            :style="`transition: all .5s ease; transform: rotate(${isOpenObs ? '180deg' : '0deg'})`"
          />
        </CFlex>
        <CCollapse :isOpen="isOpenObs">
          <OmtGrid
            v-for="(obs, index) in refundDetails.obsHistory"
            :key="`obs-${index}`"
            :mt="index === 0 ? '16px !important' : '0px !important'"
          >
            <OmtRow v-if="obs.Data" :mobileEnd="1" :desktopEnd="1">
              <OmtTypography as="p1">
                {{ obs.Data }}
              </OmtTypography>
            </OmtRow>
            <OmtRow :mobileStart="obs.Data ? 2 : 1" :desktopStart="obs.Data ? 2 : 1">
              <CBox>
                <OmtTypography
                  v-for="(obsLine, index) in obs.Obs"
                  :key="`obs-lin-${index}`"
                  as="p1"
                  :style="{ wordWrap: 'break-word' }"
                >
                  {{ obsLine }}
                </OmtTypography>
              </CBox>
            </OmtRow>
          </OmtGrid>
        </CCollapse>
      </OmtCard>
    </OmtRow>
    <template v-else>
      <OmtRow>
        <CDivider mt="14px" mb="16px" />
      </OmtRow>
      <OmtRow>
        <OmtTypography as="h2-bold" mb="16px"> Observações </OmtTypography>
      </OmtRow>
      <OmtRow>
        <OmtTypography as="p1" mb="20px"> {{ refundDetails.obsProtocol }} </OmtTypography>
      </OmtRow>
    </template>

    <OmtRow>
      <OmtTypography as="h2-bold" mb="20px" data-cy="refund-detail-docs-label">
        {{ refundDetails.docsLabel }}
      </OmtTypography>
    </OmtRow>

    <OmtRow v-for="(refund, index) in refundDetails.refunds" mb="16px" :key="`stat-${index}`">
      <RefundDetailCollapsableCard
        :refundDetail="refund"
        :answerPendency="answerPendency"
        :cancelPendency="openCancellationModal"
        :isCollapsed="refundDetails.refunds.length > 1 && refund.status !== 'P'"
      />
      <AuditFormCancellationModal :isOpen.sync="showCancellationModal" @cancel="handleCancel" />
    </OmtRow>
    <template v-if="pictures.length">
      <OmtRow>
        <OmtTypography as="h2-bold" mb="8px"> Documentos complementares </OmtTypography>
      </OmtRow>

      <OmtRow>
        <OmtTypography as="p1" mb="14px"> Pedidos médicos, relatórios e outros </OmtTypography>
      </OmtRow>

      <OmtRow>
        <CGrid
          w="100%"
          :templateColumns="['repeat(auto-fill, minmax(74px, 1fr))', 'repeat(auto-fill, minmax(134px, 1fr))']"
          gap="6"
        >
          <ImagePreviewer v-for="(picture, index) in pictures" :key="index" v-bind="picture" />
        </CGrid>
      </OmtRow>
    </template>
    <OmtRow>
      <AnswerPendencyModal
        :isOpen.sync="showPendencyModal"
        :pendencyToAnswer.sync="pendencyToAnswer"
        :protocol="protocolCreatedOnSave"
        :step="pendencyModalStep"
        :limitFiles="limitFiles"
        @savePendency="onSavePendency"
        @closeProtocolModal="onCloseProtocolModal"
        filesDescription="Insira somente a(s) imagem(ns) e/ou informações solicitadas e relacionadas a este(s) documento(s)"
        protocolMainTip="Você será notificado sobre o andamento desta solicitação via WhatsApp"
        protocolSubTip="Você poderá acompanhar o status na tela de Reembolsos"
      />
    </OmtRow>
  </OmtGrid>
</template>

<script>
import TextTile from "@/components/Molecules/TextTile";
import RefundDetailCollapsableCard from "@/components/Organisms/RefundNew/RefundDetailCollapsableCard.vue";
import AnswerPendencyModal from "@/components/Molecules/AnswerPendencyModal/index.vue";
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ImagePreviewer from "@/components/Molecules/ImagePreviewer";
import AuditFormCancellationModal from "@/components/Organisms/RefundNew/AuditForm/AuditFormCancellationModal.vue";

export default {
  components: {
    AnswerPendencyModal,
    RefundDetailCollapsableCard,
    TextTile,
    ImagePreviewer,
    AuditFormCancellationModal
  },
  async mounted() {
    if (!this.protocol) {
      this.$router.push({ name: "refund.new" });
    }
    try {
      await this.$root.$emit("show-loading");
      this.refundDetails = await this.fetchProtocolDetail({ protocol: this.protocol });
      if (!this.refundDetails) {
        this.$router.push({ name: "refund.new" });
      }
      this.limitFiles = await this.fetchMaxFiles();
      this.loadPictures();
    } catch {
      // TODO confirmar se vai exibir algum aviso
      this.$router.push({ name: "refund.new" });
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  data() {
    return {
      cancellationTicket: "",
      limitFiles: 0,
      showCancellationModal: false,
      isOpenObs: true,
      showPendencyModal: false,
      refundDetails: { accountDetails: {}, refunds: [] },
      pictures: [],
      pendencyToAnswer: {},
      pendencyModalStep: 1,
      protocolCreatedOnSave: ""
    };
  },
  computed: {
    protocol() {
      return this.$route.params.protocolo ?? "";
    }
  },
  methods: {
    ...mapActions("refund", [
      "fetchMaxFiles",
      "fetchProtocolDetail",
      "fetchProtocolDetailImage",
      "returnRefundTicket",
      "fetchProtocols",
      "cancelAudit"
    ]),
    ...mapActions("saude", ["fetchProfileMessages"]),
    loadPictures() {
      if (!this.refundDetails?.pictures?.length) return;

      this.refundDetails.pictures.forEach(p =>
        this.fetchProtocolDetailImage({ documento: p.id, original: false }).then(source =>
          this.pictures.push({
            id: p.id,
            source,
            loadModalImage: () => this.loadPicture(p.id)
          })
        )
      );
    },
    async loadPicture(id) {
      return await this.fetchProtocolDetailImage({ documento: id, original: true });
    },
    async onSavePendency(data) {
      await this.$root.$emit("show-loading");
      this.showPendencyModal = false;

      let newRefund = {};

      try {
        newRefund = await this.returnRefundTicket(data);

        if (newRefund && newRefund.Data) {
          this.fetchProfileMessages();
          await this.fetchProtocols({ protocol: newRefund.Data.protocoloANS });
        }
      } finally {
        await this.$root.$emit("hide-loading");
      }

      if (newRefund && newRefund.Data) {
        this.protocolCreatedOnSave = newRefund.Data.protocoloANS;
        this.pendencyModalStep = 2;
        this.showPendencyModal = true;
      } else {
        await Swal.fire({
          title: "Erro",
          text: "Não foi possivel registrar o atendimento.\nServiço temporariamente indisponível.",
          confirmButtonText: "OK",
          icon: "error"
        });
      }
    },
    onCloseProtocolModal() {
      this.$toast({
        description: "Sua resposta foi enviada para análise",
        status: "success",
        duration: 10000,
        position: "top"
      });
      this.$root.$emit("show-loading");
      window.location.reload(true);
    },
    answerPendency({ ticket, refundDetail }) {
      if (refundDetail.status === "P") {
        this.showPendencyModal = true;
        this.pendencyToAnswer = ticket;
      } else if (refundDetail.status === "U") {
        const { ticket: tickerNumber, dueDate } = ticket;

        this.$router.push({
          name: "refund.new.audit.explanation",
          params: {
            dueDate,
            ticket: tickerNumber,
            refund: this.refundDetails
          }
        });
      }
    },
    openCancellationModal({ ticket }) {
      this.cancellationTicket = ticket;
      this.showCancellationModal = true;
    },
    handleCancel() {
      this.$router.push({
        name: "refund.new.audit.term",
        params: {
          scenario: 6,
          answers: [],
          refund: this.refundDetails,
          ticket: this.cancellationTicket,
          mustCancelProtocol: true
        }
      });
    }
  }
};
</script>

<style scoped>
::v-deep .swal2-container {
  padding: 0;
}

::v-deep .swal2-popup {
  height: 100vh;
  width: 100vw;
  display: flex;
}

::v-deep .swal2-image {
  height: 80vh;
  width: 100%;
  object-fit: fill;
}
</style>
