<template>
  <OmtStickedModal
    v-if="isOpen && hasFilters"
    @close="close"
    :bodyBind="{
      padding: ['16px 16px 24px 16px', '68px 42px 24px 42px']
    }"
  >
    <template #header>
      <OmtButton class="clear" variant="ghost" @click="clear" height="18px" p="0">
        <OmtTypography as="p1" h="18px" w="46px" position="relative"> Limpar </OmtTypography>
      </OmtButton>
    </template>

    <CBox :minHeight="['initial', 'calc(100vh - 400px)']" mb="42px">
      <OmtTypography as="h1-bold" :mb="['24px', '36px']"> Filtrar </OmtTypography>

      <CBox mb="32px">
        <OmtTypography as="h2-bold" mb="22px"> Por beneficiário </OmtTypography>

        <CBox v-for="(option, index) in beneficiarios" justify="space-between" :key="index">
          <OmtDivider v-if="index !== 0" mb="24px" mt="24px" />

          <OmtRadio
            cursor="pointer"
            @click="beneficiario = option.label"
            :isChecked="beneficiario === option.label"
            :name="`radio-${option.label}`"
            :value="option.label"
          >
            <OmtTypography as="p1"> {{ option.label }} </OmtTypography>
          </OmtRadio>
        </CBox>
      </CBox>

      <CBox>
        <OmtTypography as="h2-bold" mb="22px"> Por idioma </OmtTypography>

        <CFlex
          v-for="(filter, index) in idiomas"
          justify="space-between"
          :key="index"
          cursor="pointer"
          @click="filter.isActive = !filter.isActive"
          :mt="index !== 0 ? '24px' : '0'"
        >
          <OmtTypography as="p1"> {{ filter.label }} </OmtTypography>
          <OmtCheckbox @change="filter.isActive = !filter.isActive" :is-checked="filter.isActive" />
        </CFlex>
      </CBox>
    </CBox>

    <template #footer>
      <OmtButton w="100%" @click="save"> Aplicar </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
/**
 * @prop { Boolean } isOpen
 * @type { key: String, value: Boolean } filterOptionObjectType
 * @type { beneficiarios: Array<filterOptionObjectType>, idiomas: Array<filterOptionObjectType> } filterType
 * @prop { filterType } filters
 * @Event {(filterType) => void} change
 */
export default {
  props: {
    isOpen: {
      Type: Boolean
    },
    filters: {
      type: Object
    }
  },
  data() {
    return {
      beneficiario: "",
      beneficiarios: [],
      idiomas: [],
      hasFilters: false
    };
  },
  methods: {
    close() {
      this.$emit("update:isOpen", false);
    },
    clear() {
      this.beneficiarios.forEach(f => (f.isActive = false));
      this.idiomas.forEach(f => (f.isActive = false));
    },
    save() {
      const filters = {
        beneficiarios: {},
        idiomas: {}
      };
      this.beneficiarios.forEach(f => (filters.beneficiarios[f.label] = f.label === this.beneficiario));
      this.idiomas.forEach(f => (filters.idiomas[f.label] = f.isActive));
      this.$emit("save", filters);
      this.close();
    },
    updateFilters(newValues) {
      const beneficiarios = [];
      const idiomas = [];
      this.beneficiario = "";
      Object.keys(newValues.beneficiarios).forEach(b => {
        const selecionado = newValues.beneficiarios[b];
        beneficiarios.push({ label: b, isActive: selecionado });
        if (selecionado) this.beneficiario = b;
      });
      Object.keys(newValues.idiomas).forEach(b => idiomas.push({ label: b, isActive: newValues.idiomas[b] }));
      this.beneficiarios = beneficiarios;
      this.idiomas = idiomas;
      this.hasFilters = beneficiarios.length > 0 && idiomas.length > 0;
    }
  },
  watch: {
    filters(newValues) {
      this.updateFilters(newValues);
    }
  },
  mounted() {
    this.updateFilters(this.filters ?? {});
  }
};
</script>
