<template>
  <select class="select" name="omt-select" :value="value" @change="$emit('update:value', $event.target.value)">
    <option value="" disabled selected>Clique para selecionar</option>
    <option v-for="(value, index) in options" :key="index" :value="value">
      {{ value }}
    </option>
  </select>
</template>
<script>
export default {
  props: {
    value: {
      required: true
    },
    options: {
      required: true
    }
  }
};
</script>
<style scoped>
.select {
  font-size: 12px;
  display: block;
  width: 100%;
  height: 40px;
  line-height: 40px;
  margin: 0;
  margin-bottom: 0px;
  padding: 0px;
  padding-left: 2px;
  background-color: transparent;
  border: 0;
  border-bottom: solid 2px rgba(0, 0, 0, 0.08);
  transition: all 250ms ease;
  outline: none;
  box-sizing: border-box;
}

.big-font-size .select {
  font-size: 18px;
}

.select:hover,
.select:focus {
  outline: none;
}

.select::placeholder {
  opacity: 1;
}
</style>
