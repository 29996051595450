<template>
  <OmtStickedModal v-if="isOpen" @close="close" data-cy="refund-create-terms-modal">
    <OmtTypography as="h1-bold" mb="24px" mx="auto" textAlign="center"> Atenção </OmtTypography>

    <OmtTypography as="p1" mb="24px">
      Canal exclusivo para AUTORIZAÇÃO.<br />Para orçamento utilize a opção no menu lateral: Minhas Solicitações.
    </OmtTypography>

    <CBox v-if="recentAuthInfo">
      <OmtDivider />
      <CFlex align-items="center">
        <OmtIcons name="warning" mr="12px" :font-size="['16px', '20px']" />
        <OmtTypography as="p1"><b>Novo pedido de autorização</b></OmtTypography>
      </CFlex>
      <CBox mt="12px">
        <OmtTypography as="p1">
          Você acabou de enviar um pedido de autorização na última meia hora, tem certeza que gostaria de solicitar
          outro?
        </OmtTypography>
      </CBox>
    </CBox>

    <template #footer>
      <OmtButton w="100%" @click="continueClick"> Continuar </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
/**
 * @prop {Boolean} isOpen
 */
export default {
  props: {
    isOpen: {
      Type: Boolean
    },
    recentAuthInfo: {
      Type: Boolean
    }
  },
  methods: {
    close() {
      this.$emit("update:isOpen", false);
    },
    continueClick() {
      this.$emit("continue");
      this.close();
    }
  }
};
</script>
