<script>
import { CDivider } from "@chakra-ui/vue";
import Vue from "vue";

export default Vue.component("OmtDivider", {
  render: function (createElement) {
    return createElement(
      CDivider,
      {
        on: { ...this.$listeners },
        class: "omt-divider",
        attrs: {
          ...this.$attrs
        }
      },
      this.$slots.default
    );
  }
});
</script>
