<template>
  <OmtRow :class="['footer-fixed-margin', `footer-fixed-margin-${fixedSize}`]">
    <OmtRow
      :class="[
        'fixed-button',
        useFooter ? 'fixed-button-with-footer' : 'fixed-button-without-footer',
        solidColor && 'fixed-button-solid'
      ]"
      :desktopStart="5"
      :desktopEnd="8"
      v-bind="$attrs.fixed ? $attrs.fixed : {}"
    >
      <slot></slot>
    </OmtRow>
  </OmtRow>
</template>
<script>
const TAMANHOS_ACEITOS = ["1x", "2x", "3x"];
export default {
  name: "FixedButtonRow",
  props: {
    useFooter: {
      type: Boolean,
      default: true
    },
    solidColor: {
      type: Boolean,
      default: false
    },
    fixedSize: {
      type: String,
      default: "1x",
      validator: value => {
        if (!TAMANHOS_ACEITOS.find(v => v === value)) {
          console.error(`Wrong fixed button size option ${value}`);
          console.error(`Allowed fixed button size options : ${TAMANHOS_ACEITOS.join(" - ")}`);
        }
        return true;
      }
    }
  }
};
</script>
<style scoped>
.fixed-button {
  position: fixed;
  left: 0;
  right: 0;
  padding: 6px;
  background-color: white;
  justify-content: center;
  max-width: 1024px;
  width: initial;
  margin: initial;
}

.fixed-button-with-footer {
  bottom: 66px;
}

.fixed-button-without-footer {
  padding-bottom: 16px;
  bottom: 0px;
}

@media screen and (min-width: 1024px) {
  .fixed-button {
    width: calc(100vw - 324px);
    margin: 0 auto;
    background-color: transparent;
  }

  .fixed-button-solid {
    background-color: white;
  }

  .fixed-button-with-footer {
    bottom: 0;
  }
}

@media all and (orientation: landscape) and (max-height: 300px) {
  .footer-fixed-margin {
    display: none;
  }

  .fixed-button {
    position: initial;
    width: 100%;
  }
}

.footer-fixed-margin {
  clear: both;
}

.footer-fixed-margin-1x {
  height: 60px;
}

.footer-fixed-margin-2x {
  height: 120px;
}

.footer-fixed-margin-3x {
  height: 160px;
}
</style>
