<template>
  <div>
    <OmtRow
      :mb="['16px', '24px']"
      pt="24px"
      mt="24px"
      borderTop="0.5px solid"
      borderColor="var(--colors-light-secondary-divider)"
    >
      <OmtTypography :as="['h3-bold', 'h1-semi-bold']"> Lista de extratos dos últimos 12 meses </OmtTypography>
    </OmtRow>
    <OmtRow mb="16px" v-for="fatura in copayStatement" :key="fatura.id">
      <OmtCard display="flex" flex-direction="column" :p="['16px', '24px']" cursor="pointer">
        <CFlex @click="detailExtract(fatura.id)">
          <CBox>
            <OmtIcons name="money-check-dollar-pen" mr="13" size="sm" :display="['block', 'none']" />
            <OmtIcons name="money-check-dollar-pen" mr="13" size="1x" :display="['none', 'block']" />
          </CBox>
          <CBox flex="1">
            <CFlex direction="row" justify="space-between" align-items="center" mb="15px">
              <OmtTypography :as="['h3-bold', 'h1-semi-bold']"> Fatura nº {{ fatura.id }} </OmtTypography>
              <OmtTypography :as="['p2', 'p4']" color="var(--colors-light-primary-gray2)">
                {{ fatura.date }}
              </OmtTypography>
            </CFlex>
            <CFlex direction="row" justify="space-between" align-items="center">
              <OmtTypography
                display="flex"
                :as="['p2', 'p4']"
                color="var(--colors-light-primary-gray2)"
                align-items="center"
                >Valor total coparticipação
                <OmtTypography as="h1-semi-bold" ml="8px" :display="['none', 'block']">
                  {{ fatura.value }}</OmtTypography
                >
              </OmtTypography>
              <OmtTypography :display="['block', 'none']" as="h3-bold"> {{ fatura.value }}</OmtTypography>
              <OmtButton
                variant="ghost"
                @click.stop="download(fatura.downloadUrl)"
                height="20px"
                p="0px"
                :display="['none', 'block']"
              >
                <OmtTypography as="button-small" color="var(--colors-light-primary-navy-blue)">
                  Baixar Fatura
                </OmtTypography>
              </OmtButton>
            </CFlex>
            <OmtRow>
              <omtDivider mb="16px" mt="24px" :display="['block', 'none']" />
            </OmtRow>
          </CBox>
        </CFlex>
        <CFlex direction="row" justify="center" align-items="center">
          <OmtButton
            variant="ghost"
            @click.stop="download(fatura.downloadUrl)"
            height="20px"
            p="0px"
            :display="['block', 'none']"
          >
            <OmtTypography as="button-small" color="var(--colors-light-primary-navy-blue)">
              Baixar Fatura
            </OmtTypography>
          </OmtButton>
        </CFlex>
      </OmtCard>
    </OmtRow>
    <OmtRow mt="99px" :mx="['40px', '0px']" v-if="copayStatement.length === 0">
      <CFlex direction="column" justify="center" align-items="center">
        <OmtIcons name="circle-exclamation" size="xl" mb="12px" color="var(--colors-light-primary-gray2)" />
        <OmtTypography as="p1" text-align="center">
          Não existem extratos de coparticipação para o titulo consultado
        </OmtTypography>
      </CFlex>
    </OmtRow>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("copay", ["copayStatement"])
  },
  methods: {
    detailExtract(id) {
      this.$router.push({ name: "copay.extrato.detalhe", params: { id: id } });
    },
    download(url) {
      window.open(url, "_blank");
    }
  }
};
</script>
