import MeusContatos from "@/pages/meus-contatos/index.vue";
import MeusContatosMenu from "@/pages/meus-contatos/menu.vue";
import MeusContatosAddress from "@/pages/meus-contatos/address.vue";
import MeusContatosEmail from "@/pages/meus-contatos/email.vue";
import MeusContatosTel from "@/pages/meus-contatos/tel.vue";
import MeusContatosIdentityVerification from "@/pages/meus-contatos/identity-verification.vue";
import AssociadoMyContactsPrint from "@/pages/associado/my-contacts-print.vue";

const routes: Route[] = [
  {
    path: "/meus-contatos",
    component: MeusContatos,
    meta: {
      registerAccess: { servico: "Formas de Contato", funcionalidade: "Acesso a formas de contato" }
    },
    children: [
      {
        path: "",
        name: "meus.contatos",
        component: MeusContatosMenu,
        meta: {
          backTo: "/"
        }
      },
      {
        path: "/meus-contatos-endereco",
        name: "meus.contatos.endereco",
        component: MeusContatosAddress,
        meta: {
          validPath: ["/meus-contatos"],
          backTo: "/meus-contatos"
        }
      },
      {
        path: "/meus-contatos-email",
        name: "meus.contatos.email",
        component: MeusContatosEmail,
        meta: {
          validPath: ["/meus-contatos"],
          backTo: "/meus-contatos"
        }
      },
      {
        path: "/meus-contatos-tel",
        name: "meus.contatos.tel",
        component: MeusContatosTel,
        meta: {
          validPath: ["/meus-contatos"],
          backTo: "/meus-contatos"
        }
      },
      {
        path: "/meus-contatos/identity-verification",
        name: "meus.contatos.identity-verification",
        component: MeusContatosIdentityVerification,
        meta: {
          validPath: ["/meus-contatos"],
          backTo: "/meus-contatos"
        }
      }
    ]
  },
  {
    path: "/associado/dadoscadastrais/imprimir/:idx?",
    name: "mycontacts.print",
    component: AssociadoMyContactsPrint,
    props: true,
    meta: {
      allowAnonymous: true,
      backTo: "/"
    }
  }
];

export default routes;
