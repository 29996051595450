import Notifications from "@/pages/notifications/index.vue";
import NotificationsDetail from "@/pages/notifications/detail.vue";

const routes: Route[] = [
  {
    path: "/notifications",
    name: "notifications",
    component: Notifications,
    props: true,
    meta: {
      checkPathDisabled: true
    }
  },
  {
    path: "/notifications/:idx",
    name: "notifications.detail",
    component: NotificationsDetail,
    props: true,
    meta: {
      checkPathDisabled: true
    }
  }
];

export default routes;
