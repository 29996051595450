const baseURL = `${process.env.VUE_APP_SERVER_URL}/RedeCredenciada`;
import * as geoUtils from "@/utils/geoDistance";

export async function ObterLimiteExames({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterLimiteExames`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterLimiteExames", error.message);
      return error;
    });
}

export async function BuscarEnderecoReferencia({ vinculo, tipoEndereco, latitude, longitude }) {
  return window.$axios
    .get({
      url: `${baseURL}/BuscarEnderecoReferencia`,
      query: {
        vinculo,
        tipoEndereco,
        latitude,
        longitude
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("BuscarEnderecoReferencia", error.message);
      return error;
    });
}

export async function BuscarEnderecoReferenciaV2({ vinculo, tipoEndereco }) {
  let coords: any = null;
  if (!tipoEndereco || (tipoEndereco !== "C" && tipoEndereco !== "R")) {
    tipoEndereco = "";
    coords = await geoUtils.GetLocation().catch(() => null);
  }
  return window.$axios
    .get({
      url: `${baseURL}/BuscarEnderecoReferenciaV2`,
      query: {
        vinculo,
        tipoEndereco,
        latitude: coords?.latitude,
        longitude: coords?.longitude
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("BuscarEnderecoReferenciaV2", error.message);
      return error;
    });
}

export async function BuscarPelaProximidadeAtual({
  rede,
  tipoAtendimento,
  codigoEspecialidade,
  subEspecialidade,
  latitude,
  longitude,
  inativos,
  vinculo
}) {
  const query = {
    rede,
    tipoAtendimento,
    codigoEspecialidade,
    subEspecialidade,
    latitude,
    longitude,
    inativos
  };
  if (vinculo) query["vinculo"] = vinculo;
  return window.$axios
    .get({
      url: `${baseURL}/BuscarPelaProximidadeAtual`,
      query
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("BuscarPelaProximidadeAtual", error.message);
      return error;
    });
}

export async function BuscarPorNome({ nome, vinculo, inativos }) {
  return window.$axios
    .get({
      url: `${baseURL}/BuscarPorNome`,
      query: {
        nome,
        vinculo,
        inativos
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("BuscarPorNome", error.message);
      return error;
    });
}

export async function BuscarPorProximidade({
  vinculo,
  rede,
  tipoAtendimento,
  codigoEspecialidade,
  subEspecialidade,
  buscaProximidadeTipo,
  inativos
}) {
  const query = {
    rede,
    tipoAtendimento,
    codigoEspecialidade,
    subEspecialidade,
    buscaProximidadeTipo,
    inativos
  };
  if (vinculo) query["vinculo"] = vinculo;
  return window.$axios
    .get({
      url: `${baseURL}/BuscarPorProximidade`,
      query
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("BuscarPorProximidade", error.message);
      return error;
    });
}

export async function BuscarPorRegiao({
  vinculo,
  rede,
  tipoAtendimento,
  codigoEspecialidade,
  subEspecialidade,
  codigoEstado,
  cidade,
  bairro,
  inativos
}) {
  const query = {
    rede,
    tipoAtendimento,
    codigoEspecialidade,
    subEspecialidade,
    codigoEstado,
    cidade,
    bairro,
    inativos
  };
  if (vinculo) query["vinculo"] = vinculo;
  return window.$axios
    .get({
      url: `${baseURL}/BuscarPorRegiao`,
      query
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("BuscarPorRegiao", error.message);
      return error;
    });
}

export async function DetalhePrestador({ vinculo, rede, codigoPrestador, srEndereco, inativos, completo }) {
  const query = {
    rede,
    codigoPrestador,
    srEndereco,
    inativos,
    completo
  };
  if (vinculo) query["vinculo"] = vinculo;
  return window.$axios
    .get({
      url: `${baseURL}/DetalhePrestador`,
      query
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("DetalhePrestador", error.message);
      return error;
    });
}

export async function ObterDadosUsuario({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterDadosUsuario`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterDadosUsuario", error.message);
      return error;
    });
}

export async function ObterPlanos() {
  return window.$axios
    .get({
      url: `${baseURL}/ObterPlanos`
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterPlanos", error.message);
      return error;
    });
}

export async function ObterRedes() {
  return window.$axios
    .get({
      url: `${baseURL}/ObterRedes`
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterRedes", error.message);
      return error;
    });
}

export async function ObterTipoAtendimento({ vinculo, rede }) {
  const query = { rede };
  if (vinculo) query["vinculo"] = vinculo;
  return window.$axios
    .get({
      url: `${baseURL}/ObterTipoAtendimento`,
      query
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterTipoAtendimento", error.message);
      return error;
    });
}

export async function ObterEspecialidades({ vinculo, rede, tipoAtendimento, inativos, nomeEspecialidade }) {
  const query = {
    rede,
    tipoAtendimento,
    inativos,
    nomeEspecialidade
  };
  if (vinculo) query["vinculo"] = vinculo;
  return window.$axios
    .get({
      url: `${baseURL}/ObterEspecialidades`,
      query
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterEspecialidades", error.message);
      return error;
    });
}

export async function ObterSubEspecialidades({ vinculo, rede, tipoAtendimento, inativos, codigoEspecialidade }) {
  const query = {
    rede,
    tipoAtendimento,
    inativos,
    codigoEspecialidade
  };
  if (vinculo) query["vinculo"] = vinculo;
  return window.$axios
    .get({
      url: `${baseURL}/ObterSubEspecialidades`,
      query
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterSubEspecialidades", error.message);
      return error;
    });
}

export async function ObterPlanosAtendidos({ vinculo, rede, codigoPrestador }) {
  const query = {
    rede,
    codigoPrestador
  };
  if (vinculo) query["vinculo"] = vinculo;
  return window.$axios
    .get({
      url: `${baseURL}/ObterPlanosAtendidos`,
      query
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterPlanosAtendidos", error.message);
      return error;
    });
}

export async function ObterPrestadoresSubstitutos({ vinculo, rede, codigoPrestador, srEndereco }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterPrestadoresSubstitutos`,
      query: {
        vinculo,
        rede,
        codigoPrestador,
        srEndereco
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterPrestadoresSubstitutos", error.message);
      return error;
    });
}

export async function ObterRegioes({
  vinculo,
  rede,
  tipoAtendimento,
  codigoEspecialidade,
  subEspecialidade,
  inativos
}) {
  const query = {
    rede,
    tipoAtendimento,
    codigoEspecialidade,
    subEspecialidade,
    inativos
  };
  if (vinculo) query["vinculo"] = vinculo;
  return window.$axios
    .get({
      url: `${baseURL}/ObterRegioes`,
      query
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterRegioes", error.message);
      return error;
    });
}

export async function BuscarHistoricoAcesso({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/BuscarHistoricoAcesso`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("BuscarHistoricoAcesso", error.message);
      return error;
    });
}

export async function RegistrarAcesso({ vinculo, prestador, endereco }) {
  return window.$axios
    .post({
      url: `${baseURL}/RegistrarAcesso`,
      query: {
        vinculo,
        prestador,
        endereco
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("RegistrarAcesso", error.message);
      return error;
    });
}

export async function VerificarFavorito({ vinculo, prestador, endereco }) {
  return window.$axios
    .get({
      url: `${baseURL}/VerificarFavorito`,
      query: {
        vinculo,
        prestador,
        endereco
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("VerificarFavorito", error.message);
      return error;
    });
}

export async function BuscarFavoritos({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/BuscarFavoritos`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("BuscarFavoritos", error.message);
      return error;
    });
}

export async function AdicionarFavorito({ vinculo, prestador, endereco }) {
  return window.$axios
    .post({
      url: `${baseURL}/AdicionarFavorito`,
      query: {
        vinculo,
        prestador,
        endereco
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("AdicionarFavorito", error.message);
      return error;
    });
}

export async function RemoverFavorito({ vinculo, prestador, endereco }) {
  return window.$axios
    .post({
      url: `${baseURL}/RemoverFavorito`,
      query: {
        vinculo,
        prestador,
        endereco
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("RemoverFavorito", error.message);
      return error;
    });
}

export async function PedidosIndicacaoRede({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/PedidosIndicacaoRede`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("PedidosIndicacaoRede", error.message);
      return error;
    });
}

export async function ResultadoIndicacaoRede({ vinculo, chamado, latitude, longitude }) {
  return window.$axios
    .get({
      url: `${baseURL}/ResultadoIndicacaoRede`,
      query: {
        vinculo,
        chamado,
        latitude,
        longitude
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ResultadoIndicacaoRede", error.message);
      return error;
    });
}

export async function PodeSimularCopay({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/PodeSimularCopay`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("PodeSimularCopay", error.message);
      return error;
    });
}

export async function ObterSimulacoesCopay({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterSimulacoesCopay`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterSimulacoesCopay", error.message);
      return error;
    });
}

export async function ObterSimulacaoCopay({ vinculo, id }) {
  return window.$axios
    .post({
      url: `${baseURL}/ObterSimulacaoCopay`,
      query: {
        vinculo
      },
      data: {
        id
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterSimulacaoCopay", error.message);
      return error;
    });
}

export async function AceiteTermoSimualacaoCopay({ vinculo }) {
  return window.$axios
    .post({
      url: `${baseURL}/AceiteTermoSimualacaoCopay`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("AceiteTermoSimualacaoCopay", error.message);
      return error;
    });
}

export async function ObterTermoSimualacaoCopay({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterTermoSimualacaoCopay`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterTermoSimualacaoCopay", error.message);
      return error;
    });
}

export async function ObterExamesProcuradosSimulacaoCopay({ vinculo, incluirTermos }) {
  return window.$axios
    .post({
      url: `${baseURL}/ObterExamesProcuradosSimulacaoCopay`,
      query: {
        vinculo
      },
      data: {
        incluirTermos
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterExamesProcuradosSimulacaoCopay", error.message);
      return error;
    });
}

export async function ListarExameSimulacaoCopay({ vinculo, exame }) {
  return window.$axios
    .get({
      url: `${baseURL}/ListarExameSimulacaoCopay`,
      query: {
        vinculo,
        exame
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ListarExameSimulacaoCopay", error.message);
      return error;
    });
}

export async function ExameGrupoCopay({ vinculo, grupo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ExameGrupoCopay`,
      query: {
        vinculo,
        grupo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ExameGrupoCopay", error.message);
      return error;
    });
}

export async function SinonimosExameCopay({ vinculo, exame }) {
  return window.$axios
    .get({
      url: `${baseURL}/SinonimosExameCopay`,
      query: {
        vinculo,
        exame
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("SinonimosExameCopay", error.message);
      return error;
    });
}

export async function ObterPrestadoresProcuradosSimulacaoCopay({ vinculo, exames, tipoEndereco, incluirTermos }) {
  let coords: any = null;
  if (!tipoEndereco || (tipoEndereco !== "C" && tipoEndereco !== "R")) {
    tipoEndereco = "";
    coords = await geoUtils.GetLocation().catch(() => null);
  }
  return window.$axios
    .post({
      url: `${baseURL}/ObterPrestadoresProcuradosSimulacaoCopay`,
      query: {
        vinculo
      },
      data: {
        exames,
        tipoEndereco,
        latitude: coords?.latitude,
        longitude: coords?.longitude,
        incluirTermos
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterPrestadoresProcuradosSimulacaoCopay", error.message);
      return error;
    });
}

export async function BuscarPrestadorSimulacaoCopay({ vinculo, exames, tipoEndereco, nome }) {
  let coords: any = null;
  if (!tipoEndereco || (tipoEndereco !== "C" && tipoEndereco !== "R")) {
    tipoEndereco = "";
    coords = await geoUtils.GetLocation().catch(() => null);
  }
  return window.$axios
    .post({
      url: `${baseURL}/BuscarPrestadorSimulacaoCopay`,
      query: {
        vinculo
      },
      data: {
        nome,
        exames,
        tipoEndereco,
        latitude: coords?.latitude,
        longitude: coords?.longitude
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("BuscarPrestadorSimulacaoCopay", error.message);
      return error;
    });
}

export async function SimularCopay({ vinculo, exames, prestadores }) {
  return window.$axios
    .post({
      url: `${baseURL}/SimularCopay`,
      query: {
        vinculo
      },
      data: {
        exames,
        prestadores
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("SimularCopay", error.message);
      return error;
    });
}

export async function ObterModeloCopay({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterModeloCopay`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterModeloCopay", error.message);
      return error;
    });
}
