import Vue from "vue";

/**
 * layout registration pattern
 */

const PREFIX = "OmtLayout";
const componentsRegex = require.context(".", true, /\.(vue)$/);

componentsRegex.keys().map(path => {
  const normalize = path.replace("./", "").replace(".vue", "");
  const [folder, file] = normalize.split("/");
  const componentName = (folder + file).replace("index", "");

  if (!componentName.startsWith("omt-")) {
    const finalComponentName = PREFIX + componentName;
    const exportedComponents = componentsRegex(path);
    Vue.component(finalComponentName, exportedComponents.default);
  }
});
