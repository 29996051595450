<template>
  <OmtLayoutHeaderFooter :bg="['none', '#F6F6F9']" :header="{ titleLabel: 'Coparticipação' }">
    <omint-slider>
      <router-view />
    </omint-slider>
  </OmtLayoutHeaderFooter>
</template>

<script>
export default {};
</script>
