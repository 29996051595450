<template>
  <div class="info-box">
    <i class="fa fa-exclamation-triangle"></i>
    <span>{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: "InfoBox",
  props: ["message"]
};
</script>
<style scoped>
.info-box {
  display: block;
  background-color: #102a46;
  color: white;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 400;
  padding: 10px 0;
}

.big-font-size .info-box {
  font-size: 19px;
}

.info-box i {
  display: table-cell;
  vertical-align: middle;
  min-height: 40px;
  width: 48px;
  text-align: center;
  margin: 0 25px;
}

.info-box span {
  display: table-cell;
  vertical-align: middle;
  line-height: normal;
  padding-right: 10px;
  width: calc(100% - 58px);
}
</style>
