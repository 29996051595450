<template>
  <omt-report-card
    :title="title"
    :description="description"
    :leadIcons="['fa fa-chevron-right fa-xs']"
    :hasEvaluation="!rating && confirmAttendance !== false"
    @click="handleClick"
  >
    <CFlex flexWrap="wrap" justify="space-between" align="center" v-if="!!rating" class="rating-container">
      <omt-p> Minha Avaliação </omt-p>
      <star-rating
        :inactive-color="theme == 'dark' ? '#ffffff' : '#BCBDC0'"
        :active-color="theme == 'dark' ? '#69788f' : '#102A46'"
        rounded-corners
        :star-size="18"
        :padding="6"
        text-class="hidden-item"
        :rating="rating"
        read-only
      />
    </CFlex>
    <CFlex v-if="confirmAttendance == false" justify="space-between" class="rating-container">
      <omt-p v-chakra textAlign="end"><strong>Atendimento não reconhecido</strong></omt-p>
    </CFlex>
  </omt-report-card>
</template>

<script>
import { CFlex, CStack, CBox } from "@chakra-ui/vue";
import StarRating from "vue-star-rating";
import { mapState } from "vuex";

export default {
  props: ["title", "description", "hasBottomBorder", "rating", "confirmAttendance"],
  components: {
    CFlex,
    CStack,
    CBox,
    StarRating
  },
  methods: {
    handleClick() {
      this.$emit("click");
    }
  },
  computed: mapState("saude", ["theme"])
};
</script>

<style lang="scss" scoped>
p {
  margin: 0 !important;
}

.fa-chevron-right {
  display: flex;
  align-items: center;
}

.rating-container {
  position: relative;
  padding-top: 6px;
  padding-bottom: 6px;
  top: 4px;

  .vue-star-rating {
    top: 4px;
    position: relative;
  }

  &::after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: #8e8e8e;
    opacity: 0.6;
    position: absolute;
    top: 0;
    transform: rotate(0deg);
    left: 0px;
  }
}
</style>
