var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('OmtGrid',[_c('OmtRow',{attrs:{"mb":"24px"}},[_c('ThirdPartyRegisterListItem',{attrs:{"item":_vm.owner}})],1),_vm._l((_vm.thirdParties),function(thirdParty,index){return _c('OmtRow',{key:index,attrs:{"mb":(index < _vm.thirdParties.length - 1 && '24px') || ''}},[_c('ThirdPartyRegisterItem',{attrs:{"type":"third-party","item":thirdParty,"id":index},on:{"action-modal":function($event){return _vm.handleEventItem('actionModal', $event)},"visualizar":function($event){return _vm.handleEventItem('visualizar', $event)},"prorrogar":function($event){return _vm.handleEventItem('renovar', $event)}}})],1)}),_c('OmtRow',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showTermModal),expression:"!showTermModal"}],attrs:{"mt":"160px","desktopStart":4,"desktopEnd":9}},[_c('OmtButton',{on:{"click":function($event){_vm.podeAdicionar() ? (_vm.showTermModal = true) : ''}}},[_c('OmtTypography',{attrs:{"as":['button-medium', 'button-large'],"color":"white"}},[_vm._v(" Cadastrar novo administrador ")])],1)],1),_c('ThirdPartyRegisterModalTerm',{attrs:{"isOpen":_vm.showTermModal},on:{"update:isOpen":function($event){_vm.showTermModal=$event},"update:is-open":function($event){_vm.showTermModal=$event},"continue":_vm.handleConcludeTerms}}),(_vm.showTokenModal)?_c('TokenModal',{attrs:{"tokenKey":"terceiro","service":_vm.tokenService},on:{"cancel":function($event){_vm.showTokenModal = false},"valid":_vm.validToken}}):_vm._e(),(_vm.showActionsModal)?_c('ActionsModal',{attrs:{"title":"Ações","list":(_vm.thirdPartyItemSelected.Tipo === 'Terceiro'
        ? [{ label: 'Editar Informações', action: 'edit', icon: 'pen-to-square' }]
        : []).concat( (_vm.thirdPartyItemSelected.Vigencia === '' &&
      _vm.thirdPartyItemSelected.Revalidacao === '' &&
      _vm.thirdPartyItemSelected.AdministradorNomeado == 'Revogado'
        ? [{ label: 'Reativar Autorização', action: 'autorizar', icon: 'circle-check' }]
        : []),
      (_vm.thirdPartyItemSelected.Vigencia === '' &&
      _vm.thirdPartyItemSelected.Revalidacao === '' &&
      _vm.thirdPartyItemSelected.AdministradorNomeado === 'Não'
        ? [{ label: 'Adicionar Autorização', action: 'adicionarAdministracao', icon: 'circle-check' }]
        : []),
      (_vm.thirdPartyItemSelected.AdministradorNomeado === 'Sim'
        ? [{ label: 'Renovar Autorização', action: 'renovar', icon: 'arrows-rotate' }]
        : []),
      (_vm.thirdPartyItemSelected.AdministradorNomeado === 'Sim' && _vm.thirdPartyItemSelected.Vigencia !== ''
        ? [{ label: 'Remover Autorização', action: 'remover', icon: 'circle-xmark' }]
        : [])
    ),"value":_vm.thirdPartyItemSelected},on:{"edit":function($event){return _vm.handleEventItem('editar', $event)},"autorizar":function($event){return _vm.handleEventItem('autorizar', $event)},"remover":function($event){return _vm.handleEventItem('remover', $event)},"renovar":function($event){return _vm.handleEventItem('renovar', $event)},"adicionarAdministracao":function($event){return _vm.handleEventItem('adicionarAdministracao', $event)},"close":function($event){_vm.showActionsModal = false}}}):_vm._e(),(_vm.showAutorizacaoModal)?_c('ThirdPartyRegisterAutorizacaoModal',{attrs:{"value":_vm.thirdPartyItemSelected,"type":_vm.AutorizacaoModalType},on:{"close":function($event){_vm.showAutorizacaoModal = false},"autorizar":function($event){return _vm.handleEventItem('reativar', $event)},"renovar":function($event){return _vm.handleEventItem('prorrogar', $event)},"adicionar":function($event){return _vm.handleEventItem('adicionar', $event)}}}):_vm._e(),(_vm.showFeedbackModal)?_c('FeedbackModal',{attrs:{"protocol":_vm.modalInfos.protocol,"title":_vm.modalInfos.title,"cardTitle":_vm.modalInfos.cardTitle,"cardDescription":_vm.modalInfos.cardDescription,"cardIcon":_vm.modalInfos.cardIcon,"modalIconBind":_vm.modalInfos.modalIconBind,"buttonLabel":_vm.modalInfos.buttonLabel},on:{"buttonClick":function($event){_vm.showFeedbackModal = false},"close":function($event){_vm.showFeedbackModal = false}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }