<template>
  <OmtLayoutContainer>
    <OmtRow z-index="1">
      <SafeLetterListFiltersHeader
        :isOpen.sync="showFiltersOptions"
        :filters="filters"
        :activedSort="activedSort"
        @change="SET_FILTERS($event)"
        @cleanSorts="SET_ACTIVED_SORT($event)"
      >
        <SafeLetterListSortButton :sorts="sorts" @sort="SET_ACTIVED_SORT($event)" :initialValue="activedSort" />
      </SafeLetterListFiltersHeader>
      <SafeLetterListFilterModal :isOpen.sync="showFiltersOptions" :filters="filters" @save="SET_FILTERS($event)" />
    </OmtRow>
    <OmtRow mt="16px" v-for="(solicitacao, index) in solicitacoesFiltradas" :key="index">
      <SafeLetterListCard :solicitacao="solicitacao" @emitir-pdf="download" />
    </OmtRow>
    <FixedButtonRow>
      <OmtButton :w="['100%', '308px !important']" @click="createNewLetter"> Solicitar nova carta </OmtButton>
    </FixedButtonRow>
  </OmtLayoutContainer>
</template>

<script>
import SafeLetterListFiltersHeader from "@/components/Organisms/SafeLetter/SafeLetterListFiltersHeader.vue";
import SafeLetterListFilterModal from "@/components/Organisms/SafeLetter/SafeLetterListFilterModal.vue";
import SafeLetterListSortButton from "@/components/Organisms/SafeLetter/SafeLetterListSortButton.vue";
import SafeLetterListCard from "@/components/Organisms/SafeLetter/SafeLetterListCard.vue";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  components: {
    SafeLetterListFiltersHeader,
    SafeLetterListFilterModal,
    SafeLetterListSortButton,
    SafeLetterListCard,
    FixedButtonRow
  },
  data() {
    return {
      showFiltersOptions: false
    };
  },
  computed: {
    ...mapState("safeLetter", ["activedSort", "filters", "sorts"]),
    ...mapGetters("safeLetter", ["solicitacoesFiltradas"])
  },
  async mounted() {
    this.$root.$emit("show-loading");
    try {
      await this.fetchSolicitacoes({ force: false });
    } finally {
      this.$root.$emit("hide-loading");
    }
  },
  methods: {
    ...mapActions("safeLetter", ["fetchSolicitacoes", "getUrlDownloadCartaSeguroViagem"]),
    ...mapMutations("safeLetter", ["SET_ACTIVED_SORT", "SET_FILTERS"]),
    async createNewLetter() {
      this.$router.push({
        name: "safe-letter.create",
        params: { etapa: "0" }
      });
    },
    download(url) {
      window.open(url, "_blank");
    }
  }
};
</script>
