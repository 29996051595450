<template>
  <div class="app-main-div" id="app">
    <template v-if="isAppLoaded">
      <TestOnline />
      <Loading />
      <omint-slider>
        <router-view />
      </omint-slider>
      <ExpirationTime />
    </template>
    <div v-else class="app-loading">
      <img :src="logoImg" />
      <p>Iniciando a aplicação...</p>
    </div>
  </div>
</template>
<script>
import TestOnline from "@/components/Molecules/TestOnline";
import Loading from "@/components/Molecules/Loading";
import ExpirationTime from "@/components/Molecules/ExpirationTime";
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { downloadUrl } from "@/utils/downloadUtil";

export default {
  components: { TestOnline, Loading, ExpirationTime },
  computed: {
    ...mapState("saude", ["isAppLoaded"]),
    ...mapGetters("biometrics", ["onNative"]),
    logoImg() {
      return process.env.VUE_APP_BASE_URL + "images/omint/logo-omint-app.png";
    }
  },
  created() {
    if (!this.onNative && this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.$workbox.messageSW({ type: "SKIP_WAITING" });
      });
    }
    this.$bus.$on("web_push", this.handleWebPush);
  },
  beforeDestroy() {
    this.$bus.$off("web_push");
  },
  methods: {
    handleWebPush(payload) {
      if (payload?.notification?.title && payload?.notification?.message) {
        payload.title = payload.notification.title;
        payload.message = payload.notification.message;
      }

      if (!payload?.title || !payload?.message) return;

      const link = payload.url_pagina ?? "";
      if (link) {
        Swal.fire({
          title: payload.title,
          html: payload.message,
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Ir para",
          cancelButtonText: "Fechar",
          allowEscapeKey: false,
          allowOutsideClick: false
        }).then(result => {
          if (result.value) {
            if (link.startsWith("/")) {
              this.$router.push(link);
            } else {
              downloadUrl(link);
            }
          }
        });
      } else {
        Swal.fire({
          title: payload.title,
          html: payload.message,
          confirmButtonText: "OK",
          icon: "info"
        });
      }
    }
  }
};
</script>
<style></style>
