import Vue from "vue";
import { ObterBeneficiariosAcessoFuncionalidade } from "@/api/associado";
import { ObterPeriodosExtratoUtilizacao, ObterExtratoUtilizacao, ExtratoServicoPDF } from "@/api/atendimento";
import { recuperarDadosVinculo } from "@/utils/vinculoUtil";

const initialState = {
  beneficiaries: [],
  semesters: [],
  services: []
};

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions: {
    async initBeneficiaries(context) {
      try {
        const response = await ObterBeneficiariosAcessoFuncionalidade({
          vinculo: context.rootState.saude.vinculo,
          funcionalidade: 2
        });

        const beneficiaries = response instanceof Error ? [] : response;
        await context.commit("SET_BENEFICIARIES", beneficiaries);
      } catch (error) {
        throw Error(error);
      }
    },
    async fetchSemesters(context, { vinculo }) {
      try {
        const { sequencia } = recuperarDadosVinculo(vinculo);

        const response = await ObterPeriodosExtratoUtilizacao({
          vinculo: context.rootState.saude.vinculo,
          beneficiario: sequencia
        });
        const semesters = response instanceof Error ? [] : response;
        await context.commit("SET_SEMESTERS", semesters);
        await context.commit("SET_SERVICES", []);
      } catch (error) {
        throw Error(error);
      }
    },
    async fetchServices(context, { ano, semestre, vinculoBeneficiario }) {
      try {
        if (!ano || !semestre) {
          await context.commit("SET_SERVICES", []);
          return;
        }

        const { sequencia } = recuperarDadosVinculo(vinculoBeneficiario);

        const response = await ObterExtratoUtilizacao({
          vinculo: context.rootState.saude.vinculo,
          semestre,
          ano,
          beneficiario: sequencia
        });

        const extratos = response instanceof Error ? [] : response;
        await context.commit("SET_SERVICES", extratos);
      } catch (error) {
        throw Error(error);
      }
    },
    async getExtractUrl(context, { ano, semestre, vinculoBeneficiario }) {
      try {
        const { sequencia } = recuperarDadosVinculo(vinculoBeneficiario);
        const result = ExtratoServicoPDF({
          vinculo: context.rootState.saude.vinculo,
          semestre,
          ano,
          beneficiario: sequencia
        });
        return result;
      } catch (error) {
        throw Error(error);
      }
    }
  },
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    SET_BENEFICIARIES: (state, beneficiaries) => Vue.set(state, "beneficiaries", beneficiaries),
    SET_SEMESTERS: (state, semesters) => Vue.set(state, "semesters", semesters),
    SET_SERVICES: (state, services) => Vue.set(state, "services", services)
  }
};
