<template>
  <OmtStickedModal @close="$emit('close')" data-cy="feedback-modal">
    <OmtTypography as="h1-bold" mb="5">{{ title }}</OmtTypography>
    <CFlex>
      <CBox>
        <OmtIcons name="square-poll-horizontal" mr="16px" size="lg" color="var(--colors-light-primary-gray1)"
      /></CBox>
      <CBox
        ><OmtTypography as="h3-bold" mb="24px"> Protocolo {{ protocolNumber }} </OmtTypography></CBox
      >
    </CFlex>
    <CFlex>
      <OmtTypography as="p2" color="var(--colors-light-primary-gray2)">
        {{ protocolTypeName }} • {{ protocolDate }}
      </OmtTypography>
    </CFlex>
    <CDivider mt="14px" mb="16px" />
    <OmtTypography as="h2-bold" mb="5">Como foi a sua experiência com esta solicitação?</OmtTypography>
    <CFlex justify="space-between" mb="8px" w="100%">
      <OmtButton
        v-for="rate in 5"
        :key="rate"
        :variant="rate === rating ? 'solid' : 'outline'"
        width="48px"
        height="50px"
        border="0.5px solid var(--colors-light-secondary-divider)"
        borderRadius="50%"
        data-cy="rate-buttons"
        @click="setRating(rate)"
      >
        <OmtTypography as="p1" :color="rate === rating ? 'white' : 'var(--colors-light-primary-gray1)'">
          {{ rate }}
        </OmtTypography>
      </OmtButton>
    </CFlex>
    <CFlex justify="space-between" mb="22px" w="100%">
      <OmtTypography as="p2"> Muito difícil </OmtTypography>
      <OmtTypography as="p2"> Muito fácil </OmtTypography>
    </CFlex>
    <CBox v-if="questions.length" class="rating-form_collapse">
      <CBox v-for="q in questions" :key="`q_${q.value}`">
        <OmtTypography as="h3-semi-bold" mb="16px">
          {{ q.label }}
        </OmtTypography>
        <OmtButton
          v-for="(opt, i) in q.options"
          :key="`q_${q.value}-${i}`"
          height="50px"
          border="0.5px solid var(--colors-light-secondary-divider)"
          borderRadius="30px"
          data-cy="rate-buttons"
          mb="32px"
          mr="8px"
          :variant="responses[q.value] === opt.value ? 'solid' : 'outline'"
          @click="setResponse(q.value, opt.value)"
        >
          <OmtTypography
            :color="responses[q.value] === opt.value ? 'white' : 'var(--colors-light-primary-gray1)'"
            :as="responses[q.value] === opt.value ? 'h3-bold' : 'h3-semi-bold'"
          >
            {{ opt.label }}
          </OmtTypography>
        </OmtButton>
      </CBox>
    </CBox>
    <CBox v-if="showObs" mb="8" class="rating-form_collapse-textarea">
      <CFlex mb="3">
        <OmtTypography as="h3-semi-bold">{{ obsLabel }}</OmtTypography>
        <OmtTypography as="h3-regular" pl="5px">{{ tipoObsLabel }}</OmtTypography>
      </CFlex>
      <CFlex flexDirection="column" alignItems="flex-end">
        <OmtTextarea
          :disabled="!rating"
          height="160px"
          padding="12px"
          :placeholder="placeholderObsLabel"
          @change="obs = $event"
          :initialValue="obs"
          borderRadius="8px"
          border="0.5px solid #DADADA"
          mb="2"
        />
        <OmtTypography as="p2"> {{ obs.length }}/350 </OmtTypography>
      </CFlex>
    </CBox>

    <OmtButton
      :disabled="!canSendEvaluation"
      borderRadius="30px"
      width="100%"
      :bigSize="true"
      @click="submit()"
      variant="solid"
    >
      Enviar
    </OmtButton>
  </OmtStickedModal>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: {
    protocolDate: {
      type: String,
      default: ""
    },
    protocolTypeName: {
      type: String,
      default: ""
    },
    protocolNumber: {
      type: String,
      default: ""
    },
    protocolType: {
      type: String,
      default: ""
    },
    stage: {
      type: String,
      default: "S"
    },
    title: {
      type: String,
      default: "O que você achou deste serviço?"
    }
  },
  data() {
    return {
      allQuestions: [],
      avaliado: false,
      obs: "",
      obsLabel: "",
      placeholderObsLabel: "",
      questions: [],
      tipoObsLabel: "",
      rating: 0,
      responses: {}
    };
  },
  computed: {
    canSendEvaluation() {
      if (this.showObs && this.tipoObsLabel === "(obrigatório)" && !this.obs) return false;
      const emptyInput = this.questions.filter(q => q.required && !this.responses[q.value]);
      return !emptyInput.length && this.rating >= 1 && this.rating <= 5;
    },
    ehSolicitacao() {
      return this.stage === "S";
    },
    showObs() {
      if (this.ehSolicitacao) return true;
      if (this.rating === 0 || this.rating >= 3) return false;
      if (!this.allQuestions?.length) return true;
      return !!this.questions.find(q => q.maxRating >= this.rating);
    }
  },
  methods: {
    ...mapActions("ticket", ["saveExpRating", "fetchQuestionsExpRating"]),
    setRating(rating) {
      this.rating = rating;
      this.updateQuestions();
    },
    setResponse(question, option) {
      this.responses[question] = option;
      this.updateQuestions();
    },
    updateQuestions() {
      this.allQuestions.forEach(q => {
        const current = q.show;
        q.show = q.maxRating >= this.rating;
        if (q.show && q.exceptions?.length > 0) {
          for (let i = 0; i < q.exceptions.length; i++) {
            if (this.responses[q.exceptions[i].question] === q.exceptions[i].option) {
              q.show = false;
              break;
            }
          }
        }
        if (current && !q.show) {
          this.responses[q.value] = 0;
        }
      });
      this.questions = this.allQuestions.filter(q => q.show);
    },
    async submit() {
      if (!this.canSendEvaluation) return;
      let newRatingValue = null;
      try {
        await this.$root.$emit("show-loading");
        newRatingValue = this.rating;
        const responses = [];
        Object.keys(this.responses).map(r => {
          if (this.responses[r] > 0) {
            responses.push({
              pergunta: r,
              resposta: this.responses[r]
            });
          }
        });
        await this.saveExpRating({
          ticket: this.protocolNumber,
          type: this.protocolType,
          step: this.stage,
          acknowledged: true,
          rating: newRatingValue,
          obs: this.obs,
          responses
        });
      } catch (error) {
        console.error("cant save avaliation", error);
      } finally {
        this.$emit("ratingSubmitClick", { newRatingValue });
        if (this.rotaDestino) {
          await this.$router.replace(this.rotaDestino);
        } else {
          this.avaliado = true;
          await this.$root.$emit("hide-loading");
        }
      }
    }
  },
  async mounted() {
    if (!this.protocolNumber || !this.protocolType) {
      this.$emit("close");
      return;
    }
    try {
      const rules = await this.fetchQuestionsExpRating({
        ticket: this.protocolNumber,
        type: this.protocolType,
        stage: this.stage
      });
      this.obsLabel = rules.label;
      this.allQuestions = rules.questions;
    } catch (error) {
      console.error("cant load avaliation questions", error);
    } finally {
      if (this.obsLabel) {
        if (this.obsLabel.includes("(opcional)")) {
          this.tipoObsLabel = "(opcional)";
          this.obsLabel = this.obsLabel.replace("(opcional)", "").trim();
        } else if (this.obsLabel.includes("(obrigatório)")) {
          this.tipoObsLabel = "(obrigatório)";
          this.obsLabel = this.obsLabel.replace("(obrigatório)", "").trim();
        }
      } else {
        this.obsLabel = this.etapa === "S" ? "Deixe um Comentário/Sugestão" : "O que podemos melhorar?";
      }
      if (!this.tipoObsLabel) this.tipoObsLabel = this.etapa === "S" ? "(opcional)" : "(obrigatório)";
      this.placeholderObsLabel = this.etapa === "S" ? "Comentário/Sugestão" : "Conte-nos sobre como podemos melhorar";
      this.avaliado = false;
      await this.$root.$emit("hide-loading");
    }
  }
};
</script>
