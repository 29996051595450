<template>
  <omint-page-content>
    <omt-highlight v-chakra mb="16px !important">Observações (máximo de 500 caracteres)</omt-highlight>
    <omt-textarea
      type="text"
      v-bind:value="obs"
      v-on:input="obs = $event"
      rows="5"
      maxlength="500"
      v-chakra
      mb="16px"
    />

    <omt-highlight v-chakra mb="0 !important">Documentos</omt-highlight>
    <omt-inline-bar class="upload-bar">
      <omt-icon v-for="(f, i) in files" :idx="i" :key="i">
        <div v-if="!f.url">
          <label :for="'file' + i">
            <i class="fas fa-plus"></i>
            <i class="far fa-image fa-4x"></i>
          </label>
          <input accept="application/pdf, image/png, image/jpeg" type="file" :id="'file' + i" @change="previewFile" />
        </div>
        <div v-else @click.prevent="() => removeFile(i)">
          <i class="fas fa-minus-circle"></i>
          <img class="thumb" v-if="f.thumb" :src="f.thumb" :alt="'Anexo ' + i" />
          <i v-else class="fas fa-file-pdf fa-4x"></i>
        </div>
      </omt-icon>
    </omt-inline-bar>

    <omt-buttons-bar>
      <omt-button :onClick="() => $router.back()"> Voltar </omt-button>
      <omt-button :on-click="submit" :disabled="obs === ''"> Prosseguir </omt-button>
    </omt-buttons-bar>
    <br />
  </omint-page-content>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "NewTicketForm",
  props: ["idCC"],
  data: function () {
    return {
      obs: "",
      ticket: null,
      totalFiles: -1,
      limitFiles: 6,
      files: []
    };
  },
  computed: {
    urls: function () {
      const u = [];
      this.files.forEach(element => {
        if (element.url) u.push(element.url);
      });
      return u;
    }
  },
  async mounted() {
    await this.$root.$emit("show-loading");
    try {
      for (let i = 0; i < this.limitFiles; i++) {
        this.files.push({ thumb: null, url: null });
      }
      if (this.$route.params.idCC) {
        this.ticket = await this.findTicket(this.$route.params.idCC);
        if (!this.ticket) {
          await this.$router.replace({ path: "/atendimento-lista" });
        }
      }
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  methods: {
    ...mapActions("saude", ["uploadFile"]),
    ...mapActions("ticket", ["createNewTicket", "findTicket"]),
    ...mapMutations("ticket", ["SET_RATING_INFOS"]),
    removeFile(fileId) {
      if (fileId < 0 || fileId > this.totalFiles) return;
      while (fileId < this.totalFiles) {
        const nextFile = this.files[fileId + 1];
        this.files[fileId] = { thumb: nextFile.thumb, url: nextFile.url };
        fileId++;
      }
      this.files[this.totalFiles] = { thumb: null, url: null };
      this.totalFiles--;
      this.recreateFiles();
    },
    recreateFiles() {
      const oldFiles = this.files;
      this.files = [];
      oldFiles.forEach(element => {
        this.files.push(element);
      });
    },
    async previewFile(event) {
      await this.$root.$emit("show-loading");
      let resp = {};
      try {
        resp = await this.uploadFile({
          filename: event.target.files[0].filename,
          blobFile: event.target.files[0]
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
      if (resp.Data && resp.Data[0]) {
        if (resp.Data[0].message !== "OK") {
          await Swal.fire({
            title: "Alerta",
            text: resp.Data[0].message,
            confirmButtonText: "OK",
            icon: "warning"
          });
        } else if (this.totalFiles + resp.Data.length > this.limitFiles - 1) {
          await Swal.fire({
            title: "Alerta",
            text: `O total de imagens e número de páginas PDF não pode ultrapassar ${this.limitFiles}`,
            confirmButtonText: "OK",
            icon: "warning"
          });
        } else {
          for (let i = 0; i < resp.Data.length; i++) {
            this.totalFiles++;
            this.files[this.totalFiles] = { thumb: resp.Data[i].thumbUrl, url: resp.Data[i].fileRemoteUrl };
          }
          this.recreateFiles();
        }
      }
    },
    async submit() {
      await this.$root.$emit("show-loading");
      let resp = {};
      try {
        resp = await this.createNewTicket({
          obs: this.obs,
          files: this.urls,
          refTicket: this.ticket ? this.ticket.IdChamadoCC : null,
          classification: "5856"
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
      if (resp.Data) {
        if (this.ticket) {
          await this.$router.replace({
            path: `/ticket-protocol/${resp.Data.protocoloANS}`
          });
        } else {
          await this.$root.$emit("show-loading");
          await this.findTicket(resp.Data.protocoloANS);
          this.SET_RATING_INFOS({ ratingRoute: "/atendimento-lista", ratingType: 1 });
          await this.$root.$emit("hide-loading");
          await this.$router.replace({
            path: `/experience-rating/${resp.Data.protocoloANS}/1/S`
          });
        }
      } else {
        await Swal.fire({
          title: "Erro",
          text: "Não foi possivel registrar o atendimento.\nServiço temporariamente indisponível.",
          confirmButtonText: "OK",
          icon: "error"
        });
      }
    }
  }
};
</script>

<style scoped>
.upload-bar {
  justify-content: space-between;
  flex-wrap: nowrap !important;
  margin-top: 0 !important;
}

.upload-bar div {
  margin: 0;
}

.upload-bar div i:first-child {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  right: -36px;
  background: #f0f0f0;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  z-index: 10;
  border: 2px solid #112b46;
  color: #112b46;
  font-size: 100%;
}

.upload-bar div input {
  display: none;
}

.upload-bar div img {
  width: 48px;
  height: 48px;
}

.upload-bar div label {
  cursor: pointer;
}

.fa-plus::before,
.fa-minus-circle::before {
  position: relative;
  right: -0.5px;
}
</style>
