<template>
  <OmtGrid>
    <OmtRow :desktopStart="3" :desktopEnd="10" :min-height="['calc(100vh - 266px)', 'calc(100vh - 500px)']">
      <CBox v-if="titulo && texto">
        <OmtTypography as="h1-bold" mb="24px">
          <span v-html="titulo" data-cy="terms-title" />
        </OmtTypography>

        <OmtTypography mb="16px" as="p1">
          <span v-html="texto" data-cy="terms-text" />
        </OmtTypography>
      </CBox>
      <CFlex align-items="center" justify-content="center" v-else> Carregando... </CFlex>
    </OmtRow>
    <OmtRow :desktopStart="4" :desktopEnd="9">
      <CStack :align="['flex-start', 'center']">
        <OmtButton w="100%" mb="24px" @click="openInfoModal" :disabled="!titulo || !texto">
          Concordar e continuar
        </OmtButton>
        <OmtTypography as="p2" display="inline-flex">
          Ao clicar, você concorda com os Termos e Condições.
        </OmtTypography>
      </CStack>
    </OmtRow>

    <RefundCreateTermsModal :isOpen.sync="modalIsOpen" @continue="onContinue" />
  </OmtGrid>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RefundCreateTermsModal from "@/components/Organisms/RefundNew/RefundCreateTermsModal.vue";

export default {
  components: {
    RefundCreateTermsModal
  },
  data() {
    return {
      modalIsOpen: false
    };
  },
  computed: {
    ...mapState("refund", ["addPermission", "terms", "newRefund"]),
    titulo() {
      return this.terms ? this.terms.ds_TituloTermoAceite : "";
    },
    texto() {
      return this.terms ? this.terms.ds_TextoTermoAceite : "";
    }
  },
  async mounted() {
    if (!this.newRefund || this.newRefund.type === null) {
      this.$router.push({ name: "refund.new.create" });
      return;
    }
    try {
      await this.$root.$emit("show-loading");
      if (!this.addPermission) {
        await this.fetchAddPermission();
      }
      if (this.addPermission) {
        await this.fetchTerms();
      } else {
        this.$router.push({ name: "refund.new" });
        return;
      }
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  methods: {
    ...mapActions("refund", ["fetchAddPermission", "fetchTerms"]),
    onContinue() {
      this.$router.push({
        name: "refund.new.create.credit-inform"
      });
    },
    openInfoModal() {
      this.modalIsOpen = true;
    }
  }
};
</script>
