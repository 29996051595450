<template>
  <omint-page>
    <Header :showLogo="true" />
    <omint-slider>
      <router-view />
    </omint-slider>
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";

export default {
  name: "ForgotPassword",
  components: {
    Header
  },
  mounted() {
    this.$root.$emit("hide-loading");
  }
};
</script>
