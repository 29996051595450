<template>
  <omint-page-content>
    <omt-h1> Enviado com sucesso! </omt-h1>
    <omt-h2>
      Seu protocolo é:
      <omt-p class="inline">
        <omt-bold>{{ protocol }}</omt-bold>
      </omt-p>
    </omt-h2>

    <br />
    <omt-p>
      Sua autorização será enviada para o local escolhido e também ficará disponível no menu Autorizações desse App e na
      área logada Omint Mais do nosso site www.omint.com.br
    </omt-p>

    <br />
    <br />

    <omt-buttons-bar>
      <omt-button :onClick="() => $router.replace({ name: 'voucher.info' })"> Resumo </omt-button>
    </omt-buttons-bar>
    <br />
  </omint-page-content>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "VoucherProtocol",
  computed: {
    ...mapState("voucher", ["protocol"])
  },
  mounted() {
    this.$root.$emit("hide-loading");
  }
};
</script>

<style scoped>
.inline {
  display: inline-block;
}
</style>
