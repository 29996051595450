<template>
  <OmtStickedModal @close="close">
    <template #header>
      <OmtButton :display="['block', 'none']" class="clear" variant="ghost" @click="clear" height="18px" p="0">
        <OmtTypography as="p1" h="18px" w="46px" position="relative"> Limpar </OmtTypography>
      </OmtButton>
    </template>

    <CBox :minHeight="['initial', 'calc(100vh - 400px)']">
      <CFlex justifyContent="space-between" :mb="['32px', '36px']">
        <OmtTypography as="h1-bold"> Filtrar </OmtTypography>
        <OmtButton class="clear" variant="ghost" @click="clear" height="18px" p="0" :display="['none', 'block']">
          <OmtTypography as="p1" h="18px" w="46px" position="relative"> Limpar </OmtTypography>
        </OmtButton>
      </CFlex>

      <OmtTypography as="h2-bold" mb="24px"> Por beneficiário </OmtTypography>
      <CStack flex-direction="column-reverse !important" mb="24px">
        <CBox v-for="(sub, index) in internalFilters.beneficiary" :key="`bene-${index}`">
          <CFlex justify="space-between" cursor="pointer">
            <OmtRadio
              @click="internalFilters.selectedBeneficiary = $event"
              :value="sub.label"
              :isChecked="internalFilters.selectedBeneficiary === sub.label"
              name="rate"
            >
              <OmtTypography as="p1"> {{ sub.label }} </OmtTypography>
            </OmtRadio>
          </CFlex>
          <OmtDivider v-if="index !== internalFilters.beneficiary.length - 1" />
        </CBox>
      </CStack>

      <OmtTypography as="h2-bold" mb="24px"> Por status </OmtTypography>
      <CStack mb="24px">
        <CBox v-for="(sub, index) in internalFilters.status" :key="`facilities-${index}`">
          <CFlex cursor="pointer" mb="12px">
            <OmtCheckbox @change="sub.isActive = !sub.isActive" :is-checked="sub.isActive" isFullWidth inverted>
              <OmtTypography as="p1"> {{ sub.label }} </OmtTypography>
            </OmtCheckbox>
          </CFlex>
        </CBox>
      </CStack>

      <OmtTypography as="h2-bold" mb="24px"> Por Assunto </OmtTypography>
      <CStack mb="24px">
        <CBox v-for="(sub, index) in internalFilters.title" :key="`facilities-${index}`">
          <CFlex cursor="pointer" mb="12px">
            <OmtCheckbox @change="sub.isActive = !sub.isActive" :is-checked="sub.isActive" isFullWidth inverted>
              <OmtTypography as="p1"> {{ sub.label }} </OmtTypography>
            </OmtCheckbox>
          </CFlex>
        </CBox>
      </CStack>
    </CBox>

    <template #footer>
      <OmtButton w="100%" mb="24px" @click="save"> Aplicar </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
export default {
  props: ["filters"],
  data() {
    return {
      internalFilters: {
        selectedBeneficiary: "",
        beneficiary: [],
        status: [],
        title: [],
        totalActived: 0
      }
    };
  },
  methods: {
    init() {
      const filters = JSON.parse(JSON.stringify(this.filters));
      this.internalFilters = filters;
    },
    close() {
      this.$emit("close");
      this.clear();
    },
    clear() {
      const filters = JSON.parse(JSON.stringify(this.filters));
      filters.selectedBeneficiary = "";
      if (filters?.beneficiary?.length) filters.beneficiary.forEach(e => (e.isActive = false));
      if (filters?.status?.length) filters.status.forEach(e => (e.isActive = false));
      if (filters?.title?.length) filters.title.forEach(e => (e.isActive = false));
      this.internalFilters = filters;
    },
    save() {
      const filters = JSON.parse(JSON.stringify(this.internalFilters));
      if (filters.selectedBeneficiary) {
        const bene = filters.beneficiary.find(f => f.label === filters.selectedBeneficiary);
        if (bene) bene.isActive = true;
      }
      const beneficiaries = filters.beneficiary.filter(f => f.isActive) ?? [];
      const status = filters.status.filter(f => f.isActive) ?? [];
      const titles = filters.title.filter(f => f.isActive) ?? [];
      filters.totalActived = beneficiaries.length + status.length + titles.length;
      this.$emit("save", filters);
      this.close();
    }
  },
  watch: {
    filters: {
      immediate: true,
      handler() {
        this.init();
      }
    }
  }
};
</script>
