<template>
  <CBox>
    <OmtTypography as="h2-bold" mb="16px" v-show="!!question.text">
      {{ question.text }}
    </OmtTypography>

    <OmtRadioGroup
      v-if="question.options"
      hasDivider
      :values="question.options"
      :value.sync="selectedRadio"
      customValueKey="id"
    />
  </CBox>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      default: () => ({
        text: "",
        options: []
      })
    }
  },
  data() {
    return {
      selectedRadio: null
    };
  },
  watch: {
    selectedRadio(val) {
      this.$emit("change", val);
    }
  }
};
</script>
