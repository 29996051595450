<template>
  <div :style="myStyle" :class="myClass"></div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: false,
      default: "dropdown",
      note: "Input name"
    },
    top: {
      type: String,
      default: ""
    },
    right: {
      type: String,
      default: ""
    },
    bottom: {
      type: String,
      default: ""
    },
    left: {
      type: String,
      default: ""
    },
    onlyClear: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    myStyle() {
      const top = this.top === "" ? (this.onlyClear ? "0" : "15") : this.top;
      const right = this.right === "" ? "0" : this.right;
      const bottom = this.bottom === "" ? (this.onlyClear ? "0" : "15") : this.bottom;
      const left = this.left === "" ? "0" : this.left;
      return `margin-top: ${top}px; margin-right: ${right}px; margin-bottom: ${bottom}px; margin-left: ${left}px;`;
    },
    myClass() {
      return this.onlyClear ? "clear" : "clear divider";
    }
  }
};
</script>
<style scoped>
.clear {
  display: block;
  clear: both;
  padding: 0px;
}

.divider {
  width: 100%;
  height: 1px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.06);
}

.dark .divider {
  color: #031432;
  background-color: rgba(255, 255, 255, 0.06);
}

.light .divider {
  color: white;
  background-color: rgba(0, 0, 0, 0.06);
}
</style>
