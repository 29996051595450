<template>
  <a :class="gridClass" class="tile" @click.prevent="onClick">
    <div class="icon"><i :class="iconClass"></i></div>
    <div class="badge" v-if="!!badge">
      <span> {{ badge }}</span>
    </div>
    {{ title }}
  </a>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["gridClass", "title", "link", "icon", "badge"],
  computed: {
    ...mapGetters("saude", ["baseUrl"]),
    iconClass() {
      return this.icon === "fa-comments" || this.icon === "fa-whatsapp"
        ? "fab fa-2x fa-whatsapp"
        : `fa fa-2x ${this.icon}`;
    }
  },
  methods: {
    onClick() {
      if (typeof this.link === "function") {
        this.link();
      } else if (this.link) {
        this.$router.push(this.link);
      }
    }
  }
};
</script>
<style scoped>
.tile {
  position: relative;
  cursor: pointer;
  transition: opacity 0.5s ease;
  box-shadow: 0px 0px 16px 2px #00000026;
}

.tile:hover {
  opacity: 0.6;
}

.badge {
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 4px;
  background-color: #102a46;
  font-size: 10px;
}

.icon {
  display: block;
  clear: both;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 8px;
}

.one-half {
  width: calc(50% - 7px);
}

.one-third {
  width: calc(33.33% - 10px);
}

a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 110px;
  margin-bottom: 30px;
  align-items: center;
  text-align: center;
  font-size: 2.9vw;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  border: 1px transparent;
}

.big {
  font-size: 3.2vw;
  line-height: 100%;
}

.light .one-half,
.light .one-third {
  background-color: white;
  color: var(--colors-light-primary-gray1);
}

.light .one-half.inverse,
.light .one-third.inverse {
  color: white;
  background-color: #68788c;
}

.dark .one-half,
.dark .one-third {
  background-color: #1f1f1f;
  color: #ccd1d9;
}

.dark .one-half.inverse,
.dark .one-third.inverse {
  color: #1f1f1f;
  background-color: #ccd1d959;
}

/* menores que um desktop */
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .icon {
    font-size: 14px;
  }

  .one-half,
  .one-third {
    width: calc(20% - 22px);
    font-size: 14px;
    height: 75px;
    line-height: 13px;
  }

  .light .one-half:hover,
  .light .one-half:focus,
  .light .one-third:hover,
  .light .one-third:focus {
    border: 1px solid;
  }

  .light .one-half.inverse:hover,
  .light .one-half.inverse:focus,
  .light .one-third.inverse:hover,
  .light .one-third.inverse:focus {
    border: 1px solid;
  }

  .dark .one-half:hover,
  .dark .one-half:focus,
  .dark .one-third:hover,
  .dark .one-third:focus {
    border: 1px solid;
  }

  .dark .one-half.inverse:hover,
  .dark .one-half.inverse:focus,
  .dark .one-third.inverse:hover,
  .dark .one-third.inverse:focus {
    border: 1px solid;
  }
}
</style>
