import Vue from "vue";
import * as api from "@/api/redeCredenciada";
import * as geoUtils from "@/utils/geoDistance";
import { allowUpdate } from "@/utils/checkReadonlyUtil";

const initialState = {
  groups: [],
  specialities: [],
  subSpecialities: [],
  locations: [],
  regions: {},
  states: [],
  matches: [],
  planos: [],
  plano: "",
  redes: [],
  rede: "",
  tipoAtendimento: "",
  group: "",
  codigoEspecialidade: "",
  descricaoEspecialidade: "",
  codigoSubEspecialidade: "",
  descricaoSubEspecialidade: "",
  state: "",
  city: "",
  neighborhood: "",
  locationType: "",
  locationName: "",
  docInfo: {},
  match: {},
  favorites: [],
  latestViews: [],
  locationFilter: {},
  deactivated: false,
  recommendationDetail: {},
  fetchAccessResource: false,
  filters: {},
  sort: ""
};

// vuex module
export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions: {
    async addFavorite(context, { prestador, endereco }) {
      if (!context.rootState.saude.vinculo) return 0;
      if (!allowUpdate()) return 0;
      const result = await api.AdicionarFavorito({
        vinculo: context.rootState.saude.vinculo,
        prestador,
        endereco
      });
      context.dispatch("fetchFavorites");
      context.dispatch("fetchLatestViews");
      return result.Data;
    },
    async remFavorite(context, { prestador, endereco }) {
      if (!context.rootState.saude.vinculo) return 0;
      if (!allowUpdate()) return 0;
      const result = await api.RemoverFavorito({
        vinculo: context.rootState.saude.vinculo,
        prestador,
        endereco
      });
      context.dispatch("fetchFavorites");
      return result.Data;
    },
    async testFavorite(context, { prestador, endereco }) {
      if (!context.rootState.saude.vinculo) {
        return 0;
      }
      const result = await api.VerificarFavorito({
        vinculo: context.rootState.saude.vinculo,
        prestador,
        endereco
      });
      return result.Data;
    },
    async fetchFavorites(context) {
      if (!context.rootState.saude.vinculo) return 0;
      await context.commit("SET_LAST_RECOMMENDATION_DETAIL", {});
      const prestadores = await api.BuscarFavoritos({
        vinculo: context.rootState.saude.vinculo
      });
      if (prestadores.Data) {
        const data = await geoUtils.CalculateDistance(prestadores.Data, "GmLatitude", "GmLongitude");
        await context.commit("SET_FAVORITES", data);

        return prestadores.Data.length;
      }
      return 0;
    },
    async addAffiliateView(context, { prestador, endereco }) {
      if (!context.rootState.saude.vinculo) return 0;
      if (!allowUpdate()) return 0;
      const result = await api.RegistrarAcesso({
        vinculo: context.rootState.saude.vinculo,
        prestador,
        endereco
      });
      context.dispatch("fetchLatestViews");
      return result.Data;
    },
    async fetchLatestViews(context) {
      if (!context.rootState.saude.vinculo) {
        return 0;
      }
      const prestadores = await api.BuscarHistoricoAcesso({
        vinculo: context.rootState.saude.vinculo
      });
      if (prestadores.Data) {
        const data = await geoUtils.CalculateDistance(prestadores.Data, "GmLatitude", "GmLongitude");
        await context.commit("SET_LAST_VIEWS", data);
        return prestadores.Data.length;
      }
      return 0;
    },
    async fetchLocationFilter(context) {
      const tpR = "Seu endereço residencial";
      const tpC = "Seu local de trabalho";
      const tpG = "Perto de Sua Localização Atual";
      const locationFilter = context.rootState.saude.vinculo
        ? {
            R: context.state.locationFilter.R ?? tpR,
            C: context.state.locationFilter.C ?? tpC,
            G: context.state.locationFilter.G ?? tpG,
            latitude: context.state.locationFilter.latitude ?? 0,
            longitude: context.state.locationFilter.longitude ?? 0
          }
        : {
            G: context.state.locationFilter.G ?? tpG,
            latitude: context.state.locationFilter.latitude ?? 0,
            longitude: context.state.locationFilter.longitude ?? 0
          };
      const buscas = [];
      if (context.rootState.saude.vinculo && (!locationFilter.R || locationFilter.R === tpR)) {
        buscas.push(
          api
            .BuscarEnderecoReferencia({
              vinculo: context.rootState.saude.vinculo,
              tipoEndereco: "R",
              latitude: 0,
              longitude: 0
            })
            .then(locationR => {
              if (locationR && locationR.Data) {
                locationFilter.R = locationR.Data;
              }
            })
        );
      }
      if (context.rootState.saude.vinculo && (!locationFilter.C || locationFilter.C === tpC)) {
        buscas.push(
          api
            .BuscarEnderecoReferencia({
              vinculo: context.rootState.saude.vinculo,
              tipoEndereco: "C",
              latitude: 0,
              longitude: 0
            })
            .then(locationC => {
              if (locationC && locationC.Data) {
                locationFilter.C = locationC.Data;
              }
            })
        );
      }
      const coords = await geoUtils.GetLocation().catch(() => null);
      if (context.rootState.saude.vinculo && coords) {
        if (coords.latitude !== locationFilter.latitude || coords.longitude !== locationFilter.longitude) {
          buscas.push(
            api
              .BuscarEnderecoReferencia({
                vinculo: context.rootState.saude.vinculo,
                tipoEndereco: "",
                latitude: coords.latitude,
                longitude: coords.longitude
              })
              .then(locationG => {
                if (locationG && locationG.Data) {
                  locationFilter.G = locationG.Data;
                  locationFilter.latitude = coords.latitude;
                  locationFilter.longitude = coords.longitude;
                }
              })
          );
        }
      }
      if (buscas?.length) {
        await Promise.all(buscas);
      }
      await context.commit("SET_LOCATION_FILTER", locationFilter);
    },
    async fetchPlans(context) {
      if (context.state.planos?.length) return;
      const planos = await api.ObterPlanos();
      if (planos.Data) {
        await context.commit("SET_PLANOS", planos.Data);
      }
    },
    async fetchRedes(context) {
      if (context.state.redes?.length) return;
      const redes = await api.ObterRedes();
      if (redes.Data) {
        await context.commit("SET_REDES", redes.Data);
      }
    },
    async fetchGroups(context) {
      if (!context.rootState.saude.vinculo && !context.state.plano && !context.state.rede) {
        await Promise.all([context.dispatch("fetchPlans"), context.dispatch("fetchRedes")]);
        return;
      }
      const tiposAtendimento = [];
      if (context.rootState.saude.vinculo) {
        const resp = await api.ObterTipoAtendimento({
          vinculo: context.rootState.saude.vinculo,
          rede: ""
        });
        if (resp?.Data?.length) tiposAtendimento.push(...resp.Data);
      } else if (context.state.plano) {
        const plano = context.state.planos.find(p => p.Codigo === context.state.plano);
        for (let i = 0; i < plano.Redes.length; i++) {
          const resp = await api.ObterTipoAtendimento({
            vinculo: "",
            rede: plano.Redes[i].Codigo
          });
          if (resp?.Data?.length) tiposAtendimento.push(...resp.Data);
        }
      } else if (context.state.rede) {
        const resp = await api.ObterTipoAtendimento({
          vinculo: "",
          rede: context.state.rede
        });
        if (resp?.Data?.length) tiposAtendimento.push(...resp.Data);
      }
      if (tiposAtendimento.length) {
        await context.commit("SET_GROUPS", tiposAtendimento);
      }
    },
    async fetchSpecialities(context) {
      context.commit("SET_SPECIALITIES", []);
      const { rede, tipoAtendimento } = context.state;
      const specs = await api.ObterEspecialidades({
        vinculo: context.rootState.saude.vinculo,
        rede,
        tipoAtendimento,
        inativos: context.state.deactivated,
        nomeEspecialidade: ""
      });

      if (specs?.Data?.length) {
        context.commit("SET_SPECIALITIES", specs.Data);
      } else {
        return Promise.reject(new Error("Nenhuma especialidade encontrada"));
      }
    },
    async fetchSubSpecialities(context) {
      context.commit("SET_SUB_SPECIALITIES", []);
      const { rede, tipoAtendimento } = context.state;
      const specs = await api.ObterSubEspecialidades({
        vinculo: context.rootState.saude.vinculo,
        rede,
        tipoAtendimento,
        codigoEspecialidade: context.state.codigoEspecialidade,
        inativos: context.state.deactivated
      });
      context.commit("SET_SUB_SPECIALITIES", specs.Data ?? []);
    },
    async fetchWithLocations(context, { codigoEstado, cidade, bairro }) {
      const locs = await api.BuscarPorRegiao({
        vinculo: context.rootState.saude.vinculo,
        rede: context.state.rede,
        tipoAtendimento: context.state.tipoAtendimento,
        codigoEspecialidade: context.state.codigoEspecialidade,
        subEspecialidade: context.state.codigoSubEspecialidade,
        codigoEstado,
        cidade,
        bairro,
        inativos: context.state.deactivated
      });

      if (locs && locs.Data) {
        await context.commit("SET_LOCATIONS", locs.Data);
      }
    },
    async fetchRegions(context) {
      const locs = await api.ObterRegioes({
        vinculo: context.rootState.saude.vinculo,
        rede: context.state.rede,
        tipoAtendimento: context.state.tipoAtendimento,
        codigoEspecialidade: context.state.codigoEspecialidade,
        subEspecialidade: context.state.codigoSubEspecialidade,
        inativos: context.state.deactivated
      });

      if (locs.Data) {
        await context.commit("SET_REGIONS", locs.Data.Regioes);
        const states = Object.keys(locs.Data.Regioes).sort();
        await context.commit("SET_STATES", states);
      } else {
        return Promise.reject(new Error("nenhuma região encontrada"));
      }
    },
    async checkDeactivateMatch(context) {
      if (context.state.deactivatedMatches && !context.state.match.CodigoPrestador) {
        await context.commit("SET_LOCATION_TYPE", context.state.deactivatedMatches.locationType);
        await context.commit("SET_MATCH", context.state.deactivatedMatches.match);
        await context.commit("SET_MATCHES", context.state.deactivatedMatches.matches);
        await context.commit("SET_DEACTIVATED", context.state.deactivatedMatches.deactivated);
        await context.commit("SET_DEACTIVATED_MATCHES", null);
      }
    },
    async fetchDoctorsInfo(context, { rede, codigoPrestador, srEndereco, inativos }) {
      if (
        context.state.docInfo?.CodigoPrestador === codigoPrestador &&
        context.state.docInfo?.SrEndereco === srEndereco
      ) {
        return;
      }
      if (context.state.deactivated !== inativos) {
        context.dispatch("SET_DEACTIVATED", inativos);
      }
      const completo =
        context.state.match?.CodigoPrestador !== codigoPrestador || context.state.match?.SrEndereco !== srEndereco;
      const info = await api.DetalhePrestador({
        vinculo: context.rootState.saude.vinculo,
        rede,
        codigoPrestador,
        srEndereco,
        inativos,
        completo
      });
      const docInfo = info && info.Data ? info.Data : {};
      docInfo.CodigoPrestador = codigoPrestador;
      docInfo.SrEndereco = srEndereco;
      if (completo && docInfo.Prestador) {
        let matches = [docInfo.Prestador];
        matches = await geoUtils.CalculateDistance(matches, "GmLatitude", "GmLongitude");
        context.commit("SET_MATCH", matches[0]);
      }

      context.commit("SET_DOCINFO", docInfo);
    },
    async fetchSubstitutes(context) {
      let substitutos = await api.ObterPrestadoresSubstitutos({
        vinculo: context.rootState.saude.vinculo,
        rede: context.state.rede,
        codigoPrestador: context.state.match.CodigoPrestador,
        srEndereco: context.state.match.SrEndereco
      });
      context.commit("SET_DEACTIVATED_MATCHES", {
        locationType: context.state.locationType,
        matches: context.state.matches,
        match: context.state.match,
        deactivated: context.state.deactivated
      });
      const data = await geoUtils.CalculateDistance(
        substitutos && substitutos.Data ? substitutos.Data : [],
        "GmLatitude",
        "GmLongitude"
      );
      context.commit("SET_MATCHES", data);
      context.commit("SET_LOCATION_TYPE", "Credenciados substitutos");
      context.commit("SET_DEACTIVATED", false);
    },
    async fetchMatches(context) {
      const docs = await api.BuscarPorRegiao({
        vinculo: context.rootState.saude.vinculo,
        rede: context.state.rede,
        tipoAtendimento: context.state.tipoAtendimento,
        codigoEspecialidade: context.state.codigoEspecialidade,
        subEspecialidade: context.state.codigoSubEspecialidade,
        codigoEstado: context.state.state,
        cidade: context.state.city,
        bairro: context.state.neighborhood,
        inativos: context.state.deactivated
      });

      let locationName = "";
      if (context.state.neighborhood) {
        locationName = `${context.state.neighborhood}, ${context.state.city} - ${context.state.state}`;
      } else if (context.state.city) {
        locationName = `${context.state.city} - ${context.state.state}`;
      } else {
        locationName = context.state.state;
      }
      await context.commit("SET_LOCATION_NAME", locationName);
      if (docs.Data) {
        const data = await geoUtils.CalculateDistance(docs.Data, "GmLatitude", "GmLongitude");
        await context.commit("SET_MATCHES", data);
      } else {
        await context.commit("SET_LOCATION_NAME", "");
        return Promise.reject(new Error("nenhum resultado encontrato"));
      }
    },
    async fetchMatchesByProximity(context, type) {
      if (type !== "C" && type !== "R") {
        throw new Error("Wrong value for type param at fetchRegionsByProximity");
      }
      const docs = await api.BuscarPorProximidade({
        vinculo: context.rootState.saude.vinculo,
        rede: context.state.rede,
        tipoAtendimento: context.state.tipoAtendimento,
        codigoEspecialidade: context.state.codigoEspecialidade,
        subEspecialidade: context.state.codigoSubEspecialidade,
        inativos: context.state.deactivated,
        buscaProximidadeTipo: type
      });

      if (docs.Data) {
        await context.commit("SET_LOCATION_NAME", docs.Data.EnderecoReferencia);
        const data = docs.Data.PrestadorProximidade.map(({ Prestador, Distancia, Favorito }) => ({
          ...Prestador,
          Distancia,
          Favorito
        }));
        await context.commit("SET_MATCHES", data);
      } else {
        await context.commit("SET_LOCATION_NAME", "");
        return Promise.reject(new Error(docs.message ? docs.message : "Nenhum resultado encontrato"));
      }
    },
    async fetchMatchesByText(context, nome) {
      const docs = await api.BuscarPorNome({
        vinculo: context.rootState.saude.vinculo,
        nome,
        inativos: context.state.deactivated
      });
      if (docs.Data) {
        const data = await geoUtils.CalculateDistance(docs.Data, "GmLatitude", "GmLongitude");
        await context.commit("SET_MATCHES", data);
        return Promise.resolve(docs.Data.length);
      } else {
        return Promise.reject(new Error("nenhum resultado encontrato"));
      }
    },
    async fetchMatchesNear(context) {
      const coords = await geoUtils.GetLocation().catch(() => null);
      if (!coords) {
        return Promise.reject(new Error("nenhum resultado encontrato"));
      }
      const docs = await api.BuscarPelaProximidadeAtual({
        vinculo: context.rootState.saude.vinculo,
        rede: context.state.rede,
        tipoAtendimento: context.state.tipoAtendimento,
        codigoEspecialidade: context.state.codigoEspecialidade,
        subEspecialidade: context.state.codigoSubEspecialidade,
        latitude: coords.latitude,
        longitude: coords.longitude,
        inativos: false
      });

      if (docs.Data) {
        await context.commit("SET_LOCATION_NAME", docs.Data.EnderecoReferencia);
        const data = docs.Data.PrestadorProximidade.map(({ Prestador, Distancia, Favorito }) => ({
          ...Prestador,
          Distancia,
          Favorito
        }));
        await context.commit("SET_MATCHES", data);
      } else {
        await context.commit("SET_LOCATION_NAME", "");
        return Promise.reject(new Error("nenhum resultado encontrato"));
      }
    },
    async fetchPlansAttended(context) {
      const docs = await api.ObterPlanosAtendidos({
        vinculo: context.rootState.saude.vinculo,
        rede: context.state.rede,
        codigoPrestador: context.state.match.CodigoPrestador
      });

      return docs && docs.Data ? docs.Data : [];
    },
    async hasAllowedAffiliateIndication(context) {
      if (!context.rootState.saude.vinculo) {
        return false;
      }
      if (context.state.allowAffiliateIndication) {
        return true;
      }
      const allowAffiliateIndication = await context.dispatch(
        "myAccount/fetchAccessResource",
        { funcionalidade: 1, permissao: 3 },
        { root: true }
      );
      await context.commit("SET_ALLOW_AFFILIATE_INDICATION", allowAffiliateIndication);
      return allowAffiliateIndication;
    },
    async reset(context) {
      context.commit("RESET");
      if (context.state.groups.length === 0) {
        await context.dispatch("fetchGroups");
      }
      context.dispatch("hasAllowedAffiliateIndication");
      context.dispatch("fetchLocationFilter");
    },
    async fetchStates(context) {
      let states = [];
      context.state.states.forEach(element => states.push({ value: element, label: element }));
      return states;
    },
    async fetchCities(context) {
      try {
        const cs = Object.keys(context.state.regions[context.state.state]).sort();
        let cities = [];
        cs.forEach(element => cities.push({ value: element, label: element }));
        return cities;
      } catch {
        return [];
      }
    },
    async fetchNeighborhoods(context) {
      try {
        const ns = context.state.regions[context.state.state][context.state.city].sort();
        let cache = {};
        // retira Ids duplicados
        const rns = ns.filter(e => {
          if (cache[e]) {
            return false;
          }
          cache[e] = true;
          return true;
        });
        let neighborhoods = [];
        rns.forEach(element => neighborhoods.push({ value: element, label: element }));
        return neighborhoods;
      } catch {
        return [];
      }
    },
    async fetchRecommendationRequests(context) {
      const response = await api.PedidosIndicacaoRede({
        vinculo: context.rootState.saude.vinculo
      });
      return !response?.Data || response instanceof Error ? [] : response.Data;
    },
    async fetchRecommendationDetail(context, { ticket, force }) {
      if (!force && `${ticket}` === `${context.state.recommendationDetail.Ticket}`) {
        return context.state.recommendationDetail;
      }
      const coords = await geoUtils.GetLocation().catch(() => null);
      const response = await api.ResultadoIndicacaoRede({
        vinculo: context.rootState.saude.vinculo,
        chamado: ticket,
        latitude: coords === null ? null : coords.latitude,
        longitude: coords === null ? null : coords.longitude
      });
      const recommendationDetail = !response?.Data || response instanceof Error ? {} : response.Data;
      if (recommendationDetail.SeuPedido?.Protocolo) {
        recommendationDetail.Ticket = ticket;
      }
      await context.commit("SET_LAST_RECOMMENDATION_DETAIL", recommendationDetail);
      return recommendationDetail;
    }
  },
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    SET_LAST_RECOMMENDATION_DETAIL: (state, recommendationDetail) => {
      Vue.set(state, "recommendationDetail", recommendationDetail);
    },
    SET_GROUPS: (state, nextGroups) => {
      Vue.set(state, "groups", nextGroups);
    },
    SET_FILTERS: (state, filters) => {
      Vue.set(state, "filters", filters);
    },
    SET_SORT: (state, sort) => {
      Vue.set(state, "sort", sort);
    },
    SET_SPECIALITIES: (state, nextSpecs) => {
      Vue.set(state, "specialities", nextSpecs);
    },
    SET_SUB_SPECIALITIES: (state, nextSpecs) => {
      Vue.set(state, "subSpecialities", nextSpecs);
    },
    SET_LOCATIONS: (state, nextLocs) => {
      Vue.set(state, "locations", nextLocs);
    },
    SET_REDE: (state, nextRede) => {
      Vue.set(state, "rede", nextRede);
    },
    SET_TIPO_ATENDIMENTO: (state, nextTipo) => {
      Vue.set(state, "tipoAtendimento", nextTipo);
    },
    SET_GROUP: (state, nextGroup) => {
      Vue.set(state, "group", nextGroup);
    },
    SET_SPECIALITY: (state, { cod, label }) => {
      Vue.set(state, "codigoEspecialidade", cod);
      Vue.set(state, "descricaoEspecialidade", label);
    },
    SET_SUB_SPECIALITY: (state, { cod, label }) => {
      Vue.set(state, "codigoSubEspecialidade", cod);
      Vue.set(state, "descricaoSubEspecialidade", label);
    },
    SET_REGIONS: (state, nextRegions) => {
      Vue.set(state, "regions", nextRegions);
    },
    SET_STATE: (state, nextState) => {
      Vue.set(state, "state", nextState);
    },
    SET_STATES: (state, states) => {
      Vue.set(state, "states", states);
    },
    SET_CITY: (state, nextCity) => {
      Vue.set(state, "city", nextCity);
    },
    SET_NEIGHBORHOOD: (state, nextNeighbor) => {
      Vue.set(state, "neighborhood", nextNeighbor);
    },
    SET_MATCHES: (state, nextMatches) => {
      Vue.set(state, "matches", nextMatches);
    },
    SET_DEACTIVATED_MATCHES: (state, nextMatches) => {
      Vue.set(state, "deactivatedMatches", nextMatches);
    },
    SET_LOCATION_TYPE: (state, nextType) => {
      Vue.set(state, "locationType", nextType);
    },
    SET_LOCATION_NAME: (state, nextLocationName) => {
      Vue.set(state, "locationName", nextLocationName);
    },
    SET_DOCINFO: (state, docInfo) => {
      Vue.set(state, "docInfo", docInfo);
    },
    SET_MATCH: (state, nextMatch) => {
      Vue.set(state, "match", nextMatch);
    },
    SET_LAST_VIEWS: (state, latestViews) => {
      Vue.set(state, "latestViews", latestViews);
    },
    SET_FAVORITES: (state, favorites) => {
      Vue.set(state, "favorites", favorites);
    },
    SET_LOCATION_FILTER: (state, locationFilter) => {
      Vue.set(state, "locationFilter", locationFilter);
    },
    SET_DEACTIVATED: (state, deactivated) => {
      Vue.set(state, "deactivated", deactivated);
      Vue.set(state, "title", deactivated ? "Prestadores inativos" : "Busca credenciada");
    },
    SET_ALLOW_AFFILIATE_INDICATION: (state, allowAffiliateIndication) => {
      Vue.set(state, "allowAffiliateIndication", allowAffiliateIndication);
    },
    SET_REDES: (state, redes) => {
      Vue.set(state, "redes", redes);
    },
    SET_PLANOS: (state, planos) => {
      Vue.set(state, "planos", planos);
    },
    SET_PLANO: (state, plano) => {
      Vue.set(state, "plano", plano);
    },
    RESET: state => {
      Vue.set(state, "specialities", []);
      Vue.set(state, "subSpecialities", []);
      Vue.set(state, "locations", []);
      Vue.set(state, "regions", {});
      Vue.set(state, "states", []);
      Vue.set(state, "matches", []);
      Vue.set(state, "rede", "");
      Vue.set(state, "tipoAtendimento", "");
      Vue.set(state, "group", "");
      Vue.set(state, "codigoEspecialidade", "");
      Vue.set(state, "descricaoEspecialidade", "");
      Vue.set(state, "codigoSubEspecialidade", "");
      Vue.set(state, "descricaoSubEspecialidade", "");
      Vue.set(state, "state", "");
      Vue.set(state, "city", "");
      Vue.set(state, "neighborhood", "");
      Vue.set(state, "locationType", "");
      Vue.set(state, "locationName", "");
      Vue.set(state, "docInfo", {});
      Vue.set(state, "match", {});
      Vue.set(state, "deactivatedMatches", null);
      Vue.set(state, "filters", {});
      Vue.set(state, "sort", "");
    }
  }
};
