<template>
  <div />
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "Logoff",
  async created() {
    await this.setUserAuthenticationState(false);
    await this.disableBiometric();
    await this.logoutUser();
    await this.cleanStorage();
    this.closeSwal();
    this.$router.replace("/login");
  },
  methods: {
    ...mapActions(["logoutUser"]),
    ...mapActions("biometrics", ["disableBiometric", "setUserAuthenticationState", "cleanStorage"]),
    closeSwal() {
      try {
        let modais = 0;
        const limit = 10;
        let hasSwalAlert = true;
        while (hasSwalAlert && modais <= limit) {
          const swalModalCloseButton = document.querySelector(".swal2-close");
          if (swalModalCloseButton) {
            modais++;
            swalModalCloseButton.click();
            continue;
          }
          const swalModalOpen = document.querySelector(".swal2-container");
          if (swalModalOpen) {
            modais++;
            swalModalOpen.click();
            continue;
          }
          hasSwalAlert = false;
        }
      } catch (error) {
        console.error("Não foi possível fechar o modal", error);
      }
    }
  }
};
</script>
