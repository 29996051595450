<template>
  <CFlex justify="space-between" width="100%" align-items="center" pr="12px">
    <OmtTypography as="h2-bold">
      {{ title }}
    </OmtTypography>
    <OmtTypography as="p2" ml="auto" color="var(--colors-light-primary-gray2)">
      {{ label }}
    </OmtTypography>
  </CFlex>
</template>

<script>
/**
 * @prop { String } title
 * @prop { String } label
 */
export default {
  props: {
    title: {
      type: String
    },
    label: {
      type: String
    }
  }
};
</script>
