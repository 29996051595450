<template>
  <omint-page>
    <omint-slider>
      <router-view />
    </omint-slider>
  </omint-page>
</template>

<script>
export default {
  name: "AffiliateIndication"
};
</script>
