import { render, staticRenderFns } from "./AccountRemovalModal.vue?vue&type=template&id=54175eeb&scoped=true&"
import script from "./AccountRemovalModal.vue?vue&type=script&lang=js&"
export * from "./AccountRemovalModal.vue?vue&type=script&lang=js&"
import style0 from "./AccountRemovalModal.vue?vue&type=style&index=0&id=54175eeb&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54175eeb",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalBody: require('@chakra-ui/vue').CModalBody, CModalFooter: require('@chakra-ui/vue').CModalFooter, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
