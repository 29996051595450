<template>
  <OmtStickedModal @close="close">
    <template #header>
      <OmtButton :display="['block', 'none']" class="clear" variant="ghost" @click="clear" height="18px" p="0">
        <OmtTypography as="p1" h="18px" w="46px" position="relative"> Limpar </OmtTypography>
      </OmtButton>
    </template>

    <CBox :minHeight="['initial', 'calc(100vh - 400px)']">
      <CFlex justifyContent="space-between" :mb="['32px', '36px']">
        <OmtTypography as="h1-bold"> Ordenar </OmtTypography>
        <OmtButton class="clear" variant="ghost" @click="clear" height="18px" p="0" :display="['none', 'block']">
          <OmtTypography as="p1" h="18px" w="46px" position="relative"> Limpar </OmtTypography>
        </OmtButton>
      </CFlex>

      <CFlex justify="space-between" cursor="pointer">
        <OmtRadio
          @click="internalSort = $event"
          value="Indicação Omint"
          :isChecked="internalSort === 'Indicação Omint'"
          name="sort"
        >
          <OmtTypography as="p1"> Indicação Omint </OmtTypography>
        </OmtRadio>
      </CFlex>
      <OmtDivider />
      <CFlex justify="space-between" cursor="pointer">
        <OmtRadio
          @click="internalSort = $event"
          value="Distância"
          :isChecked="internalSort === 'Distância'"
          name="sort"
        >
          <OmtTypography as="p1"> Distância </OmtTypography>
        </OmtRadio>
      </CFlex>
      <OmtDivider />
      <CFlex justify="space-between" cursor="pointer">
        <OmtRadio
          @click="internalSort = $event"
          value="Rede e Distância"
          :isChecked="internalSort === 'Rede e Distância'"
          name="sort"
        >
          <OmtTypography as="p1"> Rede e Distância </OmtTypography>
        </OmtRadio>
      </CFlex>
    </CBox>

    <template #footer>
      <OmtButton w="100%" @click="save"> Aplicar </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
export default {
  props: ["sort"],
  data() {
    return {
      internalSort: ""
    };
  },
  mounted() {
    this.internalSort = this.sort;
  },
  methods: {
    close() {
      this.$emit("close");
      this.clear();
    },
    clear() {
      this.internalSort = "";
    },
    save() {
      this.$emit("save", this.internalSort);
      this.close();
    }
  }
};
</script>
