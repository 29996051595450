import * as axios from "axios";

export interface IAxiosRequest {
  url: string;
  headers?: any;
  query?: any;
  data?: any;
  contentType?: string;
  responseType?: axios.ResponseType;
  defaultExceptionMessage?: string;
}

export interface IAxios {
  setAuthToken(authToken: string): void;
  clearAuthToken(): void;
  get(request: IAxiosRequest): Promise<any>;
  post(request: IAxiosRequest): Promise<any>;
  isAxiosOnLoading(): boolean;
  hasAuthToken(): boolean;
}

const JSON_TYPE: string = "application/json; charset=utf-8";
const FORM_URL_TYPE: string = "application/x-www-form-urlencoded";
const DEFAULT_TIMEOUT: number = 5 * 60 * 1000; // 5 minutos

export class APIError extends Error {
  code: number | null;
  display: boolean | null;
}

export default class AxiosProxy implements IAxios {
  authToken: string | undefined = undefined;
  awaitingRequests: number = 0;

  setAuthToken(authToken: string): void {
    this.authToken = authToken;
  }

  clearAuthToken(): void {
    this.authToken = undefined;
  }

  validateParams(params: IAxiosRequest): IAxiosRequest {
    const stringifyParams = (params: any): string => {
      Object.keys(params).forEach(p => {
        switch (params[p]) {
          case null:
            params[p] = "";
            break;
          case undefined:
            params[p] = "";
            break;
        }
      });
      return new URLSearchParams(params).toString();
    };
    let url = params.url;
    if (params.query) {
      const qs = stringifyParams(params.query);
      if (window.$log.isLogEnabled) window.$log.message(`[url: ${url}], [querystring: ${qs}]`);
      url = `${url}?${qs}`;
    }
    let data = params.data;
    if (data && params.contentType === FORM_URL_TYPE) {
      data = stringifyParams(data);
      if (window.$log.isLogEnabled) window.$log.message(`[url: ${url}], [data: ${data}]`);
    }

    let contentType = params.contentType;
    if (!contentType && data) {
      contentType = JSON_TYPE;
    }

    const headers = params.headers ?? { "Content-Type": contentType };
    if (this.authToken) {
      headers["Authorization"] = `Bearer ${this.authToken}`;
    }
    return {
      url,
      data,
      contentType,
      headers
    };
  }

  async sendMessage(method: axios.Method, params: IAxiosRequest): Promise<any> {
    this.awaitingRequests++;
    if (window.bus) {
      window.bus.$emit("axios-status", this.awaitingRequests > 0);
    }

    const { url, headers, data } = this.validateParams(params);

    return await axios.default
      .request({
        url,
        headers,
        method,
        data,
        responseType: params.responseType,
        timeout: DEFAULT_TIMEOUT
      })
      .then(resp => resp)
      .catch(error => {
        const errorMessage = params.defaultExceptionMessage ?? "Serviço temporariamente indisponível";
        const apiError = new APIError(errorMessage);
        if (error.response) {
          const errorObject = error.response ?? {};
          apiError.code = errorObject.status;
          if (errorObject.status === 400 || errorObject.status === 500) {
            const errorData: any = errorObject.data ?? {};
            if (errorData?.Error) {
              apiError.display = errorObject.status === 400;
              apiError.message = errorData.Error;
            } else if (errorData?.error_description) {
              apiError.display = errorData.error === "User";
              apiError.message = errorData.error_description;
            }
          }
        }
        throw apiError;
      })
      .finally(() => {
        this.awaitingRequests--;
        if (window.bus) {
          window.bus.$emit("axios-status", this.awaitingRequests > 0);
        }
      });
  }

  async get(params: IAxiosRequest): Promise<any> {
    const method: axios.Method = "GET";
    return await this.sendMessage(method, params);
  }

  async post(params: IAxiosRequest): Promise<any> {
    const method: axios.Method = "POST";
    return await this.sendMessage(method, params);
  }

  isAxiosOnLoading(): boolean {
    return this.awaitingRequests > 0;
  }

  hasAuthToken(): boolean {
    return this.authToken !== undefined;
  }
}
