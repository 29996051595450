import Vue from "vue";
import { app, bus } from "../main";
import store from "../store";
import {
  Cadastrar,
  VerificarCadastro,
  Autenticar,
  GerarQRCode,
  VerificarQRCode,
  RegistrarErro
} from "../api/biometria";

const getInitialState = () => {
  const initialState = {
    hasBiometric: false,
    shoudUseBiometric: false,
    isUserAuthenticated: false,
    listenersRegistred: false,
    nativeShareEnabled: false,
    storage: "",
    camera: "",
    microfone: "",
    location: ""
  };
  const rawShouldUseBiometric = localStorage.getItem("biometrics/shouldUseBiometric");
  if (rawShouldUseBiometric === "yes") {
    initialState.shoudUseBiometric = true;
  }
  return initialState;
};

const OnUserAuth = globalStore => message => {
  const { auth } = JSON.parse(message);
  if (auth) {
    globalStore.dispatch("biometrics/setAuthenticated");
  }
};

const OnEnableBiometric = globalStore => message => {
  const { status } = JSON.parse(message);
  globalStore.dispatch("biometrics/setBiometricEnabled", status);
};

const OnIsAvailable = globalStore => message => {
  const { available } = JSON.parse(message);
  globalStore.dispatch("biometrics/setBiometric", available);
};

const onNativeShareIsAvailable = globalStore => message => {
  const { available } = JSON.parse(message);
  globalStore.dispatch("biometrics/setNativeShare", available);
};

const onResourceStatusChange = globalStore => message => {
  const { storageState, cameraState, microfoneState, locationState } = JSON.parse(message);
  if (storageState) {
    globalStore.dispatch("biometrics/setStorageState", storageState);
  }
  if (cameraState) {
    globalStore.dispatch("biometrics/setCameraState", cameraState);
  }
  if (microfoneState) {
    globalStore.dispatch("biometrics/setMicrofoneState", microfoneState);
  }
  if (locationState) {
    globalStore.dispatch("biometrics/setLocationState", locationState);
  }
};

// vuex module
export default {
  namespaced: true,
  state: getInitialState,
  getters: {
    onNative: () => {
      if (process.env.VUE_APP_FORCE_NATIVE === "true") return true;
      return !!app?.$native || false;
    }
  },
  actions: {
    registerListeners(context) {
      if (!context.state.listenersRegistred && app.$native) {
        bus.$on("biometrics:isAvailable", OnIsAvailable(store));
        bus.$on("biometrics:enable", OnEnableBiometric(store));
        bus.$on("biometrics:auth", OnUserAuth(store));
        bus.$on("native_share:isAvailable", onNativeShareIsAvailable(store));
        bus.$on("resource_status", onResourceStatusChange(store));
        context.commit("SET_LISTENERS_REGISTRED", true);
      }
    },
    cleanStorage() {
      if (!app.$native) return;
      app.$native.postMessage(JSON.stringify({ type: "clean_storage" }));
    },
    authenticateUser(context) {
      if (!app.$native) return;
      context.commit("SET_IS_USER_AUTHENTICATED", false);
      app.$native.postMessage(JSON.stringify({ type: "biometrics:auth" }));
    },
    enableBiometric() {
      if (!app.$native) return;
      app.$native.postMessage(JSON.stringify({ type: "biometrics:enable" }));
    },
    checkForBiometric(context) {
      if (!app.$native) return;
      if (!context.state.hasBiometric) {
        app.$native.postMessage(JSON.stringify({ type: "biometrics:isAvailable" }));
      }
    },
    checkForNativeShare(_) {
      if (!app.$native) return;
      app.$native.postMessage(JSON.stringify({ type: "native_share:isAvailable" }));
    },
    setAuthenticated(context) {
      if (!app.$native) return;
      context.commit("SET_IS_USER_AUTHENTICATED", true);
    },
    setBiometric(context, hasBiometric) {
      if (!app.$native) return;
      context.commit("SET_HAS_BIOMETRIC", hasBiometric);
    },
    setBiometricEnabled(context, status) {
      if (!app.$native) return;
      context.commit("SET_SHOULD_USE_BIOMETRIC", status);
      context.commit("SET_IS_USER_AUTHENTICATED", status);
    },
    disableBiometric(context) {
      if (!app.$native) return;
      context.commit("SET_SHOULD_USE_BIOMETRIC", false);
      context.commit("SET_IS_USER_AUTHENTICATED", false);
    },
    shareImage(context, { title, data }) {
      if (!app.$native) return false;
      if (context.state.nativeShareEnabled) {
        app.$native.postMessage(JSON.stringify({ type: "share_image_binary", payload: { title, data } }));
      }
      return context.state.nativeShareEnabledue;
    },
    setNativeShare(context, status) {
      if (!app.$native) return;
      context.commit("SET_HAS_NATIVE_SHARE", status);
    },
    setUserAuthenticationState(_, authenticated) {
      if (!this.$native) return;
      this.$native.postMessage(
        JSON.stringify({
          type: authenticated ? "authenticated:true" : "authenticated:false"
        })
      );
    },
    requestPermissionForResource(_, resource) {
      if (!app.$native) return false;
      app.$native.postMessage(JSON.stringify({ type: "request_permission", payload: { resource } }));
      return true;
    },
    openAppSettings() {
      if (!app.$native) return false;
      app.$native.postMessage(JSON.stringify({ type: "open_app_settings", payload: {} }));
      return true;
    },
    checkPermissionForResource(_, resource) {
      if (!app.$native) return false;
      app.$native.postMessage(JSON.stringify({ type: "check_permission", payload: { resource } }));
      return true;
    },
    setStorageState(context, state) {
      context.commit("SET_STORAGE_STATE", state);
    },
    setMicrofoneState(context, state) {
      context.commit("SET_MICROFONE_STATE", state);
    },
    setCameraState(context, state) {
      context.commit("SET_CAMERA_STATE", state);
    },
    setLocationState(context, state) {
      context.commit("SET_LOCATION_STATE", state);
    },
    async authFace(context, { service, selfie }) {
      if (service && !service.startsWith("/")) {
        try {
          service = context.rootGetters[service];
        } catch (error) {
          window.$log.message(`using service ${service}`);
        }
      }
      const response = await Autenticar({
        vinculo: context.rootState.saude.vinculo,
        servico: service,
        selfie
      });
      if (response instanceof Error) throw new Error(response.message ?? "Não foi possível realizar a autenticação");
      return response.Data;
    },
    async saveFace(context, { selfie }) {
      const response = await Cadastrar({
        vinculo: context.rootState.saude.vinculo,
        selfie
      });
      if (response instanceof Error)
        throw new Error(response.message ?? "Não foi possível realizar o cadastro da biometria");
      return response.Data;
    },
    async fetchFaceStatus(context, _) {
      const response = await VerificarCadastro({
        vinculo: context.rootState.saude.vinculo
      });
      if (response instanceof Error)
        throw new Error(response.message ?? "Não foi possível verificar a situação do cadastro");
      return response.Data;
    },
    async registerError(context, { cod, dsc }) {
      const response = await RegistrarErro({
        vinculo: context.rootState.saude.vinculo,
        codigo: cod,
        descricao: dsc
      });
      return response?.Data ?? {};
    },
    async createAuthQRCode(context, { service, phone }) {
      const response = await GerarQRCode({
        vinculo: context.rootState.saude.vinculo,
        servico: service,
        telefone: phone
      });
      if (response instanceof Error) throw new Error(response.message ?? "Não foi possível gerar o QRCode");
      return response.Data;
    },
    async fetchQRCodeStatus(context, { service, token, id }) {
      const response = await VerificarQRCode({
        vinculo: context.rootState.saude.vinculo,
        servico: service,
        token,
        id
      });
      if (response instanceof Error)
        throw new Error(response.message ?? "Não foi possível verificar a situação do QRCode");
      return response.Data;
    }
  },
  mutations: {
    LOGOUT_USER: state => state,
    RESET_CONTEXT: state => state,
    SET_HAS_BIOMETRIC: (state, value) => {
      Vue.set(state, "hasBiometric", value);
    },
    SET_SHOULD_USE_BIOMETRIC: (state, value) => {
      localStorage.setItem("biometrics/shouldUseBiometric", value ? "yes" : "no");
      Vue.set(state, "shoudUseBiometric", value);
    },
    SET_IS_USER_AUTHENTICATED: (state, value) => {
      localStorage.setItem("biometrics/isUserAuthenticated", value ? "yes" : "no");
      Vue.set(state, "isUserAuthenticated", value);
    },
    SET_LISTENERS_REGISTRED: (state, value) => {
      Vue.set(state, "listenersRegistred", value);
    },
    SET_HAS_NATIVE_SHARE: (state, value) => {
      Vue.set(state, "nativeShareEnabled", value);
    },
    SET_STORAGE_STATE: (state, value) => {
      Vue.set(state, "storage", value);
    },
    SET_MICROFONE_STATE: (state, value) => {
      Vue.set(state, "microfone", value);
    },
    SET_CAMERA_STATE: (state, value) => {
      Vue.set(state, "camera", value);
    },
    SET_LOCATION_STATE: (state, value) => {
      Vue.set(state, "location", value);
    }
  }
};
