<template>
  <OmtStickedModal v-if="isOpen" @close="handleClose">
    <CBox>
      <OmtTypography as="h1-bold" mb="24px"> Detalhes do cancelamento </OmtTypography>

      <CDivider mt="12px" mb="16px" />

      <CFlex mb="16px">
        <OmtTypography as="h3-semi-bold" mr="8px"> Observações </OmtTypography>
        <OmtTypography as="p1"> (opcional) </OmtTypography>
      </CFlex>

      <CFlex flexDirection="column" alignItems="flex-end">
        <OmtTextarea
          height="160px"
          padding="12px"
          placeholder="Inclua informações ou detalhes sobre os motivos do cancelamento."
          @change="obs = $event"
          :initialValue="obs"
        />
        <OmtTypography as="p2"> {{ obs.length }}/350 </OmtTypography>
      </CFlex>
    </CBox>

    <template #footer>
      <OmtButton w="100%" @click="submit"> Enviar </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
export default {
  props: {
    isOpen: {
      Type: Boolean
    }
  },
  data() {
    return {
      obs: ""
    };
  },
  methods: {
    submit() {
      this.$emit("submit", {
        obs: this.obs
      });
    },
    handleClose() {
      this.obs = "";
      this.$emit("update:isOpen", false);
    }
  }
};
</script>
