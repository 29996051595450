import Vue from "vue";
import {
  ListarAcessosFuncionalidades,
  AtualizarAcessoPermissao,
  AtualizarAcessoFuncionalidades,
  AceitaOptin,
  VerificarAvisoInadimplencia,
  RegistarCienteAvisoInadimplencia
} from "@/api/associado";
import { allowUpdate, checkUpdatePermission } from "@/utils/checkReadonlyUtil";

const initialState = {
  permissions: [],
  appLocked: false,
  nonpaymentWarn: {}
};
export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions: {
    async lockApp(context) {
      context.commit("SET_LOCKED", true);
    },
    async unlockApp(context) {
      context.commit("SET_LOCKED", false);
    },
    async fetchPermissions(context) {
      const permissions = await ListarAcessosFuncionalidades({
        vinculo: context.rootState.saude.vinculo
      });
      await context.commit(
        "SET_PERMISSIONS",
        permissions && permissions.length > 0 ? permissions.map(p => p.Permissao) : []
      );
    },
    async updateAccessPermitions(context, { permissions }) {
      checkUpdatePermission();
      let ocorreuAlteracao = false;
      const permissoesAlteradas = [];
      for (let p = 0; p < permissions.length; p++) {
        const permissao = permissions[p];
        if ((permissao.ativa && permissao.id === 0) || (!permissao.ativa && permissao.id > 0)) {
          const newId = await AtualizarAcessoPermissao({
            vinculoDestinatario: permissao.vinculo,
            ativar: permissao.ativa,
            idPermissao: permissao.id,
            vinculo: context.rootState.saude.vinculo
          });
          if (permissao.ativa) {
            permissao.id = newId;
          } else {
            permissao.id = 0;
          }
          ocorreuAlteracao = true;
        }
        const permissoesAlteradasVinculo = [];
        permissao.funcionalidades.forEach(f =>
          f.permissoes.forEach(p => {
            if (p.ativa !== p.original) {
              permissoesAlteradasVinculo.push({
                IdFuncionalidade: p.id,
                Ativa: p.ativa
              });
            }
          })
        );
        if (permissoesAlteradasVinculo.length > 0) {
          permissoesAlteradas.push({
            IdPermissao: permissao.id,
            ListaFuncionalidades: permissoesAlteradasVinculo
          });
        }
      }
      let protocolo = "";
      if (permissoesAlteradas.length > 0) {
        const response = await AtualizarAcessoFuncionalidades({
          vinculo: context.rootState.saude.vinculo,
          permissoes: permissoesAlteradas
        });
        if (response && response.protocoloANS) {
          protocolo = response.protocoloANS;
        }
      }
      if (ocorreuAlteracao) {
        await context.dispatch("fetchPermissions");
      }
      return protocolo;
    },
    async optinAccept(context) {
      checkUpdatePermission();
      await AceitaOptin({
        vinculo: context.rootState.saude.vinculo,
        template: 13,
        login: context.rootState.saude.username
      });
    },
    async fetchNonpaymentWarning(context) {
      const response = await VerificarAvisoInadimplencia({
        vinculo: context.rootState.saude.vinculo,
        template: 13,
        login: context.rootState.saude.username
      });
      if (response.Data?.Texto) {
        context.commit("SET_NOPAYMENT_WARNING", {
          title: response.Data.Titulo,
          text: response.Data.Texto,
          key: response.Data.Chave
        });
      } else {
        context.commit("SET_NOPAYMENT_WARNING", {});
      }
    },
    async nonpaymentAware(context) {
      if (!context.state.nonpaymentWarning?.key) return;
      if (!allowUpdate()) {
        context.commit("SET_NOPAYMENT_WARNING", {});
        return;
      }
      await RegistarCienteAvisoInadimplencia({
        vinculo: context.rootState.saude.vinculo,
        chave: context.state.nonpaymentWarning.key
      });
      context.commit("SET_NOPAYMENT_WARNING", {});
    }
  },
  mutations: {
    SET_PERMISSIONS: (state, permissions) => {
      Vue.set(state, "permissions", permissions);
    },
    SET_LOCKED: (state, locked) => {
      Vue.set(state, "appLocked", locked);
    },
    SET_NOPAYMENT_WARNING: (state, nonpaymentWarning) => {
      Vue.set(state, "nonpaymentWarning", nonpaymentWarning);
    },
    LOGOUT_USER: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p]))
  }
};
