<template>
  <div id="page-content" :class="myClass">
    <InfoBox v-if="message" :message="message" />
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from "vuex";
import InfoBox from "@/components/Molecules/InfoBox";

export default {
  components: { InfoBox },
  props: {
    message: {
      type: String,
      default: null
    },
    disableMinHeight: {
      type: Boolean,
      default: false
    },
    disableFontSizeCheck: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("saude", ["theme", "bigFontSize"]),
    myClass() {
      return `content
      ${this.bigFontSize && !this.disableFontSizeCheck ? " big-font-size" : ""}
      ${this.disableMinHeight ? " content-no-min-height" : ""}`;
    }
  },
  beforeDestroy() {
    this.$root.$emit("hide-loading");
  }
};
</script>

<style>
.color-facebook {
  color: #3b5998 !important;
}

.color-linkedin {
  color: #0077b5 !important;
}

.color-mail {
  color: #3498db !important;
}

.color-twitter {
  color: #4099ff !important;
}

.color-whatsapp {
  color: #34af23 !important;
}

.content {
  padding: 0;
  min-height: calc(100vh - 146px);
  max-width: 970px !important;
  font-size: 13px;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 0px;
  padding: 24px 16px 0 16px;
  position: relative;
}

.content.big-font-size {
  font-size: 19px;
}

.content-no-min-height {
  min-height: auto;
}

.link-list {
  display: block;
  position: relative;
  margin: 0px 15px 0px 15px;
  min-width: 150px;
}

.link-list a {
  font-weight: 500;
  display: block;
  color: var(--colors-light-primary-gray1);
  line-height: 50px;
  margin-left: 40px;
  font-size: 13px;
}

.link-list a i:first-child {
  line-height: 51px;
  width: 30px;
  text-align: center;
  position: absolute;
  left: -5px;
  font-size: 18px;
}

.link-list a i:last-child {
  line-height: 50px;
  width: 20px;
  font-size: 10px;
  text-align: center;
  position: absolute;
  right: 0px;
  color: var(--colors-light-primary-gray1);
}
</style>
