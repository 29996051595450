<template>
  <omint-page-content>
    <omt-p>Cadastre mais um e-mail com a Omint</omt-p>

    <omt-input type="text" placeholder="Informe aqui seu e-mail" v-bind:value="newMail" v-on:input="newMail = $event" />

    <omt-divider :onlyClear="true" />
    <br />
    <omt-buttons-bar>
      <omt-button :onClick="handleClick" :disabled="newMail.indexOf('@') === -1" :bigSize="true"> Próximo </omt-button>
    </omt-buttons-bar>
    <br />
  </omint-page-content>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "MailForm",
  mounted() {
    this.$root.$emit("change-title-label", "Cadastro de Email");
  },
  data: function () {
    return {
      newMail: ""
    };
  },
  methods: {
    ...mapActions("firstAccess", ["fetchVinculos", "addEmail"]),
    async handleClick() {
      await this.$root.$emit("show-loading");
      await this.addEmail({
        email: this.newMail
      });
      await this.fetchVinculos();
      await this.$router.push({ name: "first-access.mailcheck" });
      await this.$root.$emit("hide-loading");
    }
  }
};
</script>

<style scoped></style>
