/* eslint-disable no-console */
import store from "../store";

export interface ILogger {
  isLogEnabled: boolean;
  message(text: any): void;
  error(text: any, infos: any): void;
}

export default class Logger implements ILogger {
  IS_PROD: boolean = process.env.NODE_ENV === "production";
  APPINSIGHTS_DISABLE: boolean = process.env.VUE_APP_APPINSIGHTS_DISABLE === "true";

  formatMessage(text: any): string {
    return `[${new Date().toLocaleString()}] ${this.stringifyIfObect(text)}`;
  }

  stringifyIfObect(text: any): string {
    if (!text) return "";
    return typeof text === "object" ? JSON.stringify(text) : `${text}`;
  }

  message(text: any): void {
    if (!this.IS_PROD || this.APPINSIGHTS_DISABLE) {
      console.log(this.formatMessage(text));
    }
  }

  error(text: any, infos: any): void {
    const message = this.formatMessage(text);
    if (this.IS_PROD && !this.APPINSIGHTS_DISABLE) {
      try {
        if (window.$appInsights) {
          const hydrated = store?.state?.saude?.hydrated ?? false;
          const vinculo = hydrated ? store?.state?.saude?.vinculo ?? "" : "";
          const operacao = hydrated ? store?.state?.saude?.operacao ?? 0 : 0;
          const perfil = hydrated ? store?.state?.saude?.perfil ?? 0 : 0;
          const grupo = hydrated ? store?.state?.saude?.grupo ?? 0 : 0;
          window.$appInsights.trackException({
            exception: infos,
            properties: { hydrated, vinculo, operacao, perfil, grupo, message }
          });
          window.$appInsights.flush();
        }
      } catch (err) {
        console.error(message, infos);
        console.error("AIns error", err);
      }
    } else {
      console.error(message, infos);
    }
  }

  get isLogEnabled(): boolean {
    // use se for preciso  contatenar strings
    // if (isLogEnabled()) log(`Uma contatenação de string{$semTerLogDisponivel} é processamento desnecessário);
    return !this.IS_PROD;
  }
}
