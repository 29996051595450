<template>
  <CGrid :templateColumns="templateColumns">
    <CGridItem v-if="currentStep > 0" :class="[inverseColor ? 'inverse step' : 'step', 'step-active']" />
    <CGridItem v-if="currentStep < totalSteps" :class="inverseColor ? 'inverse step' : 'step'" />
  </CGrid>
</template>

<script>
/**
 * @prop { number } currentStep
 * @prop { number } totalSteps
 * @prop { boolean } inverseColor
 */
export default {
  props: {
    currentStep: Number,
    totalSteps: Number,
    inverseColor: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isCompleted() {
      return this.currentStep >= this.totalSteps;
    },
    isInitial() {
      return this.currentStep <= 0;
    },
    templateColumns() {
      if (this.isCompleted || this.isInitial) return "1fr";

      const completado = Math.round((100 * this.currentStep) / this.totalSteps);
      const falta = 100 - completado;

      return `${completado}fr ${falta}fr`;
    }
  }
};
</script>

<style lang="scss" scoped>
$height: 4px;

@keyframes fill-step {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.step {
  height: $height;
  background: var(--colors-light-secondary-divider);
  position: relative;
}

.step-active::after {
  content: "";
  animation: fill-step 0.5s ease-out forwards;
  position: absolute;
  height: $height;
  left: 0;
  bottom: 0;
  background: var(--colors-light-primary-navy-blue);
}

.inverse .step {
  background: var(--colors-light-primary-navy-blue);
}

.inverse .step-active::after {
  background: var(--colors-light-secondary-divider);
}
</style>
