<template>
  <CBox> </CBox>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      tour: null
    };
  },
  methods: {
    ...mapActions("myAccount", ["checkNextWelcomeOnBoardTour"]),
    ...mapActions("biometrics", ["fetchFaceStatus"]),
    async nextTask() {
      const rota = await this.checkNextWelcomeOnBoardTour();
      this.$router.replace(rota);
    }
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      const bioCad = await this.fetchFaceStatus();
      if (bioCad?.Aceito) this.nextTask();
    } catch (error) {
      this.$toast({
        description: error.message,
        status: "error",
        duration: 10000,
        position: "bottom-right"
      });
    } finally {
      this.$root.$emit("hide-loading");
    }
  }
};
</script>
<style scoped></style>
