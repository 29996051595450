import { Workbox } from "workbox-window";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import type Vue from "vue";

export async function initServiceWorker(app: Vue) {
  if ("serviceWorker" in navigator) {
    const wb = new Workbox(`${process.env.VUE_APP_BASE_URL}service-worker.js`);
    const registration = await wb.register();
    const vapidKey = process.env.VUE_APP_WEBPUSH_KEY || "";
    if (vapidKey) {
      const firebaseConfig = {
        apiKey: "AIzaSyDhygukYlEXwlFYmxb_GQPH7_nHVhCXtgE",
        authDomain: "minhaomint.firebaseapp.com",
        databaseURL: "https://minhaomint.firebaseio.com",
        projectId: "minhaomint",
        storageBucket: "minhaomint.appspot.com",
        messagingSenderId: "1097192347158",
        appId: "1:1097192347158:web:7634dd3f682a50fc798737",
        measurementId: "G-MRWT2BKC0B"
      };

      const firebase = initializeApp(firebaseConfig);
      const messaging = getMessaging(firebase);

      onMessage(messaging, async event => {
        const payload = event?.data ?? {};
        try {
          await app.$emit("web_push", payload);
        } catch (error) {
          if (window.$log) {
            window.$log.error("FB onMessage error:", error);
          }
        }
      });

      const generateToken = async () => {
        const currentToken = await getToken(messaging, {
          vapidKey,
          serviceWorkerRegistration: registration
        });
        if (currentToken) {
          localStorage.setItem(
            "mobile_token",
            JSON.stringify({
              token: currentToken,
              system: "web",
              systemVersion: "N/A"
            })
          );
          return true;
        }
        return false;
      };

      const hasPushToken = await generateToken();
      if (!hasPushToken) {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          await generateToken();
        }
      }

      wb.addEventListener("message", async event => {
        if (!event?.data?.isFirebaseMessaging) {
          const payload = event?.data;
          try {
            await app.$emit("web_push", payload);
          } catch (error) {
            if (window.$log) {
              window.$log.error("Internal message error:", error);
            }
          }
        }
      });
    }
    return wb;
  } else {
    return null;
  }
}
