export default [
  {
    title: "Consultas",
    list: [
      {
        subtitle: "Nota Fiscal ou Recibo com as informações:",
        icon: "file-invoice-dollar",
        sublist: [
          "Nome completo do paciente atendido.",
          "Data e local do atendimento.",
          "Discriminação do serviço realizado (ex.: consulta médica).",
          "Nome completo e CRM do médico.",
          "CPF do médico ou CNPJ da clínica.",
          "Endereço e telefone do local de atendimento."
        ]
      }
    ]
  },
  {
    title: "Exames",
    list: [
      {
        subtitle: "Nota Fiscal ou Recibo com as informações:",
        icon: "file-invoice-dollar",
        sublist: [
          "Nome completo do <b>paciente atendido.</b>",
          "Discriminação dos exames e seus respectivos valores individuais.",
          "CPF e CRM do médico ou CNPJ da clínica ou laboratório que realizou o atendimento.",
          "Data e local do atendimento.",
          "Endereço e telefone do local de atendimento."
        ]
      },
      {
        subtitle: "Pedido médico com as informações:",
        icon: "file-waveform",
        sublist: [
          "Nome completo do <b>paciente atendido.</b>",
          "Descrição do(s) exame(s) solicitado(s).",
          "Nome completo e CRM do médico solicitante.",
          "Endereço, telefone do local de atendimento."
        ]
      }
    ]
  },

  {
    title: "Vacinas",
    list: [
      {
        subtitle: "Nota Fiscal ou Recibo com as informações:",
        icon: "file-invoice-dollar",
        sublist: [
          "Nome completo do <b>paciente atendido.</b>",
          "CPF do médico ou CNPJ da clínica.",
          "Discriminação dos valores individuais de cada vacina.",
          "Data e local do atendimento.",
          "Endereço e telefone do local de atendimento."
        ]
      }
    ]
  },
  {
    title: "Acupuntura, Escleroterapia e Nutrição",
    list: [
      {
        subtitle: "Nota Fiscal ou Recibo com as informações:",
        icon: "file-invoice-dollar",
        sublist: [
          "Nome completo do paciente atendido.",
          "Nome completo do profissional que realizou o atendimento.",
          "CPF do profissional ou CNPJ da clínica.",
          "Número do Conselho Regional de Classe (CRM/CRN).",
          "Data(s) do(s) atendimento(s).",
          "Tipo de terapia realizada (Ex: Acupuntura, Nutrição).",
          "Valor individual de cada sessão.",
          "Endereço, telefone do local de atendimento."
        ]
      },
      {
        subtitle: "Pedido médico do ano vigente com as informações:",
        icon: "file-waveform",
        sublist: [
          "Nome completo do paciente atendido.",
          "Descrição do diagnóstico e código do CID-10.",
          "Quantidade de sessões solicitadas.",
          "Nome completo e CRM do médico solicitante.",
          "Endereço, telefone do local de atendimento."
        ]
      }
    ],
    alert:
      "*Os reembolsos de acupuntura e escleroterapia serão aceitos somente quando os procedimentos forem realizados por <b>profissionais médicos com CRM.</b>"
  },
  {
    title: "Fisioterapia, Fonoaudiologia, RPG, Terapia Ocupacional e Psicoterapia",
    list: [
      {
        subtitle: "Nota Fiscal ou Recibo com as informações:",
        icon: "file-invoice-dollar",
        sublist: [
          "Nome completo do paciente atendido.",
          "Nome completo do profissional que realizou o atendimento.",
          "CPF do profissional ou CNPJ da clínica.",
          "Número do Conselho Regional de Classe (Crefito, CRF, CRN...).",
          "Data(s) do(s) atendimento(s).",
          "Tipo de terapia realizada (Ex: Fisioterapia, Terapia Ocupacional).",
          "Valor individual de cada sessão.",
          "· Endereço, telefone do local de atendimento"
        ]
      },
      {
        subtitle: "Pedido médico do ano vigente com as informações:",
        icon: "file-waveform",
        sublist: [
          "Nome completo do paciente atendido.",
          "Descrição do diagnóstico, código do CID-10.",
          "Quantidade de sessões solicitadas.",
          "Nome completo e CRM do médico solicitante.",
          "Endereço, telefone do local de atendimento."
        ]
      }
    ]
  },
  {
    title: "Internações e Cirurgias",
    mostLists: [
      {
        title: "Honorários com a descrição de toda a equipe",
        list: [
          {
            subtitle: "Nota Fiscal ou Recibo com as informações:",
            icon: "file-invoice-dollar",
            sublist: [
              "Nome completo do paciente atendido.",
              "Data e local do atendimento.",
              "Discriminação do(s) procedimento(s) realizado(s).",
              "Nome completo e CRM do médico.",
              "CPF do médico ou CNPJ da clínica.",
              "Endereço e telefone do local de atendimento."
            ]
          },
          {
            subtitle: "Relatório médico com as informações:",
            icon: "file-waveform",
            sublist: [
              "Nome completo do paciente atendido.",
              "Descrição do diagnóstico e procedimento(s) realizado(s).",
              "Data e período de realização do procedimento.",
              "Nome completo, número do CRM, endereço e telefone do médico solicitante."
            ]
          }
        ]
      },
      {
        title: "Despesas hospitalares",
        list: [
          {
            subtitle: "Nota Fiscal ou Recibo com as informações:",
            icon: "file-invoice-dollar",
            sublist: [
              "Nome completo do paciente atendido.",
              "Discriminação da(s) despesas (Ex.: diária, material, medicamentos...).",
              "CNPJ da clínica/Hospital que prestou o atendimento",
              "Data e local do atendimento.",
              "Endereço e telefone do local de atendimento."
            ]
          }
        ]
      }
    ]
  },
  {
    title: "Odontologia",
    list: [
      {
        subtitle: "Nota Fiscal ou Recibo com as informações:",
        icon: "file-invoice-dollar",
        sublist: [
          "Nome completo do paciente atendido.",
          "Nome completo, número do CRO.",
          "CPF do dentista ou CNPJ da clínica.",
          "Discriminação de valores individuais dos procedimentos.",
          "Data e local do atendimento.",
          "Endereço e telefone do local de atendimento."
        ]
      },
      {
        subtitle: "Informe Odontológico:",
        icon: "memo",
        sublist: []
      }
    ],
    alert:
      "Para acessar o modelo de Informe Odontológico: <ol><li>Abra o <b>menu</b> localizado no canto superior direito do app.</li><li>Clique na aba <b>Documentos de apoio.</b></li><li>No item <b>Documento</b>, acesse e baixe o PDF com o modelo do informe.</li></ol>"
  }
];
