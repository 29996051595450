Number.prototype.zeroLeft = function () {
  if (this < 10) {
    return `0${this}`;
  }
  return this;
};

Date.prototype.formatDDMMYYYY = function () {
  return `${this.getDate().zeroLeft()}/${(this.getMonth() + 1).zeroLeft()}/${this.getFullYear()}`;
};

Number.prototype.toMoney = function () {
  const moeda = "R$ ";
  const decimais = 2;
  const separadorMilhar = ".";
  const separadorDecimal = ",";

  const sinal = this < 0 ? "-" : "";
  const value = Math.abs(this || 0);
  const naoFormatado = parseInt((value * 10 ** decimais).toFixed(0));
  if (naoFormatado === 0) {
    return `${moeda}0${separadorDecimal}00`;
  }
  let formatado = String(naoFormatado);
  formatado = `${formatado.substr(0, formatado.length - 2)}${separadorDecimal}${formatado.substr(
    formatado.length - decimais,
    decimais
  )}`;
  const tamanhoMaximoSemMilhar = 3 + 1 + decimais;
  for (let index = formatado.length - tamanhoMaximoSemMilhar; index > 0; index = index - 3) {
    formatado = `${formatado.substr(0, index)}${separadorMilhar}${formatado.substr(index)}`;
  }
  return `${moeda}${sinal}${formatado}`;
};

export {};
