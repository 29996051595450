<template>
  <OmtGrid>
    <OmtRow mb="24px">
      <OmtIcons name="face-viewfinder" size="3x" color="var(--colors-light-primary-navy-blue)" />
    </OmtRow>

    <OmtRow mb="24px">
      <OmtTypography as="h1-bold"> Validação de biometria </OmtTypography>
    </OmtRow>

    <OmtRow mb="16px">
      <OmtTypography as="h3-semi-bold"> Valide sua biometria e tenha mais segurança. </OmtTypography>
    </OmtRow>

    <OmtRow mb="24px">
      <OmtTypography as="p1">
        Vamos utilizar uma ferramenta de reconhecimento facial para garantir que suas transações fiquem ainda mais
        protegidas.
      </OmtTypography>
    </OmtRow>
  </OmtGrid>
</template>

<script>
export default {};
</script>
