<template>
  <CBox>
    <OmtTypography as="p1" mb="24px"> Qual número de <b>Whatsapp</b> você gostaria de cadastrar? </OmtTypography>
    <OmtCard v-if="adicionar" w="100%" mb="24px">
      <CFlex direction="column" mb="12px">
        <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Tipo do telefone</OmtTypography>
        <OmtSelect placeholder="Selecione o tipo do telefone" @change="e => onTipoSelected(e)" :initialValue="tipo">
          <option value="Celular">Celular</option>
          <option value="Comercial">Comercial</option>
        </OmtSelect>
      </CFlex>
      <CFlex direction="column" mb="12px">
        <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Telefone</OmtTypography>
        <OmtInput
          v-model="numero"
          v-mask="mascara"
          fontSize="14px"
          fontWeight="700"
          class="input-wpp"
          placeholder="Digite o número de telefone"
        />
      </CFlex>
    </OmtCard>

    <OmtCard v-if="selecionar" w="100%" mb="24px">
      <CFlex direction="column" mb="12px">
        <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Telefone</OmtTypography>
        <OmtSelect placeholder="Selecione o telefone" @change="e => onTelefoneSelected(e)" :initialValue="numero">
          <option v-for="(fone, index) in telefones" :key="index" :value="fone.numero">
            {{ fone.numero }}
          </option>
        </OmtSelect>
      </CFlex>
      <CFlex direction="column" mb="12px">
        <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Tipo do telefone</OmtTypography>
        <OmtInput is-disabled v-model="tipo" fontSize="14px" fontWeight="700" class="input-wpp" />
      </CFlex>
    </OmtCard>

    <template v-if="editar">
      <OmtCard v-for="(fone, index) in telefones" :key="index" w="100%" mb="24px" v-show="!fone.removido">
        <CFlex direction="column" mb="12px">
          <CFlex direction="row" justify="space-between" flex-wrap="wrap">
            <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Tipo do telefone</OmtTypography>
            <OmtIcons
              v-if="!existChangeRequest"
              name="trash-can"
              height="16px"
              width="16px"
              size="lg"
              ml="16px"
              color="var(--colors-light-primary-navy-blue)"
              cursor="pointer"
              @click.prevent="onRemoverTelefoneClick(index)"
            />
          </CFlex>
          <OmtSelect
            :disabled="existChangeRequest"
            placeholder="Selecione o tipo do telefone"
            @change="e => onTipoTelefoneChange(index, e)"
            :initialValue="fone.tipo"
          >
            <option value="Celular">Celular</option>
            <option value="Comercial">Comercial</option>
            <option value="Residencial">Residencial</option>
          </OmtSelect>
        </CFlex>
        <CFlex direction="column" mb="18px">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Telefone</OmtTypography>
          <OmtInput
            :disabled="existChangeRequest"
            max-size="255"
            v-model="fone.numero"
            v-mask="getMascaraTelefone(fone)"
            fontSize="14px"
            fontWeight="700"
          />
        </CFlex>
        <CFlex direction="column" mb="12px">
          <OmtCheckbox
            :isDisabled="existChangeRequest || (fone.tipo !== 'Celular' && fone.tipo !== 'Comercial')"
            @change="onWhatappClick(index)"
            :is-checked="fone.whatsapp"
          >
            <OmtTypography as="p2" pl="15px"> Número Whatsapp </OmtTypography>
          </OmtCheckbox>
        </CFlex>
      </OmtCard>
    </template>

    <FixedButtonRow :useFooter="false" :solidColor="true" :fixedSize="podeAdicionarTelefone ? '2x' : '1x'">
      <CFlex direction="column" :w="['100%', '308px !important']">
        <OmtButton
          v-if="podeAdicionarTelefone"
          @click="onAdicionarTelefoneClick"
          w="100%"
          variant="ghost"
          color="var(--colors-light-primary-navy-blue)"
        >
          {{ labelAdicionarTelefone }}
        </OmtButton>

        <OmtButton
          :w="['100%', '308px !important']"
          @click.prevent="onNextClick"
          variant="solid"
          :disabled="!canSubmit"
        >
          Continuar
        </OmtButton>
      </CFlex>
    </FixedButtonRow>
  </CBox>
</template>

<script>
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import { maskTelFull, maskTel9DigitRegion } from "@/utils/utils";

export default {
  components: { FixedButtonRow },
  data() {
    return {
      telefones: [],
      numero: "",
      tipo: "",
      adicionar: false,
      selecionar: false,
      editar: false
    };
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      if (
        !this.resumeContacts?.Endereco &&
        !this.resumeContacts.Telefones?.length &&
        !this.resumeContacts?.Emails?.Pessoal
      ) {
        await Promise.all([this.fetchResumeContacts(), this.validateActiveTicket(), this.validarAtendimentoWhatsApp()]);
      } else {
        await this.validateActiveTicket();
      }
      const values = this.initialValues();
      this.telefones = values.telefones;
      this.tipo = values.tipo;
      this.numero = values.numero;
      this.adicionar = !this.existChangeRequest && this.telefones.length < 3;
      this.editar = !this.existChangeRequest && this.telefones.length >= 3;
      this.selecionar = this.existChangeRequest && this.telefones.length > 0;
    } catch (error) {
      this.$toast({
        description: error.message,
        status: "error",
        duration: 10000,
        position: "bottom-right"
      });
    } finally {
      this.$root.$emit("hide-loading");
    }
  },
  computed: {
    ...mapState("myAccount", ["welcomeOnBoardTourAuth"]),
    ...mapState("myContacts", ["resumeContacts", "existChangeRequest", "newPhones", "newWhatsapp"]),
    ...mapState("atendimento", ["WhatsAppAtivo", "NumeroFormatado", "DDD", "DDI"]),
    podeAdicionarTelefone() {
      if (!this.editar) return false;
      if (this.existChangeRequest) return false;
      return ((this.telefones ?? []).filter(t => !t.removido) ?? []).length < 3;
    },
    labelAdicionarTelefone() {
      return ((this.telefones ?? []).filter(t => !t.removido) ?? []).length
        ? "Adicionar outro número"
        : "Adicionar número";
    },
    mascara() {
      return this.tipo === "Comercial" || this.tipo === "Residencial" ? maskTelFull(this.numero) : maskTel9DigitRegion;
    },
    whatsapp() {
      return this.WhatsAppAtivo
        ? `+${(this.DDI || 0).zeroLeft()} (${(this.DDD || 0).zeroLeft()}) ${this.NumeroFormatado}`
        : "";
    },
    canSubmit() {
      return !!this.numero && !!this.tipo;
    }
  },
  methods: {
    ...mapActions("myContacts", [
      "fetchResumeContacts",
      "createChangeRequestFromTour",
      "validateActiveTicket",
      "validadeChanges"
    ]),
    ...mapActions("atendimento", ["validarAtendimentoWhatsApp", "ativarAtendimentoWhatsApp"]),
    ...mapActions("myAccount", ["checkNextWelcomeOnBoardTour"]),
    ...mapMutations("myContacts", ["SET_NEW_STATE"]),
    ...mapMutations("myAccount", ["SET_WELCOME_ONBOARD_TOUR_AUTH_RULES"]),
    initialValues() {
      const whatsapp = this.newWhatsapp ?? this.whatsapp;
      let fones = [];
      const baseTelefone = this.newPhones ?? this.resumeContacts?.Telefones;
      if (baseTelefone?.length) {
        fones = baseTelefone.map(t => {
          const numero = `${t.countryCode} ${t.Numero}`;
          return {
            tipo: t.Tipo,
            numero,
            whatsapp: numero === whatsapp
          };
        });
      }
      let tipo = "";
      if (this.WhatsAppAtivo) {
        const foneWhatsapp = fones.find(t => t.whatsapp);
        tipo = foneWhatsapp?.tipo ?? "Celular";
        if (!foneWhatsapp)
          fones = [
            {
              numero: whatsapp,
              tipo: "Celular",
              whatsapp: true
            },
            ...fones
          ];
      }
      const telefones = fones.slice(0, 3);
      return {
        numero: whatsapp,
        tipo,
        telefones
      };
    },
    onTipoSelected(tipo) {
      this.tipo = tipo;
    },
    onTelefoneSelected(numero) {
      if (!numero) return;
      this.numero = numero;
      const fone = this.telefones.find(t => t.numero === numero);
      this.onTipoSelected(fone?.tipo ?? "Celular");
    },
    onAdicionarTelefoneClick() {
      if (this.existChangeRequest) return;
      if (!this.podeAdicionarTelefone) return;
      this.telefones.push({ tipo: "Celular", numero: "+55", whatsapp: false });
      this.$nextTick(() => window.scrollTo(0, document.body.scrollHeight));
    },
    getMascaraTelefone(fone) {
      if (fone.tipo === "Comercial" || fone.tipo === "Residencial") {
        return maskTelFull(fone.numero);
      } else {
        return maskTel9DigitRegion;
      }
    },
    onRemoverTelefoneClick(indexRemovido) {
      if (this.existChangeRequest) return;
      const telefones = [];
      this.telefones.forEach((t, index) => {
        const fone = { ...t };
        if (index === indexRemovido) {
          fone.removido = true;
        }
        telefones.push(fone);
      });
      this.telefones = telefones;
    },
    checkWhatsappFlag() {
      const telefoneMarcado = this.telefones.find(t => t.whatsapp);
      this.numero = telefoneMarcado?.numero ?? "";
      this.tipo = telefoneMarcado?.tipo ?? "";
    },
    onTipoTelefoneChange(indexAlterado, tipo) {
      if (this.existChangeRequest) return;
      const telefones = [];
      this.telefones.forEach((t, index) => {
        const fone = { ...t };
        if (index === indexAlterado) {
          fone.tipo = tipo;
          if (tipo !== "Celular" && tipo !== "Comercial") fone.whatsapp = false;
        }
        telefones.push(fone);
      });
      this.telefones = telefones;
      this.checkWhatsappFlag();
    },
    onWhatappClick(indexMarcado) {
      if (this.existChangeRequest) return;
      this.numero = "";
      this.tipo = "";
      this.telefones.forEach(
        (t, index) =>
          (t.whatsapp = !t.whatsapp && index === indexMarcado && (t.tipo === "Celular" || t.tipo === "Comercial"))
      );
      this.checkWhatsappFlag();
    },
    async submitEditar() {
      try {
        this.$root.$emit("show-loading");
        let semAlteracaoWhatsapp = true;
        let semAlteracaoContatos = true;
        let whatsappSelecionado = "";
        if (!this.existChangeRequest) {
          const tels = [];
          const countryCodeRegex = /^\+(\d+)\s*/;
          this.telefones.forEach(tel => {
            const match = tel.numero.match(countryCodeRegex);
            let countryCode = "";
            if (match && match[1]) {
              countryCode = "+" + match[1];
            }
            if (!tel.removido)
              tels.push({
                Numero: tel.numero.replace(countryCodeRegex, ""),
                Tipo: tel.tipo,
                countryCode
              });
          });
          this.SET_NEW_STATE({ key: "newPhones", value: tels });
          const validacaoAlteracao = await this.validadeChanges({ allowWithoutChanges: true });
          const whatsappAtual = this.whatsapp;
          whatsappSelecionado = this.telefones.find(t => !!t.whatsapp)?.numero ?? "";
          if (!whatsappSelecionado || whatsappSelecionado === whatsappAtual) {
            whatsappSelecionado = null;
          }
          this.SET_NEW_STATE({ key: "newWhatsapp", value: whatsappSelecionado });
          if (validacaoAlteracao?.MsgValidacao) {
            // erro nos dados
            this.$toast({
              description: validacaoAlteracao.MsgValidacao,
              status: "error",
              duration: 10000,
              position: "bottom-right"
            });
            return;
          }
          semAlteracaoWhatsapp = !whatsappSelecionado;
          semAlteracaoContatos =
            !validacaoAlteracao?.Enderecos?.length &&
            !validacaoAlteracao?.Emails?.length &&
            !validacaoAlteracao?.Telefones?.length;
          if (!validacaoAlteracao?.Enderecos?.length) this.SET_NEW_STATE({ key: "newAddress", value: null });
          if (!validacaoAlteracao?.Emails?.length) this.SET_NEW_STATE({ key: "newEmails", value: null });
          if (!validacaoAlteracao?.Telefones?.length) this.SET_NEW_STATE({ key: "newPhones", value: null });
        }
        if (semAlteracaoContatos) {
          if (!semAlteracaoWhatsapp) {
            await this.ativarAtendimentoWhatsApp({
              phoneNumber: whatsappSelecionado,
              auth: 0
            });
          }
          this.nextTask();
        } else if (this.welcomeOnBoardTourAuth) {
          this.submit();
        } else {
          this.SET_WELCOME_ONBOARD_TOUR_AUTH_RULES({
            routeBack: this.$route.path,
            action: "myContacts/createChangeRequestFromTour"
          });
          this.$router.push("/welcome/identity-verification");
        }
      } catch (error) {
        this.$toast({
          description: error.message,
          status: "error",
          duration: 10000,
          position: "bottom-right"
        });
      } finally {
        this.$root.$emit("hide-loading");
      }
    },
    async submitAlterarOuSelecionar() {
      try {
        this.$root.$emit("show-loading");
        const semAlteracaoWhatsapp = this.numero === this.whatsapp;
        if (!semAlteracaoWhatsapp) this.SET_NEW_STATE({ key: "newWhatsapp", value: this.numero });
        let semAlteracaoContatos = true;
        const telefone = this.telefones.find(t => t.numero === this.numero);
        const countryCodeRegex = /^\+(\d+)\s*/;
        if (!this.existChangeRequest && !telefone) {
          const values = this.initialValues();
          const tels = [];
          [{ tipo: this.tipo, numero: this.numero }, ...values.telefones].forEach(tel => {
            const match = tel.numero.match(countryCodeRegex);
            let countryCode = "";
            if (match && match[1]) {
              countryCode = "+" + match[1];
            }
            if (!tel.removido)
              tels.push({
                Numero: tel.numero.replace(countryCodeRegex, ""),
                Tipo: tel.tipo,
                countryCode
              });
          });
          this.SET_NEW_STATE({ key: "newPhones", value: tels });
          const validacaoAlteracao = await this.validadeChanges({ allowWithoutChanges: true });
          if (validacaoAlteracao?.MsgValidacao) {
            // erro nos dados
            this.$toast({
              description: validacaoAlteracao.MsgValidacao,
              status: "error",
              duration: 10000,
              position: "bottom-right"
            });
            return;
          }
          semAlteracaoContatos = !validacaoAlteracao?.Telefones?.length;
          if (!validacaoAlteracao?.Telefones?.length) this.SET_NEW_STATE({ key: "newPhones", value: null });
        }
        if (semAlteracaoContatos) {
          if (!semAlteracaoWhatsapp) {
            await this.ativarAtendimentoWhatsApp({
              phoneNumber: this.numero,
              auth: this.welcomeOnBoardTourAuth
            });
          }
          this.nextTask();
        } else if (this.welcomeOnBoardTourAuth) {
          this.submit();
        } else {
          this.SET_WELCOME_ONBOARD_TOUR_AUTH_RULES({
            routeBack: this.$route.path,
            action: "myContacts/createChangeRequestFromTour"
          });
          this.$router.push("/welcome/identity-verification");
        }
      } catch (error) {
        this.$toast({
          description: error.message,
          status: "error",
          duration: 10000,
          position: "bottom-right"
        });
      } finally {
        this.$root.$emit("hide-loading");
      }
    },
    onNextClick() {
      if (this.editar) {
        this.submitEditar();
      } else {
        this.submitAlterarOuSelecionar();
      }
    },
    async nextTask() {
      const rota = await this.checkNextWelcomeOnBoardTour();
      this.$router.replace(rota);
    },
    async submit() {
      try {
        this.$root.$emit("show-loading");
        await this.createChangeRequestFromTour();
        this.nextTask();
      } catch (error) {
        this.$toast({
          description: error.message,
          status: "error",
          duration: 10000,
          position: "bottom-right"
        });
      } finally {
        this.$root.$emit("hide-loading");
      }
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep .input-wpp {
  input {
    padding: 0 !important;
    color: #4e4e4e;
    font-weight: 700;
    font-size: 14px !important;
  }
  input::placeholder {
    color: #4e4e4e;
    font-weight: 700;
    font-size: 14px;
  }
}
</style>
