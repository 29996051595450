<template>
  <div data-cy="home-notice-swiper-card" v-bind="$attrs" v-on="$listeners">
    <router-link v-if="internalLink" :to="{ path: item.Link }">
      <HomeNoticeCard :item="item" />
    </router-link>
    <a v-else :href="item.Link" target="_blank">
      <HomeNoticeCard :item="item" />
    </a>
  </div>
</template>
<script>
import HomeNoticeCard from "@/components/Organisms/Home/HomeNoticeCard";

export default {
  name: "HomeNoticeSwiperCard",
  components: { HomeNoticeCard },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    internalLink() {
      if (!this.item) return false;
      if (!this.item.Link) return false;
      return this.item.Link.startsWith("/");
    }
  }
};
</script>
<style lang="scss" scoped>
a {
  &:hover {
    opacity: 0.9;
  }
}
</style>
