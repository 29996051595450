<template>
  <omint-page-content>
    <Options
      ref="optsCity"
      :options="cityOptions"
      :name="'Cidades'"
      :id="'city-list'"
      :on-selection="onSelectCity"
      :placeholder="'Selecione a cidade'"
      :preSelected="selectedCity && selectedCity !== '' ? selectedCity : 'single'"
      :on-auto-selection="val => onAutoSelect('city', val)"
      :enable-auto-complete="true"
    />

    <br />
    <transition name="fade">
      <Options
        ref="optsProviders"
        v-show="selectedCity && selectedCity !== ''"
        :options="providerOptions"
        :name="'Prestador de serviço'"
        :id="'providers-list'"
        :on-selection="onSelectProvider"
        :placeholder="'Selecione o Prestador'"
        :preSelected="selectedProviderId && selectedProviderId !== '' ? selectedProviderId : 'single'"
        :on-auto-selection="val => onAutoSelect('providers', val)"
      />
    </transition>

    <br />
    <transition name="fade">
      <Options
        ref="optsAddress"
        v-show="selectedProviderId && selectedProviderId !== ''"
        :options="adressOptions"
        :name="'Endereço'"
        :id="'address-list'"
        :on-selection="onSelectAdress"
        :placeholder="'Selecione o Endereço'"
        :preSelected="selectedAddressId && selectedAddressId !== '' ? selectedAddressId : 'single'"
        :on-auto-selection="val => onAutoSelect('addres', val)"
      />
    </transition>

    <br />

    <omt-buttons-bar v-if="!btnDisabled && !showPicker">
      <omt-button :onClick="onClick" :disabled="btnDisabled"> Continuar </omt-button>
    </omt-buttons-bar>

    <omt-buttons-bar v-show="showPicker">
      <omt-button v-show="!visibility" :on-click="openCalendar">
        <i class="fas fa-calendar"></i> Selecionar Data
      </omt-button>
      <v-date-picker
        v-show="visibility"
        ref="picker"
        @dayclick="onChooseDate"
        :value="date === null ? new Date() : date"
        popover-visibility="focus"
      >
      </v-date-picker>
    </omt-buttons-bar>

    <center>
      <omt-p v-if="date">Data do agendamento: {{ date.formatDDMMYYYY() }}</omt-p>
    </center>
    <br />
    <div v-if="date">
      <SwitchCheckBox :onChange="val => (checked = val)" :rounded="true">
        <label>
          Declaro que li as instruções contidas no documento "Instruções de Checkup", disponível no menu "Meus
          Documentos" que agendei previamente meu check up no local na data informada.
        </label>
      </SwitchCheckBox>
    </div>

    <br />
    <br />
    <omt-buttons-bar v-if="checked">
      <omt-button class="btn-finish" :onClick="onFinish">Emitir Autorização</omt-button>
    </omt-buttons-bar>
  </omint-page-content>
</template>

<script>
import Options from "@/components/Molecules/Options";
import SwitchCheckBox from "@/components/Molecules/SwitchCheckBox";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "VoucherForm",
  components: { Options, SwitchCheckBox },
  async mounted() {
    this.$root.$emit("change-title-label", "Checkup Digital");
    await this.$root.$emit("show-loading");
    await this.clear();
    await this.fetchServiceProviders();
    await this.fetchExpDate();
    await this.$root.$emit("hide-loading");
  },
  data: function () {
    return {
      date: null,
      checked: false,
      showFinish: false,
      userConfirm: false,
      visibility: false
    };
  },
  computed: {
    ...mapState("voucher", ["selectedCity", "selectedProviderId", "selectedAddressId", "expDate", "protocol"]),
    ...mapGetters("voucher", ["providersByCity", "providersForCity", "adressForProvider", "phone"]),
    cityOptions() {
      return Object.keys(this.providersByCity).map(city => ({
        label: city,
        value: city
      }));
    },
    providerOptions() {
      const cache = [];
      return this.providersForCity
        .map(p => ({
          label: p.NomePrestador,
          value: { id: p.IdPrestadorVoucher, type: p.agendamentoVoucherTipo }
        }))
        .filter(o => {
          if (cache[o.label]) {
            return false;
          }
          cache[o.label] = true;
          return true;
        });
    },
    adressOptions() {
      return this.adressForProvider.map(p => ({
        label: p.Endereco,
        value: p.SrEndereco
      }));
    },
    btnDisabled() {
      return this.selectedCity === "" || this.selectedProviderId === "" || this.selectedAddressId === "";
    },
    showPicker() {
      return this.userConfirm && !this.btnDisabled;
    }
  },
  methods: {
    ...mapMutations("voucher", [
      "SET_SELECTED_CITY",
      "SET_SELECTED_PROVIDER",
      "SET_SELECTED_ADDRESS",
      "SET_VOUCHER_TYPE",
      "SET_DATE"
    ]),
    ...mapMutations("ticket", ["SET_RATING_INFOS"]),
    ...mapActions("voucher", ["fetchServiceProviders", "clear", "confirm", "fetchExpDate"]),
    openCalendar() {
      this.visibility = true;
      this.$nextTick(() => this.$refs.picker.$el.focus());
    },
    onAutoSelect: async function (field, val) {
      if (!val || val === "") return;
      try {
        await this.$root.$emit("show-loading");
        switch (field) {
          case "city":
            await this.SET_SELECTED_PROVIDER("");
            await this.SET_VOUCHER_TYPE("");
            await this.SET_SELECTED_ADDRESS("");
            await this.SET_SELECTED_CITY(val);
            break;
          case "providers":
            await this.SET_SELECTED_ADDRESS("");
            await this.SET_SELECTED_PROVIDER(val.id);
            await this.SET_VOUCHER_TYPE(val.type);
            break;
          case "addres":
            await this.SET_SELECTED_ADDRESS(val);
            break;
          default:
            break;
        }
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    onSelectCity: async function (val) {
      if (!val || val === "") return;

      try {
        await this.$root.$emit("show-loading");
        await this.SET_SELECTED_PROVIDER("");
        await this.SET_VOUCHER_TYPE("");
        await this.SET_SELECTED_ADDRESS("");
        await this.SET_SELECTED_CITY(val);
        this.$refs.optsProviders.reset();
        this.$refs.optsAddress.reset();
        this.date = null;
        this.checked = false;
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    onSelectProvider: async function (val) {
      if (!val || val === "") return;

      try {
        await this.SET_SELECTED_ADDRESS("");
        await this.SET_SELECTED_PROVIDER(val.id);
        await this.SET_VOUCHER_TYPE(val.type);
        this.$refs.optsAddress.reset();
        this.date = null;
        this.checked = false;
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    onSelectAdress: async function (val) {
      if (!val || val === "") return;

      try {
        await this.$root.$emit("show-loading");
        this.date = null;
        this.checked = false;
        await this.SET_SELECTED_ADDRESS(val);
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    onChooseDate: function (day) {
      if (day.date.getTime() < new Date().setHours(0, 0, 0, 0)) {
        Swal.fire({
          text: "A Data do Checkup não pode ser no passado",
          confirmButtonText: "OK"
        });
        return;
      }
      if (day.date.getTime() === new Date().setHours(0, 0, 0, 0)) {
        Swal.fire({
          text: "A Data do Checkup não pode ser a data corrente",
          confirmButtonText: "OK"
        });
        return;
      }
      if (day.date.getTime() > new Date(this.expDate).setHours(0, 0, 0, 0)) {
        Swal.fire({
          text: `A Data limite do Checkup é ${new Date(this.expDate).formatDDMMYYYY()}`,
          confirmButtonText: "OK"
        });
        return;
      }
      this.date = day.date;
      this.SET_DATE(day.date);
      this.visibility = false;
    },
    onFinish: async function () {
      try {
        await this.$root.$emit("show-loading");
        const sucess = await this.confirm();
        if (sucess) {
          this.SET_RATING_INFOS({ ratingRoute: "/voucher/voucher-redireciona", ratingType: 5 });
          await this.$router.replace({
            path: `/experience-rating/${this.protocol}/5/S`
          });
        } else {
          await this.$root.$emit("hide-loading");
          await Swal.fire({
            title: "Erro",
            html: "Não foi possivel confirmar o agendamento.<br />Serviço temporariamente indisponível.",
            confirmButtonText: "OK",
            icon: "error"
          });
        }
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    onClick: async function () {
      const yesOrNo = await Swal.fire({
        title: "Confirmação",
        html: `Você já efetuou o agendamento nesse prestador?
          <br />
          A data limite para agendamento é ${new Date(this.expDate).formatDDMMYYYY()}`,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        showCancelButton: true,
        showConfirmButton: true
      });

      if (yesOrNo.value) {
        await Swal.fire({
          text: "Selecione a Data do Checkup",
          confirmButtonText: "OK"
        });
        this.userConfirm = true;
        this.visibility = true;
        this.$nextTick(() => this.$refs.picker.$el.focus());
      } else {
        await Swal.fire({
          title: "IMPORTANTE",
          html: `
            <p>Antes de informar uma data, você deve ligar no local selecionado e agendar a data de seu checkup. Com a data agendada, retorne ao APP e emita a Autorização. </p>
            <br/>
            <p>O Telefone para esse agendamento é:</p>
            <p>
            <span><i class="fas fa-phone-square fas-2x"></i></span>
            ${this.phone}</p> `,
          confirmButtonText: "Fechar"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
