<template>
  <omint-page>
    <Header name="LocalStorage" titleLabel="LocalStorage" backRoute="/" />
    <omint-page-content>
      <div v-for="(storage, i) in storageInfos" :key="'storage-' + i">
        <omt-divider v-if="i !== 0" />
        <omt-h5>
          <b>{{ storage[0] }}</b>
        </omt-h5>
        <omt-p v-if="storage[2]">
          <omt-li v-for="(storageItem, j) in storage[1]" :key="'item-' + j">
            {{ storageItem }}
          </omt-li>
        </omt-p>
        <omt-p v-else>
          {{ storage[1] }}
        </omt-p>
      </div>
    </omint-page-content>
    <Footer />
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";

export default {
  components: { Header, Footer },
  name: "MyStorage",
  data() {
    return {
      storageInfos: {}
    };
  },
  mounted() {
    let storage = Object.entries(localStorage);
    storage.forEach(e => {
      try {
        const p = JSON.parse(e[1]);
        e[1] = p[e[0]] || Object.entries(p).filter(f => f[0] !== "exp")[0] || p;
        e.push(Array.isArray(e[1]));
      } catch (error) {
        e.push(false);
      }
    });
    this.storageInfos = storage;

    this.$root.$emit("hide-loading");
  }
};
</script>
