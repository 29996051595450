<script>
import Vue from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { CPseudoBox } from "@chakra-ui/vue";

/**
 * Icon component based on @fortawesome/pro-light-svg-icons & vue-fontawesome
 *
 * @prop {String} size currently options : '2xs', 'xs', 'sm', 'lg', 'xl', '2xl', '1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x'
 * @prop {String} color colors option : src/theme/colors.js
 */
export default Vue.component("OmtIcons", {
  props: {
    size: {
      type: String,
      validator: value => {
        const options = [
          "2xs",
          "xs",
          "sm",
          "lg",
          "xl",
          "2xl",
          "1x",
          "2x",
          "3x",
          "4x",
          "5x",
          "6x",
          "7x",
          "8x",
          "9x",
          "10x",
          // usefull on responsive situations
          // like <OmtIcons name="icon" :font-size="['12px', '16px']" size="inherit" />
          "inherit"
        ];
        const validOption = !!options.includes(value);
        if (!validOption) {
          console.error(`Allowed icon size options : ${options.join(" - ")}`);
          console.error(`value used ${value}`);
        }
        return validOption;
      },
      default: "sm"
    },
    color: {
      type: String
    },
    name: {
      type: String
    },
    prefix: {
      type: String,
      default: "fa-light"
    }
  },
  render: function (createElement) {
    return createElement(
      CPseudoBox,
      {
        on: { ...this.$listeners },
        attrs: {
          ...this.$attrs,
          color: this.color || "var(--colors-light-primary-gray1)"
        }
      },
      [
        createElement(FontAwesomeIcon, {
          attrs: {
            icon: `${this.name === "whatsapp" ? "fa-brands" : this.prefix} fa-${this.name}`,
            ...(this.size !== "inherit" ? { size: `${this.size}` } : null)
          }
        })
      ]
    );
  }
});
</script>
