<template>
  <router-link to="https://icom.app/omint-app?.pdf" v-chakra w="auto !important">
    <OmtButton
      minWidth="initial"
      width="27px"
      height="27px"
      paddingLeft="0"
      paddingRight="0"
      background="var(--colors-light-primary-dark-blue)"
      @click="() => goTo(`https://icom.app/omint-app?.pdf`)"
    >
      <img :src="baseUrl + '/images/omint/libras.svg'" alt="libras" />
    </OmtButton>
  </router-link>
</template>

<script>
import { mapGetters } from "vuex";
import { isMobile } from "@/utils/utils";
export default {
  computed: {
    ...mapGetters("saude", ["baseUrl"])
  },
  methods: {
    goTo(destiny) {
      if (isMobile()) {
        window.location = destiny;
      } else {
        window.open(destiny, "_blank");
      }
    }
  }
};
</script>
