<template>
  <CBox>
    <OmtTypography as="p1" mb="32px">
      Permita os <b>acessos necessários</b> para melhorar sua expêriencia:
    </OmtTypography>

    <OmtCard w="100%" mb="32px" pt="8px" px="24px">
      <CBox v-if="hasBiometric" w="100%" mb="28px">
        <RightSwitchCheckBox
          :key="'settings-biometric-' + `${status}`"
          :onChange="val => requestBiometricPermission(val)"
          :rounded="true"
          :checked="shoudUseBiometric"
          class="check-box-settings"
        >
          <OmtTypography as="h3-semi-bold"> Permitir o login por biometria </OmtTypography>
        </RightSwitchCheckBox>
        <OmtTypography as="p2" mb="24px" color="var(--colors-light-primary-gray2)">
          Acesse o aplicativo utilizando a sua biometria.
        </OmtTypography>
        <omt-divider />
      </CBox>
      <template v-if="v25">
        <CBox w="100%" mb="28px">
          <RightSwitchCheckBox
            v-if="v25"
            :key="'settings-camera-' + `${location}` + requestCount"
            :onChange="() => requestPermission('camera')"
            :rounded="true"
            :checked="camera === 'granted'"
            :disabled="camera === 'granted'"
            class="check-box-settings"
          >
            <OmtTypography as="h3-semi-bold"> Permitir o uso da câmera </OmtTypography>
          </RightSwitchCheckBox>
          <OmtTypography as="p2" mb="32px" color="var(--colors-light-primary-gray2)">
            Sua câmera será usada para a captura de imagens de documentos e videochamadas de consultas.
          </OmtTypography>
          <omt-divider />
        </CBox>
        <CBox w="100%" mb="28px">
          <RightSwitchCheckBox
            v-if="v25"
            :key="'settings-microfone-' + `${location}` + requestCount"
            :onChange="() => requestPermission('microfone')"
            :rounded="true"
            :checked="microfone === 'granted'"
            :disabled="microfone === 'granted'"
            class="check-box-settings"
          >
            <OmtTypography as="h3-semi-bold"> Permitir o uso do microfone </OmtTypography>
          </RightSwitchCheckBox>
          <OmtTypography as="p2" mb="32px" color="var(--colors-light-primary-gray2)">
            Seu microfone será usado em videochamadas de consultas.
          </OmtTypography>
          <omt-divider />
        </CBox>
        <CBox w="100%" mb="16px">
          <RightSwitchCheckBox
            v-if="v25"
            :key="'settings-location-' + `${location}` + requestCount"
            :onChange="() => requestPermission('location')"
            :rounded="true"
            :checked="location === 'granted'"
            :disabled="location === 'granted'"
            class="check-box-settings"
          >
            <OmtTypography as="h3-semi-bold"> Permitir acesso à localização </OmtTypography>
          </RightSwitchCheckBox>
          <OmtTypography as="p2" color="var(--colors-light-primary-gray2)">
            Sua localização será usada para realizar a busca de hospitais, laboratórios e clínicas próximas a você.
          </OmtTypography>
        </CBox>
      </template>
    </OmtCard>
    <FixedButtonRow :useFooter="false" :solidColor="true">
      <OmtButton @click.prevent="onNextClick" :w="['100%', '308px !important']" variant="solid"> Continuar </OmtButton>
    </FixedButtonRow>
  </CBox>
</template>

<script>
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";
import RightSwitchCheckBox from "@/components/Molecules/RightSwitchCheckBox";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: { FixedButtonRow, RightSwitchCheckBox },
  data() {
    return {
      requestCount: 0
    };
  },
  created() {
    this.checkPermissionForResource("camera");
    this.checkPermissionForResource("microfone");
    this.checkPermissionForResource("location");
  },
  mounted() {
    this.requestCount += 1;
    this.$root.$emit("hide-loading");
  },
  computed: {
    ...mapState("biometrics", ["shoudUseBiometric", "hasBiometric", "status", "camera", "microfone", "location"]),
    ...mapGetters("biometrics", ["onNative"]),
    v25() {
      return Number.parseInt(localStorage.getItem("native_version")) >= 25;
    }
  },
  methods: {
    ...mapActions("ticket", ["createNewTicket"]),
    ...mapActions("biometrics", [
      "enableBiometric",
      "disableBiometric",
      "requestPermissionForResource",
      "checkPermissionForResource"
    ]),
    ...mapActions("myAccount", ["checkNextWelcomeOnBoardTour"]),
    requestPermission(resource) {
      this.requestPermissionForResource(resource);
      this.requestCount += 1;
    },
    requestBiometricPermission(check) {
      if (check) {
        this.enableBiometric();
        try {
          this.createNewTicket({
            obs: "Ativação da Biometria APP",
            files: [],
            refTicket: null,
            classification: "8178"
          });
        } catch (error) {
          window.$log.error("Não foi possível gravar o chamado de ativação da Biometria", error);
        }
      } else {
        this.disableBiometric();
      }
    },
    async onNextClick() {
      const rota = await this.checkNextWelcomeOnBoardTour();
      this.$router.replace(rota);
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep .check-box-settings .slider {
  background: #586f8b40;
}
</style>
