import Forgot from "@/pages/forgot/index.vue";
import ForgotNewPassword from "@/pages/forgot/new-password-form.vue";
import ForgotResetPasswordForm from "@/pages/forgot/reset-password-form.vue";

const routes: Route[] = [
  {
    path: "/forgot",
    name: "forgot",
    component: Forgot,
    meta: {
      backTo: "/login",
      allowAnonymous: true,
      routerSequence: 0
    },
    children: [
      {
        path: "code",
        name: "code",
        component: ForgotNewPassword,
        props: true,
        meta: {
          allowAnonymous: true,
          routerSequence: 1
        }
      },
      {
        path: "reset",
        name: "reset",
        component: ForgotResetPasswordForm,
        meta: {
          allowAnonymous: true,
          routerSequence: 2
        }
      }
    ]
  }
];

export default routes;
