const R = 6371e3; // R is earth’s radius
const PI = 3.1415926535;

function toRadians(val) {
  return (val / 180.0) * PI;
}

export function FindDistance({ lat1, lon1, lat2, lon2 }) {
  const lat1radians = toRadians(lat1);
  const lat2radians = toRadians(lat2);
  const latRadians = toRadians(lat2 - lat1);
  const lonRadians = toRadians(lon2 - lon1);

  const a =
    Math.sin(latRadians / 2) * Math.sin(latRadians / 2) +
    Math.cos(lat1radians) * Math.cos(lat2radians) * Math.sin(lonRadians / 2) * Math.sin(lonRadians / 2);

  const d = (R * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))) / 1000; // KM
  return Math.round(d * 100) / 100; // KM with 2 digits precision (0.00 KM)
}

function ShowPosition(done) {
  return function (position) {
    return done({
      longitude: position.coords.longitude,
      latitude: position.coords.latitude
    });
  };
}

function LocationError(done) {
  return function (error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        window.$log.message("User denied the request for Geolocation");
        break;
      case error.POSITION_UNAVAILABLE:
        window.$log.message("Location information is unavailable");
        break;
      case error.TIMEOUT:
        window.$log.message("The request to get user location timed out");
        break;
      case error.UNKNOWN_ERROR:
        window.$log.message("An unknown error occurred.");
        break;
    }
    return done();
  };
}

export async function GetLocation() {
  if (navigator.geolocation) {
    const resp = new Promise(function (res, rej) {
      const a = ShowPosition(res);
      const b = LocationError(rej);
      navigator.geolocation.getCurrentPosition(a, b, { timeout: 15000 });
    });
    const r = await resp;
    const coords = Promise.resolve(r);
    window.$log.message({ coords });
    return coords;
  } else {
    return Promise.reject();
  }
}

export async function CalculateDistance(originalData, latitudeName, longitudeName) {
  const coords: any = await GetLocation().catch(() => null);
  let data = originalData;
  if (coords) {
    data = await Promise.all(
      originalData.map(async d => {
        let Distancia = undefined;
        if (d[latitudeName] !== "" && d[longitudeName] !== "") {
          Distancia = await FindDistance({
            lat1: coords.latitude,
            lon1: coords.longitude,
            lat2: d[latitudeName],
            lon2: d[longitudeName]
          });
        }
        return { ...d, Distancia };
      })
    );
    return data.sort((a, b) => {
      if (a.Distancia === undefined && b.Distancia === undefined) return 3;
      if (a.Distancia === undefined && b.Distancia !== undefined) return 2;
      if (a.Distancia !== undefined && b.Distancia === undefined) return -2;
      if (a.Distancia < b.Distancia) return -1;
      if (a.Distancia > b.Distancia) return 1;
      return 0;
    });
  } else {
    return originalData;
  }
}
