<template>
  <OmtStickedModal
    data-cy="refund-list-filter-modal"
    v-if="isOpen"
    @close="close"
    :headerBind="{
      padding: ['16px', '82px 42px 0px 42px']
    }"
    :bodyBind="{
      padding: ['16px 16px 24px 16px', '68px 42px 24px 42px']
    }"
  >
    <template #header>
      <OmtButton :display="['block', 'none']" class="clear" variant="ghost" @click="clear" height="18px" p="0">
        <OmtTypography as="p1" h="18px" w="46px" position="relative"> Limpar </OmtTypography>
      </OmtButton>
    </template>

    <CBox :minHeight="['initial', 'calc(100vh - 400px)']">
      <CFlex justifyContent="space-between" :mb="['24px', '36px']">
        <OmtTypography as="h1-bold"> Filtros </OmtTypography>
        <OmtButton class="clear" variant="ghost" @click="clear" height="18px" p="0" :display="['none', 'block']">
          <OmtTypography as="p1" h="18px" w="46px" position="relative"> Limpar </OmtTypography>
        </OmtButton>
      </CFlex>

      <CFlex
        justify="space-between"
        v-for="(filter, index) in modalFilters"
        :key="index"
        cursor="pointer"
        @click="filter.isActive = !filter.isActive"
        mb="24px"
        data-cy="refund-option"
      >
        <OmtTypography as="p1"> {{ filter.label }} </OmtTypography>
        <OmtCheckbox @change="filter.isActive = !filter.isActive" :is-checked="filter.isActive" />
      </CFlex>
    </CBox>

    <template #footer>
      <OmtButton w="100%" @click="save"> Aplicar </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
/**
 * @prop {Boolean} isOpen
 * @type {{ label: string, isActive: boolean }} filterType
 * @prop {filterType} filters
 * @Event {(Array<filterType>) => void} save
 */
export default {
  props: {
    isOpen: {
      Type: Boolean
    },
    filtersHasChanges: {
      type: Boolean
    },
    filters: {
      type: Array
    }
  },
  data() {
    return {
      modalFilters: []
    };
  },
  methods: {
    close() {
      this.$emit("update:isOpen", false);
    },
    clear() {
      this.modalFilters.forEach(f => (f.isActive = false));
    },
    save() {
      this.$emit("save", this.modalFilters);
      this.close();
    }
  },
  watch: {
    isOpen: {
      handler() {
        this.modalFilters = JSON.parse(JSON.stringify(this.filters));
      },
      immediate: true
    },
    modalFilters: {
      handler(filters) {
        const hasChanges = filters.filter(filter => !!filter.isActive);
        this.$emit("update:filtersHasChanges", !!hasChanges.length);
      },
      deep: true
    },
    filters: {
      handler(filters) {
        const hasChanges = filters.filter(filter => !!filter.isActive);
        this.$emit("update:filtersHasChanges", !!hasChanges.length);
      },
      deep: true
    }
  }
};
</script>
