import Blog from "@/pages/blog/index.vue";
import Services from "@/pages/services/index.vue";
import Whatsapp from "@/pages/whatsapp/index.vue";
import Credential from "@/pages/credential/index.vue";
import Shared from "@/pages/shared/index.vue";
import Home from "@/pages/home/index.vue";
import Arquivo from "@/pages/arquivo/index.vue";
import Faturas from "@/pages/faturas/index.vue";
import Optin from "@/pages/optin/index.vue";
import Vinculos from "@/pages/vinculos/index.vue";
import Mystorage from "@/pages/mystorage/index.vue";
import Settings from "@/pages/settings/index.vue";
import EditShortcuts from "@/pages/edit-shortcuts/index.vue";
import MeusArquivos from "@/pages/meus-arquivos/index.vue";
import AdministratorRegistrer from "@/pages/administrator-registrer/index.vue";
import ExternalLink from "@/pages/external-link/index.vue";
import ChangePassword from "@/pages/change-password/index.vue";
import AccountRemoval from "@/pages/account-removal/index.vue";
import Nonpayment from "@/pages/nonpayment/index.vue";
import NonpaymentTerms from "@/pages/nonpayment/terms.vue";

const routes: Route[] = [
  {
    path: "/blog/:id",
    name: "blog",
    component: Blog,
    props: true,
    meta: {
      checkPathDisabled: true
    }
  },
  {
    path: "/edit-shortcuts",
    name: "editShortcuts",
    component: EditShortcuts,
    props: true,
    meta: {
      checkPathDisabled: true
    }
  },
  {
    path: "/services",
    name: "services",
    component: Services,
    props: true,
    meta: {
      checkPathDisabled: true
    }
  },
  {
    path: "/whatsapp",
    name: "whatsapp",
    component: Whatsapp,
    meta: {
      backTo: "/",
      registerAccess: { servico: "Atendimento", funcionalidade: "Acesso a atendimento via WhatsApp" }
    }
  },
  {
    path: "/credential/:idx",
    name: "credential",
    props: true,
    component: Credential,
    meta: {
      checkPathDisabled: true
    }
  },
  {
    path: "/shared/*",
    name: "shared",
    props: true,
    component: Shared,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      checkPathDisabled: true
    }
  },
  {
    path: "/meus-arquivos",
    name: "myfiles",
    component: MeusArquivos,
    meta: {
      backTo: "/",
      registerAccess: { servico: "Meus Arquivos", funcionalidade: "Acesso a meus arquivos" }
    }
  },
  {
    path: "/administrator-registrer",
    name: "administrator-registrer",
    component: AdministratorRegistrer,
    meta: {
      backTo: "/",
      registerAccess: { servico: "Gestão de acessos", funcionalidade: "Acesso a gestão de acessos" }
    }
  },
  {
    path: "/arquivo",
    name: "displayFile",
    component: Arquivo,
    meta: {
      allowAnonymous: false,
      checkPathDisabled: true
    }
  },
  {
    path: "/faturas",
    alias: "/associado-lista-faturas",
    name: "stament",
    component: Faturas,
    meta: {
      backTo: "/",
      registerAccess: { servico: "Faturas", funcionalidade: "Acesso a listagem de Faturas" },
      validPath: ["/faturas", "/associado-lista-faturas"]
    }
  },
  {
    path: "/external-link",
    name: "external.link",
    component: ExternalLink,
    props: true,
    meta: {
      backTo: "/",
      checkPathDisabled: true
    }
  },
  {
    path: "/optin",
    name: "optin",
    component: Optin,
    meta: {
      checkPathDisabled: true
    }
  },
  {
    path: "/nonpayment",
    component: Nonpayment,
    children: [
      {
        path: "",
        name: "nonpayment.terms",
        component: NonpaymentTerms,
        meta: {
          checkPathDisabled: true,
          routerSequence: 0
        }
      }
    ]
  },
  {
    path: "/vinculos",
    name: "vinculos",
    component: Vinculos,
    meta: {
      backTo: "/",
      checkPathDisabled: true
    }
  },
  {
    path: "/change-password",
    name: "change.password",
    component: ChangePassword,
    meta: {
      backTo: "/",
      checkPathDisabled: true,
      registerAccess: { servico: "Alterar Senha", funcionalidade: "Acesso a alteração de senha" }
    }
  },
  {
    path: "/mystorage",
    name: "mystorage",
    component: Mystorage,
    meta: {
      allowAnonymous: true,
      backTo: "/"
    }
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      backTo: "/",
      checkPathDisabled: true
    }
  },
  {
    path: "/account-removal",
    name: "account-removal",
    component: AccountRemoval,
    meta: {
      backTo: "/",
      registerAccess: { servico: "Exclusão de conta", funcionalidade: "Acesso a Exclusão de conta" }
    }
  }
];

export default routes;
