<template>
  <CBox>
    <TokenSendSmsCode
      v-if="showTokenAuth"
      :service="service"
      :tokenKey="tokenKey"
      :id="id"
      @valid="handleValid"
      @cancel="handleCancel"
    />
    <TokenUnicoBiometry
      v-if="showBiometryAuth"
      :service="service"
      :tokenKey="tokenKey"
      :id="id"
      @valid="handleValid"
      @cancel="handleCancel"
    />
  </CBox>
</template>

<script>
import TokenSendSmsCode from "./sms";
import TokenUnicoBiometry from "./unico";
import checkBiometryRules from "@/components/Organisms/Biometry/checkRule.js";

export default {
  name: "TokenModal",
  props: {
    service: {
      type: String
    },
    regras: {
      type: Array,
      default: () => []
    },
    tokenKey: {
      type: String
    },
    id: {
      type: Number,
      default: 0
    }
  },
  mixins: [checkBiometryRules],
  components: {
    TokenSendSmsCode,
    TokenUnicoBiometry
  },
  data() {
    return {
      requireAuth: true,
      authType: ""
    };
  },
  computed: {
    showBiometryAuth() {
      return this.requireAuth && this.authType === "BIO-UNICO";
    },
    showTokenAuth() {
      return this.requireAuth && this.authType === "CODIGO";
    }
  },
  async mounted() {
    try {
      await this.$root.$emit("show-loading");
      this.validateActiveBiometry({
        service: this.service,
        rules: [],
        type: "",
        routeBack: this.service,
        sucessCallBack: authType => {
          this.authType = authType.Autorizador;
          if (!authType.Autorizador || authType.Id > 0) {
            this.$emit("valid", { auth: authType.Id });
          }
        }
      });
    } catch (error) {
      this.handleError(error);
      this.$emit("cancel");
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  methods: {
    handleValid(payload) {
      this.$emit("valid", payload);
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleError(error) {
      window.$log.error("biometry page", error);
      this.showErrorMessage("Ocorreu um erro ao obter a regra de autenticação");
    },
    showErrorMessage(message) {
      this.$root.$emit("hide-loading");
      this.$toast({
        description: message ?? "Ocorreu um erro ao obter a biometria",
        status: "error",
        duration: 10000,
        position: "top"
      });
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss" scoped></style>
