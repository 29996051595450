<template>
  <OmtGrid>
    <OmtRow>
      <OmtTypography as="h1-bold" mb="24px"> Protocolo {{ protocol }} </OmtTypography>
    </OmtRow>
    <OmtRow :mobileStart="1" mb="16px">
      <TextTile
        v-bind="{
          title: 'Assunto',
          description: detail.title
        }"
      />
    </OmtRow>

    <OmtRow :mobileEnd="2">
      <TextTile
        v-bind="{
          title: 'Data da solicitação',
          description: detail.createdAt
        }"
      />
    </OmtRow>

    <OmtRow>
      <CDivider mt="16px" mb="16px" />
    </OmtRow>

    <OmtRow>
      <OmtTypography as="h2-bold" mb="16px"> Dados do beneficiário </OmtTypography>
    </OmtRow>

    <OmtRow :mobileStart="1" mb="16px">
      <TextTile
        v-bind="{
          title: 'Beneficiário',
          description: detail.beneficiaryName
        }"
      />
    </OmtRow>

    <OmtRow :mobileEnd="2">
      <TextTile v-bind="{ title: 'Código beneficiário', description: detail.beneficiaryCode }" />
    </OmtRow>

    <OmtRow mt="16px">
      <OmtTypography as="p2" color="var(--colors-light-primary-gray2)"> Status </OmtTypography>
    </OmtRow>

    <OmtRow>
      <StatusTile v-chakra mb="16px" :label="detail.statusDsc" :color="statusColor" />
    </OmtRow>

    <OmtRow
      :desktopEnd="12"
      border="0.5px solid var(--colors-light-secondary-divider)"
      border-radius="8px"
      py="19px"
      px="16px"
      v-if="hasResponse"
    >
      <CBox>
        <CFlex>
          <OmtIcons name="memo" mr="16px" />
          <OmtTypography as="h2-bold"> {{ detail.doc.title }} </OmtTypography>
        </CFlex>
        <CFlex ps="27px" mb="16px">
          <OmtTypography as="p1">Data {{ detail.doc.createdAt }}</OmtTypography>
        </CFlex>
        <CFlex v-for="(r, index) in detail.doc.resp" :key="`resp-${index}`" ps="27px" mb="16px">
          <OmtTypography as="p1">{{ r }}</OmtTypography>
        </CFlex>
        <CFlex v-if="hasDoc">
          <OmtButton w="100%" height="40px" fontSize="14px" @click="download"> Baixar </OmtButton>
        </CFlex>
      </CBox>
    </OmtRow>

    <OmtRow v-if="hasPendency" py="19px" px="16px">
      <CBox>
        <div class="warning">
          <CFlex px="16px" pt="16px" pb="14px" align-items="center">
            <OmtIcons name="circle-exclamation" mr="14px" size="1x" />
            <OmtTypography as="h3-bold"> Atente-se ao prazo para regularização </OmtTypography>
          </CFlex>
          <CFlex ps="27px" mb="16px" px="16px" pb="16px">
            <OmtTypography as="p1">
              As informações ou documentos solicitados
              <omt-bold> devem ser enviados em até</omt-bold>
              <omt-bold class="days-color"> {{ detail.pendency.days }} dias</omt-bold>, caso contrário, esta solicitação
              será cancelada.
            </OmtTypography>
          </CFlex>
        </div>
        <CFlex>
          <OmtButton w="100%" height="40px" fontSize="14px" @click="modalPendencies = true">
            Responder pendências
          </OmtButton>
        </CFlex>
      </CBox>
    </OmtRow>

    <template v-if="pictures.length">
      <OmtRow>
        <CDivider mt="16px" mb="16px" />
      </OmtRow>

      <OmtRow>
        <OmtTypography as="h2-bold" mb="8px"> Imagens enviadas </OmtTypography>
      </OmtRow>

      <OmtRow>
        <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> Pedidos médicos e relatórios </OmtTypography>
      </OmtRow>

      <OmtRow>
        <CGrid
          w="100%"
          :templateColumns="['repeat(auto-fill, minmax(74px, 1fr))', 'repeat(auto-fill, minmax(134px, 1fr))']"
          gap="6"
        >
          <ImagePreviewer v-for="(picture, index) in pictures" :key="index" v-bind="picture" :source="picture.source" />
        </CGrid>
      </OmtRow>
    </template>

    <PendingResponseModal :reasons="pendencyReasons" :isOpen="modalPendencies" @close="closeModal" @submit="submit" />

    <SendPendency :isOpen="openModalSuccess" :protocol="protocolANS" @close="closeModalSendPendency" />

    <Footer />
  </OmtGrid>
</template>

<script>
import { mapActions } from "vuex";
import TextTile from "@/components/Molecules/TextTile";
import StatusTile from "@/components/Organisms/Requests/StatusTile.vue";
import Footer from "@/components/Molecules/Footer";
import PendingResponseModal from "@/components/Organisms/Requests/PendingResponseModal.vue";
import SendPendency from "@/components/Organisms/Requests/SendPendency.vue";
import ImagePreviewer from "@/components/Molecules/ImagePreviewer";

export default {
  name: "DetailRequest",
  components: {
    TextTile,
    StatusTile,
    Footer,
    PendingResponseModal,
    SendPendency,
    ImagePreviewer
  },
  data() {
    return {
      detail: {},
      modalPendencies: false,
      openModalSuccess: false,
      pictures: [],
      protocolANS: ""
    };
  },
  computed: {
    protocol() {
      return this.$route.params.protocol ?? "";
    },
    statusColor() {
      switch (this.detail.statusCode) {
        case "F":
          return "var(--colors-light-support-success)";
        case "P":
          return "var(--colors-light-support-warning)";
        default:
          return "var(--colors-light-primary-dark-blue)";
      }
    },
    hasPendency() {
      return this.detail?.statusCode === "P" && this.detail?.pendency.ticket > 0;
    },
    hasDoc() {
      const idDoc = this.detail?.doc?.id ?? 0;
      return idDoc > 0;
    },
    hasResponse() {
      if (this.hasDoc) return true;
      const respostas = this.detail?.doc?.resp?.length ?? 0;
      return respostas > 0;
    },
    pendencyReasons() {
      if (!this.hasPendency) return [];
      return this.detail?.pendency?.reasons ?? [];
    }
  },
  methods: {
    ...mapActions("request", [
      "getDocumentUrl",
      "fetchProtocolDetail",
      "fetchProtocolDetailImage",
      "returnRequestTicket"
    ]),
    loadPictures() {
      if (!this.detail?.pictures?.length) return;
      this.detail.pictures.forEach(p =>
        this.fetchProtocolDetailImage({ documento: p, original: false }).then(source =>
          this.pictures.push({
            id: p,
            source: source,
            loadModalImage: () => this.loadPicture(p)
          })
        )
      );
    },
    async loadPicture(id) {
      return await this.fetchProtocolDetailImage({ documento: id, original: true });
    },
    async getDetail() {
      const detail = await this.fetchProtocolDetail({
        protocol: this.protocol
      });
      if (!detail) {
        this.$router.replace("/solicitacoes");
        return;
      }
      this.detail = detail;
      this.loadPictures();
    },
    async download() {
      const url = await this.getDocumentUrl({ protocol: this.protocol });
      window.open(url, "_blank");
    },
    closeModal() {
      this.modalPendencies = false;
    },
    async submit({ pictures, obs }) {
      if (!this.hasPendency) return;
      let response = {};
      try {
        await this.$root.$emit("show-loading");
        response = await this.returnRequestTicket({
          protocol: this.protocol,
          pictures,
          obs
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }

      if (response && response.Data) {
        this.protocolANS = response.Data.protocoloANS;
        this.modalPendencies = false;
        this.openModalSuccess = true;
        const detail = await this.fetchProtocolDetail({
          protocol: this.protocol
        });
        if (detail) {
          this.detail = detail;
        }
      } else {
        await this.$toast({
          description: "Não foi possivel registrar o atendimento.\nServiço temporariamente indisponível.",
          status: "error",
          duration: 10000,
          position: "top"
        });
      }
    },
    closeModalSendPendency() {
      this.openModalSuccess = false;
    }
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      await this.getDetail();
    } catch (error) {
      console.error(error);
    } finally {
      this.$root.$emit("hide-loading");
    }
  }
};
</script>

<style scoped>
.warning {
  background-color: #f6f6f9;
  border-radius: 8px;
}
.days-color {
  color: #c31700;
}
</style>
