<template>
  <div :class="`${haslabel ? 'bg-light' : ''} form-group`">
    <input
      v-bind="$attrs"
      class="input"
      :placeholder="`${haslabel ? '' : placeholder}`"
      :class="myClass"
      v-bind:value="value"
      v-on:input="$emit('input', $event.target.value)"
      v-on:keydown.enter.prevent="onEnter"
      v-on:keydown="onKeydown"
      v-on:keyup="onKeyup"
      v-on:blur="onBlur"
      :data-label="placeholder"
    />
    <label v-if="haslabel"> {{ placeholder }} </label>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    onEnter: {
      type: Function,
      default: () => {
        return;
      }
    },
    onKeydown: {
      type: Function,
      default: () => {
        return;
      }
    },
    onKeyup: {
      type: Function,
      default: () => {
        return;
      }
    },
    onBlur: {
      type: Function,
      default: () => {
        return;
      }
    },
    solid: {
      type: Boolean,
      required: false
    },
    haslabel: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    myClass() {
      return `input-box
        ${this.hasIcon ? " hasIcon" : ""}
        ${this.haslabel ? " float-label" : ""}
        ${this.solid ? " solid" : ""}
        ${this.transparent ? " transparent" : ""}
        ${this.reverse ? " reverse" : ""}`;
    }
  }
};
</script>
<style lang="scss" scoped>
.form-group {
  position: relative;
}

.form-group[disabled],
.form-group[readonly] {
  opacity: 0.5;
}

.form-group label {
  top: 0;
  font-size: 11px;
  position: absolute;
  line-height: 1;
  padding: 6px 8px;
}

.bg-light {
  background-color: var(--colors-light-primary-light-gray);
  color: var(--colors-light-primary-gray1);
}

.bg-light label {
  color: var(--colors-light-primary-gray2);
}

.input {
  font-size: 12px;
  display: block;
  width: 100%;
  height: 40px;
  line-height: 1.2;
  margin: 0;
  margin-bottom: 0px;
  padding: 0 10px;
  background-color: transparent;
  border: 0;
  border-bottom: solid 2px rgba(0, 0, 0, 0.08);
  transition: all 250ms ease;
  outline: none;
  box-sizing: border-box;
}

.input[disabled="disabled"] {
  opacity: 0.5;
  cursor: not-allowed;
}

.input.float-label {
  height: 48px;
  padding-top: 1rem;
}

.solid {
  background-color: white;
}

.big-font-size .input {
  font-size: 18px;
}

.input.warning,
.input[aria-invalid="true"] {
  border-bottom: solid 2px #ff5b32 !important;
}

.input:hover,
.input:focus {
  outline: none;
}

.input::placeholder {
  opacity: 1;
}

.light .input::placeholder,
.light .input:-ms-input-placeholder,
.light .input::-ms-input-placeholder {
  color: white;
}

.light .input.inverse::placeholder,
.light .input.inverse:-ms-input-placeholder,
.light .input.inverse::-ms-input-placeholder {
  color: var(--colors-light-primary-gray1);
}

.dark {
  .bg-light {
    color: #5b5c5d;
    background-color: #fff;
  }
  .input::placeholder,
  .input:-ms-input-placeholder,
  .input::-ms-input-placeholder {
    color: #ccd1d9;
  }
  .form-group label {
    color: #5b5c5d;
  }
  .input {
    color: white;
    border-bottom: solid 2px #4a5568;
  }
  .input.float-label,
  .input.solid {
    color: var(--colors-light-primary-gray1);
  }
  .input.inverse::placeholder,
  .input.inverse:-ms-input-placeholder,
  .input.inverse::-ms-input-placeholder {
    color: #1f1f1f;
  }
}
</style>
