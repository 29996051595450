<template>
  <CBox :bg="['none', '#F6F6F9']" minHeight="100vh" width="100vw" position="sticky">
    <Header titleLabel="Reembolsos" />
    <omint-slider>
      <router-view />
    </omint-slider>
    <Footer />
  </CBox>
</template>

<script>
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";

export default {
  components: {
    Header,
    Footer
  }
};
</script>
