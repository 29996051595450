<template>
  <CBox v-bind="$attrs">
    <Header v-bind="header" @profilePicClick="$emit('profilePicClick')" />
    <CBox minHeight="calc(100vh - 80px)">
      <slot />
    </CBox>
    <Footer
      v-if="footer"
      v-bind="{
        ...{
          ...(typeof footer === 'object' && footer)
        }
      }"
    />
  </CBox>
</template>

<script>
/**
 * Set Header
 *
 * Specific info for each route
 *
 * this.$root.$on("change-back-route", newBackRoute)
 * this.$root.$on("change-title-label", newTitle)
 *
 * General info for inner routes
 *
 * v-bind="{
 *   header: {
 *     titleLabel: String
 *   },
 *   footer: {
 *      home: Boolean,
 *      refund: Boolean,
 *      services: Boolean,
 *      whatsapp: Boolean,
 *      search: Boolean
 *    } || Boolean
 * }"
 */
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";

export default {
  components: {
    Header,
    Footer
  },
  props: {
    header: {
      type: Object,
      validator: headerProps => {
        const availableProps = new Set(["name", "backRoute", "showLogo", "titleLabel"]);
        const wrongOptions = Object.keys(headerProps).filter(prop => !availableProps.has(prop));
        return !wrongOptions.length;
      }
    },
    footer: {
      type: Boolean | Object,
      default: true,
      validator: footer => {
        const availableProps = new Set(["home", "refund", "services", "chat", "search"]);
        const wrongOptions = Object.keys(footer).filter(prop => !availableProps.has(prop));
        return !wrongOptions.length;
      }
    }
  }
};
</script>
