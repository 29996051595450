import Vue from "vue";
import {
  AdicionarBeneficiario,
  AdicionarNomeado,
  EditarNomeado,
  ObterCadastroNomeado,
  ObterMunicipios,
  ObterNomeados,
  ObterParametros,
  Prorrogar,
  Reativar,
  Revogar
} from "../api/terceiros";
import { checkUpdatePermission } from "@/utils/checkReadonlyUtil";

const initialState = {
  dadosTitular: null,
  nomeados: [],
  parametros: {}
};

// vuex module
export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions: {
    async createBeneficiary(context, { autenticacao, idPessoa, tpRevalidacao }) {
      checkUpdatePermission();
      try {
        const response = await AdicionarBeneficiario({
          vinculo: context.rootState.saude.vinculo,
          autenticacao,
          idPessoa,
          tpRevalidacao
        });
        return response?.Data;
      } catch (error) {
        throw new Error(error.message);
      }
    },
    async createNominee(
      context,
      {
        autenticacao,
        nome,
        cpf,
        rne,
        rg,
        emissorRg,
        dtEmissaoRg,
        dtNascimento,
        idLocalNascimento,
        ufLocalNascimento,
        nomeLocalNascimento,
        sexo,
        nomeMae,
        idGrauRelacionamento,
        dsGrauRelacionamento,
        complementoGrauRelacionamento,
        nrCelular,
        smsCelular,
        nrFixo,
        tpFixo,
        tpEmail,
        email,
        avisoEmail,
        tpRevalidacao
      }
    ) {
      checkUpdatePermission();
      try {
        const response = await AdicionarNomeado({
          vinculo: context.rootState.saude.vinculo,
          autenticacao,
          nome,
          cpf,
          rne,
          rg,
          emissorRg,
          dtEmissaoRg,
          dtNascimento,
          idLocalNascimento,
          ufLocalNascimento,
          nomeLocalNascimento,
          sexo,
          nomeMae,
          idGrauRelacionamento,
          dsGrauRelacionamento,
          complementoGrauRelacionamento,
          nrCelular,
          smsCelular,
          nrFixo,
          tpFixo,
          tpEmail,
          email,
          avisoEmail,
          tpRevalidacao
        });
        return response?.Data;
      } catch (error) {
        throw new Error(error.message);
      }
    },
    async fetchCities(context, { uf }) {
      try {
        const response = await ObterMunicipios({
          vinculo: context.rootState.saude.vinculo,
          uf
        });
        return response.Data;
      } catch (error) {
        throw new Error(error.message);
      }
    },
    async fetchNomineeDetails(context, { id }) {
      try {
        const response = await ObterCadastroNomeado({
          vinculo: context.rootState.saude.vinculo,
          id
        });
        return response.Data;
      } catch (error) {
        throw new Error(error.message);
      }
    },
    async fetchNomineesInfos(context) {
      try {
        const response = await ObterNomeados({
          vinculo: context.rootState.saude.vinculo
        });
        if (response.Data) {
          if (!context.state.dadosTitular?.Tipo && response.Data.Titular) {
            await context.commit("SET_DADOS_TITULAR", response.Data.Titular);
          }
          if (response.Data.Nomeados) {
            await context.commit("SET_NOMEADOS", response.Data.Nomeados);
          }
          return;
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
    async fetchParams(context) {
      if (Object.keys(context.state.parametros).length > 0) return;
      try {
        const response = await ObterParametros({
          vinculo: context.rootState.saude.vinculo
        });
        if (response.Data) {
          await context.commit("SET_PARAMETROS", response.Data);
          return;
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
    async reactivateNomiee(context, { autenticacao, idPessoa, tpRevalidacao }) {
      checkUpdatePermission();
      try {
        const response = await Reativar({
          vinculo: context.rootState.saude.vinculo,
          autenticacao,
          idPessoa,
          tpRevalidacao
        });
        return response?.Data;
      } catch (error) {
        throw new Error(error.message);
      }
    },
    async removeNomieePeriod(context, { autenticacao, idPessoa }) {
      checkUpdatePermission();
      try {
        const response = await Revogar({
          vinculo: context.rootState.saude.vinculo,
          autenticacao,
          idPessoa
        });
        return response?.Data;
      } catch (error) {
        throw new Error(error.message);
      }
    },
    async updateNomineeDetails(
      context,
      {
        idPessoa,
        nome,
        cpf,
        rne,
        rg,
        emissorRg,
        dtEmissaoRg,
        dtNascimento,
        idLocalNascimento,
        ufLocalNascimento,
        nomeLocalNascimento,
        sexo,
        nomeMae,
        idGrauRelacionamento,
        dsGrauRelacionamento,
        complementoGrauRelacionamento,
        nrCelular,
        smsCelular,
        nrFixo,
        tpFixo,
        tpEmail,
        email,
        avisoEmail,
        tpRevalidacao
      }
    ) {
      checkUpdatePermission();
      try {
        const response = await EditarNomeado({
          vinculo: context.rootState.saude.vinculo,
          idPessoa,
          nome,
          cpf,
          rne,
          rg,
          emissorRg,
          dtEmissaoRg,
          dtNascimento,
          idLocalNascimento,
          ufLocalNascimento,
          nomeLocalNascimento,
          sexo,
          nomeMae,
          idGrauRelacionamento,
          dsGrauRelacionamento,
          complementoGrauRelacionamento,
          nrCelular,
          smsCelular,
          nrFixo,
          tpFixo,
          tpEmail,
          email,
          avisoEmail,
          tpRevalidacao
        });
        return response?.Data;
      } catch (error) {
        throw new Error(error.message);
      }
    },
    async updateNomieePeriod(context, { autenticacao, idPessoa, tpRevalidacao }) {
      checkUpdatePermission();
      try {
        const response = await Prorrogar({
          vinculo: context.rootState.saude.vinculo,
          autenticacao,
          idPessoa,
          tpRevalidacao
        });
        return response?.Data;
      } catch (error) {
        throw new Error(error.message);
      }
    }
  },
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    SET_DADOS_TITULAR: (state, dadosTitular) => {
      Vue.set(state, "dadosTitular", dadosTitular);
    },
    SET_NOMEADOS: (state, nomeados) => {
      Vue.set(state, "nomeados", nomeados);
    },
    SET_PARAMETROS: (state, parametros) => {
      Vue.set(state, "parametros", parametros);
    }
  }
};
