<template>
  <OmtLayoutContainer v-if="nonpaymentWarning">
    <OmtRow mb="12px">
      <OmtTypography as="h1-bold">
        <span v-html="nonpaymentWarning.title" />
      </OmtTypography>
    </OmtRow>

    <OmtRow mb="12px">
      <OmtTypography as="p1" class="nonpayment-terms">
        <span v-html="nonpaymentWarning.text" />
      </OmtTypography>
    </OmtRow>

    <FixedButtonRow>
      <OmtButton :w="['100%', '308px !important']" @click="continuar"> Ciente </OmtButton>
    </FixedButtonRow>
  </OmtLayoutContainer>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

export default {
  components: { FixedButtonRow },
  computed: {
    ...mapState("accessManagement", ["nonpaymentWarning"])
  },
  methods: {
    ...mapActions("accessManagement", ["nonpaymentAware"]),
    async continuar() {
      this.$root.$emit("show-loading");
      await this.nonpaymentAware();
      this.$router.replace("/");
    }
  },
  mounted() {
    this.$root.$emit("show-loading");
    if (this.nonpaymentWarning?.key) {
      this.$root.$emit("hide-loading");
    } else {
      this.$router.replace("/");
    }
  }
};
</script>

<style></style>
