<template>
  <span :class="myClass">
    <slot />
  </span>
</template>

<script>
export default {
  props: ["light"],
  computed: {
    myClass() {
      return `${this.light ? "bold-light" : "bold"}`;
    }
  }
};
</script>

<style scoped>
.bold {
  font-weight: 700;
}
.bold-light {
  font-weight: 600;
}

.bold,
.bold-light {
  color: var(--colors-light-primary-gray1);
}
</style>
