<template>
  <omint-page-content v-if="questionario">
    <div v-if="questionario.Resultado">
      <omt-p v-html="questionario.Resultado.Texto"></omt-p>
      <br />
      <div v-if="questionario.Resultado.ContatoDrOmint">
        <omt-buttons-bar>
          <omt-button :on-click="redirecionarDrOmint">DR. OMINT DIGITAL<br />Orientação Médica por vídeo </omt-button>
        </omt-buttons-bar>
        <div v-if="!disabledDrOmintFone">
          <br />
          <omt-p>Ou se preferir informe um telefone para contato</omt-p>
          <omt-input-box
            type="text"
            placeholder="Telefone para contato"
            v-bind:value="telefoneContato"
            v-on:input="telefoneContato = $event"
            :onKeydown="apenasNumeros"
            :onKeyup="formatarTelefone"
            :autocomplete="false"
            :hasIcon="false"
          />
          <br />
          <omt-buttons-bar>
            <omt-button :on-click="ligar" :disabled="!telefoneValido()">
              DR. OMINT DIGITAL<br />Orientação Médica por Telefone
            </omt-button>
          </omt-buttons-bar>
        </div>
      </div>
      <div v-else>
        <br />
        <center>
          <i class="fa fa-6x fa-check-circle"></i>
        </center>
      </div>
    </div>
    <div v-else-if="questionario.MultiplasOpcoes">
      <omt-p>Marque abaixo todos os sintomas que você apresenta.</omt-p>
      <SwitchCheckBox
        v-for="o in questionario.Opcoes"
        :key="o.Id"
        :id="o.Id"
        :label="o.Texto"
        :onChange="
          val => {
            o.Acc = val;
          }
        "
        :rounded="true"
      />
      <omt-buttons-bar>
        <omt-button :on-click="multiplasRespostas"> Continuar </omt-button>
      </omt-buttons-bar>
    </div>
    <div v-else>
      <omt-p v-if="questionario.Pergunta.Id == 1">
        <omt-bold>
          Olá! Elaboramos um questionário com perguntas que identificam os <u>principais</u> sinais e sintomas da
          Covid-19.
        </omt-bold>
      </omt-p>
      <omt-p v-if="questionario.Pergunta.Id == 1">
        <omt-bold>
          Este formulário não pretende definir um diagnóstico. O objetivo é orientar você para falar com um médico,
          quando suas respostas indicarem essa necessidade. O médico irá avaliar sua condição e recomendar a melhor
          conduta.
        </omt-bold>
      </omt-p>
      <omt-p>{{ questionario.Pergunta.Texto }}</omt-p>
      <br />
      <omt-buttons-bar>
        <omt-button :onClick="respostaPrincipal">
          {{ questionario.Opcoes[0].Texto }}
        </omt-button>
        <omt-button :onClick="respostaSecundaria">
          {{ questionario.Opcoes[1].Texto }}
        </omt-button>
      </omt-buttons-bar>
    </div>
    <div v-if="questionario && questionario.Pergunta && questionario.Pergunta.Id == 1">
      <br /><br /><br /><br /><br /><br />
      <omt-p>
        <small>Este serviço está baseado nas seguintes fontes de informação:</small>
      </omt-p>
      <omt-p>
        <small>
          Real-time tracking of self-reported symptoms to predict potential COVID-19 - Cristina Menni, Ana M. Valdes,
          Maxim B. Freidin, Carole H. Sudre, Long H. Nguyen, David A. Drew, Sajaysurya Ganesh, Thomas Varsavsky, M.
          Jorge Cardoso, Julia S. El-Sayed Moustafa, Alessia Visconti, Pirro Hysi, Ruth C. E. Bowyer, Massimo Mangino,
          Mario Falchi, Jonathan Wolf, Sebastien Ourselin, Andrew T. Chan, Claire J. Steves & Tim D. Specto. Nature
          Medicine volume 26, pages1037–1040(2020)
        </small>
      </omt-p>
      <omt-p>
        <small>
          Rapid implementation of mobile technology for real-time epidemiology of COVID-19. avid A. Drew1,*, View ORCID
          ProfileLong H. Nguyen1,*, View ORCID ProfileClaire J. Steves2,3, Cristina Menni2, View ORCID ProfileMaxim
          Freydin2, Thomas Varsavsky4, View ORCID ProfileCarole H. Sudre4, View ORCID ProfileM. Jorge Cardoso4,
          Sebastien Ourselin4, View ORCID ProfileJonathan Wolf5, View ORCID ProfileTim D. Spector2,5,†, View ORCID
          ProfileAndrew T. Chan1,6,†,‡, COPE Consortium. Science 19 Jun 2020: Vol. 368, Issue 6497, pp. 1362-1367. DOI:
          10.1126/science.abc0473
        </small>
      </omt-p>
      <omt-p>
        <a class="link" href="https://science.sciencemag.org/highwire/powerpoint/746116" alt="Fonte de informação">
          <small>https://science.sciencemag.org/highwire/powerpoint/746116</small>
        </a>
      </omt-p>
    </div>
  </omint-page-content>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SwitchCheckBox from "@/components/Molecules/SwitchCheckBox";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "FormCovid19",
  components: { SwitchCheckBox },
  computed: {
    ...mapState("forms", ["idFormulario", "questionario"]),
    disabledDrOmintFone() {
      return true;
    }
  },
  data() {
    return {
      telefoneContato: ""
    };
  },
  methods: {
    ...mapActions("forms", ["perguntaInicial", "responder", "ligarDrOmint"]),
    telefoneValido() {
      return this.telefoneContato.length === 13 || this.telefoneContato.length === 14;
    },
    formatarTelefone() {
      let newValue = this.telefoneContato.replace("(", "");
      newValue = newValue.replace(")", "");
      newValue = newValue.replace("-", "");
      if (newValue.length < 3) {
        this.telefoneContato = `(${newValue}`;
      } else if (newValue.length < 7) {
        this.telefoneContato = `(${newValue.substring(0, 2)})${newValue.substring(2)}`;
      } else if (newValue.length < 11) {
        this.telefoneContato = `(${newValue.substring(0, 2)})${newValue.substring(2, 6)}-${newValue.substring(6)}`;
      } else {
        this.telefoneContato = `(${newValue.substring(0, 2)})${newValue.substring(2, 7)}-${newValue.substring(7)}`;
      }
    },
    apenasNumeros(event) {
      if (!this.checkSpecialKeys(event)) {
        if (this.telefoneContato.length > 13 || !this.checkNumberKeys(event)) {
          event.returnValue = false;
          event.preventDefault();
        }
      }
    },
    checkNumberKeys(event) {
      return (event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105);
    },
    checkSpecialKeys(event) {
      return !(
        event.keyCode !== 8 &&
        event.keyCode !== 46 &&
        event.keyCode !== 37 &&
        event.keyCode !== 38 &&
        event.keyCode !== 39 &&
        event.keyCode !== 40
      );
    },
    redirecionarDrOmint() {
      this.$router.replace({ path: "/redireciona-dr-omint" });
    },
    async ligar() {
      await this.$root.$emit("show-loading");
      let resposta = {};
      try {
        resposta = await this.ligarDrOmint({
          telefone: this.telefoneContato
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
      if (resposta && resposta.Data) {
        await Swal.fire({
          title: `Protocolo: ${resposta.Data.ProtocoloANS}`,
          text: "Sua solicitação foi encaminhada. Por favor, aguarde que em até 60 minutos o médico irá retornar para sua orientação.",
          confirmButtonText: "Ok",
          icon: "success"
        });
      }
    },
    async resposta(idResposta) {
      await this.$root.$emit("show-loading");
      try {
        await this.responder({
          resposta: idResposta,
          subRespostas: []
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    respostaPrincipal() {
      this.resposta(this.questionario.Opcoes[0].Id);
    },
    respostaSecundaria() {
      this.resposta(this.questionario.Opcoes[1].Id);
    },
    async multiplasRespostas() {
      await this.$root.$emit("show-loading");
      try {
        let marcados = [];
        this.questionario.Opcoes.forEach(element => {
          if (element.Acc) marcados.push(element.Id);
        });

        await this.responder({
          resposta: this.questionario.Resposta.Id,
          subRespostas: marcados
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
    }
  },
  async mounted() {
    await this.$root.$emit("show-loading");
    try {
      await this.perguntaInicial({ formulario: 1 });
    } finally {
      await this.$root.$emit("hide-loading");
    }
  }
};
</script>
<style scoped>
.link {
  overflow-wrap: break-word;
}
</style>
