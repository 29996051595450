<template>
  <OmtCard bg="var(--colors-light-primary-light-gray)">
    <OmtTypography as="h2-bold" mb="24px"> Orientações: </OmtTypography>
    <CFlex>
      <OmtIcons mr="16px" name="face-meh" size="1x" />
      <CBox>
        <OmtTypography as="h3-semi-bold" mb="4px"> Olhe para a câmera e mantenha uma expressão neutra </OmtTypography>
        <OmtTypography as="p2" mb="4px"> Tente não sorrir ou se movimentar durante a captura. </OmtTypography>
      </CBox>
    </CFlex>

    <OmtDivider />

    <CFlex>
      <OmtIcons mr="16px" name="eye" size="1x" />
      <CBox>
        <OmtTypography as="h3-semi-bold" mb="4px"> Deixe seu rosto visível </OmtTypography>
        <OmtTypography as="p2" mb="4px"> Não use óculos, chapéus, bonés ou algo que cubra o seu rosto. </OmtTypography>
      </CBox>
    </CFlex>

    <OmtDivider />

    <CFlex>
      <OmtIcons mr="16px" name="lightbulb" size="1x" />
      <CBox>
        <OmtTypography as="h3-semi-bold" mb="4px"> Vá para um local com boa iluminação </OmtTypography>
        <OmtTypography as="p2" mb="4px"> Evite ambientes totalmente escuros ou com excesso de luz. </OmtTypography>
      </CBox>
    </CFlex>

    <OmtDivider />

    <CFlex>
      <OmtIcons mr="16px" name="lock" size="1x" />
      <CBox>
        <OmtTypography as="h3-semi-bold" mb="12px"> Segurança e privacidade </OmtTypography>
        <OmtTypography as="p2" mb="4px">
          Sua imagem será armazenada com segurança e só será utilizada para confirmar sua identidade.
        </OmtTypography>
      </CBox>
    </CFlex>

    <OmtDivider />

    <CFlex>
      <OmtIcons mr="16px" name="circle-exclamation" size="1x" />
      <CBox>
        <OmtTypography as="h3-semi-bold" mb="4px"> Softwares necessários </OmtTypography>
        <OmtTypography as="p2" mb="4px" pl="14px">
          <ul>
            <li>iOS 11.0</li>
            <li>Android 5.1</li>
          </ul>
        </OmtTypography>
      </CBox>
    </CFlex>
  </OmtCard>
</template>

<script>
export default {};
</script>
