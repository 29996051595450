import JSEncrypt from "jsencrypt";

export interface IJse {
  encrypt(str: string): String | false;
}

export default class JseUtil implements IJse {
  jse: JSEncrypt;

  constructor() {
    const publicKey = process.env.VUE_APP_PUBLIC_KEY;
    this.jse = new JSEncrypt();
    this.jse.setPublicKey(publicKey);
  }

  encrypt(str: string): String | false {
    return this.jse.encrypt(str);
  }
}
