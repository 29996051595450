<template>
  <CPseudoBox
    v-bind="$attrs"
    v-on="$listeners"
    class="conteudo"
    :color="credential.CorFonte"
    :backgroundColor="credential.CorCredencial"
    borderLeft="8px solid"
    :borderColor="credential.CorBarra"
    minHeight="190px"
    borderRadius="8px"
    position="relative"
    width="100%"
    overflow="hidden"
    padding="1rem"
  >
    <OmtTypography as="h3-bold" mb="3" :color="credential.CorFonte" height="38px">{{
      credential.name.toLowerCase().replace(/\b\w/g, match => match.toUpperCase())
    }}</OmtTypography>

    <CGrid template-columns="repeat(2, 1fr)" gap="3" v-if="credential.isMdSv == true" mb="3">
      <CBox>
        <OmtTypography as="p2" mb="1" :color="credential.CorFonte">Credencial Mediservice</OmtTypography>
        <OmtTypography as="h3-bold" :color="credential.CorFonte">{{ credential.numberT }}</OmtTypography>
      </CBox>
      <CBox>
        <OmtTypography as="p2" mb="1" :color="credential.CorFonte">Padrão</OmtTypography>
        <OmtTypography as="h3-bold" :color="credential.CorFonte">{{ credential.accT }}</OmtTypography>
      </CBox>
    </CGrid>
    <CGrid template-columns="repeat(3, 1fr)" gap="3" v-if="credential.isMdSv == true">
      <CBox>
        <OmtTypography as="p2" mb="1" :color="credential.CorFonte">Validade</OmtTypography>
        <OmtTypography as="h3-bold" :color="credential.CorFonte">{{ credential.valid }}</OmtTypography>
      </CBox>
      <CBox>
        <OmtTypography as="p2" mb="1" :color="credential.CorFonte">Plano</OmtTypography>
        <OmtTypography as="h3-bold" :color="credential.CorFonte" height="38px">{{ credential.planT }}</OmtTypography>
      </CBox>
      <CBox>
        <OmtTypography as="p2" mb="1" :color="credential.CorFonte">Assistência</OmtTypography>
        <OmtTypography as="h3-bold" :color="credential.CorFonte">{{
          credential.accTp.replace("ASSISTÊNCIA MÉDICO-", "")
        }}</OmtTypography>
      </CBox>
    </CGrid>
    <CGrid template-columns="repeat(7, 1fr)" gap="3" v-else>
      <CGridItem colSpan="3">
        <OmtTypography as="p2" mb="1" :color="credential.CorFonte">Credencial Omint</OmtTypography>
        <OmtTypography as="h3-bold" :color="credential.CorFonte">{{ credential.number }}</OmtTypography>
      </CGridItem>
      <CGridItem colSpan="2">
        <OmtTypography as="p2" mb="1" :color="credential.CorFonte">Nascimento</OmtTypography>
        <OmtTypography as="h3-bold" :color="credential.CorFonte">{{ credential.birth }}</OmtTypography>
      </CGridItem>
      <CGridItem colSpan="2">
        <OmtTypography as="p2" mb="1" :color="credential.CorFonte">Validade</OmtTypography>
        <OmtTypography as="h3-bold" :color="credential.CorFonte">{{ credential.valid }}</OmtTypography>
      </CGridItem>
      <CGridItem colSpan="7">
        <OmtTypography as="p2" mb="1" :color="credential.CorFonte">{{ credential.typePlan }}</OmtTypography>
        <OmtTypography as="h3-bold" :color="credential.CorFonte" height="38px"
          >{{ credential.plan }} - {{ credential.descCreMed }}</OmtTypography
        >
      </CGridItem>
    </CGrid>
  </CPseudoBox>
</template>
<script>
export default {
  name: "Credential",
  props: {
    credential: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    name() {
      if (!this.credential?.name) return "";
      return this.credential.name
        .split(" ")
        .map(n => `${n.charAt(0).toUpperCase()}${n.substring(1).toLowerCase()}`)
        .join()
        .replaceAll(",", " ");
    }
  }
};
</script>
