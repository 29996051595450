<template>
  <div id="profile" class="profile">
    <div class="profile-img" @click.prevent="onClick">
      <img class="avatar" :src="profilePic" v-if="profilePic !== ''" />
      <div v-else class="avatar">
        <i class="fa fa-user fa-5x"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["profilePic"],
  computed: {
    ...mapState("biometrics", ["hasBiometric", "shoudUseBiometric", "listenersRegistred"]),
    ...mapState("saude", ["hydrated"])
  },
  methods: {
    onClick() {
      this.$emit("showPicker");
    }
  }
};
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-img {
  display: flex;
  width: 80px;
  height: 80px;
  cursor: pointer;
  text-align: center;

  .avatar i {
    width: 80px;
    height: 80px;
  }
}
</style>
