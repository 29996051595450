import Vue from "vue";
import {
  ListarDocumentos,
  ListarGeral,
  NovaAutorizacao,
  ValidarNovaAutorizacao,
  ObterArquivoAutorizacao,
  ObterDevolucaoOcorrencia,
  ObterDocumentos,
  ObterLimiteArquivos,
  ObterPendenciasAutorizacao,
  ObterPlanoPrazoRetorno,
  RetornoPendenciaConvocacao
} from "../api/autorizacao";
import * as associado from "../api/associado";
import { checkUpdatePermission } from "@/utils/checkReadonlyUtil";

const dateTypes = [
  { value: "1", label: "Últimos 30 dias" },
  { value: "2", label: "Últimos 60 dias" },
  { value: "3", label: "2 últimos anos" }
];

const initialState = {
  auths: [],
  listOption: "",
  beneficiaries: [],
  filters: null,
  maxFiles: 0,
  dueDate: 0,
  dueDates: [],
  newAuth: null,
  newAuthSuccess: false
};

// vuex module
export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions: {
    async fetchAuthDocuments(context, { auth, ticket }) {
      const response = await ListarDocumentos({
        vinculo: context.rootState.saude.vinculo,
        autorizacao: auth,
        chamado: ticket
      });
      return response && response instanceof Error ? [] : response?.Data ?? [];
    },
    async fetchMaxFiles(context) {
      if (context.state.maxFiles > 0) return context.state.maxFiles;

      const response = await ObterLimiteArquivos({
        vinculo: context.rootState.saude.vinculo
      });
      if (response?.Data > 0) {
        context.commit("SET_MAX_FILES", response.Data);
        return response.Data;
      }
      return 6;
    },
    async fetchDueDate(context) {
      const response = await ObterPlanoPrazoRetorno({
        vinculo: context.rootState.saude.vinculo,
        tipoEvento: context.state.newAuth?.selectedEvento?.toUpperCase()
      });
      if (response?.Data && response?.Data.length > 0) {
        context.commit("SET_DUE_DATES", response.Data);
        return response.Data;
      }
      return [];
    },
    async fetchReasons(context, { autorizacao }) {
      const response = await ObterDevolucaoOcorrencia({
        vinculo: context.rootState.saude.vinculo,
        autorizacao: autorizacao
      });
      return response?.Data ?? [];
    },
    async fetchPendencies(context, { autorizacao }) {
      const response = await ObterPendenciasAutorizacao({
        vinculo: context.rootState.saude.vinculo,
        idAutorizacao: autorizacao
      });
      return response instanceof Error ? [] : response ?? [];
    },
    async submitPendencieResponse(context, { autorizacao, chamado, observacao, anexos }) {
      checkUpdatePermission();
      const response = await RetornoPendenciaConvocacao({
        vinculo: context.rootState.saude.vinculo,
        autorizacao,
        chamadoConvocacao: chamado,
        observacao,
        anexos
      });
      if (response instanceof Error) throw new Error(response.message);
      if (!response?.length) throw new Error("Não foi possível registrar o retorno da convocação de pendência");
      return Array.isArray(response) ? response.join(", ") : response;
    },
    async listAuths(context) {
      const auths = await ListarGeral({
        vinculo: context.rootState.saude.vinculo,
        periodo: 3
      });

      if (auths.Data?.length) {
        context.commit("SET_AUTHS", auths.Data);
        if (context.state.listOption === "") {
          context.commit("SET_LIST_OPTION", `${auths.Data[0].tpPeriodo}`);
        }
      } else if (!context.state.auths?.length) {
        context.commit("SET_AUTHS", []);
      }
    },
    async initBeneficiaries(context) {
      try {
        const response = await associado.ObterBeneficiariosTitulo({
          vinculo: context.rootState.saude.vinculo,
          dependentes: true,
          servico: 0
        });
        const beneficiaries = response instanceof Error ? [] : response.Data;

        await context.commit("SET_BENEFICIARIES", beneficiaries);
      } catch (error) {
        throw Error(error);
      }
    },
    async createNewAuth(context, { obs }) {
      checkUpdatePermission();
      try {
        const response = await NovaAutorizacao({
          vinculo: context.rootState.saude.vinculo,
          beneficiario: context.state.newAuth?.selectedBeneficiario,
          evento: context.state.newAuth?.selectedEvento?.toUpperCase(),
          dataEvento: context.state.newAuth?.dateEvento,
          localEvento: context.state.newAuth?.localEvento,
          observacao: obs,
          anexos: context.state.newAuth?.anexos?.filter(a => !!a.source)?.map(a => a.source)
        });
        return response?.Data?.protocoloANS;
      } catch (error) {
        throw new Error(error.message);
      }
    },
    async hasRecentlyeNewAuth(context, { type }) {
      try {
        const response = await ValidarNovaAutorizacao({
          vinculo: context.rootState.saude.vinculo,
          evento: type?.toUpperCase()
        });
        if (response instanceof Error) return false;
        return !response;
      } catch (error) {
        return false;
      }
    },
    async getAuthFileList(context, { autorizacao }) {
      try {
        const response = await ObterDocumentos({
          vinculo: context.rootState.saude.vinculo,
          autorizacao
        });
        const docsRaw = response instanceof Error ? [] : response?.Data ?? [];
        return docsRaw.map(d => ({
          id: d.Id,
          execucao: d.Execucao,
          diaria: d.Diaria,
          validade: d.Validade,
          url: ObterArquivoAutorizacao({
            vinculo: context.rootState.saude.vinculo,
            autorizacao,
            detalhe: d.Id
          })
        }));
      } catch {
        return [];
      }
    }
  },
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    SET_AUTHS: (state, nextAuths) => {
      Vue.set(state, "auths", nextAuths);
      const dateOptions = [];
      const statusOptions = [];
      for (let i = 0; i < dateTypes.length; i++) {
        const tpPeriodo = Number.parseInt(dateTypes[i].value);
        const filtered = nextAuths.filter(auth => auth.tpPeriodo <= tpPeriodo);
        if (filtered?.length) {
          const status = [];
          filtered.forEach(item => {
            if (!status.find(s => s === item.dsSituacao)) {
              status.push(item.dsSituacao);
              const option = statusOptions.find(status => status.label === item.dsSituacao);
              if (option) {
                option.dates.push(dateTypes[i].value);
              } else {
                statusOptions.push({
                  label: item.dsSituacao,
                  isActive:
                    state.filters?.status?.data?.find(current => current.label === item.dsSituacao)?.isActive ?? false,
                  dates: [dateTypes[i].value]
                });
              }
            }
          });
          dateOptions.push(dateTypes[i]);
        }
      }
      const filters = {
        beneficiaries: {
          data: [...new Set(state.auths.map(item => item.dsBeneficiario))],
          value: state.filters?.beneficiaries?.value ?? ""
        },
        dates: dateOptions,
        status: {
          data: statusOptions
        }
      };
      Vue.set(state, "filters", filters);
    },
    SET_LIST_OPTION: (state, option) => {
      Vue.set(state, "listOption", option);
    },
    SET_BENEFICIARIES: (state, value) => {
      Vue.set(state, "beneficiaries", value);
    },
    SET_FILTERS: (state, value) => {
      Vue.set(state, "filters", value);
    },
    RESET_FILTERS: state => {
      const auths = state.auths;
      const dateOptions = [];
      const statusOptions = [];
      for (let i = 0; i < dateTypes.length; i++) {
        const tpPeriodo = Number.parseInt(dateTypes[i].value);
        const filtered = auths.filter(auth => auth.tpPeriodo <= tpPeriodo);
        if (filtered?.length) {
          const status = [];
          filtered.forEach(item => {
            if (!status.find(s => s === item.dsSituacao)) {
              status.push(item.dsSituacao);
              const option = statusOptions.find(status => status.label === item.dsSituacao);
              if (option) {
                option.dates.push(dateTypes[i].value);
              } else {
                statusOptions.push({
                  label: item.dsSituacao,
                  isActive: false,
                  dates: [dateTypes[i].value]
                });
              }
            }
          });
          dateOptions.push(dateTypes[i]);
        }
      }

      const filters = {
        beneficiaries: {
          data: [...new Set(auths.map(item => item.dsBeneficiario))],
          value: ""
        },
        dates: dateOptions,
        status: {
          data: statusOptions
        }
      };
      Vue.set(state, "filters", filters);
    },
    SET_MAX_FILES: (state, maxFiles) => Vue.set(state, "maxFiles", maxFiles),
    SET_DUE_DATE: (state, dueDate) => Vue.set(state, "dueDate", dueDate),
    SET_DUE_DATES: (state, dueDates) => Vue.set(state, "dueDates", dueDates),
    SET_NEW_AUTH: (state, newAuth) => Vue.set(state, "newAuth", newAuth),
    SET_SUCCESS_NEW_AUTH: (state, newAuthSuccess) => {
      Vue.set(state, "newAuthSuccess", newAuthSuccess);
      Vue.set(state, "newAuth", null);
    }
  }
};
