<template>
  <CBox bg="var(--colors-light-primary-light-gray)" p="16px" mt="24px" borderRadius="8px">
    <template v-if="accounts.length">
      <CFlex v-for="(conta, index) in accounts" :key="index">
        <OmtIcons
          name="building-columns"
          height="16px"
          width="16px"
          mr="16px"
          :font-size="['16px', '20px']"
          size="inherit"
        />
        <CBox>
          <OmtTypography as="h3-bold" mb="12px"> {{ conta.ds_banco }} </OmtTypography>
          <OmtTypography as="p2" mb="8px" color="var(--colors-light-primary-gray2)">
            Conta corrente {{ conta.nr_conta }} {{ conta.at_dac ? `-${conta.at_dac}` : "" }} • Agência
            {{ conta.cd_agencia }}
          </OmtTypography>
          <CBox v-if="conta.tp_principal === 'S'">
            <omt-divider />
            <CFlex>
              <OmtIcons
                name="check-circle"
                height="16px"
                width="16px"
                size="lg"
                color="var(--colors-light-support-success)"
                display="flex"
                alignItems="center"
                mr="8px"
              />
              <OmtTypography as="p2" mb="16px"> Conta principal para crédito </OmtTypography>
            </CFlex>
          </CBox>
        </CBox>
      </CFlex>
    </template>
    <CBox v-else>
      <CFlex>
        <OmtIcons name="lightbulb" height="16px" width="16px" mr="8px" :font-size="['16px', '20px']" size="inherit" />
        <OmtTypography as="h2-bold" mb="16px" mt="8px"> Atenção: </OmtTypography>
      </CFlex>
      <OmtTypography as="p1-bold" mb="12px" mt="8px">
        Não identificamos nenhuma conta corrente cadastrada.
      </OmtTypography>
    </CBox>
  </CBox>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState("saude", ["vinculo"]),
    ...mapState("bankAccount", ["isChanging", "accounts", "selectedBeneficiary"])
  },
  methods: {
    ...mapActions("bankAccount", ["hasActiveTicket", "fetchAccounts"]),
    ...mapMutations("bankAccount", ["SET_SELECTED_BENEFICIARY"])
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      const beneficiario = this.vinculo !== null && this.vinculo.length === 10 ? this.vinculo.substring(8, 10) : "00";
      if (this.selectedBeneficiary !== beneficiario) {
        this.SET_SELECTED_BENEFICIARY(beneficiario);
        await Promise.all([
          this.hasActiveTicket({ beneficiaryCode: beneficiario }),
          this.fetchAccounts({ beneficiaryCode: beneficiario })
        ]);
      } else {
        await this.hasActiveTicket({ beneficiaryCode: beneficiario });
      }
      if (this.existChangeRequest) {
        // não pode editar pois já existe um pedido de alteração
        this.$root.$emit("change-welcome-tour", { accessLabel: "", nextLabel: "Continuar" });
      } else if (this.accounts?.length) {
        this.$root.$emit("change-welcome-tour", { accessLabel: "Editar Conta Corrente", nextLabel: "Continuar" });
      }
    } catch (error) {
      this.$toast({
        description: error.message,
        status: "error",
        duration: 10000,
        position: "bottom-right"
      });
    } finally {
      this.$root.$emit("hide-loading");
    }
  }
};
</script>
<style scoped></style>
