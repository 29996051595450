<template>
  <div class="app-loading">
    <img :src="logoImg" />
    <p>{{ step }}</p>
  </div>
</template>

<script>
import { ROTA_PADRAO } from "@/utils/perfilRouteUtil";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapState("saude", ["vinculo", "vinculos"]),
    ...mapGetters("biometrics", ["onNative"]),
    iOS() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    defaultType() {
      return this.$route.name === "redirect" ? "Omint" : "readonly-access";
    },
    nextUrl() {
      let rota = this.getParam("rota");
      if (rota) return rota === "/home" ? "/" : rota;
      rota = this.getParam("nextUrl");
      return rota ? rota : "";
    },
    fullUrl() {
      const cd1 = this.getParam("cd1");
      const cd2 = this.getParam("cd2");
      const cd3 = this.getParam("cd3");
      if (cd1 && cd2 && cd3)
        return `cd1=${cd1}&cd2=${cd2}&cd3=${cd3}&cd4=${this.getParam("cd4")}&cd5=${this.getParam(
          "cd5"
        )}&cd6=${this.getParam("cd6")}&cd7=${this.getParam("cd7")}&cd55=${this.getParam("cd55")}&p22=${this.getParam(
          "p22"
        )}&k66=${this.getParam("k66")}`;

      return null;
    },
    vinculoUrl() {
      return this.getParam("vinculo") ?? this.getParam("cd3");
    },
    loginUrl() {
      return this.getParam("login") ?? this.getParam("cd6");
    },
    usuarioUrl() {
      return this.getParam("usuario") ?? this.getParam("cd55");
    },
    logoImg() {
      return process.env.VUE_APP_BASE_URL + "images/omint/logo-omint-app.png";
    }
  },
  data() {
    return {
      step: "Validando dados para autenticação..."
    };
  },
  async mounted() {
    if (
      "localhost:8080" !== document.location.host &&
      (this.iOS || !this.onNative) &&
      "usuario" in this.$route.query &&
      "login" in this.$route.query &&
      "vinculo" in this.$route.query
    ) {
      try {
        const updated = await this.forceSWupdate();
        if (updated) {
          window.location.reload(true);
          setTimeout(() => this.validateParams(), 5000);
        } else {
          await this.validateParams();
        }
      } catch (err) {
        window.$appInsights.trackException(err, { serviceWorkerUpdate: true });
        setTimeout(() => this.validateParams(), 1000);
      }
    } else {
      await this.validateParams();
    }
  },
  methods: {
    ...mapActions("saude", ["loginUser", "forceSWupdate", "setVinculo"]),
    getParam(name) {
      if (this.$route.query[name]) return this.$route.query[name];
      if (this.$route.params[name]) return this.$route.params[name];
      return "";
    },
    async validateParams() {
      await this.$root.$emit("hide-loading");
      if (!navigator.onLine || (!this.fullUrl && !(this.vinculoUrl && this.loginUrl && this.usuarioUrl))) {
        this.$router.replace("/login");
        return;
      }
      let nextPage = await this.loginUser({
        username: this.fullUrl ?? this.valLogin,
        password: this.fullUrl ? "OmintFull" : `${this.loginUrl};${this.usuarioUrl};${this.vinculoUrl}`,
        type: this.fullUrl ? null : this.defaultType
      });

      if (nextPage !== ROTA_PADRAO && this.vinculoUrl !== this.vinculo) {
        this.step = "Identificando vinculo...";
        const dadosVinculo = {};
        this.vinculos.forEach(operacaoVinculo =>
          operacaoVinculo.Vinculos.forEach(v => {
            if (v.NumeroIdentificacaoVinculo === this.vinculoUrl) {
              dadosVinculo.operacao = operacaoVinculo.IdOperacaoOmint;
              dadosVinculo.perfil = operacaoVinculo.IdPerfilOmint;
              dadosVinculo.vinculo = v.NumeroIdentificacaoVinculo;
            }
          })
        );
        nextPage =
          dadosVinculo.vinculo === this.vinculoUrl
            ? await this.setVinculo({
                operacao: dadosVinculo.operacao,
                perfil: dadosVinculo.perfil,
                vinculo: dadosVinculo.vinculo
              })
            : ROTA_PADRAO;
      }

      if (nextPage === ROTA_PADRAO) {
        // não autorizado
        this.step = "Não autorizado";
        if (this.nextUrl) {
          this.$router.replace({
            path: "/login",
            query: {
              nextUrl: this.nextUrl
            }
          });
        } else {
          this.$router.replace("/login");
        }
        return;
      }
      this.step = "Redirecionando...";
      this.$router.replace(this.nextUrl ? this.nextUrl : nextPage);
    }
  }
};
</script>
<style scoped></style>
