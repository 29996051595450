<template>
  <CBox w="100%" :display="['none', 'flex']" v-bind="$attrs" v-on="$listeners">
    <OmtButton
      variant="outline"
      border="1px solid var(--colors-light-secondary-divider)"
      height="40px"
      width="40px"
      min-width="40px"
      @click="PrevButtonClick"
      :disabled="disabledPrev"
      :class="prev"
    >
      <OmtIcons name="arrow-left" size="lg" />
    </OmtButton>
    <OmtButton
      variant="outline"
      border="1px solid var(--colors-light-secondary-divider)"
      height="40px"
      width="40px"
      min-width="40px"
      ml="8px"
      @click="NextButtonClick"
      :disabled="disabledNext"
      :class="next"
    >
      <OmtIcons name="arrow-right" size="lg" />
    </OmtButton>
  </CBox>
</template>
<script>
export default {
  props: {
    prev: {
      type: String,
      default: ""
    },
    next: {
      type: String,
      default: ""
    },
    disabledPrev: {
      type: Boolean,
      default: false
    },
    disabledNext: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    PrevButtonClick() {
      this.$emit("on-prev-button-click");
    },
    NextButtonClick() {
      this.$emit("on-next-button-click");
    }
  }
};
</script>
