<template>
  <OmtStickedModal v-if="isOpen" @close="close" data-cy="forgot-new-password-modal">
    <OmtIcons name="check-circle" size="3x" color="var(--colors-light-support-success)" mb="32px" />
    <OmtTypography as="h4-bold" mb="16px">Cadastro realizado com sucesso!</OmtTypography>

    <CBox borderRadius="8px" backgroundColor="var(--colors-light-primary-light-gray)" p="16px" mb="24px">
      <CFlex>
        <CBox>
          <OmtIcons mr="16px" size="lg" name="mobile" />
        </CBox>
        <OmtTypography as="h2-bold" mb="16px">Seu acesso já está liberado</OmtTypography>
      </CFlex>
      <OmtTypography as="p1">Realize seu login e aproveite todas as facilidades do App Minha Omint.</OmtTypography>
    </CBox>
    <template #footer>
      <OmtButton
        variant="solid"
        width="100%"
        height="50px"
        border="0.5px solid var(--colors-light-secondary-divider)"
        borderRadius="40px"
        data-cy="button-entrar"
        @click="save"
        mb="3"
      >
        <OmtTypography as="h1-bold" color="white">Concluir</OmtTypography>
      </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
/**
 * @prop {Boolean} isOpen
 */
export default {
  props: {
    isOpen: {
      Type: Boolean
    }
  },
  methods: {
    close() {
      this.$emit("update:isOpen", false);
    },
    save() {
      this.$emit("continue");
      this.close();
    }
  }
};
</script>
