<template>
  <div>
    <c-modal :is-open="isOpen" :closeOnOverlayClick="false" is-centered>
      <c-modal-content ref="content">
        <c-modal-header>
          <i class="icon fas fa-exclamation-circle fa-2x"></i>
          <strong>Atenção!</strong>
        </c-modal-header>
        <c-modal-body>
          <omt-p>Deseja realmente exluir a sua conta?</omt-p>
        </c-modal-body>
        <c-modal-footer>
          <omt-button class="cancel" :on-click="() => handleClick('cancel')">CANCELAR</omt-button>
          <omt-button class="save dark" :on-click="() => handleClick('confirm')">EXCLUIR</omt-button>
        </c-modal-footer>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </div>
</template>

<script>
import {
  CModal,
  CModalBody,
  CModalCloseButton,
  CModalContent,
  CModalFooter,
  CModalHeader,
  CModalOverlay
} from "@chakra-ui/vue";
import OmtB from "@/components/Atoms/omt-b";

export default {
  name: "Modal",
  components: {
    OmtB,
    CModal,
    CModalOverlay,
    CModalContent,
    CModalHeader,
    CModalFooter,
    CModalBody,
    CModalCloseButton
  },
  props: ["isOpen"],
  methods: {
    handleClick(evt) {
      this.$emit(evt);
    }
  }
};
</script>

<style scoped lang="scss">
.icon {
  width: 2rem !important;
  height: 2rem !important;

  margin-bottom: 1rem;
}

::v-deep header[data-chakra-component="CModalHeader"] {
  font-weight: 600;
  line-height: 24px;
  color: var(--colors-light-primary-gray1);

  padding-left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

::v-deep div[data-chakra-component="CModalBody"] {
  padding: 0;
  text-align: center;
  margin: 1rem 0 1.5rem;

  font-weight: 400 !important;
  font-size: 0.75rem;
  line-height: 16px;
  color: var(--colors-light-primary-gray1);
}

::v-deep footer[data-chakra-component="CModalFooter"] {
  padding-top: 0;
}
</style>
