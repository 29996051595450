<template>
  <CBox>
    <CBox
      padding="0"
      margin-top="78px"
      margin-bottom="44px"
      mx="auto"
      width="calc(100vw - 324px)"
      max-width="1024px"
      :display="['none', 'block']"
    >
      <OmtStepper :currentStep="step" :totalSteps="refundCreationRoutesNames.length" />
    </CBox>
    <router-view></router-view>
  </CBox>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      step: 1
    };
  },
  watch: {
    $route(route) {
      this.step = this.refundCreationRoutesNames.indexOf(route.name) + 1;
    }
  },
  mounted() {
    if ("newForm" in this.$route.params) {
      this.SET_NEW_REFUND({});
    }
  },
  computed: {
    refundCreationRoutesNames() {
      return [
        "refund.new.create",
        "refund.new.create.terms",
        "refund.new.create.credit-inform",
        "refund.new.create.pictures-upload",
        "refund.new.create.comments"
      ].filter(Boolean);
    }
  },
  methods: {
    ...mapMutations("refund", ["SET_NEW_REFUND"])
  }
};
</script>
