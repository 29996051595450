<template>
  <omint-page>
    <Header name="ExternalLink" backRoute="/" :titleLabel="name" />
    <div class="outer" v-show="!loading">
      <iframe
        id="externalLink"
        class="inner"
        :src="link"
        v-on:load="fileLoaded"
        frameborder="0"
        allowfullscreen
        cookieSameSite="None"
        :width="width"
        :height="height"
      ></iframe>
    </div>
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";

export default {
  components: { Header },
  props: ["link", "name"],
  name: "ExternalLink",
  data() {
    return {
      loading: true,
      width: 0,
      height: 0,
      lastName: ""
    };
  },
  methods: {
    fileLoaded() {
      this.loading = false;
      this.$root.$emit("hide-loading");
    },
    onResizeHandler() {
      this.width = window.innerWidth;
      this.height = window.innerHeight - 70;
    },
    setLoading() {
      this.lastName = this.name;
      this.loading = true;
      this.$root.$emit("show-loading");
      this.onResizeHandler();
      setTimeout(this.fileLoaded, 5000);
    }
  },
  created() {
    window.addEventListener("resize", this.onResizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResizeHandler);
  },
  mounted() {
    if (!this.link) {
      this.$router.back();
    }
    this.setLoading();
  },
  updated() {
    if (this.name !== this.lastName) {
      this.setLoading();
    }
  }
};
</script>
<style scoped>
.outer {
  width: 100vw;
  height: calc(100vh - 70px);
  overflow-x: hidden;
  overflow-y: hidden;
}
.inner {
  width: calc(100vw + 2px);
  height: calc(100vh + 2px);
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
