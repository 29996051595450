<template>
  <textarea class="textarea" v-bind:value="value" v-on:input="emitEvent" v-bind="$attrs" />
</template>
<script>
export default {
  props: {
    value: {
      required: true
    }
  },
  methods: {
    emitEvent(event) {
      this.$emit("update:value", event.target.value);
      this.$emit("input", event.target.value);
    }
  }
};
</script>
<style scoped>
.textarea {
  font-family: "Open Sans", sans-serif;
  border: 1px solid transparent;
  width: 100%;
  min-height: 23px;
  padding-left: 2px;
  padding-top: 0px;
  font-size: 12px;
}

.big-font-size .textarea {
  font-size: 18px;
}

.dark .textarea {
  background-color: #1f1f1f;
  color: #ccd1d9;
}

.textarea:hover,
.textarea:focus {
  outline: none;
}

.textarea::placeholder {
  opacity: 1;
}

.textarea[disabled="disabled"] {
  opacity: 0.55;
}

.textarea {
  background-color: white;
  border: 0.5px solid #e4e4e4;
}

.textarea::placeholder,
.textarea:-ms-input-placeholder,
.textarea::-ms-input-placeholder {
  color: white;
}

.textarea.inverse::placeholder,
.textarea.inverse:-ms-input-placeholder,
.textarea.inverse::-ms-input-placeholder {
  color: var(--colors-light-primary-gray1);
}

.dark .textarea::placeholder,
.dark .textarea:-ms-input-placeholder,
.dark .textarea::-ms-input-placeholder {
  color: #ccd1d9;
}

.dark .textarea.inverse::placeholder,
.dark .textarea.inverse:-ms-input-placeholder,
.dark .textarea.inverse::-ms-input-placeholder {
  color: #1f1f1f;
}

.light .textarea:hover,
.light .textarea:focus {
  background-color: white;
}

.dark .textarea:hover,
.dark .textarea:focus {
  background-color: #1f1f1f;
}
</style>
