<template>
  <OmtGrid :minHeight="['auto', '629px']">
    <OmtRow mb="24px">
      <OmtTypography as="h1-bold"> Informações para crédito </OmtTypography>
    </OmtRow>

    <OmtRow :desktopStart="7">
      <CBox>
        <CBox mb="24px">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">
            Beneficiário que utilizou o(s) serviço(s)
          </OmtTypography>
          <OmtSelect :initialValue="ben" placeholder="Selecione o beneficiário" @change="onBeneficiaryChange">
            <option v-for="(beneficiary, index) in beneficiariesOptions" :key="index" :value="beneficiary.Beneficiario">
              {{ beneficiary.Nome }}
            </option>
          </OmtSelect>
        </CBox>

        <CBox mb="24px">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> Favorecido para crédito </OmtTypography>
          <OmtSelect :initialValue="favorecido" placeholder="Selecione o favorecido" @change="onFavorecidoChange">
            <option v-for="(favorecido, index) in favorecidosOptions" :key="index" :value="favorecido.nome">
              {{ favorecido.nome }}
            </option>
          </OmtSelect>
        </CBox>

        <CBox mb="24px">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> Conta corrente para crédito </OmtTypography>
          <OmtSelect :initialValue="acc" placeholder="Selecione a conta corrente" @change="onBankAccountChange">
            <option v-for="(account, index) in accountsOptions" :key="index" :value="account.value.id">
              {{ account.label }}
            </option>
          </OmtSelect>
        </CBox>
      </CBox>
    </OmtRow>

    <OmtRow :desktopStart="1" :desktopEnd="6" :gridRowStart="['initial', '2']">
      <CBox
        borderRadius="8px"
        bg="var(--colors-light-primary-light-gray)"
        p="12px 16px"
        cursor="pointer"
        @click="showModal = true"
      >
        <CFlex mb="16px">
          <OmtIcons name="flask" mr="14px" size="lg" />
          <OmtTypography as="h2-bold"> Evite atrasos na liberação do seu reembolso </OmtTypography>
        </CFlex>
        <OmtTypography as="p1" mb="16px">
          Confira os documentos e informações necessárias de acordo com o serviço prestado.
        </OmtTypography>

        <CFlex align-items="center">
          <OmtTypography as="button-small" color="var(--colors-light-primary-navy-blue)" mr="6px">
            Confira Aqui
          </OmtTypography>
          <OmtIcons name="chevron-right" color="var(--colors-light-primary-navy-blue)" />
        </CFlex>
      </CBox>
    </OmtRow>

    <OmtRow align-items="flex-end" pb="18px" pt="24px" bg="white" :desktopStart="4" :desktopEnd="9">
      <OmtButton @click="submit" :disabled="!canSubmit"> Continuar </OmtButton>
    </OmtRow>

    <RefundCreateCreditInformModal :isOpen.sync="showModal" />
  </OmtGrid>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import RefundCreateCreditInformModal from "@/components/Organisms/RefundNew/RefundCreateCreditInformModal.vue";

export default {
  components: {
    RefundCreateCreditInformModal
  },
  data() {
    return {
      showModal: false,
      ben: "",
      favorecido: "",
      validAcc: false,
      acc: ""
    };
  },
  async mounted() {
    if (!this.newRefund || this.newRefund.type === null) {
      this.$router.push({ name: "refund.new.create" });
      return;
    }
    try {
      if (!this.addPermission) {
        this.$router.push({ name: "refund.new" });
        return;
      }

      await this.$root.$emit("show-loading");
      if ("contacorrente" in this.newRefund) {
        const { beneficiario, favorecido, contacorrente } = this.newRefund;
        this.ben = beneficiario;
        this.favorecido = favorecido;
        this.acc = contacorrente;
        if (contacorrente) this.onBankAccountChange(contacorrente);
      } else {
        await this.fetchBeneficiaries();
        if (this.beneficiariesOptions.length === 1) {
          this.onBeneficiaryChange(this.beneficiariesOptions[0].Beneficiario);
        }
      }
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  computed: {
    ...mapState("refund", ["beneficiaries", "newRefund", "addPermission"]),
    beneficiariesOptions() {
      if (!this.beneficiaries) return [];
      return Object.keys(this.beneficiaries).map(b => this.beneficiaries[b]);
    },
    favorecidosOptions() {
      if (!this.beneficiaries) return [];
      const favorecidos = [];
      Object.keys(this.beneficiaries).forEach(b => {
        if (this.beneficiaries[b]?.ContasCredito?.length) {
          this.beneficiaries[b].ContasCredito.forEach(c => {
            let favorecido = favorecidos.find(f => f.nome === c.ds_pessoa);
            if (!favorecido) {
              favorecido = {
                nome: c.ds_pessoa,
                contas: []
              };
              favorecidos.push(favorecido);
            }
            const label = `${c.ds_banco} / ${c.cd_agencia} / ${c.nr_conta}`;
            if (!favorecido.contas.find(f => f.label === label))
              favorecido.contas.push({
                label,
                value: { id: `${c.id_ctacredito}`, confiavel: c.confiavel, validada: c.validada }
              });
          });
        }
      });
      return favorecidos;
    },
    accountsOptions() {
      if (!this.favorecido) return [];
      return this.favorecidosOptions.find(b => b.nome === this.favorecido)?.contas ?? [];
    },
    canSubmit() {
      return !!this.ben && !!this.acc && this.validAcc;
    }
  },
  methods: {
    ...mapActions("refund", ["fetchBeneficiaries"]),
    ...mapMutations("refund", ["SET_NEW_REFUND"]),
    onBeneficiaryChange(val) {
      this.ben = val;
      if (this.accountsOptions.length === 1 && !this.favorecido) {
        this.onFavorecidoChange(this.beneficiariesOptions[0].Beneficiario);
      }
    },
    onFavorecidoChange(val) {
      this.favorecido = val;
      this.onBankAccountChange(this.accountsOptions.length === 1 ? this.accountsOptions[0].value.id : "");
    },
    onBankAccountChange(id) {
      if (!this.favorecido || !id) {
        this.validAcc = false;
        this.acc = "";
        return;
      }
      const { value } = this.accountsOptions.find(account => account.value.id === id);
      this.acc = id;
      this.validAcc = value.confiavel || value.validada;
      if (!this.validAcc) {
        this.accRaw = null;
        this.$toast({
          description:
            "Para primeira utilização dessa conta, contate nossa Central de Atendimento 0800 726 4000 e libere.",
          status: "warning",
          duration: 10000,
          position: "top"
        });
      }
    },
    submit() {
      if (!this.canSubmit) return;

      this.SET_NEW_REFUND({
        ...this.newRefund,
        beneficiario: this.ben,
        favorecido: this.favorecido,
        contacorrente: this.acc
      });
      this.$router.push({
        name: "refund.new.create.pictures-upload"
      });
    }
  }
};
</script>
