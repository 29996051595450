<template>
  <div>
    <Header name="Indicação de Rede" titleLabel="Indicação de Rede" backRoute="/redecredenciada-home" />
    <omint-page-content>
      <CBox v-if="this.message !== ''">
        <InfoBox :message="message" v-chakra mb="4" />
      </CBox>

      <CBox mb="4" v-if="lastRecommendations.length">
        <omt-h1> <omt-uppercase>Últimas Indicações</omt-uppercase> </omt-h1>
      </CBox>

      <template v-if="lastRecommendations.length">
        <omt-report-card
          v-for="(recommendations, index) in lastRecommendations"
          :key="`${index}-${recommendations.ChamadoPedido}`"
          :title="recommendations.DescricaoPedido"
          :description="`${recommendations.DataPedido} - Protocolo: ${recommendations.Protocolo}`"
          :leadIcons="recommendations.Localizado ? ['fa fa-chevron-right fa-1x'] : []"
          @click="handleClick(recommendations)"
        >
          <omt-report-card
            :icon="`${recommendations.Localizado ? 'fa fa-check-circle' : 'far fa-clock'}`"
            :description="`
            ${
              recommendations.Localizado
                ? 'Localizamos credenciados de acordo com a sua solicitação. Clique para ver mais.'
                : 'Estamos localizando os melhores especialistas para você'
            }`"
            :hasEvaluation="recommendations.NotaAvaliacao === null"
          />
        </omt-report-card>
      </template>

      <CBox my="4" v-if="previousRecommendations.length">
        <omt-bold> Indicações anteriores </omt-bold>
      </CBox>

      <template v-if="previousRecommendations.length">
        <omt-report-card
          v-for="(recommendations, index) in previousRecommendations"
          :key="`${index}-${recommendations.ChamadoPedido}`"
          :title="recommendations.DescricaoPedido"
          :description="`${recommendations.DataPedido} - Protocolo: ${recommendations.Protocolo}`"
          :leadIcons="recommendations.Localizado ? ['fa fa-chevron-right fa-1x'] : []"
          @click="handleClick(recommendations)"
        >
          <omt-report-card
            :icon="`${recommendations.Localizado ? 'fa fa-check-circle' : 'far fa-clock'}`"
            :description="`
            ${
              recommendations.Localizado
                ? 'Localizamos credenciados de acordo com a sua solicitação. Clique para ver mais.'
                : 'Estamos localizando os melhores especialistas para você'
            }`"
            :hasEvaluation="recommendations.NotaAvaliacao === null"
          />
        </omt-report-card>
      </template>

      <FloatingAddButton link="/indicacao-de-credenciados/criar-indicacao" />
    </omint-page-content>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";
import InfoBox from "@/components/Molecules/InfoBox";
import Tile from "@/components/Organisms/AffiliateIndication/Tile";
import { CList, CListItem, CBox } from "@chakra-ui/vue";
import { mapActions } from "vuex";
import OmtUppercase from "@/components/Atoms/omt-uppercase.vue";
import FloatingAddButton from "@/components/Molecules/FloatingAddButton";

export default {
  name: "AffiliateIndicationVisualizeHome",
  components: {
    Footer,
    FloatingAddButton,
    Header,
    Tile,
    CList,
    CListItem,
    CBox,
    InfoBox,
    OmtUppercase
  },
  methods: {
    ...mapActions("affiliate", ["fetchRecommendationRequests"]),
    handleClick(recommendations) {
      if (!recommendations?.Localizado) return;
      this.$router.push({
        name: "indication.details",
        params: { chamado: recommendations.ChamadoPedido }
      });
    }
  },
  data() {
    return {
      recommendations: [],
      message: ""
    };
  },
  async mounted() {
    try {
      await this.$root.$emit("show-loading");
      this.recommendations = await this.fetchRecommendationRequests();
      this.message = this.recommendations.length > 0 ? "" : "Nenhum pedido de indicação de rede foi encontrado";
    } catch {
      this.message = "Serviço temporariamente indisponível";
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  computed: {
    lastRecommendations() {
      return this.recommendations.filter(recommendation => !!recommendation.Recente);
    },
    previousRecommendations() {
      return this.recommendations.filter(recommendation => !recommendation.Recente);
    }
  }
};
</script>
