<template>
  <CBox :bg="['none', 'var(--colors-light-primary-light-gray)']" minHeight="100vh" width="100vw" position="sticky">
    <OmtLayoutContainer>
      <OmtRow>
        <OmtButton
          mr="16px"
          size="sm"
          :variant="filtersCount > 0 ? 'solid' : 'outline'"
          leftIcon="sliders-h"
          :iconColor="filtersCount > 0 ? 'white' : 'var(--colors-light-primary-gray1)'"
          iconSize="sm"
          border=".5px solid var(--colors-light-secondary-divider)"
          @click="showFilterModal = true"
          :w="`${filtersCount > 0 ? 125 : 105}px !important`"
        >
          <OmtTypography as="button-small" :color="filtersCount > 0 ? 'white' : 'var(--colors-light-primary-gray1)'">
            Filtrar
          </OmtTypography>
          <CBox
            ml="16px"
            v-show="filtersCount"
            bg="var(--colors-light-secondary-light-blue)"
            border-radius="12px"
            min-h="16px"
            min-w="16px"
            h="16px"
            w="16px"
            display="flex"
            justify-content="center"
            align-items="center"
          >
            <OmtTypography as="p2" color="white">
              {{ filtersCount }}
            </OmtTypography>
          </CBox>
        </OmtButton>

        <RequestListSortButton :sorts="sorts" @sort="saveSorts($event)" :initialValue="activedSort" />

        <OmtButton
          variant="ghost"
          :disabled="!hasFilterOrSort"
          size="sm"
          w="105px !important"
          ml="auto"
          pr="0"
          @click="cleanSortsAndFilters"
        >
          Limpar
        </OmtButton>
      </OmtRow>
      <OmtRow mt="24px" v-for="(item, index) in filteredRequests" :key="`req-${index}`">
        <RequestCard v-bind="item" @click="detailRequest(item.protocol)" @evaluate="evaluateRequest(item)" />
      </OmtRow>
      <FixedButtonRow>
        <OmtButton :w="['100%', '308px !important']" @click="createNewRequest" :disabled="!addPermission">
          Nova solicitação
        </OmtButton>
      </FixedButtonRow>

      <FilterModal v-if="showFilterModal" @close="showFilterModal = false" @save="saveFilters" :filters="filters" />
      <AvaliationModal
        v-if="showAvaliationModal"
        @close="showAvaliationModal = false"
        @ratingSubmitClick="onRatingSubmitClick($event)"
        :protocolNumber="avaliation.protocol"
        :protocolDate="avaliation.date"
        :protocolTypeName="avaliation.title"
        protocolType="9"
        stage="P"
      />
    </OmtLayoutContainer>
  </CBox>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
import RequestCard from "@/components/Organisms/Requests/RequestCard.vue";
import FilterModal from "@/components/Organisms/Requests/FilterModal.vue";
import RequestListSortButton from "@/components/Organisms/Requests/RequestListSortButton.vue";
import AvaliationModal from "@/components/Organisms/AvaliationModal.vue";
import RadioGroupModal from "@/components/Molecules/RadioGroupModal";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

export default {
  name: "ListRequests",
  components: {
    RequestCard,
    FilterModal,
    RequestListSortButton,
    AvaliationModal,
    RadioGroupModal,
    FixedButtonRow
  },
  data() {
    return {
      avaliation: { date: "", protocol: "", title: "" },
      prevRoute: "",
      showAvaliationModal: false,
      showFilterModal: false
    };
  },
  computed: {
    ...mapGetters("request", ["filteredRequests"]),
    ...mapState("request", ["activedSort", "addPermission", "filters", "sorts"]),
    filtersCount() {
      return this.filters?.totalActived ?? 0;
    },
    hasFilterOrSort() {
      return this.filtersCount > 0 || this.activedSort !== "";
    }
  },
  methods: {
    ...mapActions("request", ["fetchProtocols"]),
    ...mapMutations("request", ["CLEAN_SORT_AND_FILTERS", "RESET_NEW_REQUEST", "SET_ACTIVED_SORT", "SET_FILTERS"]),
    async onRatingSubmitClick(rating) {
      if (!rating?.newRatingValue || !this.avaliation?.protocol) return;
      try {
        this.$root.$emit("show-loading");
        await this.fetchProtocols({ protocol: this.avaliation.protocol });
      } catch (error) {
        console.error(error);
      } finally {
        this.avaliation = {
          protocol: "",
          date: "",
          title: ""
        };
        this.showAvaliationModal = false;
        this.$root.$emit("hide-loading");
      }
    },
    createNewRequest() {
      if (!this.addPermission) return;
      this.RESET_NEW_REQUEST();
      this.$router.push("/solicitacoes/criar");
    },
    cleanSortsAndFilters() {
      this.CLEAN_SORT_AND_FILTERS();
    },
    detailRequest(protocol) {
      if (!protocol) return;
      this.$router.push(`/solicitacoes/detalhe/${protocol}`);
    },
    evaluateRequest(request) {
      if (!request?.protocol || !request?.createdAt) return;
      this.avaliation = {
        protocol: request.protocol,
        date: request.createdAt,
        title: request.title
      };
      this.showAvaliationModal = true;
    },
    saveFilters(filters) {
      this.SET_FILTERS(filters);
    },
    saveSorts(sorts) {
      this.SET_ACTIVED_SORT(sorts);
    }
  },
  beforeRouteEnter(_, from, next) {
    next(vm => {
      vm.prevRoute = from.path;
    });
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      let forceFetch = false;
      if (!this.prevRoute || (!this.prevRoute.startsWith("/request") && !this.prevRoute.startsWith("/solicitacoes"))) {
        forceFetch = true;
        this.cleanSortsAndFilters();
      }
      await this.fetchProtocols({ force: forceFetch });
    } catch (error) {
      console.error(error);
    } finally {
      this.$root.$emit("hide-loading");
    }
  }
};
</script>

<style scoped></style>
