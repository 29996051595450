import scenarios, { AuditFormPerformerGetter } from "./scenarios";

export type AuditFormType = {
  id: string;
  value: any;
  component: string;
};

export class AuditFormManager {
  scenarios: AuditFormType[][] = scenarios;
  answers: AuditFormType[] = [];
  scenarioIndex: number = 0;
  fetchAuditQuestion: Function;
  saveQuestionResponse: Function;

  constructor(fetchAuditQuestion, saveQuestionResponse) {
    this.fetchAuditQuestion = fetchAuditQuestion;
    this.saveQuestionResponse = saveQuestionResponse;

    this.init();
  }

  get current() {
    return this.answers[this.answers.length - 1];
  }

  get getComponent() {
    return this.current?.component;
  }

  init() {
    const { component, id } = AuditFormPerformerGetter();

    this.fetchAuditQuestion(id);

    this.answers.push({
      id,
      component,
      value: null
    });
  }

  isSame(slice: AuditFormType[]) {
    function areArraysEqual(array1, array2) {
      for (let i = 0; i < array1.length; i++) {
        const obj1 = array1[i];
        const obj2 = array2[i];

        if (obj1.id !== obj2.id || obj1.component !== obj2.component || !valuesEqual(obj2.value, obj1.value)) {
          return false;
        }
      }

      return true;
    }

    function valuesEqual(value1, value2) {
      if (Array.isArray(value1)) {
        return value1.includes(value2);
      } else {
        return value2 === value1;
      }
    }

    return areArraysEqual(this.answers, slice);
  }

  storeAnswer({ value: answer }) {
    this.current.value = parseInt(answer);

    for (let index = 0; index < this.scenarios.length; index++) {
      const _scenario = JSON.parse(JSON.stringify(this.scenarios[index]));
      const slice = _scenario.splice(0, this.answers.length);

      if (slice.length === this.answers.length && this.isSame(slice)) {
        this.scenarioIndex = index + 1;

        break;
      }
    }
  }

  async saveAndGetNext() {
    await this.saveQuestionResponse({
      questionTypeId: this.current.id,
      option: this.current.value
    });

    const nextQuestion = this.answers.length;
    const scenario = this.scenarios[this.scenarioIndex - 1];

    if (scenario) {
      const next = scenario[nextQuestion];

      if (next) {
        const { component, id } = next;

        await this.fetchAuditQuestion(id);

        await this.answers.push({
          id,
          component,
          value: null
        });
      }
    }
  }
}

export { default as AuditFormPerformer } from "./AuditFormPerformer.vue";
export { default as AuditFormSharedCredentials } from "./AuditFormSharedCredentials.vue";
export { default as AuditFormPaymentType } from "./AuditFormPaymentType.vue";
export { default as AuditFormRealizedAllProcedures } from "./AuditFormRealizedAllProcedures.vue";
export { default as AuditFormConfirmButtons } from "./ConfirmButtons.vue";
