<template>
  <router-link to="/notifications" v-chakra w="auto !important">
    <OmtButton v-bind="this.buttonAttrs">
      <OmtIcons position="absolute" size="xl" name="bell" color="var(--colors-light-primary-dark-blue)" />
      <!-- <OmtButton v-bind="this.newsIconAttrs"> {{ profile.messages.length }} </OmtButton> -->
    </OmtButton>
  </router-link>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("saude", ["profile"]),
    buttonAttrs() {
      return {
        ...this.$attrs,
        variant: "ghost",
        position: "relative",
        height: "26px",
        width: "23px",
        minWidth: "auto",
        p: "0",
        _active: {
          bg: "initial"
        }
      };
    },
    newsIconAttrs() {
      return {
        "z-index": "1",
        "min-width": "auto",
        "min-height": "auto",
        position: "absolute",
        rounded: "50%",
        top: "-10px",
        right: "2px",
        transform: "scale(.8)",
        padding: "6px",
        height: "25px",
        width: "25px",
        bg: "var(--colors-light-primary-dark-blue)",
        _hover: {
          opacity: "initial"
        },
        _active: {
          bg: "var(--colors-light-primary-dark-blue)"
        }
      };
    }
  }
};
</script>
