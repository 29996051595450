<template>
  <OmtStickedModal v-if="isOpen" @close="$emit('update:isOpen', false)" data-cy="refund-detail-answer-pendency-modal">
    <AnswerPendency
      v-show="step === 1"
      v-bind="{ limitFiles, pendencyToAnswer, filesDescription }"
      :answer.sync="answer"
    />
    <SentPendency v-show="step === 2" v-bind="{ protocol, protocolTitle, protocolMainTip, protocolSubTip }" />

    <template #footer>
      <OmtButton w="100%" @click="save" :data-cy="`button-step-${step}`" :disabled="!canSubmit">
        {{ step === 1 ? "Enviar" : "Fechar" }}
      </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
import AnswerPendency from "./AnswerPendency.vue";
import SentPendency from "./SentPendency.vue";

/**
 * @prop {Boolean} isOpen
 * @prop {Boolean} pendencyToAnswer
 * @prop {Number} step
 * @prop {Number} limitFiles
 * @prop {String} protocol
 * @prop {String} fileDescription
 * @prop {String} protocolTitle
 * @prop {String} protocolMainTip
 * @prop {String} protocolSubTip
 */
export default {
  components: {
    AnswerPendency,
    SentPendency
  },
  props: {
    isOpen: {
      Type: Boolean
    },
    pendencyToAnswer: {
      Type: Object
    },
    step: {
      Type: Number
    },
    limitFiles: {
      Type: Number
    },
    protocol: {
      Type: String
    },
    filesDescription: {
      Type: String
    },
    protocolTitle: {
      type: String,
      default: ""
    },
    protocolMainTip: {
      Type: String
    },
    protocolSubTip: {
      Type: String
    }
  },
  data() {
    return {
      answer: {
        obs: "",
        pictures: []
      }
    };
  },
  methods: {
    close() {
      this.$emit("update:isOpen", false);
    },
    async save() {
      if (this.step === 1) {
        this.$emit("savePendency", {
          protocolo: this.pendencyToAnswer.ticket,
          observacao: this.answer.obs,
          arquivos: this.answer.pictures.filter(pic => !!pic.source).map(pic => pic.source)
        });
      } else {
        this.close();
        this.$emit("closeProtocolModal");
      }
    }
  },
  computed: {
    canSubmit() {
      return !!this.answer.obs || this.answer.pictures.length > 0;
    }
  }
};
</script>
