<template>
  <OmtLayoutContainer>
    <OmtRow mb="24px">
      <OmtTypography as="h1-regular"> <omt-bold>Observações</omt-bold> (opcional) </OmtTypography>
    </OmtRow>
    <OmtRow mb="24px">
      <OmtTypography as="p1" color="#4E4E4E" font-weight="400 !important">
        <omt-bold color="#4E4E4E"> Todas as suas informações necessárias foram preenchidas. </omt-bold>
        Para finalizar, você gostaria de incluir alguma observação?
      </OmtTypography>
    </OmtRow>
    <OmtRow>
      <CFlex flexDirection="column" alignItems="flex-end">
        <OmtTextarea
          class="input-observation"
          height="109px"
          padding="12px"
          placeholder="Inclua informações ou detalhes que podem complementar os documentos enviados."
          @change="handleChange"
          :initialValue="observation"
        />
        <OmtTypography as="p2" color="var(--colors-light-primary-gray2)"> {{ observation.length }}/350 </OmtTypography>
      </CFlex>
    </OmtRow>

    <FixedButtonRow>
      <OmtButton :w="['100%', '308px !important']" @click="submit"> Enviar </OmtButton>
    </FixedButtonRow>
    <SentRequestModal
      :isOpen="modalSuccess"
      :daysAnalizy="daysAnalizy"
      :protocol="protocolANS"
      @close="closeModal"
      @save="saveEvaluation"
    />
  </OmtLayoutContainer>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SentRequestModal from "@/components/Organisms/Requests/SentRequestModal.vue";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

export default {
  name: "ObservationRequest",
  components: {
    SentRequestModal,
    FixedButtonRow
  },
  data() {
    return {
      daysAnalizy: 2,
      modalSuccess: false,
      observation: "",
      protocolANS: ""
    };
  },
  computed: {
    ...mapState("request", ["addPermission", "newRequest", "subjectOptions"])
  },
  methods: {
    ...mapActions("request", ["createRequestTicket", "fetchProtocols"]),
    ...mapActions("ticket", ["saveExpRating"]),
    ...mapMutations("request", ["RESET_NEW_REQUEST"]),
    handleChange(text) {
      this.observation = text;
    },
    async submit() {
      let response = {};
      try {
        await this.$root.$emit("show-loading");
        response = await this.createRequestTicket({ obs: this.observation });
      } finally {
        await this.$root.$emit("hide-loading");
      }

      if (response && response.Data) {
        this.protocolANS = response.Data.protocoloANS;
        this.modalSuccess = true;
        this.RESET_NEW_REQUEST();
        this.fetchProtocols({ protocol: this.protocolANS });
      } else {
        await this.$toast({
          description: "Não foi possivel registrar o atendimento.\nServiço temporariamente indisponível.",
          status: "error",
          duration: 10000,
          position: "top"
        });
      }
    },
    closeModal() {
      this.$router.push("/solicitacoes");
    },
    async saveEvaluation({ rating, obs }) {
      await this.$root.$emit("show-loading");
      try {
        await this.saveExpRating({
          ticket: this.protocolANS,
          type: "9",
          step: "S",
          rating,
          obs
        });
      } finally {
        this.$router.push("/solicitacoes");
      }
    }
  },
  mounted() {
    if (!this.addPermission) {
      this.$router.replace("/solicitacoes");
      return;
    }
    if (!this.newRequest?.subject) {
      this.$router.replace("/solicitacoes/criar");
      return;
    }
    const subject = this.subjectOptions.find(s => s.value === this.newRequest.subject);
    if (!subject?.daysAnalizy) {
      this.$router.replace("/solicitacoes/criar");
      return;
    }
    this.daysAnalizy = subject.daysAnalizy;
    this.$root.$emit("hide-loading");
  }
};
</script>

<style scoped>
::v-deep .input-observation {
  border: 0.5px solid #dadada;
  color: #8c8c8c !important;
}

::v-deep .input-observation::placeholder {
  color: #8c8c8c !important;
}
</style>
