<template>
  <OmtStickedModal
    @close="$emit('close')"
    :headerBind="{
      padding: ['16px', '82px 42px 0px 42px']
    }"
    :bodyBind="{
      padding: ['16px 16px 24px 16px', '68px 42px 24px 42px']
    }"
  >
    <template #header>
      <OmtButton class="clear" :display="['block', 'none']" variant="ghost" @click="value = ''" height="18px" p="0">
        <OmtTypography
          as="h3-bold"
          h="18px"
          w="46px"
          position="relative"
          :color="value ? 'var(--colors-light-primary-navy-blue)' : 'inherit'"
        >
          Limpar
        </OmtTypography>
      </OmtButton>
    </template>

    <CBox :minHeight="['initial', 'calc(100vh - 400px)']">
      <CFlex justifyContent="space-between" :mb="['24px', '36px']">
        <OmtTypography as="h1-bold"> {{ title }} </OmtTypography>
        <OmtButton class="clear" variant="ghost" @click="value = ''" height="18px" p="0" :display="['none', 'block']">
          <OmtTypography
            as="p1"
            classh3-bold
            h="18px"
            w="46px"
            position="relative"
            :color="value ? 'var(--colors-light-primary-navy-blue)' : 'inherit'"
          >
            Limpar
          </OmtTypography>
        </OmtButton>
      </CFlex>

      <CRadioGroup v-model="value" mt="24px">
        <CRadio isFullWidth :value="option" cursor="pointer" v-for="(option, index) in options" :key="index">
          <OmtTypography as="p1"> {{ option }} </OmtTypography>
        </CRadio>
      </CRadioGroup>
    </CBox>

    <template #footer>
      <OmtButton w="100%" @click="onSave"> Aplicar </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
export default {
  props: {
    title: String,
    options: Array,
    initialValue: String
  },
  data() {
    return {
      isOpen: false,
      value: ""
    };
  },
  mounted() {
    this.value = this.initialValue;
  },
  methods: {
    onSave() {
      this.$emit("close");
      this.$emit("save", this.value);
    }
  }
};
</script>

<style scoped>
div[data-chakra-component="CRadioGroup"] {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

::v-deep label[data-chakra-component="CRadio"] {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
</style>
