<template>
  <CBox v-bind="$attrs" v-on="$listeners" :class="myClass">
    <slot />
  </CBox>
</template>
<script>
import { CBox } from "@chakra-ui/vue";
import { mapState } from "vuex";
export default {
  props: [],
  components: {
    CBox
  },
  computed: {
    ...mapState("saude", ["theme"]),
    myClass() {
      return `omt-card ${this.theme}`;
    }
  }
};
</script>
<style scoped lang="scss">
.omt-card {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 16px 2px #00000026;
  padding: var(--space-4);
  border-radius: 12px;
}
.dark .omt-card {
  background-color: transparent;
  box-shadow: 0px 0px 16px 2px #3c5e8742;
  border: 1px solid #4a55688c;
}
</style>
