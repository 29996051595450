<template>
  <omint-page-content :disableMinHeight="true" :message="error">
    <div v-if="tickets && tickets.length > 0">
      <div class="filtros">
        <Options
          id="optFilter"
          name="optFilter"
          preSelected="first"
          :solid="true"
          :options="[
            { value: '30', label: 'Últimos 30 dias' },
            { value: '60', label: 'Últimos 60 dias' },
            { value: '2A', label: '2 últimos anos' }
          ]"
          :onSelection="setDateRef"
        />
      </div>

      <omt-report-card
        :title="`Protocolo: ${t.IdChamado}`"
        :description="`Situação ${t.Status}`"
        :leadIcons="['fa fa-chevron-right fa-xs']"
        :hasEvaluation="t.NotaAvaliacao === null"
        @click="handleClick(t)"
        v-for="(t, idx) in tickets.filter(t => applyOptions(t.DataHoraInicio))"
        :key="'t' + idx"
      />
    </div>
    <FloatingAddButton link="/ticket/form" :margin="tickets && tickets.length > 0" />
  </omint-page-content>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FloatingAddButton from "@/components/Molecules/FloatingAddButton";
import Options from "@/components/Molecules/Options";
import { CFlex, CBox, CStack } from "@chakra-ui/vue";

export default {
  name: "TicketList",
  components: { FloatingAddButton, Options, CFlex, CBox, CStack },
  async mounted() {
    await this.$root.$emit("show-loading");
    try {
      this.option = this.listOption;
      this.setDateRef(this.option);
      if (!this.tickets || this.tickets.length === 0) {
        await this.listTickets();
        if (!this.tickets || this.tickets.length === 0) {
          this.error = "Nenhum atendimento encontrado";
        }
      } else {
        this.listTickets();
      }
    } catch {
      this.error = "Serviço temporariamente indisponível";
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  data: function () {
    return {
      option: "30",
      dateRef: new Date(),
      error: null
    };
  },
  methods: {
    ...mapActions("ticket", ["listTickets", "setListOption"]),
    setDateRef(value) {
      this.option = value;
      let dataRef = new Date();
      if (this.option === "2A") {
        if (dataRef.getMonth() === 1 && dataRef.getDate() === 29) {
          // 29 de fevereiro... js conta mês estranho
          dataRef = new Date(dataRef.getFullYear() - 2, dataRef.getMonth(), 28);
        } else {
          dataRef = new Date(dataRef.getFullYear() - 2, dataRef.getMonth(), dataRef.getDate());
        }
      } else {
        dataRef.setDate(dataRef.getDate() - new Number(this.option));
      }
      this.dateRef = dataRef;
      this.setListOption(this.option);
    },
    applyOptions(ticketSrtDate) {
      try {
        const dateParts = ticketSrtDate.split(" ")[0].split("/");
        const ticketDate = new Date(dateParts[2], new Number(dateParts[1]) - 1, dateParts[0]);
        return ticketDate >= this.dateRef;
      } catch (error) {
        return true;
      }
    },
    handleClick(t) {
      this.$router.push({ path: "/ticket/detail/" + t.IdChamado });
    }
  },
  computed: {
    ...mapState("saude", ["theme"]),
    ...mapState("ticket", ["tickets", "listOption"])
  }
};
</script>

<style lang="scss" scoped>
.filtros {
  display: block;
  width: 50%;
}
</style>
