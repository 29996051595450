<template>
  <div :class="myClass">
    <div class="radio-group-item" v-for="option in options" :key="option.value">
      <input type="radio" v-bind:value="option.value" v-model="selected" />
      <label v-on:click.prevent="() => (selected = option.value)">{{ option.label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioGroup",
  props: ["options", "onSelection", "preSelected", "direction"],
  data: function () {
    return {
      selected: null
    };
  },
  mounted() {
    this.autoSelect();
  },
  updated() {
    this.autoSelect();
  },
  watch: {
    selected: function (val, oldVal) {
      if (val !== oldVal) {
        this.onSelection(val);
      }
    }
  },
  computed: {
    myClass() {
      return `radio-group-box ${this.direction === "column" ? "column" : "row"}`;
    }
  },
  methods: {
    autoSelect() {
      if (this.selected) return;
      if (
        (this.preSelected === "single" && this.options.length === 1) ||
        (this.preSelected === "first" && this.options.length > 0)
      ) {
        this.selected = this.options[0].value;
      }
    },
    reset() {
      this.selected = null;
    }
  }
};
</script>

<style scoped>
.radio-group-box {
  display: flex;
  align-items: left;
}

.radio-group-box.row {
  flex-direction: row;
}

.radio-group-box label {
  padding-left: 5px;
  padding-right: 5px;
}

.radio-group-box.column {
  flex-direction: column;
}

.radio-group-item {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.radio-group-item label {
  margin-left: 5px;
}

.radio-group-item label,
.radio-group-item input {
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
}

.big-font-size .radio-group-item label,
.big-font-size .radio-group-item input {
  font-size: 19px;
}

.light .radio-group-item label,
.light .radio-group-item input {
  color: var(--colors-light-primary-gray1);
}

.dark .radio-group-item label,
.dark .radio-group-item input {
  color: #f0f0f0;
}
</style>
