<template>
  <OmtLayoutContainer>
    <OmtRow v-if="!!getStep" mb="24px">
      <OmtStepper :currentStep="getStep" :totalSteps="3" />
    </OmtRow>

    <OmtRow>
      <router-view />
    </OmtRow>
  </OmtLayoutContainer>
</template>

<script>
export default {
  data() {
    return {
      internalStep: 0
    };
  },
  computed: {
    getStep() {
      const steps = {
        "/copay/simulations/create/exams": 1,
        "/copay/simulations/create/review": 1,
        "/copay/simulations/create/select-labs": 2
      };

      const currentStep = Object.keys(steps).find(key => {
        if (key === this.$route.path) return steps[key];
      });

      return this.internalStep || steps[currentStep];
    }
  },
  mounted() {
    this.$root.$on("finish-simulation", () => {
      this.internalStep = 3;
    });
  }
};
</script>
