<template>
  <OmtLayoutHeaderFooter
    :bg="['none', '#F6F6F9']"
    :header="{ titleLabel: 'Extrato de utilização dos serviços assistenciais (PIN - SS)' }"
  >
    <router-view />
  </OmtLayoutHeaderFooter>
</template>

<script>
export default {};
</script>
