import ThirdPartyRegister from "@/pages/third-party-register/index.vue";
import ThirdPartyRegisterList from "@/pages/third-party-register/list.vue";
import ThirdPartyRegisterForm from "@/pages/third-party-register/form.vue";
import ThirdPartyRegisterRevalidation from "@/pages/third-party-register/revalidation.vue";

const routes: Route[] = [
  {
    path: "/third-party-register",
    component: ThirdPartyRegister,
    children: [
      {
        path: "",
        component: ThirdPartyRegisterList,
        name: "third.party.register",
        meta: {
          registerAccess: { servico: "Cadastro de Terceiro", funcionalidade: "Acesso ao cadastro de terceiros" }
        }
      },
      {
        path: "/third-party-register/form",
        component: ThirdPartyRegisterForm,
        name: "third.party.register.form",
        meta: {
          backTo: "/third-party-register",
          validPath: ["/third-party-register"]
        }
      },
      {
        path: "/third-party-register/revalidation",
        component: ThirdPartyRegisterRevalidation,
        name: "third.party.register.revalidation",
        meta: {
          backTo: "/third-party-register",
          validPath: ["/third-party-register"]
        }
      }
    ]
  }
];

export default routes;
