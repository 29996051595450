const baseURL = `${process.env.VUE_APP_SERVER_URL}/PrimeiroAcesso`;

export async function TermoAceiteAtual({ device }) {
  return window.$axios
    .post({
      url: `${baseURL}/TermoAceiteAtual`,
      query: {
        device,
        versao: 2
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("TermoAceiteAtual", error.message);
      return error;
    });
}

export async function AceitarTermo({ hash }) {
  return window.$axios
    .post({
      url: `${baseURL}/AceitarTermo`,
      query: {
        hash
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("AceitarTermo", error.message);
      return error;
    });
}

export async function ValidarVinculo({ hash, vinculo }) {
  const vinculoEncrypted = window.$jse.encrypt(vinculo);
  return window.$axios
    .post({
      url: `${baseURL}/ValidarVinculo`,
      query: {
        hash,
        vinculoEncrypted
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ValidarVinculo", error.message);
      return error;
    });
}

export async function ValidarVinculoPorCPF({ hash, cpf }) {
  const vinculoEncrypted = window.$jse.encrypt(cpf);
  return window.$axios
    .post({
      url: `${baseURL}/ValidarVinculoPorCPF`,
      query: {
        hash,
        vinculoEncrypted
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ValidarVinculoPorCPF", error.message);
      return error;
    });
}

export async function EnviarConfirmacao({ hash, email }) {
  return window.$axios
    .post({
      url: `${baseURL}/EnviarConfirmacao`,
      query: {
        hash,
        email
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("EnviarConfirmacao", error.message);
      return error;
    });
}

export async function InformarConfirmacao({ hash, codigo }) {
  return window.$axios
    .post({
      url: `${baseURL}/InformarConfirmacao`,
      query: {
        hash,
        codigo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("InformarConfirmacao", error.message);
      return error;
    });
}

export async function CadastrarEmail({ hash, cpf, email }) {
  const vinculoEncrypted = window.$jse.encrypt(cpf);
  return window.$axios
    .post({
      url: `${baseURL}/CadastrarEmail`,
      query: {
        email,
        hash,
        vinculoEncrypted
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterCredencialEletronica", error.message);
      return error;
    });
}

export async function CadastrarUsuarioOmint({ hash, senha }) {
  const senhaEncrypted = window.$jse.encrypt(senha);
  return window.$axios
    .post({
      url: `${baseURL}/CadastrarUsuarioOmint`,
      query: {
        hash,
        senhaEncrypted
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("CadastrarUsuarioOmint", error.message);
      return error;
    });
}
