<template>
  <CBox>
    <OmtTypography as="p1" mb="16px">
      Libere o acesso para os beneficiários que podem <b>visualizar suas informações pessoais</b>.
    </OmtTypography>
    <OmtTypography as="p1" mb="32px">
      A Central de Atendimento e canais digitais Omint só <b>disponibilizam informações</b> aos beneficiários que
      possuem o <b>acesso autorizado</b>.
    </OmtTypography>
    <CBox v-for="(b, indexB) in permissoes" :key="indexB" w="100%" :mt="indexB > 0 ? '32px' : '0px'">
      <OmtTypography as="p1-bold" mb="8px">
        {{ b.pessoa }}
      </OmtTypography>

      <CBox v-for="(f, indexF) in b.funcionalidades" :key="`${indexF}-f`" w="100%" mt="24px">
        <CFlex direction="row" justify="space-between" align="center" flex-wrap="wrap" w="100%">
          <OmtTypography as="h3-bold" color="var(--colors-light-primary-gray2)">
            {{ f.tipo || "Informações Médicas" }}
          </OmtTypography>
          <CBox @click.prevent="ativarTodos(indexB, indexF)" cursor="pointer">
            <OmtTypography as="h3-bold" color="var(--colors-light-primary-navy-blue)">
              {{ f.label || "Ativar todos" }}
            </OmtTypography>
          </CBox>
        </CFlex>

        <CBox v-for="(p, indexP) in f.permissoes" :key="`${indexP}-f`" mt="8px">
          <omt-divider />
          <CFlex direction="row-reverse" justify="flex-end" flex-wrap="nowrap">
            <OmtTypography as="p1"> {{ p.descricao }} </OmtTypography>
            <RightSwitchCheckBox
              :key="`${indexP}-f`"
              :id="`${indexP}-f`"
              :onChange="val => ativar(indexB, indexF, indexP, val)"
              :rounded="true"
              :checked="p.ativa"
            />
          </CFlex>
        </CBox>
      </CBox>
    </CBox>
    <AdministratorRegisterOptinModal :isOpen.sync="exibirModalTermo" @continue="optinHandler" />
    <FixedButtonRow :useFooter="false" :solidColor="true">
      <OmtButton @click.prevent="onNextClick" :w="['100%', '308px !important']" variant="solid"> Continuar </OmtButton>
    </FixedButtonRow>
  </CBox>
</template>

<script>
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";
import RightSwitchCheckBox from "@/components/Molecules/RightSwitchCheckBox";
import AdministratorRegisterOptinModal from "@/components/Organisms/AdministratorRegister/AdministratorRegisterOptinModal.vue";
import { mapState, mapActions } from "vuex";

const LABEL_ATIVAR_TODAS = "Ativar todas";
const LABEL_DESATIVAR_TODAS = "Desativar todas";

export default {
  components: { FixedButtonRow, RightSwitchCheckBox, AdministratorRegisterOptinModal },
  data() {
    return {
      exibirModalTermo: false,
      optinAceito: false,
      permissoes: []
    };
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      if (!this.permissions?.length) {
        await this.fetchPermissions();
      }
      this.permissoes = this.recuperarPermissoes();
    } catch (error) {
      this.$toast({
        description: error.message,
        status: "error",
        duration: 10000,
        position: "bottom-right"
      });
    } finally {
      this.$root.$emit("hide-loading");
    }
  },
  computed: {
    ...mapState("accessManagement", ["permissions"])
  },
  methods: {
    ...mapActions("accessManagement", ["fetchPermissions", "optinAccept", "updateAccessPermitions"]),
    ...mapActions("myAccount", ["checkNextWelcomeOnBoardTour"]),
    recuperarPermissoes() {
      if (!this.permissions?.length) return [];
      return this.permissions.map(p => {
        const tipos = {};
        p.ListaFuncionalidades.forEach(f => {
          if (!tipos[f.Tipo]) {
            tipos[f.Tipo] = [];
          }
          const ativa = !!f.Ativa;
          tipos[f.Tipo].push({
            id: f.IdFuncionalidade,
            descricao: f.DsFuncionalidade,
            ativa: ativa,
            original: ativa
          });
        });
        return {
          id: p.IdPermissao,
          vinculo: p.Vinculo,
          pessoa: p.DsPessoa,
          ativa: p.IdPermissao > 0,
          funcionalidades: Object.keys(tipos).map(t => ({
            tipo: t,
            label: tipos[t].find(p => !p.ativa) ? LABEL_ATIVAR_TODAS : LABEL_DESATIVAR_TODAS,
            permissoes: tipos[t]
          }))
        };
      });
    },
    ativarTodos(indexBeneficiario, indexFuncionalidade) {
      const funcionalidade = this.permissoes[indexBeneficiario].funcionalidades[indexFuncionalidade];
      var novoValor = funcionalidade.label === LABEL_ATIVAR_TODAS;
      funcionalidade.permissoes.forEach(p => (p.ativa = novoValor));
      funcionalidade.label = funcionalidade.label === LABEL_ATIVAR_TODAS ? LABEL_DESATIVAR_TODAS : LABEL_ATIVAR_TODAS;
      this.atualizarBeneficiario(indexBeneficiario);
    },
    ativar(indexBeneficiario, indexFuncionalidade, indexPermissao, valor) {
      const funcionalidade = this.permissoes[indexBeneficiario].funcionalidades[indexFuncionalidade];
      funcionalidade.permissoes[indexPermissao].ativa = valor;
      funcionalidade.label = funcionalidade.permissoes.find(p => !p.ativa) ? LABEL_ATIVAR_TODAS : LABEL_DESATIVAR_TODAS;
      this.atualizarBeneficiario(indexBeneficiario);
    },
    atualizarBeneficiario(indexBeneficiario) {
      const beneficiario = this.permissoes[indexBeneficiario];
      beneficiario.ativa = !!beneficiario.funcionalidades.find(f => f.permissoes.find(p => p.ativa));
    },
    existemAlteracoes() {
      return !!this.permissoes.find(b => b.funcionalidades.find(f => f.permissoes.find(p => p.ativa !== p.original)));
    },
    async nextTask() {
      const rota = await this.checkNextWelcomeOnBoardTour();
      this.$router.replace(rota);
    },
    async submitChanges() {
      try {
        this.$root.$emit("show-loading");
        await this.updateAccessPermitions({ permissions: this.permissoes });
        this.nextTask();
      } catch (error) {
        this.$toast({
          description: error.message,
          status: "error",
          duration: 10000,
          position: "bottom-right"
        });
      } finally {
        this.$root.$emit("hide-loading");
      }
    },
    async optinHandler() {
      this.$root.$emit("show-loading");
      await this.optinAccept();
      this.optinAceito = true;
      this.exibirModalTermo = false;
      this.submitChanges();
    },
    async onNextClick() {
      if (this.existemAlteracoes()) {
        if (this.optinAceito) {
          this.submitChanges();
        } else {
          this.exibirModalTermo = true;
        }
      } else {
        this.nextTask();
      }
    }
  }
};
</script>
<style scoped>
::v-deep .switch-box {
  width: 33px;
  margin: 0 17px 0 0;
}
::v-deep .switch {
  height: 18px;
  flex-basis: 60px;
}
::v-deep .slider {
  width: 33px;
}
::v-deep .slider:before {
  height: 16px;
  width: 15px;
  left: 2px;
  bottom: 1px;
}
::v-deep input:checked + .slider:before {
  transform: translateX(14px);
}
::v-deep .slider.round {
  border-radius: 16px;
}
</style>
