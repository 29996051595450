<template>
  <OmtCard
    @click="!disabled && !changeStatus.existChangeRequest && $emit('change')"
    :cursor="disabled || changeStatus.existChangeRequest ? 'not-allowed' : 'pointer'"
    p="12px 16px 16px 16px"
    box-shadow="sm"
    border-color="transparent"
    min-h="85px"
  >
    <CFlex justify-content="space-between">
      <CFlex>
        <OmtIcons
          box-shadow="sm"
          :name="this.icon"
          mr="8px"
          border-radius="50%"
          height="26px"
          width="26px"
          display="flex"
          justify-content="center"
          align-items="center"
        />

        <CBox>
          <OmtTypography as="h3-bold" mb="8px">
            {{ this.title }}
          </OmtTypography>

          <OmtTypography as="p2" color="var(--colors-light-primary-gray2)">
            {{ this.description }}
          </OmtTypography>
        </CBox>
      </CFlex>

      <CFlex align-items="center" :opacity="changeStatus.existChangeRequest ? '0.2' : '1'">
        <OmtTypography as="button-small" color="var(--colors-light-primary-navy-blue)" mr="8px">Editar</OmtTypography>
        <OmtIcons name="pen-to-square" color="var(--colors-light-primary-navy-blue)" size="1x" />
      </CFlex>
    </CFlex>

    <CFlex
      v-if="changeStatus.existChangeRequest"
      ml="34px"
      mt="16px"
      align-items="center"
      bg="var(--colors-light-primary-light-gray)"
      p="8px"
      border-radius="8px"
    >
      <OmtIcons name="clock" mr="8px" size="lg" color="var(--colors-light-primary-gray2)" />
      <OmtTypography as="p2" color="var(--colors-light-primary-gray2)">
        {{ changeStatus.label }}
      </OmtTypography>
    </CFlex>
  </OmtCard>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
    description: String,
    disabled: Boolean,
    changeStatus: Object
  }
};
</script>
