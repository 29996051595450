<template>
  <CBox>
    <OmtTypography as="h1-bold" mb="8px"> Seleção de prestador </OmtTypography>
    <OmtTypography as="h3-semi-bold" mb="24px"> Selecione o prestador de sua preferência: </OmtTypography>

    <OmtRow v-for="(option, index) in vaccineProviders" :key="option.value">
      <CBox :mb="index !== vaccineProviders.length - 1 ? '24px' : '0px'">
        <CFlex justify="space-between" align-items="center" cursor="pointer">
          <OmtRadio
            @click="onChangeSelection(option.value)"
            :value="option.value"
            :isChecked="vaccineProvider === option.value"
          >
            <OmtTypography as="p1"> {{ option.label }} </OmtTypography>
          </OmtRadio>
        </CFlex>
      </CBox>
    </OmtRow>

    <CBox borderRadius="8px" bg="var(--colors-light-primary-light-gray)" p="16px" mt="24px">
      <CFlex mb="16px" :align-items="['unset', 'center']">
        <OmtIcons name="lightbulb" mr="14px" size="lg" />
        <OmtTypography as="h2-bold"> Dica! </OmtTypography>
      </CFlex>
      <OmtTypography as="p1" lineHeight="22px">
        Entre em contato com o laboratório e confirme se a vacina está disponível para aplicação.
      </OmtTypography>
    </CBox>

    <FixedButtonRow>
      <OmtButton :w="['100%', '308px !important']" @click="onNextClick" :disabled="!vaccineProvider">
        <OmtTypography
          as="h1-bold"
          :color="!vaccineType ? 'var(--colors-light-secondary-disabled-placeholder)' : 'inherit'"
        >
          Continuar
        </OmtTypography>
      </OmtButton>
    </FixedButtonRow>
  </CBox>
</template>

<script>
// Components
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "VoucherVaccineProviders",
  components: { FixedButtonRow },
  data: {
    return: {
      initialValue: ""
    }
  },
  computed: {
    ...mapState("voucher", ["vaccineProviders", "vaccineType", "vaccineProvider"])
  },
  methods: {
    ...mapActions("voucher", ["fetchProvidersVaccine"]),
    ...mapMutations("voucher", ["SET_VACCINE_STEP", "SET_VACCINE_PROVIDER", "SET_VACCINE_BENEFICIARIES"]),
    onChangeSelection(value) {
      this.SET_VACCINE_PROVIDER(value);
    },
    async onNextClick() {
      if (this.initialValue !== this.vaccineProvider) {
        this.SET_VACCINE_BENEFICIARIES([]);
      }
      this.SET_VACCINE_STEP(3);
      this.$router.push("/voucher/vacina/beneficiarios");
    },
    async getProviders() {
      const result = await this.fetchProvidersVaccine();
      if (result === "") {
        this.$toast({
          description: "Não foi possível carregar a lista de Prestadores",
          status: "error",
          duration: 10000,
          position: "top"
        });
      } else {
        if (this.vaccineProviders.length === 1) {
          this.onChangeSelection(this.vaccineProviders[0].value);
        }
      }
    }
  },

  async mounted() {
    this.$root.$emit("show-loading");
    if (!this.vaccineType) {
      this.$router.replace({
        name: "voucher.vaccine.types"
      });
      return;
    }
    this.SET_VACCINE_STEP(2);
    this.initialValue = this.vaccineProvider;
    await this.getProviders();
    this.$root.$emit("hide-loading");
  }
};
</script>
<style scoped>
::v-deep.omt-radio input[type="radio"]:checked + div span {
  background: var(--colors-light-primary-navy-blue) !important;
}
</style>
