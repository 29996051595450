import Vue from "vue";
import {
  ObterBancosDisponiveis,
  ObterAgenciasDisponiveis,
  ObterBeneficiariosTitulo,
  ListarContaCorrenteCredito,
  ConsultarRequisicaoAlteracaoContaCredito,
  CadastroContaCredito
} from "@/api/associado";
import { ExisteChamadoPendenteProcessamento } from "@/api/atendimento";
import { checkUpdatePermission } from "@/utils/checkReadonlyUtil";

const initialState = {
  banks: [],
  agencies: [],
  accounts: [],
  isChanging: false,
  protocol: "",
  requestUrl: "",
  bankCode: "",
  beneficiaries: [],
  selectedBeneficiary: "",
  newAccounts: []
};

// vuex module
export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions: {
    async hasActiveTicket(context, { beneficiaryCode }) {
      if (!beneficiaryCode) return false;

      const response = await ExisteChamadoPendenteProcessamento({
        vinculo: context.rootState.saude.vinculo,
        beneficiario: beneficiaryCode,
        servico: 1
      });
      const isChanging = !!(response instanceof Error ? false : response?.Data);
      context.commit("SET_IS_CHANGING", isChanging);
      return isChanging;
    },
    async fetchBeneficiaries(context) {
      if (context.state.beneficiaries.length > 0) return;

      const response = await ObterBeneficiariosTitulo({
        vinculo: context.rootState.saude.vinculo,
        dependentes: false,
        servico: 1
      });
      const beneficiaries =
        response instanceof Error
          ? []
          : response.Data.map(b => {
              return {
                value: b.Sequencia,
                label: b.Nome,
                document: b.DocumentoFormatado ?? b.Documento
              };
            });
      context.commit("SET_BENEFICIARIES", beneficiaries);
    },
    async fetchAccounts(context, { beneficiaryCode }) {
      if (!beneficiaryCode) return [];

      const response = await ListarContaCorrenteCredito({
        vinculo: context.rootState.saude.vinculo,
        beneficiario: beneficiaryCode,
        servico: 1
      });

      const accounts = response?.Data?.length ? response.Data : [];
      context.commit("SET_ACCOUNTS", accounts);
      return accounts;
    },
    async feachTicket(_, ticket) {
      return await ConsultarRequisicaoAlteracaoContaCredito({ chave: ticket });
    },
    async listAvailableBanks(context) {
      if (context.state.banks.length > 0) return;
      const banks = await ObterBancosDisponiveis({
        codigo: "",
        descricao: "",
        autorizaDebito: false,
        autorizaCredito: true
      });
      if (banks?.Data?.length) {
        await context.commit("SET_BANKS", banks.Data);
      }
    },
    async listAvailableAgencies(context, { bankCode }) {
      const agencies = await ObterAgenciasDisponiveis({
        banco: bankCode,
        codigo: "",
        descricao: ""
      });
      if (agencies.Data) {
        await context.commit("SET_AGENCIES", agencies.Data);
      } else {
        return agencies;
      }
    },
    async submitBeneficiaryBankInfosFromTour(context) {
      if (!context.state.newAccounts?.length) return;
      const changes = context.state.newAccounts;
      for (let i = 0; i < changes.length; i++) {
        await context.dispatch("submitBeneficiaryBankInfos", {
          ...changes[i],
          emailContato: "",
          telefoneContato: "",
          autenticacao: context.rootState.myAccount.welcomeOnBoardTourAuth
        });
      }
    },
    async submitBeneficiaryBankInfos(
      context,
      {
        beneficiario,
        idConta,
        banco,
        agencia,
        conta,
        digito,
        excluir,
        principal,
        emailContato,
        telefoneContato,
        autenticacao
      }
    ) {
      checkUpdatePermission();
      const resp = await CadastroContaCredito({
        vinculo: context.rootState.saude.vinculo,
        beneficiario,
        idConta,
        banco,
        agencia,
        conta,
        digito,
        excluir,
        principal,
        emailContato,
        telefoneContato,
        usuarioLogado: context.rootState.saude.userId,
        autenticacao
      });

      return resp instanceof Error ? "" : resp.Data?.Protocolo;
    }
  },
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    SET_AGENCIES: (state, nextAgencies) => Vue.set(state, "agencies", nextAgencies),
    SET_BANKS: (state, nextBanks) => Vue.set(state, "banks", nextBanks),
    SET_ACCOUNTS: (state, nextAccounts) => Vue.set(state, "accounts", nextAccounts),
    SET_NEW_ACCOUNTS: (state, newAccounts) => Vue.set(state, "newAccounts", newAccounts),
    SET_IS_CHANGING: (state, isChanging) => Vue.set(state, "isChanging", isChanging),
    SET_PROTOCOL: (state, value) => Vue.set(state, "protocol", value),
    SET_REQUESTURL: (state, value) => Vue.set(state, "requestUrl", value),
    SET_BENEFICIARIES: (state, value) => Vue.set(state, "beneficiaries", value),
    SET_SELECTED_BENEFICIARY: (state, value) => Vue.set(state, "selectedBeneficiary", value)
  }
};
