import { mapState } from "vuex";
import { importScript } from "@/utils/scriptUtil";
import _ from "lodash";
import { isMobile } from "@/utils/utils";

export default {
  computed: {
    ...mapState("saude", ["username"]),
    isMobile() {
      return isMobile();
    },
    defaultEqualwebButtonStyle() {
      return {
        vPosition: ["0%", null],
        scale: ["0.6", "0.6"],
        color: {
          main: "#102a46",
          second: "#ffffff"
        },
        icon: {
          type: 1,
          shape: "circle",
          outline: false
        }
      };
    }
  },
  methods: {
    isValidEqualwebUser() {
      const username = process.env.VUE_APP_EQUALWEB_USER;
      if (this.isMobile || username) {
        const equalWrap = document.getElementById("INDWrap");
        if (username && username !== this.username) {
          if (equalWrap) equalWrap.style["cssText"] = "display: none !important;";
          return false;
        }
        if (equalWrap && equalWrap.style["cssText"]) {
          equalWrap.style["cssText"] = "";
        }
      }
      return true;
    },
    resizeEqualweb: _.throttle(function (tentativa) {
      const equalMenu = document.getElementById("INDmenu-btn");
      if (!equalMenu || !equalMenu.style["cssText"]) {
        tentativa++;
        if (tentativa < 50) setTimeout(() => this.resizeEqualweb(tentativa), 100);
        return;
      }
      equalMenu.style["cssText"] = this.equalButtonStyle;
      if (!this.isValidEqualwebUser()) return;
    }, 100),
    async initEqualweb(btnStyle) {
      if (!this.isValidEqualwebUser()) return;
      const siteKey = process.env.VUE_APP_EQUALWEB_KEY;
      const domainsJS = process.env.VUE_APP_EQUALWEB_JS;
      const domainsACC = process.env.VUE_APP_EQUALWEB_ACC;
      const siteIntegrity = process.env.VUE_APP_EQUALWEB_INTEGRITY;
      const siteSRC = process.env.VUE_APP_EQUALWEB_SRC;
      if (!siteKey || !domainsJS || !domainsACC || !siteIntegrity || !siteSRC) return;
      if (window.interdeal) {
        this.resizeEqualweb(0);
        return;
      }
      window.interdeal = {
        sitekey: siteKey,
        Position: "Right",
        Menulang: "PT",
        domains: {
          js: domainsJS,
          acc: domainsACC
        },
        btnStyle: btnStyle
      };
      await importScript(document, siteSRC, coreCall => {
        coreCall.defer = true;
        coreCall.integrity = siteIntegrity;
        coreCall.crossOrigin = "anonymous";
        coreCall.setAttribute("data-cfasync", true);
      });
      this.resizeEqualweb(0);
    }
  }
};
