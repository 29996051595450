<template>
  <CBox mt="20px">
    <CBox v-if="hasBiometric" w="100%" mb="28px">
      <CFlex justify="space-between" flex-wrap="wrap" w="100%" mb="16px">
        <OmtTypography as="h3-semi-bold"> Login por biometria </OmtTypography>
        <OmtTypography v-if="shoudUseBiometric" as="h3-semi-bold"> Habilitado </OmtTypography>
        <OmtTypography v-else as="h3-semi-bold"> Desabilitado </OmtTypography>
      </CFlex>
      <OmtTypography as="p2" color="var(--colors-light-primary-gray2)" pb="10px">
        Acesse o aplicativo utilizando a sua biometria.
      </OmtTypography>
      <omt-divider />
    </CBox>
    <template v-if="v25">
      <CBox w="100%" mb="28px">
        <CFlex justify="space-between" flex-wrap="wrap" w="100%" mb="16px">
          <OmtTypography as="h3-semi-bold"> Uso da câmera </OmtTypography>
          <OmtTypography v-if="camera === 'granted'" as="h3-semi-bold"> Habilitado </OmtTypography>
          <OmtTypography v-else as="h3-semi-bold"> Desabilitado </OmtTypography>
        </CFlex>
        <OmtTypography as="p2" color="var(--colors-light-primary-gray2)" pb="10px">
          Sua câmera será usada para a captura de imagens de documentos e videochamadas de consultas.
        </OmtTypography>
        <omt-divider />
      </CBox>
      <CBox w="100%" mb="28px">
        <CFlex justify="space-between" flex-wrap="wrap" w="100%" mb="16px">
          <OmtTypography as="h3-semi-bold"> Uso do microfone </OmtTypography>
          <OmtTypography v-if="microfone === 'granted'" as="h3-semi-bold"> Habilitado </OmtTypography>
          <OmtTypography v-else as="h3-semi-bold"> Desabilitado </OmtTypography>
        </CFlex>
        <OmtTypography as="p2" color="var(--colors-light-primary-gray2)" pb="10px">
          Seu microfone será usado em videochamadas de consultas.
        </OmtTypography>
        <omt-divider />
      </CBox>
      <CBox w="100%" mb="16px">
        <CFlex justify="space-between" flex-wrap="wrap" w="100%" mb="16px">
          <OmtTypography as="h3-semi-bold"> Acesso à localização </OmtTypography>
          <OmtTypography v-if="location === 'granted'" as="h3-semi-bold"> Habilitado </OmtTypography>
          <OmtTypography v-else as="h3-semi-bold"> Desabilitado </OmtTypography>
        </CFlex>
        <OmtTypography as="p2" color="var(--colors-light-primary-gray2)">
          Sua localização será usada para realizar a busca de hospitais, laboratórios e clínicas próximas a você.
        </OmtTypography>
      </CBox>
    </template>
  </CBox>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      tour: null
    };
  },
  computed: {
    ...mapState("biometrics", ["shoudUseBiometric", "hasBiometric", "camera", "microfone", "location"]),
    v25() {
      return Number.parseInt(localStorage.getItem("native_version")) >= 25;
    }
  },
  mounted() {
    this.$root.$emit("hide-loading");
  }
};
</script>
<style scoped></style>
