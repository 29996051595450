<template>
  <OmtStickedModal v-if="isOpen" @close="$emit('close')">
    <CBox>
      <OmtRow mb="24px">
        <OmtTypography as="h1-bold"> Responder pendências </OmtTypography>
      </OmtRow>
      <OmtTypography as="p1">
        Motivos:
        <CBox mt="16px">
          <ol class="list">
            <li v-for="(r, index) in reasons" :key="`r-${index}`">{{ r }}</li>
          </ol>
        </CBox>
      </OmtTypography>

      <OmtRow>
        <CDivider mt="16px" mb="16px" />
      </OmtRow>

      <OmtRow>
        <OmtTypography as="p1">
          <omt-bold> Insira a imagem que contenha as pendências sinalizadas acima: </omt-bold>
        </OmtTypography>
      </OmtRow>

      <OmtRow mt="16px">
        <ImageUploader @change="onImageUploaderChange" :length="limitFiles" :initialValue="pictures" />
      </OmtRow>

      <OmtRow>
        <CDivider mt="16px" mb="16px" />
      </OmtRow>

      <OmtRow>
        <OmtTypography as="p1" mb="24px"> <b>Observações</b></OmtTypography>
      </OmtRow>
      <OmtRow>
        <CFlex flexDirection="column" alignItems="flex-end">
          <OmtTextarea
            class="input-observation"
            height="109px"
            padding="12px"
            placeholder="Inclua informações ou detalhes que podem complementar a resposta."
            @change="handleChange"
            :initialValue="observation"
          />
          <OmtTypography as="p2" color="var(--colors-light-primary-gray2)">
            {{ observation.length }}/350
          </OmtTypography>
        </CFlex>
      </OmtRow>
    </CBox>
    <template #footer>
      <OmtButton w="100%" @click="submit" :disabled="disableButton"> Enviar </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
import ImageUploader from "@/components/Molecules/ImageUploader";

export default {
  name: "PendingResponseModal",
  components: { ImageUploader },
  props: {
    reasons: {
      Type: Array
    },
    isOpen: {
      Type: Boolean
    }
  },
  data() {
    return {
      limitFiles: 20,
      observation: "",
      pictures: []
    };
  },
  computed: {
    disableButton() {
      return !this.observation;
    }
  },
  methods: {
    handleChange(text) {
      this.observation = text;
    },
    onImageUploaderChange(pictures) {
      const result = pictures?.filter(pic => pic.source && pic.name);
      this.pictures = result;
    },
    submit() {
      if (this.disableButton) return;
      this.$emit("submit", { pictures: this.pictures, obs: this.observation });
    }
  }
};
</script>

<style scoped>
.list {
  padding-left: 32px;
}
::v-deep .input-observation {
  border: 0.5px solid #dadada;
  color: #8c8c8c !important;
}

::v-deep .input-observation::placeholder {
  color: #8c8c8c !important;
}
</style>
