<template>
  <c-alert-dialog isCentered :size="size" :is-open="open" class="modal" :on-close="() => $emit('cancel')">
    <c-alert-dialog-overlay />

    <c-alert-dialog-content>
      <c-alert-dialog-header v-if="title">
        <span style="color: var(--colors-light-primary-gray1)"> {{ title }}</span>
      </c-alert-dialog-header>

      <c-alert-dialog-body>
        <div class="modal-slot">
          <slot />
        </div>
      </c-alert-dialog-body>

      <c-alert-dialog-footer v-if="close">
        <button
          :disabled="disabled"
          class="button close"
          style="width: 100%; min-height: 39px"
          @click="$emit('cancel')"
        >
          FECHAR
        </button>
      </c-alert-dialog-footer>

      <c-alert-dialog-footer v-else>
        <button style="min-height: 39px" @click="$emit('cancel')">CANCELAR</button>
        <button
          :disabled="disabled"
          class="button dark save"
          style="min-height: 39px"
          @click="$emit('continue')"
          v-if="this.continue"
        >
          CONTINUAR
        </button>
        <button v-else :disabled="disabled" class="button save" style="min-height: 39px" @click="$emit('save')">
          SALVAR
        </button>
      </c-alert-dialog-footer>
    </c-alert-dialog-content>
  </c-alert-dialog>
</template>

<script>
import {
  CAlertDialog,
  CAlertDialogHeader,
  CAlertDialogBody,
  CAlertDialogFooter,
  CAlertDialogOverlay,
  CAlertDialogContent
} from "@chakra-ui/vue";

export default {
  name: "Modal",
  components: {
    CAlertDialog,
    CAlertDialogHeader,
    CAlertDialogBody,
    CAlertDialogFooter,
    CAlertDialogOverlay,
    CAlertDialogContent
  },
  props: ["title", "size", "open", "disabled", "close", "continue"]
};
</script>

<style scoped lang="scss">
::v-deep header[data-chakra-component="CModalHeader"] {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--colors-light-primary-gray1);
  padding-bottom: 0;
}

::v-deep section[data-chakra-component="CAlertDialogContent"] {
  margin-top: 70px;
  margin-left: 16px;
  margin-right: 16px;
}

::v-deep div[data-chakra-component="CModalBody"] {
  padding-top: 0;
  padding-bottom: 0;

  .modal-slot {
    padding: 24px 0;
  }
}

::v-deep footer[data-chakra-component="CModalFooter"] {
  justify-content: start;
  padding-top: 0;
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    border: 1px solid #68788c;
    min-height: 55px;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    min-width: 50%;

    &.dark {
      background-color: #68788c !important;
    }

    &.cancel-dark {
      border-color: #68788c !important;
      color: #263c73 !important;
    }

    &.close {
      min-height: 39px;
      background-color: #102a46 !important;
    }
  }

  button:first-child {
    color: var(--colors-light-primary-gray1);
    margin-right: 8px;
  }

  button:last-child {
    background: #263c73;
    color: white;

    &[disabled] {
      background: #68788c;
    }
  }
}
</style>
