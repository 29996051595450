<script>
import { CCheckbox } from "@chakra-ui/vue";
import Vue from "vue";

/**
 * Checkbox component based on https://vue.chakra-ui.com/checkbox
 *
 * @prop {Boolean} defaultIsChecked
 * @prop {Boolean} isFullWidth
 * @prop {Boolean} isDisabled
 * @prop {String} size currently options : "sm" | "md" | "lg"
 */

export default Vue.component("OmtCheckbox", {
  props: {
    inverted: {
      type: Boolean,
      default: false
    }
  },
  render: function (createElement) {
    return createElement(
      CCheckbox,
      {
        on: { ...this.$listeners },
        attrs: {
          variantColor: "blue",
          size: "lg",
          borderColor: this.chakraTheme.colors.light.secondary.divider,
          ...(this.inverted && {
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between"
          }),
          ...this.$attrs
        }
      },
      this.$slots.default
    );
  }
});
</script>

<style scoped>
::v-deep svg[data-chakra-component="CIcon"] {
  width: 14px;
  height: 14px;
}

::v-deep [data-chakra-component="CBox"] {
  margin-left: 0 !important;
}
</style>
