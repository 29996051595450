<template>
  <omint-page>
    <Header name="Protocolo Atendimento" titleLabel="Protocolo Atendimento" />
    <omint-slider>
      <omint-page-content>
        <div>
          <div class="card">
            <div class="card-first-content">
              <i class="fas fa-2x fa-check-circle"></i>
              <div>
                <omt-h2>Solicitação realizada com sucesso</omt-h2>
                <omt-h2>Protocolo: {{ this.protocolo }}</omt-h2>
              </div>
            </div>
            <hr v-chakra mt="1rem" />
            <omt-p class="mb-0"
              >Você pode acompanhar o andamento desta solicitação pelo APP, WhatsApp e Site Omint.</omt-p
            >
          </div>
          <omt-button :bigSize="true" :onClick="submit"> Concluir </omt-button>
          <br />
        </div>
      </omint-page-content>
    </omint-slider>
    <Footer />
  </omint-page>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";

export default {
  name: "TicketProtocol",
  props: ["protocol"],
  components: {
    Header,
    Footer
  },
  computed: {
    ...mapGetters("saude", ["baseUrl", "theme"]),
    protocolo() {
      return this.$route.params.id;
    }
  },
  methods: {
    ...mapActions("saude", ["hydrate"]),

    async submit() {
      await this.$router.replace("/atendimento-lista");
    }
  },
  async mounted() {
    await this.hydrate();
    await this.$root.$emit("hide-loading");
    if (!this.protocolo) {
      this.$router.replace("/");
      return;
    }
  }
};
</script>

<style lang="scss" scoped>
hr {
  margin-bottom: 1rem;
}

.dark hr {
  opacity: 0.2;
}

.center {
  text-align: center;
}
.dark .card {
  background-color: #1f1f1f;
}
.card {
  background-color: #ffffff;
  padding: 16px;
  .card-first-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  i {
    margin-right: 16px;
  }
  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: var(--colors-light-primary-gray1);
  }
}

.page.dark {
  ::v-deep #optFilter {
    color: black;
    background-color: white;
  }
  ::v-deep h2,
  ::v-deep small,
  ::v-deep span,
  ::v-deep .add-button-fixed--icon,
  ::v-deep .title,
  ::v-deep .text,
  ::v-deep .inline-bar span {
    color: white !important;
  }
  ::v-deep.item-container {
    background-color: initial;
  }
  ::v-deep .destaque {
    background-color: #131313;
  }
}
</style>
