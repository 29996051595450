<template>
  <omint-page>
    <Header name="Aviso de atraso de pagamento" titleLabel="Aviso de atraso de pagamento" backRoute="/logout" />
    <omint-slider>
      <router-view />
    </omint-slider>
    <Footer />
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";

export default {
  name: "Nonpayment",
  components: {
    Header,
    Footer
  }
};
</script>
