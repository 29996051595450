import Bankaccount from "@/pages/bankaccount/index.vue";
import BankaccountHome from "@/pages/bankaccount/home.vue";
import BankaccountCreate from "@/pages/bankaccount/create.vue";
import AssociadoPrint from "@/pages/associado/print.vue";

const routes: Route[] = [
  {
    path: "/bankaccount",
    alias: "/conta-corrente",
    component: Bankaccount,
    children: [
      {
        path: "",
        name: "bankaccount.home",
        component: BankaccountHome,
        meta: {
          backTo: "/",
          registerAccess: { servico: "Conta Corrente", funcionalidade: "Acesso a listagem de conta corrente" },
          routerSequence: 0,
          validPath: ["/bankaccount", "/conta-corrente"]
        }
      },
      {
        path: "criar",
        name: "bankaccount.create",
        component: BankaccountCreate,
        meta: {
          backTo: "/conta-corrente",
          registerAccess: { servico: "Conta Corrente", funcionalidade: "Acesso a criação de conta corrente" },
          routerSequence: 1,
          validPath: ["/bankaccount", "/conta-corrente"]
        }
      }
    ]
  },
  {
    path: "/associado/conta-corrente/imprimir/:idx?",
    name: "bankaccount.print",
    component: AssociadoPrint,
    props: true,
    meta: {
      allowAnonymous: true,
      backTo: "/"
    }
  }
];

export default routes;
