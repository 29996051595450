<template>
  <omint-page-content>
    <CBox>
      <OmtTypography as="h1-bold" mb="4">Recuperar senha</OmtTypography>
      <OmtTypography as="p1">Para recuperar sua senha, informe o CPF utilizado para o login:</OmtTypography>
      <OmtInput
        v-model="email"
        :class="$v.email.$error && email.length != 11 ? 'warning' : ''"
        v-on:keydown.enter.prevent="onSendClick"
        v-mask="'###.###.###-##'"
        placeholder="CPF"
        type="text"
        px="2"
        class="InputLogin"
        mb="5"
      />
      <CFlex justifyContent="center" :display="['none', 'flex']">
        <OmtButton
          variant="solid"
          width="50%"
          height="50px"
          border="0.5px solid var(--colors-light-secondary-divider)"
          borderRadius="40px"
          data-cy="button-entrar"
          @click="onSendClick"
          :disabled="validateEmail"
        >
          <OmtTypography as="h1-bold" :color="validateEmail ? '' : 'white'">Enviar</OmtTypography>
        </OmtButton>
      </CFlex>
    </CBox>

    <OmtRow
      :display="['flex !important', 'none !important']"
      position="fixed"
      :bottom="['32px', '0']"
      left="0"
      p="16px"
      width="100%"
      background-color="white"
    >
      <OmtButton
        variant="solid"
        width="100%"
        height="50px"
        border="0.5px solid var(--colors-light-secondary-divider)"
        borderRadius="40px"
        data-cy="button-entrar"
        @click="onSendClick"
        :disabled="validateEmail"
      >
        <OmtTypography as="h1-bold" :color="validateEmail ? '' : 'white'">Enviar</OmtTypography>
      </OmtButton>
    </OmtRow>
  </omint-page-content>
</template>

<script>
import { mapActions } from "vuex";
import { email, required } from "vuelidate/lib/validators";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "ResetPasswordForm",
  data: function () {
    return {
      email: ""
    };
  },
  validations: {
    email: { required, email }
  },
  computed: {
    validateEmail() {
      return (
        this.email === "" ||
        this.email.length < 14 ||
        (((!this.$v.email.email && email.length !== 14) || !this.$v.email.required) && this.$v.email.$dirty)
      );
    }
  },
  methods: {
    ...mapActions("myAccount", ["sendResetPassordCode"]),
    async onSendClick() {
      try {
        if (this.email === "" || ((!this.email.email || !this.email.required) && this.email.$dirty)) return;
        await this.$root.$emit("show-loading");
        const resp = await this.sendResetPassordCode({
          email: `${this.email.replace(/[\s.-]*/gim, "")}`
        });
        await this.$root.$emit("hide-loading");
        if (resp.success) {
          await this.$router.push({
            name: "code",
            params: { email: this.email.replace(/[\s.-]*/gim, "") }
          });
        } else {
          return Swal.fire({
            title: "Aviso",
            text: resp.msg
              ? resp.msg
              : "O e-mail ou CPF informado não foi localizado. Caso não possua uma conta registrada, realize o Primeiro Acesso.",
            showCancelButton: true,
            confirmButtonText: "Tentar novamente",
            cancelButtonText: "Primeiro acesso"
          }).then(result => {
            if (result.isDismissed && result.dismiss !== "backdrop") {
              this.$router.push("/first-access/terms");
            }
          });
        }
      } catch (e) {
        await this.$root.$emit("hide-loading");
        await Swal.fire({
          title: "Aviso",
          text: "O e-mail ou CPF informado não foi localizado. Caso não possua uma conta registrada, realize o Primeiro Acesso.",
          showCancelButton: true,
          confirmButtonText: "Tentar novamente",
          cancelButtonText: "Primeiro acesso"
        }).then(result => {
          if (!result.isConfirmed) {
            this.$router.push("/first-access/terms");
          }
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.InputLogin::placeholder {
  font-size: 14px;
}

.center-page {
  display: block;
  margin: 0;
  position: absolute;
  width: calc(100% - 100px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 970px) {
  .center-page {
    width: 870px;
  }
}

.text {
  font-size: 16px !important;
  margin-bottom: 30px !important;
  color: #263c73 !important;
}

.error {
  line-height: 26px;
  margin-bottom: 30px;
  font-size: 13px;
  color: #6c6c6c;
  text-align: left;
  margin: 0;
  padding: 0;
}
</style>
