<template>
  <div class="cropper">
    <input type="file" @change="onSourceImage" v-show="false" ref="cropperInput" />

    <div class="cropper-area">
      <vue-croppie
        ref="croppieRef"
        :enableOrientation="true"
        :boundary="{ width: imgWidth * 2, height: imgHeight * 2 }"
        :viewport="{ width: imgWidth, height: imgHeight, type: 'circle' }"
        :enableResize="false"
        :showZoomer="false"
        @result="result"
      />
    </div>

    <div class="cropper-btns">
      <button class="cropper-btns__crop" @click.prevent="crop">
        <i class="fa fa-check" aria-hidden="true"></i>
      </button>
      <button class="cropper-btns__upload" @click.prevent="upload">
        <i class="fa fa-upload" aria-hidden="true"></i>
      </button>
      <button class="cropper-btns__cancel" @click.prevent="cancel">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: {
      type: String,
      required: false
    },
    imgWidth: {
      type: Number,
      required: false,
      default: 150
    },
    imgHeight: {
      type: Number,
      required: false,
      default: 150
    }
  },
  mounted() {
    this.upload();
    this.cropped = null;
    // this.$refs.croppieRef.bind({
    //   url:
    //     this.imgSrc ||
    //     "https://images.unsplash.com/photo-1527137342181-19aab11a8ee8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
    // });
  },
  data() {
    return {
      cropped: null
    };
  },
  methods: {
    onSourceImage(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      const reader = new FileReader();
      reader.onload = e => {
        this.$refs.croppieRef.bind({
          url: e.target.result
        });
      };

      reader.readAsDataURL(files[0]);
    },
    upload() {
      this.$refs.cropperInput.click();
    },
    crop() {
      this.$refs.croppieRef.result({
        format: "jpeg",
        circle: true
      });
    },
    cancel() {
      this.$emit("hideImageCropper");
    },
    result(output) {
      this.cropped = output;
      this.$emit("cropped", output);
    }
  }
};
</script>

<style lang="scss" scoped>
.cropper {
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  z-index: 9999;
  width: 100%;

  &-preview {
    border-radius: 50%;
    border: 1px dashed darkblue;
    overflow: hidden;
  }

  &-btns {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      display: block;
      background: #fff;
    }

    &__cancel,
    &__upload,
    &__crop {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      margin: 10px;
    }
  }
}
</style>
