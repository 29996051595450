<template>
  <omint-page>
    <Header name="Termos" titleLabel="" backRoute="/logout" />
    <omint-page-content>
      <CBox v-if="displayMocTerms" :pb="iOS ? '66px' : '16px'">
        <OmtTypography as="h1-bold" mb="4">
          {{ title }}
        </OmtTypography>
        <OmtTypography as="p1" mb="4" pb="0"><span v-html="text" /></OmtTypography>
        <CFlex
          justifyContent="between"
          :position="['fixed', 'relative']"
          :bottom="[iOS ? '0' : '0px', 'unset']"
          left="0"
          :p="['16px']"
          w="100%"
          background-color="white"
          flexWrap="wrap"
        >
          <CFlex w="100%">
            <OmtButton pt="16px" pb="16px" mt="16px" fontSize="20px" w="100%" @click="hideTerms()"> Voltar </OmtButton>
          </CFlex>
        </CFlex>
      </CBox>
      <CBox :pb="terms.length < 3 ? '0px' : ['150px', '0px']" v-else>
        <OmtTypography as="h1-bold" mb="4"> Avalie os termos de serviço </OmtTypography>
        <OmtTypography as="p1" mb="4"
          ><b>A avaliação é necessária</b> para que você use nossos serviços, conheça como eles funcionam e as leis que
          se aplicam à Omint.
        </OmtTypography>
        <CBox v-for="(t, idx) in terms" :key="idx" mb="16px" border="0.5px solid #DADADA" p="16px" borderRadius="8px">
          <CFlex
            alignItems="center"
            borderBottom="0.5px solid #DADADA"
            paddingBottom="16px"
            marginBottom="16px"
            @click="showTerms(idx)"
            cursor="pointer"
          >
            <CBox w="auto" justifyContent="center" d="flex" mr="8px">
              <OmtIcons name="memo" size="lg" />
            </CBox>
            <CBox w="90%">
              <OmtTypography as="h3-bold">{{ t.Titulo }}</OmtTypography>
            </CBox>
            <CBox w="auto" ml="auto">
              <OmtIcons name="angle-right" size="lg" />
            </CBox>
          </CFlex>
          <CFlex>
            <OmtButton
              :variant="answers[t.Id] == 'S' ? 'solid' : 'outline'"
              minWidth="122px"
              height="50px"
              border="0.5px solid var(--colors-light-secondary-divider)"
              borderRadius="40px"
              data-cy="button-entrar"
              @click="onSelection(t.Id, 'S')"
            >
              <OmtTypography as="h3-bold" :color="answers[t.Id] == 'S' ? 'white' : ''">Concordo</OmtTypography>
            </OmtButton>
            <OmtButton
              :variant="answers[t.Id] == 'N' ? 'solid' : 'outline'"
              minWidth="122px"
              height="50px"
              border="0.5px solid var(--colors-light-secondary-divider)"
              borderRadius="40px"
              data-cy="button-entrar"
              ml="16px"
              v-if="t.Obrigatorio === 'N'"
              @click="onSelection(t.Id, 'N')"
            >
              <OmtTypography as="h3-bold" :color="answers[t.Id] == 'N' ? 'white' : ''">Não concordo</OmtTypography>
            </OmtButton>
          </CFlex>
        </CBox>
        <CFlex v-if="!canSubmit">
          <CBox mr="8px"
            ><OmtIcons name="circle-exclamation" size="lg" color="var(--colors-light-support-error)"
          /></CBox>
          <CBox
            ><OmtTypography as="p1" color="var(--colors-light-support-error)"
              >É necessário avaliar todos os termos para prosseguir.</OmtTypography
            ></CBox
          >
        </CFlex>
      </CBox>
      <CFlex
        v-if="!displayMocTerms"
        justifyContent="between"
        :position="['fixed', 'relative']"
        :bottom="[iOS ? '0' : '0px', 'unset']"
        left="0"
        :p="['16px']"
        w="100%"
        background-color="white"
        flexWrap="wrap"
      >
        <CFlex w="100%">
          <OmtButton
            pt="16px"
            pb="16px"
            w="100%"
            fontSize="20px"
            variant="solid"
            @click="submit()"
            :isDisabled="!canSubmit"
          >
            Continuar
          </OmtButton>
        </CFlex>
        <CFlex w="100%">
          <OmtButton pt="16px" pb="16px" w="100%" @click="logout()" bg="none">
            <OmtTypography as="h2-bold" color="var(--colors-light-primary-navy-blue)">Sair</OmtTypography>
          </OmtButton>
        </CFlex>
      </CFlex>
    </omint-page-content>
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import { mapState, mapActions, mapGetters } from "vuex";
import RadioGroup from "@/components/Molecules/RadioGroup";
import { ROTA_PADRAO, ROTA_BASE, ROTA_OPTIN, ROTA_VINCULOS } from "@/utils/perfilRouteUtil";
import Footer from "@/components/Molecules/Footer";

export default {
  name: "Terms",
  components: { Header, RadioGroup, Footer },
  async mounted() {
    if (!this.terms || this.terms.length === 0) {
      await this.fetchTerms();
    }
    await this.$root.$emit("hide-loading");
    if (this.terms.length === 0) {
      const rota = this.defaultRoute;
      if (this.hasValidNextUrl) {
        await this.$router.replace(this.$route.query.nextUrl);
      } else {
        await this.$router.replace(rota);
      }
    } else {
      for (const terms of this.terms) {
        this.$set(this.answers, terms.Id, "");
      }
    }
  },
  data: function () {
    return {
      answers: {},
      canSubmit: false,
      title: "",
      text: "",
      displayMocTerms: false,
      selectedTerm: 0,
      selectedCheckbox: false,
      selectedObrigatorio: false
    };
  },
  computed: {
    ...mapState("myAccount", ["terms", "acceptance"]),
    ...mapGetters("biometrics", ["onNative"]),
    ...mapGetters("saude", ["defaultRoute"]),
    iOS() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    hasValidNextUrl() {
      return (
        this.$route.query.nextUrl &&
        this.$route.query.nextUrl !== ROTA_BASE &&
        this.$route.query.nextUrl !== ROTA_PADRAO &&
        this.$route.query.nextUrl !== ROTA_OPTIN &&
        this.$route.query.nextUrl !== ROTA_VINCULOS
      );
    }
  },
  async beforeRouteLeave(to, _, next) {
    if (this.displayMocTerms) {
      this.displayMocTerms = false;
      await next(false);
      return;
    }
    if (to?.name === "logout" && this.onNative) {
      await this.lockApp();
      await next("/login");
      return;
    }
    await next();
  },
  methods: {
    ...mapActions(["logoutUser"]),
    ...mapActions("myAccount", ["fetchTerms", "answerToTerms", "submitTerms"]),
    ...mapActions("accessManagement", ["lockApp"]),
    ...mapActions("saude", ["fetchInitRoute"]),
    changeBox() {
      if (this.selectedCheckbox) {
        this.answers[this.selectedTerm] = "S";
      } else {
        this.answers[this.selectedTerm] = "N";
      }
      this.answerToTerms({ id: this.selectedTerm, ans: this.answers[this.selectedTerm] });
      this.checkCanSubmit();
    },
    async onSelection(id, value) {
      this.answers[id] = value;
      this.answerToTerms({ id, ans: value });
      this.checkCanSubmit();
    },
    async aceite(id) {
      this.answerToTerms({ id, ans: "S" });
      this.checkCanSubmit();
    },
    checkCanSubmit() {
      this.canSubmit = Object.keys(this.answers).length === this.terms.length;
      if (this.canSubmit) {
        Object.values(this.answers).forEach(v => {
          if (!v) {
            this.canSubmit = false;
          }
        });
      }
    },
    async submit() {
      try {
        await this.$root.$emit("show-loading");
        await this.submitTerms();
      } finally {
        await this.$root.$emit("hide-loading");
      }
      let rota = await this.fetchInitRoute();
      if (rota === ROTA_PADRAO || rota === ROTA_OPTIN) rota = this.defaultRoute;
      if (this.hasValidNextUrl) {
        await this.$router.replace(this.$route.query.nextUrl);
      } else {
        await this.$router.replace(rota);
      }
    },
    logout() {
      this.$router.replace("/logout");
    },
    showTerms(id) {
      this.title = this.terms[id].Titulo;
      this.text = this.terms[id].Texto;
      this.displayMocTerms = true;
      this.$root.$emit("displayMocTerms", "/optinModal");
      this.selectedObrigatorio = this.terms[id].Obrigatorio === "S" ? true : false;
      if (this.answers[this.terms[id].Id] === "S") {
        this.selectedCheckbox = true;
      } else {
        this.selectedCheckbox = false;
      }
      this.selectedTerm = this.terms[id].Id;
    },
    hideTerms() {
      this.displayMocTerms = false;
      this.$root.$emit("displayMocTerms", "/logout");
    }
  }
};
</script>

<style scoped>
.options {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: 275px;
}

.term-box {
  display: flex;
}

.term-desc {
  width: 90%;
  margin-block-end: 0;
}

.term-link {
  width: 10%;
  margin: auto;
  text-align: right;
}
</style>
