import Vue from "vue";
import Vuex from "vuex";
import {
  AtivarAtendimentoWhatsApp,
  ObterAtendimentosAvaliacao,
  ObterDetalheAvaliacaoPrestador,
  ValidarAtendimentoWhatsApp
} from "@/api/atendimento";
import { checkUpdatePermission } from "@/utils/checkReadonlyUtil";

Vue.use(Vuex);

const initialState = {
  WhatsAppAtivo: false,
  TelefonesDisponiveis: null,
  NumeroCelular: null,
  NumeroFormatado: null,
  DDD: null,
  DDI: null,
  evaluationList: []
};

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    SET_WHATSAPP_ATIVO: (state, newState) => {
      Vue.set(state, "WhatsAppAtivo", newState?.WhatsAppAtivo ?? false);
      Vue.set(state, "TelefonesDisponiveis", newState?.TelefonesDisponiveis);
      Vue.set(state, "NumeroCelular", newState?.NumeroCelular);
      Vue.set(state, "NumeroFormatado", newState?.NumeroFormatado);
      Vue.set(state, "DDD", newState?.DDD);
      Vue.set(state, "DDI", newState?.DDI);
    },
    SET_EVALUATIONS_LIST: (state, newState) => {
      const setDescription = item => ({
        ...item,
        description:
          item.Especialidade?.toUpperCase()?.trim() === "ATENDIMENTIO SEM PAPEL"
            ? item.DataAtendimento
            : `${item.DataAtendimento} • ${item.Especialidade}`
      });
      const mapDescription = list => {
        if (!list) return [];
        return list.map(item => setDescription(item));
      };
      Vue.set(state, "evaluationList", {
        atendimentosAvaliados: mapDescription(newState?.atendimentosAvaliados),
        atendimentosNaoAvaliados: mapDescription(newState?.atendimentosNaoAvaliados)
      });
    }
  },
  getters: {},
  actions: {
    async validarAtendimentoWhatsApp(context) {
      if (context.state.WhatsAppAtivo) return;
      const validar = await ValidarAtendimentoWhatsApp({
        vinculo: context.rootState.saude.vinculo
      });
      await context.commit(
        "SET_WHATSAPP_ATIVO",
        validar ?? {
          WhatsAppAtivo: false,
          TelefonesDisponiveis: null,
          NumeroCelular: null,
          NumeroFormatado: null,
          DDD: null,
          DDI: null
        }
      );
    },
    async ativarAtendimentoWhatsApp(context, { phoneNumber, auth }) {
      checkUpdatePermission();
      let celularCompleto = phoneNumber;
      if (!auth && phoneNumber?.startsWith("+")) {
        const parts = phoneNumber.replace("+", "").replace("(", "").replace(")", "").split(" ");
        celularCompleto = `${parts[0]}${parts[1]}${parts[2].replace("-", "")}`;
      }
      const response = await AtivarAtendimentoWhatsApp({
        idUsuario: context.rootState.saude.userId ?? 0,
        vinculo: context.rootState.saude.vinculo,
        email: context.rootState.saude.username,
        celularCompleto,
        autenticacao: auth
      });
      if (response?.Data && phoneNumber?.startsWith("+")) {
        const parts = phoneNumber.replace("+", "").replace("(", "").replace(")", "").split(" ");
        const infos = {
          WhatsAppAtivo: true,
          TelefonesDisponiveis: [],
          NumeroCelular: phoneNumber,
          NumeroFormatado: parts[2],
          DDD: parts[1],
          DDI: parts[0]
        };
        context.commit("SET_WHATSAPP_ATIVO", infos);
        await context.dispatch(
          "myAccount/updateWelcomeOnBoardTourCompl",
          { pathTo: "/whatsapp", option: true },
          { root: true }
        );
      }
    },
    async fetchAtendimentosAvaliacao(context) {
      const response = await ObterAtendimentosAvaliacao({
        vinculo: context.rootState.saude.vinculo
      });

      await context.commit("SET_EVALUATIONS_LIST", response instanceof Error ? {} : response);
    },
    async fetchDetalheAvaliacaoPrestador(context, { atendimento }) {
      const response = await ObterDetalheAvaliacaoPrestador({
        vinculo: context.rootState.saude.vinculo,
        atendimento
      });

      return response instanceof Error ? [] : response?.Data ?? [];
    }
  }
};
