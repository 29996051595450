<template>
  <OmtCard data-cy="refund-detail-collapsable-card">
    <CFlex cursor="pointer" @click="handleClick">
      <StatusTile v-bind="getHeader()" />
      <OmtIcons
        ml="16px"
        name="chevron-down"
        :style="`transition: all .5s ease; transform: rotate(${isOpen ? '180deg' : '0deg'})`"
      />
    </CFlex>

    <CCollapse :isOpen="isOpen">
      <CBox mt="-15px" pt="16px">
        <CDivider mt="14px" mb="16px" />

        <OmtRow v-if="pictures.length">
          <CGrid
            w="100%"
            :templateColumns="['repeat(auto-fill, minmax(74px, 1fr))', 'repeat(auto-fill, minmax(134px, 1fr))']"
            gap="6"
          >
            <ImagePreviewer v-for="(picture, index) in pictures" :key="index" v-bind="picture" />
          </CGrid>
        </OmtRow>
        <template v-else>
          <CBox v-for="(ticket, i) in refundDetail.list" :key="i">
            <CBox v-for="(refund, index) in ticket.docs" :key="index">
              <CBox data-cy="card-content">
                <CGrid
                  :templateColumns="['repeat(auto-fit, minmax(110px, max-content))']"
                  justify-content="space-between"
                >
                  <CGridItem v-if="refund.createdAt">
                    <TextTile
                      v-chakra
                      mb="14px"
                      v-bind="{ title: 'Data do atendimento', description: refund.createdAt }"
                    />
                  </CGridItem>
                  <CGridItem v-if="refundDetail.status === 'L'">
                    <TextTile v-bind="{ title: 'Data do crédito', description: refund.creditedAt }" />
                  </CGridItem>
                  <CGridItem v-if="refundDetail.showValues && refund.requestedValue">
                    <TextTile
                      v-chakra
                      mb="14px"
                      v-bind="{ title: 'Valor apresentado', description: refund.requestedValue }"
                    />
                  </CGridItem>
                  <CGridItem v-if="refund.dscCompl" mb="5px">
                    <TextTile v-chakra v-bind="{ title: refund.dscCompl }" />
                  </CGridItem>
                  <CGridItem v-show="!!refund.receipts.length" v-for="(receipt, j) in refund.receipts" :key="j">
                    <OmtButton
                      height="18px"
                      p="0"
                      ml="10px"
                      align-items="self-end"
                      leftIcon="arrow-down-to-bracket"
                      variant="ghost"
                      iconColor="var(--colors-light-primary-navy-blue)"
                      :disabled="!receipt.source"
                      @click="download(receipt.id, receipt.source)"
                    >
                      <OmtTypography ml="-8px" as="button-small" color="var(--colors-light-primary-navy-blue)">
                        {{ receipt.label }}
                      </OmtTypography>
                    </OmtButton>
                  </CGridItem>
                </CGrid>
              </CBox>

              <CDivider v-if="index + 1 < ticket.docs.length" mt="10px" mb="10px" />

              <CBox
                v-if="
                  refund.demonstrativo &&
                  refundDetail.status === 'L' &&
                  i + 1 === refundDetail.list.length &&
                  index + 1 === ticket.docs.length
                "
              >
                <CDivider mt="24px" mb="24px" />
                <OmtButton
                  height="18px"
                  p="0"
                  align-items="self-end"
                  leftIcon="arrow-down-to-bracket"
                  variant="ghost"
                  iconColor="var(--colors-light-primary-navy-blue)"
                  @click="downloadDemonstrativo(refund.demonstrativo.source)"
                >
                  <OmtTypography ml="-8px" as="button-small" color="var(--colors-light-primary-navy-blue)">
                    {{ refund.demonstrativo.label }}
                  </OmtTypography>
                </OmtButton>
              </CBox>
            </CBox>

            <CDivider v-if="refundDetail.status === 'D' || refundDetail.status === 'P'" mt="14px" mb="14px" />

            <CBox v-if="refundDetail.status === 'D'">
              <TextTile v-chakra v-if="ticket.devolutionGroups.length > 1" v-bind="{ title: 'Motivo(s) da recusa' }" />
              <CBox v-for="(devolutionGroup, dgIndex) in ticket.devolutionGroups" :key="dgIndex">
                <OmtTypography v-if="ticket.devolutionGroups.length > 1" as="p1" mb="14px">
                  <omt-bold>{{ dgIndex + 1 }}. </omt-bold>
                  {{ devolutionGroup.Grupo }}
                </OmtTypography>
                <TextTile v-chakra v-else v-bind="{ title: devolutionGroup.Grupo }" />

                <CBox ml="20px" v-for="(devolutionReason, drIndex) in devolutionGroup.Motivos" :key="drIndex">
                  <OmtTypography as="p1" mb="14px">
                    <omt-bold v-if="devolutionGroup.Motivos.length > 1">{{ drIndex + 1 }}. </omt-bold>
                    {{ devolutionReason }}
                  </OmtTypography>
                </CBox>
              </CBox>
            </CBox>

            <CStack v-if="['P', 'U'].includes(refundDetail.status)">
              <TextTile v-if="ticket.devolutionGroups.length > 1" v-chakra v-bind="{ title: 'Pendências' }" />

              <CBox v-for="(devolutionGroup, dgIndex) in ticket.devolutionGroups" :key="dgIndex">
                <OmtTypography v-if="ticket.devolutionGroups.length > 1" as="p1" mb="14px">
                  <omt-bold>{{ dgIndex + 1 }}. </omt-bold>
                  {{ devolutionGroup.Grupo }}
                </OmtTypography>
                <TextTile v-chakra v-else v-bind="{ title: devolutionGroup.Grupo }" />

                <CBox ml="20px" v-for="(devolutionReason, drIndex) in devolutionGroup.Motivos" :key="drIndex">
                  <OmtTypography as="p1" mb="14px">
                    <omt-bold v-if="devolutionGroup.Motivos.length > 1">{{ drIndex + 1 }}. </omt-bold>
                    {{ devolutionReason }}
                  </OmtTypography>
                </CBox>
              </CBox>

              <OmtButton
                v-if="['P', 'U'].includes(refundDetail.status)"
                data-cy="answer-pendencies-button"
                size="sm"
                mt="24px"
                w="100%"
                @click="answerPendency({ ticket, refundDetail })"
              >
                {{ refundDetail.status === "P" ? "Responder pendências" : "Responder auditoria" }}
              </OmtButton>

              <OmtButton v-if="refundDetail.status === 'U'" @click="cancelPendency(ticket)" variant="ghost">
                <OmtIcons
                  font-size="16px"
                  name="trash-can"
                  mr="8px"
                  size="inherit"
                  color="var(--colors-light-support-error)"
                />
                <OmtTypography as="button-small" color="var(--colors-light-support-error)">
                  Cancelar solicitação
                </OmtTypography>
              </OmtButton>
            </CStack>

            <CDivider v-if="i + 1 < refundDetail.list.length" mt="24px" mb="24px" />
          </CBox>
        </template>

        <slot />
      </CBox>
    </CCollapse>
    <OmtStickedModal v-if="showPictureModal" @close="triggerPictureModal(false)">
      <CBox p="0 16px" min-height="80vh">
        <CImage data-cy="image-preview-modal" :src="sourcePictureModal" max-height="100vh" max-width="100%" />
      </CBox>
    </OmtStickedModal>
  </OmtCard>
</template>

<script>
import TextTile from "@/components/Molecules/TextTile";
import { StatusTile } from "./shared";
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ImagePreviewer from "@/components/Molecules/ImagePreviewer";

/**
 * ```ts
 * type refundType = {
 *  status: 'L' | 'R' | 'P' | 'A' | 'C' | 'U'
 *  total: string
 *  showValues: boolean
 *  pictures: { id: number, source: string, loadModalImage: Function }[]
 *  list: {
 *    provider: string
 *    createdAt: string
 *    creditedAt?: string
 *    requestedValue: string
 *    refundedValue?: string
 *    reasonPendencieRefused string
 *    receipts: { label: string, source: string }[]
 *  }
 * }
 * ```
 * @prop { refundType } refundDetail
 */
export default {
  components: {
    TextTile,
    StatusTile,
    ImagePreviewer
  },
  props: {
    refundDetail: Object,
    answerPendency: Function,
    cancelPendency: Function,
    isCollapsed: Boolean
  },
  data() {
    return {
      isOpen: false,
      showPictureModal: false,
      sourcePictureModal: "",
      pictures: []
    };
  },
  mounted() {
    this.isOpen = !this.isCollapsed;
    this.loadPictures();
  },
  methods: {
    ...mapActions("refund", ["fetchProtocolDetailImage"]),
    loadPictures() {
      this.refundDetail.pictures.forEach(p =>
        this.fetchProtocolDetailImage({ documento: p.id, original: false }).then(source =>
          this.pictures.push({
            id: p.id,
            source: source,
            loadModalImage: () => this.loadPicture(p.id)
          })
        )
      );
    },
    async loadPicture(id) {
      return await this.fetchProtocolDetailImage({ documento: id, original: true });
    },
    async downloadDemonstrativo(link) {
      window.location = link;
    },
    triggerPictureModal(value) {
      this.showPictureModal = value;
    },
    async download(id, link) {
      if (!link) return;
      try {
        await this.$root.$emit("show-loading");
        this.sourcePictureModal = await this.fetchProtocolDetailImage({ documento: id, original: true });
        if (this.sourcePictureModal) {
          this.triggerPictureModal(true);
          return;
        }
      } finally {
        await this.$root.$emit("hide-loading");
      }
      await Swal.fire({
        text: "A imagem não está disponível.",
        confirmButtonText: "OK"
      });
    },
    handleClick() {
      this.isOpen = !this.isOpen;
    },
    getHeader() {
      let totalRefundedValue = null;
      let count = 0;
      if (this.refundDetail.showValues && this.refundDetail.status !== "L") {
        totalRefundedValue = this.refundDetail.total;
      }
      if (this.refundDetail.list.length) {
        this.refundDetail.list.map(t => {
          count += t.docs.length;
        });
      }
      return {
        count,
        total: totalRefundedValue,
        label: this.refundDetail.description,
        color: this.refundDetail.color
      };
    }
  }
};
</script>
