<template>
  <div class="auth-document-uploader">
    <omt-highlight>Documentos</omt-highlight>
    <omt-inline-bar class="upload-bar">
      <omt-icon v-for="(f, i) in files" :idx="i" :key="i">
        <div v-if="!f.url">
          <label :for="'file' + i">
            <i class="fas fa-plus"></i>
            <i class="far fa-image fa-4x"></i>
          </label>
          <input accept="application/pdf, image/png, image/jpeg" type="file" :id="'file' + i" @change="previewFile" />
        </div>
        <div v-else @click.prevent="() => removeFile(i)">
          <i class="fas fa-minus-circle"></i>
          <img class="thumb" v-if="f.thumb" :src="f.thumb" :alt="'Anexo ' + i" />
          <i v-else class="fas fa-file-pdf fa-4x"></i>
        </div>
      </omt-icon>
    </omt-inline-bar>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { mapActions } from "vuex";

export default {
  name: "AuthDocumentUploader",
  props: ["files"],
  data: function () {
    return {
      internalFiles: [],
      totalFiles: -1,
      limitFiles: 6
    };
  },
  mounted() {
    this.internalFiles = this.files;
  },
  methods: {
    ...mapActions("saude", ["uploadFile"]),
    removeFile(fileId) {
      if (fileId < 0 || fileId > this.totalFiles) return;
      while (fileId < this.totalFiles) {
        const nextFile = this.internalFiles[fileId + 1];
        this.internalFiles[fileId] = { thumb: nextFile.thumb, url: nextFile.url };
        fileId++;
      }
      this.internalFiles[this.totalFiles] = { thumb: null, url: null };
      this.totalFiles--;
      this.recreateFiles();
    },
    recreateFiles() {
      const oldFiles = this.internalFiles;
      this.internalFiles = [];
      oldFiles.forEach(element => {
        this.internalFiles.push(element);
      });
    },
    async previewFile(event) {
      await this.$root.$emit("show-loading");
      let resp = {};
      try {
        resp = await this.uploadFile({
          filename: event.target.files[0].filename,
          blobFile: event.target.files[0]
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
      if (resp.Data && resp.Data[0]) {
        if (resp.Data[0].message !== "OK") {
          await Swal.fire({
            title: "Alerta",
            text: resp.Data[0].message,
            confirmButtonText: "OK",
            icon: "warning"
          });
        } else if (this.totalFiles + resp.Data.length > this.limitFiles - 1) {
          await Swal.fire({
            title: "Alerta",
            text: `O total de imagens e número de páginas PDF não pode ultrapassar ${this.limitFiles}`,
            confirmButtonText: "OK",
            icon: "warning"
          });
        } else {
          for (let i = 0; i < resp.Data.length; i++) {
            this.totalFiles++;
            this.internalFiles[this.totalFiles] = { thumb: resp.Data[i].thumbUrl, url: resp.Data[i].fileRemoteUrl };
          }
          this.recreateFiles();
        }
      }
    }
  },
  watch: {
    internalFiles(files) {
      this.$emit("update:files", files);
    }
  }
};
</script>

<style scoped>
.upload-bar {
  justify-content: space-between;
  flex-wrap: nowrap !important;
  margin: 0 !important;
  padding-bottom: 1rem;
}

.upload-bar div {
  margin: 0;
}

.upload-bar div i:first-child {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  right: -36px;
  background: #f0f0f0;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  z-index: 10;
  border: 2px solid #112b46;
  color: var(--colors-light-primary-gray1);
  font-size: 100%;
}

.upload-bar div input {
  display: none;
}

.upload-bar div img {
  width: 48px;
  height: 48px;
}

.upload-bar div label {
  cursor: pointer;
}
</style>
