<template>
  <CBox>
    <OmtTypography as="h1-bold" mb="8px"> Seleção de beneficiários </OmtTypography>

    <OmtTypography as="h3-semi-bold" mb="24px"> Selecione os beneficiários para emissão dos vouchers. </OmtTypography>

    <OmtRow v-for="(option, index) in vaccineBeneficiaries" :key="index">
      <CBox :mb="index !== vaccineBeneficiaries.length - 1 ? '24px' : '0px'">
        <CFlex justify="space-between" align-items="center" cursor="pointer">
          <OmtCheckbox @change="onChangeSelection(option.value)" :is-checked="option.isActive" isFullWidth inverted>
            <OmtTypography as="p1"> {{ option.label }} </OmtTypography>
          </OmtCheckbox>
        </CFlex>
      </CBox>
    </OmtRow>

    <FixedButtonRow>
      <OmtButton :w="['100%', '308px !important']" @click="onCreateClick" :disabled="!hasSelected">
        <OmtTypography
          as="h1-bold"
          :color="!vaccineType ? 'var(--colors-light-secondary-disabled-placeholder)' : 'inherit'"
        >
          Gerar
        </OmtTypography>
      </OmtButton>
    </FixedButtonRow>
  </CBox>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";
import { isMobile } from "@/utils/utils";

export default {
  name: "VoucherVaccineBeneficiaries",
  components: {
    FixedButtonRow
  },
  computed: {
    ...mapState("voucher", ["vaccineBeneficiaries", "vaccineType", "vaccineProvider"]),
    hasSelected() {
      return !!this.vaccineBeneficiaries.filter(b => b.isActive)?.length;
    },
    isMobile() {
      return isMobile();
    }
  },
  methods: {
    ...mapActions("voucher", ["fetchVaccineBeneficiaries", "createVaccineVoucher"]),
    ...mapMutations("voucher", ["SET_VACCINE_BENEFICIARIES"]),
    onChangeSelection(value) {
      const beneficiaries = this.vaccineBeneficiaries.map(b => ({
        value: b.value,
        label: b.label,
        isActive: b.value === value ? !b.isActive : b.isActive ?? false
      }));
      this.SET_VACCINE_BENEFICIARIES(beneficiaries);
    },
    async onCreateClick() {
      if (!this.hasSelected) return;
      this.$root.$emit("show-loading");
      let url = "";
      try {
        const beneficiaries = this.vaccineBeneficiaries.filter(b => b.isActive).map(b => b.value);
        url = await this.createVaccineVoucher({
          beneficiaries
        });
      } finally {
        this.$root.$emit("hide-loading");
      }
      if (url === "") {
        this.$toast({
          description: "Não foi possível gerar o voucher de vacina",
          status: "error",
          duration: 10000,
          position: "top"
        });
      } else {
        if (this.isMobile) {
          window.location = url;
        } else {
          window.open(url, "_blank");
        }
        this.$router.push("/");
      }
    }
  },
  async mounted() {
    this.$root.$emit("show-loading");
    if (!this.vaccineType || !this.vaccineProvider) {
      this.$router.replace({
        name: "voucher.vaccine.types"
      });
      return;
    }
    try {
      await this.fetchVaccineBeneficiaries();
    } catch (error) {
      window.$log.error("Não foi possível carregar os beneficiarios para a vacina", error);
    } finally {
      this.$root.$emit("hide-loading");
    }
  }
};
</script>
<style scoped></style>
