<template>
  <OmtStickedModal v-if="isOpen" @close="close" data-cy="refund-create-comments-modal">
    <OmtIcons
      :name="icon ? icon : 'check-circle'"
      size="2xl"
      mb="32px"
      :color="iconColor ? iconColor : 'var(--colors-light-support-success)'"
    />
    <OmtTypography as="h4-bold" mb="24px">
      {{ title }}
    </OmtTypography>

    <CFlex mb="24px">
      <OmtIcons name="square-poll-horizontal" mr="12px" />
      <OmtTypography as="h2-bold"> Protocolo: {{ protocol }} </OmtTypography>
    </CFlex>

    <CBox borderRadius="8px" bg="var(--colors-light-primary-light-gray)" p="12px 16px" mb="24px">
      <CFlex mb="16px">
        <OmtIcons name="bell-on" mr="14px" />
        <OmtTypography as="h2-bold" v-if="textAlert"> {{ textAlert }} </OmtTypography>
        <CBox v-else-if="dueDate && this.dueDate.length > 1">
          <OmtTypography as="h2-bold">
            Após a data de recebimento, a análise da solicitação será realizada em até:
          </OmtTypography>
          <CBox mt="16px">
            <ul>
              <li v-for="(prazo, index) in dueDate" :key="`prazo-${index}`" v-html="prazo.prazo"></li>
            </ul>
          </CBox>
        </CBox>
        <CBox v-else-if="dueDate && dueDate.length == 1">
          <OmtTypography as="h2-bold">{{ dueDate[0].prazo }}</OmtTypography>
        </CBox>
        <CBox v-else>
          <OmtTypography as="h2-bold">A análise do pedido será feita em até 1 dia útil</OmtTypography>
        </CBox>
      </CFlex>
      <CBox>
        <OmtTypography as="p1" mb="16px"> Em caso de pendências você será notificado via WhatsApp. </OmtTypography>
      </CBox>
      <CBox>
        <OmtTypography as="p1" mb="16px"> Você poderá acompanhar o status na tela de Autorizações. </OmtTypography>
      </CBox>
    </CBox>

    <OmtTypography as="h3-semi-bold" mb="16px" v-if="avaliation">
      Como você avalia sua experiência com esse serviço pelo APP Minha Omint?
    </OmtTypography>

    <CFlex justify="space-between" mb="8px" v-if="avaliation">
      <OmtButton
        :variant="rate === selectedRate ? 'solid' : 'outline'"
        width="48px"
        height="50px"
        border="0.5px solid var(--colors-light-secondary-divider)"
        borderRadius="50%"
        data-cy="rate-buttons"
        v-for="rate in 5"
        :key="`rate-${rate}`"
        @click="selectRate(rate)"
      >
        <OmtTypography as="p1" :color="rate === selectedRate ? 'white' : 'inherit'"> {{ rate }} </OmtTypography>
      </OmtButton>
    </CFlex>

    <CFlex justify="space-between" mb="22px" v-if="avaliation">
      <OmtTypography as="p2"> Muito difícil </OmtTypography>
      <OmtTypography as="p2"> Muito fácil </OmtTypography>
    </CFlex>

    <OmtRow v-if="selectedRate && selectedRate <= 2">
      <CFlex flexDirection="column" alignItems="flex-end">
        <OmtTextarea
          class="input-observation"
          height="109px"
          padding="12px"
          placeholder="Inclua informações ou detalhes que podem complementar sua avaliação."
          @change="handleChange"
          :initialValue="observation"
        />
        <OmtTypography as="p2" color="var(--colors-light-primary-gray2)"> {{ observation.length }}/350 </OmtTypography>
      </CFlex>
    </OmtRow>

    <template #footer>
      <OmtButton w="100%" @click="save" :disabled="avaliation && selectedRate === null">
        {{ buttonText ? buttonText : "Concluir" }}
      </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
/**
 * @prop {Boolean} isOpen
 * @prop {String} protocol
 * @prop {Object} dueDate
 */
export default {
  props: {
    title: {
      Type: String
    },
    isOpen: {
      Type: Boolean
    },
    protocol: {
      Type: String
    },
    dueDate: {
      Type: Object
    },
    avaliation: {
      Type: Boolean,
      default: true
    },
    textAlert: {
      Type: String
    },
    textDescription: {
      Type: String
    },
    icon: {
      Type: String
    },
    iconColor: {
      Type: String
    },
    buttonText: {
      Type: String
    }
  },
  data() {
    return {
      selectedRate: null,
      observation: ""
    };
  },
  computed: {},
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      this.$emit("save", {
        ticket: this.protocol,
        rating: this.selectedRate,
        obs: this.observation
      });

      this.$emit("update:isOpen", false);
    },
    selectRate(rate) {
      this.selectedRate = rate;
    },
    handleChange(text) {
      this.observation = text;
    }
  }
};
</script>
