<template>
  <CBox>
    <CStack :position="['absolute', 'relative']" :bottom="['66px', '0']" :left="['16px', '0']" :right="['16px', '0']">
      <OmtButton @click="$emit('continue')" v-bind="$attrs"> Continuar </OmtButton>
      <OmtButton @click="$emit('cancel')" variant="ghost">
        <OmtIcons font-size="16px" name="trash-can" mr="8px" size="inherit" color="var(--colors-light-support-error)" />
        <OmtTypography as="button-small" color="var(--colors-light-support-error)">
          Cancelar solicitação
        </OmtTypography>
      </OmtButton>
    </CStack>
    <CBox :height="['124px', '0']" />
  </CBox>
</template>

<script>
export default {};
</script>
