<template>
  <CGrid :templateColumns="`repeat(${totalSteps}, 1fr)`" gap="14px">
    <CGridItem
      v-for="index in totalSteps"
      :key="index"
      :class="[inverseColor ? 'inverse step' : 'step', currentStep >= index && 'step-active']"
    />
  </CGrid>
</template>

<script>
/**
 * @prop { number } currentStep
 * @prop { number } totalSteps
 * @prop { boolean } inverseColor
 */
export default {
  props: {
    currentStep: Number,
    totalSteps: Number,
    inverseColor: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
$height: 4px;

@keyframes fill-step {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.step {
  height: $height;
  background: var(--colors-light-secondary-divider);
  position: relative;
}

.step-active::after {
  content: "";
  animation: fill-step 0.5s ease-out forwards;
  position: absolute;
  height: $height;
  left: 0;
  bottom: 0;
  background: var(--colors-light-primary-navy-blue);
}

.inverse .step {
  background: var(--colors-light-primary-navy-blue);
}

.inverse .step-active::after {
  background: var(--colors-light-secondary-divider);
}
</style>
