// Syleguide Docummentation https://www.figma.com/file/kb5mo2IrfyADD4G94JIpMB/Kipp-App?node-id=10%3A2

const h1 = { as: "h1" };
const h2 = { as: "h2" };
const h3 = { as: "h3" };
const h4 = { as: "h4" };
const p = { as: "p" };
const button = { as: "span" };
const link = { as: "span" };

const propProvider = (fontSize, lineHeight, fontWeight) => ({
  fontSize,
  lineHeight,
  fontWeight
});

const typography = {
  "h1-extra-large": {
    ...h1,
    ...propProvider("36px", "42px", 700)
  },
  "h1-large": {
    ...h1,
    ...propProvider("24px", "27px", 700)
  },
  "h1-regular": {
    ...h1,
    ...propProvider("20px", "27px", 400)
  },
  "h1-bold": {
    ...h1,
    ...propProvider("20px", "24px", 700)
  },
  "h1-semi-bold": {
    ...h1,
    ...propProvider("18px", "20px", 700)
  },
  "h2-bold": {
    ...h2,
    ...propProvider("16px", "20px", 700)
  },
  "h2-semi-bold": {
    ...h2,
    ...propProvider("16px", "20px", 600)
  },
  "h2-regular": {
    ...h2,
    ...propProvider("16px", "20px", 400)
  },
  "h3-regular": {
    ...h2,
    ...propProvider("14px", "19px", 400)
  },
  "h3-bold": {
    ...h3,
    ...propProvider("14px", "19px", 700)
  },
  "h3-semi-bold": {
    ...h3,
    ...propProvider("14px", "19px", 600)
  },
  "h4-bold": {
    ...h4,
    ...propProvider("24px", "32px", 700)
  },
  p0: {
    ...p,
    ...propProvider("12px", "18px", 700)
  },
  p1: {
    ...p,
    ...propProvider("14px", "20px", 400)
  },
  "p1-bold": {
    ...p,
    ...propProvider("14px", "20px", 700)
  },
  p2: {
    ...p,
    ...propProvider("12px", "18px", 400)
  },
  p3: {
    ...p,
    ...propProvider("16px", "25px", 400)
  },
  p4: {
    ...p,
    ...propProvider("18px", "25px", 400)
  },
  "p4-semi-bold": {
    ...p,
    ...propProvider("18px", "19px", 700)
  },
  p5: {
    ...p,
    ...propProvider("24px", "33px", 400)
  },
  "button-small": {
    ...button,
    ...propProvider("14px", "18px", 700)
  },
  "button-medium": {
    ...button,
    ...propProvider("16px", "18px", 700)
  },
  "button-large": {
    ...button,
    ...propProvider("20px", "24px", 700)
  },
  l1: {
    ...link,
    ...propProvider("12px", "18px", 700)
  },
  l2: {
    ...link,
    ...propProvider("14px", "18px", 700)
  }
};

export const colors = {
  dark: "#bcbdc0",
  light: "#4E4E4E"
};

export const options = Object.keys(typography);
export default typography;
