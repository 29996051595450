<template>
  <omint-page>
    <Header name="Minhas solicitações" titleLabel="Minhas solicitações" />
    <router-view />
    <Footer />
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";

export default {
  name: "MyRequests",
  components: {
    Header,
    Footer
  }
};
</script>
