<template>
  <omint-page-content>
    <omt-highlight>Observações (máximo de 250 caracteres)</omt-highlight>
    <omt-textarea type="text" v-bind:value="obs" v-on:input="obs = $event" rows="5" maxlength="250" />

    <auth-document-uploader :files.sync="files" />

    <omt-buttons-bar>
      <omt-button :onClick="() => $router.back()"> Voltar </omt-button>
      <omt-button :on-click="submit" :disabled="disableSubmitButton"> Prosseguir </omt-button>
    </omt-buttons-bar>
    <br />
  </omint-page-content>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import AuthDocumentUploader from "@/components/Organisms/Auths/AuthDocumentUploader.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "NewTicketForm",
  components: {
    AuthDocumentUploader
  },
  params: ["idCC"],
  data() {
    return {
      obs: "",
      files: new Array(6).fill({ thumb: null, url: null })
    };
  },
  computed: {
    disableSubmitButton() {
      return this.chamadoRef ? this.obs === "" : this.obs === "" || this.files.filter(file => !!file?.url).length <= 0;
    },
    chamadoRef() {
      return this.$attrs.idCC;
    }
  },
  methods: {
    ...mapActions("auths", ["createNewAuth"]),
    ...mapMutations("ticket", ["SET_RATING_INFOS"]),
    async submit() {
      await this.$root.$emit("show-loading");
      let resp = {};
      try {
        await this.$root.$emit("show-loading");
        resp = await this.createNewAuth({
          obs: this.obs,
          files: this.files.filter(file => !!file?.url).map(file => file.url),
          refAuth: this.chamadoRef,
          classification: "5852"
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
      if (resp.Data) {
        this.SET_RATING_INFOS({ ratingRoute: "/autorizacao-lista", ratingType: 4 });
        await this.$router.replace({
          path: `/experience-rating/${resp.Data.protocoloANS}/4/S`
        });
      } else {
        await Swal.fire({
          title: "Erro",
          text: "Não foi possivel registrar o pedido de autorização.\nServiço temporariamente indisponível.",
          confirmButtonText: "OK",
          icon: "error"
        });
      }
    }
  }
};
</script>

<style scoped>
::v-deep.textarea {
  margin-top: 1rem;
}
</style>
