<template>
  <CBox>
    <OmtTypography as="p1" mb="16px">
      Você pode cadastrar até <b>duas contas correntes pessoais</b>, vinculadas ao seu CPF.
    </OmtTypography>
    <OmtTypography as="p1" mb="16px">
      Estas contas serão utilizadas para os <b>créditos de reembolso</b>.
    </OmtTypography>
    <OmtCard v-for="(conta, index) in contas" :key="index" w="100%" mb="24px" v-show="!conta.removida">
      <CFlex direction="row" justify="flex-start" flex-wrap="wrap">
        <OmtIcons
          v-if="!isChanging && titular"
          name="star"
          :prefix="conta.principal ? 'fa-solid' : 'fa-light'"
          height="16px"
          width="16px"
          size="lg"
          mr="12px"
          color="var(--colors-light-primary-navy-blue)"
          cursor="pointer"
          @click.prevent="onPrincipalClick(conta)"
        />
        <CBox :w="!isChanging && titular ? 'calc(100% - 48px)' : '100%'">
          <CFlex direction="column" mb="12px" w="100%">
            <CFlex direction="row" justify="space-between" flex-wrap="wrap">
              <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Favorecido</OmtTypography>
              <OmtIcons
                v-if="!isChanging"
                name="trash-can"
                height="16px"
                width="16px"
                size="lg"
                ml="16px"
                cursor="pointer"
                color="var(--colors-light-primary-navy-blue)"
                @click.prevent="onRemoverContaClick(conta)"
              />
            </CFlex>
            <OmtInput is-disabled v-model="conta.nomeFavorecido" fontSize="14px" fontWeight="700" max-size="10" />
          </CFlex>
          <CFlex direction="column" mb="24px">
            <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">CPF</OmtTypography>
            <OmtInput is-disabled v-model="conta.cpfFavorecido" fontSize="14px" fontWeight="700" max-size="10" />
          </CFlex>
          <CFlex direction="column" mb="12px">
            <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Banco</OmtTypography>
            <OmtInput v-if="conta.id" is-disabled v-model="conta.nomeBanco" fontSize="14px" fontWeight="700" />
            <OmtSelect
              v-else
              placeholder="Selecione o banco"
              @change="e => onBancoChange(conta, e)"
              :initialValue="conta.Codigo"
              class="select-banco"
            >
              <option v-for="(c, index) in banks" :key="index" :value="c.Codigo">
                {{ c.Descricao }}
              </option>
            </OmtSelect>
          </CFlex>
          <CFlex direction="column" mb="24px">
            <OmtTypography as="p1" color="var(--colors-light-primary-gray2)" mb="8px">Agência</OmtTypography>
            <OmtInput
              v-if="conta.id"
              is-disabled
              v-model="conta.nomeAgencia"
              fontSize="14px"
              fontWeight="700"
              class="input-conta-corrente"
            />
            <template v-else>
              <OmtSelect v-if="conta.isLoadingAgencia" placeholder="Digite o número da agência" is-loading></OmtSelect>
              <OmtTypography v-else as="h3-bold">
                <OptionsAutoCompleter
                  is-solid
                  :options="conta.agencias"
                  name="agencias"
                  id="agencies-list"
                  :onSelection="e => onAgenciaChange(conta, e)"
                  class="input-conta-corrente"
                  placeholder="Digite o número da agência"
                />
              </OmtTypography>
            </template>
          </CFlex>
          <CFlex direction="column" mb="24px">
            <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Conta</OmtTypography>
            <OmtInput
              :disabled="conta.id || isChanging"
              v-model="conta.numero"
              v-mask="'############'"
              fontSize="14px"
              fontWeight="700"
              max-size="12"
              class="input-conta-corrente"
              placeholder="Digite o número da conta"
            />
          </CFlex>
          <CFlex v-if="conta.mascaraDigito" direction="column" mb="12px">
            <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Dígito da conta</OmtTypography>
            <OmtInput
              :disabled="conta.id || isChanging"
              v-model="conta.digito"
              v-mask="conta.mascaraDigito"
              fontSize="14px"
              fontWeight="700"
              max-size="10"
              class="input-conta-corrente"
              placeholder="Digite o dígito da conta"
            />
          </CFlex>
        </CBox>
      </CFlex>
    </OmtCard>

    <FixedButtonRow :useFooter="false" :solidColor="true" :fixedSize="podeAdicionarConta ? '2x' : '1x'">
      <CFlex direction="column" :w="['100%', '308px !important']">
        <OmtButton
          v-if="podeAdicionarConta"
          @click="onAdicionarContaClick"
          w="100%"
          variant="ghost"
          color="var(--colors-light-primary-navy-blue)"
        >
          {{ labelAdicionarConta }}
        </OmtButton>
        <OmtButton
          @click.prevent="onNextClick"
          :w="['100%', '308px !important']"
          variant="solid"
          :disabled="!canSubmit"
        >
          Continuar
        </OmtButton>
      </CFlex>
    </FixedButtonRow>
  </CBox>
</template>

<script>
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";
import OptionsAutoCompleter from "@/components/Molecules/OptionsAutoCompleter";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: { FixedButtonRow, OptionsAutoCompleter },
  data() {
    return {
      contas: [],
      nomeFavorecido: "",
      cpfFavorecido: "",
      bancoAgencias: {}
    };
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      const beneficiario = this.vinculo !== null && this.vinculo.length === 10 ? this.vinculo.substring(8, 10) : "00";
      const actions = [this.hasActiveTicket({ beneficiaryCode: beneficiario })];
      if (this.selectedBeneficiary !== beneficiario) {
        this.SET_SELECTED_BENEFICIARY(beneficiario);
        actions.push(this.fetchAccounts({ beneficiaryCode: beneficiario }));
      }
      if (!this.beneficiaries?.length) actions.push(this.fetchBeneficiaries());
      if (!this.banks?.length) actions.push(this.listAvailableBanks());
      await Promise.all(actions);
      await this.initialValues();
    } catch (error) {
      this.$toast({
        description: error.message,
        status: "error",
        duration: 10000,
        position: "bottom-right"
      });
    } finally {
      this.$root.$emit("hide-loading");
    }
  },
  computed: {
    ...mapState("saude", ["vinculo", "titular"]),
    ...mapState("bankAccount", [
      "banks",
      "agencies",
      "isChanging",
      "accounts",
      "selectedBeneficiary",
      "beneficiaries",
      "newAccounts"
    ]),
    ...mapState("myAccount", ["welcomeOnBoardTourAuth"]),
    contasNaoRemovidas() {
      return (this.contas ?? []).filter(c => !c.removida) ?? [];
    },
    podeAdicionarConta() {
      if (this.isChanging) return false;
      return this.contasNaoRemovidas.length < 2;
    },
    labelAdicionarConta() {
      return this.contasNaoRemovidas.length ? "Adicionar outra Conta Corrente" : "Adicionar Conta Corrente";
    },
    canSubmit() {
      return !this.contasNaoRemovidas.find(
        c => c.codigoBanco === "" || c.codigoAgencia === "" || c.numero === "" || (c.mascaraDigito && c.digito === "")
      );
    }
  },
  methods: {
    ...mapActions("bankAccount", [
      "hasActiveTicket",
      "fetchAccounts",
      "fetchBeneficiaries",
      "listAvailableBanks",
      "listAvailableAgencies",
      "submitBeneficiaryBankInfosFromTour"
    ]),
    ...mapActions("myAccount", ["checkNextWelcomeOnBoardTour"]),
    ...mapMutations("bankAccount", ["SET_SELECTED_BENEFICIARY", "SET_NEW_ACCOUNTS"]),
    ...mapMutations("myAccount", ["SET_WELCOME_ONBOARD_TOUR_AUTH_RULES"]),
    async initialValues() {
      const beneficiary = this.beneficiaries.find(b => b.value === this.selectedBeneficiary);
      if (!beneficiary) {
        this.$router.replace({ name: "bankaccount.home" });
        return;
      }
      this.nomeFavorecido = beneficiary.label;
      this.cpfFavorecido = beneficiary.document;

      let contas = [];
      if (this.newAccounts?.length) {
        contas = this.newAccounts.map(async conta => {
          const resumo = {
            id: conta.idConta,
            principal: conta.principal,
            nomeFavorecido: this.nomeFavorecido,
            cpfFavorecido: this.cpfFavorecido,
            codigoBanco: "",
            nomeBanco: "",
            isLoadingAgencia: false,
            agencias: [],
            codigoAgencia: "",
            nomeAgencia: "",
            removida: conta.excluir,
            numero: conta.conta,
            digito: conta.digito
          };
          await this.onBancoChange(resumo, conta.banco);
          this.onAgenciaChange(resumo, conta.agencia);
          return resumo;
        });
      } else {
        contas = (this.accounts ?? []).map(async conta => {
          const resumo = {
            id: conta.id_ctacredito,
            principal: conta.tp_principal === "S",
            nomeFavorecido: this.nomeFavorecido,
            cpfFavorecido: this.cpfFavorecido,
            codigoBanco: "",
            nomeBanco: "",
            isLoadingAgencia: false,
            agencias: [],
            codigoAgencia: "",
            nomeAgencia: "",
            numero: conta.nr_conta,
            digito: conta.at_dac
          };
          await this.onBancoChange(resumo, conta.cd_banco);
          this.onAgenciaChange(resumo, conta.cd_agencia);
          return resumo;
        });
      }
      this.contas = await Promise.all(contas);
    },
    onPrincipalClick(conta) {
      if (!conta || this.isChanging || !this.titular) return;

      const principal = !conta.principal;
      if (principal) {
        const indexConta = this.contas.indexOf(conta);
        const contas = this.contas.map((c, index) => {
          const conta = { ...c };
          conta.principal = index === indexConta;
          return conta;
        });
        this.contas = contas;
      } else {
        conta.principal = principal;
      }
    },
    onRemoverContaClick(conta) {
      if (!conta || this.isChanging) return;

      const indexConta = this.contas.indexOf(conta);
      const contas = this.contas.map((c, index) => {
        const conta = { ...c };
        if (index === indexConta) conta.removida = true;
        return conta;
      });
      this.contas = contas;
    },
    async onBancoChange(conta, banco) {
      if (!conta || !banco) return;

      conta.isLoadingAgencia = true;
      if (!this.bancoAgencias[banco]) {
        await this.listAvailableAgencies({ bankCode: banco });
        this.bancoAgencias[banco] = (this.agencies ?? []).map(a => ({ label: a.Descricao, value: a.Codigo }));
      }
      const dadosBanco = (this.banks ?? []).find(b => b.Codigo === banco);
      conta.codigoBanco = dadosBanco?.Codigo ?? "";
      conta.nomeBanco = dadosBanco?.Descricao ?? "";
      conta.agencias = this.bancoAgencias[banco];
      conta.isLoadingAgencia = false;
      conta.codigoAgencia = "";
      conta.nomeAgencia = "";
      switch (dadosBanco.Digito ?? 0) {
        case 1:
          if (conta.digito && conta.digito.length > 1) {
            conta.digito = conta.digito.substring(0, 1);
          }
          conta.mascaraDigito = "#";
          break;
        case 2:
          conta.mascaraDigito = "##";
          break;
        default:
          conta.digito = "";
          conta.mascaraDigito = "";
          break;
      }
    },
    onAgenciaChange(conta, agencia) {
      if (!conta?.codigoBanco || !agencia) return;
      const dadosAgencia = (this.bancoAgencias[conta.codigoBanco] ?? []).find(a => a.value === agencia);
      conta.codigoAgencia = dadosAgencia?.value ?? "";
      conta.nomeAgencia = dadosAgencia?.label ?? "";
    },
    onAdicionarContaClick() {
      if (!this.podeAdicionarConta) return;
      this.contas.push({
        id: 0,
        principal: false,
        nomeFavorecido: this.nomeFavorecido,
        cpfFavorecido: this.cpfFavorecido,
        codigoBanco: "",
        nomeBanco: "",
        mascaraDigito: "",
        isLoadingAgencia: false,
        agencias: [],
        codigoAgencia: "",
        nomeAgencia: "",
        numero: "",
        digito: ""
      });
      this.$nextTick(() => window.scrollTo(0, document.body.scrollHeight));
    },
    onNextClick() {
      let contasRemovidas = (this.contas.filter(c => c.removida && c.id) ?? []).map(c => ({ ...c }));
      let adicionadas = (this.contasNaoRemovidas.filter(c => !c.id) ?? []).map(c => ({ ...c }));
      adicionadas.forEach(a => {
        const marcadaParaRemover = contasRemovidas.find(
          r =>
            r.codigoBanco === a.codigoBanco &&
            r.codigoAgencia === a.codigoAgencia &&
            r.numero === a.numero &&
            r.digito === a.digito
        );
        if (marcadaParaRemover) {
          a.id = marcadaParaRemover.id;
          marcadaParaRemover.removida = false;
        }
      });
      contasRemovidas = contasRemovidas.filter(c => c.removida) ?? [];
      adicionadas = adicionadas.filter(c => !c.id);

      const contaPrincipalAtual = (this.accounts ?? []).find(c => c.tp_principal === "S")?.id_ctacredito ?? 0;
      const novaContaPrincipal = (this.contas ?? []).find(c => c.principal)?.id ?? 0;
      const contaPrincipalAlterada = contaPrincipalAtual !== novaContaPrincipal;
      const contaPrincipalEhNovaConta = !contaPrincipalAlterada || !novaContaPrincipal;

      const alteracoes = [];
      contasRemovidas.forEach(c =>
        alteracoes.push({
          beneficiario: this.selectedBeneficiary,
          idConta: c.id,
          banco: c.codigoBanco,
          agencia: c.codigoAgencia,
          conta: c.numero,
          digito: c.digito,
          excluir: true,
          principal: c.principal
        })
      );
      adicionadas.forEach(c =>
        alteracoes.push({
          beneficiario: this.selectedBeneficiary,
          idConta: c.id,
          banco: c.codigoBanco,
          agencia: c.codigoAgencia,
          conta: c.numero,
          digito: c.digito,
          excluir: false,
          principal: c.principal
        })
      );
      if (contaPrincipalAlterada && !contaPrincipalEhNovaConta && novaContaPrincipal) {
        const c = this.contas.find(c => c.principal);
        alteracoes.push({
          beneficiario: this.selectedBeneficiary,
          idConta: c.id,
          banco: c.codigoBanco,
          agencia: c.codigoAgencia,
          conta: c.numero,
          digito: c.digito,
          excluir: false,
          principal: c.principal
        });
      }
      this.SET_NEW_ACCOUNTS(alteracoes);
      if (!alteracoes.length) {
        this.nextTask();
      } else if (this.welcomeOnBoardTourAuth) {
        this.submit();
      } else {
        this.SET_WELCOME_ONBOARD_TOUR_AUTH_RULES({
          routeBack: this.$route.path,
          action: "bankAccount/submitBeneficiaryBankInfosFromTour"
        });
        this.$router.push("/welcome/identity-verification");
      }
    },
    async nextTask() {
      const rota = await this.checkNextWelcomeOnBoardTour();
      this.$router.replace(rota);
    },
    async submit() {
      try {
        this.$root.$emit("show-loading");
        await this.submitBeneficiaryBankInfosFromTour();
        this.nextTask();
      } catch (error) {
        this.$toast({
          description: error.message,
          status: "error",
          duration: 10000,
          position: "bottom-right"
        });
      } finally {
        this.$root.$emit("hide-loading");
      }
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep .input-conta-corrente {
  input {
    padding: 0 !important;
    color: #4e4e4e;
    font-weight: 700;
    font-size: 14px !important;
  }

  .chevron {
    font-size: 12px;
    color: #4e4e4e;
  }

  input::placeholder {
    color: #4e4e4e;
    font-weight: 700;
    font-size: 14px;
  }
}

::v-deep .select-banco {
  svg {
    transform: rotate(-90deg);
  }
}
</style>
