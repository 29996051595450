import Agendamento from "@/pages/agendamento/index.vue";
import AgendamentoSchedule from "@/pages/agendamento/schedule.vue";
import AgendamentoDetails from "@/pages/agendamento/details.vue";
import VideoCall from "@/pages/video-call/index.vue";
import VideoCallTaskVideoCall from "@/pages/video-call/task-video-call.vue";
import VideoCallTaskPreCall from "@/pages/video-call/task-pre-call.vue";
import OmintVideoCall from "@/pages/video-call/omint-video-call.vue";

const routes: Route[] = [
  {
    path: "/agendamento",
    alias: "/redireciona-dr-omint",
    component: Agendamento,
    params: true,
    children: [
      {
        path: "",
        name: "tasks.schedule",
        component: AgendamentoSchedule,
        params: true,
        meta: {
          backTo: "/",
          registerAccess: { servico: "Dr. Omint", funcionalidade: "Acesso a DrOmint" },
          validPath: ["/redireciona-dr-omint"]
        }
      },
      {
        path: "details",
        alias: "/video-call/details",
        name: "tasks.details",
        component: AgendamentoDetails,
        params: true,
        meta: {
          backTo: "/",
          checkPathDisabled: true
        }
      },
      {
        path: "equipe-saude",
        name: "tasks.equipe",
        alias: "/equipe-saude",
        component: AgendamentoSchedule,
        params: true,
        meta: {
          backTo: "/",
          registerAccess: { servico: "Equipe de Saude", funcionalidade: "Acesso a Equipe de Saude" },
          validPath: ["/equipe-saude", "/agendamento/equipe-saude"]
        }
      },
      {
        path: "reagendar",
        name: "tasks.reagendar",
        alias: "/reagendar-equipe",
        component: AgendamentoSchedule,
        params: true,
        meta: {
          checkPathDisabled: true
        }
      },
      {
        path: "indicacao",
        name: "tasks.indicacao",
        component: AgendamentoSchedule,
        params: true,
        meta: {
          validPath: ["/redecredenciada", "/redecredenciada-home"]
        }
      },
      {
        path: "/agendamento/:prestador",
        name: "tasks.prestador",
        component: AgendamentoSchedule,
        params: true,
        meta: {
          registerAccess: { servico: "Agenda Prestador", funcionalidade: "Acesso a Agenda Prestador" },
          validPath: ["/redecredenciada", "/redecredenciada-home"]
        }
      }
    ]
  },
  {
    path: "/video-call",
    component: VideoCall,
    params: true,
    children: [
      {
        path: "",
        name: "tasks.call",
        component: VideoCallTaskVideoCall,
        params: true,
        meta: {
          checkPathDisabled: true
        }
      },
      {
        path: "/video-call/pre-call/:chamado",
        name: "tasks.precall",
        component: VideoCallTaskPreCall,
        params: true,
        meta: {
          checkPathDisabled: true
        }
      }
    ]
  },
  {
    path: "/video-call/atendimento/:chamado",
    name: "tasks.video-chamada",
    component: OmintVideoCall,
    params: true,
    meta: {
      checkPathDisabled: true
    }
  }
];

export default routes;
