<template>
  <CModal
    scroll-behavior="outside"
    size="full"
    :block-scroll-on-mount="false"
    :is-centered="false"
    :is-open="true"
    :closeOnOverlayClick="false"
    :closeOnEsc="false"
    :onClose="onCloseClick"
  >
    <CModalOverlay />
    <CModalContent :w="['100%', 'min(100%, 938px)']">
      <CModalHeader>
        <CFlex w="100%" direction="column">
          <OmtRow mb="24px">
            <OmtIcons name="face-viewfinder" size="3x" color="var(--colors-light-primary-navy-blue)" />
          </OmtRow>

          <OmtRow>
            <OmtTypography as="h1-bold"> Validação de biometria </OmtTypography>
          </OmtRow>
        </CFlex>
      </CModalHeader>
      <CModalCloseButton />
      <CModalBody>
        <OmtRow>
          <BiometryAuthCard />
        </OmtRow>
      </CModalBody>
      <CModalFooter>
        <CFlex w="100%" direction="column">
          <OmtRow>
            <BiometryCameraButton
              @success="handleBiometryCameraSuccess"
              @error="handleBiometryCameraError"
              @resourceError="handleResourceError"
              @startLoading="$root.$emit('show-loading')"
              @endLoading="$root.$emit('hide-loading')"
              buttonLabel="Continuar"
              :confirmUser="true"
            />
          </OmtRow>
          <OmtRow>
            <OmtButton
              v-bind="{
                ...{
                  variant: 'ghost',
                  width: '100%',
                  ...$attrs
                }
              }"
              @click="onCloseClick"
            >
              Tentar mais tarde
            </OmtButton>
          </OmtRow>
        </CFlex>
      </CModalFooter>
    </CModalContent>
  </CModal>
</template>

<script>
import BiometryCameraButton from "@/components/Organisms/Biometry/BiometryCameraButton";
import BiometryAuthCard from "@/components/Organisms/Biometry/BiometryAuthCard.vue";
import { mapActions } from "vuex";

export default {
  name: "TokenUnicoModal",
  props: {
    service: {
      type: String
    },
    tokenKey: {
      type: String
    },
    id: {
      type: Number,
      default: 0
    }
  },
  components: {
    BiometryCameraButton,
    BiometryAuthCard
  },
  methods: {
    ...mapActions("biometrics", ["authFace"]),
    async handleBiometryCameraSuccess(payload) {
      try {
        const { encrypted } = payload;
        await this.$root.$emit("show-loading");
        const data = await this.authFace({ service: this.service, selfie: encrypted });
        if (data?.MensagemErro) {
          this.showErrorMessage(data.MensagemErro);
          return;
        }
        if (data?.Autorizado) {
          this.$emit("valid", { auth: data.IdAutorizacao });
          return;
        }

        this.showErrorMessage("Não foi possível realizar a autenticação");
      } catch (error) {
        this.handleError(error);
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    handleError(error) {
      window.$log.error("biometry page", error);
      this.showErrorMessage("Ocorreu um erro ao obter a biometria");
    },
    showErrorMessage(message) {
      this.$toast({
        description: message ?? "Ocorreu um erro ao obter a biometria",
        status: "error",
        duration: 10000,
        position: "top"
      });
    },
    handleResourceError(error) {
      this.$toast({
        description: "Ocorreu um erro ao iniciar a câmera",
        status: "error",
        duration: 10000,
        position: "top"
      });
      window.$log.error("biometry init", error);
    },
    handleBiometryCameraError(error) {
      window.$log.error("biometry camera", error);
    },
    onCloseClick() {
      this.$root.$emit("hide-loading");
      this.$emit("cancel");
    }
  }
};
</script>
