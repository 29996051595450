<template>
  <OmtLayoutContainer>
    <OmtRow>
      <OmtTypography as="h1-bold"> Exames adicionados </OmtTypography>
    </OmtRow>

    <OmtRow mt="24px" justify-content="space-between" align-items="center">
      <OmtTypography as="p2">
        <strong> {{ selectedExams.length }} </strong> exame(s) selecionado(s)
      </OmtTypography>
      <CBox @click="cleanExams">
        <OmtTypography
          display="block"
          as="button-small"
          cursor="pointer"
          text-align="right"
          color="var(--colors-light-primary-navy-blue)"
        >
          Limpar tudo
        </OmtTypography>
      </CBox>
    </OmtRow>

    <OmtRow stack gap="16px" mt="24px">
      <OmtCardTile
        v-for="(item, index) in selectedExams"
        :key="index"
        :icon="{ name: 'clipboard-list-check', size: 'lg' }"
        :title="item.name"
        @click="deselectExam(item)"
        htmlTitle
      >
        <template v-slot:lead>
          <OmtIcons name="minus" size="lg" />
        </template>
      </OmtCardTile>
    </OmtRow>

    <FixedButtonRow>
      <OmtButton
        align-items="center"
        :w="['100%', '308px !important']"
        position="relative"
        :disabled="!selectedExams.length"
        @click="$router.push({ path: '/copay/simulations/create/select-labs' })"
      >
        Selecionar laboratórios
      </OmtButton>
    </FixedButtonRow>
  </OmtLayoutContainer>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import OmtCardTile from "@/components/Molecules/CardTile";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

export default {
  components: {
    OmtCardTile,
    FixedButtonRow
  },
  mounted() {
    if (!this.selectedExams.length) {
      this.$router.push({
        path: "/copay/simulations/create/exams"
      });
    }
  },
  data() {
    return {
      selectedCategory: {
        id: 0,
        label: "Todos"
      },
      search: ""
    };
  },
  computed: {
    ...mapState("copay", ["selectedExams"])
  },
  methods: {
    ...mapActions("copay", ["triggerExam"]),
    ...mapMutations("copay", ["TRIGGER_EXAM", "SET_PROP"]),
    cleanExams() {
      this.SET_PROP({ payload: [], prop: "selectedExams" });
    },
    deselectExam(item) {
      if (!item?.id) return;
      this.triggerExam({ isActive: false, name: item.name });
    },
    getFullExamNameDetails(exam) {
      if (!exam.options.length) return exam.name;
      else {
        const activedOptions = exam.options.filter(option => Boolean(option.isActive));
        const activedOptionsNames = activedOptions.map(option => option.label).join(" - ");
        return `${exam.name} • <span style='font-weight: 500'>${activedOptionsNames}</span>`;
      }
    }
  }
};
</script>
