<template>
  <CPseudoBox as="footer" v-bind="$attrs" height="293px">
    <OmtGrid
      bg="var(--colors-light-primary-light-gray) !important"
      m="0 !important"
      w="100vw !important"
      max-width="inherit !important"
    >
      <OmtRow mb="32px">
        <CImage :src="imageSources['Logo_Omint_Positivo']" height="32px" />
      </OmtRow>
      <OmtRow mb="32px">
        <OmtTypography as="h3-bold" text-align="center" color="var(--colors-light-primary-gray2)">
          ACOMPANHE NAS REDES SOCIAIS
        </OmtTypography>
      </OmtRow>
      <OmtRow mb="32px" :desktopStart="6" :desktopEnd="7">
        <CFlex justify-content="space-between">
          <CBox>
            <CImage cursor="pointer" :src="imageSources['facebook']" height="40px" />
          </CBox>

          <CBox>
            <CImage cursor="pointer" :src="imageSources['instagram']" height="40px" />
          </CBox>

          <CBox>
            <CImage cursor="pointer" :src="imageSources['linkedin']" height="40px" />
          </CBox>

          <CBox>
            <CImage cursor="pointer" :src="imageSources['youtube']" height="40px" />
          </CBox>

          <CBox>
            <CImage cursor="pointer" :src="imageSources['blog']" height="40px" />
          </CBox>

          <CBox>
            <CImage cursor="pointer" :src="imageSources['spotify']" height="40px" />
          </CBox>
        </CFlex>
      </OmtRow>
      <OmtRow :desktopStart="5" :desktopEnd="8">
        <CFlex justify-content="space-between" flex-wrap="wrap">
          <CImage :src="imageSources['ans-number']" height="40px" max-width="102px" />
          <CImage :src="imageSources['tiss']" height="40px" />
          <CImage :src="imageSources['ans']" height="40px" />
          <CImage :src="imageSources['omint-iso']" height="40px" />
        </CFlex>
      </OmtRow>
    </OmtGrid>
    <Footer />
  </CPseudoBox>
</template>

<script>
import Footer from "@/components/Molecules/Footer";

export default {
  components: {
    Footer
  },
  computed: {
    imageSources() {
      return [
        "Logo_Omint_Positivo",
        "ans-number",
        "omint-iso",
        "ans",
        "blog",
        "facebook",
        "instagram",
        "linkedin",
        "spotify",
        "tiss",
        "youtube"
      ].reduce(
        (acc, value) => ({
          ...acc,
          [value]: `${process.env.VUE_APP_BASE_URL}images/blog/${value}.svg`
        }),
        {}
      );
    }
  }
  // Comentado para prod
  // methods: {
  //   handleClick(item) {
  //     const items = {
  //       facebook: "https://www.facebook.com/Omint/",
  //       instagram: "https://www.instagram.com/omint/",
  //       linkedin: "https://www.linkedin.com/company/omint/",
  //       youtube: "https://www.youtube.com/user/OmintBrasil",
  //       blog: "https://www.omint.com.br/blog/",
  //       spotify: "https://open.spotify.com/user/omint"
  //     };

  //     window.open(items[item], "_blank");
  //   }
  // }
};
</script>
