import Vue from "vue";
import {
  DetalheProtocolo,
  NovaSolicitacao,
  ObterDocumento,
  ObterImagem,
  ObterProtocolos,
  ObterTiposSolicitacao,
  RetornoPendencia
} from "@/api/solicitacao";
import { ObterBeneficiariosTitulo } from "@/api/associado";
import { checkUpdatePermission } from "@/utils/checkReadonlyUtil";

const initialState = {
  activedSort: "",
  addPermission: true,
  beneficiaries: [],
  filters: {},
  lastFetch: null,
  limitFiles: 20,
  newRequest: {},
  protocols: [],
  sorts: ["Mais recentes", "Mais antigos"],
  subjectOptions: []
};

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  getters: {
    getAuthorizationService: context => {
      const subject = context.subjectOptions.find(s => s.value === context.newRequest.subject);

      return subject?.authorizationService;
    },
    filteredRequests: context => {
      let protocols = context.protocols?.length ? JSON.parse(JSON.stringify(context.protocols)) : [];
      if (protocols.length === 0) return [];

      const beneficiaries = context.filters?.beneficiary.filter(f => f.isActive) ?? [];
      if (beneficiaries?.length) {
        protocols = protocols.filter(p => !!beneficiaries.find(f => f.label === p.beneficiaryName));
        if (protocols.length === 0) return [];
      }
      const status = context.filters?.status.filter(f => f.isActive) ?? [];
      if (status?.length) {
        protocols = protocols.filter(p => !!status.find(f => f.label === p.statusDsc));
        if (protocols.length === 0) return [];
      }
      const titles = context.filters?.title.filter(f => f.isActive) ?? [];
      if (titles?.length) {
        protocols = protocols.filter(p => !!titles.find(f => f.label === p.title));
        if (protocols.length === 0) return [];
      }

      const sorts = {
        dateToNum(d) {
          if (!d || !d.includes("/")) return 0;
          d = d.split("/");
          return new Date(d[2], Number(d[1]) - 1, d[0]).getTime();
        },
        "Mais recentes"() {
          protocols.sort((a, b) => {
            return this.dateToNum(b.createdAt) - this.dateToNum(a.createdAt);
          });
        },
        "Mais antigos"() {
          protocols.sort((a, b) => {
            return this.dateToNum(a.createdAt) - this.dateToNum(b.createdAt);
          });
        }
      };

      if (context.activedSort) sorts[context.activedSort]();

      return protocols;
    }
  },
  actions: {
    async getDocumentUrl(context, { protocol }) {
      return await ObterDocumento({
        vinculo: context.rootState.saude.vinculo,
        protocolo: protocol
      });
    },
    async createRequestTicket(context, { obs }) {
      checkUpdatePermission();
      const newRequest = context.state.newRequest;
      const pictures = (newRequest.pictures ?? []).filter(p => p.source).map(p => p.source);
      return await NovaSolicitacao({
        vinculo: context.rootState.saude.vinculo,
        arquivos: pictures,
        beneficiario: newRequest.beneficiary,
        tipo: newRequest.subject,
        observacao: obs,
        autenticacao: newRequest.authorizationId
      });
    },
    async fetchBeneficiaries(context) {
      if (context.state.beneficiaries.length > 0) return;
      const resp = await ObterBeneficiariosTitulo({
        vinculo: context.rootState.saude.vinculo,
        dependentes: true,
        servico: 0
      });
      if (resp?.Data?.length) {
        const beneficiaries = resp.Data.map(b => ({
          value: b.Sequencia,
          label: b.Nome
        }));
        context.commit("SET_BENEFICIARIES", beneficiaries);
      }
    },
    async fetchProtocols(context, options) {
      const protocolFilter = options?.protocol ?? "";
      const forceFetch = options?.force ?? false;
      if (!forceFetch && !protocolFilter && context.state.lastFetch) return;

      const response = await ObterProtocolos({
        vinculo: context.rootState.saude.vinculo,
        protocolo: protocolFilter
      });
      if (response?.Data?.length) {
        let protocols = response.Data.map(p => ({
          protocol: p.ProtocoloANS,
          ticket: p.Chamado,
          title: p.TipoSolicitacao,
          beneficiaryCode: p.CdUtilizacao,
          beneficiaryName: p.DsUtilizacao,
          statusCode: p.CdSituacao,
          statusDsc: p.DsSituacao,
          createdAt: p.DtSolicitacao,
          canEvaluate: p.Nota === null
        }));
        if (protocolFilter)
          protocols = [...protocols, ...(context.state.protocols ?? []).filter(p => p.protocol !== protocolFilter)];
        context.commit("SET_PROTOCOLS", protocols);
      }
    },
    async fetchProtocolDetail(context, { protocol }) {
      const response = await DetalheProtocolo({
        vinculo: context.rootState.saude.vinculo,
        protocolo: protocol
      });
      if (response.Data)
        return {
          protocol: response.Data.ProtocoloANS,
          ticket: response.Data.Chamado,
          title: response.Data.TipoSolicitacao,
          beneficiaryCode: response.Data.CdUtilizacao,
          beneficiaryName: response.Data.DsUtilizacao,
          statusCode: response.Data.CdSituacao,
          statusDsc: response.Data.DsSituacao,
          doc: {
            id: response.Data.Documento.Id ?? 0,
            title: response.Data.Documento.Descricao,
            createdAt: response.Data.Documento.Data,
            resp: response.Data.Documento.Resposta ?? []
          },
          pendency: {
            ticket: response.Data.Pendencia?.Chamado ?? 0,
            reasons: response.Data.Pendencia?.Motivos ?? [],
            days: response.Data.Pendencia.DiasResposta ?? 20
          },
          createdAt: response.Data.DtSolicitacao,
          score: response.Data.Nota,
          pictures: response.Data.Imagens
        };
      return null;
    },
    async fetchProtocolDetailImage(context, { documento, original }) {
      return await ObterImagem({
        vinculo: context.rootState.saude.vinculo,
        documento,
        original
      });
    },
    async fetchSubjectOptions(context) {
      if (context.state.subjectOptions.length > 0) return;
      const resp = await ObterTiposSolicitacao({
        vinculo: context.rootState.saude.vinculo
      });
      if (resp?.Data?.length) {
        const subjectOptions = resp.Data.map(t => ({
          value: t.Id,
          label: t.Nome,
          daysAnalizy: t.PrazoAnalise,
          daysPendency: t.PrazoPendencia,
          orientation: t.Orientacao,
          warningDays: t.AvisoPrazo,
          warningOrientation: t.AvisoOrientacao,
          attchamentType: t.TipoAnexo,
          beneficiaryType: t.TipoBeneficiario,
          authorizationService: t.ServicoAutenticacao,
          attchamentDsc: (t.Anexos ?? []).map(a => ({
            id: a.Id,
            dsc: a.Nome
          }))
        }));
        context.commit("SET_SUBJECT_OPTIONS", subjectOptions);
      }
    },
    returnRequestTicket: async (context, { protocol, obs, pictures }) => {
      checkUpdatePermission();
      const picturesSource = (pictures ?? []).filter(p => p.source).map(p => p.source);
      return await RetornoPendencia({
        vinculo: context.rootState.saude.vinculo,
        protocolo: protocol,
        observacao: obs,
        arquivos: picturesSource
      });
    }
  },
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    CLEAN_SORT_AND_FILTERS: state => {
      var filters = JSON.parse(JSON.stringify(state.filters));
      if (filters.beneficiary) filters.beneficiary.forEach(f => (f.isActive = false));
      if (filters.status) filters.status.forEach(f => (f.isActive = false));
      if (filters.title) filters.title.forEach(f => (f.isActive = false));
      filters.totalActived = 0;
      filters.selectedBeneficiary = "";
      Vue.set(state, "filters", filters);
      Vue.set(state, "activedSort", "");
    },
    RESET_NEW_REQUEST: state => Vue.set(state, "newRequest", {}),
    SET_ACTIVED_SORT: (state, activedSort) => Vue.set(state, "activedSort", activedSort),
    SET_AUTHORIZATION_ID: (state, authorizationId) => {
      var newRequest = JSON.parse(JSON.stringify(state.newRequest));
      newRequest.authorizationId = authorizationId;
      Vue.set(state, "newRequest", newRequest);
    },
    SET_BENEFICIARY: (state, beneficiary) => {
      var newRequest = JSON.parse(JSON.stringify(state.newRequest));
      newRequest.beneficiary = beneficiary;
      Vue.set(state, "newRequest", newRequest);
    },
    SET_BENEFICIARIES: (state, beneficiaries) => Vue.set(state, "beneficiaries", beneficiaries),
    SET_FILTERS: (state, filters) => Vue.set(state, "filters", filters),
    SET_PICTURES: (state, pictures) => {
      var newRequest = JSON.parse(JSON.stringify(state.newRequest));
      newRequest.pictures = pictures;
      Vue.set(state, "newRequest", newRequest);
    },
    SET_PROTOCOLS: (state, protocols) => {
      Vue.set(state, "lastFetch", new Date().getTime());
      Vue.set(state, "protocols", protocols);

      const beneficiaryFilters = [...new Set(protocols.map(p => p.beneficiaryName))].sort();
      const statusFilters = [...new Set(protocols.map(p => p.statusDsc))].sort();
      const titleFilters = [...new Set(protocols.map(p => p.title))].sort();

      const filters = {
        beneficiary: beneficiaryFilters.map(f => ({
          label: f,
          isActive: state.filters.beneficiary?.find(current => current.label === f)?.isActive ?? false
        })),
        status: statusFilters.map(f => ({
          label: f,
          isActive: state.filters.status?.find(current => current.label === f)?.isActive ?? false
        })),
        title: titleFilters.map(f => ({
          label: f,
          isActive: state.filters.title?.find(current => current.label === f)?.isActive ?? false
        }))
      };

      const beneficiaries = filters.beneficiary.filter(f => f.isActive) ?? [];
      const status = filters.status.filter(f => f.isActive) ?? [];
      const titles = filters.title.filter(f => f.isActive) ?? [];
      filters.totalActived = beneficiaries.length + status.length + titles.length;

      Vue.set(state, "filters", filters);
    },
    SET_SUBJECT: (state, subject) => {
      var newRequest = JSON.parse(JSON.stringify(state.newRequest));
      newRequest.subject = subject;
      Vue.set(state, "newRequest", newRequest);
    },
    SET_SUBJECT_OPTIONS: (state, types) => Vue.set(state, "subjectOptions", types)
  }
};
