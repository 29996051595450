<template>
  <omint-page>
    <Header name="Agendamento" titleLabel="Agendamento" />
    <omint-slider>
      <router-view />
    </omint-slider>
    <Footer />
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";

export default {
  name: "Tasks",

  components: {
    Header,
    Footer
  }
};
</script>
