import Copay from "@/pages/copay/index.vue";
import CopayHome from "@/pages/copay/home.vue";
// Simulations
import CopaySimulations from "@/pages/copay/simulations/index.vue";
import CopaySimulationsCreate from "@/pages/copay/simulations/create/index.vue";
import CopaySimulationsCreateTerms from "@/pages/copay/simulations/create/terms.vue";
import CopaySimulationsCreateAddExams from "@/pages/copay/simulations/create/add-exams.vue";
import CopaySimulationsCreateAddExamsReview from "@/pages/copay/simulations/create/add-exams-review.vue";
import CopaySimulationsCreateSelectLabs from "@/pages/copay/simulations/create/select-labs.vue";
import CopaySimulationsView from "@/pages/copay/simulations/view.vue";
// Copay Models
import CopayModels from "@/pages/copay/copay-models/index.vue";

const routes: Route[] = [
  {
    path: "/copay",
    component: Copay,
    children: [
      {
        path: "",
        name: "copay.home",
        component: CopayHome,
        meta: {
          backTo: "/",
          routerSequence: 0,
          validPath: ["/copay"]
        }
      },
      {
        path: "/copay/simulations",
        name: "copay.simulations",
        component: CopaySimulations,
        meta: {
          backTo: "/copay",
          registerAccess: { servico: "Copay", funcionalidade: "Acesso a listagem de simulações" },
          routerSequence: 1,
          validPath: ["/copay"]
        }
      },
      {
        path: "/copay/simulations/create",
        alias: "/copay/simulations/terms",
        component: CopaySimulationsCreate,
        meta: {
          routerSequence: 1
        },
        children: [
          {
            path: "",
            component: CopaySimulationsCreateTerms,
            meta: {
              backTo: "/copay/simulations",
              registerAccess: { servico: "Copay", funcionalidade: "Acesso ao termo de simulação" },
              routerSequence: 1,
              validPath: ["/copay"]
            }
          },
          {
            path: "/copay/simulations/create/exams",
            name: "copay.simulations.create.exams",
            component: CopaySimulationsCreateAddExams,
            meta: {
              backTo: "/copay/simulations/create",
              routerSequence: 2,
              validPath: ["/copay"]
            }
          },
          {
            path: "/copay/simulations/create/review",
            name: "copay.simulations.create.review",
            component: CopaySimulationsCreateAddExamsReview,
            meta: {
              backTo: "/copay/simulations/create/exams",
              routerSequence: 3,
              validPath: ["/copay"]
            }
          },
          {
            path: "/copay/simulations/create/select-labs",
            name: "copay.simulations.create.select-labs",
            component: CopaySimulationsCreateSelectLabs,
            meta: {
              backTo: "/copay/simulations/create/review",
              routerSequence: 4,
              validPath: ["/copay"]
            }
          }
        ]
      },
      {
        path: "/copay/simulations/view/:id?",
        name: "copay.simulations.view",
        component: CopaySimulationsView,
        meta: {
          registerAccess: { servico: "Copay", funcionalidade: "Resultado da simulação" },
          routerSequence: 1,
          validPath: ["/copay"]
        }
      },
      {
        path: "/copay/models",
        name: "copay.models",
        component: CopayModels,
        meta: {
          backTo: "/copay",
          routerSequence: 1,
          validPath: ["/copay"]
        }
      }
    ]
  }
];

export default routes;
