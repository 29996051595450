/**
 * @param {number} scenario
 */
export default scenario => {
  const scenaries = {
    1: [
      "<span>Confirmo que <b>não tenho conhecimento dos documentos apresentados em meu nome e autorizo o cancelamento imediato</b> dessa Solicitação de Reembolso;</span>",
      "<span>Tenho ciência que a <b>reapresentação dessas despesas</b>, sob qualquer forma, <b>reiniciará o processo de auditoria</b>.</span>"
    ],
    2: [
      "<span><b>Tenho conhecimento dos documentos apresentados em meu nome</b>, confirmo a veracidade das informações constantes nas Notas Fiscais, Recibos e demais documentos apresentados;</span>",
      "<span><b>Estou ciente que os documentos fiscais apresentados serão compartilhados</b>, na sua integralidade, com o Conselho de Controle de Atividades Financeiras;</span>",
      "<span><b>Declaro que minhas respostas são verdadeiras</b> e prestadas em caráter definitivo, irrevogável, irretratável e podem ser utilizadas em todas as esferas.</span>",
      "<span><b>Estou ciente que essas respostas serão analisadas</b> pela auditoria médica e administrativa da Omint em <b>até 05 dias úteis</b> (a partir do envio dessas confirmações) e que novas informações poderão ser solicitadas durante este processo.</span>"
    ],
    3: [
      "<span><b>Tenho conhecimento dos documentos apresentados em meu nome</b>, confirmo a veracidade das informações constantes nas Notas Fiscais, Recibos e demais documentos apresentados;</span>",
      "<span><b>Estou ciente que os documentos fiscais apresentados serão compartilhados</b>, na sua integralidade, com o Conselho de Controle de Atividades Financeiras;</span>",
      "<span><b>Declaro que minhas respostas são verdadeiras</b> e prestadas em caráter definitivo, irrevogável, irretratável e podem ser utilizadas em todas as esferas.</span>",
      "<span><b>Estou ciente que essas respostas serão analisadas</b> pela auditoria médica e administrativa da Omint em <b>até 05 dias úteis</b> (a partir do envio dessas confirmações) e que novas informações poderão ser solicitadas durante este processo.</span>"
    ],
    4: [
      "<span>Confirmo que ainda não paguei as despesas apresentadas nesse processo de reembolso.</span>",
      "<span>Tenho ciência que essa solicitação pode ser reapresentada, desde que inclua a respectiva <b>nota fiscal/recibo e comprovante de desembolso.</b></span>",
      "<b>Os comprovantes de desembolso aceitos são: </b>",
      "<span>Boleto quitado; </span>",
      "<span>Comprovante de transação bancária (não serão aceitos comprovantes de AGENDAMENTO de Pix ou transferência bancária); </span>",
      "<span>Cópia da fatura do cartão de crédito;</span>",
      "<span>Cópia do extrato bancário.</span>",
      "<b>Atenção:</b>",
      "Não serão aceitos documentos digitalizados parcialmente ou ilegíveis;",
      "O comprovante de desembolso deve conter todas as informações da transação ( Data, hora, valor, identificação do destinatário, identificação do pagador, etc)."
    ],
    5: [
      "<span>Confirmo que ainda não paguei as despesas apresentadas nesse processo de reembolso.</span>",
      "<span>Tenho ciência que essa solicitação pode ser reapresentada, desde que inclua a respectiva <b>nota fiscal/recibo e comprovante de desembolso.</b></span>",
      "<b>Os comprovantes de desembolso aceitos são: </b>",
      "<span>Boleto quitado; </span>",
      "<span>Comprovante de transação bancária (não serão aceitos comprovantes de AGENDAMENTO de Pix ou transferência bancária); </span>",
      "<span>Cópia da fatura do cartão de crédito;</span>",
      "<span>Cópia do extrato bancário.</span>",
      "<b>Atenção:</b>",
      "Não serão aceitos documentos digitalizados parcialmente ou ilegíveis;",
      "O comprovante de desembolso deve conter todas as informações da transação ( Data, hora, valor, identificação do destinatário, identificação do pagador, etc)."
    ],
    6: [
      "<span>Confirmo que <b>não tenho conhecimento dos documentos apresentados em meu nome e autorizo o cancelamento imediato</b> dessa Solicitação de Reembolso;</span>",
      "<span>Tenho ciência que a <b>reapresentação dessas despesas</b>, sob qualquer forma, <b>reiniciará o processo de auditoria</b>.</span>"
    ]
  };

  return scenaries[scenario];
};
