/* meta group infos/rules:
-valores padrões (não precisa adicionar):
 offline: false (quando true, pode navegar para a página mesmo offline)
 allowAnonymous: false (quando true, pode navegar para a página sem estar logado)
 checkPathDisabled: false (quando true, não verifica a permissão de acesso)

-permissão de rota (relacionado ao menu lateral):
 validAction: define a store/action que valida se a rota está disponível, para rotas liberadas por regras de serviços
 validPath: caminhos válidos, para sub rotas, rotas com apelidos ou parâmetros de url

-outras regras:
 backTo: indica a rota correta para o botão voltar (se for uma página de form ou detalhe, indique a página de listagem)
 routerSequence: usar apenas nos grupos 'children'
 registerAccess: se não sabe, questione ao receber a task que gera uma nova rota

-Route interface declaration: src/@types/route.d.ts
*/
import appBase from "./routes/app-base";

import affiliateEvaluation from "./routes/affiliate-evaluation";
import affiliateIndication from "./routes/affiliate-indication";
import affiliateSearch from "./routes/affiliate-search";
import auths from "./routes/auths";
import bankAccount from "./routes/bank-account";
import biometry from "./routes/biometry";
import campain80Plus from "./routes/campain-80plus";
import firsAccess from "./routes/first-access";
import forgotPassword from "./routes/forgot-password";
import forms from "./routes/forms";
import homeServices from "./routes/home-services";
import myContact from "./routes/my-contact";
import notification from "./routes/notification";
import onboardWelcome from "./routes/onboard-welcome";
import refund from "./routes/refund";
import thirdPartyRegister from "./routes/third-party-register";
import ticket from "./routes/ticket";
import videoCall from "./routes/video-call";
import voucher from "./routes/voucher";
import copay from "./routes/copay";
import requests from "./routes/requests";
import servicesExtract from "./routes/services-extract";
import safeLetter from "./routes/safe-letter";
import copayStatement from "./routes/copay-statement";

const routes: Route[] = [
  ...copay,
  ...affiliateEvaluation,
  ...affiliateIndication,
  ...affiliateSearch,
  ...auths,
  ...bankAccount,
  ...biometry,
  ...campain80Plus,
  ...firsAccess,
  ...forgotPassword,
  ...forms,
  ...homeServices,
  ...myContact,
  ...notification,
  ...onboardWelcome,
  ...refund,
  ...thirdPartyRegister,
  ...ticket,
  ...videoCall,
  ...voucher,
  ...appBase,
  ...requests,
  ...servicesExtract,
  ...safeLetter,
  ...copayStatement
];

export default routes;
