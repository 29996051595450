<template>
  <CFlex @click="fecharMenu" cursor="pointer" class="drawer-link">
    <CBox>
      <OmtIcons :name="icon" size="xl" mr="2" />
      <OmtTypography as="p1">
        {{ label }}
      </OmtTypography>
    </CBox>
    <i class="fa fa-angle-right"></i>
  </CFlex>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { mapGetters } from "vuex";

const SPECIAL_URLS = ["pages.omint.com.br"];

export default {
  name: "DrawerLink",
  props: {
    link: {
      type: String
    },
    icon: {
      type: String
    },
    label: {
      type: String
    }
  },
  computed: {
    ...mapGetters("biometrics", ["onNative"])
  },
  methods: {
    fecharMenu() {
      const baseUrl = `${document.location.protocol}//${document.location.host}`;
      const uri = this.link ?? "";
      const specialLink = !!SPECIAL_URLS.find(u => uri.includes(u));
      const internalLink = uri.startsWith("/") || uri.startsWith(baseUrl);
      if (uri) {
        this.$root.$emit("show-hide-menu");

        try {
          if (internalLink) {
            this.$router.push(uri);
            return;
          }

          if (specialLink) {
            if (this.onNative) {
              Swal.fire({
                title: "Aviso",
                text: "Você será redirecionado para um site externo. Deseja continuar?",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showCancelButton: true
              }).then(yesOrNo => {
                if (yesOrNo.value) window.location = `${uri}?fileType=.pdf`;
              });
              return;
            }
            window.open(uri, "_blank");
            return;
          }

          if (this.$route.params.name === this.label) return;

          this.$router.push({
            name: "external.link",
            query: { title: this.label },
            params: { link: uri, name: this.label }
          });
        } catch (error) {
          window.$log.error("Não foi possível acessar o item de menu", error);
        }
      }
    }
  }
};
</script>

<style scoped>
.drawer-link {
  margin: 14px 10px 10px 10px !important;
  transition: all 250ms ease;
  padding: 7px 10px 7px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drawer-link * {
  color: var(--colors-light-primary-gray1);
  font-size: 13px;
}

.drawer-link div {
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.drawer-link:hover {
  border-radius: 8px;
}

.dark .drawer-link {
  border: 1px solid #1f1f1f;
}

.light .drawer-link {
  border: 1px solid white;
}

@media only screen and (min-width: 992px) {
  /* Desktop */
  .light .drawer-link:hover,
  .light .drawer-link:focus {
    border: 1px solid #031432;
  }

  .dark .drawer-link:hover,
  .dark .drawer-link:focus {
    border: 1px solid white;
  }
}

.drawer-link > *,
.fa-bars {
  cursor: pointer;
  transition: opacity 0.5s ease;
}

.drawer-link:hover,
.fa-bars:hover {
  opacity: 0.6;
}
</style>
