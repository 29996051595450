import FirstAccess from "@/pages/first-access/index.vue";
import FirstAccessTerms from "@/pages/first-access/terms.vue";
import FirstAccessBond from "@/pages/first-access/bond.vue";
import FirstAccessMailCheck from "@/pages/first-access/mail-check.vue";
import FirstAccessMailForm from "@/pages/first-access/mail-form.vue";
import FirstAccessCodeCheck from "@/pages/first-access/code-check.vue";
import FirstAccessPasswordForm from "@/pages/first-access/password-form.vue";
import FirstAccessSuccess from "@/pages/first-access/success.vue";

const routes: Route[] = [
  {
    path: "/first-access",
    component: FirstAccess,
    meta: {
      allowAnonymous: true,
      routerSequence: 0
    },
    children: [
      {
        path: "terms",
        name: "first-access.terms",
        component: FirstAccessTerms,
        meta: {
          allowAnonymous: true,
          routerSequence: 1
        }
      },
      {
        path: "bond",
        name: "first-access.bond",
        component: FirstAccessBond,
        meta: {
          allowAnonymous: true,
          routerSequence: 2
        }
      },
      {
        path: "mailcheck",
        name: "first-access.mailcheck",
        component: FirstAccessMailCheck,
        meta: {
          allowAnonymous: true,
          routerSequence: 3
        }
      },
      {
        path: "mailform",
        name: "first-access.mailform",
        component: FirstAccessMailForm,
        meta: {
          allowAnonymous: true,
          routerSequence: 4
        }
      },
      {
        path: "code",
        name: "first-access.code",
        component: FirstAccessCodeCheck,
        meta: {
          allowAnonymous: true,
          routerSequence: 5
        }
      },
      {
        path: "password",
        name: "first-access.password",
        component: FirstAccessPasswordForm,
        meta: {
          allowAnonymous: true,
          routerSequence: 6
        }
      },
      {
        path: "success",
        name: "first-access.success",
        component: FirstAccessSuccess,
        meta: {
          allowAnonymous: true,
          backTo: "/login",
          routerSequence: 7
        }
      }
    ]
  }
];

export default routes;
