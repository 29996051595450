<template>
  <OmtCard :padding="['16px', '24px']">
    <CFlex>
      <CBox :pr="['16px', '26px']">
        <OmtIcons :name="icon" :font-size="['16px', '21px']" size="inherit" />
      </CBox>

      <CBox w="100%">
        <template>
          <OmtTypography :as="['h3-bold', 'h1-semi-bold']" v-if="type === 'owner'" :pt="['4px', '']">
            Titular
          </OmtTypography>

          <CBox v-else-if="type === 'third-party'">
            <OmtTypography as="h3-bold" :display="['block', 'none']" :pt="['4px', '']">
              Dependentes e terceiros
            </OmtTypography>
            <OmtTypography as="h1-semi-bold" :display="['none', 'block']" :pt="['4px', '']">
              Dependentes e administradores terceiros
            </OmtTypography>
          </CBox>
        </template>

        <OmtDivider :mt="['16px', '24px']" :mb="['16px', '22px']" />

        <template v-if="type === 'owner'">
          <CGrid :template-columns="['repeat(2, 1fr)', 'repeat(3, minmax(198px, 1fr))']" gap="18px">
            <CGridItem v-for="(info, index) in item.list" :key="index" :col-span="(index === 0 && '2') || ''">
              <OmtTypography :as="['p2', 'p1']" color="var(--colors-light-primary-gray2)">{{
                info.header
              }}</OmtTypography>
              <OmtTypography :as="['p1', 'p3']">{{ info.description }}</OmtTypography>
            </CGridItem>
          </CGrid>
        </template>

        <template v-else-if="type === 'third-party'">
          <CBox v-if="item.list.length">
            <CGrid :template-columns="['repeat(2, 1fr)', 'repeat(3, minmax(198px, 1fr))']" gap="18px" mb="24px">
              <CGridItem v-for="(info, index) in item.list" :key="index" :col-span="(index === 0 && '2') || ''">
                <OmtTypography :as="['p2', 'p1']" color="var(--colors-light-primary-gray2)">
                  {{ info.header }}
                </OmtTypography>
                <OmtTypography :as="['p1', 'p3']">{{ info.description }}</OmtTypography>
              </CGridItem>
            </CGrid>

            <CFlex flex-wrap="wrap" style="gap: 16px" v-if="item.list.length">
              <OmtButton
                :max-width="['132px', '148px']"
                @click="$emit('visualizar', item)"
                height="41px"
                border-color="var(--colors-light-secondary-divider)"
                variant="outline"
                leftIcon="search"
              >
                <OmtTypography as="h3-semi-bold"> Visualizar </OmtTypography>
              </OmtButton>
              <!-- <OmtButton
                :max-width="['132px', '148px']"
                @click="$emit('edit', info)"
                height="41px"
                border-color="var(--colors-light-secondary-divider)"
                variant="outline"
                leftIcon="pen-to-square"
                v-if="item.DadosEditaveis == true"
              >
                <OmtTypography as="h3-semi-bold"> Editar </OmtTypography>
              </OmtButton> -->
              <OmtButton
                :max-width="['132px', '148px']"
                @click="$emit('remove', item)"
                height="41px"
                border-color="var(--colors-light-secondary-divider)"
                variant="outline"
                leftIcon="trash-can"
                v-if="item.Vigencia !== ''"
              >
                <OmtTypography as="h3-semi-bold"> Remover </OmtTypography>
              </OmtButton>
              <OmtButton
                :max-width="['132px', '148px']"
                @click="$emit('prorrogar', item)"
                height="41px"
                border-color="var(--colors-light-secondary-divider)"
                variant="outline"
                leftIcon="arrows-rotate"
                v-if="item.Vigencia !== ''"
              >
                <OmtTypography as="h3-semi-bold"> Prorrogar </OmtTypography>
              </OmtButton>
              <OmtButton
                :max-width="['132px', '148px']"
                @click="$emit('adicionar', item)"
                height="41px"
                border-color="var(--colors-light-secondary-divider)"
                variant="outline"
                leftIcon="plus-circle"
                v-if="item.Vigencia == '' && item.Revalidacao == '' && item.Tipo == 'Beneficiário'"
              >
                <OmtTypography as="h3-semi-bold"> Adicionar </OmtTypography>
              </OmtButton>
              <!-- <OmtButton
                :max-width="['132px', '148px']"
                @click="$emit('reativar', item)"
                height="41px"
                border-color="var(--colors-light-secondary-divider)"
                variant="outline"
                leftIcon="plus-circle"
                v-if="item.AdministradorNomeado == 'Revogado'"
              >
                <OmtTypography as="h3-semi-bold"> Reativar </OmtTypography>
              </OmtButton> -->
            </CFlex>
          </CBox>

          <CFlex v-else>
            <OmtIcons mr="16px" name="circle-exclamation" color="var(--colors-light-support-warning)" size="1x" />
            <OmtTypography :as="['p1', 'p3']"> Você ainda não possui administradores cadastrados </OmtTypography>
          </CFlex>
        </template>
      </CBox>
    </CFlex>
  </OmtCard>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "owner",
      validator: value => {
        const options = ["owner", "third-party"];
        const validOption = !!options.includes(value);
        if (!validOption) {
          console.error(`Allowed ThirdPartyRegisterListItem type options : ${options.join(" - ")}`);
          console.error(`value used ${value}`);
        }
        return validOption;
      }
    },
    item: {
      type: Object
    }
  },
  computed: {
    icon() {
      return {
        owner: "user-alt",
        "third-party": "user-plus"
      }[this.type];
    }
  }
};

const buildOwnerListItem = ({ Nome, PlanoMedico, PlanoOdontologico, Titulo, DataIngresso }) => [
  {
    header: "Nome",
    description: Nome || ""
  },
  {
    header: "Plano Médico",
    description: PlanoMedico || ""
  },
  {
    header: "Plano Odontológico",
    description: PlanoOdontologico || ""
  },
  {
    header: "Título",
    description: Titulo || ""
  },
  {
    header: "Data de ingresso",
    description: DataIngresso || ""
  }
];

const buildThirdpartyListItem = ({ Nome, Tipo, Revalidacao, AdministradorNomeado, Vigencia }) => [
  {
    header: "Nome",
    description: Nome || ""
  },
  {
    header: "Tipo",
    description: Tipo || ""
  },
  {
    header: "Revalidação",
    description: Revalidacao || ""
  },
  {
    header: "Administrador nomeado",
    description: AdministradorNomeado || ""
  },
  {
    header: "Data de vigência",
    description: Vigencia || ""
  }
];

export { buildOwnerListItem, buildThirdpartyListItem };
</script>
