import { render, staticRenderFns } from "./NecessaryDocumentsModal.vue?vue&type=template&id=1a3f4033&"
import script from "./NecessaryDocumentsModal.vue?vue&type=script&lang=js&"
export * from "./NecessaryDocumentsModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CAccordionIcon: require('@chakra-ui/vue').CAccordionIcon, CAccordionHeader: require('@chakra-ui/vue').CAccordionHeader, CFlex: require('@chakra-ui/vue').CFlex, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CAccordionPanel: require('@chakra-ui/vue').CAccordionPanel, CDivider: require('@chakra-ui/vue').CDivider, CAccordionItem: require('@chakra-ui/vue').CAccordionItem, CAccordion: require('@chakra-ui/vue').CAccordion})
