<template>
  <CModal
    isCentered
    initialFocusRef="phonesOptions"
    scrollBehavior="outside"
    :is-open="true"
    :closeOnOverlayClick="false"
    :closeOnEsc="false"
    :size="modalSize"
    :onClose="onCloseClick"
  >
    <CModalOverlay />
    <CModalContent>
      <CModalHeader align="center">
        <CBox class="icone">
          <i class="fa fa-exclamation"></i>
        </CBox>
        <omt-h3> Token de Segurança </omt-h3>
      </CModalHeader>
      <CModalCloseButton :color="closeButtonColor" />
      <CModalBody p="8px 20px 20px 20px">
        <CBox>
          <omt-p v-chakra mb="4">Sua solicitação será processada após a confirmação do Token de Segurança.</omt-p>
          <CBox class="card">
            <omt-highlight v-if="phones && phones.length">Selecione um Celular</omt-highlight>
            <omt-highlight v-else>O token será enviado para seu e-mail</omt-highlight>
            <CFlex class="card-content">
              <CBox class="phone-options" v-if="phones && phones.length">
                <Options
                  ref="phonesOptions"
                  :solid="true"
                  :options="phonesOptions"
                  :on-selection="onPhoneSelected"
                  placeholder="Selecione um Celular"
                  preSelected="first"
                />
              </CBox>
              <omt-button
                :disabled="!phone && phones && phones.length"
                :colorInverse="true"
                :onClick="() => this.enviarToken()"
              >
                Enviar Token
              </omt-button>
            </CFlex>
          </CBox>

          <CBox class="card">
            <omt-highlight>Informe o Token</omt-highlight>
            <CFlex class="card-content">
              <TokenInput :value="token" @input="onTokenInput($event)" class="token-input" />
              <CBox color="red" v-if="tokenInvalido">Token Inválido</CBox>
              <omt-button :disabled="!canCheckToken" :colorInverse="true" :onClick="() => this.validarToken()">
                Validar Token
              </omt-button>
            </CFlex>
          </CBox>
        </CBox>
      </CModalBody>
    </CModalContent>
  </CModal>
</template>

<script>
import { mapState } from "vuex";
import mixin from "./mixin";
import Options from "@/components/Molecules/Options";
import { isMobile } from "@/utils/utils";
import TokenInput from "@/components/Molecules/TokenInput";

export default {
  name: "TokenSmsModal",
  mixins: [mixin],
  components: {
    Options,
    TokenInput
  },
  computed: {
    ...mapState("saude", ["theme", "bigFontSize"]),
    modalSize() {
      return isMobile() && window.innerWidth < 992 ? "xs" : "xl";
    },
    bgColor() {
      return this.theme === "dark" ? "#1f1f1f" : "white";
    },
    closeButtonColor() {
      return this.theme === "dark" ? "white" : "black";
    },
    fontColor() {
      return this.theme === "dark" ? "white" : "#031432";
    },
    tema() {
      return this.bigFontSize ? `${this.theme} big-font-size` : this.theme;
    }
  },
  methods: {
    onCloseClick() {
      this.$root.$emit("hide-loading");
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 992px) {
  .phone-options {
    flex: 1;

    .option {
      margin-bottom: 0;
    }
  }

  .token-input {
    height: 50px !important;
    flex: 1;
    letter-spacing: 1rem;
    margin-bottom: 0;
  }

  button.button {
    min-width: 140px !important;
  }

  .card {
    box-shadow: 0px 0px 16px 2px #00000024;
    border-radius: 4px;
    padding: 20px 16px;

    .card-content {
      gap: 1rem;
      margin-top: 1rem;
      flex-direction: row;

      .input.token-input {
        margin-bottom: 0;
      }

      .phone-options {
        margin-bottom: 0;
      }
    }

    & + & {
      margin-top: 1rem;
    }
  }

  .icone {
    width: 35px;
    height: 35px;
    left: 30px;
    border: 1px solid var(--colors-black);

    i {
      font-size: 18px;
    }
  }

  button[data-chakra-component="CModalCloseButton"] {
    right: 20px;
  }
}

.big-font-size button[data-chakra-component="CModalCloseButton"] {
  top: 18px;
}

button[data-chakra-component="CModalCloseButton"] {
  top: 16px;
  right: 16px;
}

.card-content {
  flex-direction: column;

  .token-input {
    margin-bottom: 1rem;
  }

  .phone-options {
    margin-bottom: 12px;
  }
}

.card + .card {
  margin-top: 1rem;
}

button.button {
  height: 48px;
  min-width: 100%;
  font-size: 14px !important;
}

.big-font-size button.button {
  min-width: 260px !important;
  position: relative;
  top: -8px;
}

h3 {
  margin: 0;
}

input {
  background-color: white !important;
  color: black !important;
  letter-spacing: 2px;
  letter-spacing: 5px;
  text-decoration: underline dashed;
  text-underline-offset: 30%;
  border: 2px solid rgba(0, 0, 0, 0.08);
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icone {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 16px;
  width: 26px;
  height: 26px;

  i {
    font-size: 12px;
  }
}

.light {
  .icone {
    background-color: #68788c;
    i {
      color: #f0f0f0;
    }
  }
}

.dark {
  input {
    background-color: white !important;
    color: black !important;
  }

  .icone {
    background-color: white;
    i {
      color: black;
    }
  }
}
</style>
