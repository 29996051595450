<template>
  <CModal size="sm" :is-centered="true" :on-close="closeInfoModal" :closeOnOverlayClick="true" :is-open="true">
    <CModalContent borderRadius="4px" ref="content">
      <CModalBody borderRadius="4px" :class="theme" p="var(--space-3)" style="color: var(--colors-light-primary-gray1)">
        <CFlex align="center" justify="space-between" mb="var(--space-3)">
          <CFlex align="center">
            <i v-chakra mx="0" class="fa fa-md fa-info"></i>
            <omt-h1>Cadastro de conta corrente</omt-h1>
          </CFlex>
          <i class="fa fa-xs fa-times" @click="closeInfoModal"></i>
        </CFlex>
        <CBox>
          <omt-p class="text-decoration"> É permitido o cadastro de 02 contas por beneficiário; </omt-p>
          <omt-p class="text-decoration">
            Para atualizar uma conta já cadastrada, é necessário que você a exclua e cadastre novamente;
          </omt-p>
          <omt-p class="text-decoration">
            <div>
              <b>Conta Principal:</b> será utilizada quando não for indicada uma conta para crédito de Reembolso
              solicitado por Correio ou entregue na Omint;
            </div>
          </omt-p>
          <omt-p class="text-decoration">
            <div>
              <b>Não</b> é permitido o cadastro de Conta Salário ou Conta Poupança. Cadastre somente Contas Correntes
              que estejam em nome do Associado <b>logado nesse momento</b>.
            </div>
          </omt-p>
        </CBox>
      </CModalBody>
    </CModalContent>
    <CModalOverlay />
  </CModal>
</template>

<script>
import { CBox, CFlex, CModal, CModalOverlay, CModalContent, CModalBody } from "@chakra-ui/vue";
import Options from "@/components/Molecules/Options";
import AccountCard from "@/components/Organisms/BankAccount/AccountCard.vue";
import { mapState } from "vuex";

export default {
  name: "InfoModal",
  components: {
    CBox,
    CFlex,
    Options,
    AccountCard,
    CModal,
    CModalOverlay,
    CModalContent,
    CModalBody
  },
  methods: {
    closeInfoModal() {
      this.$emit("close");
    }
  },
  computed: {
    ...mapState("saude", ["theme"])
  }
};
</script>

<style lang="scss" scoped>
h1,
p {
  margin: 0;
}

div[data-chakra-component="CModalBody"].dark {
  border: 1px solid currentColor;
}

.text-decoration {
  display: flex;
  align-items: baseline;
  gap: 8px;

  &:before {
    content: "";
    min-height: 4px;
    min-width: 4px;
    background: black;
    border-radius: 50%;
    display: inline-flex;
    margin-bottom: 4px;
    position: relative;
    bottom: 3px;
  }
}

.fa-times {
  position: relative;
  bottom: 5px;
  cursor: pointer;
  transition: opacity ease 0.5s;
  &:hover {
    opacity: 0.6;
  }
}

.fa-info {
  border-radius: 50%;
  outline: 4px solid currentColor;
  padding: 10px;
  width: var(--space-8);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.4);
}
</style>
