<script>
import Vue from "vue";
import { CPseudoBox } from "@chakra-ui/vue";

/**
 * Slide component based on https://vue.chakra-ui.com/pseudobox
 *
 * @prop {String} width
 * @prop {String} height
 * @prop {any} $props any props listed here: https://vue.chakra-ui.com/style-props
 */
export default Vue.component("OmtSwiperSlide", {
  props: {
    width: {
      type: String
    },
    height: {
      type: String
    }
  },
  render: function (createElement) {
    return createElement(
      CPseudoBox,
      {
        on: { ...this.$listeners },
        attrs: {
          width: this.width + " !important",
          height: this.height + " !important",
          ...this.$attrs
        },
        class: "swiper-slide"
      },
      this.$slots.default
    );
  }
});
</script>
