<template>
  <omint-page>
    <Header name="Gestão de acessos" titleLabel="Gestão de acessos" backRoute="/" />

    <omint-page-content>
      <div class="row">
        <omt-h5 class="administrator-title">Autorizar acesso a Informação</omt-h5>
      </div>

      <div class="row">
        <omt-p class="recipient-title">
          <i class="fa fa-user"></i>
          Beneficiários
        </omt-p>
      </div>

      <div v-for="(p, index) in permissoes" :key="index">
        <BeneficiaryCard
          :vinculo="p.vinculo"
          :pessoa="p.pessoa"
          :ativa="p.ativa"
          :funcionalidades="p.funcionalidades"
          @checked="onBeneficiaryChange"
          @change="onPermissionChange"
        />
      </div>

      <div class="row mt-24">
        <omt-buttons-bar style="width: 100%">
          <omt-button class="cancel" :onClick="voltar"> CANCELAR </omt-button>
          <omt-button class="save dark" :onClick="submit"> SALVAR </omt-button>
        </omt-buttons-bar>
      </div>
    </omint-page-content>

    <Modal
      :open="exibirModalTermo"
      @cancel="exibirModalTermo = false"
      @continue="optinHandler"
      :continue="true"
      :disabled="!optinAceito"
      size="lg"
      :title="'Termo de aceite para acesso às minhas informações pessoais'"
    >
      <CBox class="box-scroll" style="color: var(--colors-light-primary-gray1)">
        <omt-p class="lh-24">
          É importante saber que, ao clicar em “Salvar”, você está autorizando o(s) Beneficiário(s) selecionado(s) a
          visualizarem suas informações pessoais, nos serviços assinalados, através dos seguintes canais de atendimento:
        </omt-p>
        <ul>
          <li>
            <omt-p class="lh-24 m-0">Web, pelo canal exclusivo "Minha Omint" </omt-p>
          </li>
          <li>
            <omt-p class="lh-24 m-0">Telefone, pela Central de Atendimento 24 horas</omt-p>
          </li>
          <li>
            <omt-p class="lh-24 m-0">WhatsApp e App Minha Omint</omt-p>
          </li>
        </ul>

        <omt-p class="lh-24"
          >Para cancelar a autorização, a qualquer momento, basta desmarcar os Beneficiários e/ou Serviços. A utilização
          indevida de qualquer informação fornecida pela Omint durante o período da autorização será de sua inteira
          responsabilidade, não cabendo à Omint qualquer tipo de reparação.</omt-p
        >
      </CBox>
      <div class="row mt-24 accept">
        <CCheckbox iconColor="white.100" borderColor="#E2E8F0" v-model="optinAceito">
          <CText
            fontSize="12px"
            lineHeight="16px"
            fontWeight="400"
            color="var(--colors-light-primary-gray1)"
            mt="0"
            mb="0"
          >
            Li e concordo com os termos e condições do <b>Termo de Aceite.</b>
          </CText>
        </CCheckbox>
      </div>
    </Modal>
    <Footer />
  </omint-page>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Header from "@/components/Molecules/Header";
import Modal from "@/components/Organisms/AccessManagement/Modal.vue";
import Footer from "@/components/Molecules/Footer";
import BeneficiaryCard from "@/components/Organisms/AccessManagement/BeneficiaryCard.vue";
import { CFlex, CBox, CFormControl, CText, CCheckbox } from "@chakra-ui/vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  components: { Modal, Footer, CFlex, Header, CBox, BeneficiaryCard, CFormControl, CText, CCheckbox },
  name: "AccessManagement",
  data() {
    return {
      exibirModalTermo: false,
      optinAceito: false,
      permissoes: [],
      alterado: false,
      beneficiarioAtivoSemPermissao: false
    };
  },
  computed: {
    ...mapState("accessManagement", ["permissions"])
  },
  async mounted() {
    try {
      await this.$root.$emit("show-loading");
      await this.fetchPermissions();

      if (!this.permissions || this.permissions.length === 0) {
        await this.$root.$emit("hide-loading");
        await Swal.fire({
          title: "Aviso",
          text: "Você não possui beneficiários para autorizar acessos.",
          confirmButtonText: "OK",
          icon: "warning"
        });
        this.$router.push("/");
        return;
      }

      this.permissoes = this.permissions.map(p => {
        const tipos = {};
        p.ListaFuncionalidades.forEach(f => {
          if (!tipos[f.Tipo]) {
            tipos[f.Tipo] = [];
          }
          tipos[f.Tipo].push({
            id: f.IdFuncionalidade,
            descricao: f.DsFuncionalidade,
            ativa: f.Ativa,
            original: f.Ativa
          });
        });
        return {
          id: p.IdPermissao,
          vinculo: p.Vinculo,
          pessoa: p.DsPessoa,
          ativa: p.IdPermissao > 0,
          funcionalidades: Object.keys(tipos).map(t => ({
            tipo: t,
            permissoes: tipos[t]
          }))
        };
      });
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  methods: {
    ...mapActions("accessManagement", ["fetchPermissions", "optinAccept", "updateAccessPermitions"]),
    ...mapMutations("ticket", ["SET_RATING_INFOS"]),
    verificarAlteracoes() {
      this.alterado = false;
      this.beneficiarioAtivoSemPermissao = false;
      this.permissoes.forEach(p => {
        if (!this.alterado && ((p.ativa && p.id === 0) || (!p.ativa && p.id > 0))) {
          this.alterado = true;
        }
        let semPermissao = p.ativa;
        p.funcionalidades.forEach(t =>
          t.permissoes.forEach(f => {
            if (f.ativa && semPermissao) {
              semPermissao = false;
            }
            if (f.ativa !== f.original && !this.alterado) {
              this.alterado = true;
            }
          })
        );
        if (semPermissao && !this.beneficiarioAtivoSemPermissao) {
          this.beneficiarioAtivoSemPermissao = true;
        }
      });
    },
    onBeneficiaryChange({ vinculo }) {
      const permissao = this.permissoes.find(p => p.vinculo === vinculo);
      if (permissao) {
        permissao.ativa = !permissao.ativa;
        if (!permissao.ativa) {
          permissao.funcionalidades.forEach(f => f.permissoes.forEach(p => (p.ativa = false)));
        }
      }
      this.verificarAlteracoes();
    },
    onPermissionChange({ vinculo, tipo, permissao }) {
      const permissaoBeneficiario = this.permissoes.find(p => p.vinculo === vinculo);
      if (permissaoBeneficiario) {
        const tipoFuncionalidade = permissaoBeneficiario.funcionalidades.find(f => f.tipo === tipo);
        if (tipoFuncionalidade) {
          const permissaoFuncionalidade = tipoFuncionalidade.permissoes.find(p => p.id === permissao);
          if (permissaoFuncionalidade) {
            permissaoFuncionalidade.ativa = !permissaoFuncionalidade.ativa;
          }
        }
      }
      this.verificarAlteracoes();
    },
    voltar() {
      this.$router.push("/");
    },
    submit() {
      if (!this.alterado) {
        Swal.fire({
          text: "Nenhuma alteração realizada",
          confirmButtonText: "Fechar",
          icon: "error"
        });
        return;
      }

      if (this.beneficiarioAtivoSemPermissao) {
        Swal.fire({
          text: "Você precisa selecionar algum item que o beneficiário poderá acessar.",
          confirmButtonText: "Fechar",
          icon: "error"
        });
        return;
      }
      if (!this.optinAceito) {
        this.exibirModalTermo = true;
      } else {
        this.submitChanges();
      }
    },
    async optinHandler() {
      this.$root.$emit("show-loading");
      await this.optinAccept();
      this.exibirModalTermo = false;
      await this.submitChanges();
    },
    async submitChanges() {
      try {
        this.$root.$emit("show-loading");
        const protocoloANS = await this.updateAccessPermitions({ permissions: this.permissoes });
        this.SET_RATING_INFOS({ ratingRoute: "/", ratingType: 7 });
        this.$router.replace({
          path: `/experience-rating/${protocoloANS}/7/S`
        });
      } catch (error) {
        window.$log.error("Não foi possível aplicar a permissão", error);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.pa-16 {
  padding: 16px;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  &.pt {
    padding-top: 16px;
  }

  &.mt-24 {
    margin-top: 24px;
  }

  &.mt-5 {
    margin-top: 5px;
  }

  &.mb-16 {
    margin-bottom: 16px;
  }
}

.administrator-title {
  color: var(--colors-light-primary-gray1);
  font-weight: 600 !important;
  margin-bottom: 0 !important;
}
.dark .administrator-title {
  color: var(--colors-light-primary-gray1);
}

.recipient-title {
  color: var(--colors-light-primary-gray1);
  font-weight: bold !important;
  margin: 16px 0px 0px 0px;

  i {
    margin-right: 8px;
  }
}

.w-100 {
  width: 100%;
}

input[type="checkbox"]:checked + div[data-chakra-component="CControlBox"],
input[type="checkbox"][aria-checked="mixed"] + div[data-chakra-component="CControlBox"] {
  background-color: #102a46;
}

.box-scroll {
  height: calc(100vh - 400px) !important;
  overflow: scroll;
}

.icon-avatar {
  border: 2px solid #344960;
  border-radius: 50%;
  padding: 10px;
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="checkbox"]:checked + .css-1snqmie,
input[type="checkbox"][aria-checked="mixed"] + .css-1snqmie {
  background-color: #68788c;
  border-color: #68788c;
  width: 16px !important;
  height: 16px !important;
}

.accept {
  label[data-chakra-component="CCheckbox"] {
    align-items: flex-start !important;

    div[data-chakra-component="CControlBox"] {
      width: 16px !important;
      height: 16px !important;
    }
  }
}

.lh-24 {
  line-height: 24px;
}

.m-0 {
  margin: 0;
}
</style>
