<template>
  <OmtStickedModal
    v-if="isOpen && modalFilters"
    @close="close"
    :bodyBind="{
      padding: ['16px 16px 24px 16px', '68px 42px 24px 42px']
    }"
  >
    <template #header>
      <OmtButton class="clear" variant="ghost" @click="clear" height="18px" p="0" :disabled="!canClean">
        <OmtTypography as="p1" h="18px" w="46px" position="relative"> Limpar </OmtTypography>
      </OmtButton>
    </template>

    <CBox :minHeight="['initial', 'calc(100vh - 400px)']" mb="42px">
      <OmtTypography as="h1-bold" :mb="['24px', '36px']"> Filtrar </OmtTypography>

      <CBox mb="32px">
        <OmtTypography as="h2-bold" mb="22px"> Por beneficiário </OmtTypography>

        <CBox v-for="(option, index) in modalFilters.beneficiaries.data" justify="space-between" :key="index">
          <OmtDivider v-if="index !== 0" mb="24px" mt="24px" />

          <OmtRadio
            cursor="pointer"
            @click="modalFilters.beneficiaries.value = option"
            :isChecked="modalFilters.beneficiaries.value === option"
            :name="`radio-${modalFilters.beneficiaries.label}`"
            :value="option"
          >
            <OmtTypography as="p1"> {{ option }} </OmtTypography>
          </OmtRadio>
        </CBox>
      </CBox>

      <CBox mb="32px">
        <OmtTypography as="h2-bold" mb="22px"> Por período </OmtTypography>

        <OmtSelect
          variant="outline"
          placeholder="Selecione um filtro"
          @change="e => setListOption(e)"
          mb="24px"
          :initialValue="dateOption"
        >
          <option v-for="(filter, index) in modalFilters.dates" :key="index" :value="filter.value">
            {{ filter.label }}
          </option>
        </OmtSelect>
      </CBox>

      <CBox>
        <OmtTypography as="h2-bold" mb="22px"> Por status </OmtTypography>

        <CFlex
          v-for="(filter, index) in statusOptions"
          justify="space-between"
          :key="index"
          cursor="pointer"
          @click="filter.isActive = !filter.isActive"
          :mt="index !== 0 ? '24px' : '0'"
        >
          <OmtTypography as="p1"> {{ filter.label }} </OmtTypography>
          <OmtCheckbox @change="filter.isActive = !filter.isActive" :is-checked="filter.isActive" />
        </CFlex>
      </CBox>
    </CBox>

    <template #footer>
      <OmtButton w="100%" @click="save"> Aplicar </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  props: {
    isOpen: {
      Type: Boolean
    }
  },
  data() {
    return {
      modalFilters: null,
      dateOption: ""
    };
  },
  methods: {
    ...mapMutations("auths", ["SET_FILTERS", "SET_LIST_OPTION"]),
    setListOption(value) {
      if (value !== this.dateOption) {
        this.dateOption = value;
      }
    },
    close() {
      this.$emit("update:isOpen", false);
    },
    clear() {
      this.modalFilters.status.data.forEach(f => (f.isActive = false));
      this.modalFilters.beneficiaries.value =
        this.modalFilters.beneficiaries.data.length === 1 ? this.modalFilters.beneficiaries.data[0] : "";
      this.dateOption = this.listOption;
    },
    save() {
      if (this.modalFilters.beneficiaries.data.length === 1) {
        this.modalFilters.beneficiaries.value = "";
      }
      this.SET_LIST_OPTION(this.dateOption);
      this.SET_FILTERS({ ...this.modalFilters });
      this.close();
    }
  },
  computed: {
    ...mapState("auths", ["filters", "listOption"]),
    statusOptions() {
      if (!this.dateOption) return this.modalFilters.status.data;
      return this.modalFilters.status.data.filter(item => !!item.dates.find(d => d === this.dateOption));
    },
    canClean() {
      const hasActivedStatus = this.modalFilters.status.data.filter(f => f.isActive);
      return !!hasActivedStatus.length || !!this.modalFilters.beneficiaries.value;
    }
  },
  watch: {
    isOpen: {
      handler(value) {
        if (value) {
          this.dateOption = this.listOption;
          this.modalFilters = JSON.parse(JSON.stringify(this.filters));
          if (this.modalFilters.beneficiaries.data.length === 1) {
            this.modalFilters.beneficiaries.value = this.modalFilters.beneficiaries.data[0];
          }
        }
      },
      immediate: true
    }
  }
};
</script>
