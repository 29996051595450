<template>
  <CBox d="inline-flex" w="100%" class="noticeCard" data-cy="home-notice-swiper-content" :class="item.Cor">
    <CBox w="auto">
      <div class="img" data-cy="home-notice-swiper-icon">
        <OmtIcons size="lg" color="white" :name="item.Icone" />
      </div>
    </CBox>
    <CBox w="100%">
      <div class="noticeContent" data-cy="home-notice-swiper-card-content">
        <OmtTypography as="h3-semi-bold" class="text-limit" height="40px">
          {{ item.Mensagem }}
        </OmtTypography>
        <OmtTypography as="h3-bold" color="var(--colors-light-primary-navy-blue)" mt="3"> Ver detalhes </OmtTypography>
      </div>
    </CBox>
  </CBox>
</template>
<script>
export default {
  name: "HomeNoticeCard",
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
<style lang="scss" scoped>
.noticeCard {
  min-height: 80px;
  background: #f6f6f9;
  border-radius: 8px;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }

  .img {
    min-width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background: var(--colors-light-primary-navy-blue);
  }

  .text-limit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &.primary-navyBlue {
    .img {
      background: var(--colors-light-primary-navy-blue);
    }
  }
  &.support-success {
    .img {
      background: var(--colors-light-support-success);
    }
  }
  &.support-warning {
    .img {
      background: var(--colors-light-support-warning);
    }
  }
  &.support-error {
    .img {
      background: var(--colors-light-support-error);
    }
  }
  &.support-news {
    .img {
      background: var(--colors-light-support-news);
    }
  }
  .noticeContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-start;
    height: 100%;
    padding: 8px 16px;
    gap: 12px;
  }
}
</style>
