<template>
  <CModal size="sm" :is-centered="true" :on-close="closeInfoModal" :closeOnOverlayClick="true" :is-open="true">
    <CModalContent borderRadius="4px" ref="content">
      <CModalBody
        borderRadius="4px"
        :class="theme"
        pb="0"
        pt="var(--space-3)"
        px="var(--space-3)"
        style="color: var(--colors-light-primary-gray1)"
      >
        <CBox position="relative">
          <i class="fa fa-md fa-times" @click="closeInfoModal"></i>
        </CBox>

        <CBox class="modal-content">
          <i class="fa fa-md fa-info"></i>
          <CBox pb="4">
            <omt-h1>Confirma a exclusão?</omt-h1>
          </CBox>
        </CBox>
      </CModalBody>
      <CModalFooter :class="theme">
        <omt-button :onClick="emitCancel">
          <omt-p>CANCELAR</omt-p>
        </omt-button>
        <omt-button :colorInverse="true" :onClick="emitDelete">
          <omt-p>EXCLUIR</omt-p>
        </omt-button>
      </CModalFooter>
    </CModalContent>
    <CModalOverlay />
  </CModal>
</template>

<script>
import { CBox, CFlex, CStack, CModal, CModalOverlay, CModalFooter, CModalContent, CModalBody } from "@chakra-ui/vue";
import Options from "@/components/Molecules/Options";
import AccountCard from "@/components/Organisms/BankAccount/AccountCard.vue";
import { mapState } from "vuex";

export default {
  name: "DeleteModal",
  components: {
    CBox,
    CFlex,
    Options,
    CStack,
    AccountCard,
    CModal,
    CModalFooter,
    CModalOverlay,
    CModalContent,
    CModalBody
  },
  computed: {
    ...mapState("saude", ["theme"])
  },
  methods: {
    closeInfoModal() {
      this.$emit("close");
    },
    emitCancel() {
      this.$emit("cancel");
    },
    emitDelete() {
      this.$emit("delete");
    }
  }
};
</script>

<style lang="scss" scoped>
h1,
p {
  margin: 0;
}

button.inverse > .p {
  color: white !important;
}

footer.dark .button:not(.inverse) {
  background-color: transparent;
}

div[data-chakra-component="CModalBody"].dark {
  border-left: 1px solid currentColor;
  border-top: 1px solid currentColor;
  border-right: 1px solid currentColor;
}

footer.dark {
  border-left: 1px solid currentColor;
  border-bottom: 1px solid currentColor;
  border-right: 1px solid currentColor;
}

.fa-info {
  border-radius: 50%;
  outline: 4px solid currentColor;
  padding: 10px;
  width: var(--space-10);
  height: var(--space-10);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.8);
}

.fa-times {
  position: absolute;
  right: 0;
  cursor: pointer;
  transition: opacity ease 0.5s;
  &:hover {
    opacity: 0.6;
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--space-3);
}

::v-deep footer {
  button {
    width: 100%;
  }
  & > button {
    border: 1px solid #68788c !important;
    margin-right: var(--space-3);
  }
}
</style>
