import { render, staticRenderFns } from "./HomeCredential.vue?vue&type=template&id=7094ce72&"
import script from "./HomeCredential.vue?vue&type=script&lang=js&"
export * from "./HomeCredential.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CGrid: require('@chakra-ui/vue').CGrid, CGridItem: require('@chakra-ui/vue').CGridItem, CPseudoBox: require('@chakra-ui/vue').CPseudoBox})
