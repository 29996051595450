<template>
  <CSwitch :isChecked="value" :isDisabled="disabled" @change="handleChange" />
</template>
<script>
import { CSwitch } from "@chakra-ui/vue";

/**
 * Button component based on https://vue.chakra-ui.com/switch
 * @prop {Boolean} initialValue
 */
export default {
  name: "OmtSwitch",
  components: {
    CSwitch
  },
  props: {
    initialValue: Boolean,
    disabled: {
      type: Boolean,
      defautl: false
    }
  },
  data() {
    return { value: "" };
  },
  methods: {
    handleChange(value) {
      if (!this.disabled) this.$emit("change", value);
    }
  },
  watch: {
    initialValue: {
      handler(newInitialValue) {
        this.$nextTick(() => {
          this.value = newInitialValue;
        });
      },
      immediate: true
    }
  }
};
</script>
