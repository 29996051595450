<template>
  <OmtCard display="flex" flex-direction="column">
    <CFlex>
      <CBox flex="1">
        <CFlex direction="row" justify="space-between">
          <CFlex :direction="['column', 'row']" style="column-gap: 4px" mt="8px">
            <OmtTypography :as="['h3-bold', 'p4-semi-bold']" mb="12px" color="var(--colors-light-primary-gray1)">
              {{ solicitacao.idioma }}
            </OmtTypography>
            <CBox>
              <OmtTypography :display="['none', 'block']" as="p1" pr="5px" color="var(--colors-light-primary-gray2)">
                - {{ solicitacao.data }}
              </OmtTypography>
              <OmtTypography :display="['block', 'none']" as="p2"> Solicitado em {{ solicitacao.data }} </OmtTypography>
            </CBox>
          </CFlex>
          <CBox :align-self="['flex-end', 'flex-start']">
            <OmtButton
              v-if="solicitacao.urlDownload"
              @click="emitirPdf"
              variant="ghost"
              size="sm"
              v-chakra
              h="18px"
              pr="0px"
            >
              <OmtTypography as="h3-bold" color="var(--colors-light-primary-navy-blue)" text-decoration="underline">
                Baixar PDF
              </OmtTypography>
            </OmtButton>
          </CBox>
        </CFlex>
        <omt-divider />
        <CBox w="100%" v-for="(beneficiario, index) in solicitacao.beneficiarios" :key="index">
          <CFlex direction="row" justify="space-between">
            <OmtTypography :as="['p1', 'p3']" color="var(--colors-light-primary-gray1)" mb="16px">
              {{ capitalize(beneficiario.nome) }}
            </OmtTypography>

            <CBox>
              <OmtTypography :as="['p1', 'p3']" mb="16px" color="var(--colors-light-secondary-gray2)">
                {{ capitalize(beneficiario.parentesco) }}
              </OmtTypography>
            </CBox>
          </CFlex>
          <omt-divider v-if="index < solicitacao.beneficiarios.length - 1" />
        </CBox>
      </CBox>
    </CFlex>
  </OmtCard>
</template>

<script>
import { capitalize } from "@/utils/utils";

export default {
  props: {
    solicitacao: {
      type: Object,
      required: true
    }
  },
  methods: {
    async emitirPdf() {
      this.$emit("emitir-pdf", this.solicitacao.urlDownload);
    },
    capitalize(text) {
      return capitalize(text);
    }
  }
};
</script>
