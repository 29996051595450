const baseURL = `${process.env.VUE_APP_SERVER_URL}/PushNotification`;

export async function RegistrarDevice({ vinculo, perfil, token, system, systemVersion }) {
  return window.$axios
    .post({
      url: `${baseURL}/RegistrarDevice`,
      query: {
        idOperacaoPerfilOmint: perfil,
        nrVinculo: vinculo,
        deviceId: token,
        sistemaOperacional: system,
        versaoSistemaOperacional: systemVersion
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("RegistrarDevice", error.message);
      return error;
    });
}
