<script>
import Vue from "vue";
import { CRadio } from "@chakra-ui/vue";

export default Vue.component("OmtRadio", {
  props: {
    value: String
  },
  render(createElement) {
    return createElement(
      CRadio,
      {
        nativeOn: {
          change: () => {
            this.$emit("click", this.value);
          }
        },
        class: "omt-radio",
        attrs: {
          ...this.$attrs
        }
      },
      this.$slots.default
    );
  }
});
</script>

<style scoped>
::v-deep.omt-radio div[data-chakra-component="CBox"] {
  margin-left: 0;
}

::v-deep.omt-radio input[type="radio"]:checked + div {
  background: white;
}

::v-deep.omt-radio input[type="radio"]:checked + div span {
  background: var(--colors-light-primary-gray1);
  height: 57%;
  width: 57%;
}

::v-deep.omt-radio input[type="radio"] + div {
  background: white;
  border-color: var(--colors-light-primary-gray1);
}

::v-deep.omt-radio {
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
}
</style>
