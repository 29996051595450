<template>
  <div class="options-box" :class="myClass">
    <Dropdown
      ref="dropdown"
      :name="label || ''"
      :options="options.map(o => ({ id: o.value, name: o.label }))"
      :maxItem="10"
      :placeholder="placeholder || ''"
      v-on:selected="onSelection"
      :onBlur="onBlur"
    />
    <div :class="`${solid ? 'chevron-box solid' : 'chevron-box'}`">
      <i class="chevron fas fa-chevron-right fa-1x"></i>
    </div>
  </div>
</template>

<script>
import Dropdown from "@/components/Molecules/Dropdown";

export default {
  name: "Options",
  components: { Dropdown },
  props: ["solid", "options", "label", "onSelection", "id", "name", "placeholder", "preSelected", "onBlur"],
  data: function () {
    return {
      selected: null
    };
  },
  methods: {
    reset() {
      this.$refs.dropdown.clear();
    },
    setOption(label) {
      const opt = this.options.find(o => o.label === label);
      if (opt) {
        this.$refs.dropdown.selectOption({ id: opt.value, name: opt.label }, true);
      }
    }
  },
  computed: {
    myClass() {
      return `${this.solid ? " solid" : ""}`;
    }
  },
  mounted() {
    if (this.preSelected && this.options.length === 1) {
      this.setOption(this.options[0].label);
    }
  },
  watch: {
    options: function () {
      if (this.preSelected && this.options.length === 1) {
        this.setOption(this.options[0].label);
      }
    },
    selected: function (val) {
      this.$emit("selected", val);
    }
  }
};
</script>

<style scoped>
.options-box {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border: 0;
  box-shadow: none;
}

.dark .options-box.solid ::v-deep .dropdown__input {
  color: var(--colors-light-primary-gray1);
}

.options-box.solid {
  background: white;
  color: var(--colors-light-primary-gray1);
  border: 1px transparent;
  border-bottom: transparent 1px;
}

.options-box ::v-deep .dropdown__input {
  font-size: 12px;
  height: 28px;
  padding: 6px 0px 6px 10px;
}

.big-font-size .options-box ::v-deep .dropdown__input {
  font-size: 18px;
}

.light .options-box {
  border-bottom: solid 1px rgba(0, 0, 0, 0.08);
}

.dark .options-box {
  border-bottom: solid 1px rgba(255, 255, 255, 0.08);
}

.light .options-box:hover:not(.solid) {
  border-bottom: solid 1px black;
}

.dark .options-box:hover {
  border-bottom: solid 1px white;
}

.chevron {
  position: relative;
  display: block;
  top: 2px;
}

.light .chevron {
  color: #041534;
}

.dark .chevron {
  color: white;
}

.chevron-box {
  position: relative;
  right: 10px;
}
.chevron-box.solid {
  top: 10px;
  right: 12px;
}
l {
  width: 100vw;
  font-weight: 700;
}
</style>
