<template>
  <OmtCard display="flex" flex-direction="column" cursor="pointer" v-bind="$attrs" v-on="$listeners">
    <CFlex>
      <CBox w="36px">
        <OmtIcons name="square-poll-horizontal" size="lg" />
      </CBox>
      <CBox flex="1">
        <OmtTypography as="h3-bold" mb="12px">
          {{ title }}
        </OmtTypography>
        <OmtTypography as="p2" color="var(--colors-light-primary-gray2)" mb="16px">
          Solicitada em {{ createdAt }}
        </OmtTypography>
        <OmtTypography as="p1" color="var(--colors-light-primary-gray1)">
          {{ beneficiaryName }}
        </OmtTypography>
        <CDivider mt="16px" mb="16px" />
        <StatusTile v-chakra mt="16px" mb="14px" :label="statusDsc" :color="statusColor" />
      </CBox>
    </CFlex>
    <CFlex v-if="canEvaluate" justify-content="center" mt="5px">
      <OmtButton
        leftIcon="thumbs-up"
        size="md"
        iconSize="1x"
        border=".5px solid var(--colors-light-secondary-divider)"
        :variant="'outline'"
        :iconColor="'var(--colors-light-primary-gray1)'"
        w="158px !important"
        pl="15px"
        pr="15px"
        @click.stop="$emit('evaluate', protocol)"
      >
        <OmtTypography as="button-small" :color="'var(--colors-light-primary-gray1)'"> Avaliar serviço </OmtTypography>
      </OmtButton>
    </CFlex>
  </OmtCard>
</template>

<script>
import StatusTile from "./StatusTile";

/**
 * @prop { String } protocol
 * @prop { String } title
 * @prop { String } createdAt
 * @prop { String } beneficiaryName
 * @prop { String } statusCode
 * @prop { String } statusDsc
 * @prop { Boolean } canEvaluate
 */
export default {
  name: "RequestCard",
  components: { StatusTile },
  props: {
    protocol: { Type: String },
    title: { Type: String },
    createdAt: { Type: String },
    beneficiaryName: { Type: String },
    statusCode: { Type: String },
    statusDsc: { Type: String },
    canEvaluate: { Type: Boolean }
  },
  computed: {
    statusColor() {
      switch (this.statusCode) {
        case "F":
          return "var(--colors-light-support-success)";
        case "P":
          return "var(--colors-light-support-warning)";
        default:
          return "var(--colors-light-primary-dark-blue)";
      }
    }
  }
};
</script>
