<template>
  <div class="add-button-component">
    <div class="fixed-add-button-margin" v-if="margin" />
    <div class="add-button-fixed add-button-fixed-shadow">
      <div class="add-button-fixed add-button-fixed-outer">
        <div class="add-button-fixed add-button-fixed-inner">
          <router-link v-if="link" class="add-button-fixed--icon" :to="link">
            <i class="fas fa-plus fa-3x"></i>
          </router-link>
          <a v-else v-on:click.prevent="onLineClick" class="add-button-fixed--icon">
            <i class="fas fa-plus fa-3x"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FloatingAddButton",
  props: ["link", "onClick", "margin"],
  methods: {
    onLineClick() {
      if (!navigator.onLine) {
        window.dispatchEvent(new Event("offline"));
        return;
      }
      this.onClick();
    }
  }
};
</script>
<style scoped>
.add-button-component {
  display: block;
}

.add-button-component * {
  cursor: pointer;
}

.add-button-fixed {
  border-radius: 100%;
  text-align: center;
  position: relative;

  display: block;
  color: var(--colors-light-primary-gray1);

  cursor: pointer;
}

.add-button-fixed-inner {
  width: 56px;
  height: 56px;
  font-size: 12px;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-button-fixed-outer {
  width: 64px;
  height: 64px;
  padding: 5px;
  margin: 0px;
  margin-top: 2px;
  margin-left: 2px;
  background-color: white;
}

.add-button-fixed-shadow {
  position: fixed;
  width: 78px;
  height: 78px;
  padding: 0px;
  margin: 0px;
  bottom: 65px;
  right: 25px;
  z-index: 2;
}

@media screen and (min-width: 970px) {
  .add-button-fixed-shadow {
    right: calc(25px + (100vw - 970px) / 2);
  }
}

.fixed-add-button-margin {
  display: block;
  min-height: 100px;
  clear: both;
}

.light .add-button-fixed-inner {
  border: 4px solid var(--colors-light-primary-gray1);
}

.light .add-button-fixed--icon i,
.light .add-button-fixed--icon:visited,
.light .add-button-fixed--icon:hover,
.light .add-button-fixed--icon:active {
  color: var(--colors-light-primary-gray1);
}

.dark .add-button-fixed-inner {
  border: 4px solid #1f1f1f;
}

.dark .add-button-fixed--icon i,
.dark .add-button-fixed--icon:visited,
.dark .add-button-fixed--icon:hover,
.dark .add-button-fixed--icon:active {
  color: #1f1f1f;
}
</style>
