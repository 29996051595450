<template>
  <OmtStickedModal v-if="isOpen" @close="$emit('update:isOpen', false)">
    <OmtTypography as="h1-bold" mb="24px"> Cancelar solicitação de reembolso </OmtTypography>

    <OmtTypography as="p1" mb="16px"> Tem certeza que deseja cancelar esta solicitação? </OmtTypography>

    <CFlex backgroundColor="var(--colors-light-primary-light-gray)" borderRadius="8px" p="16px">
      <CBox>
        <OmtIcons name="circle-exclamation" size="sm" mr="2" color="var(--colors-light-support-warning)" />
      </CBox>
      <CBox>
        <OmtTypography as="p2">
          <b>Importante</b>: A reapresentação destas despesas, sob qualquer forma, reiniciará o processo de auditoria.
        </OmtTypography>
      </CBox>
    </CFlex>

    <template #footer>
      <CStack w="100%">
        <OmtButton w="100%" @click="$emit('cancel')"> Cancelar solicitação </OmtButton>
        <OmtButton w="100%" variant="ghost" @click="$emit('update:isOpen', false)"> Voltar </OmtButton>
      </CStack>
    </template>
  </OmtStickedModal>
</template>

<script>
export default {
  props: {
    isOpen: {
      Type: Boolean
    }
  }
};
</script>
