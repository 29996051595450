<template>
  <CSelect
    v-model="select"
    v-bind="{
      fontSize: '14px',
      lineHeight: '19px',
      color: 'var(--colors-light-primary-gray1)',
      borderColor: 'var(--colors-light-secondary-divider)',
      variant,
      _focus: {
        borderColor: 'var(--colors-light-secondary-divider'
      },
      ...styleByVariant,
      ...$attrs
    }"
    @change="onChange"
    class="atoms-select"
  >
    <slot />
  </CSelect>
</template>

<script>
import { CSelect } from "@chakra-ui/vue";
import _ from "lodash";

/**
 * Select component based on https://vue.chakra-ui.com/select
 * @prop {String} initialValue
 * @prop {String} variant currently options : "unstyled" | "outline" | "filled" | "flushed"
 */
export default {
  props: {
    initialValue: String,
    variant: {
      type: String,
      validator: value => {
        const options = ["outline", "unstyled", "flushed", "filled"];
        const validOption = !!options.includes(value);
        if (!validOption) console.error(`Allowed select variant options : ${options.join(" - ")}`);
        return validOption;
      },
      default: "flushed"
    }
  },
  components: {
    CSelect
  },
  data() {
    return {
      select: ""
    };
  },
  methods: {
    onChange: _.debounce(function (val) {
      this.$emit("change", val);
    }, 0)
  },
  watch: {
    initialValue: {
      handler(newInitialValue) {
        this.$nextTick(() => {
          this.select = newInitialValue;
        });
      },
      immediate: true
    }
  },
  computed: {
    styleByVariant() {
      const VARIANTS = {
        outline: {
          borderRadius: "32px"
        }
      };

      return VARIANTS[this.variant] || {};
    }
  }
};
</script>

<style>
.atoms-select select[data-chakra-component="CSelectInput"] {
  font-weight: 700;
}

.atoms-select div[data-chakra-component="CSelectIconWrapper"] {
  right: 0rem !important;
}
</style>
