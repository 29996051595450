<script>
import Vue from "vue";
import { CButton } from "@chakra-ui/vue";
import OmtIcons from "../Icons/index.vue";

const BASE = {
  rounded: "var(--radii-button)",
  _focus: {
    boxShadow: "none !important"
  },
  _hover: {
    opacity: "var(--opacity-light)"
  },
  _active: {
    background: "none"
  }
};

const VARIANTS = {
  solid: {
    bg: "var(--colors-light-primary-navy-blue)",
    color: "white",
    border: "0.5px solid var(--colors-light-primary-divider)",
    _disabled: {
      opacity: 0.8,
      cursor: "not-allowed",
      bg: "var(--colors-light-secondary-disabled)",
      color: "var(--colors-light-secondary-disabled-placeholder)"
    }
  },
  outline: {
    color: "var(--colors-light-primary-navy-blue)",
    bg: "white",
    border: "0.5px solid var(--colors-light-primary-gray1)"
  },
  "outline-gray": {
    color: "var(--colors-light-primary-navy-blue)",
    bg: "white",
    border: "0.5px solid var(--colors-light-secondary-divider)"
  },
  ghost: {
    bg: "transparent"
  }
};

/**
 * Button component based on https://vue.chakra-ui.com/button
 *
 * @prop {Boolean} isDisabled
 * @prop {Boolean} isLoading
 * @prop {String} loadingText
 * @prop {String} leftIcon all avaliable options on fontawesome v5
 * @prop {String} rightIcon all avaliable options on fontawesome v5
 * @prop {String} size currently options : "xs" (height: 34px) | "sm" (height: 40px) | "md"  (height: 48px) | "lg"  (height: 58px)
 * @prop {String} variant currently options : "solid" | "outline" | "ghost"
 * @prop {String} iconColor
 * @prop {String} iconSize
 */
export default Vue.component("OmtButton", {
  props: {
    variant: {
      type: String,
      validator: value => {
        const options = Object.keys(VARIANTS);
        const validOption = !!options.includes(value);
        if (!validOption) console.error(`Allowed button variant options : ${options.join(" - ")}`);
        return validOption;
      },
      default: "solid"
    },
    iconColor: { type: String },
    iconSize: { type: String },
    size: {
      type: String,
      validator: value => {
        const options = ["xs", "sm", "md", "lg"];
        const validOption = !!options.includes(value);
        if (!validOption) console.error(`Allowed button sizes : ${options.join(" - ")}`);
        return validOption;
      },
      default: "lg"
    }
  },
  render: function (createElement) {
    const { leftIcon, rightIcon, ...args } = this.$attrs;

    const sizes = {
      xs: "34px",
      sm: "40px",
      md: "48px",
      lg: "58px"
    };

    const iconsAttrs = {
      color: this.iconColor,
      size: this.iconSize
    };

    return createElement(
      CButton,
      {
        on: { ...this.$listeners },
        attrs: {
          height: sizes[this.size],
          ...VARIANTS[this.variant],
          ...args,
          ...BASE
        }
      },
      [
        !!leftIcon &&
          createElement(OmtIcons, {
            attrs: {
              name: leftIcon,
              mr: "1rem",
              ...iconsAttrs
            }
          }),
        this.$slots.default,
        !!rightIcon &&
          createElement(OmtIcons, {
            attrs: {
              name: rightIcon,
              ml: "1rem",
              ...iconsAttrs
            }
          })
      ]
    );
  }
});
</script>
