<template>
  <CStack v-if="evaluation" p="16px" :class="theme + ' attendance-card'">
    <CStack v-for="(prestador, index) in prestadores" :key="`prest-${index}`">
      <CFlex align-items="baseline" mb="24px" pb="24px" borderBottom=".5px solid #cccccc">
        <i class="fa fa-user-md fa-2x" v-chakra mr="16px"></i>
        <omt-h2>
          {{ prestador.NomePrestador }}
        </omt-h2>
      </CFlex>

      <CStack class="attendance-card-text-container" pb="24px" mb="24px">
        <CStack class="attendance-card-text">
          <omt-p>Especialidade</omt-p>
          <omt-h5>{{ prestador.Especialidade }}</omt-h5>
        </CStack>

        <CStack v-if="false" class="attendance-card-text">
          <omt-p>Beneficiário atendido</omt-p>
          <omt-h5> TODO </omt-h5>
        </CStack>

        <CFlex
          justify-content="space-between"
          :pb="evaluation.Respondido == 'S' ? '' : '24px'"
          :borderBottom="evaluation.Respondido == 'S' ? '' : '.5px solid #cccccc'"
        >
          <CStack class="attendance-card-text">
            <omt-p>Data do atendimento</omt-p>
            <omt-h5>{{ prestador.DataAtendimento }}</omt-h5>
          </CStack>

          <CStack
            v-if="prestador.HoraAtendimento && prestador.HoraAtendimento !== '00:00'"
            class="attendance-card-text"
          >
            <omt-p>Horário do atendimento</omt-p>
            <omt-h5>{{ prestador.HoraAtendimento }}</omt-h5>
          </CStack>
        </CFlex>
      </CStack>
    </CStack>

    <CBox v-if="evaluation.Respondido === 'S'" class="evaluationStatusBox">
      <template v-if="evaluation.TipoAtendimento === '600'">
        <CFlex v-if="evaluation.ConfirmaAtendimento" align="baseline">
          <i class="fas fa-check-circle iconEvaluation green" v-chakra mr="16px"></i>
          <omt-h5> Atendimento realizado </omt-h5>
        </CFlex>
        <CFlex v-else align="baseline">
          <i class="fas fa-times-circle iconEvaluation red" v-chakra mr="16px"></i>
          <omt-h5> Atendimento não realizado </omt-h5>
        </CFlex>
      </template>
      <template v-if="evaluation.Respostas">
        <CBox v-for="(p, index) in evaluation.Respostas" :key="`${evaluation.idAtendimento}-${index}`">
          <omt-p> {{ index + 1 }} {{ p.Pergunta }}</omt-p>
          <omt-p>
            <omt-bold>{{ p.Resposta }}</omt-bold>
          </omt-p>
          <omt-divider v-if="index + 1 < evaluation.Respostas.length" />
        </CBox>
      </template>
    </CBox>
    <CBox v-else>
      <omt-h5> <strong>Você confirma esse atendimento?</strong> </omt-h5>

      <omt-buttons-bar v-chakra mt="24px">
        <omt-button
          :colorInverse="
            (!confirmEvaluation && confirmEvaluation !== null) ||
            (evaluation.ConfirmaAtendimento == false && !confirmEvaluation && confirmEvaluation !== null)
          "
          :onClick="() => (confirmEvaluation = false)"
        >
          <strong>Não</strong>
        </omt-button>
        <omt-button
          :colorInverse="confirmEvaluation || (evaluation.ConfirmaAtendimento == true && confirmEvaluation !== null)"
          :onClick="() => (confirmEvaluation = true)"
        >
          <strong>Sim</strong>
        </omt-button>
      </omt-buttons-bar>
    </CBox>

    <slot></slot>
  </CStack>
</template>

<script>
import { CFlex, CBox, CStack } from "@chakra-ui/vue";
import Tile from "@/components/Molecules/Tile";
import { mapState } from "vuex";

export default {
  props: ["evaluation", "prestadores"],
  components: {
    Tile,
    CFlex,
    CBox,
    CStack
  },
  data() {
    return {
      confirmEvaluation: null
    };
  },
  watch: {
    confirmEvaluation(confirmEvaluation) {
      this.$emit("confirmEvaluation", confirmEvaluation);
    }
  },
  computed: mapState("saude", ["theme"])
};
</script>

<style lang="scss" scoped>
h2,
p {
  margin: 0;
}

.button {
  border: 1px solid #6f8092 !important;
  width: 50%;

  &:hover {
    opacity: 0.6;
  }

  & + & {
    margin-left: 24px;
  }
}

.attendance-card.light {
  background-color: white;
  color: var(--colors-light-primary-gray1);
}

.attendance-card.dark {
  background-color: #000000;
  color: var(--colors-light-primary-gray1);
}

.attendance-card {
  &-text-container {
    gap: 16px;
  }

  &-text {
    gap: 8px;
  }
}
.evaluationStatusBox {
  border: 0.5px solid #cccccc;
  padding: 16px;
  h5 {
    margin-bottom: 0 !important;
    font-weight: 600;
  }
  .iconEvaluation {
    font-size: 1.2rem;
    &.green {
      color: #00c37d;
    }
    &.red {
      color: #c31700;
    }
  }
}
</style>
