import Vue from "vue";
import { CancelarTarefa, ObterProtocoloANS } from "@/api/atendimento";
import { ListarTarefas, DetalheTarefa, ObterRegrasAgenda, ObterAgenda, NovaTarefa } from "@/api/agenda";
import { ObterToken, GravaAcessos, ObterPessoasOnline, ObterImagemProfile } from "@/api/videoConferencia";
import { getUA } from "@/utils/deviceName";
import { checkUpdatePermission } from "@/utils/checkReadonlyUtil";

const initialState = {
  taskList: [],
  callInfos: {}
};

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions: {
    async createTask(
      context,
      { beneficiario, local, especialidade, staff, dia, mes, ano, hora, duracao, chamado, reserva, slot, tipo }
    ) {
      checkUpdatePermission();
      const dispositivo = getUA();
      const response = await NovaTarefa({
        vinculo: context.rootState.saude.vinculo,
        beneficiario,
        local,
        especialidade,
        staff,
        dia,
        mes,
        ano,
        hora,
        duracao,
        dispositivo,
        chamado,
        reserva,
        slot,
        tipo
      });
      if (response instanceof Error) throw response;

      return response?.Data;
    },
    async featchTasks(context) {
      const response = await ListarTarefas({
        vinculo: context.rootState.saude.vinculo
      });
      if (response && response.Data) {
        await context.commit("SET_TASK_LIST", response.Data);
      }
    },
    async featchTaskDetail(context, { chamado }) {
      const response = await DetalheTarefa({
        vinculo: context.rootState.saude.vinculo,
        chamado
      });
      return response?.Data;
    },
    async featchTaskRules(context, { chamado, prestador, tipo }) {
      const response = await ObterRegrasAgenda({
        vinculo: context.rootState.saude.vinculo,
        chamado,
        prestador,
        tipo
      });
      return response?.Data;
    },
    async featchCalendar(context, { beneficiario, local, especialidade, staff }) {
      const response = await ObterAgenda({
        vinculo: context.rootState.saude.vinculo,
        beneficiario,
        local,
        especialidade,
        staff
      });
      return response?.Data;
    },
    async cancelTask(context, { chamado }) {
      checkUpdatePermission();
      const response = await CancelarTarefa({
        vinculo: context.rootState.saude.vinculo,
        chamadoRef: chamado
      });
      const sucess = !(response instanceof Error);
      if (sucess) {
        //atualiza a lista de tarefas
        await context.dispatch("featchTasks");
      }
      return sucess;
    },
    async featchVideoCallTokens(context, { chamado, tpPessoa }) {
      const vinculo = context.rootState.saude.vinculo;
      const tokenResponse = await ObterToken({
        vinculo,
        idChamado: chamado,
        tpPessoa
      });
      const callInfos = tokenResponse instanceof Error ? {} : tokenResponse.Data[0];
      context.commit("SET_CALL_INFOS", callInfos);
      return !(tokenResponse instanceof Error);
    },
    async registerRoomAccess(context, { tpPessoa }) {
      checkUpdatePermission();
      const vinculo = context.rootState.saude.vinculo;
      const callInfos = context.state.callInfos;
      if (callInfos.idSalaPessoa) {
        const accessResponse = await GravaAcessos({
          vinculo,
          idSalaPessoa: callInfos.idSalaPessoa,
          tpPessoa
        });
        return !(accessResponse instanceof Error);
      } else {
        return false;
      }
    },
    async featchImagemProfile(context, { staff }) {
      const vinculo = context.rootState.saude.vinculo;
      const imagemProfile = await ObterImagemProfile({
        vinculo,
        pessoa: staff
      });
      return imagemProfile?.Data ?? "";
    },
    async featchOnlinePeople(context) {
      if (!context.state.callInfos?.idSalaPessoa) {
        return {};
      }
      const response = await ObterPessoasOnline({
        vinculo: context.rootState.saude.vinculo,
        idSalaPessoa: context.state.callInfos.idSalaPessoa
      });
      return response instanceof Error ? {} : response.Data;
    },
    async fetchANSProtocol(context, { chamado }) {
      const response = await ObterProtocoloANS({
        vinculo: context.rootState.saude.vinculo,
        idChamado: chamado
      });
      return response instanceof Error ? "" : response;
    }
  },
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    SET_TASK_LIST: (state, tasks) => {
      Vue.set(state, "taskList", tasks);
    },
    SET_CALL_INFOS: (state, data) => {
      Vue.set(state, "callInfos", data);
    }
  }
};
