<template>
  <omint-page>
    <Header titleLabel="Voucher vacina" />
    <omint-page-content>
      <CBox>
        <CBox p="0" mb="24px" mx="auto" w="calc(100vw - 40px)" max-width="1024px">
          <OmtStepper :currentStep="vaccineStep" :totalSteps="3" :inverseColor="true" />
        </CBox>
        <router-view></router-view>
      </CBox>
    </omint-page-content>
    <Footer />
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";
import { mapState } from "vuex";

export default {
  name: "VoucherVaccine",
  components: { Header, Footer },
  computed: {
    ...mapState("voucher", ["vaccineStep", "vaccineTypes"])
  }
};
</script>
