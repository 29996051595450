<template>
  <div id="page" :class="theme + ' page'">
    <slot></slot>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("saude", ["theme"])
  }
};
</script>
<style>
.page {
  min-height: 100vh;
  width: 100vw;
  position: sticky;
}
/* tema base*/
.theme {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}
/* Tema claro */
.light {
  background-color: #fff;
  color: var(--colors-light-primary-gray1);
}

.light.page {
  border-image: linear-gradient(to bottom right, #f0f0f0, #e0e0e0) 1 100%;
}

.light a {
  color: #2980b9;
}
/* Tema escuro */
.dark {
  background-color: #000000;
  color: #69788f;
}

.dark.page {
  border-image: linear-gradient(to bottom right, #000000, #101010) 1 100%;
}

.dark a {
  color: white !important;
}
</style>
