<template>
  <omint-page-content>
    <omt-h1 class="title" v-html="titulo"></omt-h1>
    <div class="text" v-html="texto"></div>
    <hr />
    <div v-if="!aceiteRealizado" class="form">
      <SwitchCheckBox :onChange="val => (checked = val)" v-model="checked" :rounded="true">
        <span>
          Após ler atentamente as informações acima e esclarecer todas as dúvidas <strong>ACEITO PARTICIPAR</strong> por
          livre e espontânea vontade, do Programa Cuidar+ Omint e concordo com todos os termos aqui propostos.
        </span>
      </SwitchCheckBox>
    </div>

    <omt-buttons-bar>
      <omt-button v-if="!aceiteRealizado" :onClick="aceitar" :disabled="!checked"> Aceitar </omt-button>
    </omt-buttons-bar>
    <br />
  </omint-page-content>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import SwitchCheckBox from "@/components/Molecules/SwitchCheckBox";

export default {
  name: "ScreeningTerms",
  components: { SwitchCheckBox },
  data: function () {
    return {
      checked: false
    };
  },
  computed: {
    ...mapState("myAccount", ["screeningTerms"]),
    titulo() {
      return this.screeningTerms ? this.screeningTerms.Titulo : "";
    },
    texto() {
      return this.screeningTerms ? this.screeningTerms.Texto : "";
    },
    aceiteRealizado() {
      return this.screeningTerms ? this.screeningTerms.aceiteRealizado : false;
    }
  },
  async mounted() {
    this.$root.$emit("show-loading");
    try {
      await this.fetchScreeningTerms();
      if (!this.screeningTerms || !this.screeningTerms.Titulo) {
        this.voltar();
      }
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  methods: {
    ...mapActions("myAccount", ["fetchScreeningTerms", "acceptScreeningTerms"]),
    ...mapActions("saude", ["fetchMenuData"]),
    async aceitar() {
      this.$root.$emit("show-loading");
      const aceite = await this.acceptScreeningTerms();
      if (aceite.Data && aceite.Data.protocoloANS) {
        await this.fetchMenuData();
        await this.$root.$emit("hide-loading");
        await Swal.fire({
          title: "Seu aceite ao termo foi registrado com sucesso",
          text: `Seu protocolo é: ${aceite.Data.protocoloANS}`,
          confirmButtonText: "Continuar",
          icon: "success",
          timer: 10000,
          timerProgressBar: true
        });
        await this.$router.replace({ path: "/" });
      } else {
        await this.$root.$emit("hide-loading");
        await Swal.fire({
          title: "Erro",
          html: "<span> Não foi possivel registrar o atendimento. <br/ Serviço temporariamente indisponível. </span>",
          confirmButtonText: "OK",
          icon: "error"
        });
      }
    },
    voltar() {
      this.$router.replace({ path: "/" });
    }
  }
};
</script>
<style scoped lang="scss">
.title {
  text-transform: uppercase;
}
hr {
  border: 1px solid #eee;
}

::v-deep ul {
  padding-left: 16px;
}

.form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 25px;

  div:first-child {
    align-items: center;
    justify-content: center;
  }
}

.form input {
  display: block;
  text-align: center;
  width: 2rem;
  height: 2rem;
  margin-right: 5px;
  margin-left: -4px;
}

.form span {
  display: block;
  width: calc(100% - 2rem);
}

.dark .title,
.dark .text,
.dark .form {
  color: #f0f0f0;
}
.buttons {
  button {
    width: 100%;
  }
}
</style>
