// To Create a new blog post create a component inside
// 1- @/components/Organisms/Blog/BlogPosts/${id}.vue
// 2- uploadd a .png picture inside https://stgkippapps.blob.core.windows.net/img/${id}.png
// Obs: ${id} it will be used also as a route i.e: /blog/${id}

const getBaseById = id => ({
  id,
  asyncComponent: import(/* webpackChunkName: "blog_[request]" */ `@/components/Organisms/Blog/BlogPosts/${id}.vue`)
});

const posts = [
  {
    id: "quiz-fraudes",
    category: "Segurança e LGPD",
    date: "17/10/23",
    title: "Teste seus conhecimentos e proteja-se contra as fraudes",
    relatedPostThumb: "<b>Teste seus conhecimentos e proteja-se contra as fraudes</b>",
    relatedThumbObjectFit: "contain",
    thumb: "https://stgkippapps.blob.core.windows.net/img/Quiz_Fraude.png",
    externalLink: ({ titulo }) => ({
      mobile: `https://pages.omint.com.br/quiz/?titulo=${titulo}&type=quiz.pdf`,
      desktop: `https://www.omint.com.br/quiz-fraudes-de-reembolso/?titulo=${titulo}`
    })
  },
  {
    ...getBaseById("app-minha-omint-esta-de-cara-nova"),
    category: "Saúde e bem-estar",
    date: "01/08/23",
    title: "O app Minha Omint está de cara nova!",
    relatedPostThumb: "O app Minha Omint está de cara nova!",
    thumb: "https://stgkippapps.blob.core.windows.net/img/app-minha-omint-de-cara-nova-1.png"
  },
  {
    ...getBaseById("fraudes-e-reembolso"),
    category: "Fraudes e reembolso",
    date: "08/03/23",
    title: "Fraudes de reembolso: compartilhamento de senha",
    relatedPostThumb: "<b>Quando devo desconfiar de possíveis fraudes</b> de reembolso em planos de saúde?",
    thumb: "https://stgkippapps.blob.core.windows.net/img/fraudes-e-reembolso.png"
  },
  {
    ...getBaseById("seguro-de-vida-para-mulher"),
    category: "Vida e finanças",
    date: "08/03/23",
    title: "Seguro de vida para mulher: entenda os benefícios para o público feminino",
    relatedPostThumb: "<b>Seguro de vida para mulher:</b> entenda os benefícios para o público feminino",
    thumb: "https://stgkippapps.blob.core.windows.net/img/seguro-de-vida-para-mulher.png"
  },
  {
    ...getBaseById("invisalign"),
    category: "Saúde e bem-estar",
    date: "04/03/23",
    title: "Invisalign: alinhe seus dentes com conforto, discrição e liberdade",
    relatedPostThumb: "<b>Invisalign:</b> alinhe seus dentes com conforto, discrição e liberdade",
    thumb: "https://stgkippapps.blob.core.windows.net/img/invisalign.png"
  },
  {
    ...getBaseById("novo-programa-60-mais"),
    category: "Saúde e bem-estar",
    date: "04/03/23",
    title: "Novo programa 60+ Omint - Cuidado personalizado para seu bem estar",
    relatedPostThumb: "<b>Novo programa 60+ Omint </b> - Cuidado personalizado para seu bem estar",
    thumb: "https://stgkippapps.blob.core.windows.net/img/novo-programa-60-mais.png"
  },
  {
    ...getBaseById("fraudes-se-tornam-recorrentes"),
    category: "Fraudes e reembolso",
    date: "21/10/23",
    title: "Fraudes se tornam recorrentes em reembolso de planos de saúde",
    relatedPostThumb: "<b>Fraudes se tornam recorrentes em reembolso</b> de planos de saúde",
    thumb: "https://stgkippapps.blob.core.windows.net/img/fraudes-se-tornam-recorrentes.png"
  }
];

export const getPostbyId = id => posts.find(post => post.id === id);

export default posts;
