export async function dataURItoBlob(dataURI: string) {
  const byteString = atob(dataURI.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/png" });
}

export async function downloadBlob(blob: Blob) {
  const url = window.URL.createObjectURL(blob);
  const a: HTMLAnchorElement = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  a.href = url;
  a.target = "_blank";
  await a.click();
  await a.remove();
  window.URL.revokeObjectURL(url);
}

export async function downloadUrl(url: string) {
  const a: HTMLAnchorElement = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  a.href = url;
  a.target = "_blank";
  a.click();
  a.remove();
}

export function downloadUrlWithName(url: string, fileName: string) {
  const a: HTMLAnchorElement = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  a.href = url;
  a.download = fileName;
  a.click();
  a.remove();
}

export async function downloadBlobWithName(blob: Blob, fileName: string) {
  const url = window.URL.createObjectURL(blob);
  const a: HTMLAnchorElement = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  a.href = url;
  a.download = fileName;
  await a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
}

export function blobToBase64(blob) {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
}
