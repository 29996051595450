<template>
  <OmtLayoutContainer>
    <OmtRow mb="24px">
      <OmtTypography as="h1-bold"> Nova solicitação </OmtTypography>
    </OmtRow>
    <OmtRow mb="16px">
      <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> Assunto da solicitação </OmtTypography>
    </OmtRow>
    <OmtRow v-if="!loading">
      <Options
        id="optSubjectId"
        name="optSubject"
        :solid="true"
        :preSelected="subjectId"
        :options="subjectOptions"
        :onSelection="onSelectedSubject"
        placeholder="Selecione o assunto"
        class="options-requests"
      />
    </OmtRow>

    <template v-if="!loading && subjectId && subject.beneficiaryType">
      <OmtRow mt="24px" mb="16px">
        <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">
          Beneficiário que realizará o procedimento
        </OmtTypography>
      </OmtRow>
      <OmtRow>
        <Options
          id="optRecipientId"
          name="optRecipient"
          :solid="true"
          :preSelected="beneficiary"
          :options="beneficiaries"
          :onSelection="onSelectedBeneficiary"
          placeholder="Selecione o Beneficiário"
          class="options-requests"
        />
      </OmtRow>
    </template>
    <template v-if="subjectId">
      <OmtRow mt="24px">
        <div class="notices">
          <CFlex>
            <OmtIcons name="circle-exclamation" size="1x" mr="5" color="#4E4E4E" />
            <OmtTypography as="p3">
              <omt-bold>Evite atrasos na liberação da sua solicitação</omt-bold>
            </OmtTypography>
          </CFlex>
          <CBox mt="4">
            <OmtTypography as="p1" class="release">
              <span v-html="subject.orientation"></span>
            </OmtTypography>
          </CBox>
          <CFlex align-items="center" mt="4" v-if="subject.attchamentType">
            <OmtIcons name="file-waveform" size="sm" mr="2" color="#4E4E4E" />
            <OmtTypography as="p2">
              <omt-bold>{{ subject.attchamentType }}</omt-bold>
            </OmtTypography>
          </CFlex>

          <CBox mt="16px" ml="16px" v-if="attchamentDsc.length">
            <ul class="list">
              <li v-for="(item, index) in attchamentDsc" :key="`att-${index}`">
                <OmtTypography as="p2">{{ item }}</OmtTypography>
              </li>
            </ul>
          </CBox>
          <CFlex mt="4">
            <a class="show-all-button" v-on:click.prevent="() => (modalDocuments = true)"> Ver tudo </a>
            <OmtIcons name="angle-right" size="sm" ml="2" color="#172972" />
          </CFlex>
        </div>
      </OmtRow>
    </template>
    <FixedButtonRow>
      <OmtButton :w="['100%', '308px !important']" @click="next" :disabled="disableButton"> Continuar </OmtButton>
    </FixedButtonRow>
    <DocumentsModal :subjectOptions="subjectOptions" :isOpen="modalDocuments" @close="closeModal()" />
  </OmtLayoutContainer>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Options from "@/components/Molecules/Options";
import DocumentsModal from "@/components/Organisms/Requests/NecessaryDocumentsModal.vue";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

export default {
  name: "CreateRequest",
  components: {
    Options,
    DocumentsModal,
    FixedButtonRow
  },
  data() {
    return {
      beneficiary: "",
      loading: true,
      modalDocuments: false,
      subjectId: 0,
      subject: {}
    };
  },
  computed: {
    ...mapState("request", ["addPermission", "beneficiaries", "newRequest", "subjectOptions"]),
    attchamentDsc() {
      if (!this.subject?.attchamentDsc?.length) return [];
      const showMore = this.subject.attchamentDsc.length > 2;
      const attchamentDsc = [...this.subject.attchamentDsc.slice(0, 2).map(a => a.dsc)];
      if (showMore) attchamentDsc[1] = `${attchamentDsc[1]} ...`;
      return attchamentDsc;
    },
    disableButton() {
      if (this.subject?.beneficiaryType) return !this.subjectId || !this.beneficiary;
      return !this.subjectId;
    }
  },
  methods: {
    ...mapActions("request", ["fetchBeneficiaries", "fetchSubjectOptions"]),
    ...mapMutations("request", ["SET_BENEFICIARY", "SET_SUBJECT"]),
    onSelectedSubject(id) {
      if (id) {
        this.subjectId = id;
        this.subject = this.subjectOptions.find(s => s.value === id);
      }
    },
    onSelectedBeneficiary(beneficiary) {
      if (beneficiary) {
        this.beneficiary = beneficiary;
      }
    },
    next() {
      if (this.disableButton) return;
      this.SET_SUBJECT(this.subjectId);
      this.SET_BENEFICIARY(this.subject?.beneficiaryType ? this.beneficiary : "");
      this.$router.push(this.subject.attchamentType ? "/solicitacoes/upload" : "/solicitacoes/observacao");
    },
    closeModal() {
      this.modalDocuments = false;
    }
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      this.loading = true;
      if (!this.addPermission) this.$router.replace("/solicitacoes");
      await Promise.all([this.fetchBeneficiaries(), this.fetchSubjectOptions()]);
      if (this.newRequest?.subject) this.onSelectedSubject(this.newRequest.subject);
      if (this.newRequest?.beneficiary) this.onSelectedBeneficiary(this.newRequest.beneficiary);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
      this.$root.$emit("hide-loading");
    }
  }
};
</script>

<style scoped>
.notices {
  background-color: #f6f6f9;
  padding: 16px;
  border-radius: 8px;
}

.notices .release {
  line-height: 22px;
}

.list li {
  font-size: 12px;
}

.show-all-button {
  color: #172972;
  font-weight: 700;
  font-size: 14px;
}

.options-requests ::v-deep select {
  font-size: 14px !important;
  font-weight: 700;
}
</style>
