<template>
  <omint-page-content>
    <TokenModal
      v-if="showTokenModal"
      tokenKey="criar-cc"
      service="/conta-corrente"
      v-on:cancel="() => showModal(false)"
      v-on:valid="validToken"
    />
    <CFlex align="center" mb="var(--space-5)">
      <omt-h1 style="text-transform: uppercase">Cadastrar nova conta</omt-h1>
    </CFlex>

    <CBox class="base-space">
      <CBox>
        <CFlex align="center" mb="var(--space-3)">
          <i class="fas fa-lg fa-user" v-chakra mr="var(--radii-lg)"></i>
          <omt-p> Favorecido </omt-p>
        </CFlex>
        <omt-input :disabled="true" :value="nomeBeneficiario" :solid="true" />
      </CBox>

      <CBox>
        <CFlex align="center" mb="var(--space-3)">
          <i class="fas fa-lg fa-user" v-chakra mr="var(--radii-lg)"></i>
          <omt-p> CPF do Favorecido </omt-p>
        </CFlex>
        <omt-input :disabled="true" :value="documentoBeneficiario" :solid="true" />
      </CBox>

      <CBox>
        <CFlex align="center" mb="var(--space-3)">
          <i class="fas fa-lg fa-university" v-chakra mr="var(--radii-lg)"></i>
          <omt-p> Banco </omt-p>
        </CFlex>

        <CBox class="base-space">
          <Options
            :solid="true"
            :options="bankOptions"
            name="bancos"
            id="banks-list"
            :onSelection="onBankSelected"
            placeholder="Selecione um banco"
          />

          <Options
            v-if="bank === '' || loadingAgencies"
            :loading="loadingAgencies"
            :solid="true"
            name="agencias"
            id="agencies-list"
            :placeholder="bank === '' ? 'Antes, selecione um banco' : 'Buscando lista de agências...'"
          />

          <OptionsAutoCompleter
            v-else
            :solid="true"
            :options="agencyOptions"
            name="agencias"
            id="agencies-list"
            :onSelection="onAgencySelected"
            :onBlur="onAgencyBlur"
            placeholder="Selecione ou Digite o número da sua Agência"
          />
        </CBox>
      </CBox>

      <omt-p>Informe o código e nome da agência. (Ex: 0000)</omt-p>

      <CFlex class="base-space base-space--row" mb="var(--space-2)">
        <CBox class="base-space base-space--column">
          <omt-p> Conta </omt-p>
          <omt-input
            :solid="true"
            placeholder="número da conta"
            v-bind:value="accountNumber"
            v-on:input="checkAccountInput($event)"
            :onKeydown="apenasNumeros"
            :onBlur="onBlurValidate"
          />
        </CBox>

        <CBox class="base-space base-space--column">
          <omt-p> Dígito </omt-p>
          <omt-input
            v-bind:value="digit"
            v-on:input="checkDigitInput($event)"
            :disabled="maxDigits === 0"
            :solid="true"
            placeholder="dígito"
            :maxlength="maxDigits"
            :onKeyup="checkMaxDigits"
            :onKeydown="apenasNumeros"
            :onBlur="onBlurValidate"
          />
        </CBox>
      </CFlex>

      <CFlex align-items="center" v-if="titular">
        <CCheckbox class="radio" size="md" mb="var(--space-5)" v-model="isMainAccount" :is-disabled="isDefaultAccount">
          <omt-p> Selecionar como conta principal para crédito </omt-p>
        </CCheckbox>
        <i v-chakra class="fa fa-lg fa-info" mb="5" ml="3" @click="showInfoModal = true"></i>
      </CFlex>

      <CBox class="terms-check" :class="theme" mb="var(--space-4)">
        <CCheckbox borderColor="#102a46" v-model="checked1" />
        <omt-p>
          Estou ciente que os créditos de reembolso serão efetuados nessa conta corrente após análise e processamento
          pela Omint e que os processos de reembolso iniciados antes do processamento pela Omint, desta solicitação,
          ainda serão creditados na conta corrente anterior.
        </omt-p>
      </CBox>

      <CBox class="terms-check" :class="theme" mb="var(--space-4)">
        <CCheckbox borderColor="#102a46" v-model="checked2" />
        <omt-p>
          <omt-bold>Atenção: </omt-bold> Para efeito de eventuais reembolsos de despesas
          médico-hospitalares-laboratoriais, minhas e de meus dependentes, conforme previsto em contrato, na minha
          condição de Beneficiário Titular, declaro para todos os fins de direito que:
          <ol>
            <omt-li>
              AUTORIZO a Omint Serviços de Saúde Ltda. a efetuar depósitos na minha conta corrente indicada
            </omt-li>
            <omt-li> REPONSABILIZO-ME pelos dados contidos neste instrumento, informado por mim. </omt-li>
          </ol>
        </omt-p>
      </CBox>

      <omt-button :onClick="() => showModal(true)" :disabled="!canSubmit" :colorInverse="true"> Continuar </omt-button>
    </CBox>

    <CBox w="100%" h="var(--space-5)"></CBox>

    <AccountMainModal v-if="showInfoModal" @close="showInfoModal = false" />
    <ProtocolModal
      v-if="protocolModal.show"
      :isCentered="true"
      :protocol="protocolModal.protocol"
      :description="protocolModal.description"
      :success="protocolModal.success"
      @close="protocolModal.show = false"
      @conclude="handleConclude"
    />
  </omint-page-content>
</template>

<script>
import { CBox, CFlex, CStack, CCheckbox } from "@chakra-ui/vue";
import AccountMainModal from "@/components/Organisms/BankAccount/Modals/AccountMainModal.vue";
import OptionsAutoCompleter from "@/components/Molecules/OptionsAutoCompleter";
import Options from "@/components/Molecules/Options";
import AccountCard from "@/components/Organisms/BankAccount/AccountCard.vue";
import TokenModal from "@/components/Organisms/TokenModal";
import { mapState, mapActions } from "vuex";
import ProtocolModal from "@/components/Organisms/BankAccount/Modals/ProtocolModal.vue";

export default {
  name: "BankAccountCreate",
  components: {
    CBox,
    CFlex,
    CStack,
    OptionsAutoCompleter,
    Options,
    CCheckbox,
    AccountCard,
    TokenModal,
    AccountMainModal,
    ProtocolModal
  },
  data() {
    return {
      nomeBeneficiario: "",
      documentoBeneficiario: "",
      accountNumber: "",
      digit: "",
      bank: "",
      agency: "",
      showInfoModal: false,
      isMainAccount: false,
      maxDigits: 0,
      checked1: false,
      checked2: false,
      isDefaultAccount: false,
      loadingAgencies: false,
      showTokenModal: false,
      protocolModal: {
        show: false,
        protocol: "",
        description: "",
        success: false
      }
    };
  },
  async mounted() {
    if (!this.selectedBeneficiary || !this.beneficiaries) {
      this.$router.replace({ name: "bankaccount.home" });
      return;
    }
    const beneficiary = this.beneficiaries.find(b => b.value === this.selectedBeneficiary);
    if (!beneficiary) {
      this.$router.replace({ name: "bankaccount.home" });
      return;
    }
    this.nomeBeneficiario = beneficiary.label;
    this.documentoBeneficiario = beneficiary.document;

    this.fetchAccounts({ beneficiaryCode: this.selectedBeneficiary }).then(accounts => {
      if (!accounts.length) {
        this.isMainAccount = true;
        this.isDefaultAccount = true;
      }
    });

    try {
      await this.$root.$emit("show-loading");
      await this.listAvailableBanks();
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  methods: {
    ...mapActions("bankAccount", [
      "fetchAccounts",
      "listAvailableBanks",
      "listAvailableAgencies",
      "submitBeneficiaryBankInfos"
    ]),
    renderProtocolModal({ protocol, success, description }) {
      this.protocolModal.show = true;
      this.protocolModal.protocol = protocol;
      this.protocolModal.success = success;
      this.protocolModal.description = description;
    },
    apenasNumeros(e) {
      if (!this.checkSpecialKeys(e)) {
        if ((this.digit && this.digit.length > this.maxDigits) || !this.checkNumberKeys(e)) {
          e.returnValue = false;
          e.preventDefault();
        }
      }
    },
    checkAccountInput(value) {
      const newValue = value ? value.match("[0-9]*")[0] : "";
      if (newValue !== this.accountNumber) {
        this.accountNumber = newValue;
      }
    },
    checkDigitInput(value) {
      const newValue = value ? value.match("[0-9]*")[0] : "";
      if (newValue !== this.digit) {
        this.digit = newValue;
        this.checkMaxDigits();
      }
    },
    checkNumberKeys(e) {
      return (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105);
    },
    checkSpecialKeys(e) {
      return !(
        e.keyCode !== 8 &&
        e.keyCode !== 9 &&
        e.keyCode !== 46 &&
        e.keyCode !== 37 &&
        e.keyCode !== 38 &&
        e.keyCode !== 39 &&
        e.keyCode !== 40
      );
    },
    checkMaxDigits() {
      if (this.digit) {
        if (this.digit < 0) {
          this.digit = 0;
        } else if (this.digit.length > this.maxDigits) {
          this.digit = this.digit.slice(0, this.maxDigits);
        }
      }
    },
    onBankSelected(bank) {
      if (bank === "" || this.bank === bank) return;
      this.bank = bank;
      this.loadingAgencies = true;
      const bankInfos = this.banks.find(b => b.Codigo === bank);
      this.maxDigits = Number(bankInfos.Digito || 0);
      this.agency = "";
      this.digit = "";
      this.$nextTick(() => this.refreshAgencies());
    },
    async refreshAgencies() {
      try {
        await this.$root.$emit("show-loading");
        const error = await this.listAvailableAgencies({ bankCode: this.bank });
        if (!error) {
          await this.$root.$emit("hide-loading");
        }
      } finally {
        this.loadingAgencies = false;
      }
    },
    onBlurValidate(event) {
      event.target.setAttribute("aria-invalid", !event.target.value);
    },
    onAgencySelected(agency) {
      if (agency === "" || this.agency === agency) return;
      this.agency = agency;
    },
    onAgencyBlur(event) {
      event.target.setAttribute("aria-invalid", !this.agency);
    },
    showModal(value) {
      this.showTokenModal = value;
    },
    validToken({ auth }) {
      this.showModal(false);
      this.submit({ auth });
    },
    async submit({ auth }) {
      let protocolo = "";
      try {
        await this.$root.$emit("show-loading");
        const agencia = this.agencies.find(a => a.Codigo === this.agency);
        protocolo = await this.submitBeneficiaryBankInfos({
          beneficiario: this.selectedBeneficiary,
          idConta: 0,
          banco: this.bank,
          agencia: agencia ? agencia.Descricao : this.agency,
          conta: this.accountNumber,
          digito: this.digit,
          excluir: false,
          principal: this.isMainAccount,
          emailContato: "",
          telefoneContato: "",
          autenticacao: auth
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
      if (protocolo) {
        const description = this.isMainAccount
          ? "Em até 05 minutos esta conta corrente estará disponível e será a conta principal para uso em seu APP."
          : "Em até 05 minutos esta solicitação será processada.";
        this.renderProtocolModal({ protocol: protocolo, success: true, description });
      } else {
        this.renderProtocolModal({ protocol: "", success: false, description: "" });
      }
    },
    handleConclude() {
      this.$router.replace({ name: "bankaccount.home" });
    }
  },
  computed: {
    ...mapState("saude", ["theme", "titular"]),
    ...mapState("bankAccount", ["banks", "agencies", "selectedBeneficiary", "beneficiaries"]),
    bankOptions() {
      if (!this.banks) return [];
      return this.banks.map(b => ({ label: b.Descricao, value: b.Codigo }));
    },
    agencyOptions() {
      if (!this.agencies || !this.bank || this.loadingAgencies) return [];
      return this.agencies.map(a => ({ label: a.Descricao, value: a.Codigo }));
    },
    canSubmit() {
      return (
        !!this.accountNumber &&
        (this.digit !== "" || this.maxDigits === 0) &&
        !!this.checked1 &&
        !!this.checked2 &&
        !!this.bank &&
        !!this.agency
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.base-space {
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
  &--row {
    flex-direction: row !important;
  }

  &--column {
    width: 100%;
    max-width: 250px;
  }
}

h1,
p {
  margin: 0;
}

.dark input {
  background-color: white !important;
  color: #818181 !important;
}

.option {
  margin-bottom: 0 !important;
}

.fa-info-circle {
  cursor: pointer;
  transition: opacity ease 0.5s;
  &:hover {
    opacity: 0.6;
  }
}
.fa-info {
  border-radius: 50%;
  outline: 4px solid currentColor;
  padding: 10px;
  width: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.5);
  cursor: pointer;
  transition: opacity ease 0.5s;
  &:hover {
    opacity: 0.6;
  }
}
.radio {
  ::v-deep input[type="checkbox"] + div[data-chakra-component="CControlBox"] {
    svg {
      color: white;
    }
  }

  ::v-deep input[type="checkbox"]:checked + div[data-chakra-component="CControlBox"] {
    background-color: white;

    svg {
      background-color: #102a46;
      border-radius: 50%;
      height: 8px;
      width: 8px;
      color: var(--colors-light-primary-gray1);
    }
  }

  ::v-deep input[type="checkbox"]:checked:disabled + div[data-chakra-component="CControlBox"] {
    border-color: black;
    opacity: 0.6;
  }

  ::v-deep div[data-chakra-component="CControlBox"] {
    border-radius: 50% !important;
    border-color: #102a46;
    color: var(--colors-light-primary-gray1);
    background: white;
    height: var(--sizes-3);
    width: var(--sizes-3);
  }
}

::v-deep .terms-check.dark svg {
  color: black;
}

.terms-check {
  display: flex;
  align-items: baseline;
  gap: 12px;

  ::v-deep input[type="checkbox"]:checked + div[data-chakra-component="CControlBox"] {
    background-color: #68788c;
    border-color: #68788c;
  }

  ::v-deep div[data-chakra-component="CControlBox"] {
    height: var(--sizes-3);
    width: var(--sizes-3);
  }
}
</style>
