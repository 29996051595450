<template>
  <OmtLayoutContainer>
    <OmtRow>
      <OmtGrid v-if="!verificationStarted">
        <OmtRow mb="24px">
          <OmtTypography as="h1-bold"> Sua solicitação será processada após a confirmação do token </OmtTypography>
        </OmtRow>

        <OmtRow mb="24px">
          <OmtTypography as="p1"> Como deseja confirmar o código? </OmtTypography>
        </OmtRow>

        <template v-if="canUseCode">
          <OmtRow mb="22px">
            <OmtTypography as="h3-bold"> Por SMS </OmtTypography>
          </OmtRow>
          <OmtRow mb="32px" stack v-if="phones.length > 0">
            <CBox v-for="(phone, index) in phones" :key="index">
              <OmtDivider v-if="index !== 0" />
              <OmtRadio
                @click="value => selectMethod('CODIGO', 'phone', value)"
                :value="phone"
                name="verifycation-method"
              >
                <OmtTypography as="p1"> {{ phone }} </OmtTypography>
              </OmtRadio>
            </CBox>
          </OmtRow>
          <OmtRow v-else mb="32px">
            <OmtTypography as="p1" opacity="0.6"> Nenhum telefone cadastrado </OmtTypography>
          </OmtRow>

          <OmtRow mb="32px">
            <OmtTypography as="h3-bold"> Por e-mail </OmtTypography>
          </OmtRow>
          <OmtRow mb="32px" stack v-if="emails.length > 0">
            <CBox v-for="(email, index) in emails" :key="index">
              <OmtDivider v-if="index !== 0" />
              <OmtRadio
                @click="value => selectMethod('CODIGO', 'email', value)"
                :value="email"
                name="verifycation-method"
              >
                <OmtTypography as="p1"> {{ email }} </OmtTypography>
              </OmtRadio>
            </CBox>
          </OmtRow>
          <OmtRow v-else mb="32px">
            <OmtTypography as="p1" opacity="0.6"> Nenhum E-mail cadastrado </OmtTypography>
          </OmtRow>
        </template>

        <template v-if="canUsebiometry">
          <OmtRow mb="32px">
            <OmtTypography as="h3-bold"> Por biometria facial </OmtTypography>
          </OmtRow>
          <OmtRow mb="84px" stack>
            <OmtRadio
              @click="value => selectMethod('BIO-UNICO', 'biometry', value)"
              value="biometry"
              name="verifycation-method"
            >
              <OmtTypography as="p1">Realizar reconhecimento facial </OmtTypography>
            </OmtRadio>
          </OmtRow>
        </template>

        <OmtRow :desktopStart="5" :desktopEnd="8">
          <OmtButton :disabled="!canVerify" @click="startVerification"> Enviar </OmtButton>
        </OmtRow>
      </OmtGrid>

      <OmtGrid v-if="verificationStarted">
        <OmtRow v-if="selectedMethod === 'CODIGO'" stack>
          <OmtRow mb="24px">
            <OmtTypography as="h1-bold"> Verificação de identidade</OmtTypography>
          </OmtRow>

          <OmtRow mb="24px" stack>
            <OmtTypography as="h3-semi-bold"> Insira o código que enviamos para: <br /> </OmtTypography>
            <OmtTypography as="p1">
              {{ selectedContact }}
            </OmtTypography>
          </OmtRow>

          <OmtRow mb="24px">
            <TokenInput :value="token" @input="onTokenInput($event)" />
          </OmtRow>

          <OmtRow mb="32px">
            <OmtTypography as="p1"> Se você não receber o código em até 30 minutos, clique abaixo: </OmtTypography>
          </OmtRow>

          <OmtRow mb="24px">
            <OmtButton @click="enviarToken" variant="ghost">
              <OmtTypography
                cursor="pointer"
                as="h3-bold"
                color="var(--colors-light-primary-navy-blue)"
                text-align="center"
              >
                Reenviar código
              </OmtTypography>
            </OmtButton>
          </OmtRow>

          <OmtRow mb="32px">
            <OmtButton :disabled="!canCheckToken" @click="validarToken"> Enviar </OmtButton>
          </OmtRow>
        </OmtRow>

        <OmtRow v-else stack gap="24px">
          <BiometryAuthHeader />
          <BiometryAuthCard />
          <BiometryCameraButton
            @success="handleBiometryCameraSuccess"
            @error="handleError"
            @resourceError="handleError"
            @startLoading="$root.$emit('show-loading')"
            @endLoading="$root.$emit('hide-loading')"
            buttonLabel="Continuar"
            :confirmUser="true"
          />
        </OmtRow>

        <OmtRow>
          <FeedbackModal
            v-if="showSuccessModal"
            :protocol="modalInfos.protocol"
            :title="modalInfos.title"
            :cardTitle="modalInfos.cardTitle"
            :cardIcon="modalInfos.cardIcon"
            :modalIconBind="modalInfos.modalIconBind"
            :buttonLabel="modalInfos.buttonLabel"
            @buttonClick="closeModal"
            @close="closeModal"
          />
        </OmtRow>
      </OmtGrid>
    </OmtRow>
  </OmtLayoutContainer>
</template>

<script>
import TokenInput from "@/components/Molecules/TokenInput/index.vue";
import FeedbackModal from "@/components/Molecules/FeedbackModal";
import BiometryCameraButton from "@/components/Organisms/Biometry/BiometryCameraButton";
import BiometryAuthCard from "@/components/Organisms/Biometry/BiometryAuthCard.vue";
import BiometryAuthHeader from "@/components/Organisms/Biometry/BiometryAuthHeader.vue";
import mixin from "@/components/Organisms/TokenModal/mixin";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  mixins: [mixin],
  components: {
    TokenInput,
    FeedbackModal,
    BiometryAuthCard,
    BiometryAuthHeader,
    BiometryCameraButton
  },
  created() {
    this.disableAutoSendTokenWithNoPhone = true;
    this._service = "/formas-contato";
  },
  async mounted() {
    if (!this.hasChanges) {
      this.$router.push({
        path: "/meus-contatos"
      });
      return;
    }

    this.$root.$emit("change-title-label", "Verificação de identidade");

    try {
      this.$root.$emit("show-loading");
      const rules = await this.fetchAuthType({ service: "/formas-contato" });
      if (rules?.Autorizadores?.length || rules?.Autorizador) {
        if (rules?.Autorizadores?.length) {
          this.canUseCode = !!rules.Autorizadores.find(val => val === "CODIGO");
          this.canUsebiometry = !!rules.Autorizadores.find(val => val === "BIO-UNICO");
        } else {
          this.canUseCode = rules.Autorizador === "CODIGO";
          this.canUsebiometry = rules.Autorizador === "BIO-UNICO";
        }
        this.$on("valid", $event => this.handleValid($event));
        if (this.canUsebiometry && !this.canUseCode) {
          this.selectMethod("BIO-UNICO", "biometry", "BIO-UNICO");
          this.startVerification();
        }
        this.$root.$emit("hide-loading");
      } else {
        this.handleValid({ auth: null });
      }
    } catch (error) {
      this.$toast({
        description: error.message,
        status: "error",
        duration: 10000,
        position: "bottom-right"
      });
      this.$router.replace("/meus-contatos");
    } finally {
      if (this.canUseCode || this.canUsebiometry) {
        this.$root.$emit("hide-loading");
      }
    }
  },
  data() {
    return {
      selectedMethod: "",
      tokenType: "",
      selectedContact: "",
      verificationStarted: false,
      showSuccessModal: false,
      modalInfos: {
        protocol: "",
        modalIconBind: {
          name: "check-circle",
          color: "var(--colors-light-support-success)"
        },
        title: "Solicitação enviada com sucesso!",
        cardTitle: "Suas atualizações estarão disponíveis em até 15 minutos.",
        cardIcon: "mobile",
        buttonLabel: "Concluir"
      },
      response: {},
      canUseCode: false,
      canUsebiometry: false
    };
  },
  methods: {
    ...mapActions("myContacts", ["createChangeRequest"]),
    ...mapActions("ticket", ["fetchAuthType"]),
    ...mapMutations("ticket", ["SET_RATING_INFOS"]),
    ...mapMutations("myContacts", ["RESET_NEW_CONTACTS"]),
    ...mapActions("biometrics", ["authFace"]),
    selectMethod(method, tokenType, contact) {
      this.selectedContact = contact;
      this.selectedMethod = method;
      this.tokenType = tokenType;
    },
    startVerification() {
      if (this.selectedMethod === "CODIGO") {
        if (this.tokenType === "phone") {
          this.onPhoneSelected(this.selectedContact);
        } else {
          this.onEmailSelected(this.selectedContact);
        }

        this.enviarToken().then(sucesso => (this.verificationStarted = sucesso));
      } else {
        this.verificationStarted = true;
      }
    },
    async handleValid({ auth }) {
      try {
        this.$root.$emit("show-loading");
        await this.createChangeRequest({ auth: this.autenticacao || auth });
        this.modalInfos.protocol = this.changeRequest.Protocolo;
        this.showSuccessModal = true;
      } catch (error) {
        this.$toast({
          description: error.message,
          status: "error",
          duration: 10000,
          position: "bottom-right"
        });
      } finally {
        this.$root.$emit("hide-loading");
      }
    },
    closeModal() {
      this.showSuccessModal = false;
      this.SET_RATING_INFOS({ ratingRoute: "/meus-contatos", ratingType: 6 });
      this.RESET_NEW_CONTACTS();

      this.$router.replace({
        path: `/experience-rating/${this.changeRequest.Protocolo}/6/S`
      });
    },
    async handleBiometryCameraSuccess(payload) {
      try {
        const { encrypted } = payload;
        await this.$root.$emit("show-loading");
        const data = await this.authFace({ service: "/formas-contato", selfie: encrypted });
        if (data?.MensagemErro) {
          this.handleError(data.MensagemErro);
          return;
        }
        if (data?.Autorizado) {
          this.handleValid({ auth: data.IdAutorizacao });
          return;
        }
        this.$toast("Não foi possível realizar a autenticação");
      } catch (error) {
        console.error(error);
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    handleError(error) {
      this.$toast({
        description: error || "Ocorreu um erro ao iniciar a câmera",
        status: "error",
        duration: 10000,
        position: "top"
      });
    }
  },
  computed: {
    ...mapState({
      emails: state =>
        [state.myContacts.resumeContacts?.Emails?.Pessoal, state.myContacts.resumeContacts?.Emails?.Fatura].filter(
          value => Boolean(value)
        ),
      hasChanges: state => {
        return !!state.myContacts.newAddress || !!state.myContacts.newEmails || !!state.myContacts.newPhones;
      },
      changeRequest: state => state.myContacts.changeRequest
    }),
    canVerify() {
      return !!this.selectMethod && !!this.selectedContact;
    }
  }
};
</script>
