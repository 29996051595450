import IndicacaoDeCredenciados from "@/pages/indicacao-de-credenciados/index.vue";
import IndicacaoDeCredenciadosVisualize from "@/pages/indicacao-de-credenciados/visualize.vue";
import IndicacaoDeCredenciadosForm from "@/pages/indicacao-de-credenciados/form.vue";
import IndicacaoDeCredenciadosMenu from "@/pages/indicacao-de-credenciados/menu.vue";
import IndicacaoDeCredenciadosVisualizeResults from "@/pages/indicacao-de-credenciados/visualize-results.vue";
import IndicacaoDeCredenciadosVisualizeAccredited from "@/pages/indicacao-de-credenciados/visualize-accredited.vue";

const routes: Route[] = [
  {
    path: "/indicacao-de-credenciados",
    component: IndicacaoDeCredenciados,
    children: [
      {
        path: "",
        name: "indication",
        component: IndicacaoDeCredenciadosVisualize,
        meta: {
          registerAccess: {
            servico: "Indicação de Rede Credenciada",
            funcionalidade: "Acesso a indicação de rede credenciada"
          },
          validAction: "affiliate/hasAllowedAffiliateIndication",
          validPath: ["/redecredenciada", "/redecredenciada-home"]
        }
      },
      {
        path: "formulario",
        name: "indication.form",
        component: IndicacaoDeCredenciadosForm,
        meta: {
          checkPathDisabled: true,
          validAction: "affiliate/hasAllowedAffiliateIndication",
          validPath: ["/redecredenciada", "/redecredenciada-home"]
        }
      },
      {
        path: "criar-indicacao",
        component: IndicacaoDeCredenciados,
        meta: {
          checkPathDisabled: true,
          validAction: "affiliate/hasAllowedAffiliateIndication",
          validPath: ["/redecredenciada", "/redecredenciada-home"]
        },
        children: [
          {
            path: "",
            name: "indication.form.create",
            component: IndicacaoDeCredenciadosMenu,
            meta: {
              checkPathDisabled: true,
              validAction: "affiliate/hasAllowedAffiliateIndication",
              validPath: ["/redecredenciada", "/redecredenciada-home"]
            }
          },
          {
            path: "resultado/:chamado",
            name: "indication.details",
            component: IndicacaoDeCredenciadosVisualizeResults,
            meta: {
              checkPathDisabled: true,
              validAction: "affiliate/hasAllowedAffiliateIndication",
              validPath: ["/redecredenciada", "/redecredenciada-home"]
            }
          },
          {
            path: "credenciados",
            name: "indication.accredited",
            component: IndicacaoDeCredenciadosVisualizeAccredited,
            meta: {
              checkPathDisabled: true,
              validAction: "affiliate/hasAllowedAffiliateIndication",
              validPath: ["/redecredenciada", "/redecredenciada-home"]
            }
          }
        ]
      }
    ]
  }
];

export default routes;
