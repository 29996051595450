<template>
  <OmtGrid>
    <OmtRow mb="24px">
      <OmtTypography as="h1-bold"> Telefone </OmtTypography>
    </OmtRow>

    <OmtRow mb="24px">
      <OmtTypography as="h3-semi-bold">
        Para contato e envio de comunicações e atualizações sobre seu plano.
      </OmtTypography>
    </OmtRow>

    <OmtRow>
      <OmtCard box-shadow="sm" border-color="transparent">
        <OmtIcons
          mb="16px"
          box-shadow="sm"
          name="phone"
          border-radius="50%"
          height="32px"
          width="32px"
          display="flex"
          justify-content="center"
          align-items="center"
          size="lg"
        />

        <OmtCard v-for="(tel, index) in formTels" :key="index" box-shadow="sm" border-color="transparent" mb="16px">
          <Options
            :haslabel="true"
            placeholder="Selecione tipo de telefone"
            label="Tipo de Telefone"
            :options="[
              { value: 'Celular', label: 'Celular' },
              { value: 'Comercial', label: 'Comercial' },
              { value: 'Residencial', label: 'Residencial' }
            ]"
            :trackedValue="tel.Tipo"
            :preSelected="tel.Tipo"
            :placeholderDisabled="false"
            :onSelection="telType => (tel.Tipo = telType)"
          />

          <div v-if="tel.Tipo" v-chakra mt="2">
            <CFlex align="center">
              <omt-input v-chakra w="100%" v-model="tel.Numero" placeholder="(11) 9999-9999" v-mask="getMask(tel)" />
              <CBox pos="relative" pl="2" top="8px" cursor="pointer" @click="tel.Tipo = tel.Numero = ''">
                <OmtIcons name="trash-can" size="lg" />
              </CBox>
            </CFlex>
          </div>
        </OmtCard>

        <omt-button :style="{ width: '100%' }" :onClick="submit" :colorInverse="true" :disabled="!canSubmit">
          Continuar
        </omt-button>
      </OmtCard>
    </OmtRow>
  </OmtGrid>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Options from "@/components/Molecules/Options";
import { maskTel8Digit, maskTel9Digit } from "@/utils/utils";

export default {
  components: {
    Options
  },
  data() {
    return {
      formTels: [],
      showButton: true,
      maskPattern: maskTel8Digit
    };
  },
  async mounted() {
    this.initTelefones(this.tels);
  },
  computed: {
    ...mapState({
      tels: state => state.myContacts.newPhones || state.myContacts.resumeContacts.Telefones,
      existTelChangeRequest: state => state.myContacts.existTelChangeRequest
    }),
    canSubmit() {
      const hasInvalidChanges = this.formTels?.filter(tel => {
        if (!tel.Tipo && !tel.Numero.length) return false;

        if (tel.Tipo === "Residencial" || tel.Tipo === "Comercial") {
          return tel.Numero.length < 14;
        } else if (tel.Tipo === "Celular") {
          return tel.Numero.length < 15;
        } else {
          return true;
        }
      });

      return hasInvalidChanges.length === 0;
    }
  },
  watch: {
    tels(tels) {
      this.initTelefones(tels);
    }
  },
  methods: {
    ...mapMutations("myContacts", ["SET_NEW_STATE"]),
    initTelefones(tels) {
      let newTels = tels
        ? tels.map(t => ({
            Tipo: t.Tipo,
            Numero: t.Numero,
            countryCode: t.countryCode
          }))
        : [];
      for (let i = newTels.length; i < 3; i++)
        newTels.push({
          Tipo: "",
          Numero: "",
          countryCode: "+55"
        });
      this.formTels = newTels;
    },
    async submit() {
      this.$router.push({
        path: "/meus-contatos"
      });

      const tels = this.formTels
        .filter(t => !!t.Numero && !!t.Tipo)
        .map(t => ({
          Tipo: t.Tipo,
          Numero: t.Numero,
          countryCode: t.countryCode
        }));

      this.SET_NEW_STATE({ key: "newPhones", value: tels });
    },
    getMask(tel) {
      if (tel.Tipo === "Comercial" || tel.Tipo === "Residencial") {
        if (tel.Numero.length > 14) {
          return maskTel9Digit;
        } else {
          return maskTel8Digit;
        }
      } else {
        return maskTel9Digit;
      }
    }
  }
};
</script>
