<template>
  <div class="card" v-on="$listeners">
    <CFlex>
      <div class="card-icon">
        <FontAwesomeIcon
          icon="fa-light fa-square-poll-horizontal"
          size="lg"
          color="var(--colors-light-primary-gray1)"
        />
      </div>
      <div class="card-body">
        <p class="card-protocol">Protocolo {{ protocol }}</p>

        <p class="card-createdAt">Solicitada em {{ createdAt }}</p>

        <p class="card-name">
          {{ name }}
        </p>

        <hr class="card-divider" />

        <div class="card-status">
          <div class="card-status-dot" :style="{ background: getColor }" />
          <p class="card-status-label">
            {{ dsStatus }}
          </p>
        </div>
      </div>
    </CFlex>
    <CFlex v-if="canEvaluate" justify-content="center" mt="15px">
      <OmtButton
        leftIcon="thumbs-up"
        size="md"
        iconSize="1x"
        border=".5px solid var(--colors-light-secondary-divider)"
        :variant="'outline'"
        :iconColor="'var(--colors-light-primary-gray1)'"
        w="158px !important"
        pl="15px"
        pr="15px"
        @click.stop="$emit('evaluate', protocol)"
      >
        <OmtTypography as="button-small" :color="'var(--colors-light-primary-gray1)'"> Avaliar serviço </OmtTypography>
      </OmtButton>
    </CFlex>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  props: {
    protocol: String,
    createdAt: String,
    name: String,
    tpStatus: String,
    dsStatus: String,
    canEvaluate: Boolean
  },
  components: {
    FontAwesomeIcon
  },
  computed: {
    getColor() {
      const colors = {
        A: "var(--colors-light-primary-navy-blue)",
        C: "var(--colors-light-support-error)",
        D: "var(--colors-light-support-error)",
        L: "var(--colors-light-support-success)",
        P: "var(--colors-light-support-warning)",
        R: "var(--colors-light-support-error)",
        T: "var(--colors-light-primary-gray2)"
      };

      return this.tpStatus in colors ? colors[this.tpStatus] : "var(--colors-light-primary-gray2)";
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  padding: var(--space-4);
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #dadada;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &-body {
    flex: 1;
  }

  &-icon {
    width: 36px;
  }

  &-status {
    display: flex;
    align-items: center;

    &-dot {
      background: var(--colors-light-support-success);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }

  &-protocol {
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    color: var(--colors-light-primary-gray1);
    margin-bottom: 12px;
  }

  &-createdAt {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: var(--colors-light-primary-gray2);
    margin-bottom: 16px;
  }

  &-name,
  &-status-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--colors-light-primary-gray1);
  }

  &-divider {
    border: 0;
    border-bottom: 0.0625rem solid;
    width: auto;
    margin-top: 16px;
    margin-bottom: 16px;
    opacity: 0.3;
    border-color: var(--colors-light-secondary-divider);
    border-style: solid;
    border-width: 0.1px;
  }
}
</style>
