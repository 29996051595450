<template>
  <omint-page>
    <Header name="Primeiro Acesso" titleLabel="Primeiro Acesso" />

    <div class="dots-wrapper">
      <CFlex>
        <CBox
          v-for="id in curr + 1"
          :key="id"
          width="20%"
          height="4px"
          backgroundColor="var(--colors-light-primary-navy-blue)"
        />
      </CFlex>
    </div>
    <omint-slider>
      <router-view />
    </omint-slider>
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";

export default {
  components: { Header },
  name: "FirstAccess",
  data: function () {
    return {
      curr: 0
    };
  },
  mounted() {
    this.$root.$emit("hide-loading");
  },
  watch: {
    $route: function (router) {
      const { name } = router;
      switch (name) {
        case "first-access.terms":
          this.curr = 0;
          break;
        case "first-access.bond":
          this.curr = 1;
          break;
        case "first-access.mailcheck":
        case "first-access.mailform":
          this.curr = 2;
          break;
        case "first-access.code":
          this.curr = 3;
          break;
        case "first-access.password":
          this.curr = 4;
          break;
        default:
          this.curr = 0;
      }
    }
  }
};
</script>

<style scoped>
.dots-wrapper {
  width: 100%;
  background: #d9d9d9;
}
</style>
