<template>
  <CBox>
    <CBox v-if="isReady">
      <OmtGrid v-if="showBiometryAuth">
        <OmtRow mb="24px">
          <OmtIcons name="face-viewfinder" size="3x" color="var(--colors-light-primary-navy-blue)" />
        </OmtRow>

        <OmtRow mb="24px">
          <OmtTypography as="h1-bold"> Validação de biometria </OmtTypography>
        </OmtRow>

        <OmtRow mb="24px">
          <BiometryAuthCard />
        </OmtRow>

        <OmtRow mb="32px">
          <BiometryCameraButton
            @success="handleBiometryCameraSuccess"
            @error="handleBiometryCameraError"
            @resourceError="handleResourceError"
            @startLoading="$root.$emit('show-loading')"
            @endLoading="$root.$emit('hide-loading')"
            buttonLabel="Continuar"
            :confirmUser="true"
          />
        </OmtRow>
      </OmtGrid>

      <TokenAuth v-else-if="showTokenAuth" @success="handleTokenSuccess" />

      <router-view v-else />
    </CBox>
  </CBox>
</template>

<script>
/**
 * How to use:
 *
 * {
 *   path: "/route-you-want-to-protect/service",
 *   component: AuthMiddleware,
 *   children: [
 *     {
 *       path: "",
 *       name: "route-you-want-to-protect.service",
 *       component: ServiceRoute,
 *       meta: {
 *         auth_service: "/service/feature",
 *         save_auth_mutation: "serviceVuexModule/MutationToSaveAuthorizationID"
 *       }
 *     }
 *   ]
 * }
 */
import BiometryCameraButton from "@/components/Organisms/Biometry/BiometryCameraButton";
import BiometryAuthCard from "@/components/Organisms/Biometry/BiometryAuthCard.vue";
import TokenAuth from "@/components/Organisms/TokenAuth";
import { mapActions } from "vuex";

export default {
  components: {
    BiometryCameraButton,
    BiometryAuthCard,
    TokenAuth
  },
  data() {
    return {
      authenticated: false,
      requireAuth: true,
      isReady: false,
      authType: ""
    };
  },
  beforeRouteEnter(to, _, next) {
    if (!to.meta?.auth_service) {
      next(false);
      throw new Error(`auth_service meta field not found on route with name ${to.name}`);
    } else if (!to.meta?.save_auth_mutation) {
      next(false);
      throw new Error(`save_auth_mutation meta field not found on route with name ${to.name}`);
    } else {
      next();
    }
  },
  async mounted() {
    try {
      await this.$root.$emit("show-loading");

      if ("requireAuth" in this.$route.params) this.requireAuth = this.$route.params.requireAuth;

      if (!this.requireAuth) {
        if (this.$route.meta?.save_auth_mutation) {
          this.$store.commit(this.$route.meta?.save_auth_mutation, "");
        }
        this.authenticated = true;
        return;
      }
      const authType = await this.fetchAuthType({
        service: this.$route.meta?.auth_service,
        type: this.$route.meta?.auth_get_type,
        rules: this.$route.params.rules || []
      });
      this.authType = authType.Autorizador;
      if (!this.authType) {
        this.authenticated = true;
      }
      if (authType.Id && authType.Id > 0) {
        if (this.$route.meta?.save_auth_mutation) {
          this.$store.commit(this.$route.meta?.save_auth_mutation, authType.Id);
        }
        this.authenticated = true;
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      await this.$root.$emit("hide-loading");
      this.isReady = true;
    }
  },
  methods: {
    ...mapActions("biometrics", ["authFace"]),
    ...mapActions("ticket", ["fetchAuthType"]),
    async handleBiometryCameraSuccess(payload) {
      const { encrypted } = payload;

      try {
        await this.$root.$emit("show-loading");
        const { CadastroPendente, Autorizado, IdAutorizacao, MensagemErro } = await this.authFace({
          selfie: encrypted,
          service: this.$route.meta?.auth_service
        });

        if (MensagemErro) {
          this.showErrorMessage(MensagemErro);
          return;
        }

        if (CadastroPendente) {
          this.$toast({
            description: "O cadastro da sua biometria ainda está pendente",
            status: "info",
            duration: 10000,
            position: "top"
          });

          this.$router.push({
            path: "/"
          });

          return;
        }

        if (Autorizado && !!IdAutorizacao) {
          if (this.$route.meta?.save_auth_mutation) {
            this.$store.commit(this.$route.meta?.save_auth_mutation, IdAutorizacao);
          }
          this.authenticated = true;
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    handleError(error) {
      window.$log.error("biometry page", error);
      this.showErrorMessage("Ocorreu um erro ao obter a biometria");
    },
    showErrorMessage(message) {
      this.$toast({
        description: message ?? "Ocorreu um erro ao realizar a autenticação",
        status: "error",
        duration: 10000,
        position: "top"
      });
    },
    handleResourceError(error) {
      this.$toast({
        description: "Ocorreu um erro ao iniciar a câmera",
        status: "error",
        duration: 10000,
        position: "top"
      });
      window.$log.error("biometry init", error);
    },
    handleBiometryCameraError(error) {
      window.$log.error("biometry camera", error);
    },
    handleTokenSuccess(payload) {
      if (payload) this.authenticated = true;
    }
  },
  computed: {
    showBiometryAuth() {
      return !!this.requireAuth && !this.authenticated && this.authType === "BIO-UNICO";
    },
    showTokenAuth() {
      return !!this.requireAuth && !this.authenticated && this.authType === "CODIGO";
    }
  }
};
</script>
