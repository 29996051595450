import { render, staticRenderFns } from "./RatingForm.vue?vue&type=template&id=4ce7e6c5&scoped=true&"
import script from "./RatingForm.vue?vue&type=script&lang=js&"
export * from "./RatingForm.vue?vue&type=script&lang=js&"
import style0 from "./RatingForm.vue?vue&type=style&index=0&id=4ce7e6c5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ce7e6c5",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CFlex: require('@chakra-ui/vue').CFlex})
