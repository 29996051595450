<template>
  <omint-page>
    <Header name="Documento" :titleLabel="doc.name" />
    <omint-page-content>
      <omt-highlight v-if="loading">Carregando {{ doc.name }}. Por favor, aguarde.</omt-highlight>
      <iframe
        id="iframepdf"
        :src="doc.url"
        v-on:load="fileLoaded"
        frameborder="0"
        allowfullscreen
        :width="width"
        :height="height"
      ></iframe>
    </omint-page-content>
    <Footer />
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";
import { mapState } from "vuex";

export default {
  components: { Header, Footer },
  name: "DisplayFile",
  data() {
    return {
      loading: true,
      width: 0,
      height: 0
    };
  },
  computed: {
    ...mapState("myFiles", ["doc"])
  },
  methods: {
    fileLoaded() {
      this.loading = false;
      this.$root.$emit("hide-loading");
    },
    onResizeHandler() {
      const bordas = 25 * 2;
      const headerFooter = bordas + 55 * 2;
      const maxWidth = 970;
      const width = window.innerWidth - bordas;
      this.width = width < maxWidth ? width : maxWidth;
      this.height = window.innerHeight - headerFooter;
    }
  },
  created() {
    window.addEventListener("resize", this.onResizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResizeHandler);
  },
  mounted() {
    if (!this.doc || !this.doc.name || !this.doc.url) {
      this.$router.back();
    }
    this.onResizeHandler();
  }
};
</script>
