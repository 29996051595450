<template>
  <OmtInput
    variant="outline"
    v-model="token"
    placeholder="Insira aqui seu token"
    type="number"
    :min-w="enableMinWidth ? ['auto', '375px'] : ''"
  />
</template>

<script>
export default {
  props: {
    value: String,
    enableMinWidth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    token: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  }
};
</script>

<style scoped>
::v-deep input {
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  letter-spacing: 2px;
}

::v-deep input::placeholder {
  color: var(--colors-light-secondary-divider);
}

@media (max-width: 992px) {
  ::v-deep input::placeholder {
    font-size: 20px;
  }
}
</style>
