<template>
  <CBox :bg="['none', 'var(--colors-light-primary-light-gray)']" minHeight="100vh" width="100vw" position="sticky">
    <OmtLayoutContainer>
      <OmtRow mb="32px">
        <CFlex direction="column">
          <OmtTypography as="h1-bold"> {{ descricaoEspecialidade }} </OmtTypography>
          <OmtTypography v-if="descricaoSubEspecialidade" as="p1"> {{ descricaoSubEspecialidade }} </OmtTypography>
        </CFlex>
      </OmtRow>
      <OmtRow mb="24px" flex-wrap="wrap">
        <OmtButton
          mr="16px"
          size="sm"
          :variant="filtersCount > 0 ? 'solid' : 'outline'"
          leftIcon="sliders-h"
          :iconColor="filtersCount > 0 ? 'white' : 'var(--colors-light-primary-gray1)'"
          iconSize="sm"
          border=".5px solid var(--colors-light-secondary-divider)"
          @click="showFilterModal = true"
          :w="`${filtersCount > 0 ? 125 : 105}px !important`"
        >
          <OmtTypography as="button-small" :color="filtersCount > 0 ? 'white' : 'var(--colors-light-primary-gray1)'">
            Filtrar
          </OmtTypography>
          <CBox
            ml="16px"
            v-show="filtersCount"
            bg="var(--colors-light-secondary-light-blue)"
            border-radius="12px"
            min-h="16px"
            min-w="16px"
            h="16px"
            w="16px"
            display="flex"
            justify-content="center"
            align-items="center"
          >
            <OmtTypography as="p2" color="white">
              {{ filtersCount }}
            </OmtTypography>
          </CBox>
        </OmtButton>

        <OmtButton
          leftIcon="arrow-up-arrow-down"
          size="sm"
          iconSize="sm"
          border=".5px solid var(--colors-light-secondary-divider)"
          :variant="!!sort ? 'solid' : 'outline'"
          :iconColor="!!sort ? 'white' : 'var(--colors-light-primary-gray1)'"
          w="115px !important"
          @click="showSortModal = true"
        >
          <OmtTypography as="button-small" :color="!!sort ? 'white' : 'var(--colors-light-primary-gray1)'">
            Ordenar
          </OmtTypography>
        </OmtButton>

        <OmtButton
          variant="ghost"
          :disabled="!filtersCount && !sort"
          size="sm"
          w="105px !important"
          @click="cleanSortsAndFilters"
        >
          Limpar
        </OmtButton>
      </OmtRow>

      <OmtRow :mb="['16px', '32px']">
        <OmtTypography as="h2-bold"> Localize por nome </OmtTypography>
      </OmtRow>

      <OmtRow mb="24px">
        <OmtInput
          variant="filled"
          placeholder="Digite o nome do profissional ou clínica"
          icon-right="search"
          v-model="textSearch"
        />
      </OmtRow>

      <OmtRow v-for="(item, index) in list" :key="index">
        <DoctorsListItem
          @click="onClick(item)"
          :TipoLogradouro="item.TipoLogradouro"
          :EnderecoRua="item.EnderecoRua"
          :EnderecoNumero="item.EnderecoNumero"
          :EnderecoBairro="item.EnderecoBairro"
          :CodigoEstado="item.CodigoEstado"
          :EnderecoCidade="item.EnderecoCidade"
          :DescricaoPrestador="item.DescricaoPrestador"
          :DescricaoEspecialidade="item.DescricaoEspecialidade"
          :Distancia="item.Distancia"
          :Network="item.NetworkName"
          :Favorito="item.Favorito"
          :CodigoPrestador="item.CodigoPrestador"
          :SrEndereco="item.SrEndereco"
          :DsIdioma="item.DsIdioma"
          :Nota="item.ScoreTotal"
          :WhatsApp="item.WhatsApp"
          :Teleconsulta="item.Teleconsulta"
          :Agendamento="item.Agendamento"
        />
      </OmtRow>
    </OmtLayoutContainer>

    <FilterModal
      v-if="showFilterModal"
      @close="showFilterModal = false"
      @save="onFilterSave"
      :filters="filters"
      :facilities="facilities"
      :languages="languages"
      :networks="networks"
      :subspecialties="subspecialties"
    />
    <SortModal v-if="showSortModal" @close="showSortModal = false" @save="onSortSave" :sort="sort" />
  </CBox>
</template>

<script>
import DoctorsListItem from "@/components/Organisms/AffiliateSearch/DoctorsListItem.vue";
import FilterModal from "@/components/Organisms/AffiliateSearch/FilterModal.vue";
import SortModal from "@/components/Organisms/AffiliateSearch/SortModal.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import _ from "lodash";

export default {
  name: "DoctorsList",
  components: { FilterModal, SortModal, DoctorsListItem },
  async mounted() {
    if (!this.locationType) {
      await this.$router.replace({ name: "affiliate" });
      return;
    }
    try {
      await this.$root.$emit("show-loading");
      this.SET_MATCH({ CodigoPrestador: null });
      if (this.prevRoute === "/redecredenciada/regiao") {
        switch (this.locationType) {
          case "Perto da minha casa":
            await this.fetchMatchesByProximity("R");
            break;
          case "Perto do meu trabalho":
            await this.fetchMatchesByProximity("C");
            break;
          case "Busca por região":
            await this.fetchMatches();
            break;
          case "Busca por texto":
          case "Favoritos":
          case "Últimas visualizações":
          case "Credenciados substitutos":
            break;
          default:
            await this.fetchMatchesNear();
        }
      }
    } catch (e) {
      await this.$root.$emit("hide-loading");

      await Swal.fire({
        text: e.message ? e.message : "Nenhum resultado encontrato",
        confirmButtonText: "OK",
        icon: "warning"
      });
      await this.$router.replace({ name: "affiliate" });
      await this.$root.$emit("show-loading");
    } finally {
      switch (this.locationType) {
        case "Favoritos":
          this.originalList = this.favorites;
          break;
        case "Últimas visualizações":
          this.originalList = this.latestViews;
          break;
        default:
          this.originalList = this.matches;
      }

      this.originalList = this.originalList.map(item => ({
        NetworkName: this.getNetworkName(item.TpRede),
        ...item
      }));

      this.getFacilities();
      this.getLanguages();
      this.getNetworkList();
      this.getSubspecialtyList();
      this.filterList();
      this.onSortSave("");

      await this.$root.$emit("hide-loading");
    }
    this.$root.$emit("change-title-label", this.group ? this.capitalizeWords(this.group) : "Busca de Rede");
  },
  data() {
    return {
      prevRoute: "/redecredenciada/regiao",
      textSearch: "",
      originalList: [],
      list: [],
      showFilterModal: false,
      showSortModal: false,
      facilities: [],
      languages: [],
      networks: [],
      subspecialties: []
    };
  },
  beforeRouteEnter(_, from, next) {
    next(vm => {
      vm.prevRoute = from.path;
    });
  },
  computed: {
    ...mapState("saude", ["hydrated"]),
    ...mapState("affiliate", [
      "deactivated",
      "group",
      "rede",
      "descricaoEspecialidade",
      "descricaoSubEspecialidade",
      "matches",
      "locationType",
      "favorites",
      "latestViews",
      "filters",
      "sort"
    ]),
    filtersCount() {
      const activeFacilities = this.filters?.facilities?.filter(fac => !!fac.isActive) || [];
      const activeLanguages = this.filters?.languages?.filter(lang => !!lang.isActive) || [];
      const activeNetworks = this.filters?.networks?.filter(net => !!net.isActive) || [];
      const activeSubspecilties = this.filters?.subspecialties?.filter(esp => !!esp.isActive) || [];

      return activeFacilities.length + activeLanguages.length + activeNetworks.length + activeSubspecilties.length;
    }
  },
  watch: {
    textSearch: {
      handler: _.debounce(function () {
        this.filterList();
      }, 500)
    }
  },
  methods: {
    ...mapActions("affiliate", ["fetchMatches", "fetchMatchesByProximity", "fetchMatchesNear"]),
    ...mapMutations("affiliate", ["SET_MATCH", "SET_FILTERS", "SET_SORT"]),
    normalize(value) {
      return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    async onClick(match) {
      await this.$root.$emit("show-loading");
      await this.SET_MATCH(match);
      const rede = this.rede ? this.rede : "0000";
      await this.$router.push({
        name: "affiliate.docInfo",
        params: {
          situacao: this.deactivated ? "I" : "A",
          rede,
          cdPrestador: match.CodigoPrestador,
          srEndereco: match.SrEndereco
        }
      });
    },
    getNetworkName(tpRede) {
      switch (tpRede) {
        case "P":
          return "Principal";
        case "C":
          return "Complementar";
        case "A":
          return "MediService";
        default:
          return "";
      }
    },
    filterList() {
      if (!this.originalList?.length) {
        this.list = [];
        return;
      }
      const interruptFilter = () => {
        if (!list?.length) {
          this.list = [];
          return true;
        }
      };
      let list = [];
      const filteredLanguages = this.filters.languages?.filter(lang => lang.isActive);
      const filteredSubspecialties = this.filters.subspecialties?.filter(esp => esp.isActive);
      const filteredNetworks = this.filters.networks?.filter(net => net.isActive);

      if (filteredLanguages?.languages && !this.originalList[0].languages) {
        this.originalList.forEach(
          i => (i.languages = (i.DsIdioma ? i.DsIdioma.split(",") : []).map(l => this.normalize(l).trim()))
        );
      }

      if (this.textSearch === "") {
        list = this.originalList;
      } else {
        if (!this.originalList[0].matchText) {
          this.originalList.forEach(i => (i.matchText = this.normalize(JSON.stringify(i)).toUpperCase()));
        }
        const textSearch = this.normalize(this.textSearch.toUpperCase());
        list = this.originalList.filter(i => i.matchText.indexOf(textSearch) !== -1);
        if (interruptFilter()) return;
      }
      if (filteredLanguages?.length) {
        const languages = filteredLanguages.map(lang => lang.normalizedLabel);
        list = list.filter(i => i.languages.find(langItem => languages.find(langFilter => langFilter === langItem)));
        if (interruptFilter()) return;
      }
      if (filteredSubspecialties?.length) {
        const specialties = filteredSubspecialties.map(esp => esp.normalizedLabel);
        list = list.filter(i => i.subspecialties.find(item => specialties.find(filter => filter === item)));
        if (interruptFilter()) return;
      }
      if (filteredNetworks?.length) {
        const networks = filteredNetworks.map(net => net.normalizedLabel);
        list = list.filter(i => networks.find(filter => filter === i.NetworkName));
        if (interruptFilter()) return;
      }
      if (this.filters.minimumRate) {
        const minimumRate = Number(this.filters.minimumRate);
        list = list.filter(i => i.ScoreTotal >= minimumRate);
        if (interruptFilter()) return;
      }
      if (this.filters.facilities?.length) {
        if (this.filters.facilities.find(f => f.name === "online")?.isActive) {
          list = list.filter(i => !!i.Agendamento);
          if (interruptFilter()) return;
        }
        if (this.filters.facilities.find(f => f.name === "videoCall")?.isActive) {
          list = list.filter(i => !!i.Teleconsulta);
          if (interruptFilter()) return;
        }
        if (this.filters.facilities.find(f => f.name === "whatsapp")?.isActive) {
          list = list.filter(i => !!i.WhatsApp);
          if (interruptFilter()) return;
        }
      }
      this.list = list;
    },
    onFilterSave(filters) {
      this.SET_FILTERS(filters);
      this.filterList();
    },
    onSortSave(sort) {
      this.SET_SORT(sort);

      if (sort === "" || sort === "Indicação Omint") {
        this.list = this.list.sort((a, b) => {
          let base = 0;
          if (a.ScoreTotal < b.ScoreTotal) base = 100;
          if (a.ScoreTotal > b.ScoreTotal) base = -100;
          if (a.Distancia === undefined && b.Distancia === undefined) return base + 3;
          if (a.Distancia === undefined && b.Distancia !== undefined) return base + 2;
          if (a.Distancia !== undefined && b.Distancia === undefined) return base - 2;
          if (a.Distancia < b.Distancia) return base - 1;
          if (a.Distancia > b.Distancia) return base + 1;
          return base;
        });
      } else {
        this.list = this.list.sort((a, b) => {
          if (a.Distancia === undefined && b.Distancia === undefined) return 3;
          if (a.Distancia === undefined && b.Distancia !== undefined) return 2;
          if (a.Distancia !== undefined && b.Distancia === undefined) return -2;
          if (a.Distancia < b.Distancia) return -1;
          if (a.Distancia > b.Distancia) return 1;
          return 0;
        });
      }
      if (sort === "Rede e Distância") {
        const listPrincipal = this.list.filter(s => s.TpRede === "P" || s.TpRede === null);
        const listComplementar = this.list.filter(s => s.TpRede === "C");
        const listMediService = this.list.filter(s => s.TpRede === "A");
        this.list = [...listPrincipal, ...listComplementar, ...listMediService];
      }
    },
    capitalizeWords(inputString) {
      return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
    },
    cleanSortsAndFilters() {
      this.onFilterSave({});
      this.onSortSave("");
    },
    getFacilities() {
      const facilities = [];
      if (this.originalList.find(i => !!i.Agendamento)) facilities.push({ name: "online" });
      if (this.originalList.find(i => !!i.Teleconsulta)) facilities.push({ name: "videoCall" });
      if (this.originalList.find(i => !!i.WhatsApp)) facilities.push({ name: "whatsapp" });
      this.facilities = facilities;
    },
    getLanguages() {
      const languages = [];
      this.originalList.forEach(item => {
        const langs = item.DsIdioma ? item.DsIdioma.split(",") : ["Português"];
        item.languages = langs.map(lang => {
          const normalizeLabel = this.normalize(lang).trim();
          if (!languages.find(l => l.normalizedLabel === normalizeLabel)) {
            languages.push({
              label: lang,
              normalizedLabel: normalizeLabel
            });
          }
          return normalizeLabel;
        });
      });

      languages.sort(function (a, b) {
        if (a.normalizedLabel > b.normalizedLabel) {
          return 1;
        }
        if (a.normalizedLabel < b.normalizedLabel) {
          return -1;
        }
        return 0;
      });

      this.languages = languages;
    },
    getNetworkList() {
      const networks = [];
      this.originalList.forEach(item => {
        const normalizeLabel = this.normalize(item.NetworkName).trim();
        if (!networks.find(e => e.normalizedLabel === normalizeLabel)) {
          networks.push({
            label: item.NetworkName,
            normalizedLabel: normalizeLabel
          });
        }
      });

      networks.sort((a, b) => {
        if (a.normalizedLabel > b.normalizedLabel) {
          return 1;
        }
        if (a.normalizedLabel < b.normalizedLabel) {
          return -1;
        }
        return 0;
      });

      this.networks = networks;
    },
    getSubspecialtyList() {
      const subspecialties = [];
      this.originalList.forEach(item => {
        const esp = item.SubEspecialidades ? item.SubEspecialidades : [];
        item.subspecialties = esp.map(esp => {
          const normalizeLabel = this.normalize(esp).trim();
          if (!subspecialties.find(e => e.normalizedLabel === normalizeLabel)) {
            subspecialties.push({
              label: esp,
              normalizedLabel: normalizeLabel
            });
          }
          return normalizeLabel;
        });
      });

      subspecialties.sort((a, b) => {
        if (a.normalizedLabel > b.normalizedLabel) {
          return 1;
        }
        if (a.normalizedLabel < b.normalizedLabel) {
          return -1;
        }
        return 0;
      });

      this.subspecialties = subspecialties;
    }
  }
};
</script>
