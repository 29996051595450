<template>
  <OmtButton align-items="center" position="relative" v-bind="$attrs" v-on="$listeners">
    <CFlex
      v-show="!!count"
      position="absolute"
      left="18px"
      top="18px"
      justify-content="center"
      align-items="center"
      border-radius="50%"
      bg="white"
      h="22px"
      w="22px"
      p="4px"
    >
      <OmtTypography as="h2-bold" color="var(--colors-light-primary-navy-blue)">
        {{ count }}
      </OmtTypography>
    </CFlex>

    <span>{{ label }}</span>
  </OmtButton>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: "Click"
    }
  }
};
</script>
