import ExtratoServicosAssistenciais from "@/pages/extrato-servicos-assistenciais/index.vue";
import ExtratoServicosAssistenciaisHome from "@/pages/extrato-servicos-assistenciais/home.vue";

const routes: Route[] = [
  {
    path: "/services-extract",
    alias: "/extrato-servicos-assistenciais",
    component: ExtratoServicosAssistenciais,
    params: true,
    children: [
      {
        path: "",
        name: "extrato",
        component: ExtratoServicosAssistenciaisHome,
        meta: {
          backTo: "/",
          validPath: ["/services-extract", "/extrato-servicos-assistenciais"]
        }
      }
    ]
  }
];

export default routes;
