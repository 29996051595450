<template>
  <div :class="`${myClass}`">
    <div class="form-group">
      <select :class="`${haslabel ? 'bg-light' : ''}`" :id="id" :name="name" v-model="selected" :disabled="disabled">
        <option
          v-if="placeholder && placeholder !== ''"
          value=""
          :disabled="placeholderDisabled === false ? false : true"
          selected
        >
          {{ placeholder }}
        </option>
        <option v-for="(option, idx) in options" v-bind:value="option.value" :key="option.value + '_ ' + idx">
          {{ option.label }}
        </option>
      </select>
      <label v-if="haslabel"> {{ label ? label : placeholder }} </label>
      <em v-if="!disabled"><i class="fas fa-chevron-down fa-1x"></i></em>
      <div class="loader" :style="loaderStyle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Options",
  props: [
    "solid",
    "options",
    "isLoading",
    "onSelection",
    "id",
    "name",
    "placeholder",
    "placeholderDisabled",
    "label",
    "preSelected",
    "trackedValue",
    "solid",
    "disabled",
    "haslabel",
    "onBlur"
  ],
  computed: {
    myClass() {
      return `option 
        ${this.haslabel ? " float-label" : ""}
        ${this.solid ? " solid" : ""}
        ${this.isLoading ? " loading" : ""}
      `;
    },
    loaderStyle() {
      return `background: url('${process.env.VUE_APP_BASE_URL + "images/loading.svg"}') no-repeat center center;`;
    }
  },
  data: function () {
    return {
      selected: null
    };
  },
  mounted() {
    this.autoSelect();
  },
  updated() {
    this.autoSelect();
  },
  watch: {
    selected(val, oldVal) {
      if (val !== oldVal && val !== "") {
        this.onSelection(val);
      } else {
        if (this.placeholderDisabled === false) {
          this.onSelection(val);
        }
      }
    },
    trackedValue(val, oldVal) {
      if (val !== oldVal) {
        this.selected = val;
      }
    }
  },
  methods: {
    autoSelect() {
      if (this.selected) {
        const validOption = this.options?.length && !!this.options.find(o => o.value === this.selected);
        if (validOption) return;
        this.selected = null;
      }

      if (
        (this.preSelected === "single" && this.options.length === 1) ||
        (this.preSelected === "first" && this.options.length > 0)
      ) {
        this.selected = this.options[0].value;
        this.onSelection(this.selected);
      } else if (this.preSelected) {
        const validPreSelected = this.options?.length && !!this.options.find(o => o.value === this.preSelected);
        if (validPreSelected) {
          this.selected = this.preSelected;
          this.onSelection(this.preSelected);
        }
      }
      if (this.selected === null && this.placeholder && this.placeholder !== "") {
        this.selected = "";
        if (this.onSelection) this.onSelection("");
      }
    },
    reset() {
      this.selected = null;
    }
  }
};
</script>
<style scoped>
.form-group {
  position: relative;
}

.form-group[readonly] {
  opacity: 0.5;
}

.form-group label {
  left: 0;
  top: 0;
  font-size: 11px;
  position: absolute;
  line-height: 1;
  padding: 6px 8px;
  color: var(--colors-light-primary-gray1);
}

.option {
  display: block;
  position: relative;
  color: var(--colors-light-primary-gray1);
  border: 0;
  box-sizing: border-box;
  margin-bottom: 15px;
  height: 40px;
  line-height: 40px;
  border-radius: 0px;
  width: 100%;
  box-shadow: none;
  background-color: transparent;
  font-size: 12px;
  padding: 0px;
}

.option select {
  color: var(--colors-light-primary-gray1);
  box-sizing: border-box;
  margin-bottom: 15px;
  border: 0;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  border-radius: 0px;
  width: 100%;
  box-shadow: none;
  background-color: transparent;
  font-size: 12px;
  outline: 0;
  border-image: none;
  outline-offset: -2px;
  border-color: transparent;
  outline-color: transparent;
  -webkit-appearance: none;
}

.option select[disabled] {
  cursor: not-allowed;
  opacity: 0.6;
}

.option em {
  position: absolute;
  top: 10px !important;
  font-size: 100%;
  font-style: normal;
  right: 5px !important;
  pointer-events: none;
}

.option em i {
  width: 25px;
  text-align: center;
  margin-top: 5px;
  display: block;
}

.option.solid {
  background: white;
  color: var(--colors-light-primary-gray1);
  border: 1px transparent;
  border-bottom: transparent 1px;
}

.option.solid em {
  color: var(--colors-light-primary-gray1);
}

.option.loading .loader {
  position: absolute;
  right: -20px;
  height: 80px;
  width: 80px;
  transform: scale(0.33);
  top: -20px;
}

.float-label.option select {
  line-height: 1.2;
  height: 48px;
  color: #000 !important;
  padding-top: 1rem;
}

.float-label.option em {
  top: 18px !important;
}

.light .option {
  border-bottom: solid 2px rgba(0, 0, 0, 0.08);
}

.light .option em {
  color: var(--colors-light-primary-gray1);
}

.light .float-label.option select {
  border-bottom: solid 2px rgba(0, 0, 0, 0.08);
}

.big-font-size .option {
  font-size: 18px;
}

.big-font-size .option em {
  top: 0px;
  right: 0px;
}

.big-font-size .option select {
  font-size: 18px;
}

.bg-light {
  background-color: #e8e8e8 !important;
}

.dark .option {
  color: white;
  border-bottom: solid 2px rgba(255, 255, 255, 0.08);
}

.dark .option select {
  color: white;
  background-color: black;
  border-bottom: solid 2px #4a5568;
}

.dark .option.solid select {
  color: var(--colors-light-primary-gray1);
  background-color: white;
  border-bottom: 0px;
}

/* DARK MODE */
.dark .bg-light {
  background-color: #fff !important;
}

.option[aria-invalid="true"] {
  border-bottom: solid 2px #ff5b32 !important;
}
</style>
