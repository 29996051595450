<template>
  <omint-page>
    <Header name="Vinculos" :showLogo="true" backRoute="/" />
    <omint-page-content>
      <CBox>
        <br />
        <OmtTypography as="h1-bold" align="start">Selecione um perfil de acesso: </OmtTypography>
        <br />
        <CBox
          v-for="(t, id) of grupos"
          :key="t.IdPerfilOmint"
          :marginBottom="id == grupos.length - 1 ? '0px' : '32px'"
          cursor="pointer"
        >
          <OmtTypography as="h2-bold" mb="16px"> {{ t.DescricaoPerfilOmint }}</OmtTypography>

          <CFlex
            v-for="(e, i) in t.Opcoes"
            @click="changeSelected(e.value)"
            :key="i"
            mt="16px"
            w="100%"
            alignItems="center"
            :border="selected === e.value ? '0.5px solid #172972' : '0.5px solid #DADADA'"
            borderRadius="8px"
            padding="16px"
          >
            <CBox mr="16px">
              <CFlex
                width="32px"
                height="32px"
                borderRadius="32px"
                backgroundColor="#F6F6F9"
                justifyContent="center"
                alignItems="center"
              >
                <i
                  :class="
                    t.IdPerfilOmint === 1
                      ? 'far fa-user fa-1x'
                      : t.IdPerfilOmint === 2
                      ? 'far fa-building fa-1x'
                      : 'fas fa-user-tie fa-1x'
                  "
                ></i>
              </CFlex>
            </CBox>
            <CBox w="auto">
              <OmtTypography as="h3-semi-bold">
                {{ e.DescricaoIdentificacaoVinculo }}
              </OmtTypography>
              <OmtTypography as="p2" v-if="t.IdPerfilOmint !== 2">
                {{ e.NumeroIdentificacaoVinculo }}
              </OmtTypography>
            </CBox>
          </CFlex>
        </CBox>
      </CBox>
      <CFlex
        :position="['fixed', 'relative']"
        :left="['0', '0']"
        bottom="0"
        marginTop="66px"
        padding="16px"
        width="100%"
        justifyContent="center"
      >
        <OmtButton
          variant="solid"
          width="100%"
          maxWidth="380px"
          height="50px"
          border="0.5px solid var(--colors-light-secondary-divider)"
          borderRadius="40px"
          data-cy="button-entrar"
          :disabled="selected === ''"
          @click="onSelect(selected)"
        >
          <OmtTypography as="h1-bold" color="white">Entrar</OmtTypography>
        </OmtButton>
      </CFlex>
    </omint-page-content>
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";
import Options from "@/components/Molecules/Options";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { mapState, mapActions } from "vuex";
import { ROTA_PADRAO, ROTA_BASE, ROTA_OPTIN, ROTA_VINCULOS } from "@/utils/perfilRouteUtil";

export default {
  name: "Terms",
  components: { Header, Footer, Options },
  data() {
    return {
      grupos: [],
      selected: ""
    };
  },
  async mounted() {
    if (!this.vinculos || this.vinculos.length <= 0) {
      if (this.operacao > 0 && this.perfil > 0 && this.vinculo !== "") {
        const rota = await this.fetchInitRoute();
        this.$router.replace(rota);
      } else {
        this.$router.replace(ROTA_PADRAO);
      }
      return;
    }
    let gruposIniciais = [];
    for (let i = 0; i < this.vinculos.length; i++) {
      let grupo = this.vinculos[i];
      let existe = gruposIniciais.filter(g => g.IdPerfilOmint === grupo.IdPerfilOmint);
      let opcoes = await this.options(grupo.IdOperacaoOmint, grupo.IdPerfilOmint, grupo.Vinculos);
      if (existe && existe.length > 0) {
        existe[0].Opcoes.push(...opcoes);
      } else {
        gruposIniciais.push(grupo);
        grupo.Opcoes = opcoes;
      }
    }
    this.grupos = gruposIniciais;
    await this.$root.$emit("hide-loading");
  },
  computed: {
    ...mapState("saude", ["vinculos", "operacao", "perfil", "vinculo"]),
    hasValidNextUrl() {
      return (
        this.$route.query.nextUrl &&
        this.$route.query.nextUrl !== ROTA_BASE &&
        this.$route.query.nextUrl !== ROTA_PADRAO &&
        this.$route.query.nextUrl !== ROTA_OPTIN &&
        this.$route.query.nextUrl !== ROTA_VINCULOS
      );
    }
  },
  methods: {
    ...mapActions("saude", ["setVinculo", "fetchInitRoute", "testSession"]),
    ...mapActions(["logoutUser"]),
    async options(operacao, perfil, vinculos) {
      const completo = vinculos.map(({ NumeroIdentificacaoVinculo, DescricaoIdentificacaoVinculo }) => ({
        value: `${operacao}|${perfil}|${NumeroIdentificacaoVinculo}`,
        label:
          DescricaoIdentificacaoVinculo.indexOf(NumeroIdentificacaoVinculo) === 0
            ? DescricaoIdentificacaoVinculo
            : `${NumeroIdentificacaoVinculo} ${DescricaoIdentificacaoVinculo}`,
        DescricaoIdentificacaoVinculo: DescricaoIdentificacaoVinculo,
        NumeroIdentificacaoVinculo: NumeroIdentificacaoVinculo
      }));
      const filtrado = [];
      completo.forEach(e => {
        let existe = filtrado.filter(g => g.value === e.value);
        if (!(existe && existe.length > 0)) {
          filtrado.push(e);
        }
      });
      return filtrado;
    },
    changeSelected(value) {
      this.selected = value;
    },
    async onSelect(value) {
      if (value === "") return;
      var partes = value.split("|");
      var operacao = Number.parseInt(partes[0]);
      var perfil = Number.parseInt(partes[1]);
      var vinculo = partes[2];
      await this.$root.$emit("show-loading");
      await this.setVinculo({ operacao, perfil, vinculo });
      const situacao = await this.testSession();
      if (situacao) {
        const route = await this.fetchInitRoute();
        await this.$root.$emit("hide-loading");
        if (route.startsWith("/")) {
          if (this.hasValidNextUrl) {
            if (route === ROTA_OPTIN) {
              await this.$router.replace({
                path: route,
                query: {
                  nextUrl: this.$route.query.nextUrl
                }
              });
            } else {
              await this.$router.replace(this.$route.query.nextUrl);
            }
          } else {
            await this.$router.replace(route);
          }
        } else {
          this.logoutUser();
          window.location = route;
        }
      } else {
        await this.$root.$emit("hide-loading");
        await Swal.fire({
          text: "Funcionalidade não disponível para o perfil",
          confirmButtonText: "OK"
        });
        this.$router.replace("/logout");
        return false;
      }
    }
  }
};
</script>

<style scoped>
.box-groups {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}
.box-profile {
  background-color: #8f9aac;
  border-radius: 5px;
  height: 146px;
  width: 100%;
  color: #fff;
  position: relative;
  margin-bottom: 30px;
  padding-top: 30px;
  text-align: center;
}
.box-profile span {
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2em;
  margin-top: 1em;
}

.vinculo {
  display: block;
  width: 100%;
  color: #012545;
  font-size: 0.65em;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 5px 5px 0px 0px;
  border: 1px solid #012545;
}

@media only screen and (min-width: 992px) {
  /* Desktop */
  .vinculo {
    cursor: pointer;
    width: 300px;
  }
}
</style>
