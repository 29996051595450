<template>
  <omint-page>
    <Header titleLabel="Whatsapp" />
    <transition name="fade">
      <omint-page-content class="container-fone">
        <div v-if="!WhatsAppAtivo && (!TelefonesDisponiveis || TelefonesDisponiveis.length === 0)">
          <h2>Você não possui telefones cadastrados!</h2>
          <h2>1) Cadastre o número do telefone utilizado para acessar o WhatsApp.</h2>
          <h2>2) Aguarde a análise e liberação do novo telefone cadastrado (24 horas).</h2>
          <h2>3) Clique novamente no botão “Atendimento WhatsApp”.</h2>
          <div>
            <omt-buttons-bar>
              <omt-button v-if="!onWelcomeTour" :onClick="() => $router.back()"> Sair </omt-button>
              <omt-button :onClick="() => $router.push('/meus-contatos?add-contact-modal=Telefones')">
                Continuar
              </omt-button>
            </omt-buttons-bar>
          </div>
        </div>
        <div v-else-if="!WhatsAppAtivo && TelefonesDisponiveis && TelefonesDisponiveis.length > 0">
          <h2>Selecione o número do telefone utilizado para acessar o Whatsapp.</h2>
          <Options
            :options="TelefoneOptions"
            :name="'telefone'"
            :id="'telefones-list'"
            :on-selection="onSelected"
            :placeholder="'Selecione um Telefone'"
            :preSelected="TelefoneOptions.length === 1 ? 'first' : ''"
          />
          <div>
            <omt-buttons-bar>
              <omt-button :onClick="() => $router.push('/')"> {{ lblButtonBack }} </omt-button>
              <omt-button :onClick="() => goWhats()"> Salvar </omt-button>
            </omt-buttons-bar>
          </div>
        </div>
      </omint-page-content>
    </transition>
    <Footer />
  </omint-page>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Header from "@/components/Molecules/Header";
import Options from "@/components/Molecules/Options";
import Footer from "@/components/Molecules/Footer";
import { PERFIL_ASSOCIADO_KIPP, ROTA_WELCOME_ONBOARD_TOUR } from "@/utils/perfilRouteUtil";
import { isMobile } from "@/utils/utils";

export default {
  name: "Whatsapp",
  data() {
    return {
      telefone: "",
      prevRoute: ""
    };
  },
  computed: {
    ...mapState("atendimento", ["WhatsAppAtivo", "TelefonesDisponiveis"]),
    ...mapState("saude", ["vinculo", "perfil"]),
    ...mapState("myAccount", ["welcomeOnBoardTourStep"]),
    onWelcomeTour() {
      if (!this.prevRoute) return false;
      if (this.welcomeOnBoardTourStep > 0) return true;
      return this.prevRoute.startsWith(ROTA_WELCOME_ONBOARD_TOUR);
    },
    lblButtonBack() {
      return this.onWelcomeTour ? "Continuar" : "Sair";
    },
    isMobile() {
      return isMobile();
    },
    TelefoneOptions() {
      if (this.TelefonesDisponiveis === null || (this.TelefonesDisponiveis && this.TelefonesDisponiveis.length === 0))
        return [];
      const telefones = this.TelefonesDisponiveis.map(b => ({
        label: `(${b.DDD}) ${b.NumeroFormatado}`,
        value: b.NumeroCompleto
      }));
      if (telefones.length === 1) {
        this.telefone = telefones[0].value;
      }
      return telefones;
    }
  },
  methods: {
    ...mapActions("atendimento", ["validarAtendimentoWhatsApp", "ativarAtendimentoWhatsApp"]),
    ...mapActions("myAccount", ["fetchAccessResource"]),
    onSelected(value) {
      this.telefone = value;
    },
    async goWhats() {
      this.$root.$emit("show-loading");
      try {
        await this.ativarAtendimentoWhatsApp({
          phoneNumber: this.telefone,
          auth: 0
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }

      this.redirectWhats();
    },
    async redirectWhats() {
      let link = "";

      if (!this.onWelcomeTour) {
        if (this.perfil === PERFIL_ASSOCIADO_KIPP) {
          link = "https://wa.me/5511999315738";
        } else {
          const novoChatbotGPT = await this.fetchAccessResource({ funcionalidade: 2, permissao: 1 });
          link = novoChatbotGPT ? "https://wa.me/5511932779705" : "http://wa.me/5511999351140";
        }
        if (this.isMobile) {
          window.location = `${link}?text=Ol%C3%A1`;
        } else {
          window.open(link, "_blank");
        }
      }
      this.$router.push("/");
    }
  },
  components: {
    Header,
    Options,
    Footer
  },
  beforeRouteEnter(_, from, next) {
    next(vm => {
      vm.prevRoute = from.path;
    });
  },
  async mounted() {
    this.$root.$emit("show-loading");
    try {
      await this.validarAtendimentoWhatsApp();
      if (this.WhatsAppAtivo) {
        this.redirectWhats();
      }
    } catch (error) {
      window.$log.error("Não foi possivel obter as formas de contato", error);
    } finally {
      this.$root.$emit("hide-loading");
    }
  }
};
</script>
<style scoped>
.container-fone {
  text-align: center;
  line-height: 30px;
}
</style>
