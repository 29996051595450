<script>
import { CPseudoBox } from "@chakra-ui/vue";
import Vue from "vue";

/**
 * Icon component based on https://vue.chakra-ui.com/pseudobox
 *
 * @prop {any} $props any props listed here: https://vue.chakra-ui.com/style-props
 */
export default Vue.component("OmtCard", {
  render: function (createElement) {
    return createElement(
      CPseudoBox,
      {
        on: { ...this.$listeners },
        attrs: {
          padding: "4",
          borderRadius: "8",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: this.chakraTheme.colors.light.secondary.divider,
          ...this.$attrs
        }
      },
      this.$slots.default
    );
  }
});
</script>
