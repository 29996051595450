<template>
  <OmtLayoutContainer>
    <OmtRow>
      <OmtTypography as="h1-bold"> Buscar exames </OmtTypography>
    </OmtRow>

    <OmtRow mt="24px">
      <OmtInput
        variant="filled"
        iconRight="search"
        placeholder="Busque pelo nome do exame"
        v-model="search"
        :iconRightClick="onSearch"
        v-on:keydown.enter.prevent="onSearch"
      />
    </OmtRow>

    <OmtRow mt="24px" v-if="hasTextToSearch && !isSearching && searchedExams.length > 0">
      <OmtTypography as="h2-bold"> Resultados </OmtTypography>
    </OmtRow>

    <OmtRow stack gap="16px" mt="24px" v-if="searchedExams.length">
      <OmtCardTile
        v-for="(item, index) in searchedExams"
        :key="index"
        :icon="{ name: 'clipboard-list-check', size: 'lg' }"
        :title="item.name"
        @click="selectExam(item)"
        :border-color="
          item.isActive ? 'var(--colors-light-secondary-light-blue)' : 'var(--colors-light-secondary-divider)'
        "
      >
        <template v-slot:lead>
          <CBox @click.stop="selectExam(item)">
            <OmtIcons :name="item.isActive ? 'minus' : 'plus'" size="lg" />
          </CBox>
        </template>
      </OmtCardTile>
    </OmtRow>

    <OmtRow stackgap="12px" v-else-if="hasTextToSearch && !isSearching">
      <CFlex>
        <OmtIcons name="circle-exclamation" size="1x" mr="8px" />
        <OmtTypography as="p1" color="var(--colors-light-primary-gray1)">
          Não foram encontrados resultados para a sua busca.
        </OmtTypography>
      </CFlex>
    </OmtRow>

    <FixedButtonRow>
      <ButtonCounter
        :w="['100%', '308px !important']"
        :count="selectedExams.length"
        :disabled="!selectedExams.length"
        @click="$router.push({ path: '/copay/simulations/create/review' })"
        label="Ver exames"
      />
    </FixedButtonRow>
  </OmtLayoutContainer>
</template>

<script>
import { mapActions, mapState } from "vuex";
import OmtCardTile from "@/components/Molecules/CardTile";
import ButtonCounter from "@/components/Molecules/ButtonCounter";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

export default {
  components: {
    OmtCardTile,
    ButtonCounter,
    FixedButtonRow
  },
  data() {
    return {
      hasTextToSearch: false,
      isSearching: false,
      search: ""
    };
  },
  computed: {
    ...mapState("copay", ["selectedExams", "searchedExams"])
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      await this.fetchExamsLimit();
    } catch (error) {
      console.error("copay exam error", error);
    } finally {
      this.$root.$emit("hide-loading");
    }
  },
  methods: {
    ...mapActions("copay", ["searchExam", "triggerExam", "fetchExamsLimit"]),
    async selectExam(item) {
      const success = await this.triggerExam({
        isActive: !item.isActive,
        name: item.name
      });

      this.$toast({
        description: success ? "Exame adicionado com sucesso" : "Você atingiu o limite máximo permitido de exames",
        status: success ? "success" : "info",
        duration: 2000,
        position: "top"
      });
    },
    async onSearch() {
      try {
        this.$root.$emit("show-loading");
        this.hasTextToSearch = this.search !== "";
        this.isSearching = true;
        await this.searchExam({ search: this.search });
      } catch (error) {
        console.error("copay exam error", error);
      } finally {
        this.isSearching = false;
        this.$root.$emit("hide-loading");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.full-view-swipe {
  margin-left: -16px;
  min-width: calc(100% + 32px);

  ::v-deep .swiper {
    padding: 0 16px;
  }

  ::v-deep .swiper-slide {
    width: auto;
  }

  button {
    pointer-events: none;
  }
}

@media screen and (min-width: 1024px) {
  .full-view-swipe {
    margin-left: -36px;
    min-width: calc(100% + 72px);
  }
}
</style>
