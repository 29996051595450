<template>
  <omint-page>
    <Header name="Configuração" titleLabel="Configuração" />
    <omint-page-content>
      <omt-highlight>Configurações Gerais</omt-highlight>
      <br />
      <RightSwitchCheckBox
        v-if="hasBiometric"
        :key="'settings-biometric-' + `${status}`"
        :onChange="val => handleClick(val)"
        :rounded="true"
        :checked="shoudUseBiometric"
      >
        <CFlex direction="column" align="start" mt="-2px">
          <CBox fontSize="0.8125rem" fontWeight="bold">Ativar acesso por biometria</CBox>
          <CBox fontSize="0.625rem" lineHeight="10px">Acesse o aplicativo usando a sua digital</CBox>
        </CFlex>
      </RightSwitchCheckBox>
      <omt-divider v-if="hasBiometric" />
      <RightSwitchCheckBox
        v-if="v25"
        :key="'settings-camera-' + `${camera}` + requestCount"
        :onChange="() => requestPermission('camera')"
        :rounded="true"
        :checked="camera === 'granted'"
        :disabled="camera === 'granted'"
      >
        <CFlex direction="column" align="start" mt="-2px">
          <CBox fontSize="0.8125rem">Permissão para uso da camera</CBox>
          <CBox fontSize="0.625rem" lineHeight="10px">Necessário para uso do Dr. Omint</CBox>
          <CFlex align="center" justify="center" v-if="camera === 'granted'">
            <CBox fontSize="0.625rem" lineHeight="10px" fontWeight="bold" mt="9px">
              <CIcon name="check-circle" size="16px" color="var(--colors-light-primary-gray1)" mr="8px" />
            </CBox>
            <CBox fontSize="0.625rem" lineHeight="10px" fontWeight="bold" mt="9px">Permissão Autorizada</CBox>
          </CFlex>
        </CFlex>
      </RightSwitchCheckBox>
      <omt-divider v-if="v25" />
      <RightSwitchCheckBox
        v-if="v25"
        :key="'settings-microfone-' + `${microfone}` + requestCount"
        :onChange="() => requestPermission('microfone')"
        :rounded="true"
        :checked="microfone === 'granted'"
        :disabled="microfone === 'granted'"
      >
        <CFlex direction="column" align="start" mt="-2px">
          <CBox fontSize="0.8125rem">Permissão para uso do microfone</CBox>
          <CBox fontSize="0.625rem" lineHeight="10px">Necessário para uso do Dr. Omint</CBox>

          <CFlex align="center" justify="center" v-if="microfone === 'granted'">
            <CBox fontSize="0.625rem" lineHeight="10px" fontWeight="bold" mt="9px">
              <CIcon name="check-circle" size="16px" color="var(--colors-light-primary-gray1)" mr="8px" />
            </CBox>
            <CBox fontSize="0.625rem" lineHeight="10px" fontWeight="bold" mt="9px">Permissão Autorizada</CBox>
          </CFlex>
        </CFlex>
      </RightSwitchCheckBox>
      <omt-divider v-if="v25" />
      <RightSwitchCheckBox
        v-if="v25"
        :key="'settings-location-' + `${location}` + requestCount"
        :onChange="() => requestPermission('location')"
        :rounded="true"
        :checked="location === 'granted'"
        :disabled="location === 'granted'"
      >
        <CFlex direction="column" align="start" mt="-2px">
          <CBox fontSize="0.8125rem">Permissão para uso do localização</CBox>
          <CBox fontSize="0.625rem" lineHeight="10px">Necessário para Busca de Rede por Proximidade</CBox>
          <CFlex align="center" justify="center" v-if="location === 'granted'">
            <CBox fontSize="0.625rem" lineHeight="10px" fontWeight="bold" mt="9px">
              <CIcon name="check-circle" size="16px" color="var(--colors-light-primary-gray1)" mr="8px" />
            </CBox>
            <CBox fontSize="0.625rem" lineHeight="10px" fontWeight="bold" mt="9px">Permissão Autorizada</CBox>
          </CFlex>
        </CFlex>
      </RightSwitchCheckBox>
    </omint-page-content>
    <Footer />
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";
import RightSwitchCheckBox from "@/components/Molecules/RightSwitchCheckBox";
import { mapActions, mapGetters, mapState } from "vuex";
import { CBox, CFlex, CIcon } from "@chakra-ui/vue";

export default {
  components: { Header, Footer, RightSwitchCheckBox, CFlex, CBox, CIcon },
  name: "Settings",
  data: function () {
    return {
      requestCount: 0
    };
  },
  created() {
    this.checkPermissionForResource("camera");
    this.checkPermissionForResource("microfone");
    this.checkPermissionForResource("location");
  },
  mounted() {
    if (!(this.v25 && this.onNative) && !this.hasBiometric) {
      this.$router.replace("/");
    }
    this.$root.$emit("hide-loading");
    this.requestCount += 1;
  },
  computed: {
    ...mapState("biometrics", ["shoudUseBiometric", "hasBiometric", "status", "camera", "microfone", "location"]),
    ...mapGetters("biometrics", ["onNative"]),
    v25() {
      return Number.parseInt(localStorage.getItem("native_version")) >= 25;
    }
  },
  methods: {
    requestPermission(resource) {
      this.requestPermissionForResource(resource);
      this.requestCount += 1;
    },
    ...mapActions("ticket", ["createNewTicket"]),
    ...mapActions("biometrics", [
      "enableBiometric",
      "disableBiometric",
      "requestPermissionForResource",
      "checkPermissionForResource"
    ]),
    handleClick(check) {
      if (check) {
        this.enableBiometric();
        try {
          this.createNewTicket({
            obs: "Ativação da Biometria APP",
            files: [],
            refTicket: null,
            classification: "8178"
          });
        } catch (error) {
          window.$log.error("Não foi possível gravar o chamado de ativação da Biometria", error);
        }
      } else {
        this.disableBiometric();
      }
    }
  }
};
</script>
