<template>
  <OmtGrid>
    <OmtRow mb="24px">
      <OmtTypography as="h1-bold"> Informações de contato </OmtTypography>
    </OmtRow>

    <OmtRow mb="24px">
      <OmtTypography as="h3-semi-bold">
        Endereço utilizado para envio de correspondências sobre o seu plano.
      </OmtTypography>
    </OmtRow>

    <OmtRow>
      <OmtCard box-shadow="sm" border-color="transparent">
        <OmtIcons
          mb="16px"
          box-shadow="sm"
          name="home"
          border-radius="50%"
          height="32px"
          width="32px"
          display="flex"
          justify-content="center"
          align-items="center"
          size="lg"
        />

        <OmtCard box-shadow="sm" border-color="transparent" mb="16px" v-if="!!formAddress">
          <OmtTypography as="h3-bold" mb="16px"> Endereço residencial </OmtTypography>

          <CFlex mb="4" mx="-2">
            <CBox px="2" flex="1">
              <omt-input v-mask="'#####-###'" :haslabel="true" v-model="formAddress.CEP" placeholder="CEP" />
            </CBox>
            <CBox px="2" flex="2">
              <omt-input :haslabel="true" :value="formAddress.Logradouro" readonly placeholder="Logradouro" />
            </CBox>
          </CFlex>

          <CFlex mb="4" mx="-2">
            <CBox px="2" flex="1">
              <omt-input :haslabel="true" v-model="formAddress.Numero" placeholder="Número" />
            </CBox>
            <CBox px="2" flex="2">
              <omt-input :haslabel="true" :value="formAddress.Bairro" readonly placeholder="Bairro" />
            </CBox>
          </CFlex>

          <CBox mb="4">
            <omt-input :haslabel="true" v-model="formAddress.Complemento" placeholder="Complemento" />
          </CBox>

          <CFlex mb="2" mx="-2">
            <CBox px="2" flex="1">
              <omt-input :haslabel="true" :value="formAddress.UF" readonly placeholder="Estado" />
            </CBox>
            <CBox px="2" flex="2">
              <omt-input :haslabel="true" :value="formAddress.Cidade" readonly placeholder="Cidade" />
            </CBox>
          </CFlex>
        </OmtCard>

        <omt-button :style="{ width: '100%' }" :onClick="submit" :colorInverse="true" :disabled="!canSubmit">
          Continuar
        </omt-button>
      </OmtCard>
    </OmtRow>
  </OmtGrid>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import utils from "@/utils/utils";
import Options from "@/components/Molecules/Options";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  components: {
    Options
  },
  data() {
    return {
      validateSubmit: false,
      formAddress: null,
      estados: [],
      cidades: []
    };
  },
  async mounted() {
    if (!this.formAddress) this.formAddress = _.cloneDeep(this.address);
    this.estados = utils.statesFromCountry.map(estado => estado.initials);
  },
  computed: {
    ...mapState({
      address: state => state.myContacts.newAddress || state.myContacts.resumeContacts.Endereco
    }),
    canSubmit() {
      if (!!this.formAddress && this.formAddress.CEP !== this.address.CEP) {
        return true;
      }
      if (!!this.formAddress && this.formAddress.Numero !== this.address.Numero) {
        return true;
      }
      if (!!this.formAddress && this.formAddress.Complemento !== this.address.Complemento) {
        return true;
      }
      return false;
    }
  },
  watch: {
    address(address) {
      if (!this.formAddress) this.formAddress = _.cloneDeep(address);
    },
    ["formAddress.CEP"](cep) {
      if (cep.length === 8 && cep.indexOf("-") === -1) {
        const formattedCEP = cep.replace(/\D/g, "");
        const finalCep = formattedCEP.replace(/^(\d{5})(\d{3})$/, "$1-$2");
        this.formAddress.CEP = finalCep;
      }
      if (cep.length === 9) {
        this.searchAddressByCep(cep);
      }
    },
    ["formAddress.UF"](uf) {
      this.listCitiesByState(uf);
    }
  },
  methods: {
    ...mapActions("myContacts", ["fetchAddressInfos", "fetchCities"]),
    ...mapMutations("myContacts", ["SET_NEW_STATE"]),
    async submit() {
      if (this.validateSubmit) {
        this.SET_NEW_STATE({
          key: "newAddress",
          value: this.formAddress
        });

        this.$router.push({
          path: "/meus-contatos"
        });
      }
    },
    async searchAddressByCep(cep) {
      this.$root.$emit("show-loading");

      try {
        if (cep.replace(/\D/g, "").length > 7) {
          const response = await this.fetchAddressInfos({
            cep: cep
          }).then(data => data.shift());

          if (response) {
            this.validateSubmit = true;

            const formAddress = {
              Bairro: response.Bairro,
              CEP: response.CEP,
              Cidade: response.Municipio.Nome,
              IdLogradouro: response.Codigo,
              Logradouro: response.Descricao,
              TipoLogradouro: response.TipoLogradouro.Codigo,
              UF: response.Municipio.UF
            };

            Object.keys(formAddress).map(key => {
              this.formAddress[key] = formAddress[key];
            });
          } else {
            this.validateSubmit = false;
            Swal.fire({
              title: "Erro",
              text: "Informe um CEP válido",
              confirmButtonText: "OK",
              icon: "error"
            });
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.$root.$emit("hide-loading");
      }
    },
    async listCitiesByState(uf) {
      this.cidades = await this.fetchCities({
        uf: uf
      }).then(data => {
        return data.map(cidade => cidade.Nome);
      });
    }
  }
};
</script>
