<template>
  <button :class="`${myClass} ${disabled ? 'disabled' : ''}`" v-on:click.prevent="onLineClick">
    <slot></slot>
  </button>
</template>
<script>
export default {
  props: ["disabled", "onClick", "colorInverse", "bigSize"],
  computed: {
    myClass() {
      return `button
      ${this.colorInverse === true ? " inverse" : ""}
      ${this.bigSize === true ? " bigsize" : ""}`;
    }
  },
  methods: {
    onLineClick() {
      if (!navigator.onLine) {
        window.dispatchEvent(new Event("offline"));
        return;
      }
      if (!this.disabled) this.onClick();
    }
  }
};
</script>
<style scoped lang="scss">
.big-font-size .button {
  font-size: 150% !important;
  min-width: 150px;
}

.inverse {
  background-color: #68788c !important;
  color: white !important;
}

.dark .button {
  box-shadow: 0px 0px 16px 2px #3c5e8742;
  border: 1px solid #ffffff26;
}
.light .button {
  box-shadow: 0px 0px 8px 2px #00000012;
}

.button {
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px;
  min-width: 100px;
  min-height: 48px;
  border: 1px transparent;
  border-radius: 0;
  font-size: 100%;
  color: var(--colors-light-primary-gray1);

  cursor: pointer;
  &.bigsize {
    margin-top: 30px !important;
    margin-bottom: 0px !important;
    background-color: #263c73 !important;
    color: white !important;
    display: block !important;
    text-align: center;
    padding: 10px 20px;
    font-size: 12px;
    border-radius: 6px !important;
    color: #fff;
    margin-bottom: 15px;
    overflow: hidden;
    transition: all 250ms ease;
    font-weight: 800;
    width: 100%;
  }

  &.save {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 5px !important;
    background-color: #263c73 !important;
    color: white !important;
    display: block !important;
    text-align: center;
    font-size: 12px;
    color: #fff;
    overflow: hidden;
    transition: all 250ms ease;
    font-weight: 800;
    width: 100%;

    &.dark {
      min-height: 39px !important;
      background-color: #68788c !important;
    }

    &.cancel-dark {
      min-height: 39px !important;
      border-color: #68788c !important;
      color: #263c73 !important;
    }
  }

  &.cancel {
    min-height: 39px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-right: 5px !important;
    color: var(--colors-light-primary-gray1) !important;
    border: 1px solid #68788c;
    background-color: transparent !important;
    display: block !important;
    text-align: center;
    font-size: 12px;
    color: #fff;
    overflow: hidden;
    transition: all 250ms ease;
    font-weight: 800;
    width: 100%;
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:hover,
  &:focus {
    outline: none;
  }
}

.big-font-size .button.bigsize {
  font-size: 18px;
}

@media only screen and (min-width: 992px) {
  /* Desktop */
  .button:hover {
    cursor: pointer;
    border: 1px solid;
  }
}
</style>
