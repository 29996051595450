<template>
  <div class="row mr ml mt-5 mb-16">
    <div class="custom-card pa-16">
      <div class="inner-row">
        <CFlex justify="space-between" align="center" class="w-100" jus>
          <omt-h5 class="custom-h5">
            {{ pessoa }}
          </omt-h5>
          <div class="switch-dark">
            <CSwitch :isChecked="ativa" size="lg" @change="handleChange" />
          </div>
        </CFlex>
      </div>

      <div v-if="ativa">
        <div v-for="f in funcionalidades" :key="f.tipo">
          <div class="inner-row mt-24">
            <omt-bold>
              {{ f.tipo || "Informações médicas" }}
            </omt-bold>
          </div>

          <div class="inner-row">
            <small>
              <omt-bold light="true"> ( Selecione os itens que este beneficiário poderá acessar ) </omt-bold>
            </small>
          </div>

          <div class="inner-row column">
            <CBox v-for="permissao in f.permissoes" :key="permissao.id" class="container-box">
              <div class="container-check">
                <input
                  type="checkbox"
                  :checked="permissao.ativa"
                  @change="setFuncionalidade(f.tipo, permissao.id)"
                  class="custom-checkbox"
                />
                <label class="m-0" @click.prevent="setFuncionalidade(f.tipo, permissao.id)">{{
                  permissao.descricao
                }}</label>
              </div>
            </CBox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CSwitch, CBox, CFlex, CText, CCheckbox } from "@chakra-ui/vue";

export default {
  name: "BeneficiaryCard",
  props: ["vinculo", "pessoa", "ativa", "funcionalidades"],
  components: { CBox, CSwitch, CFlex, CText, CCheckbox },
  methods: {
    handleChange() {
      this.$emit("checked", {
        vinculo: this.vinculo
      });
    },
    setFuncionalidade(tipo, permissao) {
      this.$emit("change", {
        vinculo: this.vinculo,
        tipo,
        permissao
      });
    }
  }
};
</script>

<style scoped lang="scss">
.pa-16 {
  padding: 16px;
}

.m-0 {
  margin: 0;
}

.inner-row {
  grid-column-start: 1;
  grid-column-end: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  @media (min-width: 1024px) {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  &.ml {
    margin-left: 16px;
  }

  &.mr {
    margin-right: 16px;
  }

  &.pt {
    padding-top: 16px;
  }

  &.mt-24 {
    margin-top: 24px;
  }

  &.mt-5 {
    margin-top: 5px;
  }

  &.mb-16 {
    margin-bottom: 16px;
  }

  &.column {
    flex-direction: column;
  }
}

.w-100 {
  width: 100%;
}

.custom-card {
  box-shadow: 0px 0px 16px 2px #00000026;
  width: 100%;
}
.custom-h5 {
  color: var(--colors-light-primary-gray1);
  font-weight: bold !important;
}

.container-box {
  &:not(:last-child) {
    border-bottom: 0.5px solid #cccccc;
  }

  .container-check {
    padding-bottom: 16px;
    padding-top: 16px;
    display: inline-flex;
    align-items: center;

    .custom-checkbox {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      cursor: pointer;
    }
  }
}

.switch-dark {
  label[data-chakra-component="CSwitch"] {
    div[data-chakra-component="CControlBox"] {
      background-color: #bcbdc0;
    }

    input[data-chakra-component="CVisuallyHidden"]:checked + div[data-chakra-component="CControlBox"] {
      background-color: #102a46;
    }
  }
}

.dark {
  .switch-dark {
    input[data-chakra-component="CVisuallyHidden"]:checked + div[data-chakra-component="CControlBox"] {
      background-color: #0058e6 !important;
    }
  }

  .custom-card {
    background-color: #1f1f1f;
    color: #ccd1d9;
  }

  .custom-h5 {
    color: inherit;
  }
}
</style>
