<template>
  <OmtStickedModal v-if="isOpen" @close="close" data-cy="refund-create-terms-modal">
    <OmtTypography as="h1-bold" :mb="['24px', '32px']" mx="auto" textAlign="left">
      {{ title }}
    </OmtTypography>

    <OmtTypography as="p1" mb="24px">
      <span v-html="text" />
    </OmtTypography>

    <template #footer>
      <OmtButton :w="['100%', '60%']" @click="close" :position="['relative', 'absolute']" :bottom="['auto', '40px']">
        Ok
      </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
/**
 * @prop {Boolean} isOpen
 * @prop {Boolean} title
 * @prop {Boolean} text
 */
export default {
  props: {
    isOpen: {
      Type: Boolean
    },
    title: {
      Type: String
    },
    text: {
      Type: String
    }
  },
  methods: {
    close() {
      this.$emit("close");
      this.$emit("update:isOpen", false);
    }
  }
};
</script>
