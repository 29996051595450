<template>
  <omint-page-content v-if="ticket">
    <omt-highlight>
      Número do Protocolo:
      <omt-p class="inline"> {{ ticket.IdChamado }}</omt-p>
    </omt-highlight>

    <omt-highlight>
      Data de Início:
      <omt-p class="inline"> {{ ticket.DataHoraInicio ? ticket.DataHoraInicio.replace(" ", " - ") : "" }}</omt-p>
    </omt-highlight>

    <omt-highlight>
      Situação:
      <omt-p class="inline"> {{ ticket.Status }}</omt-p>
    </omt-highlight>

    <omt-buttons-bar>
      <omt-button :onClick="onClickHandler"> Responder </omt-button>
    </omt-buttons-bar>

    <br />
    <div v-if="ticket.Observacoes && ticket.Observacoes.length !== 0">
      <br />
      <omt-highlight>Observações</omt-highlight>
      <omt-divider top="0" />

      <div v-for="(obs, i) in ticket.Observacoes" :key="'obs-' + i">
        <p><omt-bold>Data de Inclusão: </omt-bold>{{ obs.DataInclusao }}</p>
        <p><omt-bold>Texto: </omt-bold>{{ obs.DescricaoObservacao }}</p>
      </div>
    </div>

    <div v-if="ticket.Imagens && ticket.Imagens.length !== 0">
      <br />
      <omt-highlight>Documentos</omt-highlight>
      <omt-divider top="0" />

      <omt-inline-bar>
        <omt-icon v-for="(im, i) in ticket.Imagens" :key="'docs-' + i">
          <label>
            <i v-if="im.Link && im.Link != ''" class="fas fa-external-link fa-3x"></i>
            <i
              v-else-if="im.Tipo == 'pdf'"
              class="fas fa-file-pdf fa-3x"
              @click.prevent="() => onSelect(ticket.IdChamadoCC, im.Documento, im.Tipo)"
            ></i>
            <i
              v-else
              class="fas fa-file-image fa-3x"
              @click.prevent="() => onSelect(ticket.IdChamadoCC, im.Documento, im.Tipo)"
            ></i>
          </label>
        </omt-icon>
      </omt-inline-bar>
    </div>

    <RatingForm
      v-if="ticket.NotaAvaliacao === null"
      :protocolo="ticket.IdChamado"
      tipo="1"
      etapa="P"
      textoAvaliacao="Avalie a solução dada para sua solicitação."
      v-chakra
      mt="4"
    />
  </omint-page-content>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import RatingForm from "@/components/Organisms/RatingForm";
import { mapActions } from "vuex";

export default {
  name: "TicketDetail",
  props: ["idx"],
  components: { RatingForm },
  async mounted() {
    if (!this.idx) {
      this.$router.back();
    }
    try {
      await this.$root.$emit("show-loading");
      this.ticket = await this.findTicket(this.idx);
      if (this.ticket) {
        await this.$root.$emit("show-loading");
        await this.$root.$emit("change-footer", "edit", `/ticket/form/${this.idx}`);
      }
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  data: function () {
    return {
      ticket: null,
      editRouter: ""
    };
  },
  beforeDestroy() {
    this.$root.$emit("change-footer", "edit", "");
  },
  methods: {
    ...mapActions("ticket", ["findTicket", "getDocument"]),
    onClickHandler: function () {
      this.$router.push(`/ticket/form/${this.ticket.IdChamado}`);
    },
    onSelect: async function (ticket, document, type) {
      await this.$root.$emit("show-loading");
      try {
        const docStr = await this.getDocument({ ticket, document });
        const blob = new Blob([docStr.file], { type: type === "pdf" ? "application/pdf" : `image/${type}` });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          Swal.fire({
            html: `<img src='${reader.result}' style='max-width:100%;max-height:70vh;margin:auto;'>`,
            width: window.innerWidth - 50,
            showConfirmButton: false,
            showCloseButton: true
          });
        };
      } finally {
        await this.$root.$emit("hide-loading");
      }
    }
  }
};
</script>
<style scoped>
.inline {
  display: inline;
}
</style>
