import { render, staticRenderFns } from "./ProtocolModal.vue?vue&type=template&id=76b87e4a&scoped=true&"
import script from "./ProtocolModal.vue?vue&type=script&lang=js&"
export * from "./ProtocolModal.vue?vue&type=script&lang=js&"
import style0 from "./ProtocolModal.vue?vue&type=style&index=0&id=76b87e4a&lang=scss&scoped=true&"
import style1 from "./ProtocolModal.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76b87e4a",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CModalBody: require('@chakra-ui/vue').CModalBody, CModalFooter: require('@chakra-ui/vue').CModalFooter, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
