<template>
  <CFlex w="100%" v-bind="$attrs" v-on="$listeners" justifyContent="center" alignItems="center" pt="5" pb="2">
    <CBox v-for="id in totalSlides" :key="'slide' + id" w="auto" mr="2" ml="2">
      <CBox
        :bgColor="
          atualSlide === id - 1 ? 'var(--colors-light-primary-navy-blue)' : 'var(--colors-light-secondary-divider)'
        "
        mx="auto"
        width="8px"
        height="8px"
        borderRadius="8px"
      />
    </CBox>
  </CFlex>
</template>
<script>
export default {
  props: {
    totalSlides: {
      type: Number,
      default: 0
    },
    atualSlide: {
      type: Number,
      default: 0
    }
  }
};
</script>
