var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CSelect',_vm._b({staticClass:"atoms-select",on:{"change":_vm.onChange},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}},'CSelect',Object.assign({}, {fontSize: '14px',
    lineHeight: '19px',
    color: 'var(--colors-light-primary-gray1)',
    borderColor: 'var(--colors-light-secondary-divider)',
    variant: _vm.variant,
    _focus: {
      borderColor: 'var(--colors-light-secondary-divider'
    }},
    _vm.styleByVariant,
    _vm.$attrs),false),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }