import Vue from "vue";
import { RegistrarDevice } from "../api/pushNotification";
import { allowUpdate } from "@/utils/checkReadonlyUtil";

let initialState = {
  token: "",
  system: "",
  systemVersion: "",
  isTokenUpdated: false
};

// vuex module
export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  getters: {
    initialized: state => state.token !== "" && state.system !== "" && state.systemVersion !== ""
  },
  actions: {
    async LoadTokenData(context) {
      const rawToken = localStorage.getItem("mobile_token");
      if (rawToken && rawToken !== "") {
        const tokenData = JSON.parse(rawToken);
        await context.commit("SET_TOKEN", tokenData.token);
        await context.commit("SET_SYSTEM", tokenData.system);
        await context.commit("SET_SYSTEM_VERSION", tokenData.systemVersion);
        const oldToken = localStorage.getItem("mobile_token_saved");
        await context.commit("SET_IS_TOKEN_UPDATED", oldToken === rawToken);
      } else {
        await context.commit("SET_IS_TOKEN_UPDATED", true);
      }
    },
    async SyncToken(context) {
      if (!context.rootState.saude.vinculo) return;
      if (context.state.isTokenUpdated) return;
      if (!allowUpdate()) return;
      await RegistrarDevice({
        vinculo: context.rootState.saude.vinculo,
        perfil: context.rootState.saude.perfil || 1,
        token: context.state.token,
        system: context.state.system,
        systemVersion: context.state.systemVersion
      });

      const { token, system, systemVersion } = context.state;
      localStorage.setItem(
        "mobile_token_saved",
        JSON.stringify({
          token,
          system,
          systemVersion
        })
      );
    }
  },
  mutations: {
    LOGOUT_USER: state => state,
    RESET_CONTEXT: state => state,
    SET_TOKEN: (state, token) => Vue.set(state, "token", token),
    SET_SYSTEM: (state, system) => Vue.set(state, "system", system),
    SET_SYSTEM_VERSION: (state, systemVersion) => Vue.set(state, "systemVersion", systemVersion),
    SET_IS_TOKEN_UPDATED: (state, isTokenUpdated) => Vue.set(state, "isTokenUpdated", isTokenUpdated)
  }
};
