<script>
import OmtIcons from "../Icons/index.vue";
import { CInput, CPseudoBox } from "@chakra-ui/vue";
import Vue from "vue";

/**
 * Input component based on https://vue.chakra-ui.com/input
 *
 * @prop {String} variant currently options : "outline", "unstyled", "flushed", "filled"
 * @prop {String} iconLeft any option included here src/components/Atoms/Icons/library.js
 * @prop {String} iconRight
 *
 */

export default Vue.component("OmtInput", {
  props: {
    variant: {
      type: String,
      default: "flushed",
      validator: value => ["outline", "unstyled", "flushed", "filled"].indexOf(value) > -1
    },
    iconLeft: String,
    iconRight: String,
    iconLeftClick: Function,
    iconRightClick: Function,
    fontSize: String,
    fontWeight: String,
    letterSpacing: String
  },
  computed: {
    styleByVariant() {
      const styles = {
        outline: {
          borderColor: "var(--colors-light-secondary-divider)"
        },
        flushed: {
          variant: "flushed",
          borderColor: "var(--colors-light-secondary-divider)",
          _focus: {
            borderColor: "var(--colors-light-secondary-divider)",
            boxShadow: "initial"
          }
        },
        filled: {
          variant: "filled",
          height: "57px",
          bg: "var(--colors-light-primary-light-gray)",
          color: "var(--colors-light-primary-gray1)",
          borderRadius: "8px",
          _placeholder: {
            fontSize: "16px",
            color: "var(--colors-light-primary-gray1)",
            fontWeight: 400
          },
          _focus: {
            borderColor: "none",
            boxShadow: "initial"
          }
        }
      };
      return styles[this.variant];
    }
  },
  render: function (createElement) {
    return createElement(
      CPseudoBox,
      {
        attrs: {
          position: "relative",
          width: "100%"
        }
      },
      [
        this.iconLeft &&
          createElement(OmtIcons, {
            attrs: {
              name: this.iconLeft,
              position: "absolute",
              top: "18px",
              left: "16px",
              zIndex: 1,
              cursor: this.iconLeftClick ? "pointer" : "auto"
            },
            ...(this.iconLeftClick && {
              on: {
                click: this.iconLeftClick
              }
            })
          }),
        createElement(CInput, {
          on: { ...this.$listeners },
          attrs: {
            ...this.$attrs,
            ...this.styleByVariant,
            ...(this.iconLeft && {
              pl: "42px"
            }),
            ...(this.iconRight && {
              pr: "42px"
            }),
            size: "lg",
            color: "var(--colors-light-primary-gray1)",
            fontSize: this.fontSize ? this.fontSize : "14px",
            letterSpacing: this.letterSpacing ? this.letterSpacing : "",
            lineHeight: "19px",
            fontWeight: this.fontWeight ? this.fontWeight : "600",
            _disabled: {
              opacity: 0.7
            }
          }
        }),
        this.iconRight &&
          createElement(OmtIcons, {
            attrs: {
              name: this.iconRight,
              position: "absolute",
              top: "14px",
              right: "16px",
              zIndex: 1,
              cursor: this.iconRightClick ? "pointer" : "auto"
            },
            ...(this.iconRightClick && {
              on: {
                click: this.iconRightClick
              }
            })
          })
      ]
    );
  }
});
</script>
