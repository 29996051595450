<template>
  <OmtGrid>
    <OmtRow :mb="['16px', '20px']">
      <OmtIcons name="file-magnifying-glass" color="var(--colors-light-primary-navy-blue)" size="2x" />
    </OmtRow>

    <OmtRow mb="16px">
      <OmtTypography as="h1-bold"> Esta solicitação de reembolso encontra-se em auditoria </OmtTypography>
    </OmtRow>

    <OmtRow mb="16px">
      <OmtTypography as="p1">
        Para a Omint, a transparência em nossa relação é fundamental. Portanto, gostaríamos de esclarecer o nosso
        processo de Auditoria Médico Administrativa.
      </OmtTypography>
    </OmtRow>

    <OmtRow mb="24px">
      <OmtTypography as="p1">
        <b>Neste momento, esta solicitação de reembolso encontra-se "Suspensa"</b>. Para dar continuidade à análise,
        será necessário fornecer informações e documentos adicionais que nos permitam verificar, conforme regras
        contratuais:
      </OmtTypography>
    </OmtRow>

    <OmtRow :mb="['24px', '42px']">
      <CBox p="16px" border-radius="8px" bg="var(--colors-light-primary-light-gray)">
        <CFlex>
          <OmtIcons w="18px" mr="20px" name="money-check-dollar-pen" />
          <CBox>
            <OmtTypography as="h3-semi-bold"> A data do pagamento das despesas </OmtTypography>
            <OmtTypography as="p2">
              Se o pagamento efetivo das despesas ocorreu <b>antes da data desta solicitação</b>.
            </OmtTypography>
          </CBox>
        </CFlex>

        <OmtDivider my="16px" />

        <CFlex>
          <OmtIcons w="18px" mr="20px" name="memo" />
          <CBox>
            <OmtTypography as="h3-semi-bold"> A descrição dos procedimentos </OmtTypography>
            <OmtTypography as="p2">
              Se a realização de <b>todos os procedimentos</b> está descrita na solicitação.
            </OmtTypography>
          </CBox>
        </CFlex>

        <OmtDivider my="16px" />

        <CFlex>
          <OmtIcons w="18px" mr="20px" name="location-dot" />
          <CBox>
            <OmtTypography as="h3-semi-bold"> O local da realização dos procedimentos </OmtTypography>
            <OmtTypography as="p2">
              Se o local escolhido é <b>habilitado e autorizado</b> para a execução os procedimentos realizados.
            </OmtTypography>
          </CBox>
        </CFlex>

        <OmtDivider my="16px" />

        <CFlex>
          <OmtIcons w="18px" mr="20px" name="memo-circle-check" />
          <CBox>
            <OmtTypography as="h3-semi-bold"> Os procedimentos realizados </OmtTypography>
            <OmtTypography as="p2">
              Se os procedimentos declarados correspondem aos <b>procedimentos efetivamente realizados</b>.
            </OmtTypography>
          </CBox>
        </CFlex>
      </CBox>
    </OmtRow>

    <OmtRow mb="16px">
      <OmtTypography as="p1">
        É importante observar que evidências de uso indevido ou fornecimento de informações inverídicas
        <b> pode resultar no cancelamento do seu contrato. </b>
      </OmtTypography>
    </OmtRow>

    <OmtRow mb="16px">
      <OmtTypography as="p1">
        <b> Você pode solicitar o cancelamento desta solicitação de reembolso a qualquer momento</b> e encerrar o
        processo de auditoria, sem acarretar qualquer implicação e eliminar a necessidade de fornecer informações e
        documentos complementares.
      </OmtTypography>
    </OmtRow>

    <OmtRow :mb="['24px', '42px']">
      <OmtTypography as="p1">
        Estamos à disposição para esclarecer qualquer dúvida que possa surgir durante esse processo.
      </OmtTypography>
    </OmtRow>

    <OmtRow :desktopStart="5" :desktopEnd="8">
      <OmtButton @click="sendToNext"> Continuar </OmtButton>
    </OmtRow>
  </OmtGrid>
</template>

<script>
export default {
  data() {
    return {
      dueDate: "",
      ticket: "",
      refund: ""
    };
  },
  mounted() {
    this.$root.$emit("hide-loading");

    if (this.$route.params.dueDate) this.dueDate = this.$route.params.dueDate;
    if (this.$route.params.ticket) this.ticket = this.$route.params.ticket;
    if (this.$route.params.refund) this.refund = this.$route.params.refund;

    if (!this.dueDate || !this.ticket || !this.refund) {
      this.$router.replace({
        path: "/refund-new"
      });
    }
  },
  methods: {
    sendToNext() {
      this.$router.replace({
        name: "refund.new.audit",
        params: {
          dueDate: this.dueDate,
          ticket: this.ticket,
          refund: this.refund
        }
      });
    }
  }
};
</script>
