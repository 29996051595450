<template>
  <OmtStickedModal v-if="isOpen" @close="close">
    <OmtTypography as="h4-bold" mb="24px"> Termo de aceite para cadastro de administrador de conta </OmtTypography>

    <OmtTypography as="p3" mb="8px">
      Você está solicitando o cadastro de um terceiro como administrador de seu contrato.
    </OmtTypography>

    <OmtTypography as="p3" mb="8px">
      É importante saber que, ao cadastrar um administrador, você estará lhe concedendo poderes para realizar transações
      e visualizar suas informações confidenciais e de seus beneficiários.
    </OmtTypography>

    <OmtTypography as="p3" mb="8px"> O administrador nomeado terá acesso aos seguintes canais: </OmtTypography>

    <CList styleType="disc">
      <OmtTypography as="p3">
        <CListItem mb="8px"> App Minha Omint (versões web e mobile)</CListItem>
      </OmtTypography>

      <OmtTypography as="p3">
        <CListItem mb="8px"> Central de Atendimento 24 horas por telefone. </CListItem>
      </OmtTypography>

      <OmtTypography as="p3">
        <CListItem mb="8px">WhatsApp e Chatbot Omint+ pelos canais digitais. </CListItem>
      </OmtTypography>
    </CList>

    <OmtTypography as="p3" mb="8px">
      A revalidação da nomeação será exigida periodicamente, a cada <b>6 ou 12 meses</b>, conforme a opção selecionada
      no cadastro.
    </OmtTypography>

    <OmtTypography as="p3" mb="8px">
      A nomeação pode ser cancelada, a qualquer momento, pelo App Minha Omint.
    </OmtTypography>

    <OmtTypography as="p3" mb="8px">
      A utilização indevida de qualquer informação fornecida pela Omint durante o período da nomeação será de sua
      inteira responsabilidade, não cabendo à Omint qualquer tipo de reparação.
    </OmtTypography>

    <OmtCheckbox mt="24px" mb="40px" @change="handleOptin">
      <OmtTypography as="p3"> Li e concordo com os termos e condições descritas acima. </OmtTypography>
    </OmtCheckbox>

    <template #footer>
      <OmtButton w="100%" @click="handleContinue" :disabled="!optin"> Concordar e continuar </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
/**
 * @prop {Boolean} isOpen
 */
export default {
  props: {
    isOpen: {
      Type: Boolean
    }
  },
  data() {
    return {
      optin: false
    };
  },
  methods: {
    close() {
      this.optin = false;
      this.$emit("update:isOpen", false);
    },
    handleContinue() {
      this.$emit("continue");
      this.close();
    },
    handleOptin() {
      this.optin = !this.optin;
    }
  }
};
</script>
