<template>
  <omt-card
    @click="handleClick"
    :class="{
      'tile-card-container': hasCountainer,
      'has-hover-effect': hasHoverEffect
    }"
  >
    <CFlex position="relative">
      <CFlex :w="!!hasEvaluation ? '80%' : '90%'" align-items="baseline">
        <i :class="`${this.icon} fa-lg`"></i>
        <CStack class="labels-container" ml="4">
          <omt-bold class="labels-title" v-if="!!title">
            {{ title }}
          </omt-bold>
          <omt-p v-if="subTitle">
            {{ subTitle }}
          </omt-p>
          <CFlex justify-content="space-between">
            <omt-p v-chakra v-if="!!description">
              {{ description }}
            </omt-p>
          </CFlex>
        </CStack>
      </CFlex>

      <CFlex class="lead-icons-container" justify-content="flex-end">
        <CBox v-for="(leadIcon, index) in leadIcons" :key="index" v-bind:ml="index !== 0 && '4'">
          <i :class="`${leadIcon} fa-lg`"></i>
        </CBox>
      </CFlex>

      <omt-p v-chakra v-if="!!hasEvaluation" class="labels-sub-description"> Avaliar </omt-p>
    </CFlex>
    <slot />
  </omt-card>
</template>

<script>
import { CFlex, CStack, CBox } from "@chakra-ui/vue";

export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    hasEvaluation: {
      type: Boolean,
      default: false
    },
    subTitle: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: "fas fa-clipboard-list"
    },
    leadIcons: {
      type: Array
    },
    link: {
      type: String,
      default: ""
    },
    hasCountainer: {
      type: Boolean,
      default: false
    },
    hasHoverEffect: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CFlex,
    CStack,
    CBox
  },
  methods: {
    handleClick() {
      if (this.link) this.$router.push(this.link);
      this.$emit("click");
    }
  }
};
</script>

<style lang="scss" scoped>
.omt-card + .omt-card {
  margin-top: 1rem;
}

.omt-card > .omt-card {
  padding: 0;
  box-shadow: none;
  margin-top: 1rem;

  &.dark {
    border: transparent;
  }
}

.labels {
  &-container {
    gap: 6px;
    line-height: normal;
  }
  &-title {
    font-size: 14px;
  }
}

.labels-sub-description {
  position: absolute;
  right: 0px;
  line-height: normal;
  font-size: 14px;
  bottom: 6px;
  text-decoration: underline;
}

.has-hover-effect {
  transition: opacity 0.5s ease;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
}

p {
  margin: 0 !important;
}

.tile-card-container {
  padding: 1.25rem 1rem;
  background-color: white;
}

.lead-icons-container {
  margin-left: auto;
  width: 100%;
  flex: 1;
  margin-bottom: 0 !important;
  position: relative;
  top: -6px;
}

.dark .tile-card-container {
  background-color: #1f1f1f;
}
</style>
