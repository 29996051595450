<template>
  <OmtStickedModal v-if="isOpen" @close="close" data-cy="refund-create-comments-modal">
    <OmtIcons name="check-circle" size="2xl" mb="32px" color="var(--colors-light-support-success)" />
    <OmtTypography as="h1-bold" mb="24px">
      Solicitação de reembolso <br />
      enviado com sucesso!
    </OmtTypography>

    <CFlex mb="24px">
      <OmtIcons name="square-poll-horizontal" mr="12px" />
      <OmtTypography as="h2-bold"> Protocolo: {{ protocol }} </OmtTypography>
    </CFlex>

    <CBox borderRadius="8px" bg="var(--colors-light-primary-light-gray)" p="12px 16px" mb="24px">
      <CFlex mb="16px">
        <OmtIcons name="bell-on" mr="14px" />

        <CBox v-if="!!prazos && prazos.length > 1">
          <OmtTypography as="h2-bold">
            Após a apresentação da documentação completa à OMINT, a análise da solicitação de reembolso será realizada
            em até:
          </OmtTypography>
          <CBox mt="16px">
            <ul>
              <li v-for="(prazo, index) in prazos" :key="`prazo-${index}`" v-html="prazo.prazo"></li>
            </ul>
          </CBox>
        </CBox>
        <OmtTypography as="h2-bold" v-else-if="!!prazos && prazos.length == 1">
          {{ prazos[0].prazo }}
        </OmtTypography>
        <OmtTypography as="h2-bold" v-else> A análise do pedido será feita em até 2 dias úteis </OmtTypography>
      </CFlex>
      <OmtTypography as="p1" mb="16px">
        <b>Atenção:</b> a OMINT poderá solicitar documentos e informações complementares, para elucidar eventuais
        divergências. Nestes casos, o prazo para o reembolso estará interrompido até a apresentação dos documentos e
        informações complementares solicitados.
      </OmtTypography>
      <OmtTypography as="p1" mb="16px"> Em caso de pendências você será notificado via WhatsApp. </OmtTypography>
      <OmtTypography as="p1" mb="16px"> Acompanhe o status na tela de Reembolsos. </OmtTypography>
    </CBox>

    <OmtTypography as="h3-semi-bold" mb="16px">
      Como você avalia sua experiência com esse serviço pelo APP Minha Omint?
    </OmtTypography>

    <CFlex justify="space-between" mb="8px">
      <OmtButton
        :variant="rate === selectedRate ? 'solid' : 'outline'"
        width="48px"
        height="50px"
        border="0.5px solid var(--colors-light-secondary-divider)"
        borderRadius="50%"
        data-cy="rate-buttons"
        v-for="rate in 5"
        :key="`rate-${rate}`"
        @click="selectRate(rate)"
      >
        <OmtTypography as="p1" :color="rate === selectedRate ? 'white' : 'inherit'"> {{ rate }} </OmtTypography>
      </OmtButton>
    </CFlex>

    <CFlex justify="space-between" mb="22px">
      <OmtTypography as="p2"> Muito difícil </OmtTypography>
      <OmtTypography as="p2"> Muito fácil </OmtTypography>
    </CFlex>

    <OmtRow v-if="selectedRate && selectedRate <= 2">
      <CFlex flexDirection="column" alignItems="flex-end">
        <OmtTextarea
          class="input-observation"
          height="109px"
          padding="12px"
          placeholder="Inclua informações ou detalhes que podem complementar sua avaliação."
          @change="handleChange"
          :initialValue="observation"
        />
        <OmtTypography as="p2" color="var(--colors-light-primary-gray2)"> {{ observation.length }}/350 </OmtTypography>
      </CFlex>
    </OmtRow>

    <template #footer>
      <OmtButton w="100%" @click="save" :disabled="selectedRate === null"> Concluir </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
/**
 * @prop {Boolean} isOpen
 * @prop {String} protocol
 * @prop {Array} prazos
 */
export default {
  props: {
    isOpen: {
      Type: Boolean
    },
    protocol: {
      Type: String
    },
    prazos: {
      Type: Array
    }
  },
  data() {
    return {
      selectedRate: null,
      observation: ""
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      this.$emit("save", {
        ticket: this.protocol,
        type: "3",
        step: "S",
        rating: this.selectedRate,
        obs: this.observation
      });

      this.$emit("update:isOpen", false);
    },
    selectRate(rate) {
      this.selectedRate = rate;
    },
    handleChange(text) {
      this.observation = text;
    }
  }
};
</script>
