<template>
  <OmtStickedModal v-if="isOpen" @close="$emit('close')">
    <CBox>
      <OmtRow mt="16px">
        <OmtIcons name="paper-plane-top" size="3x" mb="32px" color="#172972" />
      </OmtRow>
      <OmtRow mb="32px">
        <OmtTypography as="h1-large">
          Resposta enviada <br />
          com sucesso!
        </OmtTypography>
      </OmtRow>

      <OmtRow>
        <OmtTypography as="p3">
          <CFlex>
            <OmtIcons name="memo" mr="14px" size="1x" />
            <omt-bold>Protocolo: {{ protocol }}</omt-bold>
          </CFlex>
        </OmtTypography>
      </OmtRow>

      <OmtRow mt="32px">
        <div class="warning">
          <CFlex px="16px" pt="16px" pb="14px" align-items="center">
            <OmtIcons name="bell-on" mr="14px" size="1x" />
            <OmtTypography as="p3">
              <omt-bold>Informações enviadas para análise</omt-bold>
            </OmtTypography>
          </CFlex>
          <CFlex ps="27px" mb="16px" px="16px" pb="16px">
            <OmtTypography as="p1">
              Você será notificado sobre o andamento desta solicitação pela home do APP e pode acompanhar o status deste
              envio pela área Minhas Solicitações.
            </OmtTypography>
          </CFlex>
        </div>
      </OmtRow>
    </CBox>
    <template #footer>
      <OmtButton w="100%" @click="$emit('close')"> Fechar </OmtButton>
    </template>
  </OmtStickedModal>
</template>
<script>
export default {
  name: "SendPendency",
  props: {
    isOpen: {
      Type: Boolean
    },
    protocol: {
      type: String
    }
  }
};
</script>

<style scoped>
.warning {
  background-color: #f6f6f9;
  border-radius: 8px;
  margin-bottom: 170px;
}
</style>
