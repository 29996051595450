<template>
  <OmtStickedModal v-if="isOpen" @close="$emit('close')">
    <OmtRow mb="24px">
      <OmtTypography as="h1-bold"> Documentos necessários </OmtTypography>
    </OmtRow>
    <OmtTypography as="p1">
      Confira os documentos e informações necessárias para a sua solicitação.<br />
      <br />
      <strong>Lembre-se:</strong> Todas as imagens enviadas devem pertencer ao beneficiário que utilizou o serviço.
    </OmtTypography>

    <CAccordion :defaultIndex="-1" :allowToggle="true" mt="4">
      <CAccordionItem v-for="(info, i) in necessaryDocs" :key="i">
        <CAccordionHeader
          v-bind="{
            _hover: {
              background: 'inherit'
            },
            _focus: {
              boxShadow: 'none'
            }
          }"
        >
          <CBox flex="1" text-align="left">
            <OmtTypography as="button-small"> {{ info.title }} </OmtTypography>
          </CBox>
          <CAccordionIcon />
        </CAccordionHeader>

        <CAccordionPanel pb="0">
          <OmtTypography as="p1"> <span v-html="info.text"></span></OmtTypography>
          <CBox p="6px 0px">
            <CList styleType="disc">
              <CBox w="100%" v-for="(item, listIndex) in info.list" :key="`info-${listIndex}`">
                <CFlex pb="16px" pt="16px">
                  <OmtIcons :name="item.icon" size="sm" mr="2" />
                  <OmtTypography as="p0">{{ item.subtitle }}</OmtTypography>
                </CFlex>
                <OmtTypography
                  as="p2"
                  v-for="(subItem, subItemIndex) in item.sublist"
                  :key="`subItem-${listIndex}-${subItemIndex}`"
                  :pb="subItemIndex === item.sublist.length - 1 ? '16px' : '0'"
                >
                  <CListItem mb="6px" v-if="typeof subItem === 'string'">
                    <span v-html="subItem"></span>
                  </CListItem>
                </OmtTypography>
              </CBox>
              <CBox w="100%" v-for="(most, mostIndex) in info.mostLists" :key="`most-${mostIndex}`">
                <OmtTypography as="p0" pb="16px" color="var(--colors-lightPrimary-gray2)">
                  {{ most.title }}
                </OmtTypography>
                <CBox v-for="(item, itemIndex) of most.list" :key="`most-${mostIndex}-${itemIndex}`">
                  <CFlex pb="16px">
                    <OmtIcons :name="item.icon" size="sm" mr="2" />
                    <OmtTypography as="p0">{{ item.subtitle }}</OmtTypography>
                  </CFlex>
                  <CBox>
                    <OmtTypography
                      as="p2"
                      v-for="(subItem, subItemIndex) in item.sublist"
                      :key="`most-${mostIndex}-${itemIndex}-${subItemIndex}`"
                      :pb="subItemIndex === item.sublist.length - 1 ? '16px' : '0'"
                    >
                      <CListItem mb="6px" v-if="typeof subItem === 'string'">
                        <span v-html="subItem"></span>
                      </CListItem>
                    </OmtTypography>
                  </CBox>
                </CBox>
              </CBox>
            </CList>
          </CBox>

          <CFlex
            backgroundColor="var(--colors-light-primary-light-gray)"
            borderRadius="8px"
            padding="16px"
            margin="0 -16px"
            v-if="info.alert"
          >
            <CBox>
              <OmtIcons name="circle-exclamation" size="lg" mr="2" color="var(--colors-light-support-warning)" />
            </CBox>
            <CBox>
              <OmtTypography as="p0" fontWeight="400" lineHeight="22px">
                <span v-html="info.alert"></span>
              </OmtTypography>
            </CBox>
          </CFlex>
        </CAccordionPanel>

        <CDivider mt="8px" />
      </CAccordionItem>
    </CAccordion>
  </OmtStickedModal>
</template>

<script>
export default {
  name: "DocumentsModal",
  props: {
    isOpen: {
      Type: Boolean
    },
    subjectOptions: {
      Type: Object
    }
  },
  data() {
    return {
      necessaryDocs: []
    };
  },
  methods: {
    init() {
      let necessaryDocs = [];
      if (this.subjectOptions?.length) {
        necessaryDocs = this.subjectOptions.map(o => {
          const doc = {
            title: o.label,
            text: o.warningOrientation
              ? `<span style='color: var(--colors-light-support-error)'>${o.warningOrientation}</span><br> `
              : o.orientation,
            list: o.attchamentType?.length
              ? [
                  {
                    subtitle: o.attchamentType,
                    icon: "file-waveform",
                    sublist: (o.attchamentDsc ?? []).map(a => a.dsc)
                  }
                ]
              : [],
            alert: o.warningDays
              ? `${o.warningDays} <br><b> O prazo de análise é de até ${o.daysAnalizy} dias úteis.</b>`
              : `<b> O prazo de análise é de até ${o.daysAnalizy} dias úteis.</b>`
          };
          return doc;
        });
      }
      this.necessaryDocs = necessaryDocs;
    }
  },
  watch: {
    subjectOptions: {
      immediate: true,
      handler() {
        this.init();
      }
    }
  }
};
</script>
