<template>
  <CPseudoBox v-bind="$attrs" v-on="$listeners" :pb="spacing && ['', `${spacing}px !important`]" class="omt-grid">
    <slot></slot>
  </CPseudoBox>
</template>
<script>
export default {
  data() {
    return {
      spacing: 0
    };
  }
};
</script>

<style scoped>
/* mobile grid */
.omt-grid {
  background: white;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  width: 100%;
  padding: 24px 16px 32px;
}

.omt-grid .omt-grid {
  padding: 0;
  width: 100%;
}

.dark .omt-grid {
  background: black;
}

/* desktop grid */
@media screen and (min-width: 1024px) {
  .omt-grid {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;
    width: calc(100vw - 244px);
    max-width: 1104px;
    margin: 48px auto 56px auto;
    padding: 40px;
  }
}

.omt-grid .omt-grid {
  width: 100%;
  max-width: initial;
  margin: initial;
  padding: initial;
}
</style>
