import { render, staticRenderFns } from "./omt-report-card.vue?vue&type=template&id=559e46fc&scoped=true&"
import script from "./omt-report-card.vue?vue&type=script&lang=js&"
export * from "./omt-report-card.vue?vue&type=script&lang=js&"
import style0 from "./omt-report-card.vue?vue&type=style&index=0&id=559e46fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "559e46fc",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFlex: require('@chakra-ui/vue').CFlex, CStack: require('@chakra-ui/vue').CStack, CBox: require('@chakra-ui/vue').CBox})
