import Vue from "vue";
import * as apiFormulario from "@/api/formulario";
import { checkUpdatePermission } from "@/utils/checkReadonlyUtil";

let initialState = {
  formulario: 0,
  questionario: null,
  agenda: [],
  horarios: [],
  prestadores: []
};

// vuex module
export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions: {
    async perguntaInicial(context, { formulario }) {
      await context.commit("SET_FORMULARIO", formulario);
      const novoQuestionario = await apiFormulario.BuscarPergunta({
        vinculo: context.rootState.saude.vinculo,
        formulario
      });
      if (novoQuestionario && novoQuestionario.Data) {
        await context.commit("SET_QUESTIONARIO", novoQuestionario.Data);
      }
    },
    async responder(context, { resposta, subRespostas }) {
      checkUpdatePermission();
      const identificacao = context.state.questionario.Identificacao ? context.state.questionario.Identificacao : 0;
      const novoQuestionario = await apiFormulario.Responder({
        vinculo: context.rootState.saude.vinculo,
        identificacao,
        formulario: context.state.formulario,
        pergunta: context.state.questionario.Pergunta.Id,
        resposta,
        subRespostas
      });
      if (novoQuestionario && novoQuestionario.Data) {
        await context.commit("SET_QUESTIONARIO", novoQuestionario.Data);
      }
    },
    async ligarDrOmint(context, { telefone }) {
      checkUpdatePermission();
      const identificacao = context.state.questionario.Identificacao ? context.state.questionario.Identificacao : 0;
      return await apiFormulario.LigarDrOmint({
        vinculo: context.rootState.saude.vinculo,
        identificacao,
        telefone
      });
    },
    async registrarAgenda(context, { id, prestador, telefone, email, inicioDisponibilidade, terminoDisponibilidade }) {
      checkUpdatePermission();
      const agendaAtualizada = await apiFormulario.RegistrarAgenda({
        vinculo: context.rootState.saude.vinculo,
        id,
        prestador,
        telefone,
        email,
        inicioDisponibilidade,
        terminoDisponibilidade
      });
      if (agendaAtualizada && agendaAtualizada.Data) {
        return true;
      }
      return false;
    },

    async listarAgenda(context, { referencia }) {
      const agendaAtualizada = await apiFormulario.ListarAgenda({
        vinculo: context.rootState.saude.vinculo,
        referencia
      });
      await context.commit(
        "SET_AGENDA",
        agendaAtualizada && agendaAtualizada.Data ? agendaAtualizada && agendaAtualizada.Data : []
      );
    }
  },
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    SET_FORMULARIO: (state, next) => Vue.set(state, "formulario", next),
    SET_QUESTIONARIO: (state, next) => Vue.set(state, "questionario", next),
    SET_AGENDA: (state, next) => Vue.set(state, "agenda", next)
  }
};
