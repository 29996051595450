<template>
  <OmtGrid>
    <OmtRow mt="24px">
      <CFlex direction="column">
        <CFlex :direction="['column', 'row']" :align-items="['flex-start', 'center']">
          <OmtIcons name="check-circle" :pb="['24px', '0px']" size="3x" color="var(--colors-light-support-success)" />
          <OmtTypography as="h4-bold" :pl="['0px', '17px']">Carta(s) gerada(s) com sucesso!</OmtTypography>
        </CFlex>

        <CBox v-if="protocolo.email" mt="24px">
          <OmtTypography :as="['p1', 'p3']">
            Para a sua segurança a Omint enviou automaticamente um comprovante com todas as informações dessa
            solicitação para o e-mail:<strong> {{ protocolo.email }} </strong>
          </OmtTypography>
        </CBox>
        <CBox v-if="protocolo.email" :display="['none', 'block']" mt="24px">
          <omt-divider />
        </CBox>
        <CBox :display="['none', 'block']" mt="24px">
          <OmtTypography as="p4-semi-bold"> Baixar PDF </OmtTypography>
        </CBox>
        <CBox :display="['none', 'block']" mt="24px">
          <OmtTypography :as="['p0', 'p3']">
            Caso prefira imprimir a carta seguro viagem, clique no link abaixo:
          </OmtTypography>
        </CBox>
        <CBox :display="['block', 'none']" mt="24px">
          <OmtTypography :as="['p2', 'p3']"
            >Caso prefira <b>baixar em PDF</b> a carta seguro viagem, clique no link abaixo:</OmtTypography
          >
        </CBox>
      </CFlex>
    </OmtRow>
    <OmtRow mt="24px" mb="32px">
      <CFlex direction="column">
        <CBox
          v-for="(documento, index) in protocolo.documentos"
          :key="index"
          cursor="pointer"
          @click="download(documento.urlDownload)"
        >
          <OmtTypography
            as="h3-bold"
            color="var(--colors-light-primary-navy-blue)"
            text-decoration="underline"
            mb="16px"
          >
            <CFlex direction="row" align-items="center">
              <OmtIcons :display="['none', 'block']" name="file-pdf" color="var(--colors-light-primary-navy-blue)" />
              <OmtTypography
                :display="['none', 'block']"
                as="l2"
                pl="8px"
                color="var(--colors-light-primary-navy-blue)"
                >{{ documento.label }}</OmtTypography
              >
              <OmtTypography
                :display="['block', 'none']"
                as="p1-bold"
                pl="8px"
                color="var(--colors-light-primary-gray1)"
                >{{ documento.label }}</OmtTypography
              >
            </CFlex>
          </OmtTypography>
        </CBox>
      </CFlex>
    </OmtRow>
    <OmtRow mb="16px">
      <CBox p="16px" borderRadius="8px" backgroundColor="var(--colors-light-primary-light-gray)">
        <CFlex mb="16px" align-items="baseline">
          <OmtIcons :display="['none', 'block']" name="circle-exclamation" mr="14px" size="1x" />
          <OmtIcons :display="['block', 'none']" name="bell-on" mr="14px" size="1x" />
          <OmtTypography as="button-medium">Acompanhe</OmtTypography>
        </CFlex>
        <CFlex mb="16px" align-items="baseline">
          <OmtTypography as="p1">
            Lembramos que você também pode acompanhar esta solicitação indo no espaço
            <omt-bold> Minha Omint / Carta de Seguro Viagem </omt-bold>
          </OmtTypography>
        </CFlex>
      </CBox>
    </OmtRow>

    <FixedButtonRow>
      <OmtButton :w="['100%', '308px !important']" @click="close"> Concluir </OmtButton>
    </FixedButtonRow>
    <CBox h="30px" />
  </OmtGrid>
</template>

<style>
.icon {
  width: 16px;
  height: 16px;
  vertical-align: -0.125em;
  margin-right: 8px;
}

.icon path {
  fill: var(--colors-light-primary-navy-blue);
}
</style>

<script>
import SafeLetterListFiltersHeader from "@/components/Organisms/SafeLetter/SafeLetterListFiltersHeader.vue";
import SafeLetterListSortButton from "@/components/Organisms/SafeLetter/SafeLetterListSortButton.vue";
import SafeLetterListCard from "@/components/Organisms/SafeLetter/SafeLetterListCard.vue";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

export default {
  components: {
    SafeLetterListFiltersHeader,
    SafeLetterListSortButton,
    SafeLetterListCard,
    FixedButtonRow
  },
  computed: {
    protocolo() {
      return this.$route.params.protocolo ?? {};
    }
  },
  mounted() {
    if (!this.protocolo?.documentos?.length) {
      this.$router.replace({
        name: "safe-letter.list"
      });
    }
  },
  methods: {
    async close() {
      this.$router.replace({
        name: "safe-letter.list"
      });
    },
    download(url) {
      window.open(url, "_blank");
    }
  }
};
</script>
