<template>
  <OmtLayoutHeaderFooter :footer="false" @profilePicClick="showImageCropper = true">
    <OmtGrid px="0 !important" my="0 !important">
      <ImageCropper @hideImageCropper="hideImagePicker" @cropped="onImageResult" v-if="showImageCropper" />

      <OmtRow :desktopStart="1" :desktopEnd="5" :mb="['24px', '0']">
        <CBox>
          <CFlex align-items="center" mb="24px" :display="['none', 'flex']">
            <Profile :profilePic="profile.profilePic" @showPicker="onShowImagePicker" />
            <OmtTypography ml="16px" as="h1-large" color="var(--colors-light-primary-navy-blue)">
              {{ profileName }}
            </OmtTypography>
          </CFlex>
          <template>
            <CFlex v-if="messagesToDisplay.length > 0" :display="['block', 'none']">
              <OmtSwiper
                key="messagesSwiper"
                :autoplay="5000"
                v-if="messagesToDisplay.length > 0"
                data-cy="home-notice-swiper"
                :ml="['16px', '0']"
                :class="
                  notificationSelected > 0 && notificationSelected === messagesToDisplay.length - 1
                    ? 'margin-finish-carousel'
                    : ''
                "
              >
                <OmtSwiperSlide v-for="(item, id) in messagesToDisplay" maxWidth="322px" :key="id" :slidesPerView="1.1">
                  <HomeNoticeSwiperCard :item="item" v-chakra maxWidth="322px" minWidth="322px" />
                </OmtSwiperSlide>
              </OmtSwiper>
            </CFlex>
            <CFlex v-if="profileNotificationChunks.length > 0" :display="['none', 'block']" mb="32px">
              <OmtSwiper
                key="notificationSwiper"
                :autoplay="5000"
                :slidesPerView="1"
                :spaceBetween="16"
                :navigation="true"
                :goToSlide="Number(notificationSelected)"
                mb="16px"
              >
                <OmtSwiperSlide v-for="(chunks, i) in profileNotificationChunks" :key="i">
                  <HomeNoticeSwiperCard
                    v-for="(item, id) in chunks"
                    :key="id"
                    :item="item"
                    :mb="id === 0 && '16px'"
                    v-chakra
                  />
                </OmtSwiperSlide>
              </OmtSwiper>
            </CFlex>
          </template>
        </CBox>
      </OmtRow>

      <OmtRow :desktopStart="7" :desktopEnd="12" mb="32px" justify-content="flex-end">
        <CBox v-if="credentials.length > 0" :maxWidth="['100%', '390px']">
          <OmtTypography as="p1" :display="['none', 'block']">
            Visualizar informações da credencial do beneficiário:
          </OmtTypography>

          <CBox :p="['0 16px', '0']">
            <OmtSelect
              :initialValue="selectedCredencialName"
              mb="24px"
              @change="onCredentialChange($event)"
              data-cy="home-credential-select"
            >
              <option v-for="cred in credentialNames" :key="cred.index" :value="cred.index">
                {{ cred.name.toLowerCase().replace(/\b\w/g, match => match.toUpperCase()) }}
                {{ cred.titular ? "- Titular" : "- Dependente" }}
              </option>
            </OmtSelect>
          </CBox>
          <OmtSwiper
            class="CardSwiper"
            v-chakra
            mb="16px"
            refs="credentialSwiper"
            data-cy="home-credential-swiper"
            :goToSlide="Number(credentialSelected)"
            @slide-atual="onCredentialChange($event)"
            :ml="['16px', '0']"
            :class="
              credentialSelected > 0 && credentialSelected === credentials.length - 1 ? 'margin-finish-carousel' : ''
            "
            :breakPoints="{
              320: {
                slidesPerView: 1.1
              },
              1024: {
                slidesPerView: 1
              }
            }"
          >
            <OmtSwiperSlide v-for="(item, id) in credentials" :key="id">
              <HomeCredential data-cy="home-credential" :credential="item" v-chakra />
            </OmtSwiperSlide>
          </OmtSwiper>

          <CBox w="100%" d="inline-flex" min-height="46px">
            <CFlex d="inline-flex" w="100%" alignItems="center" :justifyContent="['center', 'flex-start']">
              <CFlex :display="['none', 'block']" :justifyContent="['', 'space-between']">
                <OmtSwiperNavigation
                  :disabledPrev="credentialSelected === 0"
                  :disabledNext="credentialSelected === credentials.length - 1"
                />
              </CFlex>
              <OmtButton
                v-if="displayShareButton"
                :width="['45%', '35%']"
                ml="2.5%"
                mr="2.5%"
                height="46px"
                variant="outline"
                border="1px solid var(--colors-light-secondary-divider)"
                @click="irParaCredencial(credentialSelected)"
              >
                <OmtTypography as="h3-bold">Compartilhar</OmtTypography>
              </OmtButton>
              <OmtButton
                :width="['45%', '']"
                :ml="displayShareButton ? 'unset' : '2.5%'"
                :mr="['2%', '0']"
                height="46px"
                variant="outline"
                border="1px solid var(--colors-light-secondary-divider)"
                v-if="credentials[credentialSelected] && credentials[credentialSelected].isMdSv !== true"
                d="flex"
                alignItems="center"
                justifyContent="center"
              >
                <OmtTypography as="h3-bold">Token:</OmtTypography>
                <OmtTypography as="button-large" ml="7px" letterSpacing="7px" mr="-7px">{{
                  createToken(credentialSelected)
                }}</OmtTypography>
              </OmtButton>
            </CFlex>
          </CBox>
        </CBox>
      </OmtRow>

      <OmtRow v-if="menusFavoritos.length > 0" mb="40px" py="24px" flex-direction="column" class="shortcuts-section">
        <CFlex justify-content="space-between" align-items="baseline" margin-bottom="16px">
          <CBox :p="['0 16px', '0']">
            <OmtTypography as="h1-large">Meus atalhos</OmtTypography>
          </CBox>

          <CBox align="end" :mr="['16px', '0']" mb="14px" data-cy="home-edit-shortcuts">
            <router-link :to="{ path: '/edit-shortcuts' }">
              <CBox :d="['none', 'block']"><OmtIcons cursor="pointer" name="pen-to-square" size="xl" /></CBox>
              <CBox :d="['block', 'none']"><OmtIcons cursor="pointer" name="pen-to-square" /></CBox>
            </router-link>
          </CBox>
        </CFlex>

        <CBox>
          <OmtSwiper class="AtalhosSwiper">
            <OmtSwiperSlide
              v-for="(item, id) in menusFavoritos"
              :key="id"
              :ml="id === 0 && ['16px', '0']"
              @click.prevent="quickMenuOnClick(item.View)"
              w="90px !important"
              data-cy="home-shortcuts-card"
              :class="id > 0 && id === menusFavoritos.length - 1 ? 'margin-finish-carousel' : ''"
              cursor="pointer"
            >
              <OmtCard padding="24px" h="100%" w="100%" display="flex" justify-content="center" align-items="center">
                <OmtIcons :name="item.Icon" size="lg" />
              </OmtCard>
              <OmtTypography
                as="button-small"
                :width="['fit-content', '100%']"
                mt="8px !important"
                text-align="center"
                display="flex"
                align-items="center"
                justify-content="center"
                w="100%"
              >
                {{ item.Label }}
              </OmtTypography>
            </OmtSwiperSlide>
          </OmtSwiper>
        </CBox>
      </OmtRow>

      <OmtRow mb="14px" :p="['0 16px', '0']" v-if="taskList.length > 0">
        <OmtTypography as="h1-large">Minhas atividades</OmtTypography>
      </OmtRow>

      <OmtRow :mb="taskList.length > 0 ? '36px' : ''">
        <TaskList />
      </OmtRow>

      <OmtRow mb="14px" :p="['0 16px', '0']">
        <OmtTypography as="h1-large">Destaques Omint</OmtTypography>
      </OmtRow>

      <OmtRow mb="42px" v-if="blogPosts.length > 0">
        <HomeBlogPosts data-cy="home-blog-swiper" :posts="blogPosts" />
      </OmtRow>
    </OmtGrid>

    <Onboard v-if="shouldOpenOnboard" onboard="initial-onboard" />
    <template v-if="displayModalMessage">
      <HomeNoticeModal
        :isOpen.sync="displayModalMessage"
        :title="modalMessage.Mensagem"
        :text="modalMessage.MensagemPopUp"
        @close="closeModalMessage"
      />
    </template>
    <div v-show="false">
      <div v-if="protocoloAvalicaoApp" ref="avaliacaoApp">
        <RatingForm :protocolo="protocoloAvalicaoApp" tipo="601" etapa="P" />
      </div>
    </div>
  </OmtLayoutHeaderFooter>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Credential from "@/components/Organisms/Credential";
import ImageCropper from "@/components/Molecules/ImageCropper";
import Profile from "@/components/Molecules/Profile";
import TaskList from "@/components/Organisms/TaskList";
import RatingForm from "@/components/Organisms/RatingForm";
import HomeCredential from "@/components/Organisms/Home/HomeCredential";
import HomeBlogPosts from "@/components/Organisms/Home/HomeBlogPosts";
import HomeNoticeSwiperCard from "@/components/Organisms/Home/HomeNoticeSwiperCard";
import HomeNoticeModal from "@/components/Organisms/Home/HomeNoticeModal.vue";
import OmintOTP from "@/utils/otpToken";
import Onboard, { onboards } from "@/components/Organisms/Onboard";
import { downloadUrl } from "@/utils/downloadUtil";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { PERFIL_ASSOCIADO_KIPP } from "@/utils/perfilRouteUtil";
import { isMobile } from "@/utils/utils";
import _ from "lodash";

const otp = new OmintOTP();

export default {
  name: "Home",
  components: {
    Profile,
    Credential,
    ImageCropper,
    TaskList,
    RatingForm,
    HomeCredential,
    HomeNoticeSwiperCard,
    HomeNoticeModal,
    HomeBlogPosts,
    Onboard
  },
  data() {
    return {
      displayModalMessage: false,
      modalMessage: {},
      showImageCropper: false,
      credentialSelected: 0,
      notificationSelected: 0,
      initialOnboardOptin: false
    };
  },
  computed: {
    ...mapState("tasks", ["taskList"]),
    ...mapState("myAccount", ["blogPosts"]),
    ...mapState("biometrics", ["hasBiometric", "shoudUseBiometric", "listenersRegistred"]),
    ...mapState("saude", [
      "profile",
      "perfil",
      "credentials",
      "profileName",
      "vinculo",
      "protocoloAvalicaoApp",
      "menusFavoritos",
      "menu"
    ]),
    ...mapGetters("saude", ["baseUrl", "defaultRoute", "onboardWasAlreadyClosed"]),
    displayShareButton() {
      return this.perfil !== PERFIL_ASSOCIADO_KIPP;
    },
    isMobile() {
      return isMobile();
    },
    initialOnboardWasAlreadyClosed() {
      return this.onboardWasAlreadyClosed("initial-onboard");
    },
    shouldOpenOnboard() {
      return this.isMobile && this.initialOnboardOptin && !this.initialOnboardWasAlreadyClosed;
    },
    messagesToDisplay() {
      if (!this.profile?.messages?.length) return [];
      let messages = this.profile.messages.filter(m => !!m.Link && (m.Alerta === "S" || m.Alerta === "N"));
      if (this.hasBiometric && this.listenersRegistred && !this.shoudUseBiometric) return messages;

      messages = messages.filter(item => !(item.TipoAviso === 7 && item.Link === "/settings"));
      return messages;
    },
    credentialNames() {
      if (!this.credentials?.length) return [];
      const names = [];
      this.credentials.forEach((c, index) => {
        if (!names.find(n => n.name === c.name)) {
          names.push({
            index: `${index}`,
            name: c.name,
            number: c.number,
            titular: c.titular
          });
        }
      });
      return names;
    },
    selectedCredencialName() {
      if (!this.credentials?.length) return 0;
      if (this.credentialSelected < 0) return 0;
      if (this.credentialSelected >= this.credentials.length) return 0;
      const names = this.credentialNames.filter(c => c.index <= this.credentialSelected);
      return `${names?.length ? names[names.length - 1]?.index ?? 0 : 0}`;
    },
    profileNotificationChunks() {
      return _.chunk(this.messagesToDisplay, 2);
    }
  },
  async created() {
    this.registerListeners();

    try {
      if (this.initialOnboardWasAlreadyClosed) return;
      const pendingOptin = await this.getOnboardOptin({ template: onboards["initial-onboard"].template });

      this.initialOnboardOptin = !!pendingOptin?.Texto;
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    ...mapActions("myAccount", ["fetchBlogPosts"]),
    ...mapActions("saude", [
      "hydrate",
      "fetchProfileImage",
      "changeProfilePick",
      "clearProtocoloAvalicaoApp",
      "getOnboardOptin",
      "registerAlertNotification"
    ]),
    ...mapActions("pushToken", ["LoadTokenData", "SyncToken"]),
    ...mapActions("biometrics", [
      "checkForBiometric",
      "registerListeners",
      "checkForNativeShare",
      "checkPermissionForResource"
    ]),
    changeCredentialCarousel(action) {
      if (action === "next" && this.credentialSelected < this.credentials.length - 1) {
        this.credentialSelected += 1;
      }
      if (action === "prev" && this.credentialSelected > 0) {
        this.credentialSelected -= 1;
      }
    },
    onCredentialChange(e) {
      this.credentialSelected = Number(e);
    },
    createToken(credentialId) {
      let cred = this.credentials[credentialId];
      if (cred.number === "" || !cred.number) return;
      const n = cred.number.split(" ");
      const secret = `${n[0]}${n[1]}`;
      return otp.getTokenOtp(secret);
    },
    irParaCredencial(credentialSelected) {
      this.$router.push({ path: `/credential/${credentialSelected}` });
    },
    onShowImagePicker() {
      this.showImageCropper = true;
    },
    hideImagePicker() {
      this.showImageCropper = false;
    },
    async onImageResult(imgUrl) {
      const blob = await fetch(imgUrl).then(res => res.blob());
      await this.changeProfilePick({ blobFile: blob });
      this.hideImagePicker();
      await this.fetchProfileImage();
    },
    async isCorrectHome() {
      const correctHome = this.defaultRoute;
      if (this.$route.path !== correctHome) {
        this.$router.replace(correctHome);
        return false;
      }
      return true;
    },
    closeModalMessage() {
      this.registerAlertNotification({
        chamadoReferenciado: this.modalMessage.Chamado,
        tipoAviso: this.modalMessage.TipoAviso,
        classificacao: this.modalMessage.Classificacao
      });
      this.modalMessage = {};
      this.displayModalMessage = false;
    },
    displayAlerts() {
      if (this.profile?.messages?.length) {
        const mensagem = this.profile.messages.find(m => m.Alerta === "X");
        if (mensagem) {
          this.modalMessage = { ...mensagem, MensagemPopUp: mensagem.MensagemPopUp.replaceAll("|", "<br />") };
          this.displayModalMessage = true;
        } else {
          this.modalMessage = { MensagemPopUp: "", Mensagem: "" };
          this.displayModalMessage = false;
        }
      }
      if (this.protocoloAvalicaoApp !== "") {
        Swal.fire({
          html: this.$refs.avaliacaoApp,
          showConfirmButton: false,
          showCloseButton: true
        }).then(async () => {
          await this.clearProtocoloAvalicaoApp();
        });
      }

      const avisar = this.messagesToDisplay.filter(m => m.Alerta === "S");
      if (avisar && avisar.length > 0) {
        avisar.forEach(m => {
          Swal.fire({
            html: `<p style="text-align: left;">${m.MensagemPopUp.replaceAll("|", "<br />")}</p>`,
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: "Ver Agora",
            cancelButtonText: "Ver Depois",
            allowEscapeKey: false,
            allowOutsideClick: false
          }).then(result => {
            try {
              this.registerAlertNotification({
                chamadoReferenciado: m.Chamado,
                tipoAviso: m.TipoAviso,
                classificacao: m.Classificacao
              });
            } finally {
              if (result.value) {
                if (m.Link.startsWith("/")) {
                  this.$router.push(m.Link);
                } else {
                  downloadUrl(m.Link);
                }
              }
            }
          });
        });
      }
    },
    quickMenuOnClick(link) {
      if (typeof link === "function") {
        link();
      } else if (link) {
        this.$router.push(link);
      }
    }
  },
  async mounted() {
    if (!this.isCorrectHome()) return;
    try {
      await this.LoadTokenData();
      await this.SyncToken();
    } finally {
      this.$root.$emit("hide-loading");
    }
    this.checkForBiometric();
    this.checkForNativeShare();
    this.checkPermissionForResource("camera");
    this.checkPermissionForResource("microfone");
    this.checkPermissionForResource("location");
    this.credentialSelected = 0;
    if (!this.blogPosts?.length) {
      this.fetchBlogPosts();
    }
    this.displayAlerts();
  }
};
</script>

<style scoped>
.hide-option {
  display: none;
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}
.cropper {
  position: absolute;
  top: 65px;
  bottom: 0px;
  width: 100vw;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
}

.shortcuts-section {
  background: var(--colors-light-primary-light-gray);
}

@media screen and (min-width: 1024px) {
  .shortcuts-section {
    background: white;
  }
}
</style>
