const baseURL = `${process.env.VUE_APP_SERVER_URL}/Reembolso`;

export async function HistoricoReembolso({ vinculo, reembolso }) {
  return await window.$axios
    .get({
      url: `${baseURL}/Historico`,
      query: {
        vinculo,
        reembolso
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("HistoricoReembolso", error.message);
      return error;
    });
}

export async function ValidarBeneficiarioReembolsoDigital({ vinculo }) {
  return await window.$axios
    .get({
      url: `${baseURL}/ValidarBeneficiarioReembolsoDigital`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ValidarBeneficiarioReembolsoDigital", error.message);
      return error;
    });
}

export async function ObterLimiteArquivosPedido({ vinculo }) {
  return await window.$axios
    .get({
      url: `${baseURL}/ObterLimiteArquivosPedido`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterLimiteArquivosPedido", error.message);
      return error;
    });
}

export async function DetalheProtocolo({ vinculo, protocolo }) {
  return await window.$axios
    .get({
      url: `${baseURL}/DetalheProtocolo`,
      query: {
        vinculo,
        protocolo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("DetalheProtocolo", error.message);
      throw error;
    });
}

export async function ObterProtocolos({ vinculo, periodo, protocolo, chamado }) {
  return await window.$axios
    .get({
      url: `${baseURL}/ObterProtocolos`,
      query: {
        vinculo,
        periodo,
        protocolo: protocolo || "",
        chamado: chamado || 0
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterProtocolos", error.message);
      throw error;
    });
}

export async function ObterImagem({ vinculo, documento, original }) {
  const key = `${vinculo};${documento};${original ? "T" : "F"}`;
  const chave = `${window.$jse.encrypt(key)}`;
  return await window.$axios
    .get({
      url: `${baseURL}/ObterImagem`,
      query: {
        vinculo,
        chave,
        original
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterImagem", error.message);
      throw new Error(error.message);
    });
}

export function ObterDocumento({ vinculo, documento }) {
  const key = `${vinculo};${documento}`;
  const chave = `${window.$jse.encrypt(key)}`;

  const queryString = new URLSearchParams({
    vinculo,
    chave,
    tipo: ".pdf"
  }).toString();

  return `${baseURL}/ObterDocumento?${queryString}`;
}

export function ObterDemonstrativo({ vinculo, protocolo, documento }) {
  const key = `${vinculo};${protocolo};${documento}`;
  const chave = `${window.$jse.encrypt(key)}`;

  const queryString = new URLSearchParams({
    vinculo,
    chave,
    tipo: ".pdf"
  }).toString();

  return `${baseURL}/ObterDemonstrativo?${queryString}`;
}

export async function ObterTiposAnexos({ vinculo }) {
  return await window.$axios
    .get({
      url: `${baseURL}/ObterTiposAnexos`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterTiposAnexos", error.message);
      throw error;
    });
}

export async function ObterPerguntaAuditoria({ vinculo, autenticacao, chamado, protocolo, pergunta }) {
  return window.$axios
    .post({
      url: `${baseURL}/ObterPerguntaAuditoria`,
      query: {
        vinculo
      },
      data: {
        autenticacao,
        chamado,
        protocolo,
        pergunta
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      throw new Error(error.message);
    });
}

export async function ResponderAuditoria({ vinculo, autenticacao, chamado, protocolo, auditoria, pergunta, resposta }) {
  return window.$axios
    .post({
      url: `${baseURL}/ResponderAuditoria`,
      query: {
        vinculo
      },
      data: {
        id: auditoria,
        autenticacao,
        pergunta,
        resposta,
        chamado,
        protocolo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      throw new Error(error.message);
    });
}

export async function EncerrarAuditoria({
  vinculo,
  autenticacao,
  chamado,
  protocolo,
  auditoria,
  anexos,
  observacao,
  solicitacaoCancelamento
}) {
  return window.$axios
    .post({
      url: `${baseURL}/EncerrarAuditoria`,
      query: {
        vinculo
      },
      data: {
        id: auditoria,
        autenticacao,
        chamado,
        protocolo,
        anexos,
        observacao,
        solicitacaoCancelamento
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      throw new Error(error.message);
    });
}
