<template>
  <CBox>
    <CFlex
      v-bind="$attrs"
      v-on="$listeners"
      justify="space-between"
      :align-items="alignItems"
      cursor="pointer"
      :style="{ gap }"
    >
      <CFlex align-items="center">
        <OmtIcons v-if="icon" :name="icon" mr="12px" w="18px" :size="iconSize" />
        <slot />
      </CFlex>

      <CFlex align-items="center">
        <slot name="lead"></slot>
        <OmtIcons v-if="hasArrow" name="chevron-right" color="black" :font-size="['16px', '20px']" size="inherit" />
      </CFlex>
    </CFlex>

    <OmtDivider mt="24px" mb="24px" v-if="divider" />
  </CBox>
</template>

<script>
/**
 * @prop {any} $props any props listed here: https://vue.chakra-ui.com/style-props
 * @prop {String} icon any option included here src/components/Atoms/Icons/library.js
 * @prop {Boolean} divider hide or show divider
 * @prop {Boolean} hasArrow hide or show chevron-right icon
 */
export default {
  props: {
    icon: String,
    alignItems: {
      type: String,
      default: "center"
    },
    divider: {
      type: Boolean,
      default: true
    },
    hasArrow: {
      type: Boolean,
      default: true
    },
    gap: {
      type: String,
      default: "8px"
    },
    iconSize: {
      type: String,
      default: "sm"
    }
  }
};
</script>
