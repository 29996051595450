import Welcome from "@/pages/welcome/index.vue";
import AdministratorRegistrerResume from "@/pages/welcome/administrator-registrer/resume.vue";
import AdministratorRegistrerEdit from "@/pages/welcome/administrator-registrer/edit.vue";
import BiometryRegisterResume from "@/pages/welcome/biometry-register/resume.vue";
import BiometryRegisterEdit from "@/pages/welcome/biometry-register/edit.vue";
import ContaCorrenteResume from "@/pages/welcome/conta-corrente/resume.vue";
import ContaCorrenteEdit from "@/pages/welcome/conta-corrente/edit.vue";
import MeusContatosResume from "@/pages/welcome/meus-contatos/resume.vue";
import MeusContatosEdit from "@/pages/welcome/meus-contatos/edit.vue";
import SettingsResume from "@/pages/welcome/settings/resume.vue";
import SettingsEdit from "@/pages/welcome/settings/edit.vue";
import WhatsappResume from "@/pages/welcome/whatsapp/resume.vue";
import WhatsappEdit from "@/pages/welcome/whatsapp/edit.vue";
import IdentityVerification from "@/pages/welcome/identity-verification.vue";

const routes: Route[] = [
  {
    path: "/welcome",
    component: Welcome,
    meta: {
      checkPathDisabled: true
    },
    children: [
      {
        path: "administrator-registrer/resume",
        component: AdministratorRegistrerResume,
        meta: {
          checkPathDisabled: true
        }
      },
      {
        path: "administrator-registrer/edit",
        component: AdministratorRegistrerEdit,
        meta: {
          checkPathDisabled: true
        }
      },
      {
        path: "biometry/register/resume",
        component: BiometryRegisterResume,
        meta: {
          checkPathDisabled: true
        }
      },
      {
        path: "biometry/register/edit",
        component: BiometryRegisterEdit,
        meta: {
          checkPathDisabled: true
        }
      },
      {
        path: "conta-corrente/resume",
        component: ContaCorrenteResume,
        meta: {
          checkPathDisabled: true
        }
      },
      {
        path: "conta-corrente/edit",
        component: ContaCorrenteEdit,
        meta: {
          checkPathDisabled: true
        }
      },
      {
        path: "meus-contatos/resume",
        component: MeusContatosResume,
        meta: {
          checkPathDisabled: true
        }
      },
      {
        path: "meus-contatos/edit",
        component: MeusContatosEdit,
        meta: {
          checkPathDisabled: true
        }
      },
      {
        path: "settings/resume",
        component: SettingsResume,
        meta: {
          checkPathDisabled: true
        }
      },
      {
        path: "settings/edit",
        component: SettingsEdit,
        meta: {
          checkPathDisabled: true
        }
      },
      {
        path: "whatsapp/resume",
        component: WhatsappResume,
        meta: {
          checkPathDisabled: true
        }
      },
      {
        path: "whatsapp/edit",
        component: WhatsappEdit,
        meta: {
          checkPathDisabled: true
        }
      }
    ]
  },
  {
    path: "/welcome/identity-verification",
    component: IdentityVerification,
    meta: {
      checkPathDisabled: true
    }
  }
];

export default routes;
