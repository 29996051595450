<template>
  <CModal size="xs" :is-centered="true" :on-close="closeAccountMainModal" :closeOnOverlayClick="true" :is-open="true">
    <CModalContent borderRadius="4px" ref="content">
      <CModalBody borderRadius="4px" :class="theme" p="var(--space-3)" style="color: var(--colors-light-primary-gray1)">
        <CFlex align="center" justify="space-between" mb="var(--space-3)">
          <CFlex align="center">
            <i v-chakra mx="0" class="fa fa-md fa-info"></i>
            <omt-h1> Atualizar como conta principal </omt-h1>
          </CFlex>
          <i class="fa fa-xs fa-times" @click="closeAccountMainModal"></i>
        </CFlex>
        <CBox pl="16px">
          <omt-p class="text-decoration">
            <div>
              <b>Conta Principal:</b> será utilizada quando não for indicada uma conta para crédito de Reembolso
              solicitado por Correio ou entregue na Omint.
            </div>
          </omt-p>
        </CBox>
      </CModalBody>
    </CModalContent>
    <CModalOverlay />
  </CModal>
</template>

<script>
import { CBox, CFlex, CModal, CModalOverlay, CModalContent, CModalBody } from "@chakra-ui/vue";
import Options from "@/components/Molecules/Options";
import AccountCard from "@/components/Organisms/BankAccount/AccountCard.vue";
import { mapState } from "vuex";

export default {
  name: "AccountMainModal",
  components: {
    CBox,
    CFlex,
    Options,
    AccountCard,
    CModal,
    CModalOverlay,
    CModalContent,
    CModalBody
  },
  methods: {
    closeAccountMainModal() {
      this.$emit("close");
    }
  },
  computed: {
    ...mapState("saude", ["theme"])
  }
};
</script>

<style lang="scss" scoped>
h1,
p {
  margin: 0;
}

div[data-chakra-component="CModalBody"].dark {
  border: 1px solid currentColor;
}

.text-decoration {
  display: flex;
  align-items: baseline;
  gap: 8px;

  &:before {
    content: "";
    min-height: 4px;
    min-width: 4px;
    background: black;
    border-radius: 50%;
    display: inline-flex;
    margin-bottom: 4px;
    position: relative;
    bottom: 3px;
  }
}

.fa-times {
  position: relative;
  bottom: 0;
  right: 10px;
  cursor: pointer;
  transition: opacity ease 0.5s;
  &:hover {
    opacity: 0.6;
  }
}

.fa-info {
  border-radius: 50%;
  outline: 4px solid currentColor;
  padding: 10px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.4);
}
</style>
