<template>
  <omint-page>
    <Header titleLabel="Tokens Cadastrados" />
    <omint-page-content>
      <CBox v-if="!verificationStarted">
        <OmtTypography as="h1-regular" mb="32px">
          Selecione uma das opções abaixo para <b>validação do token</b> que será enviado:
        </OmtTypography>

        <template v-if="canUseCode && phones.length > 0">
          <OmtRow mb="8px">
            <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> Celular </OmtTypography>
          </OmtRow>
          <OmtRow mb="16px" stack>
            <CBox v-for="(phone, index) in phones" :key="index">
              <OmtDivider v-if="index !== 0" />
              <OmtRadio
                @click="value => selectMethod('CODIGO', 'phone', value)"
                :value="phone"
                name="verifycation-method"
              >
                <OmtTypography as="p1" fontSize="md"> {{ "+55 " + phone }} </OmtTypography>
              </OmtRadio>
            </CBox>
          </OmtRow>
        </template>
        <omt-divider />
        <template v-if="canUseCode && emails.length > 0">
          <OmtRow mb="8px">
            <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"> E-mail </OmtTypography>
          </OmtRow>
          <OmtRow mb="48px" stack>
            <CBox v-for="(email, index) in emails" :key="index">
              <OmtDivider v-if="index !== 0" />
              <OmtRadio
                @click="value => selectMethod('CODIGO', 'email', value)"
                :value="email"
                name="verifycation-method"
              >
                <OmtTypography as="p1" fontSize="md"> {{ email }} </OmtTypography>
              </OmtRadio>
            </CBox>
          </OmtRow>
        </template>

        <template v-if="canUsebiometry && hasBiometry">
          <OmtRow mb="32px">
            <OmtTypography as="h3-bold"> Por biometria facial </OmtTypography>
          </OmtRow>
          <OmtRow mb="84px" stack>
            <OmtRadio
              @click="value => selectMethod('BIO-UNICO', 'biometry', value)"
              value="biometry"
              name="verifycation-method"
            >
              <OmtTypography as="p1">Realizar reconhecimento facial </OmtTypography>
            </OmtRadio>
          </OmtRow>
        </template>
        <template v-else>
          <CBox w="100%" style="text-align: center">
            <OmtTypography as="h1-regular" mb="48px"> ou: </OmtTypography>
          </CBox>
          <OmtButton @click="onRegisterBiometry" w="100%" variant="ghost" color="var(--colors-light-primary-navy-blue)">
            Cadastrar Biometria Facial
          </OmtButton>
        </template>

        <FixedButtonRow :useFooter="false">
          <OmtButton :w="['100%', '308px !important']" :disabled="!canVerify" @click="startVerification">
            Continuar
          </OmtButton>
        </FixedButtonRow>
      </CBox>
      <CBox v-else pb="24px">
        <OmtRow v-if="selectedMethod === 'CODIGO'" stack>
          <OmtRow mb="32px">
            <OmtTypography as="h1-bold" lineHeight="32px">
              Informe o código enviado para {{ selectedContact }}
            </OmtTypography>
          </OmtRow>

          <OmtRow mb="32px">
            <TokenInput :value="token" @input="onTokenInput($event)" />
          </OmtRow>

          <OmtRow mb="18px">
            <OmtTypography
              as="p1"
              color="var(--colors-light-primary-gray2)"
              v-if="selectedMethod === 'CODIGO' && tokenType === 'phone'"
            >
              Se você não receber o código, clique em reenviar código.
            </OmtTypography>
            <OmtTypography as="p1" color="var(--colors-light-primary-gray2)" v-else>
              Se você não receber o código, verifique sua caixa de spam ou clique abaixo em reenviar código.
            </OmtTypography>
          </OmtRow>

          <OmtRow mb="24px">
            <OmtButton @click="enviarToken" variant="ghost" color="var(--colors-light-primary-navy-blue)">
              <OmtTypography as="p1" color="var(--colors-light-primary-navy-blue)" fontWeight="700">
                Reenviar código
              </OmtTypography>
            </OmtButton>
          </OmtRow>

          <FixedButtonRow :useFooter="false">
            <OmtButton :w="['100%', '308px !important']" :disabled="!canCheckToken" @click="validarToken">
              Continuar
            </OmtButton>
          </FixedButtonRow>
        </OmtRow>

        <CBox v-else stack>
          <BiometryAuthHeader />
          <CBox mb="24px">
            <BiometryAuthCard />
          </CBox>
          <BiometryCameraButton
            @success="handleBiometryCameraSuccess"
            @error="handleError"
            @resourceError="handleError"
            @startLoading="$root.$emit('show-loading')"
            @endLoading="$root.$emit('hide-loading')"
            buttonLabel="Continuar"
            :confirmUser="true"
          />
        </CBox>
      </CBox>
    </omint-page-content>
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import TokenInput from "@/components/Molecules/TokenInput/index.vue";
import BiometryCameraButton from "@/components/Organisms/Biometry/BiometryCameraButton";
import BiometryAuthCard from "@/components/Organisms/Biometry/BiometryAuthCard.vue";
import BiometryAuthHeader from "@/components/Organisms/Biometry/BiometryAuthHeader.vue";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";
import mixin from "@/components/Organisms/TokenModal/mixin";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  mixins: [mixin],
  components: {
    Header,
    TokenInput,
    BiometryAuthCard,
    BiometryAuthHeader,
    BiometryCameraButton,
    FixedButtonRow
  },
  created() {
    this.disableAutoSendTokenWithNoPhone = true;
    this._service = "/fluxo-bem-vindo";
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      if (!this.welcomeOnBoardTourRules?.routeBack || !this.welcomeOnBoardTourRules?.action) {
        this.$router.replace("/");
        return;
      }
      const rules = await this.fetchAuthType({ service: this._service });
      if (rules?.Autorizadores?.length || rules?.Autorizador) {
        if (rules?.Autorizadores?.length) {
          this.canUseCode = !!rules.Autorizadores.find(val => val === "CODIGO");
          this.canUsebiometry = !!rules.Autorizadores.find(val => val === "BIO-UNICO");
        } else {
          this.canUseCode = rules.Autorizador === "CODIGO";
          this.canUsebiometry = rules.Autorizador === "BIO-UNICO";
        }
        this.$on("valid", $event => this.handleValid($event));
        if (this.canUsebiometry) {
          const bioCad = await this.fetchFaceStatus();
          this.hasBiometry = bioCad?.Aceito ?? false;
        }
        this.$root.$emit("change-back-route", this.welcomeOnBoardTourRules.routeBack);
        this.$root.$emit("hide-loading");
      } else {
        this.handleValid({ auth: 0 });
      }
    } catch (error) {
      this.$toast({
        description: error.message,
        status: "error",
        duration: 10000,
        position: "bottom-right"
      });
      this.$router.replace(this.welcomeOnBoardTourRules.routeBack);
    } finally {
      this.$root.$emit("hide-loading");
    }
  },
  data() {
    return {
      selectedMethod: "",
      tokenType: "",
      selectedContact: "",
      verificationStarted: false,
      canUseCode: false,
      canUsebiometry: false,
      hasBiometry: false
    };
  },
  methods: {
    ...mapActions("ticket", ["fetchAuthType"]),
    ...mapActions("biometrics", ["authFace", "fetchFaceStatus"]),
    ...mapActions("myAccount", ["saveAndCheckNextWelcomeOnBoardTour"]),
    ...mapMutations("myAccount", ["SET_WELCOME_ONBOARD_TOUR_AUTH"]),
    selectMethod(method, tokenType, contact) {
      this.selectedContact = contact;
      this.selectedMethod = method;
      this.tokenType = tokenType;
    },
    onRegisterBiometry() {
      this.$router.push({
        name: "biometry-register",
        params: {
          routeBack: "/welcome/identity-verification"
        }
      });
    },
    startVerification() {
      if (this.selectedMethod === "CODIGO") {
        if (this.tokenType === "phone") {
          this.onPhoneSelected(this.selectedContact);
        } else {
          this.onEmailSelected(this.selectedContact);
        }

        this.enviarToken().then(sucesso => (this.verificationStarted = sucesso));
      } else {
        this.verificationStarted = true;
      }
    },
    async handleValid({ auth }) {
      try {
        this.$root.$emit("show-loading");
        this.SET_WELCOME_ONBOARD_TOUR_AUTH(auth);
        const rota = await this.saveAndCheckNextWelcomeOnBoardTour();
        this.$router.replace(rota);
      } catch (error) {
        console.error(error);
      } finally {
        this.$root.$emit("hide-loading");
      }
    },
    async handleBiometryCameraSuccess(payload) {
      try {
        const { encrypted } = payload;
        this.$root.$emit("show-loading");
        const data = await this.authFace({ service: this._service, selfie: encrypted });
        if (data?.MensagemErro) {
          this.handleError(data.MensagemErro);
          return;
        }
        if (data?.Autorizado) {
          await this.handleValid({ auth: data.IdAutorizacao });
          return;
        }
        this.$toast("Não foi possível realizar a autenticação");
      } catch (error) {
        console.error(error);
      } finally {
        this.$root.$emit("hide-loading");
      }
    },
    handleError(error) {
      this.$toast({
        description: error || "Ocorreu um erro ao iniciar a câmera",
        status: "error",
        duration: 10000,
        position: "top"
      });
    }
  },
  computed: {
    ...mapState("myAccount", ["welcomeOnBoardTourRules"]),
    ...mapState("myContacts", ["resumeContacts"]),
    emails() {
      const opcoes = [];
      if (this.resumeContacts?.Emails?.Pessoal) opcoes.push(this.resumeContacts.Emails.Pessoal);
      if (this.resumeContacts?.Emails?.Fatura) opcoes.push(this.resumeContacts.Emails.Fatura);
      return opcoes;
    },
    canVerify() {
      return !!this.selectMethod && !!this.selectedContact;
    }
  }
};
</script>
