<template>
  <CTextarea
    v-bind="{
      height: '160px',
      padding: '12px',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '400',
      _focus: {
        borderColor: 'inherit',
        boxShadow: 'none'
      },
      ...$attrs
    }"
    :value="value"
    :maxlength="this.maxLength"
    @change="handleChange"
  />
</template>
<script>
import { CTextarea } from "@chakra-ui/vue";

/**
 * Button component based on https://vue.chakra-ui.com/textarea
 * @prop {String} maxLength default: 350
 * @prop {String} initialValue
 */
export default {
  components: {
    CTextarea
  },
  props: {
    initialValue: String,
    maxLength: {
      type: String,
      default: "350"
    }
  },
  data() {
    return { value: "" };
  },
  methods: {
    handleChange(value) {
      const newValue = value;
      this.value = newValue;
      this.$emit("change", newValue);
    }
  },
  watch: {
    initialValue: {
      handler(newInitialValue) {
        this.$nextTick(() => {
          this.value = newInitialValue;
        });
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
textarea[data-chakra-component="CTextarea"],
textarea[data-chakra-component="CTextarea"]::placeholder {
  font-family: Open Sans, Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--colors-light-primary-gray1);
}
</style>
