<template>
  <omint-page-content>
    <div class="box">
      <CBox>
        <OmtTypography as="h1-bold" mb="4">Envio do código de confirmação</OmtTypography>
        <OmtTypography as="p1" mb="24px">Insira o código que enviamos para:</OmtTypography>
        <CBox bg="var(--colors-light-primary-light-gray)" p="16px" borderRadius="8px" mb="24px">
          <CFlex mb="14px">
            <OmtIcons name="address-card" mr="16px" />
            <OmtTypography as="h2-bold"> Contatos </OmtTypography>
          </CFlex>
          <OmtTypography as="p2" mb="4px" v-if="resetPassword.emailMascarado !== ''"> E-mail </OmtTypography>
          <OmtTypography as="p1" mb="8px" v-if="resetPassword.emailMascarado !== ''">
            {{ resetPassword.emailMascarado }}
          </OmtTypography>
          <OmtTypography as="p2" mb="4px" v-if="resetPassword.telefoneMascarado !== ''">
            SMS para o celular com o final
          </OmtTypography>
          <OmtTypography as="p1" mb="16px" v-if="resetPassword.telefoneMascarado !== ''">
            {{ resetPassword.telefoneMascarado }}
          </OmtTypography>
          <CDivider mt="12px" mb="16px" />
          <OmtTypography
            as="p2"
            mb="16px"
            color="var(--colors-light-primary-gray2)"
            v-if="resetPassword.emailMascarado !== ''"
          >
            Caso o código não chegue em até 10 minutos verifique sua caixa de spam ou clique abaixo:
          </OmtTypography>
          <CFlex justifyContent="center">
            <OmtButton variant="ghost" v-if="resetPassword.emailMascarado !== ''" @click="resendPassword">
              <OmtTypography
                as="p1-bold"
                color="var(--colors-light-primary-navy-blue)"
                textAlign="center"
                cursor="pointer"
              >
                Reenviar código
              </OmtTypography>
            </OmtButton>
          </CFlex>
        </CBox>
        <CBox>
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Código de confirmação</OmtTypography>
          <OmtInput
            type="number"
            placeholder=""
            v-model="$v.code.$model"
            :class="$v.code.$error ? 'warning' : ''"
            mb="16px"
          />
        </CBox>

        <CBox mb="16px">
          <Password @passwordChange="onPasswordChange" />
        </CBox>
      </CBox>
    </div>
    <OmtButton
      variant="solid"
      width="100%"
      height="50px"
      border="0.5px solid var(--colors-light-secondary-divider)"
      borderRadius="40px"
      data-cy="button-entrar"
      @click="onFinalizeClick"
      :disabled="!validPassword || $v.code.$invalid"
      mb="3"
    >
      <OmtTypography as="h1-bold" :color="'white'">Salvar</OmtTypography>
    </OmtButton>
    <ForgotNewPasswordModal :isOpen.sync="modalIsOpen" @continue="onContinue" />
  </omint-page-content>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ForgotNewPasswordModal from "@/components/Organisms/Forgot/ForgotNewPasswordModal.vue";
import Password from "@/components/Molecules/Password/index.vue";

export default {
  name: "NewPasswordForm",
  props: ["email"],
  components: {
    ForgotNewPasswordModal,
    Password
  },
  data: function () {
    return {
      code: null,
      modalIsOpen: false,
      newPassword: "",
      validPassword: false
    };
  },
  validations: {
    code: { required, numeric }
  },
  mounted() {
    if (this.email === "" || this.email === undefined) {
      this.$router.replace({ name: "reset" });
    }
  },
  computed: {
    ...mapState("myAccount", ["resetPassword"])
  },
  methods: {
    ...mapActions("myAccount", ["checkCodeAndChangePassword", "sendResetPassordCode"]),
    onPasswordChange({ valid, password }) {
      this.validPassword = valid;
      this.newPassword = password;
    },
    onContinue() {
      this.$router.push({
        name: "login"
      });
    },
    async resendPassword() {
      try {
        await this.$root.$emit("show-loading");
        await this.sendResetPassordCode({ email: this.email });
        await this.$root.$emit("hide-loading");
        await Swal.fire({
          title: "Sucesso",
          text: "Novo código de confirmação solicitado com sucesso!",
          confirmButtonText: "OK"
        });
      } catch (e) {
        window.$log.error("Não foi possível reenviar o código de recuperação de senha", e);
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    async onFinalizeClick() {
      if (this.validPassword && this.code) {
        let connError = true;
        try {
          await this.$root.$emit("show-loading");
          const resp = await this.checkCodeAndChangePassword({
            codigo: this.code,
            email: this.email,
            password: this.newPassword
          });
          await this.$root.$emit("hide-loading");

          if (resp.Data !== undefined) {
            if (resp.Data) {
              connError = false;
              this.modalIsOpen = true;
            }
          } else if (resp && resp instanceof Error) {
            connError = false;
            await Swal.fire({
              title: "Aviso",
              text: "O Código informado está expirado ou é inválido.",
              confirmButtonText: "OK"
            });
          }
        } catch (e) {
          await this.$root.$emit("hide-loading");
          connError = true;
        } finally {
          await this.$root.$emit("hide-loading");
        }

        if (connError) {
          await Swal.fire({
            title: "Aviso",
            text: "Sua senha não foi alterada. Por favor tente novamente mais tarde.",
            confirmButtonText: "OK"
          });
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.box {
  height: calc(100vh - 180px);
  overflow-y: auto;
}
</style>
