<template>
  <OmtStickedModal v-if="isOpen" @close="close" data-cy="refund-create-terms-modal">
    <OmtTypography as="h1-bold" mb="24px" mx="auto" textAlign="center"> Atenção ao valor dos RECIBOS</OmtTypography>

    <ul v-chakra :ml="['16px', '0px']">
      <li>
        <OmtTypography as="p1" mb="24px">
          <b>Recibo</b> (que não seja Nota Fiscal), com valor superior a R$ 2.000,00, deve ser enviado o documento
          <b>original</b> através dos Correios para o endereço abaixo:
        </OmtTypography>
        <OmtTypography as="p1" mb="24px">
          Rua Franz Schubert, 33 - Jd. Paulistano - SP <br />
          CEP: 01454-020<br />
          <b>A/C - Setor de Reembolso</b>
        </OmtTypography>
      </li>
      <li>
        <OmtTypography as="p1">
          Nota Fiscal Eletrônica de <b>qualquer valor</b> deve ser enviada <b><u>somente</u></b> pelo App Minha Omint.
        </OmtTypography>
      </li>
    </ul>
    <template #footer>
      <OmtButton w="100%" @click="save"> Entendi </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
/**
 * @prop {Boolean} isOpen
 */
export default {
  props: {
    isOpen: {
      Type: Boolean
    }
  },
  methods: {
    close() {
      this.$emit("update:isOpen", false);
    },
    save() {
      this.$emit("continue");
      this.close();
    }
  }
};
</script>
