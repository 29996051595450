<template>
  <CPseudoBox as="header" height="65px" z-index="var(--zIndices-banner)" position="relative" v-bind="$attrs">
    <CFlex
      bg="var(--colors-light-primary-navy-blue)"
      justify-content="center"
      align-items="center"
      min-width="100vw"
      position="fixed"
      top="0"
      height="65px"
    >
      <OmtGrid my="0 !important" :p="['0px 16px !important', '0 !important']" bg="transparent !important">
        <OmtRow :desktopStart="1" :desktopEnd="2" :mobileStart="1" :mobileEnd="1">
          <OmtIcons name="chevron-left" color="white" cursor="pointer" @click="handleBack" />
        </OmtRow>
        <OmtRow :desktopStart="6" :desktopEnd="7" :mobileStart="2" :mobileEnd="3">
          <router-link to="/" v-chakra display="flex" justify-content="center" cursor="pointer">
            <CImage :src="getLogoSrc" height="32px" />
          </router-link>
        </OmtRow>
      </OmtGrid>
    </CFlex>
  </CPseudoBox>
</template>

<script>
export default {
  computed: {
    getLogoSrc() {
      return process.env.VUE_APP_BASE_URL + "images/Logo_OmintSaudeSeguros_Negativo.svg";
    }
  },
  methods: {
    handleBack() {
      this.$router.back();
    }
  },
  mounted() {
    const equalsMenu = document.getElementById("INDmenu-btn");
    if (equalsMenu)
      equalsMenu.style["cssText"] =
        "rigth: 125px; top: 85%; width: 80px; height: 80px; --indscale: 0.6; --indmaincolor: #102a46;";
  }
};
</script>
