<template>
  <omint-page-content>
    <div class="term-box">
      <OmtTypography as="h1-bold" mb="16px">Identificação de vínculo</OmtTypography>
      <OmtTypography as="h3-semi-bold" mb="24px"
        >Para identificar seu vínculo com a Omint informe seu CPF:
      </OmtTypography>
      <!-- <OmtTypography as="p1">Vínculo</OmtTypography>
      <OmtSelect
        placeholder="Selecione o seu vínculo com a Omint"
        @change="e => (selected = e)"
        :initialValue="selected"
        mb="24px"
      >
        <option v-for="(vinculo, index) in vinculos" :key="index" :value="vinculo.value">
          {{ vinculo.label }}
        </option>
      </OmtSelect> -->

      <OmtTypography as="p1">CPF</OmtTypography>
      <OmtInput v-model="input" placeholder="Digite seu CPF" class="InputNumber" v-mask="'###.###.###-##'" mb="5" />
      <omt-p class="error" v-if="inputError && input.length === 14"> CPF inválido</omt-p>

      <omt-divider :onlyClear="true" />
      <br />
    </div>
    <OmtButton
      variant="solid"
      width="100%"
      height="50px"
      border="0.5px solid var(--colors-light-secondary-divider)"
      borderRadius="40px"
      data-cy="button-entrar"
      @click="handleClick()"
      :disabled="input.length != 14 || inputError"
      mb="3"
    >
      <OmtTypography as="h1-bold" color="white">Continuar</OmtTypography>
    </OmtButton>
  </omint-page-content>
</template>

<script>
import Options from "@/components/Molecules/Options";
import { mapState, mapActions, mapMutations } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "Bond",
  components: { Options },
  mounted() {
    this.$root.$emit("change-title-label", "Vínculo");
    this.$root.$emit("hide-loading");
  },
  data: function () {
    return {
      selected: "1",
      inputError: false,
      input: ""
    };
  },
  computed: {
    ...mapState("firstAccess", ["vinculos", "error"])
  },
  methods: {
    ...mapActions("firstAccess", ["fetchVinculos"]),
    ...mapMutations("firstAccess", ["SET_CPF", "SET_CRED"]),
    handleClick: async function () {
      if (this.input.length === 14) {
        this.SET_CPF(this.input.replace(/[\s.-]*/gim, ""));
        this.SET_CRED("");
        // } else if (this.input.length === 13) {
        //   this.SET_CRED(this.input.slice(0, 10));
        //   this.SET_CPF("");
      } else {
        return;
      }
      await this.$root.$emit("show-loading");
      const error = await this.fetchVinculos();
      await this.$root.$emit("hide-loading");
      if (error !== "") {
        if (error.indexOf("Recuperação de Senha") > 0) {
          Swal.fire({
            title: "Aviso",
            text: error,
            showCancelButton: true,
            confirmButtonText: "Tentar novamente",
            cancelButtonText: "Recuperação de Senha",
            allowEscapeKey: false,
            allowOutsideClick: false
          }).then(result => {
            if (result.value) {
              this.invalidUser = true;
              this.invalidPwd = true;
            } else {
              this.$router.push("/forgot/reset");
            }
          });
        } else {
          Swal.fire({
            title: "Aviso",
            text: error,
            confirmButtonText: "Tentar novamente"
          });
        }
      } else {
        await this.$router.push({ name: "first-access.mailcheck" });
      }
    },
    validateCPF(cpf) {
      if (!cpf) {
        return false;
      }
      cpf = cpf.replace(/[\s.-]*/gim, "");
      if (
        !cpf ||
        cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999"
      ) {
        return false;
      }
      let sum = 0;
      let rest;
      for (let i = 1; i <= 9; i += 1) {
        sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
      }
      rest = (sum * 10) % 11;
      if (rest === 10 || rest === 11) {
        rest = 0;
      }
      if (rest !== parseInt(cpf.substring(9, 10), 10)) {
        return false;
      }
      sum = 0;
      for (let i = 1; i <= 10; i += 1) {
        sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
      }
      rest = (sum * 10) % 11;
      if (rest === 10 || rest === 11) {
        rest = 0;
      }
      if (rest !== parseInt(cpf.substring(10, 11), 10)) {
        return false;
      }
      return true;
    }
  },
  watch: {
    input: function (val) {
      this.inputError = !this.validateCPF(val);
    }
  }
};
</script>

<style lang="scss" scoped>
.term-box {
  height: calc(100vh - 180px);
  overflow-y: auto;
}

.InputNumber {
  font-size: 14px;
  font-weight: 700;
  font-size: 14px;
  color: var(--colors-light-primary-gray1);
  &::placeholder {
    font-size: 14px;
    font-weight: 700;
    font-size: 14px;
    color: var(--colors-light-primary-gray1);
  }
}
</style>
