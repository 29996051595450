import Vue from "vue";
import Vuex from "vuex";
import { ObterVinculos } from "@/api/auth";
import * as api from "@/api/api";
import * as associado from "@/api/associado";
import * as atendimento from "@/api/atendimento";
import { loginUser, logoutUser, setAuthToken } from "@/utils/authUtil";
import { getStorageData, setStorageData } from "@/utils/storageUtil";
import * as minhaConta from "@/api/minhaConta";
import {
  getRouteByPerfilNumber,
  isAssociado,
  PERFIL_ASSOCIADO_KIPP,
  ROTA_ALTERAR_SENHA,
  ROTA_OPTIN,
  ROTA_AVISO_ATRASO,
  ROTA_PADRAO,
  ROTA_WELCOME_ONBOARD_TOUR
} from "@/utils/perfilRouteUtil";
import { PLANO_MEDICO, PLANO_ODONTO, PLANO_TERCEIRO } from "@/utils/credentialUtil";

Vue.use(Vuex);

const initialState = {
  header: 0,
  isAppLoaded: false,
  isReadonlyAccess: false,
  profile: getStorageData("profile", {
    profilePic: "",
    messages: []
  }),
  menu: getStorageData("menu", [], true),
  credentials: getStorageData("credentials", [], true),
  userId: getStorageData("userId", "", false),
  profileName: getStorageData("profileName", "", true),
  credentialName: getStorageData("credentialName", "", true),
  vinculo: getStorageData("vinculo", "", false),
  vinculos: [],
  username: getStorageData("username", "", false),
  titular: false,
  contratType: "",
  bigFontSize: getStorageData("bigFontSize", false, false),
  theme: getStorageData("theme", "light", false),
  hydrated: false,
  hydrating: false,
  disableBiometric: false,
  pwaVersion: getStorageData("pwaVersion", "", false),
  route: "/login",
  operacao: getStorageData("operacao", 1, false),
  perfil: getStorageData("perfil", 1, false),
  chamadoRef: getStorageData("chamadoRef", false),
  protocoloAvalicaoApp: "",
  hasMedicCredentials: false,
  hasOdontoCredentials: false,
  menusFavoritos: [],
  closedOnboards: []
};

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  mutations: {
    RESET_CONTEXT: state => {
      var newState = {
        profile: {
          profilePic: "",
          messages: []
        },
        menu: [],
        menusFavoritos: [],
        titular: false,
        hydrated: false
      };
      Object.keys(newState).forEach(p => {
        Vue.set(state, p, newState[p]);
        setStorageData(p, null);
      });
    },
    LOGOUT_USER: state => {
      const newState = {
        profile: {
          profilePic: "",
          messages: []
        },
        isReadonlyAccess: false,
        menu: [],
        menusFavoritos: [],
        credentials: [],
        userId: "",
        profileName: "",
        credentialName: "",
        vinculo: "",
        vinculos: [],
        username: "",
        titular: false,
        contratType: "",
        bigFontSize: false,
        theme: getStorageData("theme", "light"),
        hydrated: false,
        hydrating: false,
        disableBiometric: false,
        route: "/login",
        operacao: 1,
        perfil: 1,
        chamadoRef: null,
        protocoloAvalicaoApp: "",
        closedOnboards: [],
        hasMedicCredentials: false,
        hasOdontoCredentials: false
      };
      Object.keys(newState).forEach(p => {
        Vue.set(state, p, newState[p]);
        setStorageData(p, null);
      });
    },
    SAVE_NEW_PROPS(state) {
      const statesToSave = [
        "userId",
        "username",
        "vinculo",
        "operacao",
        "perfil",
        "chamadoRef",
        "menusFavoritos",
        "menu",
        "credentialName",
        "profileName",
        "profile"
      ];
      statesToSave.forEach(p => {
        const val = state[p];
        Vue.set(state, p, val);
        if (!state.isReadonlyAccess) setStorageData(p, val);
      });
      // salva as credenciais (separado pois pode estourar limite do storage)
      const credenciais = JSON.parse(JSON.stringify(state.credentials ?? []));
      while (credenciais?.length && !setStorageData("credentials", credenciais)) {
        credenciais.pop();
      }
    },
    SET_PROP(state, { payload, prop }) {
      Vue.set(state, prop, payload);
    },
    PUSH_CLOSE_ONBOARD(state, payload) {
      state.closedOnboards.push(payload);
    }
  },
  getters: {
    baseApi: () => process.env.VUE_APP_SERVER_URL,
    baseUrl: () =>
      process.env.VUE_APP_BASE_URL === "/"
        ? ""
        : process.env.VUE_APP_BASE_URL.substring(0, process.env.VUE_APP_BASE_URL.length - 1),
    defaultRoute: state =>
      state.route === ROTA_PADRAO ? getRouteByPerfilNumber(state.operacao, state.perfil) : state.route,
    pwaVersion: () => process.env.VUE_APP_PWA_VERSION,
    onboardWasAlreadyClosed: state => onboard => state.closedOnboards.findIndex(_onboard => _onboard === onboard) !== -1
  },
  actions: {
    appLoaded: context => {
      if (!context.state.isAppLoaded) {
        context.commit("SET_PROP", { payload: true, prop: "isAppLoaded" });
      }
    },
    hydrate: async context => {
      if (context.state.hydrated) return;
      if (context.state.hydrating) return;
      if (!context.state.userId || !context.state.username || !context.state.vinculo) return;
      const vinculo = context.state.vinculo;
      context.commit("SET_PROP", { payload: true, prop: "hydrating" });
      window.$log.message("Atualizando informações de menu, credenciais e profile...");
      try {
        if (getRouteByPerfilNumber(context.state.operacao, context.state.perfil) === ROTA_PADRAO) {
          context.commit("SET_PROP", { payload: route, prop: "route" });
          context.commit("SET_PROP", { payload: false, prop: "hydrating" });
          context.commit("SET_PROP", { payload: true, prop: "hydrated" });
          return;
        }
        // serviços iniciais, obrigatórios
        const initialServices = [
          context.dispatch("myAccount/fetchTerms", null, { root: true }),
          context.dispatch("accessManagement/fetchNonpaymentWarning", null, { root: true }),
          context.dispatch("myAccount/fetchChangePasswordRequired", null, { root: true })
        ];
        if (isAssociado(context.state.perfil)) {
          initialServices.push(context.dispatch("myAccount/fetchWelcomeOnBoardTour", null, { root: true }));
        }
        await Promise.all(initialServices);
        // regra para caso exista fluxo de bem vindo (carregar o menu no login passa a ser obrigatório)
        let menuLoaded = false;
        if (isAssociado(context.state.perfil)) {
          const hasWelcomeTour = context.rootState.myAccount?.hasWelcomeOnboard ?? false;
          if (hasWelcomeTour) {
            await Promise.all([
              context.dispatch("fetchMenuData"),
              context.dispatch("fetchFavoriteMenus", { retornarAtalhos: true })
            ]);
            await context.dispatch("myAccount/checkhWelcomeOnBoardTourPages", null, { root: true });
            menuLoaded = true;
          }
        }

        const route = getRouteByPerfilNumber(context.state.operacao, context.state.perfil);
        context.commit("SET_PROP", { payload: route, prop: "route" });

        const servicosBase = [];
        if (isAssociado(context.state.perfil)) {
          servicosBase.push(context.dispatch("checkAppRating"));
          servicosBase.push(context.dispatch("fetchProfileImage"));
          servicosBase.push(context.dispatch("fetchCredentials"));
          servicosBase.push(context.dispatch("fetchProfileMessages"));
          if (!menuLoaded) {
            servicosBase.push(context.dispatch("fetchMenuData"));
            servicosBase.push(context.dispatch("fetchFavoriteMenus", { retornarAtalhos: true }));
          }
        }
        if (!context.state.vinculos?.length) {
          servicosBase.push(context.dispatch("fetchVinculos"));
        }
        Promise.all(servicosBase).then(() => {
          if (vinculo === context.state.vinculo) {
            context.dispatch("fetchVinculoInfos");
            context.commit("SET_PROP", { payload: false, prop: "hydrating" });
            context.commit("SET_PROP", { payload: true, prop: "hydrated" });
            context.commit("SAVE_NEW_PROPS");
            if (context.state.disableBiometric) {
              context.dispatch("biometrics/disableBiometric", null, { root: true });
            }
          }
        });
      } catch (err) {
        window.$log.error("Não foi aplicar as regras de hydrate", err);
      }
    },
    createHeader: context => {
      const newValue = context.state.header++;
      context.commit("SET_PROP", { payload: newValue, prop: "header" });
      return newValue === 1;
    },
    destroyHeader: context => {
      const newValue = context.state.header--;
      context.commit("SET_PROP", { payload: newValue, prop: "header" });
      return newValue === 0;
    },
    changeTheme: context => {
      const newThema = context.state.theme === "light" ? "dark" : "light";
      context.commit("SET_PROP", { payload: newThema, prop: "theme" });
    },
    async loginUser(context, { username, password, type }) {
      let isReadonlyAccess = false;
      if (type === "readonly-access") {
        type = "Omint";
        isReadonlyAccess = true;
      }
      username = username?.trim();
      const changingUser = username?.toLowerCase() !== context.state.username?.trim()?.toLowerCase();
      const oldUserId = context.state.userId;

      const appLocked = context.rootState.accessManagement.appLocked;
      const loginData = await loginUser(type ?? username, password);
      if (loginData === null || !loginData.Login) {
        return "";
      }

      await context.dispatch("accessManagement/unlockApp", null, { root: true });
      if (changingUser) {
        if (context.state.username) {
          logoutUser();
          context.commit("LOGOUT_USER");
        }
        setAuthToken(loginData);
      }
      if (changingUser && oldUserId !== "" && oldUserId !== loginData.IdUsuario) {
        context.commit("SET_PROP", { payload: true, prop: "disableBiometric" });
      }
      context.commit("SET_PROP", { payload: isReadonlyAccess, prop: "isReadonlyAccess" });
      context.commit("SET_PROP", { payload: false, prop: "hydrated" });
      context.commit("SET_PROP", { payload: false, prop: "hydrating" });

      if (appLocked && context.state.vinculos.length === 1) {
        if (context.state.vinculos[0].Vinculos.length === 1) {
          await context.dispatch("hydrate");
          return await context.dispatch("fetchInitRoute");
        } else {
          return "/vinculos";
        }
      }

      username = loginData.Login.trim();
      context.commit("SET_PROP", { payload: loginData.IdUsuario, prop: "userId" });
      context.commit("SET_PROP", { payload: username, prop: "username" });
      await context.dispatch("fetchVinculos");
      const vinculos = context.state.vinculos;
      if (vinculos?.length) {
        context.dispatch(
          "myAccount/registerAccess",
          {
            servico: "Login",
            funcionalidade: "Operação de login",
            id: null
          },
          { root: true }
        );
        if (vinculos.length === 1) {
          const operacaoVinculo = vinculos[0];
          if (operacaoVinculo.Vinculos.length === 1) {
            await context.dispatch("setVinculo", {
              operacao: operacaoVinculo.IdOperacaoOmint,
              perfil: operacaoVinculo.IdPerfilOmint,
              vinculo: operacaoVinculo.Vinculos[0].NumeroIdentificacaoVinculo
            });
            return await context.dispatch("fetchInitRoute");
          }
        }
        return "/vinculos";
      }
      return "";
    },
    async setVinculo(context, { operacao, perfil, vinculo }) {
      context.dispatch("resetContext", {}, { root: true });
      let grupo = 0;
      const vinculos = context.state.vinculos;
      if (operacao === 0 && perfil === 0) {
        if (vinculo) {
          vinculos.every(g =>
            g.Vinculos.every(v => {
              if (v.NumeroIdentificacaoVinculo === vinculo) {
                operacao = g.IdOperacaoOmint;
                perfil = g.IdPerfilOmint;
                grupo = v.IdGrupoOmint;
                return false;
              }
              return true;
            })
          );
        }
      }
      if (vinculo && grupo === 0) {
        vinculos.every(g =>
          g.Vinculos.every(v => {
            if (
              v.NumeroIdentificacaoVinculo === vinculo &&
              operacao === g.IdOperacaoOmint &&
              perfil === g.IdPerfilOmint
            ) {
              grupo = v.IdGrupoOmint;
              return false;
            }
            return true;
          })
        );
      }
      minhaConta.AtualizarVinculo({
        vinculo,
        operacao,
        perfil,
        grupo,
        sistemaOperacional: context.rootState.pushToken.system,
        versaoSO: context.rootState.pushToken.systemVersion
      });
      const route = getRouteByPerfilNumber(operacao, perfil);

      context.commit("SET_PROP", { payload: route, prop: "route" });
      context.commit("SET_PROP", { payload: vinculo, prop: "vinculo" });
      context.commit("SET_PROP", { payload: operacao, prop: "operacao" });
      context.commit("SET_PROP", { payload: perfil, prop: "perfil" });

      // reseta dados do vinculo
      const newState = {
        profile: {
          profilePic: "",
          messages: []
        },
        menu: [],
        menusFavoritos: [],
        credentials: [],
        profileName: "",
        credentialName: "",
        titular: false,
        contratType: "",
        hydrated: false,
        hydrating: false,
        protocoloAvalicaoApp: "",
        closedOnboards: [],
        hasMedicCredentials: false,
        hasOdontoCredentials: false
      };
      Object.keys(newState).forEach(p => {
        context.commit("SET_PROP", { payload: newState[p], prop: p });
      });

      if (isAssociado(perfil)) {
        context.dispatch("createTicket");
      }
      await context.dispatch("hydrate");
    },
    fetchVinculoInfos: context => {
      try {
        const operacao = context.state.operacao;
        const perfil = context.state.perfil;
        const operacaoVinculo = context.state.vinculos.find(
          v => v.IdOperacaoOmint === operacao && v.IdPerfilOmint === perfil
        );
        const vinculoData = operacaoVinculo.Vinculos.find(v => v.NumeroIdentificacaoVinculo === context.state.vinculo);
        const userName = vinculoData.DescricaoIdentificacaoVinculo?.toLowerCase().replace(/\b\w/g, match =>
          match.toUpperCase()
        );
        const firstName = userName && userName.indexOf(" ") > 0 ? userName.split(" ")[0] : userName;
        context.commit("SET_PROP", { payload: vinculoData.Tipo, prop: "contratType" });
        context.commit("SET_PROP", { payload: vinculoData.Titular, prop: "titular" });
        context.commit("SET_PROP", {
          payload: userName,
          prop: "credentialName"
        });
        context.commit("SET_PROP", {
          payload: firstName,
          prop: "profileName"
        });
      } catch (err) {
        window.$log.error("Não foi possível recuperar os dados do vinculo", err);
      }
    },
    fetchInitRoute: async context => {
      try {
        const defaultRoute = getRouteByPerfilNumber(context.state.operacao, context.state.perfil);
        if (defaultRoute === ROTA_PADRAO) {
          const services = await api.ObterServicosUsuario({
            operacao: context.state.operacao,
            perfil: context.state.perfil,
            usuario: context.state.userId,
            login: context.state.username
          });
          const link = services && services.Data && services.Data.length > 0 ? services.Data[0].DsServicoURL : "";
          if (link) {
            return link;
          }
        }
        const terms = context.rootState.myAccount?.terms;
        if (terms?.length) return ROTA_OPTIN;
        const nonPaymentWarning = context.rootState.myAccount?.changePasswordRequired;
        if (nonPaymentWarning) return ROTA_AVISO_ATRASO;
        const changePasswordRequired = context.rootState.myAccount?.changePasswordRequired;
        if (changePasswordRequired) return ROTA_ALTERAR_SENHA;
        const onNative = context.rootGetters["biometrics/onNative"];
        if (!onNative) return defaultRoute;
        const welcomeOnBoardTour = context.rootState.myAccount?.welcomeOnBoardTour ?? [];
        const nextTour = welcomeOnBoardTour.find(b => !b.currentResponse);
        if (nextTour?.id) {
          return ROTA_WELCOME_ONBOARD_TOUR;
        }
        return defaultRoute;
      } catch (err) {
        window.$log.error("Não foi possível resolver a rota inicial", err);
        return ROTA_PADRAO;
      }
    },
    async createTicket(context) {
      const chamadoInicial = await atendimento.RegistroNavegacao({
        vinculo: context.state.vinculo,
        classificacao: 4722
      });
      if (chamadoInicial && chamadoInicial.Data) {
        context.commit("SET_PROP", { payload: chamadoInicial.Data, prop: "chamadoRef" });
      }
    },
    clearProtocoloAvalicaoApp(context) {
      context.commit("SET_PROP", {
        payload: "",
        prop: "protocoloAvalicaoApp"
      });
    },
    async checkAppRating(context) {
      if (context.state.chamadoRef) {
        const protocoloAvalicaoApp = await atendimento.VerificarAvaliacaoAplicativo({
          vinculo: context.state.vinculo,
          chamado: context.state.chamadoRef
        });
        context.commit("SET_PROP", {
          payload: protocoloAvalicaoApp?.Data ?? "",
          prop: "protocoloAvalicaoApp"
        });
      }
    },
    async refreshContext(context) {
      const userId = getStorageData("userId", "");
      const vinculo = getStorageData("vinculo", "");
      const username = getStorageData("username", "");
      if (userId === "" && vinculo === "" && username === "") {
        return "";
      }
      const redirectTo = await context.dispatch("loginUser", {
        username,
        password: `${username};${userId};${vinculo}`,
        type: "Omint"
      });
      if (redirectTo === "/vinculos") {
        await context.dispatch("setVinculo", {
          operacao: context.state.operacao,
          perfil: context.state.perfil,
          vinculo: context.state.vinculo
        });
      }

      return await context.dispatch("fetchInitRoute");
    },
    fetchVinculos: async context => {
      const usuario = context.state.userId;
      const vinculos = await ObterVinculos({ usuario });
      if (vinculos && vinculos.Data) {
        context.commit("SET_PROP", { payload: vinculos.Data, prop: "vinculos" });
      } else {
        context.commit("SET_PROP", { payload: [], prop: "vinculos" });
      }
    },
    fetchMenuData: async context => {
      let menus = [];
      const services = await api.ObterServicosDisponiveis({
        vinculo: context.state.vinculo,
        login: context.state.username,
        operacao: context.state.operacao,
        perfil: context.state.perfil
      });
      if (services?.Data) {
        const allMenus = services.Data.Menu.filter(m => !m.Shortcut);
        allMenus.forEach(a => {
          if (a.View) {
            a.View = a.View.replace("views/restrito", "").replace(".html", "");
          }
          if (!menus.find(m => m.Label === a.Label)) {
            const seguroViagemLegado = a.View.includes("Associados/CartaViagem/FormSolicitacao.aspx");
            if (!seguroViagemLegado || (seguroViagemLegado && context.state.perfil !== PERFIL_ASSOCIADO_KIPP)) {
              menus.push(a);
            }
          }
        });
      }
      context.commit("SET_PROP", { payload: menus, prop: "menu" });
    },
    fetchProfileImage: async context => {
      const vinculo = context.state.vinculo;
      const dadosImagem = await minhaConta.ObterImagemProfile({
        vinculo
      });
      let profilePic = "";
      if (dadosImagem?.Data) {
        profilePic = `data:image/${dadosImagem.Data.Extensao};base64,${dadosImagem.Data.ImagemB64}`;
      }
      if (vinculo !== context.state.vinculo) return;
      context.commit("SET_PROP", {
        payload: {
          profilePic,
          messages: context.state.profile?.messages ?? []
        },
        prop: "profile"
      });
    },
    fetchProfileMessages: async context => {
      const vinculo = context.state.vinculo;
      const welcomeOnBoardTour = context.rootState.myAccount?.welcomeOnBoardTour ?? [];
      const nextTour = welcomeOnBoardTour.find(b => !b.currentResponse);
      let messages = context.state.profile?.messages ?? [];
      messages = messages.filter(
        m =>
          !(
            m.TipoAviso === 1 &&
            m.Cor === "support-warning" &&
            m.Icone === "pen-to-square" &&
            m.Mensagem === "Conclua o cadastro das suas informações no app"
          )
      );
      let onboardTourMessage = null;
      if (nextTour?.id) {
        onboardTourMessage = {
          TipoAviso: 1,
          Alerta: "N",
          Cor: "support-warning",
          Icone: "pen-to-square",
          MensagemPopUp: "",
          Mensagem: "Conclua o cadastro das suas informações no app",
          Link: ROTA_WELCOME_ONBOARD_TOUR
        };
        messages = [onboardTourMessage, ...messages];
      }
      context.commit("SET_PROP", {
        payload: {
          profilePic: context.state.profile?.profilePic ?? "",
          messages
        },
        prop: "profile"
      });

      const avisos = await minhaConta.ObterAvisos({
        vinculo
      });
      if (avisos?.Data) {
        messages = onboardTourMessage ? [onboardTourMessage] : [];
        if (avisos.Data.length) {
          avisos.Data.forEach((aviso, index) => {
            aviso.index = `${aviso.TipoAviso}-${index}`;
            if (aviso.Link === "/notifications") {
              aviso.Link = `${aviso.Link}/${aviso.index}`;
            }
            messages.push(aviso);
          });
        }
        if (vinculo !== context.state.vinculo) return;
        context.commit("SET_PROP", {
          payload: {
            profilePic: context.state.profile?.profilePic ?? "",
            messages
          },
          prop: "profile"
        });
      }
    },
    fetchCredentials: async context => {
      const vinc = context.state.vinculo;
      let credData = await associado.ObterCredencialEletronica({
        codigoTitulo: vinc
      });
      if (!credData?.Data?.length) return;
      let credenciais = [];
      for (let index = 0; index < credData.Data.length; index++) {
        const element = credData.Data[index];

        let credencialMediService = null;
        if (element.BeneficiarioPlanoMedicina > 0) {
          let credTypeMedicina = "";
          let codigoCredencialMedicina = element.CodigoCredencialMedicina ?? "";
          if (codigoCredencialMedicina.length > 1) {
            codigoCredencialMedicina = codigoCredencialMedicina.substring(0, 1);
          }
          switch (codigoCredencialMedicina) {
            case "P":
              credTypeMedicina = "prata";
              break;
            case "S":
              credTypeMedicina = "azul";
              break;
            default:
              credTypeMedicina = context.state.perfil === PERFIL_ASSOCIADO_KIPP ? "branco" : "ouro";
              break;
          }

          credenciais.push({
            id: `med ${index}`,
            cns: element.NumeroCns,
            birth: element.StrDataNascimento,
            bg: element.CaminhoImagemCredenciaMedicina,
            posBg: element.CaminhoImagemCredenciaMedicina
              ? credenciais.length
              : credenciais.find(c => c.id === `med ${element.PosImagemCredenciaMedicina}`).posBg,
            plan: element.CodigoPlanoMedicina,
            valid: element.StrDataFimCredencialMedicina,
            ingress: element.StrDataIngresso,
            number: element.NumeroCredenciaMedicina,
            name: element.NomeBeneficiario,
            credType: credTypeMedicina,
            shareUrl: element.ChaveMedicina,
            numberT: null,
            sex: element.Sexo,
            tipoDocumento: element.TipoDocumento,
            numeroDocumento: element.NumeroDocumento,
            descCreMed: element.DescricaoCredencialMedicina,
            dobIso: element.DataNascimento,
            titular: element.Titular,
            CorBarra: element.CorBarraMedicina,
            CorCredencial: element.CorCredencialMedicina,
            CorFonte: element.CorFonteMedicina,
            typePlan: PLANO_MEDICO,
            vinculo: element.Vinculo
          });
          if (element.NumeroTerceira !== null) {
            credencialMediService = {
              id: `mdsv ${index}`,
              cns: element.NumeroCns,
              birth: element.StrDataNascimento,
              bg: element.CaminhoImagemCredenciaTerc,
              plan: element.IdPlanoTerceira,
              valid: element.StrDataFimCredencialMedicina,
              ingress: element.StrDataIngresso,
              number: element.NumeroCredenciaMedicina,
              name: element.NomeBeneficiario,
              credType: credTypeMedicina,
              shareUrl: element.ChaveTerc,
              numberT: element.NumeroTerceira,
              planT: element.PlanoTerceira,
              accT: element.AcomodacaoTerceira,
              accTp: element.TpAssistencia,
              abrT: element.AbrangenciaTerceira,
              isMdSv: true,
              titular: element.Titular,
              CorBarra: element.CorBarraTerceira,
              CorCredencial: element.CorCredencialTerceira,
              CorFonte: element.CorFonteTerceira,
              typePlan: PLANO_TERCEIRO,
              vinculo: element.Vinculo
            };
          }
        }

        if (element.BeneficiarioPlanoOdonto > 0) {
          let credTypeOdonto = "ouro";
          if (element.CodigoCredencialOdonto === "P") {
            credTypeOdonto = "prata";
          } else if (element.CodigoCredencialOdonto === "S" || element.CodigoCredencialOdonto === "O") {
            credTypeOdonto = "azul";
          }

          credenciais.push({
            id: `odn ${index}`,
            cns: element.NumeroCns,
            birth: element.StrDataNascimento,
            bg: element.CaminhoImagemCredenciaOdonto,
            posBg: element.CaminhoImagemCredenciaOdonto
              ? credenciais.length
              : credenciais.find(c => c.id === `odn ${element.PosImagemCredenciaOdonto}`).posBg,
            plan: element.CodigoPlanoOdonto,
            valid: element.StrDataFimCredencialOdonto,
            ingress: element.StrDataIngresso,
            number: element.NumeroCredenciaOdonto,
            name: element.NomeBeneficiario,
            credType: credTypeOdonto,
            shareUrl: element.ChaveOdonto,
            numberT: null,
            sex: element.Sexo,
            tipoDocumento: element.TipoDocumento,
            numeroDocumento: element.NumeroDocumento,
            dobIso: element.DataNascimento,
            typePlan: PLANO_ODONTO,
            descCreMed: element.DescricaoCredencialOdonto,
            titular: element.Titular,
            CorBarra: element.CorBarraOdonto,
            CorCredencial: element.CorCredencialOdonto,
            CorFonte: element.CorFonteOdonto,
            isOdonto: true,
            vinculo: element.Vinculo
          });
        }

        if (credencialMediService) {
          (credencialMediService.posBg = element.CaminhoImagemCredenciaTerc
            ? credenciais.length
            : credenciais.find(c => c.id === `mdsv ${element.PosImagemCredenciaTerc}`).posBg),
            credenciais.push(credencialMediService);
        }
      }

      credenciais.forEach(c => {
        const n = c.number.split(" ");
        const vinculo = `${n[0]}${n[1]}`;
        const tipoCredencial = c.id.substring(0, 1).toUpperCase();
        c.urlWallet = api.GerarUrlWallet({ vinculo, tipoCredencial });
      });

      const hasSmaxPlan = !!credenciais.find(c => c.plan === "SMAX");
      if (!hasSmaxPlan) {
        credenciais.forEach(c => {
          c.CorFonte = "#FFF";
        });
      }

      context.commit("SET_PROP", { payload: credenciais, prop: "credentials" });

      const loggedUserCredential = credData?.Data?.find(credential => credential.Vinculo === vinc);
      context.commit("SET_PROP", {
        payload: loggedUserCredential?.BeneficiarioPlanoMedicina > 0,
        prop: "hasMedicCredentials"
      });
      context.commit("SET_PROP", {
        payload: loggedUserCredential?.BeneficiarioPlanoOdonto > 0,
        prop: "hasOdontoCredentials"
      });

      context.dispatch("checkCredentialAppVersion");
    },
    checkCredentialAppVersion: async context => {
      if (context.rootGetters["biometrics/onNative"]) {
        // aguardar definição de versão do app preparada para a alteração das credenciais
        if (Number.parseInt(localStorage.getItem("native_version") || "0") < 999) {
          let credenciais = context.state.credentials.map(c => {
            if (c.bg) {
              return c;
            } else {
              let copy = {};
              for (var attr in c) {
                copy[attr] = c[attr];
              }
              copy.bg = context.state.credentials[c.posBg].bg;
              return copy;
            }
          });
          while (credenciais !== null && credenciais.length > 0 && !setStorageData("credentials", credenciais)) {
            credenciais.pop();
          }
        }
      }
    },
    async fetchCredentialsLinks(context, id) {
      if (!id) return;
      const credenciais = context.state.credentials;
      const c = credenciais.find(c => c.id === id);
      if (c.shareUrl && c.urlWallet)
        return {
          shareUrl: c.shareUrl,
          urlWallet: c.urlWallet
        };
      try {
        const n = c.number.split(" ");
        const vinculo = `${n[0]}${n[1]}`;
        const tipoCredencial = c.id.substring(0, 1).toUpperCase();
        c.urlWallet = await api.GerarUrlWallet({ vinculo, tipoCredencial });
        const chave = await associado.GerarCredencialEletronicaCompartilhada({
          vinculo,
          tipoCredencial
        });
        c.shareUrl = chave.Data;
        context.commit("SET_PROP", { payload: credenciais, prop: "credentials" });
        return {
          shareUrl: c.shareUrl,
          urlWallet: c.urlWallet
        };
      } catch (error) {
        return {
          shareUrl: c.shareUrl,
          urlWallet: c.urlWallet
        };
      }
    },
    setBigFontSize: async (context, bigFontSize) => {
      context.commit("SET_PROP", { payload: bigFontSize, prop: "bigFontSize" });
    },
    uploadFile: async (context, { filename, blobFile }) => {
      return await atendimento.FileUpload({
        vinculo: context.state.vinculo,
        filename,
        blobFile
      });
    },
    changeProfilePick: async (context, { blobFile }) => {
      await minhaConta.AlterarImagemProfile({
        vinculo: context.state.vinculo,
        filename: "tests/logo-omint.jpg",
        blobFile
      });
    },
    async registerAlertNotification(context, { classificacao, tipoAviso, chamadoReferenciado }) {
      const registro = await atendimento.RegistroCienteAviso({
        vinculo: context.state.vinculo,
        classificacao,
        tipoAviso,
        chamadoReferenciado
      });
      if (registro && registro.Data) {
        await context.dispatch("fetchProfileMessages");
      }
    },
    async forceSWupdate(context) {
      if (!navigator.onLine) {
        return false;
      }
      //verifica se mudou a versão do pwa
      var pwaVersion = await api.PWAVersion();
      if (!pwaVersion || !pwaVersion.Data || pwaVersion.Data === context.state.pwaVersion) {
        return false;
      }
      context.commit("SET_PROP", { payload: pwaVersion.Data, prop: "pwaVersion" });
      setStorageData("pwaVersion", pwaVersion.Data);
      return true;
    },
    async testSession(context) {
      try {
        const situacao = await minhaConta.SituacaoSessao({
          vinculo: context.state.vinculo
        });
        return situacao?.Data ?? true;
      } catch {
        return true;
      }
    },
    async removeUserAccount(context) {
      const response = await api.RemoveAccount({ vinculo: context.state.vinculo });
      return !(response instanceof Error);
    },
    fetchFavoriteMenus: async (context, { retornarAtalhos }) => {
      const vinculo = context.state.vinculo;

      const favoritos = await api.ObterMenusFavoritos({ vinculo, retornarAtalhos });
      if (favoritos?.length) {
        favoritos.forEach(a => {
          if (a.View) {
            a.View = a.View.replace("views/restrito", "").replace(".html", "");
          }
        });
      }
      if (retornarAtalhos) {
        context.commit("SET_PROP", { payload: favoritos, prop: "menusFavoritos" });
      }
      return favoritos;
    },
    saveFavoriteMenus: async (context, { menus }) => {
      const vinculo = context.state.vinculo;
      return await api.GravarMenusFavoritos({ vinculo, menus });
    },
    getOnboardOptin: async (context, { template }) => {
      if (!template || template === "0") return;
      return await minhaConta.ObtemOptinPendente({
        vinculo: context.state.vinculo,
        template,
        sistema: "PWA OMINT ONBOARD"
      });
    },
    saveClosedOnboard: async (context, { onboard, sendToServer, template }) => {
      context.commit("PUSH_CLOSE_ONBOARD", onboard);

      if (sendToServer) {
        const vinculo = context.state.vinculo;
        const login = context.state.username;

        try {
          await associado.AceitaOptin({
            vinculo,
            template,
            login
          });
        } catch (error) {
          throw new Error(error);
        }
      }
    }
  }
};
