<template>
  <div class="doctor-item-card" @click="$listeners.click">
    <div class="doctor-item-flex mb-24">
      <div class="doctor-item-box doctor-item-icon">
        <FontAwesomeIcon icon="fa-light fa-user-doctor" size="xs" color="#4E4E4E" />
      </div>

      <div class="doctor-item-box width-80">
        <p class="doctor-item-h1">{{ DescricaoPrestador }}</p>
        <p class="doctor-item-p overflow-ellipsis">
          {{ especialidades }}
        </p>

        <div class="doctor-item-flex items-center inlined-details">
          <div class="doctor-item-box inline-flex align-center" v-if="getNotaFormatada" v-show="false">
            <FontAwesomeIcon icon="fa-sharp fa-star" size="xl" color="var(--colors-light-support-warning)" />
            <p class="doctor-item-h3 doctor-item-warning">{{ getNotaFormatada }}</p>
          </div>

          <template v-if="getDistanciaFormatada">
            <div class="mx-3 align-center" v-show="false">
              <p class="doctor-item-h3">
                <b>•</b>
              </p>
            </div>

            <div class="doctor-item-box inline-flex align-center">
              <p class="doctor-item-h3">{{ getDistanciaFormatada }} km</p>
            </div>
          </template>

          <div class="doctor-item-box">
            <span v-if="Network && Distancia !== undefined && !Number.isNaN(Distancia)" class="hifen"> - </span>
            <span v-if="Network" class="network">{{ Network }}</span>
          </div>

          <template v-if="WhatsApp">
            <div class="mx-3 align-center">
              <p class="doctor-item-h3">
                <b>•</b>
              </p>
            </div>

            <div class="doctor-item-box inline-flex align-center">
              <button class="doctor-item-button">
                <FontAwesomeIcon icon="fa-brands fa-whatsapp" color="var(--colors-light-support-success)" size="xl" />
              </button>
            </div>
          </template>

          <template v-if="Teleconsulta">
            <div class="mx-3 align-center">
              <p class="doctor-item-h3">
                <b>•</b>
              </p>
            </div>

            <div class="doctor-item-box inline-flex align-center">
              <FontAwesomeIcon icon="fa-light fa-video" size="xl" />
            </div>
          </template>
          <template v-if="Agendamento">
            <div class="mx-3 align-center">
              <p class="doctor-item-h3">
                <b>•</b>
              </p>
            </div>

            <div class="doctor-item-box inline-flex align-center">
              <FontAwesomeIcon icon="fa-light fa-calendar" size="xl" />
            </div>
          </template>
        </div>
        <div class="width-100">
          <p class="doctor-item-p">
            {{ TipoLogradouro ? TipoLogradouro : "" }} {{ EnderecoRua }}, {{ EnderecoNumero }} - {{ EnderecoBairro }} -
            {{ EnderecoCidade }} / {{ CodigoEstado }}
          </p>
        </div>
      </div>

      <div class="doctor-item-box ml-auto">
        <FontAwesomeIcon icon="fa-light fa-angle-right" size="xl" />
      </div>
    </div>
    <OmtDivider mb="24px" />
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  props: [
    "TipoLogradouro",
    "EnderecoRua",
    "EnderecoNumero",
    "EnderecoBairro",
    "EnderecoCidade",
    "CodigoEstado",
    "DescricaoPrestador",
    "DescricaoEspecialidade",
    "Distancia",
    "Network",
    "Favorito",
    "CodigoPrestador",
    "SrEndereco",
    "DsIdioma",
    "Nota",
    "WhatsApp",
    "Teleconsulta",
    "Agendamento"
  ],
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      favorite: this.Favorito
    };
  },
  computed: {
    getDistanciaFormatada() {
      return this.Distancia ? `${Math.round(this.Distancia * 100) / 100}`.replaceAll(".", ",") : "";
    },
    getNotaFormatada() {
      return this.Nota ? `${Math.round(this.Nota * 10) / 10}`.replaceAll(".", ",") : "";
    },
    especialidades() {
      if (!this.DescricaoEspecialidade) return [];
      return this.DescricaoEspecialidade.replaceAll("|", " • ");
    }
  }
};
</script>

<style scoped lang="scss">
.mx-3 {
  margin: 0 0.75rem;
}

.ml-auto {
  margin-left: auto;
}

.overflow-ellipsis {
  width: calc(100vw - 120px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 16px;
}

$breakpoints: (
  "1024px": 650px,
  "1150px": 750px,
  "1300px": 880px,
  "1400px": 920px
);

@each $min-width, $width in $breakpoints {
  @media only screen and (min-width: $min-width) {
    .overflow-ellipsis {
      width: $width;
    }
  }
}

.doctor-item-card {
  cursor: pointer;
}

.doctor-item-flex {
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  .items-center {
    align-items: center;
  }
}

.inlined-details {
  flex-wrap: wrap;
  gap: 8px;
}

.doctor-item-box {
  margin-right: 4px;

  .width-80 {
    width: 80%;
    margin-right: 2px;
  }

  .inline-flex {
    display: inline-flex;
    align-items: center;
  }
}

.doctor-item-button {
  height: auto;
  padding: 0;
  min-width: auto;
  background-color: transparent;
  color: var(--colors-light-primary-gray1);
}

.doctor-item-icon {
  font-size: 2rem;
  padding: 8px;
  background-color: var(--colors-light-primary-light-gray);
  border-radius: 8px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.doctor-item-h1 {
  font-family: "Open Sans", Roboto, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: var(--colors-light-primary-gray1);
  margin-bottom: 8px;
}

.doctor-item-p {
  font-family: "Open Sans", Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colors-light-primary-gray1);
}

.doctor-item-h3 {
  font-family: "Open Sans", Roboto, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  color: var(--colors-light-primary-gray1);
}

.doctor-item-warning {
  margin-left: 8px;
  color: var(--colors-light-support-warning);
}
</style>
