export async function importScript(
  document: Document,
  src: string,
  newElementCall: Function | undefined
): Promise<HTMLScriptElement> {
  let scriptElement = Array.from(document.scripts).find(s => s.src?.endsWith(src));
  if (scriptElement) return scriptElement;

  scriptElement = document.createElement("script");
  scriptElement.setAttribute("src", src);
  if (newElementCall) newElementCall(scriptElement);
  await document.head.appendChild(scriptElement);
  return scriptElement;
}

export async function importModuleScript(document: Document, src: string, body: string): Promise<HTMLScriptElement> {
  let scriptElement = Array.from(document.scripts).find(s => s.src?.endsWith(src));
  if (!scriptElement) {
    scriptElement = document.createElement("script");
    scriptElement.setAttribute("type", "module");
    scriptElement.setAttribute("src", src);
    await document.head.appendChild(scriptElement);
  }
  if (body) {
    const scriptElementImport = document.createElement("script");
    scriptElementImport.setAttribute("type", "module");
    scriptElementImport.appendChild(document.createTextNode(body));
    const child = await document.head.appendChild(scriptElementImport);
    await document.head.removeChild(child);
  }
  return scriptElement;
}

export async function importStyle(document: Document, href: string): Promise<CSSStyleSheet> {
  const styleSheet = Array.from(document.styleSheets).find(s => s.href?.endsWith(href));
  if (styleSheet) return styleSheet;

  const styleElement = document.createElement("link");
  styleElement.setAttribute("href", href);
  styleElement.setAttribute("rel", "stylesheet");
  await document.head.appendChild(styleElement);
  return Array.from(document.styleSheets).find(s => s.href?.endsWith(href));
}
