import store from "@/store";

function afterEachRouteGuard(to, from) {
  const name = "/" + to.name;
  const url = location.protocol + "//" + location.host + to.fullPath;
  if (window.$appInsights) {
    window.$appInsights.stopTrackPage(name, url);
    window.$appInsights.flush();
  }
  if (from?.name === null) {
    store.dispatch("saude/appLoaded");
  }
}

export default afterEachRouteGuard;
