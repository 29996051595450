<template>
  <div>
    <Header name="Indicação de Rede" titleLabel="Indicação de Rede" backRoute="/indicacao-de-credenciados" />
    <omint-page-content v-if="pedido">
      <CBox mb="4">
        <omt-h1> <omt-uppercase>Resultado da indicação</omt-uppercase> </omt-h1>
      </CBox>

      <BlockList v-chakra p="4" mb="4">
        <CBox position="relative">
          <div class="dots-vertical"></div>
          <AffiliateIndicationTile
            v-chakra
            class="tile"
            mb="3"
            icon="fas fa-user"
            :title="pedido.DescricaoPedido"
            :description="`${pedido.DataPedido} - ${pedido.HoraPedido} - Protocolo: ${pedido.Protocolo}`"
          />
        </CBox>
        <AffiliateIndicationTile icon="fas fa-lightbulb" title="Nossa indicação" :description="descricaoIndicacao" />
        <CBox v-if="indicacao.Observacao">
          <omt-divider />
          <CBox pl="6">
            <omt-p>
              {{ indicacao.Observacao }}
            </omt-p>
          </CBox>
        </CBox>
      </BlockList>

      <CBox v-if="credenciados.length" mb="4">
        <omt-bold> Credenciados indicados </omt-bold>
      </CBox>

      <CBox v-if="credenciados.length" mb="4">
        <BlockList
          v-for="credenciado in credenciados"
          :key="credenciado.CodigoPrestador"
          class="hover-effect"
          @click="handleClick(credenciado)"
        >
          <AffiliateIndicationTile
            v-chakra
            p="4"
            :hasCountainer="true"
            icon="fas fa-user"
            :title="credenciado.DescricaoPrestador"
            :leadIcons="[credenciado.Favorito ? 'fas fa-heart' : 'far fa-heart']"
          />

          <CBox pl="48px">
            <CFlex align="baseline" v-if="credenciado.Distancia">
              <i class="fas fa-map-marker-alt fa-lg" v-chakra mr="3" />
              <omt-p>{{ Math.round(credenciado.Distancia * 100) / 100 }} km</omt-p>
            </CFlex>
            <omt-p>
              {{ credenciado.TipoLogradouro }} {{ credenciado.EnderecoRua }}, {{ credenciado.EnderecoNumero }} <br />
              {{ credenciado.EnderecoBairro }} - {{ credenciado.CodigoEstado }}
            </omt-p>
          </CBox>
          <omt-divider top="0" bottom="0" />
        </BlockList>
      </CBox>

      <RatingForm
        v-chakra
        pb="32px"
        v-if="recommendationDetail.NotaAvaliacao === null"
        tipo="10"
        etapa="P"
        :protocolo="pedido.Protocolo"
        @ratingSubmitClick="onNewRating"
      />
    </omint-page-content>
    <Footer />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";
import BlockList from "@/components/Molecules/BlockList";
import RatingForm from "@/components/Organisms/RatingForm";
import AffiliateIndicationTile from "@/components/Organisms/AffiliateIndication/Tile";
import { CList, CListItem, CBox, CFlex } from "@chakra-ui/vue";

export default {
  name: "AffiliateIndicationVisualizeResults",
  components: { Footer, Header, AffiliateIndicationTile, CList, CListItem, CBox, CFlex, BlockList, RatingForm },
  computed: {
    ...mapState("affiliate", ["recommendationDetail"]),
    chamado() {
      return this.$route.params.chamado;
    },
    indicacao() {
      return this.recommendationDetail?.NossaIndicacao ?? {};
    },
    descricaoIndicacao() {
      return this.indicacao?.DataIndicacao ? `${this.indicacao.DataIndicacao} - ${this.indicacao.HoraIndicacao}` : "";
    },
    pedido() {
      return this.recommendationDetail?.SeuPedido ?? {};
    },
    credenciados() {
      return (
        this.recommendationDetail?.CredenciadosIndicados?.sort((a, b) => {
          if (a.Distancia < b.Distancia) return -1;
          if (a.Distancia > b.Distancia) return 1;
          return 0;
        }) ?? []
      );
    }
  },
  methods: {
    ...mapMutations("affiliate", ["SET_MATCH"]),
    ...mapActions("affiliate", ["fetchRecommendationDetail"]),
    async fetchResultDetail(force) {
      try {
        await this.$root.$emit("show-loading");
        await this.fetchRecommendationDetail({
          ticket: this.chamado,
          force
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    async handleClick(match) {
      await this.SET_MATCH(match);
      await this.$router.push({
        name: "indication.accredited"
      });
    },
    async onNewRating() {
      await this.fetchResultDetail(true);
    }
  },
  async mounted() {
    if (!this.chamado) {
      await this.$router.replace("/indicacao-de-credenciados");
      return;
    }
    await this.fetchResultDetail(false);
    if (!this.pedido.Protocolo) {
      await this.$router.replace("/indicacao-de-credenciados");
      return;
    }
  }
};
</script>

<style lang="scss" scoped>
.block-list.block:first-of-type {
  margin-top: 0 !important;
}

.indications-list {
  max-height: calc(100vh - 400px);
  overflow-y: scroll;
}
.dots-vertical {
  position: absolute;
  height: calc(100% - 1rem);
  top: 0;
  left: 0;
  margin: 24px 5px;
  z-index: 0;
  border-left: 4px dotted var(--colors-gray-500);
  opacity: 0.25;
}

.big-font-size .dots-vertical {
  margin-left: 8px;
}

.hover-effect {
  transition: opacity 0.5s ease;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
}
</style>
