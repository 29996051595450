<template>
  <OmtCard p="5">
    <CFlex cursor="pointer" @click="handleClick" align-items="center">
      <StatusTile :title="serviceDetail.Evento" :label="serviceDetail.TotalEvento" />
      <OmtIcons
        ml="auto"
        name="chevron-right"
        :style="`transition: all .5s ease; transform: rotate(${isOpen ? '90deg' : '0deg'})`"
      />
    </CFlex>

    <CCollapse :isOpen="isOpen">
      <CBox mt="-15px" pt="16px" v-for="(serviceDetails, index) in serviceDetail.Detalhes" :key="index">
        <CDivider mt="14px" mb="16px" />
        <template>
          <CFlex>
            <CBox d="flex">
              <FontAwesomeIcon
                icon="fa-light fa-square-poll-horizontal"
                size="lg"
                color="var(--colors-light-primary-gray1)"
                class="custom-ico"
              />
            </CBox>
            <CBox w="100%">
              <CFlex :align-items="['unset', 'center']" :flex-direction="['column', 'row']">
                <CBox :w="['100%', '85%']">
                  <OmtTypography as="h3-bold">{{ serviceDetails.Prestador }}</OmtTypography>
                </CBox>
                <CBox :ml="['unset', 'auto']" :mt="['8px', '0']">
                  <OmtTypography ml="auto" as="p2" color="var(--colors-light-primary-gray2)">{{
                    serviceDetails.Data
                  }}</OmtTypography>
                </CBox>
              </CFlex>
              <CBox mt="12px">
                <OmtTypography as="p2">{{ serviceDetails.Procedimento }}</OmtTypography>
              </CBox>
            </CBox>
          </CFlex>
        </template>
      </CBox>
    </CCollapse>
  </OmtCard>
</template>

<script>
import TextTile from "@/components/Molecules/TextTile";
import { StatusTile } from "./shared";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  components: {
    TextTile,
    StatusTile,
    FontAwesomeIcon
  },
  props: {
    serviceDetail: Object,
    isCollapsed: Boolean
  },
  data() {
    return {
      isOpen: false
    };
  },
  mounted() {
    this.isOpen = !this.isCollapsed;
  },
  methods: {
    handleClick() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style scoped>
.custom-ico {
  margin-right: 8px;
}
</style>
