<template>
  <OmtStickedModal v-if="isOpen" @close="close">
    <OmtTypography as="h1-bold" mb="24px" mx="auto" textAlign="center">
      Termo de aceite para acesso às minhas informações pessoais
    </OmtTypography>

    <OmtTypography as="p1" mb="24px">
      É importante saber que, ao clicar em <b>Salvar</b>, você está autorizando o(s) Beneficiário(s) selecionado(s) a
      visualizarem suas informações pessoais, nos serviços assinalados, através dos seguintes canais de atendimento:
    </OmtTypography>
    <CList spacing="16px" mb="24px">
      <CListItem display="flex">
        <CListIcon icon="chevron-right" />
        <OmtTypography :as="['p1', 'p4']"> Web, pelo canal exclusivo <b>Minha Omint</b>. </OmtTypography>
      </CListItem>

      <CListItem display="flex">
        <CListIcon icon="chevron-right" />
        <OmtTypography :as="['p1', 'p4']"> Telefone, pela Central de Atendimento 24 horas. </OmtTypography>
      </CListItem>
    </CList>
    <CBox>
      <OmtCheckbox @change="() => (aceito = !aceito)">
        <OmtTypography as="p2" pl="15px">
          Li e concordo com os termos e condições do <b>Termo de Aceite.</b>
        </OmtTypography>
      </OmtCheckbox>
    </CBox>

    <template #footer>
      <OmtButton w="100%" :disabled="!aceito" @click="continueClick"> Continuar </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
/**
 * @prop {Boolean} isOpen
 */
export default {
  props: {
    isOpen: {
      Type: Boolean
    }
  },
  data() {
    return {
      aceito: false
    };
  },
  methods: {
    close() {
      this.$emit("update:isOpen", false);
    },
    continueClick() {
      this.$emit("continue");
      this.close();
    }
  }
};
</script>
