<template>
  <CBox class="omt-radio-group" v-bind="$attrs">
    <CRadioGroup v-model="computedValue" v-on="$listeners">
      <CRadio
        v-for="(radio, index) in values"
        :value="radio[customValueKey]"
        :key="index"
        :mt="index !== 0 ? '8px' : ''"
        :mb="index !== values.length - 1 ? '8px' : ''"
        :class="{ divider: hasDivider && index !== values.length - 1 }"
        class="omt-radio"
      >
        <OmtTypography as="h3-semi-bold">
          {{ radio.label }}
        </OmtTypography>
      </CRadio>
    </CRadioGroup>
  </CBox>
</template>

<script>
/**
 * @prop {Boolean} hasDivider show OmtDivider between the options
 * @prop {{ value: string, label: string }} values key and value for each Radio
 */
export default {
  props: {
    value: String,
    hasDivider: Boolean,
    customValueKey: {
      type: String,
      default: "value"
    },
    values: Array
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("update:value", newValue);
      }
    }
  }
};
</script>

<style>
.omt-radio-group div[data-chakra-component="CBox"] {
  position: relative;
}

.omt-radio-group .divider::after {
  content: "";
  position: absolute;
  width: calc(100% - 24px);
  height: 1px;
  background: var(--colors-light-secondary-divider);
  opacity: 0.5;
  left: 24px;
  bottom: -3px;
}

.omt-radio-group .omt-radio input[type="radio"]:checked + div {
  background: white;
}

.omt-radio-group .omt-radio input[type="radio"]:checked + div span {
  background: var(--colors-light-primary-gray1);
  height: 57%;
  width: 57%;
}

.omt-radio-group .omt-radio input[type="radio"] + div {
  background: white;
  border-color: var(--colors-light-primary-gray1);
}
</style>
