<template>
  <omint-page>
    <Header name="Exclusão de conta" titleLabel="Exclusão de conta" backRoute="/" />
    <omint-page-content>
      <omt-h1 class="title">Detalhes da Exclusão</omt-h1>

      <BlockList>
        <div class="block-box">
          <h2 class="subtitle">Dados do Associado</h2>
          <div class="info-row">
            <i class="fas fa-user"></i>
            <omt-p>{{ profileName }}</omt-p>
          </div>
          <div class="buttons-box">
            <omt-button :onClick="onExclude">Excluir</omt-button>
          </div>
        </div>
      </BlockList>

      <BlockList>
        <div class="hint-box">
          <div class="info-box">
            <i class="fas fa-lightbulb"></i>
            <h3 class="dicas">Dicas</h3>
          </div>
          <ul>
            <omt-li>Após o cancelamento você perderá o acesso ao app.</omt-li>
            <omt-li>Para criar uma nova conta acesse “Primeiro Acesso”.</omt-li>
          </ul>
        </div>
      </BlockList>
    </omint-page-content>

    <Modal :is-open="showConfirmationModal" @cancel="this.onCancel" @confirm="this.onConfirmation" />

    <Footer />
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";
import BlockList from "@/components/Molecules/BlockList";
import Modal from "@/components/Organisms/AccountRemovalModal";
import Footer from "@/components/Molecules/Footer";
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  components: { Header, BlockList, Modal, Footer },
  name: "AccountRemoval",
  computed: {
    ...mapState("saude", ["profileName"])
  },
  data() {
    return {
      showConfirmationModal: false
    };
  },
  methods: {
    ...mapActions("saude", ["removeUserAccount"]),
    async onConfirmation() {
      await this.$root.$emit("show-loading");
      this.showConfirmationModal = false;
      const sucess = await this.removeUserAccount();
      if (sucess) {
        await this.$router.replace("/logout");
      } else {
        await this.$root.$emit("hide-loading");
        await Swal.fire({
          title: "Erro",
          text: "Não foi possivel remover a conta.\nServiço temporariamente indisponível.",
          confirmButtonText: "OK",
          icon: "error"
        });
      }
    },
    onCancel() {
      this.showConfirmationModal = false;
    },
    onExclude() {
      this.showConfirmationModal = true;
    }
  },
  mounted() {
    this.$root.$emit("hide-loading");
  }
};
</script>

<style scoped lang="scss">
.block-list + .block-list {
  margin-top: 16px;
}
.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 24px;
}
.subtitle {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 16px;
}
.dicas {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 0px;
}
.block-box {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 5px 20px 0px 20px;
  i {
    padding-top: 5px;
    padding-right: 10px;
  }
  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin: 0px;
    padding: 0px;
  }
  .button-box {
    width: 100%;
  }
  .buttons-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    button {
      margin: 16px 10px 20px 10px;
      min-width: 40%;
      max-height: 42px;
      min-height: 42px;
    }
  }
  .info-box,
  .info-row {
    display: flex;
    justify-content: flex-start;
  }
  .info-row {
    justify-content: center;
    margin: 50px 0;
  }
  .info-box {
    flex-wrap: nowrap;
    flex-direction: row;
  }
}
.big-font-size {
  .title {
    font-size: 20px;
    line-height: 30px;
  }
  .subtitle {
    font-size: 20px;
  }
  .dicas {
    font-size: 18px;
  }
  .block-box {
    p {
      font-size: 18px;
    }
    ul {
      font-size: 15px;
    }
    button {
      min-width: 90%;
      max-height: inherit;
      min-height: inherit;
    }
  }
}
.light {
  .block-box {
    button {
      border: 1px solid #68788c;
    }
  }
  .title {
    color: var(--colors-light-primary-gray1);
  }
  .subtitle,
  .dicas {
    color: var(--colors-light-primary-gray1);
  }
}
.dark {
  .block-box {
    button {
      border: 1px solid #f0f0f0;
    }
  }
  .title {
    color: #f0f0f0;
  }
  .subtitle,
  .dicas {
    color: #f0f0f0;
  }
}
.hint-box {
  display: grid;
  grid-template-rows: 20px 1fr;
  padding: 16px 20px;
  grid-row-gap: 16px;

  .info-box {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .info-box h3 {
    margin: 0;
  }

  ul {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    padding: 0 0 0 20px;
  }
}
</style>
