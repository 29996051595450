<template>
  <CFlex>
    <CBox>
      <OmtIcons
        :name="isValid ? 'check' : 'xmark'"
        size="lg"
        mr="16px"
        :color="isValid ? 'var(--colors-light-support-success)' : 'var(--colors-light-support-error)'"
      />
    </CBox>
    <CBox>
      <OmtTypography color="var(--colors-light-primary-gray2)" as="p1">{{ rule }}</OmtTypography>
    </CBox>
  </CFlex>
</template>

<script>
export default {
  name: "ValitationRuleItem",
  props: ["isValid", "rule"]
};
</script>
