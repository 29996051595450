import SafeLetterIndex from "@/pages/safe-letter/index.vue";
import SafeLetterList from "@/pages/safe-letter/list.vue";
import SafeLetterCreate from "@/pages/safe-letter/create.vue";
import SafeLetterSuccess from "@/pages/safe-letter/success.vue";

const routes: Route[] = [
  {
    path: "/carta-seguro-viagem",
    component: SafeLetterIndex,
    children: [
      {
        path: "",
        name: "safe-letter.list",
        component: SafeLetterList,
        meta: {
          backTo: "/",
          validPath: ["/carta-seguro-viagem"]
        }
      },
      {
        path: "create/:etapa",
        name: "safe-letter.create",
        component: SafeLetterCreate,
        meta: {
          validPath: ["/carta-seguro-viagem"]
        }
      },
      {
        path: "success",
        name: "safe-letter.success",
        component: SafeLetterSuccess,
        meta: {
          backTo: "/carta-seguro-viagem",
          validPath: ["/carta-seguro-viagem"]
        }
      }
    ]
  }
];

export default routes;
