<template>
  <CBox>
    <Header name="Detalhes do atendimento" titleLabel="Detalhes do atendimento" backRoute="/avaliacao-do-prestador" />
    <omint-page-content v-if="!loading">
      <CBox mb="24px">
        <AttendanceCard :evaluation="dadosAvaliacao"> </AttendanceCard>
      </CBox>

      <CBox mb="24px" :class="theme + ' avaliacao'" v-if="!!dadosAvaliacao.Avaliacao">
        <CFlex justify="space-between" align="center">
          <omt-h5> <strong>Avaliação</strong> </omt-h5>
          <star-rating
            v-chakra
            pt="2"
            v-model="dadosAvaliacao.Avaliacao"
            :inactive-color="theme == 'dark' ? '#ffffff' : '#BCBDC0'"
            :active-color="theme == 'dark' ? '#69788f' : '#102A46'"
            rounded-corners
            :star-size="16"
            :padding="20"
            text-class="hidden-item"
            :read-only="true"
          />
        </CFlex>
      </CBox>

      <CBox v-if="!!dadosAvaliacao.Observacao">
        <CFlex mb="16px" align="baseline">
          <i class="far fa-comment-alt fa-lg" v-chakra mr="16px"></i>
          <omt-h5>
            <strong v-if="!!dadosAvaliacao.RotuloTextoLivre">{{ dadosAvaliacao.RotuloTextoLivre }}</strong>
            <strong v-else>Comentário</strong>
          </omt-h5>
        </CFlex>
        <CFlex align="end" direction="column">
          <omt-textarea
            v-chakra
            borderColor="#ccc !important"
            p="16px !important"
            max-width="calc(100% - 36px)"
            v-bind:value="dadosAvaliacao.Observacao"
            rows="6"
            maxlength="250"
            :readonly="true"
          />
          <span>
            <omt-p> {{ dadosAvaliacao.Observacao.length }} /250 </omt-p>
          </span>
        </CFlex>
      </CBox>
    </omint-page-content>
  </CBox>
</template>

<script>
import StarRating from "vue-star-rating";
import Header from "@/components/Molecules/Header";
import AttendanceCard from "@/components/Organisms/ProviderEvaluation/AttendanceCard.vue";
import { CBox, CFlex } from "@chakra-ui/vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "ProviderEvaluationResume",
  components: { CBox, AttendanceCard, CFlex, Header, StarRating },
  data() {
    return {
      loading: true,
      dadosAvaliacao: {}
    };
  },
  async mounted() {
    try {
      await this.$root.$emit("show-loading");
      if (!this.evaluationList?.length) {
        await this.fetchAtendimentosAvaliacao();
      }
      if (this.evaluationList?.atendimentosAvaliados?.length && this.idAtendimento) {
        this.dadosAvaliacao = this.evaluationList.atendimentosAvaliados.find(
          a => a.idAtendimento === this.idAtendimento
        );
      }
      if (this.dadosAvaliacao?.TipoAtendimento && this.idAtendimento) {
        const detalhesResposta = await this.fetchResponsesExpRating({
          ticket: this.idAtendimento,
          type: this.dadosAvaliacao.TipoAtendimento,
          stage: "P"
        });
        if (this.dadosAvaliacao.TipoAtendimento === "600") {
          this.dadosAvaliacao.Respostas = detalhesResposta.Respostas;
        } else {
          this.dadosAvaliacao = {
            TipoAtendimento: this.dadosAvaliacao.TipoAtendimento,
            idAtendimento: this.dadosAvaliacao.idAtendimento,
            NomePrestador: this.dadosAvaliacao.NomePrestador,
            Especialidade: this.dadosAvaliacao.Especialidade,
            DataAtendimento: this.dadosAvaliacao.DataAtendimento,
            HoraAtendimento: this.dadosAvaliacao.HoraAtendimento,
            Avaliacao: detalhesResposta.Nota,
            Observacao: detalhesResposta.Observacao,
            ConfirmaAtendimento: this.dadosAvaliacao.ConfirmaAtendimento,
            Respondido: this.dadosAvaliacao.Respondido,
            RotuloTextoLivre: detalhesResposta.RotuloTextoLivre,
            Respostas: detalhesResposta.Respostas
          };
        }
      }
    } finally {
      if (this.dadosAvaliacao?.idAtendimento === this.idAtendimento) {
        this.loading = false;
        await this.$root.$emit("hide-loading");
      } else {
        this.$router.push("/avaliacao-do-prestador");
      }
    }
  },
  computed: {
    ...mapState("saude", ["theme"]),
    ...mapState("atendimento", ["evaluationList"]),
    idAtendimento() {
      return this.$route.params.id ?? "0";
    }
  },
  methods: {
    ...mapActions("atendimento", ["fetchAtendimentosAvaliacao"]),
    ...mapActions("ticket", ["fetchResponsesExpRating"])
  }
};
</script>

<style lang="scss" scoped>
.button {
  border: 1px solid #6f8092 !important;
  width: 50%;

  &:hover {
    opacity: 0.6;
  }

  & + & {
    margin-left: 24px;
  }
}

.avaliacao {
  &.light {
    background-color: #fff;
  }
  padding: 16px;
}
</style>
