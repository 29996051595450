<template>
  <OmtLayoutContainer>
    <OmtRow mb="24px">
      <OmtTypography as="h1-bold">
        <span v-html="terms.title" />
      </OmtTypography>
    </OmtRow>

    <OmtRow mb="24px">
      <OmtTypography as="p1" class="text-terms">
        <span v-html="terms.text" />
      </OmtTypography>
    </OmtRow>

    <OmtRow mb="24px">
      <OmtCheckbox @change="optin = !optin" :is-checked="optin">
        <OmtTypography as="p1" ml="16px">
          Confirmo que concordo com os <b>Termos e Condições</b> descritos acima.
        </OmtTypography>
      </OmtCheckbox>
    </OmtRow>

    <FixedButtonRow>
      <OmtButton :w="['100%', '308px !important']" @click="continuar" :disabled="!optin">
        Concordar e continuar
      </OmtButton>
    </FixedButtonRow>
  </OmtLayoutContainer>
</template>

<script>
import { mapState, mapActions } from "vuex";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

export default {
  components: { FixedButtonRow },
  data() {
    return {
      optin: false
    };
  },
  computed: {
    ...mapState("copay", ["terms"])
  },
  methods: {
    ...mapActions("copay", ["fetchTermsCopay", "acceptTermsCopay"]),
    continuar() {
      this.acceptTermsCopay();
      this.$router.push({ path: "/copay/simulations/create/exams" });
    }
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      await this.fetchTermsCopay();
    } catch (error) {
      console.error("copay terms load error", error);
    } finally {
      if (!this.terms.text) {
        this.$router.push({ path: "/copay/simulations" });
      }
      this.$root.$emit("hide-loading");
    }
  }
};
</script>

<style>
.text-terms span,
ul {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.text-terms ul {
  margin-left: 26px;
}
</style>
