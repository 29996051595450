<template>
  <CBox data-cy="biometry-camera-button">
    <resouce-modal v-if="shouldRequestResources" :useMic="false" />
    <OmtButton
      :disabled="disableButton"
      v-bind="{
        ...{
          variant: 'solid',
          width: '100%',
          ...$attrs
        }
      }"
      @click="handleStart"
    >
      {{ buttonLabel }}
    </OmtButton>
  </CBox>
</template>

<script>
/**
 * @prop {String} type currently options: "document" | "selfie"
 * @prop {String} buttonLabel
 * @event { {base64: String, encrypted: String} } success
 * @event {Error} error
 * @event {Object} support
 * @event null startLoading
 * @event null endLoading
 */

import unicoCameraBuilder from "./builder";
import { mapActions, mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ResouceModal from "@/components/Organisms/DrOmint/ResourceModal";

const camerasTypes = {
  document: "prepareDocumentCamera",
  selfie: "prepareSelfieCamera"
};

export default {
  mixins: [unicoCameraBuilder],
  components: { ResouceModal },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    confirmUser: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      validator: value => {
        const options = Object.keys(camerasTypes);
        const validOption = !!options.includes(value);
        if (!validOption) console.error(`Allowed camera options : ${options.join(" - ")}`);
        return validOption;
      },
      default: "selfie"
    },
    buttonLabel: {
      type: String,
      default: "Começar"
    }
  },
  data() {
    return {
      loading: 0,
      initialized: false,
      cameraLiberada: true,
      cameraUnico: null,
      initUnicoError: null,
      hasUserConfirmed: false
    };
  },
  computed: {
    ...mapState("biometrics", ["camera", "microfone"]),
    ...mapState("saude", ["credentialName"]),
    ...mapGetters("biometrics", ["onNative"]),
    v25() {
      return Number.parseInt(localStorage.getItem("native_version")) >= 25;
    },
    shouldRequestResources() {
      if (!this.v25 || !this.onNative) return !this.cameraLiberada;
      return this.v25 && this.onNative && this.camera !== "granted";
    },
    disableButton() {
      if (this.disabled) return true;
      if (this.initUnicoError) return true;
      if (!this.cameraUnico) return true;
      return this.shouldRequestResources;
    }
  },
  watch: {
    disabled() {
      this.initResources();
    },
    initialized(value) {
      if (value) this.loadingModal(false);
    },
    shouldRequestResources(value) {
      if (value) this.loadingModal(false);
    }
  },
  async created() {
    await this.initResources();
  },
  methods: {
    ...mapActions("biometrics", ["checkPermissionForResource"]),
    async loadingModal(show) {
      if (show) {
        this.loading++;
        if (this.loading === 1) await this.$root.$emit("show-loading");
      } else {
        this.loading--;
        if (this.loading === 0) await this.$root.$emit("hide-loading");
      }
    },
    async initResources() {
      if (this.initialized) return;
      if (this.disabled) return;
      await this.loadingModal(true);
      if (this.onNative) {
        this.checkPermissionForResource("camera");
      } else {
        await this.checkResources();
      }
      this.initialized = true;
    },
    async checkResources() {
      if (navigator && "mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices) {
        try {
          const videoStream = await navigator.mediaDevices.getUserMedia({
            video: true
          });
          const tracks = videoStream.getTracks();
          for (let i = 0; i < tracks.length; i++) {
            await tracks[i].stop();
          }
          this.cameraLiberada = true;
        } catch (error) {
          process.browser && window.$log.error("Error on checkResources video", error);
          this.cameraLiberada = false;
        }
      } else {
        this.cameraLiberada = false;
      }
    },
    initCameraUnico() {
      this.loadingModal(true);
      const unicoCamera = this.unicoCameraBuilder.build();
      unicoCamera
        .prepareSelfieCamera(this.unicoConfig, this.selfieCameraTypes.SMART)
        .then(cameraUnico => {
          this.cameraUnico = cameraUnico;
          this.loadingModal(false);
        })
        .catch(error => {
          this.loadingModal(false);
          this.initUnicoError = error;
          this.$emit("resourceError", error);
        });
    },
    async handleStart() {
      if (this.shouldRequestResources || !this.cameraUnico) return;
      if (this.confirmUser && !this.hasUserConfirmed) {
        const result = await Swal.fire({
          html: `Vamos iniciar a Biometria de ${this.credentialName}<br/><br/>Você confirma que é essa pessoa?`,
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          allowEscapeKey: false,
          allowOutsideClick: false
        });
        if (result.isConfirmed) {
          this.hasUserConfirmed = true;
        } else {
          return;
        }
      }

      this.cameraUnico.open({
        on: {
          success: obj => {
            this.$emit("success", obj);
          },
          error: error => {
            this.$emit("error", error);
          }
        }
      });
    }
  }
};
</script>
