<template>
  <div class="resume-table" v-if="!!table.length">
    <div class="resume-table__title">
      <img :src="baseUrl + iconSrc" />
      <h3>{{ title }}</h3>
    </div>
    <div class="resume-table__card-container">
      <div class="resume-table__card" v-for="(change, index) in table" :key="index + generateUUID()">
        <div class="card-row">
          <div class="card-row__header">Ação</div>
          <div class="card-row__body">{{ change.Acao }}</div>
        </div>
        <div class="card-row">
          <div class="card-row__header">Antes</div>
          <div class="card-row__body">
            <ul v-if="change.Acao !== 'Incluir'">
              <li v-for="(key, rowIndex) in Object.keys(change.Antes)" :key="rowIndex + generateUUID()">
                <span v-if="key !== 'IdLogradouro'">
                  <b>{{ key }}</b> : {{ change.Antes[key] }}
                </span>
              </li>
            </ul>
            <span v-else> Vazio </span>
          </div>
        </div>
        <div class="card-row">
          <div class="card-row__header">Depois</div>
          <div class="card-row__body">
            <ul v-if="change.Acao !== 'Excluir'">
              <li v-for="(key, rowIndex) in Object.keys(change.Depois)" :key="rowIndex + generateUUID()">
                <span v-if="key !== 'IdLogradouro'"
                  ><b>{{ key }}</b> : {{ change.Depois[key] }}
                </span>
              </li>
              <template v-if="!!aditionalKeys.length">
                <li v-for="(aditionalKeys, keyIndex) in aditionalKeys" :key="keyIndex + generateUUID()">
                  {{ `${aditionalKeys.label}: ${change[aditionalKeys.key]}` }}
                </li>
              </template>
            </ul>
            <span v-else> Vazio </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    table: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ""
    },
    iconSrc: {
      type: String,
      default: ""
    },
    aditionalKeys: {
      type: Array,
      default: () => []
    }
  },
  computed: mapGetters("saude", ["baseUrl"]),
  methods: {
    generateUUID() {
      return Math.random();
    }
  }
};
</script>

<style lang="scss" scoped>
.dark.page {
  .resume-table__card {
    color: var(--colors-light-primary-gray1);
    background-color: #1f1f1f;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.resume-table {
  & + & {
    margin-top: 32px;
  }

  &__title {
    display: flex;
    margin-bottom: 16px;

    h3 {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: var(--colors-light-primary-gray1);
      margin: 0;
    }

    img {
      margin: 0 16px 0 0;
    }
  }

  &__card {
    background-color: white;

    & + & {
      margin-top: 24px;
    }
    &-container {
      width: 100%;
    }
  }

  .card {
    &-row {
      display: flex;
      padding: 16px 0;
      margin: 0 32px;
      gap: 16px;

      &:not(:last-child) {
        border-bottom: 1px solid lavender;
      }
      &__header {
        flex: 1;
      }

      &__body {
        flex: 10;
      }
    }
  }
}
</style>
