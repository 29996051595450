import { mapActions, mapState } from "vuex";

export default {
  props: {
    service: {
      type: String
    },
    tokenKey: {
      type: String
    },
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      disableAutoSendTokenWithNoPhone: false,
      tokenInvalido: false,
      tokenValido: false,
      tentativas: 0,
      autenticacao: 0,
      tokenId: -1,
      token: "",
      phone: "",
      email: "",
      numberContacted: "",
      emailContacted: "",
      _service: "",
      _totenKey: ""
    };
  },
  computed: {
    ...mapState("myContacts", ["resumeContacts"]),
    testMode() {
      return process.env.VUE_APP_DISABLE_TOKEN_MODAL === "true";
    },
    phones() {
      if (!this.resumeContacts?.Telefones?.length) return [];
      return (this.resumeContacts?.Telefones.filter(t => t.Tipo === "Celular") ?? []).map(t => t.Numero);
    },
    phonesOptions() {
      if (!this.phones) return [];
      return this.phones.map(p => ({ label: p, value: p }));
    },
    canCheckToken() {
      if (this.testMode) return true;
      if (this.tokenInvalido) return false;
      if (this.token.length !== 4) return false;
      if (!this.phones?.length) return true;
      return !!this.phone || !!this.email;
    },
    computedTotenKeyValue() {
      return this.tokenKey || this._totenKey;
    },
    computedServiceValue() {
      return this.service || this._service;
    }
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");

      await this.fetchResumeContacts();
      if (!this.phones?.length && !this.testMode && !this.disableAutoSendTokenWithNoPhone) {
        this.$toast({
          description: "Atenção! Nenhum telefone celular cadastrado, o Token será enviado apenas para seu e-mail.",
          status: "error",
          duration: 10000,
          position: "bottom-right"
        });
        if (!this.disableAutoSendTokenWithNoPhone) this.enviarToken();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.$root.$emit("hide-loading");
    }
  },
  methods: {
    ...mapActions("myAccount", ["sendValidationCode", "validateCode"]),
    ...mapActions("myContacts", ["fetchResumeContacts"]),
    onPhoneSelected(value) {
      if (value && value !== this.phone) {
        this.phone = value;
      }
    },
    onEmailSelected(value) {
      if (value && value !== this.email) {
        this.email = value;
      }
    },
    onCloseClick() {
      this.$root.$emit("hide-loading");
      this.$emit("cancel");
    },
    onTokenInput(value) {
      if (value && value !== this.token) {
        this.tokenInvalido = false;
        this.token = value;
      }
    },
    async validarToken() {
      if (this.testMode) {
        this.$emit("valid");
        return;
      }
      if (!this.tokenInvalido && this.token.length === 4) {
        await this.$root.$emit("show-loading");
        const hasValid = await this.validateCode({
          phone: this.phone,
          email: this.email,
          id: this.tokenId,
          key: this.computedTotenKeyValue,
          code: this.token,
          auth: this.autenticacao,
          service: this.computedServiceValue || this.$route.meta.auth_service
        });
        await this.$root.$emit("hide-loading");
        if (hasValid instanceof Error) {
          this.$toast({
            description: "Não foi possível enviar o código para autenticação.\nServiço temporariamente indisponível.",
            status: "error",
            duration: 10000,
            position: "bottom-right"
          });
        } else if (hasValid) {
          this.$emit("valid", { auth: this.autenticacao });

          if ("save_auth_mutation" in this.$route.meta) {
            this.$store.commit(this.$route.meta.save_auth_mutation, this.autenticacao);
          }

          this.tokenValido = true;
        } else {
          this.tentativas++;
          if (this.tentativas > 10) {
            this.$toast({
              description: "Limite de tentativas excedido. Solicite o envio de um novo token.",
              status: "error",
              duration: 10000,
              position: "bottom-right"
            });
          } else {
            this.$toast({
              description: "Token inválido.",
              status: "error",
              duration: 10000,
              position: "bottom-right"
            });
          }
        }
      }
    },
    createTokenId() {
      const ID_LIMIT = 1000;
      const agora = new Date();
      let newId = this.id + agora.getHours() * 3600 + agora.getMinutes() * 60 + agora.getSeconds();
      while (newId > ID_LIMIT) newId -= ID_LIMIT;
      this.tokenId = `${this.tokenId}` === `${newId}` ? newId + 1 : newId;
    },
    async enviarToken() {
      await this.$root.$emit("show-loading");
      this.tentativas = 0;
      this.createTokenId();
      const sended = await this.sendValidationCode({
        phone: this.phone,
        email: this.email,
        id: this.tokenId,
        key: this.computedTotenKeyValue,
        service: this.computedServiceValue || this.$route.meta.auth_service,
        auth: this.autenticacao
      });
      await this.$root.$emit("hide-loading");
      if (sended?.enviado) {
        this.tokenId = sended.chave;
        this.autenticacao = sended.autenticacao;
        this.numberContacted = this.phone && sended.telefone ? this.phone : sended.telefone;
        this.emailContacted = sended.email;
        let messageText = "";
        if (sended.telefone && sended.email)
          messageText = `Token enviado o telefone: ${this.numberContacted} e para o e-mail: ${sended.email}`;
        else
          messageText = sended.telefone
            ? `Token enviado para o telefone: ${this.numberContacted}`
            : `Token enviado para o email: ${sended.email}`;

        this.$toast({
          description: messageText,
          status: "success",
          duration: 10000,
          position: "bottom-right"
        });
        return true;
      } else {
        this.$toast({
          description: "Não foi enviar o código para autenticação.\nServiço temporariamente indisponível.",
          status: "error",
          duration: 10000,
          position: "bottom-right"
        });
        return false;
      }
    }
  }
};
