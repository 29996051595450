<template>
  <omint-page-content>
    <div class="term-box">
      <OmtTypography as="h1-bold" mb="16px">Verificação de identidade</OmtTypography>
      <OmtTypography as="h3-semi-bold" mb="16px"
        >Insira o código que enviamos para o e-mail <br />{{ ofuscateEmail }}.</OmtTypography
      >
      <CBox w="100%" mb="24px">
        <OmtInput
          type="text"
          v-model="input"
          border="0.5px solid var(--colors-light-secondary-divider)"
          borderRadius="8px"
          :placeholder="codePlaceholder"
          ref="inputFields"
          textAlign="center"
          fontSize="24px"
          letterSpacing="10px"
          fontWeight="700"
          p="0"
          w="100%"
          @focus="codePlaceholder = ''"
          @blur="codePlaceholder = '000000000'"
        />
      </CBox>
      <OmtTypography as="p1" mb="32px">
        Se você não receber o código, verifique sua caixa de spam ou clique abaixo em reenviar código.
      </OmtTypography>

      <CFlex justifyContent="center">
        <OmtButton variant="ghost" @click="reSendCode">
          <OmtTypography as="p1-bold" color="var(--colors-light-primary-navy-blue)" textAlign="center" cursor="pointer">
            Reenviar código
          </OmtTypography>
        </OmtButton>
      </CFlex>
    </div>
    <OmtButton
      variant="solid"
      width="100%"
      height="50px"
      border="0.5px solid var(--colors-light-secondary-divider)"
      borderRadius="40px"
      data-cy="button-entrar"
      @click="handleClick"
      :disabled="continueButtonDisabled"
      mb="3"
    >
      <OmtTypography as="h1-bold" color="white">Continuar</OmtTypography>
    </OmtButton>
  </omint-page-content>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

const minCodeLength = 8;

export default {
  name: "CodeCheck",
  mounted() {
    this.$root.$emit("change-title-label", "Confirmação de Identidade");
    if (this.$route.params.email === undefined) {
      this.$router.push({ name: "first-access.mail-check" });
    }
  },
  data() {
    return {
      input: "",
      codePlaceholder: "000000000"
    };
  },
  computed: {
    continueButtonDisabled() {
      return this.input.length < minCodeLength;
    },
    ofuscateEmail() {
      const email = this.$route.params.email ?? "";
      const isValidEmail = email.includes("@") && !email.startsWith("@") && !email.endsWith("@");
      if (!isValidEmail) return email;
      const emailSplit = email.split("@");
      const comecoEmail = emailSplit[0];
      const fimEmail = emailSplit[1];
      if (comecoEmail.length > 3) {
        const hiddenChars = "•".repeat(comecoEmail.length - 3);
        const firstThreeChars = comecoEmail.substring(0, 3);
        return `${firstThreeChars}${hiddenChars}@${fimEmail}`;
      } else if (fimEmail.length > 3) {
        const hiddenChars = "•".repeat(fimEmail.length - 3);
        const firstThreeChars = fimEmail.substring(0, 3);
        return `${comecoEmail}@${firstThreeChars}${hiddenChars}`;
      } else {
        return email;
      }
    }
  },
  methods: {
    ...mapActions("firstAccess", ["checkCode", "sendCode"]),
    handleClick: async function () {
      await this.$root.$emit("show-loading");
      const error = await this.checkCode({ codigo: this.input });
      await this.$root.$emit("hide-loading");
      if (error !== "") {
        Swal.fire({
          title: "Aviso",
          text: error,
          confirmButtonText: "Tentar novamente"
        });
      } else {
        await this.$router.push({ name: "first-access.password" });
      }
    },
    async reSendCode() {
      await this.$root.$emit("show-loading");
      try {
        await this.sendCode({ email: this.$route.params.email });
        Swal.fire({
          title: "Sucesso",
          text: "Novo código solicitado com sucesso!",
          confirmButtonText: "OK"
        });
      } catch (e) {
        Swal.fire({
          title: "Aviso",
          text: "Não foi possível enviar o código, tente novamente mais tarde.",
          confirmButtonText: "OK"
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.term-box {
  height: calc(100vh - 180px);
  overflow-y: auto;
}
</style>
