<template>
  <CModal :is-centered="isCentered" size="sm" :on-close="closeModal" :closeOnOverlayClick="true" :is-open="true">
    <CModalContent borderRadius="4px" ref="content">
      <CModalBody
        borderRadius="4px"
        :class="theme"
        pb="0"
        pt="var(--space-3)"
        px="var(--space-3)"
        style="color: var(--colors-light-primary-gray1)"
      >
        <CFlex justifyContent="space-between">
          <CBox textAlign="center">
            <i :class="`fa fa-lg fa-info-circle`"></i>
          </CBox>
          <CBox position="relative" textAlign="right">
            <i class="fa fa-md fa-times" @click="closeModal"></i>
          </CBox>
        </CFlex>
        <CBox class="modal-content">
          <CBox mt="4">
            <omt-p>
              <b>Imagens aceitas</b><br />
              <b>Formato:</b> PDF, JPG e PNG<br />
              <b>Modo:</b> Retrato (câmera na posição vertical)<br />
              Envio <b>máximo de 6 documentos</b> por solicitação (imagens com vários recibos serão devolvidas para
              regularização)
            </omt-p>
          </CBox>
        </CBox>
      </CModalBody>
    </CModalContent>
    <CModalOverlay />
  </CModal>
</template>

<script>
import { CBox, CFlex, CStack, CModal, CModalOverlay, CModalContent, CModalBody } from "@chakra-ui/vue";
import { mapState } from "vuex";

export default {
  name: "InfoSendFileModal",
  props: {
    isCentered: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CBox,
    CFlex,
    CStack,
    CModal,
    CModalOverlay,
    CModalContent,
    CModalBody
  },
  computed: {
    ...mapState("saude", ["theme"])
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    emitConclude() {
      this.$emit("conclude");
    }
  }
};
</script>
