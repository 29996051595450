import Vue from "vue";
import * as api from "../api/atendimento";
import { checkUpdatePermission } from "@/utils/checkReadonlyUtil";

const initialState = {
  tickets: [],
  listOption: "30",
  ratingRoute: "",
  ratingType: 0
};

// vuex module
export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  actions: {
    async listTickets(context) {
      const tickets = await api.Lista({
        vinculo: context.rootState.saude.vinculo,
        situacao: null,
        chamado: 0,
        classificacao: -1,
        demanda: -1,
        tipoCliente: 1,
        cpf: null
      });
      if (tickets && tickets.Data && tickets.Data.Atendimentos) {
        await context.commit("SET_TICKETS", tickets.Data.Atendimentos);
      }
    },
    async fetchTicketDocuments(context, { ticket }) {
      const response = await api.ListarDocumentos({
        vinculo: context.rootState.saude.vinculo,
        chamado: ticket
      });
      return response && response instanceof Error ? [] : response?.Data ?? [];
    },
    async setListOption(context, option) {
      context.commit("SET_LIST_OPTION", option);
    },
    async getDocument(context, infos) {
      return await api.ObterDocumento({
        vinculo: context.rootState.saude.vinculo,
        chamado: infos.ticket,
        documento: infos.document
      });
    },
    async findTicket(context, ticket) {
      if (context.state.tickets.length > 0) {
        const ticketInfos = context.state.tickets.filter(r => r.IdChamado === ticket);
        if (ticketInfos && ticketInfos.length > 0) {
          const detalheChamado = await api.DetalharChamado({
            vinculo: context.rootState.saude.vinculo,
            chamado: ticket
          });

          if (detalheChamado && detalheChamado.Data) {
            ticketInfos[0].Imagens = detalheChamado.Data.Imagens;
            ticketInfos[0].Observacoes = detalheChamado.Data.Observacoes;
          }

          return ticketInfos[0];
        }
      }
      const tickets = await api.Lista({
        vinculo: context.rootState.saude.vinculo,
        situacao: null,
        chamado: ticket,
        classificacao: -1,
        demanda: -1,
        tipoCliente: 1,
        cpf: null
      });
      if (tickets && tickets.Data && tickets.Data.Atendimentos) {
        tickets.Data.Atendimentos.push(...context.state.tickets);
        context.commit("SET_TICKETS", tickets.Data.Atendimentos);

        const detalheChamado = await api.DetalharChamado({
          vinculo: context.rootState.saude.vinculo,
          chamado: tickets.Data.Atendimentos[0].IdChamado
        });

        if (detalheChamado && detalheChamado.Data) {
          tickets.Data.Atendimentos[0].Imagens = detalheChamado.Data.Imagens;
          tickets.Data.Atendimentos[0].Observacoes = detalheChamado.Data.Observacoes;
        }

        return tickets.Data.Atendimentos[0];
      }
      return null;
    },
    async createNewTicket(context, { obs, files, refTicket, classification }) {
      checkUpdatePermission();
      return api.NovoAtendimento({
        vinculo: context.rootState.saude.vinculo,
        observacao: obs,
        arquivos: files,
        chamadoReferenciado: refTicket,
        classificacao: classification
      });
    },
    async fetchQuestionsExpRating(context, { ticket, type, stage }) {
      const response = await api.ObterPerguntasAvaliacao({
        vinculo: context.rootState.saude.vinculo,
        atendimento: ticket,
        tipo: type,
        etapa: stage
      });
      const rules = response instanceof Error ? {} : response.Data ?? {};
      return {
        label: rules.RotuloTextoLivre,
        questions:
          rules.Perguntas?.map(q => ({
            value: q.Id,
            label: q.Dsc,
            maxRating: q.NotaMaximaExibicao,
            required: q.Obrigatorio,
            options:
              q.Opcoes.map(o => ({
                value: o.Id,
                label: o.Dsc
              })) ?? [],
            exceptions:
              q.Excecoes?.map(e => ({
                question: e.Pergunta,
                option: e.Opcao
              })) ?? []
          })) ?? []
      };
    },
    async fetchResponsesExpRating(context, { ticket, type, stage }) {
      const response = await api.ObterRespostasAvaliacao({
        vinculo: context.rootState.saude.vinculo,
        atendimento: ticket,
        tipo: type,
        etapa: stage
      });
      return response instanceof Error ? {} : response.Data ?? {};
    },
    async saveExpRating(context, { ticket, type, step, acknowledged, rating, obs, responses }) {
      checkUpdatePermission();
      const response = await api.AvaliacaoAtendimento({
        vinculo: context.rootState.saude.vinculo,
        atendimento: ticket,
        tipo: type,
        etapa: step,
        confirma: acknowledged,
        nota: rating,
        obs,
        respostas: responses
      });
      if (response instanceof Error) throw response;
      return response;
    },
    async fetchAuthType(context, { service, type, rules }) {
      let tipo = "S";
      if (service && !service.startsWith("/")) {
        try {
          service = context.rootGetters[service];
        } catch (error) {
          window.$log.message(`using service ${service}`);
        }
      }
      if (type) {
        try {
          const stateType = context.rootGetters[type];
          if (stateType === false) {
            tipo = "N";
          }
        } catch (error) {
          // não funcionou o get
          tipo = "S";
        }
      }

      const response = await api.ObterAutorizador({
        vinculo: context.rootState.saude.vinculo,
        servico: service,
        tipo,
        regras: rules
      });
      if (response instanceof Error) throw new Error("Não foi possível obter o tipo de autenticação necessária");
      return response.Data ?? { Autorizador: "", Id: 0, Autorizadores: [] };
    }
  },
  mutations: {
    LOGOUT_USER: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    RESET_CONTEXT: state => Object.keys(state).forEach(p => Vue.set(state, p, initialState[p])),
    SET_TICKETS: (state, nextTickets) => {
      Vue.set(state, "tickets", nextTickets);
    },
    SET_LIST_OPTION: (state, option) => {
      Vue.set(state, "listOption", option);
    },
    SET_RATING_INFOS: (state, { ratingRoute, ratingType }) => {
      Vue.set(state, "ratingRoute", ratingRoute);
      Vue.set(state, "ratingType", ratingType);
    }
  }
};
