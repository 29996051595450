<template>
  <OmtLayoutHeaderFooter
    v-bind="{
      header: {
        titleLabel: 'Notificações'
      }
    }"
  >
    <OmtGrid p="0 !important" :mt="['auto', '32px !important']">
      <OmtRow :desktopStart="1" :desktopEnd="12">
        <CTabs>
          <CTabList>
            <CTab w="50%"><OmtTypography as="h2-bold" py="2">Novidades</OmtTypography></CTab>
            <CTab w="50%"><OmtTypography as="h2-bold" py="2">Alertas</OmtTypography></CTab>
          </CTabList>
          <CTabPanels>
            <CTabPanel>
              <OmtGrid>
                <OmtRow v-for="(item, id) of news" :key="'notificacao' + id" mb="5">
                  <OmtCard>
                    <router-link v-if="item.Link.startsWith('/')" :to="{ path: item.Link }">
                      <NotificationCard :item="item" />
                    </router-link>
                    <a v-else :href="item.Link" target="_blank">
                      <NotificationCard :item="item" />
                    </a>
                  </OmtCard>
                </OmtRow>
              </OmtGrid>
            </CTabPanel>
            <CTabPanel>
              <OmtGrid>
                <OmtRow v-for="(item, id) of warnings" :key="'notificacao' + id" mb="5">
                  <OmtCard>
                    <router-link v-if="item.Link.startsWith('/')" :to="{ path: item.Link }">
                      <NotificationCard :item="item" />
                    </router-link>
                    <a v-else :href="item.Link" target="_blank">
                      <NotificationCard :item="item" />
                    </a>
                  </OmtCard>
                </OmtRow>
              </OmtGrid>
            </CTabPanel>
          </CTabPanels>
        </CTabs>
      </OmtRow>
    </OmtGrid>
  </OmtLayoutHeaderFooter>
</template>

<script>
import NotificationCard from "@/components/Organisms/Notifications/NotificationCard";
import { mapState } from "vuex";

export default {
  components: { NotificationCard },
  computed: {
    ...mapState("saude", ["profile"]),
    ...mapState("biometrics", ["hasBiometric", "shoudUseBiometric", "listenersRegistred"]),
    warnings() {
      if (!this.profile?.messages?.length) return [];
      return this.profile.messages.filter(item => item.TipoAviso === 2 || item.TipoAviso === 6 || item.TipoAviso === 9);
    },
    news() {
      if (!this.profile?.messages?.length) return [];
      const messages = this.profile.messages.filter(
        item => !(item.TipoAviso === 2 || item.TipoAviso === 6 || item.TipoAviso === 9)
      );
      if (this.hasBiometric && this.listenersRegistred && !this.shoudUseBiometric) return messages;

      return messages.filter(item => item.TipoAviso !== 7);
    }
  },
  mounted() {
    this.$root.$emit("hide-loading");
  }
};
</script>
