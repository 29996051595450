<template>
  <OmtStickedModal v-if="isOpen" @close="$emit('update:isOpen', false)">
    <OmtTypography as="h1-bold" mb="24px"> Documentos necessários </OmtTypography>
    <OmtTypography as="p1" mb="24px">
      Confira os documentos e informações necessárias para cada tipo de reembolso.
    </OmtTypography>
    <OmtTypography as="p1" mb="24px">
      <b>Lembre-se:</b> Todas as imagens enviadas devem pertencer ao beneficiário que utilizou o serviço.
    </OmtTypography>

    <CAccordion :defaultIndex="-1" :allowToggle="true">
      <CAccordionItem v-for="(info, i) in necessaryDocs" :key="i">
        <CAccordionHeader
          v-bind="{
            _hover: {
              background: 'inherit'
            },
            _focus: {
              boxShadow: 'none'
            }
          }"
        >
          <CBox flex="1" text-align="left">
            <OmtTypography as="button-small"> {{ info.title }} </OmtTypography>
          </CBox>
          <CAccordionIcon />
        </CAccordionHeader>

        <CAccordionPanel pb="0">
          <CBox p="6px 0px">
            <CList styleType="disc">
              <template v-for="(item, listIndex) in info.list">
                <CFlex pb="16px" :key="`info-${listIndex}`">
                  <OmtIcons :name="item.icon" size="sm" mr="2" />
                  <OmtTypography as="p0">{{ item.subtitle }}</OmtTypography>
                </CFlex>
                <OmtTypography
                  as="p2"
                  v-for="(subItem, subItemIndex) in item.sublist"
                  :key="`subItem-${listIndex}-${subItemIndex}`"
                  :pb="subItemIndex === item.sublist.length - 1 ? '16px' : '0'"
                >
                  <CListItem mb="6px" v-if="typeof subItem === 'string'">
                    <span v-html="subItem"></span>
                  </CListItem>
                </OmtTypography>
              </template>
              <template v-for="(most, mostIndex) in info.mostLists">
                <OmtTypography as="p0" pb="16px" color="var(--colors-lightPrimary-gray2)" :key="`most-${mostIndex}`">
                  {{ most.title }}
                </OmtTypography>
                <CBox v-for="(item, itemIndex) of most.list" :key="`most-${mostIndex}-${itemIndex}`">
                  <CFlex pb="16px">
                    <OmtIcons :name="item.icon" size="sm" mr="2" />
                    <OmtTypography as="p0">{{ item.subtitle }}</OmtTypography>
                  </CFlex>
                  <CBox>
                    <OmtTypography
                      as="p2"
                      v-for="(subItem, subItemIndex) in item.sublist"
                      :key="`most-${mostIndex}-${itemIndex}-${subItemIndex}`"
                      :pb="subItemIndex === item.sublist.length - 1 ? '16px' : '0'"
                    >
                      <CListItem mb="6px" v-if="typeof subItem === 'string'">
                        <span v-html="subItem"></span>
                      </CListItem>
                    </OmtTypography>
                  </CBox>
                </CBox>
              </template>
            </CList>
          </CBox>

          <CFlex
            backgroundColor="var(--colors-light-primary-light-gray)"
            borderRadius="8px"
            padding="16px"
            margin="0 -16px"
            v-if="info.alert"
          >
            <CBox>
              <OmtIcons name="circle-exclamation" size="lg" mr="2" color="var(--colors-light-support-warning)" />
            </CBox>
            <CBox>
              <OmtTypography as="p0" fontWeight="400" lineHeight="22px">
                <span v-html="info.alert"></span>
              </OmtTypography>
            </CBox>
          </CFlex>
        </CAccordionPanel>

        <CDivider mt="8px" />
      </CAccordionItem>
    </CAccordion>
  </OmtStickedModal>
</template>

<script>
import necessaryDocs from "./fixture/necessary-docs";

/**
 * @prop {Boolean} isOpen
 * @Event {( Array<{ label: string, isActive: boolean }> ) => void} change
 */
export default {
  props: {
    isOpen: {
      Type: Boolean
    }
  },
  data() {
    return {
      necessaryDocs
    };
  },
  methods: {
    close() {
      this.$emit("update:isOpen", false);
    },
    save() {
      this.$emit("change", this.filters);
      this.close();
    }
  }
};
</script>
