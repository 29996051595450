<template>
  <OmtCard :padding="['16px', '24px']">
    <CFlex>
      <CBox :pr="['16px', '26px']">
        <OmtIcons name="user-plus" :font-size="['16px', '21px']" size="inherit" />
      </CBox>
      <CBox w="100%" cursor="pointer">
        <CFlex justifyContent="space-between">
          <OmtTypography :as="['h3-bold', 'h1-semi-bold']" @click="$emit('visualizar', item)">
            {{ item.Nome }}
          </OmtTypography>
          <OmtIcons
            cursor="pointer"
            name="ellipsis-vertical"
            :font-size="['16px', '21px']"
            size="inherit"
            @click="$emit('action-modal', item)"
          />
        </CFlex>
        <OmtTypography
          :as="['p2', 'p1']"
          pt="1"
          color="var(--colors-light-primary-gray2)"
          @click="$emit('visualizar', item)"
          cursor="pointer"
        >
          {{ item.Tipo }} administrador <span v-if="item.Vigencia !== ''">• Vigência: {{ item.Vigencia }}</span>
        </OmtTypography>

        <OmtDivider :mt="['16px', '24px']" :mb="['16px', '22px']" />

        <CFlex :flexWrap="['wrap', '']" width="100%" justifyContent="space-between">
          <CBox>
            <CFlex alignItems="center" v-if="item.AdministradorNomeado == 'Sim'">
              <CBox bg="var(--colors-light-support-success)" width="8px" height="8px" borderRadius="8px" mr="5px" />
              <OmtTypography :as="['p2', 'p1']" color="var(--colors-light-primary-gray2)"
                >Administrador autorizado</OmtTypography
              >
            </CFlex>
            <CFlex alignItems="center" v-else>
              <CBox bg="var(--colors-light-support-error)" width="8px" height="8px" borderRadius="8px" mr="5px" />
              <OmtTypography :as="['p2', 'p1']" color="var(--colors-light-primary-gray2)"
                >Administrador não autorizado</OmtTypography
              >
            </CFlex>
          </CBox>
          <CFlex
            justifyContent="center"
            :mt="['20px', '0']"
            :width="['100%', 'auto']"
            pr="20px"
            @click="$emit('prorrogar', item)"
            v-if="item.AdministradorNomeado === 'Sim'"
          >
            <OmtIcons
              name="arrows-rotate"
              :font-size="['16px', '21px']"
              size="sm"
              pr="8px"
              color="var(--colors-light-primary-navy-blue)"
            />
            <OmtTypography as="h3-bold" color="var(--colors-light-primary-navy-blue)" cursor="pointer">
              Renovar autorização</OmtTypography
            >
          </CFlex>
        </CFlex>
      </CBox>
    </CFlex>
  </OmtCard>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    icon() {
      return {
        owner: "user-alt",
        "third-party": "user-plus"
      }[this.type];
    }
  }
};
</script>
