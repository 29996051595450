<template>
  <CPseudoBox
    data-cy="omt-image-previewer"
    v-bind="{
      ...{
        ...(!!previewOnClick && {
          cursor: 'pointer'
        }),
        ...(!!hasBorder
          ? {
              bg: 'var(--colors-light-primary-light-gray)',
              p: '9px',
              borderRadius: '8px',
              maxWidth: [getMobileSize + 18 + 'px', !onlyMobileSize && '148px'],
              minWidth: [getMobileSize + 18 + 'px', !onlyMobileSize && '148px']
            }
          : {
              maxWidth: [getMobileSize + 'px', !onlyMobileSize && '148px'],
              minWidth: [getMobileSize + 'px', !onlyMobileSize && '148px']
            })
      }
    }"
  >
    <CBox @click="triggerModal(true)">
      <CImage
        w="100%"
        borderRadius="8px"
        :h="[getMobileSize + 'px', !onlyMobileSize && '130px']"
        :src="source"
        v-bind="$attrs"
      />
    </CBox>
    <OmtStickedModal v-if="!!previewOnClick && showPictureModal" @close="triggerModal(false)">
      <CBox p="0 16px" min-height="80vh">
        <CImage data-cy="image-preview-modal" :src="sourceModal" max-height="100vh" max-width="100%" />
      </CBox>
    </OmtStickedModal>
  </CPseudoBox>
</template>

<script>
/**
 * Component extends https://vue.chakra-ui.com/pseudobox
 *
 * @prop {any} $props any props listed here: https://vue.chakra-ui.com/style-props
 *
 * @prop {String} source
 * @prop {String} size currently options : "xs" (height: 32px) | "sm" (height: 40px)
 * @prop {Boolean} previewOnClick
 * @prop {Boolean} hasBorder
 * @prop {Boolean} onlyMobileSize
 */

export default {
  props: {
    source: String,
    loadModalImage: {
      type: Function,
      default: null
    },
    previewOnClick: {
      type: Boolean,
      default: true
    },
    hasBorder: {
      type: Boolean,
      default: true
    },
    onlyMobileSize: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      validator: value => {
        const options = ["xs", "sm"];
        const validOption = !!options.includes(value);
        if (!validOption) console.error(`Allowed sizes : ${options.join(" - ")}`);
        return validOption;
      },
      default: "sm"
    }
  },
  data() {
    return {
      sourceModal: "",
      showPictureModal: false
    };
  },
  methods: {
    async triggerModal(shouldShow) {
      if (this.previewOnClick) {
        if (this.loadModalImage) {
          this.sourceModal = await this.loadModalImage();
        } else {
          this.sourceModal = this.source;
        }
        this.showPictureModal = shouldShow;
      }
    }
  },
  computed: {
    getMobileSize() {
      const sizes = {
        xs: 32,
        sm: 56
      };

      return sizes[this.size];
    }
  }
};
</script>
