const baseURL = `${process.env.VUE_APP_SERVER_URL}/SeguroViagem`;

export async function ObterSolicitacoes({ vinculo, idChamado = null, idioma = null, protocolo = null }) {
  const query = {
    vinculo,
    ...(idChamado && { idChamado }),
    ...(idioma && { idioma }),
    ...(protocolo && { protocolo })
  };

  return window.$axios
    .get({
      url: `${baseURL}/ObterSolicitacoes`,
      query
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterSolicitacoes", error.message);
      throw new Error(error.message);
    });
}

export async function ObterDadosRelatorio({ vinculo }) {
  const query = {
    vinculo
  };

  return window.$axios
    .get({
      url: `${baseURL}/ObterDadosRelatorio`,
      query
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterDadosRelatorio", error.message);
      throw new Error(error.message);
    });
}

export async function EnviarCarta({ vinculo, idiomas, beneficiarios }) {
  return window.$axios
    .post({
      url: `${baseURL}/EnviarCarta`,
      query: {
        vinculo
      },
      data: {
        Idiomas: idiomas,
        Beneficiarios: beneficiarios
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("EnviarCarta", error.message);
      throw new Error(error.message);
    });
}

export function DownloadCartaSeguroViagem({ vinculo, documento }) {
  const queryParams = new URLSearchParams({
    vinculo: vinculo,
    key: documento,
    tipo: ".pdf"
  }).toString();
  return `${baseURL}/DownloadCartaSeguroViagem?${queryParams}`;
}
