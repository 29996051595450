<template>
  <OmtCard display="flex" flex-direction="column" cursor="pointer" v-bind="$attrs" v-on="$listeners">
    <CFlex>
      <CBox w="36px">
        <OmtIcons name="square-poll-horizontal" size="lg" />
      </CBox>
      <CBox flex="1">
        <OmtTypography as="h3-semi-bold" mb="12px"> Protocolo {{ protocol }} </OmtTypography>
        <OmtTypography as="p2" color="var(--colors-light-primary-gray2)" mb="16px">
          Solicitado em {{ createdAt }}
        </OmtTypography>

        <CBox v-for="(docType, index) in resumeDocTypes" :key="`${index}-${docType.type}-${docType.description}`">
          <CDivider mt="0" mb="0" />
          <StatusTile
            v-chakra
            mt="16px"
            mb="14px"
            :label="docType.description"
            :color="docType.color"
            :count="docType.count"
            :total="docType.total"
          />
        </CBox>
      </CBox>
    </CFlex>
    <CFlex v-if="canEvaluate" justify-content="center" mt="5px">
      <OmtButton
        leftIcon="thumbs-up"
        size="md"
        iconSize="1x"
        border=".5px solid var(--colors-light-secondary-divider)"
        :variant="'outline'"
        :iconColor="'var(--colors-light-primary-gray1)'"
        w="158px !important"
        pl="15px"
        pr="15px"
        @click.stop="$emit('evaluate', protocol)"
      >
        <OmtTypography as="button-small" :color="'var(--colors-light-primary-gray1)'"> Avaliar serviço </OmtTypography>
      </OmtButton>
    </CFlex>
  </OmtCard>
</template>

<script>
import { StatusTile } from "./shared";

/**
 * @type { RefundType: { description: string, color: string, total: string, count: number }}
 *
 * @prop { RefundType[] } resumeDocTypes
 * @prop { String } createdAt
 * @prop { String } protocol
 */
export default {
  components: {
    StatusTile
  },
  props: {
    protocol: { Type: String },
    createdAt: { Type: String },
    canEvaluate: { Type: Boolean },
    resumeDocTypes: {
      type: Array,
      default: []
    }
  }
};
</script>
