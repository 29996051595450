<template>
  <OmtGrid>
    <OmtRow mb="24px">
      <OmtTypography as="h1-bold"> Análise da solicitação </OmtTypography>
    </OmtRow>
    <OmtRow mb="16px">
      <CFlex>
        <OmtIcons name="square-poll-horizontal" mr="12px" size="lg" />
        <OmtTypography as="h3-semi-bold"> Protocolo: {{ refund.protocol }} </OmtTypography>
      </CFlex>
    </OmtRow>
    <OmtRow>
      <CFlex justify-content="space-between" w="100%">
        <OmtTypography as="p2" color="var(--colors-light-primary-gray2)">
          Solicitação de reembolso • {{ refund.totalAmount }}
        </OmtTypography>

        <OmtTypography as="p2" color="var(--colors-light-primary-gray2)"> {{ refund.createdAt }} </OmtTypography>
      </CFlex>
    </OmtRow>
    <OmtRow>
      <OmtDivider mt="24px" mb="24px" />
    </OmtRow>

    <OmtRow v-show="!!auditFormManager.getComponent" mb="48px">
      <AuditForm
        :is="auditFormManager.getComponent"
        @change="handleChange"
        :question="currentAuditQuestion"
        :dueDate="dueDate"
      />
    </OmtRow>

    <OmtRow :desktopStart="5" :desktopEnd="8">
      <AuditFormConfirmButtons
        @continue="handleContinue"
        @cancel="showCancellationModal = true"
        :disabled="value === 0 || value === ''"
      />
    </OmtRow>

    <AuditFormCancellationModal :isOpen.sync="showCancellationModal" @cancel="sendToTermsFromCancel" />
  </OmtGrid>
</template>

<script>
import * as AuditForm from "@/components/Organisms/RefundNew/AuditForm";
import AuditFormCancellationModal from "@/components/Organisms/RefundNew/AuditForm/AuditFormCancellationModal.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    AuditFormCancellationModal,
    ...AuditForm
  },
  data() {
    return {
      auditFormManager: {},
      value: "",
      showCancellationModal: false,
      refund: {},
      ticket: null,
      dueDate: ""
    };
  },
  async mounted() {
    await this.$root.$emit("change-title-label", "Auditoria de reembolso");

    if (this.$route.params.dueDate) this.dueDate = this.$route.params.dueDate;
    if (this.$route.params.refund) this.refund = this.$route.params.refund;
    if (this.$route.params.ticket) this.ticket = this.$route.params.ticket;

    if (!this.refund || !this.ticket) {
      this.$router.push({
        path: "/refund-new"
      });

      return;
    }

    this.auditFormManager = new AuditForm.AuditFormManager(this.loadQuestion, this.saveQuestionResponse);
  },
  computed: {
    ...mapState("refund", ["audit"]),
    currentAuditQuestion() {
      return this.audit[this.auditFormManager?.current?.id];
    }
  },
  methods: {
    ...mapActions("refund", ["fetchAuditQuestion", "saveAuditResponse"]),
    handleChange($event) {
      this.value = parseInt($event);
      this.auditFormManager.storeAnswer({ value: this.value });
    },
    handleContinue() {
      this.auditFormManager.saveAndGetNext();
      this.value = "";
    },
    sendToTermsFromCancel() {
      this.$router.replace({
        name: "refund.new.audit.term",
        params: {
          scenario: 6,
          answers: [],
          refund: this.refund,
          ticket: this.ticket,
          mustCancelProtocol: true
        }
      });
    },
    sendToTerms(dueDate = "") {
      this.$router.replace({
        name: "refund.new.audit.term",
        params: {
          scenario: this.auditFormManager.scenarioIndex,
          dueDate,
          answers: this.auditFormManager.answers,
          refund: this.refund,
          ticket: this.ticket
        }
      });
    },
    async loadQuestion(questionTypeId) {
      try {
        await this.$root.$emit("show-loading");

        if (this.ticket && this.refund.protocol) {
          await this.fetchAuditQuestion({
            ticket: this.ticket,
            protocol: this.refund.protocol,
            questionTypeId
          });
        } else {
          this.$router.push({
            path: "/refund-new"
          });
        }
      } catch (error) {
        this.$toast({
          description: error.message,
          status: "error",
          duration: 10000,
          position: "top"
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    async saveQuestionResponse({ questionTypeId, option }) {
      try {
        await this.$root.$emit("show-loading");
        const { EncerrarFluxo } = await this.saveAuditResponse({
          questionTypeId,
          option
        });

        if (EncerrarFluxo) this.sendToTerms(this.dueDate);
      } catch (error) {
        this.$toast({
          description: error.message,
          status: "error",
          duration: 10000,
          position: "top"
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
    }
  }
};
</script>
