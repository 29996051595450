<template>
  <omint-page>
    <Header name="Credencial Offline" titleLabel="Credencial Offline" backRoute="/login" />
    <OmtLayoutContainer>
      <OmtRow v-if="selectedCredencialIdx >= 0" flex-direction="column" mb="24px">
        <CBox>
          <OmtTypography as="p1"> Visualizar informações da credencial do beneficiário: </OmtTypography>
          <CBox :p="['0 16px', '0']">
            <OmtSelect :initialValue="selectedCredencialKey" mb="24px" @change="onCredentialNameChange($event)">
              <option v-for="n in credentialNames" :key="n.key" :value="n.key">
                {{ n.name }}
                {{ n.tipo }}
              </option>
            </OmtSelect>
          </CBox>
        </CBox>
        <OmtSwiper mb="16px" :goToSlide="selectedCredencialIdx" @slide-atual="onCredentialImageChange($event)">
          <OmtSwiperSlide v-for="(c, idx) in credentialsMap" :key="c.key">
            <Credential
              :id="c.id"
              :number="c.number"
              :name="c.name"
              :ingress="c.ingress"
              :valid="c.valid"
              :birth="c.birth"
              :plan="c.plan"
              :bg="c.bg || credentials[c.posBg].bg"
              :credType="c.credType"
              :cns="c.cns"
              :idx="idx"
              :numberT="c.numberT"
              :planT="c.planT"
              :accT="c.accT"
              :accTp="c.accTp"
              :abrT="c.abrT"
              cssMediaType=""
              @token="tokenUpdated(c.idx, $event)"
            />
          </OmtSwiperSlide>
        </OmtSwiper>

        <CFlex>
          <OmtSwiperNavigation
            justifyContent="center"
            :disabledPrev="selectedCredencialIdx === 0"
            :disabledNext="selectedCredencialIdx === credentialsMap.length - 1"
            @on-prev-button-click="changeCredentialCarousel('prev')"
            @on-next-button-click="changeCredentialCarousel('next')"
          />
        </CFlex>
      </OmtRow>

      <FixedButtonRow v-if="!isLogged && !isOffline" :useFooter="false" class="fixed-index">
        <OmtButton :w="['100%', '308px !important']" @click.prevent.stop="login" :disable="isLoginDisabled">
          Fazer login
        </OmtButton>
      </FixedButtonRow>
    </OmtLayoutContainer>
  </omint-page>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import { mapState } from "vuex";
import Header from "@/components/Molecules/Header";
import Credential from "@/components/Organisms/Credential.vue";
import { isLoggedIn } from "@/utils/authUtil";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

export default {
  name: "OfflineCredential",
  data() {
    return {
      credentialsMap: [],
      credentialNames: [],
      selectedCredential: {},
      selectedCredencialIdx: -1,
      selectedCredencialKey: "",
      isLoggedInApp: false,
      isOffline: false
    };
  },
  computed: {
    ...mapState("saude", ["credentials", "operacao", "perfil", "vinculo"]),
    isLogged() {
      if (this.appLocked) return false;
      return this.isLoggedInApp && this.vinculo && this.perfil && this.operacao;
    },
    isLoginDisabled() {
      if (!this.isOffline) return;
    }
  },
  created() {
    this.isOffline = !navigator.onLine;
    window.addEventListener("offline", this.onConnectionChange);
    window.addEventListener("online", this.onConnectionChange);
  },
  beforeDestroy() {
    window.removeEventListener("offline", this.onConnectionChange);
    window.removeEventListener("online", this.onConnectionChange);
  },
  methods: {
    changeCredentialCarousel(direction) {
      if (direction === "prev") {
        if (this.selectedCredencialIdx > 0) {
          this.onCredentialImageChange(this.selectedCredencialIdx - 1);
        }
        return;
      }
      if (direction === "next") {
        const next = this.selectedCredencialIdx + 1;
        if (next < this.credentialsMap.length) {
          this.onCredentialImageChange(next);
        }
        return;
      }
    },
    initCredentialsMap() {
      if (!this.credentials?.length) return;
      let idx = -1;
      const creds = this.credentials.map(cred => ({
        ...cred,
        nameNorm: cred.name.toLowerCase().replace(/\b\w/g, match => match.toUpperCase()),
        idx: ++idx,
        key: `credImg-${idx}`,
        keyName: `credName-${idx}`
      }));
      let lastName = "";
      let lastKey = "";
      creds.forEach(c => {
        if (c.nameNorm !== lastName) {
          lastName = c.nameNorm;
          lastKey = c.keyName;
        } else {
          c.keyName = lastKey;
        }
      });
      this.credentialsMap = creds;
    },
    initCredentialNames() {
      if (!this.credentials?.length) return;
      let lastName = "";
      const names = [];
      this.credentialsMap.forEach(cred => {
        const name = cred.nameNorm;
        if (name !== lastName) {
          lastName = name;
          names.push({
            idx: cred.idx,
            key: cred.keyName,
            name: cred.nameNorm,
            tipo: cred.titular ? "- Titular" : "- Dependente"
          });
        }
      });
      this.credentialNames = names;
    },
    onCredentialNameChange(key) {
      if (this.selectedCredencialKey === key) return;
      this.selectedCredencialKey = key;
      this.selectedCredential = this.credentialsMap.find(cred => cred.keyName === key);
      this.selectedCredencialIdx = this.selectedCredential.idx;
      this.updateToken();
    },
    onCredentialImageChange(idx) {
      if (this.selectedCredencialIdx === idx) return;
      this.selectedCredencialIdx = idx;
      this.selectedCredential = this.credentialsMap.find(cred => cred.idx === idx);
      this.selectedCredencialKey = this.selectedCredential.keyName;
      this.updateToken();
    },
    onConnectionChange() {
      this.isOffline = !navigator.onLine;
    },
    login() {
      this.$router.push("/login");
    },
    tokenUpdated(idx, token) {
      if (idx >= this.credentialsMap.length) return;
      this.credentialsMap[idx].token = token;
      if (this.selectedCredencialIdx !== idx) return;
      this.updateToken();
    },
    updateToken() {
      if (this.credentialsMap[this.selectedCredencialIdx].isMdSv === true) {
        this.$root.$emit("change-title-label", `Credencial`);
        return;
      }
      this.$root.$emit(
        "change-title-label",
        `Credencial Token ${this.credentialsMap[this.selectedCredencialIdx].token}`
      );
    }
  },
  mounted() {
    this.isLoggedInApp = isLoggedIn();
    this.isOffline = !navigator.onLine;
    this.$root.$emit("hide-loading");
    if (!this.credentials?.length) return;
    this.initCredentialsMap();
    this.initCredentialNames();
    this.onCredentialImageChange(0);
  },
  components: {
    Carousel,
    Slide,
    Credential,
    Header,
    FixedButtonRow
  }
};
</script>
<style scoped>
.fixed-index {
  z-index: 1;
}
</style>
