<template>
  <div>
    <Header name="Médicos e Clínicas" titleLabel="Médicos e Clínicas" />
    <omint-page-content v-if="docInfo && match">
      <CBox mb="3">
        <AffiliateIndicationTile
          icon="fas fa-user"
          :title="match.DescricaoPrestador"
          :leadIcons="[favorite ? 'fas fa-heart' : 'far fa-heart']"
          @click="changeFavorite"
        />
      </CBox>
      <omt-divider />

      <CFlex v-if="match.Distancia !== undefined && !Number.isNaN(match.Distancia)" align="baseline">
        <i class="fas fa-map-marker-alt fa-lg" />
        <CBox ml="2">
          <omt-p> {{ Math.round(match.Distancia * 100) / 100 }} km </omt-p>
        </CBox>
        <CBox ml="2">
          <omt-p> A partir da sua localização atual </omt-p>
        </CBox>
      </CFlex>

      <CBox mb="3">
        <omt-p>
          {{ match.TipoLogradouro }} {{ match.EnderecoRua }}, {{ match.EnderecoNumero }}
          <br />
          {{ match.EnderecoBairro }} - {{ match.CodigoEstado }}
        </omt-p>
      </CBox>

      <omt-button
        v-chakra
        w="100%"
        mb="3"
        v-for="(c, i) in docInfo.Contatos"
        :key="'tel-' + i"
        :onClick="() => goTo('tel: ' + c.EnderecoTelefoneDdd + c.EnderecoTelefonePrefixo + c.EnderecoTelefone)"
      >
        <i class="fas fa-phone flip-x"></i>&nbsp; {{ c.EnderecoTelefoneDdd }}
        {{ c.EnderecoTelefonePrefixo }}
        {{ c.EnderecoTelefone }}
      </omt-button>

      <CFlex class="buttons-container" mb="6">
        <omt-button
          :onClick="
            () => goTo(`https://www.google.com/maps/search/?api=1&query=${match.GmLatitude}%2C${match.GmLongitude}`)
          "
        >
          <img :src="this.baseUrl + '/images/omint/googleMaps.svg'" alt="google maps logo" />
        </omt-button>
        <omt-button
          :onClick="
            () => goTo(`https://www.waze.com/ul?ll=${match.GmLatitude}%2C${match.GmLongitude}&navigate=yes&zoom=17`)
          "
        >
          <img :src="this.baseUrl + '/images/omint/wazeIcon.svg'" alt="waze logo" />
        </omt-button>
      </CFlex>

      <CBox v-if="docInfo.Especialidades && docInfo.Especialidades.length">
        <AffiliateIndicationTile icon="fa fa-user" title="Especialidades" />
        <ul>
          <li v-for="(e, i) in docInfo.Especialidades" :key="'spec-' + i + e.CodigoEspecialidade">
            {{ e.DescricaoEspecialidade.trim() }}
          </li>
        </ul>
        <omt-divider />
      </CBox>

      <CBox v-if="docInfo.Acessos && docInfo.Acessos.length">
        <AffiliateIndicationTile icon="fa fa-check" title="Facilidades" />
        <ul>
          <li v-for="(a, i) in docInfo.Acessos" :key="i">
            {{ a.trim() }}
          </li>
        </ul>
        <omt-divider />
      </CBox>

      <CBox mb="3">
        <AffiliateIndicationTile icon="fa fa-medal" title="Qualificações" />
        <omt-p>
          Conforme Instrução Normativa 52/DIDES de 21/03/2013 a qualificação é uma informação voluntária do Prestador.
        </omt-p>

        <CFlex class="qualifications-label" v-for="(q, i) in qualificacoesANS" :key="i">
          <CBox>
            <img :src="q.src" :alt="q.name" />
          </CBox>
          <span> {{ q.dsc }} </span>
        </CFlex>
      </CBox>

      <omt-button v-chakra w="100%" my="3" :colorInverse="true" :onClick="redirectToPlansPage">
        PLANOS ATENDIDOS
      </omt-button>
    </omint-page-content>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";
import AffiliateIndicationTile from "@/components/Organisms/AffiliateIndication/Tile";
import { CBox, CFlex } from "@chakra-ui/vue";
import { mapGetters, mapActions, mapState } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "AffiliateIndicationVisualizeAccredited",
  components: { Footer, Header, AffiliateIndicationTile, CBox, CFlex },
  data() {
    return {
      favorite: false
    };
  },
  async mounted() {
    if (!this.match) {
      await this.$router.replace("/indicacao-de-credenciados");
      return;
    }
    try {
      await this.$root.$emit("show-loading");
      this.favorite = this.match.Favorito;
      await this.fetchDoctorsInfo({
        rede: "",
        codigoPrestador: this.match.CodigoPrestador,
        srEndereco: this.match.SrEndereco,
        inativos: false
      });
      this.addAffiliateView({
        prestador: this.match.CodigoPrestador,
        endereco: this.match.SrEndereco
      });
      this.testFavorite({
        prestador: this.match.CodigoPrestador,
        endereco: this.match.SrEndereco
      }).then(r => (this.favorite = r));
    } catch (error) {
      await Swal.fire({
        text: error.message,
        confirmButtonText: "OK",
        icon: "error"
      });
    } finally {
      this.$root.$emit("hide-loading");
    }
  },
  computed: {
    ...mapGetters("saude", ["baseUrl"]),
    ...mapState("affiliate", ["docInfo", "match"]),
    qualificacoesANS() {
      return [
        {
          name: "Aona",
          dsc: "Padrão nacional de qualidade",
          src: `${this.baseUrl}/images/omint/busca/aona.jpg`
        },
        {
          name: "Adicq",
          dsc: "Padrão nacional de qualidade",
          src: `${this.baseUrl}/images/omint/busca/adicq.jpg`
        },
        {
          name: "Apalc",
          dsc: "Padrão nacional de qualidade",
          src: `${this.baseUrl}/images/omint/busca/apalc.jpg`
        },
        {
          name: "Acba",
          dsc: "Padrão internacional de qualidade",
          src: `${this.baseUrl}/images/omint/busca/acba.jpg`
        },
        {
          name: "Aqg",
          dsc: "Padrão internacional de qualidade",
          src: `${this.baseUrl}/images/omint/busca/aqg.jpg`
        },
        {
          name: "R",
          dsc: "Profissional com residência",
          src: `${this.baseUrl}/images/omint/busca/r.jpg`
        },
        {
          name: "N",
          dsc: "Comunicação de eventos adversos",
          src: `${this.baseUrl}/images/omint/busca/n.jpg`
        },
        {
          name: "P",
          dsc: "Profissional com especialização",
          src: `${this.baseUrl}/images/omint/busca/p.jpg`
        },
        {
          name: "Q",
          dsc: "Qualidade monitorada",
          src: `${this.baseUrl}/images/omint/busca/q.jpg`
        },
        {
          name: "E",
          dsc: "Título de Especialista",
          src: `${this.baseUrl}/images/omint/busca/e.jpg`
        }
      ];
    }
  },
  methods: {
    ...mapActions("affiliate", ["fetchDoctorsInfo", "addAffiliateView", "testFavorite", "remFavorite", "addFavorite"]),
    goTo(destiny) {
      window.open(destiny);
    },
    changeFavorite() {
      if (this.favorite) {
        this.remFavorite({
          prestador: this.match.CodigoPrestador,
          endereco: this.match.SrEndereco
        });
      } else {
        this.addFavorite({
          prestador: this.match.CodigoPrestador,
          endereco: this.match.SrEndereco
        });
      }
      this.favorite = !this.favorite;
    },
    async redirectToPlansPage() {
      await this.$router.push({
        path: "/redecredenciada/planos"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.buttons-container {
  gap: 12px;

  button {
    width: 100%;
  }
}

.flip-x {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

ul {
  margin-top: 12px;
  padding: 0 22px;
}

.qualifications-label {
  gap: 12px;

  & > div {
    min-width: 48px;
  }
}
</style>
